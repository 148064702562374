import React, { useState } from "react";
import {
  Box, Button, Grid, Text, Title, Card,
  Group, Badge, List, ThemeIcon, Collapse,
  Overlay, Paper, Loader
} from "@mantine/core";
import { ChevronRight } from 'tabler-icons-react';
import { COLORS } from '../../../../constants';
import Body from '../../../../components/Body/Body';
import { AnimatePresence, motion } from "framer-motion";
import { automateTriggers, getTriggersStatus, sendSMSviaWhatsapp } from "../../../../services/triggers.service";
import { useMutation, useQuery } from '@tanstack/react-query';
import { displayNotification } from "../../../../components/CommonComponents/Notification/displayNotification";
import { IconSendOff, IconSettingsAutomation } from "@tabler/icons-react";

const STYLES = {
  modalStyles: {
    position: 'fixed',
    top: 'calc(10vh + 20px)',
    left: '30%',
    maxHeight: '100%',
    zIndex: 300,
    padding: '5vh 5vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

const DAY_OPENING_LIST = ['Loan Book'];
const EOD_LIST = [
  'Loan Book',
  'Receipt Vs Rollover Statistics',
  'DPD Receipt Statistics',
  'Movement Statistics',
];

const MODAL_CONTENT = {
  opening: {
    title: 'Day Opening Stats',
    list: DAY_OPENING_LIST,
    type: 'WhatsApp',
    message: 'This will deliver notifications to management in multiple categories.'
  },
  eod: {
    title: 'EoD Stats',
    list: EOD_LIST,
    type: 'WhatsApp',
    message: 'This will deliver notifications to management in multiple categories.'
  },
  automate: {
    title: 'Are you sure?',
    list: EOD_LIST,
    message: 'Once you <strong>Confirm</strong> this action, notifications will be sent to management automatically when the eod execution is completed successfully.'
  },
  deactivate: {
    title: 'Are you sure?',
    list: EOD_LIST,
    message: 'Once you <strong>Confirm</strong> this action, you need to execute the triggers manually.'
  }
}

const CardContent = ({
  header,
  children,
  list,
  label = 'Categories',
  showContent = false
}) => {
  const [opened, setOpened] = useState(showContent);

  return (
    <Box>
      {header ? (<Text size="sm" mb={'sm'} color="dimmed">
        {header}
      </Text>) : null}
      <Button
        compact
        variant="subtle"
        size="xs"
        color="gray"
        sx={{
          fontWeight: 500
        }}
        onClick={() => setOpened((o) => !o)}
      >
        {opened ? 'Hide' : 'Show'} {label}
      </Button>
      <Collapse in={opened}>
        {
          Array.isArray(list) && list.length ? (
            <List
              spacing="xs"
              size="xs"
              center
              icon={
                <ThemeIcon size={18} radius="xl" color="transparent">
                  <ChevronRight size={14} color="teal" />
                </ThemeIcon>
              }
            >
              {
                list.map((item, index) => (
                  <List.Item key={index} color="dimmed">{item}</List.Item>
                ))
              }
            </List>
          ) : children
        }
      </Collapse>
    </Box>
  )
}

const Triggers = () => {
  const [modalType, setModalType] = useState();
  const sendSMSQuery = useMutation((body) => sendSMSviaWhatsapp(body));
  const automateTriggersQuery = useMutation(() => automateTriggers());
  const getTriggersStatusQuery = useQuery({ queryKey: ['triggers-status'], queryFn: () => getTriggersStatus() });

  /**
   * Handles triggering confirm action and sending SMS via Whatsapp.
   */
  const handleConfirmTrigger = () => {
    if (['automate', 'deactivate']?.includes(modalType)) {
      automateTriggersQuery?.mutate({}, {
        onSuccess: (res) => {
          displayNotification({ message: res?.message, variant: 'success' });
        },
        onError: (err) => {
          displayNotification({ message: err?.message || err, variant: 'error' });
        },
        onSettled: () => {
          setModalType();
          getTriggersStatusQuery?.refetch();
        }
      })
    } else if (modalType) {
      let body = { category: modalType === 'eod' ? 'end_of_day' : 'start_of_day' };
      sendSMSQuery?.mutate(body, {
        onSuccess: (res) => {
          displayNotification({ message: res?.message, variant: 'success' });
        },
        onError: (err) => {
          displayNotification({ message: err?.message || err, variant: 'error' });
        },
        onSettled: () => {
          setModalType();
          getTriggersStatusQuery?.refetch();
        }
      })
    }
  }

  return (
    <Body>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <Title mb={'md'} order={4} pl={'5px'}>Triggers &amp; Communications</Title>
          <Button
            compact
            leftIcon={getTriggersStatusQuery?.data?.updated_send_mis_value == 0 ? <IconSettingsAutomation size={14} /> : <IconSendOff size={14} />}
            loading={automateTriggersQuery.isFetching || getTriggersStatusQuery?.isLoading}
            color={getTriggersStatusQuery?.data?.updated_send_mis_value == 0 ? 'teal.7' : 'red'}
            component={motion.div}
            onClick={() => {
              setModalType(getTriggersStatusQuery?.data?.updated_send_mis_value == 0 ? 'automate' : 'deactivate');
            }}
          >
            {getTriggersStatusQuery?.data?.updated_send_mis_value == 0 ? 'Automate' : 'Deactivate'}
          </Button>
        </Box>
        <Grid sx={{ display: 'flex', alignItems: 'flex-start' }}>
          {
            Object.keys(MODAL_CONTENT).map((itemKey, index) => {
              const item = MODAL_CONTENT[itemKey];
              if (['automate', 'deactivate']?.includes(itemKey))
                return null;
              return (
                <Grid.Col span={6} key={index}>
                  <Card component={motion.div} layoutId={itemKey} p={'md'} radius={'md'} withBorder>
                    <Group position="apart" mb="xs">
                      <Text weight={500}>{item.title}</Text>
                      <Badge color="green" variant="light">
                        {item.type}
                      </Badge>
                    </Group>

                    <CardContent
                      header={item.message}
                      list={item.list}
                    />

                    <Button
                      variant="light"
                      color="blue"
                      fullWidth
                      mt="md"
                      radius="md"
                      onClick={() => setModalType(itemKey)}
                      disabled={getTriggersStatusQuery?.data?.updated_send_mis_value == 0 ? false : true}
                    >
                      {getTriggersStatusQuery?.data?.updated_send_mis_value == 0
                        ? 'Send now'
                        : 'Triggers are automated'
                      }
                    </Button>
                  </Card>
                </Grid.Col>
              );
            })
          }
        </Grid>
        <center>
          {getTriggersStatusQuery?.isLoading ? (
            <Loader mt={'md'} />
          ) : (
            getTriggersStatusQuery?.data?.updated_send_mis_value == 0 ? (
              <Text mt={'md'} color={COLORS.gray(70)} sx={{ fontSize: 11 }}>
                To enable automation for the above triggers, click the <strong>"Automate"</strong> button above.
                <br />
                Once you've automated the triggers, they will activate upon the successful completion of the EOD execution.
              </Text>
            ) : (
              <Text mt={'md'} color={COLORS.gray(70)} sx={{ fontSize: 11 }}>
                The above triggers are automated by the system and will execute when the EOD execution is successfully Completed.
                <br />
                To deactivate this, click the <strong>"Deactivate"</strong> button above.
              </Text>
            )
          )}
        </center>
      </Box>

      <AnimatePresence>
        {
          Boolean(modalType) ? (<>
            <Overlay
              component={motion.div}
              opacity={0.5}
              color={COLORS.black}
              sx={{ position: 'fixed' }}
              exit={{ opacity: 0 }}
            />
            <Box
              size='md'
              p={'xl'}
              layoutId={modalType}
              sx={STYLES.modalStyles}
              component={motion.div}
            >
              <Paper
                size='md'
                p={'xl'}
                role="dialog"
                tabIndex={-1}
                aria-modal
                radius={'md'}
                shadow="xs"
              >
                <Group position="apart" mb="xs">
                  <Text weight={700} size={20}>{MODAL_CONTENT[modalType].title}</Text>
                  {MODAL_CONTENT[modalType].type ? (
                    <Badge color="green" variant="light">
                      {MODAL_CONTENT[modalType].type}
                    </Badge>
                  ) : null}
                </Group>
                <Box mt="md" color="dimmed">
                  {['automate', 'deactivate']?.includes(modalType) ? (
                    <Text sx={{ maxWidth: 500 }}><div dangerouslySetInnerHTML={{ __html: MODAL_CONTENT[modalType].message }} /></Text>
                  ) : (
                    <Text>Clicking <strong>Confirm</strong> will trigger WhatsApp messages for top management.</Text>
                  )}
                  <Text mt={'xs'}>
                    Few things to keep in mind,
                    <Box pb={'xs'} ml="lg" mt={'xs'}>
                      <CardContent
                        showContent
                        list={MODAL_CONTENT[modalType].list}
                      />
                    </Box>
                  </Text>
                </Box>
                <Box mt="xl" sx={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
                  <Button variant="subtle" onClick={() => setModalType()}>
                    Cancel
                  </Button>
                  <Button
                    color={"green.5"}
                    variant={"filled"}
                    onClick={handleConfirmTrigger}
                    loading={['automate', 'deactivate']?.includes(modalType) ? automateTriggersQuery.isLoading : sendSMSQuery.isLoading}
                  >
                    Confirm
                  </Button>
                </Box>
              </Paper>
            </Box>
          </>
          ) : null
        }
      </AnimatePresence>
    </Body >
  );
};

export default Triggers;
