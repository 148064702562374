import React from 'react'
import { Grid, Text, Stack, Title } from '@mantine/core';
import COLORS from './../../../constants/colors';
import ControllerTextField from '../../../components/CommonComponents/TextField/ControllerTextField';
import ControllerSwitch from '../../../components/CommonComponents/TextField/ControllerSwitch';

const TaxInformationInvoiceComp = ({ control, errors, setValue, placeholderStyle }) => {
  return (
    <Grid gutter={'md'} sx={{maxHeight:"55vh",overflow:'hidden',overflowY: 'auto'}}>
      <Grid.Col span={8} pr={'xs'} sx={{ height: 'fit-content' }}>
        <Title order={6} mb={'md'}>Tax Information Details</Title>
        <Grid gutter={'md'}>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Tax</Text>
              <ControllerTextField
                name={'tax'}
                label={'Tax'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Tax'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.tax?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>L.S.T No</Text>
              <ControllerTextField
                name={'lst_no'}
                label={'L.S.T No'}
                displayLabel={false}
                spanSize={12}
                placeholder={'L.S.T No'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.l_s_t_no?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>C.S.T No</Text>
              <ControllerTextField
                name={'cst_no'}
                label={'C.S.T No'}
                displayLabel={false}
                spanSize={12}
                placeholder={'C.S.T No'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.c_s_t_no?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Excise</Text>
              <ControllerTextField
                name={'excise'}
                label={'Excise'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Excise'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.excise?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>E.C.C. No</Text>
              <ControllerTextField
                name={'ecc_no'}
                displayLabel={false}
                spanSize={12}
                placeholder={'E.C.C. No'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.e_c_c_no?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Range</Text>
              <ControllerTextField
                name={'range'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Range'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.range?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={12} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Collectorate</Text>
              <ControllerTextField
                name={'collectorate'}
                displayLabel={false}
                spanSize={4}
                placeholder={'Collectorate'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.collectorate?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={2} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Tax Liable</Text>
              <ControllerSwitch
                name={'tax_liable'}
                displayLabel={false}
                spanSize={12}
                control={control}
                onLabel="Yes"
                offLabel="No"
                size={'md'}
                color="green"
              />
            </Stack>
          </Grid.Col>

          <Grid.Col span={12} py={0} mt={'xs'}>
            <Title order={6} mb={'md'}>PAN Details</Title>
          </Grid.Col>
          <Grid.Col span={4} py={0} sx={{height: 'fit-content'}}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Income Tax</Text>
              <ControllerTextField
                name={'income_tax'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Income Tax'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.income_tax?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>P.A.N. No.</Text>
              <ControllerTextField
                name={'pan_no'}
                displayLabel={false}
                spanSize={12}
                placeholder={'P.A.N. No.'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.pan_no?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>P.A.N. Status</Text>
              <ControllerTextField
                name={'pan_status'}
                displayLabel={false}
                spanSize={12}
                placeholder={'P.A.N. Status'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.pan_status?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>P.A.N. Reference No.</Text>
              <ControllerTextField
                name={'pan_reference_no'}
                displayLabel={false}
                spanSize={12}
                placeholder={'P.A.N. Reference No.'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.pan_reference_no?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>VAT</Text>
              <ControllerTextField
                name={'vat'}
                displayLabel={false}
                spanSize={12}
                placeholder={'VAT'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.vat?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>T.I.N. No.</Text>
              <ControllerTextField
                name={'tin_no'}
                displayLabel={false}
                spanSize={12}
                placeholder={'T.I.N. No.'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.tin_no?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Export or Deemed Export</Text>
              <ControllerSwitch
                name={'export_or_deemed_export'}
                displayLabel={false}
                spanSize={12}
                control={control}
                onLabel="Yes"
                offLabel="No"
                size={'md'}
                color="green"
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>VAT Exempted</Text>
              <ControllerSwitch
                name={'vat_exempted'}
                displayLabel={false}
                spanSize={12}
                control={control}
                placeholder={'VAT Exempted'}
                onLabel="Yes"
                offLabel="No"
                size={'md'}
                color="green"
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Service Tax</Text>
              <ControllerTextField
                name={'service_tax'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Service Tax'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.service_tax?.message}
              />
            </Stack>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col span={4} pl={'xs'} mt={'sm'} sx={{borderLeft: `1px solid ${COLORS.gray(10)}`, borderBottom: `1px solid ${COLORS.gray(10)}`, height: 'fit-content'}}>
        <Grid gutter={'md'}>
          <Title order={6} ml={'xs'} mb={'md'}>GST Details</Title>
          <Grid.Col span={12} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>GST</Text>
              <ControllerTextField
                name={'gst_number'}
                displayLabel={false}
                spanSize={12}
                placeholder={'GST'}
                control={control}
                disabled
                styles={placeholderStyle}
                errors={errors?.gst?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={12} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>GST Customer Type</Text>
              <ControllerTextField
                name={'gst_customer_type'}
                displayLabel={false}
                spanSize={12}
                placeholder={'GST Customer Type'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.gst_customer_type?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={12} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>GST Registration Type</Text>
              <ControllerTextField
                name={'gst_registration_type'}
                displayLabel={false}
                spanSize={12}
                placeholder={'GST Registration Type'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.gst_registration_type?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={12} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>GST Registration No</Text>
              <ControllerTextField
                name={'gst_registration_no'}
                displayLabel={false}
                spanSize={12}
                placeholder={'GST Registration No'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.gst_registration_no?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={12} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>ARN No.</Text>
              <ControllerTextField
                name={'arn_no'}
                displayLabel={false}
                spanSize={12}
                placeholder={'ARN No.'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.arn_no?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={12} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>e-Commerce Operator</Text>
              <ControllerSwitch
                name={'ecommerce_operator'}
                displayLabel={false}
                spanSize={12}
                control={control}
                onLabel="Yes"
                offLabel="No"
                size={'md'}
                color="green"
              />
            </Stack>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col span={12}>
        <Grid gutter={'md'}>
          <Grid.Col span={3} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Nature of Services</Text>
              <ControllerTextField
                name={'nature_of_services'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Nature of Services'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.nature_of_services?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={3} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Service Entity Type</Text>
              <ControllerTextField
                name={'service_entity_type'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Service Entity Type'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.nature_of_services?.message}
              />
            </Stack>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  )
}

export default TaxInformationInvoiceComp
