import React, { useState } from "react";
import { Paper, Title, Box, Table, createStyles } from "@mantine/core";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { getProjectionReport } from "../../services/dashboard.service";
import { useQuery } from "@tanstack/react-query";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import Nums from "../../components/Body/Nums";
import { VictoryCustomTooltipSpline } from "./CustomTooltipSpline";
import { currencyFormat } from "../../components/Currency/Currency";
import changeValueFormat from "../../utils/changeValueFormat";

const useStyles = createStyles({
  tableHeader: {
    backgroundColor: "#d9d9d9",
    position: "sticky",
    left: 0,
    width: "20px",
  },
  tableScroll: {
    width: "100%",
    overflow: "scroll",
  },
  displayAmount: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    fontWeight: "600",
    gap: "2px",
  },
});

const CRORE = 10000000;

// used to display the currency in cr by clicking on
const DisplayValue = ({ value, type = "amount" }) => {
  const { classes } = useStyles();
  const [amountInCR, setAmountInCR] = useState(true);

  let FinalValue = changeValueFormat(value, type);

  let suffix;
  if (amountInCR) {
    FinalValue = changeValueFormat(value / CRORE, type, { round: false });
    suffix = "Cr";
  }

  return value ? (
    <Title
      onClick={() => setAmountInCR(!amountInCR)}
      order={6}
      className={classes.displayAmount}
    >
      {FinalValue} {suffix}
    </Title>
  ) : (
    "-"
  );
};

const SplineChart = ({ entity }) => {

  const { classes } = useStyles();
  const { data: reportDetails = [] } = useQuery(
    [`report-details`, entity],
    () => getProjectionReport(entity),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Paper p={"md"}>
      <Title order={5}>Projection Graph</Title>
      <Box mt="xl">
        <VictoryChart
          height={200}
          width={900}
          padding={{
            left: 80,
            bottom: 20,
          }}
          domainPadding={{ x: [30, 30], y: [10, 20] }}
          theme={VictoryTheme.material}
          containerComponent={
            <VictoryVoronoiContainer
              voronoiDimension="x"
              labels={({ datum }) => " "}
              labelComponent={
                <VictoryTooltip
                  flyoutComponent={
                    <VictoryCustomTooltipSpline type={"projection"} />
                  }
                  constrainToVisibleArea
                  cornerRadius={0}
                  flyoutStyle={{ fill: "white" }}
                />
              }
            />
          }
        >
          <VictoryAxis
            crossAxis
            theme={VictoryTheme.material}
            tickFormat={(t) => `${dateFormat(t, "DD-MM-YYYY")}`}
            style={{
              tickLabels: { fontSize: 8 },
            }}
            standalone={false}
          />
          <VictoryAxis
            dependentAxis
            crossAxis
            style={{
              tickLabels: { fontSize: 8 },
            }}
            theme={VictoryTheme.material}
            tickFormat={(t) => `${currencyFormat(parseFloat(t) / 10000000)}Cr`}
            standalone={false}
          />
          <VictoryLine
            interpolation="natural"
            style={{
              data: { stroke: "#5c7aea" },
              parent: { border: "1px solid #ccc" },
              labels: { fill: "gray", fontSize: 7 },
            }}
            labels={({ datum }) => `${currencyFormat(parseFloat(datum.principal)/10000000)}Cr`}
            data={reportDetails}
            x={"due_date"}
            y={"principal"}
          />
          <VictoryScatter
            data={reportDetails}
            x={"due_date"}
            y={"principal"}
            style={{ data: { fill: "blue" } }}
          />
        </VictoryChart>
        <Box className={classes.tableScroll} mt={"lg"}>
          <Table highlightOnHover>
            <tbody>
              <tr>
                <td className={classes.tableHeader}>
                  <strong>Date</strong>
                </td>
                {reportDetails?.map((item, index) => (
                  <td key={index}>
                    {dateFormat(item?.due_date, "DD-MM-YYYY")}
                  </td>
                ))}
              </tr>
              <tr>
                <td className={classes.tableHeader}>
                  <strong>Day</strong>
                </td>
                {reportDetails?.map((item, index) => (
                  <td key={index}>
                    {dateFormat(item?.due_date, "ddd")}
                  </td>
                ))}
              </tr>
              <tr>
                <td className={classes.tableHeader}>
                  <strong>Amount</strong>
                </td>
                {reportDetails?.map((item, index) => (
                  <td key={index}>
                    <DisplayValue value={item?.principal} />
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
        </Box>
      </Box>
    </Paper>
  );
};

export default SplineChart;
