import { Button, Text } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Plus } from "tabler-icons-react";
import Body from "../../components/Body/Body";
import DataTableWrapper from "../../components/CommonComponents/Table/DataTableWrapper";
import Nums from "../../components/Body/Nums";
import { getAllUnidentifiedReceipts } from "../../services/unidentifiedReceipt.service";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import {
  APPROVED,
  AUTO_CANCELLED,
  CANCELLATION_APPROVAL,
  CANCELLATION_REVIEW,
  CANCELLED,
  IN_APPROVAL,
  IN_REVIEW,
  OPEN,
  REJECTED,
} from "../../constants/status";
import useModuleRecordCountStore from "../../store/moduleRecordCountStore";

const UnidentifiedReceiptTable = () => {
  const navigateTo = useNavigate();
  const columnHelper = createColumnHelper();
  const location = useLocation();
  const { moduleCount } = useModuleRecordCountStore();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [records, setRecords] = useState({ label: "show 10", value: 10 });
  // get the search params
  const [searchParams, setSearchParams] = useSearchParams();
  const params = {}
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  /** used to change the page to 1 while the search us used
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1);
  }, [search, searchParams.get("status"), records]);

  // used to get the list of unidentified receipt
  const { data: receiptTable = [], isLoading } = useQuery(
    ["unidentified-receipt", page, search, records, params],
    () =>
      getAllUnidentifiedReceipts({
        ...params,
        page,
        search,
        records: records?.value,        
      }),
    {
      enabled: Boolean(searchParams.get("status") && searchParams.get("status") !== "none"),
      refetchOnWindowFocus: false,
    }
  );

  // handle the on click function
  const onRowClick = (i) => {
    if (i?.status_value == OPEN) {
      navigateTo(`/unidentified-receipt/${i?.id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == IN_REVIEW || i?.status_value == IN_APPROVAL) {
      navigateTo(`/unidentified-receipt/review/${i?.id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == APPROVED) {
      navigateTo(`/unidentified-receipt/approved/${i?.id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == AUTO_CANCELLED) {
      navigateTo(`/unidentified-receipt/cancelled/${i?.id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == REJECTED) {
      navigateTo(`/unidentified-receipt/rejected/${i?.id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == CANCELLED) {
      navigateTo(`/cancellation/unidentified-receipt-cancelled/${i?.id}`, { state: { from: location.pathname } });
    } else if (
      i?.status_value == CANCELLATION_REVIEW ||
      i?.status_value == CANCELLATION_APPROVAL
    ) {
      navigateTo(`/cancellation/unidentified-receipt-cancel/${i?.id}`, { state: { from: location.pathname } });
    }
  };

  const onClickAction = (value, type) => {
    return (
      <span
        style={{ color: "#0063FF", cursor: "pointer" }}
      >
        {type === "amount" ? (
          <Nums value={value.getValue()} styles={{textAlign: 'right'}} />
        ) : type === "date" ? (
          dateFormat(value.getValue())
        ) : (
          value.getValue()
        )}
      </span>
    );
  };

  const tableAction = (
    <Button
      variant="filled"
      color="primary"
      leftIcon={<Plus size={18} />}
      size="sm"
      onClick={() => navigateTo("/unidentified-receipt/creation", { state: { from: location.pathname } })}
    >
      Create Unidentified Receipt
    </Button>
  );

  const dueColumnData = [
    columnHelper.accessor("code", {
      header: "Code",
      cell: (value) => onClickAction(value, "text"),
    }),
    columnHelper.accessor("unidentified_receipt_amount", {
      header: <Text sx={{textAlign: 'right'}}>Receipt Amount</Text>,
      cell: (value) => onClickAction(value, "amount"),
    }),
    columnHelper.accessor("unidentified_receipt_date", {
      header: "Receipt Date",
      cell: (value) => onClickAction(value, "date"),
    }),
    columnHelper.accessor("maker_name", {
      header: "Maker Name",
      cell: (value) => value.getValue(),
    }),
  ];

  return (
    <Body background={false}>
      <DataTableWrapper
        title="Unidentified Receipt"
        rowData={receiptTable?.data}
        columnData={dueColumnData}
        onRowClick={onRowClick}
        noDataText="No unidentified Receipts yet!"
        noDataSubText="This section will contain list of created unidentified receipt details."
        useApiPagination
        totalNoOfPages= {Math.ceil(parseInt(moduleCount?.count)/records?.value)}
        totalNoOfRecords={moduleCount?.count}
        page={page}
        setPage={setPage}
        rowLength={records}
        setRowLength={setRecords}
        apiSearch={setSearch}
        actions={tableAction}
        showStatus
        module_name="unidentified_receipt"
        addDataCallback={() => navigateTo("/unidentified-receipt/creation", { state: { from: location.pathname } })}
        // when the status is none then the loading is set to false other wise it will dependent on isLoading
        loading={searchParams.get("status") !== "none" ? isLoading : searchParams.get("status") === null ? true : false}
        filter={false}
      />
    </Body>
  );
};

export default UnidentifiedReceiptTable;
