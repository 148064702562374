import React, { useEffect, useState } from 'react'
import { Tabs, Box, createStyles } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { eInvoiceCreation, eInvoiceDirectSave, eInvoiceStatusChange, eInvoiceUpdate, getAllRemarkseInvoice, geteInvoiceById, gstVerifyForInvoice } from '../../../services/e-invoice.service';
import { getStageforModules } from '../../../services/module.service';
import { IN_APPROVAL, IN_REVIEW, OPEN } from '../../../constants/status';
import { dateFormat } from '../../../components/DateFormat/dateFormat';
import { COLORS, STAGE_CHANGE_TEXT } from '../../../constants';
import GeneralInvoiceComp from './GeneralInvoiceComp';
import CommunicationInvoiceComp from './CommunicationInvoiceComp';
import InvoicingInvoiceComp from './InvoicingInvoiceComp';
import PaymentInvoiceComp from './PaymentInvoiceComp';
import ShippingInvoiceComp from './ShippingInvoiceComp';
import TaxInformationInvoiceComp from './TaxInformationInvoiceComp';
import ActionButtons from '../../../components/CommonComponents/ActionButtons/ActionButtons';
import ModalDataViwer from '../../../components/SucessfullModal/ModalDataViwer';
import ViewRemark from '../../../components/Remark/ViewRemark';
import Remarks from '../../../components/CommonComponents/Remarks/Remarks';
import { displayNotification } from '../../../components/CommonComponents/Notification/displayNotification';

const useStyles = createStyles({
  mainBox: {
    background: COLORS.white,
    borderRadius: 8,
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1)",

  },
  tabPanelRoot: {
    padding: '24px',
    marginTop: '10px'
  }
})

const placeholderStyle = {
  input: {
    '::placeholder': {
      fontSize: '11px'
    }
  }
}

const placeholderSelectStyle = {
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '13px',
    padding: '5px 15px 5px 15px'
  }),
  control: (provided) => ({
    ...provided,
    fontSize: '12px',
  }),
}

const schema = yup.object({
  gst_number: yup.string().required('Please Enter gst no'),
});

const CustomerCreationInvoiceTabs = ({ tabView, action }) => {

  const { createdInvoiceId } = useParams();
  const [selectedValue, setSelectedValue] = useState("general");
  const [remarksOpen, setRemarksOpen] = useState();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [sucessfulmodal, setSucessfulmodal] = useState({});
  const [remarksLoading, setRemarksLoading] = useState();
  const [gstNumber, setGSTNumber] = useState();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState();
  const [label, setLabel] = useState();
  const { classes } = useStyles();

  const eInvoiceDataQuery = useQuery({
    queryKey: ['eInvoiceData', createdInvoiceId],
    queryFn: () => geteInvoiceById({ id: createdInvoiceId }),
    enabled: Boolean(createdInvoiceId),
    initialData: {}
  })

  const remarksTrailQuery = useQuery({
    queryKey: ['remarksTrail', createdInvoiceId],
    queryFn: () => getAllRemarkseInvoice({ id: createdInvoiceId }),
    enabled: Boolean(createdInvoiceId),
    initialData: []
  })

  const getStagesQuery = useQuery({
    queryKey: ['stages', createdInvoiceId],
    queryFn: () => getStageforModules("external_customer"),
    enabled: Boolean(createdInvoiceId),
    select: (data) => {
      return {
        'current_stage': OPEN,
        'next_stage': data[1]
      }
    },
    initialData: []
  })

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      country: 'india',
      last_date_modified: new Date(),
    },
  });
  const value = watch();
  console.log(value);
  /* 
    This useEffect resets the form and fills default value to the resp fields.
  */
  useEffect(() => {
    if (Object.keys(eInvoiceDataQuery?.data).length) {
      reset(eInvoiceDataQuery?.data);
    }
  }, [eInvoiceDataQuery?.data]);

  const verifyGST = () => {
    if (value?.gst_number) {
      setButtonLoader({ gst: true })
      gstVerifyForInvoice({ gst: value.gst_number })
        .then((res) => {
          setGSTNumber(value?.gst_number)
          res?.data[0]?.name && setValue('name', res?.data[0]?.name);
          res?.data[0]?.email && setValue('email', res?.data[0]?.email);
          res?.data[0]?.mobile && setValue('mobile', res?.data[0]?.mobile);
          res?.data[0]?.address && setValue('address', res?.data[0]?.address);
          res?.data[0]?.city && setValue('city', res?.data[0]?.city);
          res?.data[0]?.post_code && setValue('post_code', res?.data[0]?.post_code);
          res?.data[0]?.state_code && setValue('state_code', res?.data[0]?.state_code);
        })
        .catch((err) => {
          displayNotification({ message: err?.message || err, variant: 'error' })
        })
        .finally(() => {
          setButtonLoader({ gst: false })
        })
    } else {
      displayNotification({ message: 'Please Enter GST No', variant: 'error' })
    }
  }

  /**
 * Saves data to the server. If the invoice has been previously created, it updates it, 
 * otherwise it creates a new one.
 *
 * @param {Object} data - the data to save
 */
  const onSave = (data) => {
    setButtonLoader({ save: true })
    const body = {
      ...value,
      last_date_modified: dateFormat(value?.last_date_modified, 'YYYY-MM-DD'),
      gst_number: gstNumber,
    }
    if (createdInvoiceId) {
      eInvoiceUpdate({ body, id: createdInvoiceId })
        .then((res) => {
          setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.update });
        })
        .catch((err) => {
          displayNotification({ message: err?.message || err, variant: 'error' })
        })
        .finally(() => {
          setButtonLoader({ save: false })
        })
    } else {
      eInvoiceCreation({ body })
        .then((res) => {
          setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.save });
        })
        .catch((err) => {
          displayNotification({ message: err?.message || err, variant: 'error' })
        })
        .finally(() => {
          setButtonLoader({ save: false })
        })
    }
  }

  /**
 * Handles the review modal by validating the schema after save.
 */
  const handleReviewModal = () => {
    schema.validate(value)
      .then(res => {
        setOpen(true);
        setStatus(IN_REVIEW);
        setLabel(getStagesQuery?.data?.next_stage == IN_APPROVAL ? 'Send for Approval' : 'Send for Review');
      })
      .catch(err => {
        displayNotification({ message: err?.errors, variant: 'error' })
      })
  }

  /**
 * Handles the submission of the review modal. 
 * If there is an existing invoice, it updates it and changes its status. 
 * If there is no invoice, it saves a new one. 
 */
  const onSendForReview = (remarks) => {
    const remarksBody = {
      remarks: remarks,
    };    
    const body = {
      ...value,
      last_date_modified: dateFormat(value?.last_date_modified, 'YYYY-MM-DD'),
      gst_number: gstNumber,
    }
    setRemarksLoading(true);
    if (createdInvoiceId) {
      eInvoiceUpdate({ body, id: createdInvoiceId })
        .then((res) => {
          eInvoiceStatusChange({ id: createdInvoiceId, body: remarksBody })
            .then((res) => {
              setSucessfulmodal({ open: true, title: getStagesQuery?.data?.next_stage == IN_APPROVAL ? STAGE_CHANGE_TEXT?.approval : STAGE_CHANGE_TEXT?.review });
            })
            .catch((err) => {
              displayNotification({ message: err?.message || err, variant: 'error' })
            })
        })
        .catch((err) => {
          displayNotification({ message: err?.message || err, variant: 'error' })
        })
        .finally(() => {
          setButtonLoader({ save: false })
          setRemarksLoading(false);
          setOpen(false);
        })
    } else {
      eInvoiceDirectSave({ body: { ...remarksBody, ...body } })
        .then((res) => {
          setSucessfulmodal({ open: true, title: getStagesQuery?.data?.next_stage == IN_APPROVAL ? STAGE_CHANGE_TEXT?.approval : STAGE_CHANGE_TEXT?.review });
        })
        .catch((err) => {
          displayNotification({ message: err?.message || err, variant: 'error' })
        })
        .finally(() => {
          setButtonLoader({ save: false })
          setRemarksLoading(false);
          setOpen(false);
        })
    }
  }

  return (
    <>
      <Tabs
        radius="xs"
        // orientation='vertical'
        value={selectedValue}
        onTabChange={setSelectedValue}
        color='orange'
        styles={(theme) => ({
          tab: {
            padding: '14px 16px',
            "&:is(:first-of-type)": {
              borderTopLeftRadius: 8,
            },
            "&:is(:last-of-type)": {
              borderTopRightRadius: 8,
            },
            "&:is([aria-selected=true])": {
              color: COLORS.orange(),
              fontWeight: 600,
              backgroundColor: COLORS.orange(6),
            },
          },
        })}
      >
        <Tabs.List grow>
          <Tabs.Tab value="general">General</Tabs.Tab>
          <Tabs.Tab value="communication">Communication</Tabs.Tab>
          <Tabs.Tab value="invoicing">Invoicing</Tabs.Tab>
          <Tabs.Tab value="payments">Payments</Tabs.Tab>
          <Tabs.Tab value="shipping">Shipping</Tabs.Tab>
          <Tabs.Tab value="tax_information">Tax Information</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="general" pt="xs" pb={0} className={classes?.tabPanelRoot}>
          <GeneralInvoiceComp control={control} errors={errors} setValue={setValue} placeholderStyle={placeholderStyle} verifyGST={verifyGST} gstLoading={buttonLoader?.gst} placeholderSelectStyle={placeholderSelectStyle} />
        </Tabs.Panel>

        <Tabs.Panel value="communication" pt="xs" pb={0} className={classes?.tabPanelRoot}>
          <CommunicationInvoiceComp control={control} errors={errors} setValue={setValue} placeholderStyle={placeholderStyle} placeholderSelectStyle={placeholderSelectStyle} />
        </Tabs.Panel>

        <Tabs.Panel value="invoicing" pt="xs" pb={0} className={classes?.tabPanelRoot}>
          <InvoicingInvoiceComp control={control} errors={errors} setValue={setValue} placeholderStyle={placeholderStyle} values={value} placeholderSelectStyle={placeholderSelectStyle} />
        </Tabs.Panel>

        <Tabs.Panel value="payments" pt="xs" pb={0} className={classes?.tabPanelRoot}>
          <PaymentInvoiceComp control={control} errors={errors} setValue={setValue} placeholderStyle={placeholderStyle} placeholderSelectStyle={placeholderSelectStyle} />
        </Tabs.Panel>

        <Tabs.Panel value="shipping" pt="xs" pb={0} className={classes?.tabPanelRoot}>
          <ShippingInvoiceComp control={control} errors={errors} setValue={setValue} placeholderStyle={placeholderStyle} placeholderSelectStyle={placeholderSelectStyle} />
        </Tabs.Panel>

        <Tabs.Panel value="tax_information" pt="xs" pb={0} className={classes?.tabPanelRoot}>
          <TaxInformationInvoiceComp control={control} errors={errors} setValue={setValue} placeholderStyle={placeholderStyle} placeholderSelectStyle={placeholderSelectStyle} />
        </Tabs.Panel>
      </Tabs>
      <Box px={'xl'}>
        <ActionButtons
          status={eInvoiceDataQuery?.data?.status_value ? eInvoiceDataQuery?.data?.status_value : OPEN}
          nextStage={getStagesQuery?.data?.next_stage || IN_APPROVAL}
          base={'creation'}
          sendForReviewCB={handleReviewModal}
          sendForApprovalCB={handleReviewModal}
          saveCB={handleSubmit(onSave)}
          loading={{ save: buttonLoader?.save }}
          remarks={remarksTrailQuery?.data}
          isReady={Boolean(getStagesQuery?.data?.next_stage) || true}
          currentStatus={eInvoiceDataQuery?.data?.status_value}
          moduleApplicationId={eInvoiceDataQuery?.data?.id}
          moduleName={'external_customer'}
          remarksCB={() => setRemarksOpen(true)}
        />
      </Box>

      <ModalDataViwer
        opened={sucessfulmodal?.open}
        label={eInvoiceDataQuery?.data?.customer_code?.code}
        title={sucessfulmodal?.title}
        action={action}
        href={tabView ? '' : '/external-customer'}
        hreftext={'Back to list table'}
      />

      <ViewRemark open={remarksOpen} onClose={setRemarksOpen} remarkTrail={remarksTrailQuery?.data} />

      <Remarks
        open={open}
        setOpen={setOpen}
        type={getStagesQuery?.data?.next_stage}
        callback={onSendForReview}
        remarksTitle={getStagesQuery?.data?.next_stage === IN_APPROVAL ? 'Approval Remarks' : 'Review Remarks'}
        check={status}
        label={label}
        loading={remarksLoading}
        currentStatus={eInvoiceDataQuery?.data?.status_value}
        moduleApplicationId={eInvoiceDataQuery?.data?.id}
        moduleName={'IMD'}
      />
    </>
  )
}

export default CustomerCreationInvoiceTabs