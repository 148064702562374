import { Box, Group, NumberInput } from '@mantine/core'
import Select, { components } from 'react-select';
import React from 'react'

const groupStyles = {
  borderBottom: `2px dotted gray`,
};

/**
 * Renders a group component with given props.
 * Used to style the group selection in react select component.
 * This is only applicable for group selection.
 *
 * @param {object} props - The props for the group component.
 * @return {JSX.Element} The rendered group component.
 */
const GroupSelect = (props) => (
  <div style={groupStyles}>
    <components.Group {...props} />
  </div>
);

const styles = {
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  option: (provided) => ({ ...provided, fontSize: '12px', padding: '4px 10px 4px 10px' }),
  control: (provided) => ({ ...provided, fontSize: '12px', border: '1px solid #ddd' }),
  valueContainer: (provided) => ({
    ...provided,
    maxHeight: '29px',
    padding: '0 6px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'initial',
  }),
  placeholder: (provided) => ({
    ...provided,
    position: 'absolute',
    bottom: '4px',
  })
};

// time filter for tat report
const TimeFilterTATReport = ({ value = [], onChange, time = 4, onTimeChange }) => {

  const options = [
    // { value: 'all', label: "All Difference" },
    {
      label: `Time Difference`, options: [
        { value: 'below', type: 'time', label: `Less then ${time}hr` },
        { value: 'above', type: 'time', label: `Greater then ${time}hr` },
      ]
    },
    {
      label: `Created Time`, options: [
        { value: 'work_hours', type: 'created', label: `On Office Hours` },
        { value: 'off_hours', type: 'created', label: `After Office Hours` },
      ]
    },
  ];

  // handling the select logic
  const handleValueChange = (selectedOption, actionMeta) => {
    let selectedValues = [...selectedOption];
    selectedValues?.pop();
    let valData = selectedValues?.map(i => i?.type);
    if (valData?.includes(actionMeta?.option?.type)) {
      onChange([...selectedValues?.filter(i => i?.type != actionMeta?.option?.type), actionMeta?.option]);
    }
    else {
      onChange(selectedOption);
    }
  }

  return (
    <Group spacing={'8px'}>
      <Box style={{ width: 170 }}>
        <Select
          value={value}
          options={options}
          styles={styles}
          onChange={(d, i) => handleValueChange(d, i)}
          isMulti
          placeholder={'Time filter'}
          hideSelectedOptions={false}
        />
      </Box>
      {value?.filter(i => i?.type === 'time')?.length ? <Box style={{ width: 60 }}>
        <NumberInput
          value={time}
          min={1}
          max={10}
          step={1}
          onChange={d => onTimeChange(d)}
        />
      </Box> : null}
    </Group>
  )
}

export default TimeFilterTATReport