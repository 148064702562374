import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { createStyles, Text, Box, Card } from "@mantine/core";
import Body from "../../components/Body/Body";
import { COLORS } from "../../constants";
import { useNavigate } from "react-router-dom"
import { getAllHistoricalReports } from "./utils/getReportStateByName";

const useStyles = createStyles({
  cardstyle: {
    cursor: "pointer",
    height: 120,
    width: 130,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&:hover": {
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 20px 30px",
    },
  },
  textStyle: {
    color: COLORS.primary.dark,
    textAlign: "center",
  },
});

const historicalReportList = getAllHistoricalReports()

const HistoricalReport = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Body background={false}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          marginBottom: 1,
          alignItems: "center",
        }}
      >
        <PageTitle title={"Historical Report"} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {historicalReportList?.map((item, index) => (
          <Card
            key={index}
            shadow="sm"
            padding="md"
            m="md"
            radius="md"
            sx={{ width: "150px" }}
            className={classes?.cardstyle}
            onClick={() => navigate(item?.href)}
          >
            <item.icon size={30} color={"gray"} strokeWidth={"1px"} />
            <Text mt={"20px"} size={"12px"} className={classes?.textStyle}>
              {item?.label}
            </Text>
          </Card>
        ))}
      </Box>
    </Body>
  );
};

export default HistoricalReport;
