import React from 'react'
import { Text, Box, createStyles, Tooltip } from '@mantine/core'
import { COLORS } from '../../constants';
import { useQuery } from '@tanstack/react-query';
import { getLatestTrancheDetails } from '../../services/customer.service';
import ReactTable from '../CommonComponents/Table/ReactTable';
import { createColumnHelper } from '@tanstack/react-table';
import { dateFormat } from '../DateFormat/dateFormat';
import Nums from '../Body/Nums';
import { LoanStatusToken } from '../CommonComponents/Preview/FilePreview';
import { LOAN_STATUS_COLORS } from '../../utils/StatusTable';
import dayjs from 'dayjs';

const useStyles = createStyles({
  card: {
    background: COLORS.white,
    borderRadius: "5px",
    border: `1px solid ${COLORS.border.light}`,
  },
})

const TrancheDetails = ({ customerId }) => {
  const { classes } = useStyles();
  const columnHelper = createColumnHelper();

  // getting the last 10 tranche details
  const getTranchesQuery = useQuery(
    ['latest-tranche-details', customerId],
    () => getLatestTrancheDetails({ customerId }),
    {
      enabled: Boolean(customerId),
    }
  );

  // table columns
  const columns = [
    columnHelper.accessor('prospect_code', {
      header: 'Prospect Code',
    }),
    columnHelper.accessor('disbursed_date', {
      header: 'Disbursed Date',
      cell: (value) => dateFormat(value.getValue(), 'DD MMM YYYY'),
    }),
    columnHelper.accessor('due_date', {
      header: 'Due Date',
      cell: (value) => dateFormat(value.getValue(), 'DD MMM YYYY'),
    }),
    columnHelper.accessor('loan_amount', {
      header: 'Loan Amount',
      cell: (value) => <Nums value={value.getValue()} />,
    }),
    columnHelper.accessor('outstanding_principle', {
      header: 'Outstanding Principal',
      cell: (value) => <Nums value={value.getValue()} />,
    }),
    columnHelper.accessor('loan_status', {
      header: 'Loan Status',
      cell: (value) => (
        <LoanStatusToken
          label={value.getValue()}
          color={LOAN_STATUS_COLORS?.[value.getValue()]}
        />
      )
    }),
    columnHelper.accessor('dpd', {
      header: 'DPD',
    }),
    columnHelper.accessor('receipt_date', {
      header: 'Receipt Date',
      cell: (value) => <Tooltip color='gray' label={`Closed ${dayjs(value.row.original?.closed_date).diff(new Date(value.row.original?.disbursed_date), 'day')} day's from disbursed`} disabled={!Boolean(value.getValue() && value.row.original?.loan_status === 'foreclosed')}><span>{dateFormat(value.getValue(), 'DD MMM YYYY')}</span></Tooltip>,
    }),
  ];

  return (
    <>
      <Text mb={'2px'} color={'gray'} size={'xs'}>
        Last 10 Tranche Details
      </Text>
      <Box p={'sm'} className={classes.card}>
        <ReactTable
          rowData={getTranchesQuery.data || []}
          columnData={columns}
          loading={getTranchesQuery?.isLoading}
          onRowClick={false}
        />
      </Box>
    </>
  )
}

export default TrancheDetails