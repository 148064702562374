import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { SYSTEM_CONFIG_STORE } from './storeKeys';
import { getLastDateofEOD } from '../services/eod.service';
import { ExclamationMark } from 'tabler-icons-react';
import { displayNotification } from '../components/CommonComponents/Notification/displayNotification';

const useSystemConfigStore = create(
  persist(
    (set) => {
      return {
        config: {},
        fetchSystemConfig: async () => {
          const config = await getLastDateofEOD().catch(err => {
            displayNotification({
              id: 'lms-system-date',
              title: 'Oops! Unable to get System Date!',
              variant: 'error',
              icon: <ExclamationMark />,
              autoClose: 2000,
              message: 'We are unable to fetch the system date. Please try again later.'
            })
          });
          set({ config })
        },
        resetSystemConfig: () => {
          set({ config: {} }, true)
        }
      }
    },
    {
      name: SYSTEM_CONFIG_STORE,
      storage: createJSONStorage(() => sessionStorage)
    }
  )
)

export default useSystemConfigStore;