import { ActionIcon, Box, FocusTrap, Image, Text, TextInput } from '@mantine/core'
import React, { useState } from 'react'
import PageTitle from '../../components/PageTitle/PageTitle'
import { searchLoanIdforPayment } from '../../services/paymentHistory.service';
import Body from '../../components/Body/Body';
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { useNavigate } from 'react-router-dom';
import { ArrowNarrowRight, Search } from 'tabler-icons-react';

const PaymentHistorySearch = ({ searchOpen, custName, custID }) => {

    const [search, setSearch] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const handleLoanIdSearch = () => {
        if(search?.length > 5) {
            setLoading(true)
            searchLoanIdforPayment(search)
                .then((res) => {
                    navigate(`/payment-history/${search}`, { state : res })
                })
                .catch((e) => {
                    displayNotification({ message: e?.message || e, variant: 'error' })
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

  return (
    <Body background={false}>
        <Box>
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 1,
                alignItems: 'center',
            }}
            >
            <PageTitle title={custName ? custName : 'Payment History'} id={custID && custID} />
            <Box
                sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                }}
            >
                {/** We can pass a components to use it in table as a name options */}
                {searchOpen ?
                    <TextInput
                        icon={<Search size={18} stroke-width={1.5} />}
                        radius="md"
                        value={search?.toUpperCase()}
                        onChange={(e) => setSearch(e.target.value?.toUpperCase())}
                        onKeyDown={(e) => {e.key === "Enter" && handleLoanIdSearch()}}
                        size="md"
                        rightSection={
                            <ActionIcon size={32} radius="xl" onClick={handleLoanIdSearch} loading={loading}>
                                <ArrowNarrowRight size={18} color={'blue'} stroke-width={1.5} />
                            </ActionIcon>
                        }
                        placeholder='Enter Tranche Code'
                        rightSectionWidth={42}
                    /> : null
                }
            </Box>        
            </Box>
            {!searchOpen ?
                <Box>
                    <Box
                    mt='md'
                    p='xl'
                    sx={{
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: 10,
                        height: '60vh'
                    }}
                    >
                    <Image
                        src='https://i.imgur.com/A6KRQAV.png'
                        width={150}
                        height={100}
                        radius='md'
                    />
                    <Box>
                        <Text size='sm' sx={{ color: 'rgb(0,0,0,0.4)' }}>{'Please enter Tranche Code to get payment history'}</Text>                        
                        <FocusTrap active={true}>
                            <TextInput
                                icon={<Search size={18} stroke-width={1.5} />}
                                radius="md"
                                value={search?.toUpperCase()}
                                onKeyDown={(e) => {e.key === "Enter" && handleLoanIdSearch()}}
                                onChange={(e) => setSearch(e.target.value)}
                                size="md"
                                rightSection={
                                    <ActionIcon size={32} radius="xl" onClick={handleLoanIdSearch} loading={loading}>
                                        <ArrowNarrowRight size={18} color={'blue'} stroke-width={1.5} />
                                    </ActionIcon>
                                }
                                placeholder='Enter Tranche Code'
                                rightSectionWidth={42}
                            />
                        </FocusTrap>
                    </Box>
                </Box>
            </Box> : null
            }
        </Box>
    </Body>
  )
}

export default PaymentHistorySearch
