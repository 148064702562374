import { Text } from '@mantine/core';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getApprovedDisb } from '../../services/payment.service';
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import Filter from '../../components/CommonComponents/Filter/Filter';
import Nums from '../../components/Body/Nums';
import SimpleReactTable from '../../components/CommonComponents/Table/SimpleReactTable';
import { createColumnHelper } from '@tanstack/react-table';
import { DrawerModal } from '../../components/CommonComponents/Drawer/DrawerModal';

const PaymentDrawer = ({ open, onClose }) => {

  const columnHelper = createColumnHelper();
  const location = useLocation();
  const [filter, setFilter] = useState(true)
  const [loading, setLoading] = useState()
  const [PaymentData, setPaymentData] = useState([])
  const [filterDetails, setFilterDetails] = useState([]);

  const navigate = useNavigate();

  const onSubmit = (values, filterData) => {
    setFilterDetails(filterData)
    setLoading(true)
    getApprovedDisb({...values})
      .then((res) => {
        setPaymentData(res)
      })
      .catch((e) => {
        setPaymentData()
        displayNotification({id: 'customer-imd', message: e?.message || e, variant: 'error'})
      })
      .finally(() => {
        setLoading(false)
        setFilter(false)
      })
  }

  const columns = [
    columnHelper.accessor('prospect_code', {
      header: 'Tranche Code',
    }),
    columnHelper.accessor('customer_name', {
      header: 'Name',
    }),
    columnHelper.accessor('disbursed_amount', {
      header: <Text sx={{textAlign: 'right'}}>Disbursed Amount</Text>,
      cell: (value) =>  <Nums value={value.getValue()} styles={{textAlign: 'right'}} />,
    }),
  ];
  
  return (
    <DrawerModal
      opened={open}
      onClose={() => onClose(false)}
      title="Find Payment Details"
      position='right'
      padding='xl'
      size='50%'
    >
    {/* Used to show filter */}
    { filter ?
      <Filter 
        callback={onSubmit} 
        onClose={onClose} 
        open={open}
        loading={loading} 
        type={'payment'} 
      /> :
      <SimpleReactTable
        rowData={PaymentData}
        columnData={columns}
        filterData={filterDetails}
        setFilter={setFilter}
        showFilter
        sx={{height: '70vh'}}
        rowKeyGetter={(row) => {
          return row?.id;
        }}
        onRowClick={(i, o) => navigate(`/payment/${i?.customer_id}/${i?.id}`, { state: { from: location.pathname } })}
      />
    }
    </DrawerModal>
  );
};

export default PaymentDrawer;