import React from 'react'
import { Grid, Box } from '@mantine/core';
import DataViewerWapper from '../../../components/CommonComponents/Preview/DataViewerWapper';
import TitleViewer from '../../../components/CommonComponents/Title/TitleViewer';
import { ViewFormData } from '../../../components/CommonComponents/Preview/FilePreview';

/**
 * Renders a component displaying payment details for an invoice.
 *
 * @param {object} data - An object containing payment details.
 */
const PaymentsInvoiceReviewComp = ({data}) => {
  return (
    <>
      <Grid gutter={'md'}>
        <Grid.Col span={6}>
          <DataViewerWapper>
            <Box mb={'sm'}>
              <TitleViewer title={"Payments Details"} />
            </Box>
            <ViewFormData
              label={'Application Method'}
              value={data?.data?.application_method}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Partner Type'}
              value={data?.data?.partner_type}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Payment Terms Code'}
              value={data?.data?.payment_terms_code}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Payment Method Code'}
              value={data?.data?.payment_method_code}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Reminder Terms Code'}
              value={data?.data?.reminder_terms_code}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Fin. Charge Terms Code'}
              value={data?.data?.fin_charge_terms_code}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Cash Flow Payment Terms Code'}
              value={data?.data?.cash_flow_payment_terms_code}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Print Statements'}
              value={data?.data?.print_statements ? 'Yes' : 'No'}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Last Statement No.'}
              value={data?.data?.last_statement_no}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Block Payment Tolerance'}
              value={data?.data?.block_payment_tolerance ? 'Yes' : 'No'}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Preferred Bank Account'}
              value={data?.data?.preferred_bank_account}
              loading={data?.isLoading}
            />
          </DataViewerWapper>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default PaymentsInvoiceReviewComp