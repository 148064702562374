import React, { useEffect, useState } from "react";
import { Box, createStyles, Grid, SegmentedControl, Paper, Title } from "@mantine/core";
import DashboardLoanStats from "./DashboardComp/DashboardLoanStats";
import { useQuery } from "@tanstack/react-query";
import {
  getAllEntities,
  getCreditBookDetails,
  getCreditBookDetailsDynamic,
  getHistoricalNPA,
} from "../services/dashboard.service";
import SplineChart from "./DashboardComp/SplineChart";
import DataTableWrapper from "../components/CommonComponents/Table/DataTableWrapper";
import useSystemConfigStore from "../store/systemConfigStore";
import { useDebouncedValue } from "@mantine/hooks";
import management_dashboard_headers from "./managementDashboardHeaders";

const createdStyles = createStyles({
  card: {
    maxWidth: "1350px",
    margin: "auto",
  },
  creditBookHeader: {
    display: "flex",
    alignItems: "center",
    borderTopLeftRadius: "15px",
    gap: "20px",
  },
});

const Home = () => {
  const systemDate = useSystemConfigStore((store) => store.config);
  const { classes } = createdStyles();
  const [creditBook, setCreditBook] = useState('100');
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [records, setRecords] = useState({ label: "Show 10", value: 10 });
  const [deBounceSearch] = useDebouncedValue(search?.length > 3 ? search : '', 700);
  const [sorting, setSorting] = useState([{
    "id": "max_dpd",
    "desc": true
  }]);
  const { data: entities = [{ label: "All", value: '100' }] } = useQuery(
    ["entities"],
    () => getAllEntities(),
    {
      select: (data) => {
        let val = {
          value: '100',
          label: "All",
        };
        let entities = data.map((data) => {
          return {
            value: data.value?.toString(),
            label: data.label,
          };
        })
        return [val, ...entities];
      },
      refetchOnWindowFocus: false,
    }
  );

  const historical_nap_detail_stats = useQuery({
    queryKey: ["historical-npa-table", deBounceSearch, page, records, sorting, creditBook],
    queryFn: () => getHistoricalNPA({ from: systemDate?.current_system_date, filter: 'npa_details', page, records: records?.value, search: deBounceSearch, orderBy: sorting?.[0]?.desc === true ? 'desc' : 'asc', entity: creditBook == 100 ? '' : creditBook }),
    enabled: Boolean(systemDate?.current_system_date),
  });

  useEffect(() => {
    page != 1 && setPage(1);
  }, [records, search])

  return (
    <>
      <Box className={classes.card} p={0}>
        <Grid gutter="md">
          <Grid.Col span={12}>
            <Box>
              <SegmentedControl
                value={creditBook}
                data={entities}
                size={"xs"}
                color="blue"
                styles={(theme) => ({
                  root: {
                    backgroundColor: "white",
                    border: "1px solid #e2e2e2",
                  },
                })}
                onChange={setCreditBook}
                transitionDuration={500}
                transitionTimingFunction="linear"
              />
            </Box>
          </Grid.Col>

          <Grid.Col span={6}>
            <DashboardLoanStats
              entity={parseInt(creditBook)}
              type={"Opening"}
              dataProp={getCreditBookDetailsDynamic}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <DashboardLoanStats
              entity={parseInt(creditBook)}
              type={"Current"}
              dataProp={getCreditBookDetails}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <Paper p={'md'}>
              <DataTableWrapper
                addDataOption={false}
                rowData={historical_nap_detail_stats?.data?.data}
                styles={{ overflowX: "auto", whiteSpace: "nowrap", maxWidth: "100vw" }}
                columnData={management_dashboard_headers?.npa || []}
                useApiPagination
                title={<Title order={5}>NPA Details</Title>}
                breadcrumb={false}
                totalNoOfPages={historical_nap_detail_stats?.data?.total_number_of_pages}
                totalNoOfRecords={historical_nap_detail_stats?.data?.total_number_of_records}
                noDataSubText='There is no NPA loans found.'
                page={page}
                filter={false}
                setPage={setPage}
                apiSearch={setSearch}
                rowLength={records}
                setRowLength={setRecords}
                sorting={sorting}
                setSorting={setSorting}
                allowSorting={true}
                isSortingRemoval={false}
                loading={historical_nap_detail_stats?.isLoading}
              />
            </Paper>
          </Grid.Col>

          {/* This section is hided for now, Said by client */}
          {/* <Grid.Col span={7}>
            <RegionOverdueChart entity={parseInt(creditBook)} />
          </Grid.Col>

          <Grid.Col span={5}>
            <DPDWiseChart entity={parseInt(creditBook)} />
          </Grid.Col>

          <Grid.Col span={6}>
            <RegionvsDPDWiseChart entity={parseInt(creditBook)} />
          </Grid.Col>

          <Grid.Col span={6}>
            <OMCvsDPDWiseChart entity={parseInt(creditBook)} />
          </Grid.Col> */}

          <Grid.Col span={12}>
            <SplineChart entity={parseInt(creditBook)} />
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};

export default Home;
