import { Box, Button, Grid, Image } from "@mantine/core";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Body from "../../components/Body/Body";
import DataViewerWapper from "../../components/CommonComponents/Preview/DataViewerWapper";
import { ViewFormData } from "../../components/CommonComponents/Preview/FilePreview";
import TitleViewer from "../../components/CommonComponents/Title/TitleViewer";
import PageTitle from "../../components/PageTitle/PageTitle";
import { GENERAL_IMG, STAGE_CHANGE_TEXT } from "../../constants";
import ImdStyle from "../IMD/Imd.styles";
import Remarks from "../../components/CommonComponents/Remarks/Remarks";
import ViewRemark from "../../components/Remark/ViewRemark";
import Nums from "../../components/Body/Nums";
import ModalDataViwer from "../../components/SucessfullModal/ModalDataViwer";
import IdBadge from "../../components/CommonComponents/Preview/IdBadge";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { useQuery } from "@tanstack/react-query";
import ActionButtons from "../../components/CommonComponents/ActionButtons/ActionButtons";
import AccountingInterfaceModal from "../../components/CommonComponents/AccuntingInterface/AccountingInterfaceModal";
import { getStageforModules } from "../../services/module.service";
import {
  APPROVED,
  CANCELLATION_APPROVAL,
  CANCELLATION_REVIEW,
  IN_APPROVAL,
  IN_REVIEW,
} from "../../constants/status";
import {
  getUnidentifiedReceiptById,
  getUnidentifiedReceiptRemarksById,
  identifiedReceiptDetailsById,
  sendUnidentifiedReceiptForNextStage,
  sendUnidentifiedReceiptForPushback,
  sendUnidentifiedReceiptForRejected,
  sendUnidentifiedReceiptFroCancellation
} from '../../services/unidentifiedReceipt.service';
import { ArrowRight } from "tabler-icons-react";
import DashBoardCustomerDetails from '../../components/DashboardCard/dashboard';

const UnidentifiedReceiptReview = ({ action = true, home = "/unidentified-receipt" }) => {
  const navigate = useNavigate();
  const { createdUnReceiptId } = useParams();
  // set remarks modal open or close as boolean
  const [open, setOpen] = useState(false);
  // handling the status
  const [status, setStatus] = useState();
  // handling the remarks modal loading
  const [remarksLoading, setRemarksLoading] = useState();
  // handling the successful modal [contain the modal open or close and title]
  const [sucessfulmodal, setSucessfulmodal] = useState({});
  // handling the remarks modal label
  const [label, setLabel] = useState();
  // handling the remarks modal
  const [remarksOpen, setRemarksOpen] = useState(false);
  // handle the remarks title
  const [remarksTitle, setRemarksTitle] = useState();
  // handling the accounting interface modal
  const [interfaceOpen, setInterfaceOpen] = useState(false);

  // get unidentified receipt details by id
  const { data: receiptDetails = [], isLoading } = useQuery(
    ["unidentified-receipt-by-id", createdUnReceiptId],
    () => createdUnReceiptId && getUnidentifiedReceiptById(createdUnReceiptId),
    {
      select: (data) => {
        return data[0]
      },
      refetchOnWindowFocus: false
    }
  );

  const identifiedReceiptDetails = useQuery(
    ['receipt-by-id', createdUnReceiptId],
    () => identifiedReceiptDetailsById(createdUnReceiptId),
    {
      enabled: Boolean(receiptDetails?.is_unidentified_receipt_closed),
      cacheTime: 0,
    }
  )

  // used to get the available stages
  const { data: stages = [] } = useQuery(
    ["receipt-stages"],
    () => getStageforModules("unidentified_receipt"),
    {
      enabled: Boolean(receiptDetails?.status),
      select: (dataItem) => {
        return {
          current_stage: receiptDetails?.status_value,
          next_stage:
            dataItem[dataItem.indexOf(receiptDetails?.status_value) + 1],
        };
      },
      refetchOnWindowFocus: false,
    }
  );

  // get the unidentified receipt remarks by id
  const { data: reviewTrail = [] } = useQuery(
    ["remarks", createdUnReceiptId],
    () => getUnidentifiedReceiptRemarksById(createdUnReceiptId),
    { refetchOnWindowFocus: false }
  );

  // send the unidentified receipt for next stage
  const onSendReceiptforNextStage = (remarks, status) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true);
    sendUnidentifiedReceiptForNextStage(createdUnReceiptId, body)
      .then((data) => {
        setSucessfulmodal({
          open: true,
          title:
            status == IN_REVIEW
              ? STAGE_CHANGE_TEXT?.approval
              : status == IN_APPROVAL
                ? STAGE_CHANGE_TEXT?.approved
                : status == CANCELLATION_REVIEW
                  ? STAGE_CHANGE_TEXT?.reviewCancel
                  : STAGE_CHANGE_TEXT?.approveCancel,
        });
      })
      .catch((e) => {
        displayNotification({
          id: "receipt-approve",
          message: e?.message || e,
          variant: 'error',
        });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      });
  };

  // used to handle the pushback
  const handlePushback = (remarks) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true);
    sendUnidentifiedReceiptForPushback(createdUnReceiptId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.pushback });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      });
  };

  // used to handle the reject
  const handleReject = (remarks) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true);
    sendUnidentifiedReceiptForRejected(createdUnReceiptId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.reject });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      });
  };

  // used to handle the cancellation
  const handleCancel = (remarks, reason) => {
    const body = {
      remarks,
      reason: reason?.reason,
    };
    setRemarksLoading(true);
    sendUnidentifiedReceiptFroCancellation(createdUnReceiptId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.cancel });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      });
  };

  // used to call the function by status from the remarks component and pass the remarks and assignedTo to the function
  const callFunction = (remarks, status, reason) => {
    switch (status) {
      case "review":
      case "approval":
      case "cancellation":
        onSendReceiptforNextStage(remarks, status);
        break;
      case "reject":
        handleReject(remarks);
        break;
      case "pushback":
        handlePushback(remarks);
        break;
      case "cancel":
        handleCancel(remarks, reason);
        break;
      default:
        displayNotification({
          message: "Something went wrong, Please contact support!",
          variant: 'error',
        });
        break;
    }
  };

  // used to navigate to the identified receipt
  const viewIdentifiedReceipt = (i) => {
    if (i?.status_value) {
      if (i?.status_value == APPROVED) {
        i?.receipt_type?.toLowerCase() === 'rec' && navigate(`/receipt/approved/${i?.customer_id}/${i?.receipt_id}`);
        i?.receipt_type?.toLowerCase() === 'clr' && navigate(`/customer-receipt/approved/${i?.customer_id}/${i?.receipt_id}`);
        i?.receipt_type?.toLowerCase() === 'imd' && navigate(`/fee-invoice/approved/${i?.receipt_id}/${i?.customer_id}`);
      } else if (i?.status_value == CANCELLATION_REVIEW || i?.status_value == CANCELLATION_APPROVAL) {
        i?.receipt_type?.toLowerCase() === 'rec' && navigate(`/cancellation/receipt-cancel/${i?.receipt_id}/${i?.customer_id}`);
        i?.receipt_type?.toLowerCase() === 'clr' && navigate(`/customer-receipt/cancelled/${i?.customer_id}/${i?.receipt_id}`);
        i?.receipt_type?.toLowerCase() === 'imd' && navigate(`/fee-invoice/cancelled/${i?.receipt_id}/${i?.customer_id}`);
      }
    }
  };

  return (
    <>
      <Body background={false}>
        {/* name and id has send as props to display in the page */}
        <PageTitle title={"Unidentified Receipt"} />
      </Body>
      {identifiedReceiptDetails?.data?.customer_id ? <DashBoardCustomerDetails id={identifiedReceiptDetails?.data?.customer_id} /> : null}
      <Box sx={{ position: "relative" }}>
        <Body>
          {/* receipt name is send as props to display and status value is send to change the color of the badge */}
          {receiptDetails?.code && (
            <div style={ImdStyle.flag}>
              <IdBadge
                remarks={receiptDetails?.status_value}
                label={receiptDetails?.code}
              />
            </div>
          )}
          <Grid gutter="sm" mt={"md"}>
            <Grid.Col span={4}>
              {identifiedReceiptDetails?.data?.code
                ? <DataViewerWapper>
                  <TitleViewer title={identifiedReceiptDetails?.data?.receipt_type?.toLowerCase() === 'imd' ? "Fee Invoice (Mapped)" : "Receipt Details (Mapped)"}>
                    <Button
                      variant="subtle"
                      compact
                      size="xs"
                      rightIcon={<ArrowRight size={12} />}
                      onClick={
                        () => viewIdentifiedReceipt(identifiedReceiptDetails?.data)
                      }
                    >
                      {identifiedReceiptDetails?.data?.receipt_type?.toLowerCase() === 'imd' ? 'View Fee Invoice' : 'View Receipt'}
                    </Button>
                  </TitleViewer>
                  <Grid.Col span={12} p={0} mt={'md'}>
                    <ViewFormData
                      label={identifiedReceiptDetails?.data?.receipt_type?.toLowerCase() === 'imd' ? "Invoice Code" : "Receipt Code"}
                      value={identifiedReceiptDetails?.data?.code}
                      loading={identifiedReceiptDetails?.isLoading}
                    />
                  </Grid.Col>
                  <Grid.Col span={12} p={0}>
                    <ViewFormData
                      label="Customer Name"
                      value={identifiedReceiptDetails?.data?.customer_name}
                      loading={identifiedReceiptDetails?.isLoading}
                    />
                  </Grid.Col>
                  <Grid.Col span={12} p={0}>
                    <ViewFormData
                      label="Customer Id"
                      value={identifiedReceiptDetails?.data?.customer_id}
                      loading={identifiedReceiptDetails?.isLoading}
                    />
                  </Grid.Col>
                </DataViewerWapper>
                : <Grid.Col
                  span={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    src={GENERAL_IMG}
                    alt="Illustration"
                  />
                </Grid.Col>
              }
            </Grid.Col>
            <Grid.Col span={4}>
              {/* DataViewerWapper component is used to display the data inside the bule background */}
              <DataViewerWapper>
                <Grid.Col span={12}>
                  <ViewFormData
                    label="Receipt No"
                    value={receiptDetails?.id}
                    loading={isLoading}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewFormData
                    label="Receipt Date"
                    value={dateFormat(
                      receiptDetails?.unidentified_receipt_date,
                      "DD/MM/YYYY"
                    )}
                    loading={isLoading}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewFormData
                    label="Receipt Amount"
                    value={
                      <Nums withTooltip withCopy value=
                        {receiptDetails?.unidentified_receipt_amount}
                      />
                    }
                    loading={isLoading}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewFormData
                    label="Instrument Date"
                    value={dateFormat(
                      receiptDetails?.instrument_date,
                      "DD/MM/YYYY"
                    )}
                    loading={isLoading}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewFormData
                    label="Instrument Type"
                    value={receiptDetails?.instrument_type?.toUpperCase()}
                    loading={isLoading}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewFormData
                    label="Payment Ref.No"
                    value={receiptDetails?.payment_ref_no}
                    loading={isLoading}
                  />
                </Grid.Col>
              </DataViewerWapper>
            </Grid.Col>
            <Grid.Col span={4}>
              <DataViewerWapper>
                <Grid.Col span={12}>
                  <TitleViewer title="Bank Details" />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewFormData
                    label="Bank Name"
                    value={receiptDetails?.target_bank_name}
                    loading={isLoading}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewFormData
                    label="Account No"
                    value={receiptDetails?.target_bank_no}
                    loading={isLoading}
                  />
                </Grid.Col>
              </DataViewerWapper>
            </Grid.Col>
          </Grid>
          {/** This Action buttons component is used to display the buttons in the flow */}
          <ActionButtons
            status={stages?.current_stage}
            nextStage={stages?.next_stage}
            isReady={Boolean(stages?.next_stage)}
            remarks={reviewTrail}
            accountingCB={() => setInterfaceOpen(true)}
            sendForApprovalCB={() => {
              setOpen(true);
              setRemarksTitle("Approval Remarks");
              setStatus("review");
              setLabel("Send for Approval");
            }}
            approvedCB={() => {
              setOpen(true);
              setRemarksTitle("Approval Remarks");
              setStatus("approval");
              setLabel("Approve");
            }}
            pushbackCB={() => {
              setOpen(true);
              setRemarksTitle("Pushback Remarks");
              setStatus("pushback");
              setLabel("Pushback");
            }}
            rejectCB={() => {
              setOpen(true);
              setRemarksTitle("Reject Remarks");
              setStatus("reject");
              setLabel("Reject");
            }}
            remarksCB={() => setRemarksOpen(true)}
            cancelCB={() => {
              setOpen(true);
              setRemarksTitle("Cancellation Remarks");
              setStatus("cancellation");
              setLabel("Send for Cancellation");
            }}
            approveCancelCB={() => {
              setOpen(true);
              setRemarksTitle("Are you sure?");
              setStatus("cancel");
              setLabel("Confirm");
            }}
            currentStatus={receiptDetails?.status_value}
            moduleApplicationId={receiptDetails?.id}
            moduleName={'unidentified_receipt'}
          />

          {/** used to display the modal for accounting data */}
          <AccountingInterfaceModal
            open={interfaceOpen}
            close={() => setInterfaceOpen(false)}
            id={createdUnReceiptId}
            name={"unidentified_receipt"}
            code={receiptDetails?.code}
          />

          {/* remarks component is used to get remarks from user */}
          <Remarks
            open={open}
            setOpen={setOpen}
            type={stages?.next_stage}
            callback={callFunction}
            remarksTitle={remarksTitle}
            moduleName={"unidentified_receipt"}
            check={status}
            label={label}
            loading={remarksLoading}
            currentStatus={receiptDetails?.status_value}
            moduleApplicationId={receiptDetails?.id}
          />
          {/* ModalDataViwer is used to display the status on sucess stage change */}
          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={receiptDetails?.code}
            title={sucessfulmodal?.title}
            href={home}
            hreftext={"Back to list table"}
          />
        </Body>
        {/* viewremarks is used to display remarks */}

        <ViewRemark
          open={remarksOpen}
          onClose={setRemarksOpen}
          remarkTrail={reviewTrail}
        />
      </Box>
    </>
  );
};

export default UnidentifiedReceiptReview;
