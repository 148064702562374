import { Grid, Stack, Text, Title } from '@mantine/core'
import React from 'react'
import ControllerTextField from '../../../components/CommonComponents/TextField/ControllerTextField';
import ControllerSwitch from '../../../components/CommonComponents/TextField/ControllerSwitch';
import { COLORS } from '../../../constants';

/**
 * Renders a shipping invoice component with shipping details.
 *
 * @param {object} control - Controller object for the form input.
 * @param {object} errors - Object containing error messages for the form input.
 * @param {function} setValue - Function to set the value of the form input.
 * @param {object} placeholderStyle - Styles for the input placeholder.
 */
const ShippingInvoiceComp = ({ control, errors, setValue, placeholderStyle }) => {
  return (
    <Grid gutter={'md'} sx={{maxHeight:"55vh",overflow:'hidden',overflowY: 'auto'}}>
      <Grid.Col span={12} sx={{ height: 'fit-content' }}>
        <Title order={6} mb={'md'}>Shipping Details</Title>
        <Grid gutter={'md'}>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Location Code</Text>
              <ControllerTextField
                name={'location_code'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Location Code'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.location_code?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Reserve</Text>
              <ControllerTextField
                name={'reserve'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Reserve'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.reserve?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Shipping Advice</Text>
              <ControllerTextField
                name={'shipping_advice'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Shipping Advice'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.shipping_advice?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Shipment Method Code</Text>
              <ControllerTextField
                name={'shipment_method_code'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Shipment Method Code'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.shipping_method_code?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Shipping Agent Code</Text>
              <ControllerTextField
                name={'shipping_agent_code'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Shipping Agent Code'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.shipping_agent_code?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Shipping Agent Service Code</Text>
              <ControllerTextField
                name={'shipping_agent_service_code'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Shipping Agent Service Code'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.shipping_agent_service_code?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Shipping Time</Text>
              <ControllerTextField
                name={'shipping_time'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Shipping Time'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.shipping_time?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Base Calendar Code</Text>
              <ControllerTextField
                name={'base_calendar_code'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Base Calendar Code'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.base_calendar_code?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={3} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Combine Shipments</Text>
              <ControllerSwitch
                name={'combine_shipments'}
                displayLabel={false}
                spanSize={12}
                control={control}
                onLabel="Yes"
                offLabel="No"
                size={'md'}
                color="green"
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={3} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Customized Calendar</Text>
              <ControllerSwitch
                name={'customized_calendar'}
                displayLabel={false}
                spanSize={12}
                control={control}
                onLabel="Yes"
                offLabel="No"
                size={'md'}
                color="green"
              />
            </Stack>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  )
}

export default ShippingInvoiceComp