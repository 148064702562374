import {
  Box,
  Button,
  Divider,
  Grid,
  SegmentedControl,
  Switch,
  Table,
  Text,
  Title,
  Image,
  ActionIcon,
  Tooltip,
  Center,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import Body from '../../components/Body/Body';
import ControllerDatePicker from '../../components/CommonComponents/TextField/ControllerDatePicker';
import ControllerSelect from '../../components/CommonComponents/TextField/ControllerSelect';
import ControllerTextField from '../../components/CommonComponents/TextField/ControllerTextField';
import FormLabel from '../../components/CommonComponents/TextField/FormLabel';
import TitleViewer from '../../components/CommonComponents/Title/TitleViewer';
import DashBoardCustomerDetails from '../../components/DashboardCard/dashboard';
import PageTitle from '../../components/PageTitle/PageTitle';
import { COLORS, STAGE_CHANGE_TEXT } from '../../constants';
import Nums from '../../components/Body/Nums';
import { customerBankDetails, CompanyBankDetails } from '../../services/imd.service';
import ControllerCurrencyInput
  from '../../components/CommonComponents/TextField/ControllerCurrencyInput';
import {
  createReceipt,
  updateReceipt,
  getAllReceiptsById,
  getReceiptRemarks,
  getAppropriationLogic,
  sendReceiptforNextStage,
  saveReceiptAndsendForNextStage,
  getEligibleReceiptLoanbyId
} from '../../services/receipt.service';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import Remarks from '../../components/CommonComponents/Remarks/Remarks';
import ViewRemark from '../../components/Remark/ViewRemark';
import ModalDataViwer from '../../components/SucessfullModal/ModalDataViwer';
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import IdBadge from '../../components/CommonComponents/Preview/IdBadge';
import ImdStyle from '../IMD/Imd.styles';
import { currencyAsFloat } from '../../components/Currency/currencyFormat';
import dayjs from 'dayjs';
import ActionButtons
  from '../../components/CommonComponents/ActionButtons/ActionButtons';
import { instrumentType } from '../../utils/InstrumentTypes';
import { ViewData } from '../../components/CommonComponents/Preview/FilePreview';
import { getStageforModules } from '../../services/module.service';
import UnidentifiedReceiptListModal from './UnidentifiedReceiptListModal';
import {
  getEligibleUnidentifiedReceiptByLoanId,
  unidentifiedReceiptEligibleCheck
} from '../../services/unidentifiedReceipt.service';
import DialogModal from './../../components/CommonComponents/Modal/DialogModal';
import useSystemConfigStore from '../../store/systemConfigStore';
import { FileInfo } from 'tabler-icons-react';
import { PAYMENT_REF_NO_LIMIT } from '../../constants/validationCheck';
import { getSignedUrl } from '../../services/common.service';
import { getBusinessBanks } from '../../services/banks.service';
import { isAllowed } from '../../utils/roleBaseAccess';
import useAuthStore from '../../store/authStore';
import { action_id } from '../../config/userRules';

const schema = yup.object({
  receipt_date: yup.string().nullable().required('Please select receipt Date'),
  excess_amount: yup.number().nullable(),
  receipt_amount: yup.string().when(['excess_amount'], {
    is: (excess_amount) =>
      excess_amount <= 0,
    then: () => yup.string().required('Please Enter Receipt Amount')
  }),
  instrument_date: yup.string().nullable().required('Please select instrument Date'),
  instrument_type: yup.string().nullable().required('Please select instrument type'),
  target_bank_id: yup.string().required('Please select Bank'),
});

/** 1Lack is the min amount to show the option to create a revolving loan */
const min_prin_renewal_amount = 100000;

const saveSchema = schema.shape({
  source_bank_id: yup.string().nullable()
    .when(['instrument_type'], {
      is: (instrument_type) => instrument_type != 'cash',
      then: () => yup.string().nullable().required('Please select customer bank'),
    }),
  payment_ref_no: yup.string()
    .max(parseInt(PAYMENT_REF_NO_LIMIT), `payment no must be less then ${PAYMENT_REF_NO_LIMIT} characters`)
    .nullable()
    .when(['instrument_type'], {
      is: (instrument_type) => instrument_type != 'cash',
      then: () => yup.string().nullable().required('Please Enter Payment Ref No'),
    }),
});

const ReceiptCreation = ({ formType }) => {
  const { receiptId, loanId, createdReceiptId } = useParams();
  const systemDate = useSystemConfigStore(store => store.config);
  const auth = useAuthStore(store => store.auth);
  const [open, setOpen] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [status, setStatus] = useState();
  const [remarksLoading, setRemarksLoading] = useState();
  const [sucessfulmodal, setSucessfulmodal] = useState({});
  const [label, setLabel] = useState();
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [fieldType, setFieldType] = useState('auto');
  const [checked, setChecked] = useState(false);
  const [appropriation, setAppropriation] = useState();
  const [disbReceipt, setDisbReceipt] = useState({})
  const [modalObj, setModalObj] = useState({});
  const [unidentifiedReceipt, setUnidentifiedReceipt] = useState({});
  const [signedUrl, setSignedUrl] = useState();

  /** used to get aprticular receipt values by sending the loan id and receipt date */
  const loanDetails = useMutation(
    (loanId, body) => getEligibleReceiptLoanbyId(loanId, body),
  )

  /** used to get the created receipt details by sending receipt id */
  const { data: receiptDetails = [] } = useQuery(
    ['receipt-details', createdReceiptId],
    () => createdReceiptId && getAllReceiptsById(createdReceiptId),
    {
      onSuccess: (data) => {
        setFieldType(data?.appropriation_application_mode);
        setChecked(data?.tds);
      },
      refetchOnWindowFocus: false
    }
  )

  /** getting the unidentified receipt list */
  const { data: UnidentifiedReceiptData = [] } = useQuery(
    ["unidentified-receipt"],
    () => getEligibleUnidentifiedReceiptByLoanId({ loanId, receiptId: createdReceiptId }),
    {
      select: (response) => {
        return response.map((item) => ({
          ...item,
          search_key: Object.values(item).join().toLowerCase(),
        }));
      },
      enabled: Boolean(formType === 'view' ? receiptDetails?.id : true),
      refetchOnWindowFocus: false,
    }
  );

  const { data: stages = [] } = useQuery(
    ['receipt-stage', 'receipt'],
    () => getStageforModules('receipt'),
    {
      select: (data) => {
        return {
          'current_stage': 'draft',
          'next_stage': data[1]
        }
      },
      refetchOnWindowFocus: false
    }
  );

  /**  */
  const { data: customerBank = [] } = useQuery(
    ['customer-bank', receiptId],
    () => receiptId && customerBankDetails(receiptId),
    { refetchOnWindowFocus: false }
  );

  const { data: targetBank = [], isLoading: targetBankLoading } = useQuery(
    ['company-bank', disbReceipt?.target_bank_id, unidentifiedReceipt?.id],
    () => receiptId && getBusinessBanks({ product_id: disbReceipt?.product_id }),
    {
      enabled: Boolean(disbReceipt?.product_id),
      refetchOnWindowFocus: false,
    }
  );

  const { data: reviewTrail = [] } = useQuery(
    ['remarks', createdReceiptId],
    () => createdReceiptId && getReceiptRemarks(createdReceiptId),
    { refetchOnWindowFocus: false }
  );

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues:
      formType == 'view' ? { ...receiptDetails, excess_amount: receiptDetails?.customer_excess_amount } : { instrument_date: dayjs(new Date()).format(), excess_amount: disbReceipt?.customer_excess_amount },
  });
  const values = watch()

  useEffect(() => {
    if (Object.keys(receiptDetails).length) {
      reset(receiptDetails);
      setValue('excess_amount', receiptDetails?.customer_excess_amount || 0);
    }
  }, [receiptDetails]);

  const getUrl = async () => {
    if (values?.receipt_proof_url) {
      let response = await getSignedUrl(values?.receipt_proof_url)
      setSignedUrl({ url: response?.url })
      setModalObj({ modal: true, type: 'proof' })
    }
  }

  useEffect(() => {
    if (Object.keys(systemDate).length && !createdReceiptId) {
      setValue('receipt_date', systemDate?.current_system_date);
      setValue('instrument_date', systemDate?.current_system_date);
    }
  }, [systemDate]);

  useEffect(() => {
    if (Object.keys(disbReceipt).length) {
      setValue('excess_amount', disbReceipt?.customer_excess_amount || 0);
    }
  }, [disbReceipt])

  /**
   * This useEffect is used to get the receipt values according to the receipt date
   * eg: if we change the receipt date to yesterday it will display yesterday record
   */
  useEffect(() => {
    const body = {
      receipt_date: dateFormat(values?.receipt_date, 'YYYY-MM-DD'),
      unidentified_receipt_id: unidentifiedReceipt?.id || values?.unidentified_receipt_id,
    }
    /**
     * API call will done when the system date is there
     */
    if (systemDate?.current_system_date) {
      loanDetails.mutate({ loanId, body }, {
        onSuccess: res => {
          res?.length && setDisbReceipt(res[0])
        },
        onError: e => {
          displayNotification({ message: e?.message || e, variant: 'error' })
        }
      })
    }
  }, [values?.receipt_date, unidentifiedReceipt?.id])

  // display the notification if there is a receipt in between the receipt date and system date
  // this will be trigger when the unidentified receipt is selected
  useEffect(() => {
    const body = {
      "receipt_date": unidentifiedReceipt?.unidentified_receipt_date,
      "loan_id": parseInt(loanId)
    }
    if (unidentifiedReceipt?.id) {
      unidentifiedReceiptEligibleCheck(body)
        .then((res) => {
          if (res?.message) {
            displayNotification({ message: res?.message, variant: 'warning' })
          }
        })
    }
  }, [unidentifiedReceipt?.id])

  useEffect(() => {
    if (receiptDetails?.unidentified_receipt_id) {
      setUnidentifiedReceipt(UnidentifiedReceiptData?.find((e) => e?.id === receiptDetails?.unidentified_receipt_id))
    } else {
      setUnidentifiedReceipt({})
    }
  }, [UnidentifiedReceiptData])

  /** this state is used to null the values when the receipt amount and receipt date get change */
  useEffect(() => {
    setAppropriation({})
    if (values?.receipt_amount != receiptDetails?.receipt_amount || values?.receipt_date != receiptDetails?.receipt_date) {
      [
        "appropriated_principle",
        "appropriated_interest",
        "appropriated_spread",
        "appropriated_addon_spread",
        "appropriated_interest_amount_during_penalty",
        "appropriated_spread_amount_during_penalty",
        "appropriated_addon_spread_amount_during_penalty",
        "appropriated_penal_interest_amount",
        "appropriated_charges",
      ]?.map((item) => {
        delete receiptDetails[item];
      });
    }
  }, [values?.receipt_amount, values?.receipt_date])

  const handleReviewModal = () => {
    saveSchema.validate(values)
      .then(res => {
        if (!disbReceipt?.customer_excess_amount && !values?.receipt_amount) {
          displayNotification({ message: 'Please Enter Receipt Amount', variant: 'error' })
        }
        else if (!values?.tds_amount && checked) {
          displayNotification({ message: 'Please Enter TDS amount', variant: 'error' })
        } else {
          /** if this condition stratifies then the revolving option will pop up while moving to next stage */
          // if(currencyAsFloat(values?.appropriated_principle) >= min_prin_renewal_amount || appropriation?.appropriated_principle >= min_prin_renewal_amount) {
          //   setModalObj({modal: true, type: 'review'})
          // } else {
          setOpen(true);
          setStatus('review');
          setLabel(stages?.next_stage == 'approval' ? 'Send for Approval' : 'Send for Review');
          // }
        }
      })
      .catch(err => {
        displayNotification({ message: err?.errors, variant: 'error' })
      })
  }

  const calculateBackDate = () => {
    return new Date(systemDate?.current_system_date)
      .setDate(new Date(systemDate?.current_system_date)
        .getDate() - disbReceipt?.backdate);
  }

  const calculateAppropration = () => {
    const body = {
      loan_id: parseInt(loanId),
      unidentified_receipt_id: unidentifiedReceipt?.id,
      receipt_amount: currencyAsFloat(values?.receipt_amount),
      receipt_date: dateFormat(values?.receipt_date, 'YYYY-MM-DD'),
    }
    setButtonLoader({ calculate: true })
    /** used to calculate approprate logic for the given receipt amount */
    getAppropriationLogic(body)
      .then((res) => {
        setAppropriation(res)
      })
      .catch((e) => {
        if (e?.RequestValidationError) {
          Object.keys(e?.RequestValidationError).forEach((item, index) => {
            setError(item, { message: e?.RequestValidationError[item] });
          });
        } else {
          displayNotification({
            id: 'imd-save',
            message: e?.message || e,
            variant: 'error',
          });
        }
      })
      .finally(() => {
        setButtonLoader({ calculate: false })
      })
  }

  /** function will call when the user click the save button 
   * this function will handle the the save and update call
   * data is a object which will contain the 
   * {
   *  receipt_date, receipt_amount, instrument_type, instrument_date, bank_id, appropriated_amount
   * } 
   */
  const onSubmit = (data, isRenewalLoan) => {
    // body is used for create and update receipt
    const body = {
      loan_id: parseInt(loanId),
      receipt_amount: currencyAsFloat(values?.receipt_amount),
      receipt_date: dateFormat(values?.receipt_date, 'YYYY-MM-DD'),
      instrument_date: dateFormat(systemDate?.current_system_date, 'YYYY-MM-DD'),
      instrument_type: values?.instrument_type,
      appropriation_application_mode: fieldType,
      tds: checked == true ? 1 : 0,
      unidentified_receipt_id: unidentifiedReceipt?.id,
      tds_amount: checked == true ? (currencyAsFloat(values?.tds_amount) || 0) : 0,
      appropriated_principle: currencyAsFloat(values?.appropriated_principle) || 0,
      appropriated_interest: currencyAsFloat(values?.appropriated_interest) || 0,
      appropriated_charges: currencyAsFloat(values?.appropriated_charges) || 0,
      appropriated_spread: currencyAsFloat(values?.appropriated_spread) || 0,
      appropriated_addon_spread: currencyAsFloat(values?.appropriated_addon_spread) || 0,
      appropriated_interest_amount_during_penalty: currencyAsFloat(values?.appropriated_interest_amount_during_penalty) || 0,
      appropriated_spread_amount_during_penalty: currencyAsFloat(values?.appropriated_spread_amount_during_penalty) || 0,
      appropriated_addon_spread_amount_during_penalty: currencyAsFloat(values?.appropriated_addon_spread_amount_during_penalty) || 0,
      appropriated_penal_interest_amount: currencyAsFloat(values?.appropriated_penal_interest_amount) || 0,
      source_bank_id: null,
      target_bank_id: values?.target_bank_id,
      create_revolving_loan: isRenewalLoan || 'no',
    }

    if (values?.instrument_type != 'cash') {
      body.payment_ref_no = values?.payment_ref_no?.toUpperCase()
      body.source_bank_id = values?.source_bank_id
    }

    setButtonLoader({ save: true })
    if (!createdReceiptId) {
      createReceipt(body)
        .then((res) => {
          setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.save });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' });
          }
        })
        .finally(() => {
          setButtonLoader({ save: false })
        })
    } else {
      updateReceipt(createdReceiptId, body)
        .then((res) => {
          setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.update });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' });
          }
        })
        .finally(() => {
          setButtonLoader({ save: false })
        })
    }
  }

  const OnSendForReview = (remarks) => {
    // body to send for review from created receipt
    const body = {
      "remarks": remarks,
      "remark_type": 'maker',
    }
    // body to direct send for review
    const savebody = {
      loan_id: parseInt(loanId),
      receipt_amount: currencyAsFloat(values?.receipt_amount),
      receipt_date: dateFormat(values?.receipt_date, 'YYYY-MM-DD'),
      instrument_date: dateFormat(systemDate?.current_system_date, 'YYYY-MM-DD'),
      instrument_type: values?.instrument_type,
      appropriation_application_mode: fieldType,
      tds: checked == true ? 1 : 0,
      unidentified_receipt_id: unidentifiedReceipt?.id,
      tds_amount: checked == true ? (currencyAsFloat(values?.tds_amount) || 0) : 0,
      appropriated_principle: currencyAsFloat(values?.appropriated_principle) || 0,
      appropriated_interest: currencyAsFloat(values?.appropriated_interest) || 0,
      appropriated_spread: currencyAsFloat(values?.appropriated_spread) || 0,
      appropriated_addon_spread: currencyAsFloat(values?.appropriated_addon_spread) || 0,
      appropriated_interest_amount_during_penalty: currencyAsFloat(values?.appropriated_interest_amount_during_penalty) || 0,
      appropriated_spread_amount_during_penalty: currencyAsFloat(values?.appropriated_spread_amount_during_penalty) || 0,
      appropriated_addon_spread_amount_during_penalty: currencyAsFloat(values?.appropriated_addon_spread_amount_during_penalty) || 0,
      appropriated_penal_interest_amount: currencyAsFloat(values?.appropriated_penal_interest_amount) || 0,
      appropriated_charges: currencyAsFloat(values?.appropriated_charges) || 0,
      source_bank_id: null,
      target_bank_id: values?.target_bank_id,
      create_revolving_loan: modalObj?.isSelect || 'no',
      ...body,
    }

    if (values?.instrument_type != 'cash') {
      savebody.payment_ref_no = values?.payment_ref_no?.toUpperCase()
      savebody.source_bank_id = values?.source_bank_id
    }

    setRemarksLoading(true)
    // if there is receipt id then the update function will call or the direct send for review will call
    // If direct send for review then there is no receipt ID, It has been handled by backend
    if (!createdReceiptId) {
      saveReceiptAndsendForNextStage(savebody)
        .then((res) => {
          setSucessfulmodal({ open: true, title: stages?.next_stage == 'approval' ? STAGE_CHANGE_TEXT?.approval : STAGE_CHANGE_TEXT?.review });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' });
          }
        })
        .finally(() => {
          setOpen(false)
          setRemarksLoading(false)
        })
    } else {
      // first the update function will call if it get pass then the review function will call and if it get pass then the generate PDF function will call
      updateReceipt(createdReceiptId, savebody)
        .then((res) => {
          sendReceiptforNextStage(createdReceiptId, body)
            .then((res) => {
              setSucessfulmodal({ open: true, title: stages?.next_stage == 'approval' ? STAGE_CHANGE_TEXT?.approval : STAGE_CHANGE_TEXT?.review });
            })
            .catch((e) => {
              if (e?.RequestValidationError) {
                Object.keys(e?.RequestValidationError).forEach((item, index) => {
                  setError(item, { message: e?.RequestValidationError[item] });
                });
              } else {
                displayNotification({ message: e?.message || e, variant: 'error' });
              }
            })
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' });
        })
        .finally(() => {
          setOpen(false)
          setRemarksLoading(false)
        })
    }
  }
  // used to handle error msg for the table
  const handleErrorMsg = (val1, val2) => {
    if (currencyAsFloat(val1) > val2) {
      return 'Please enter valid amount.'
    }
  }

  /** handle the current excess amount */
  const handleCurrentExcessAmount = () => {
    /** used to get the total value by sending the object */
    const handleTotal = (objectData) => {
      let total = currencyAsFloat(objectData?.appropriated_principle || 0) +
        currencyAsFloat(objectData?.appropriated_interest || 0) +
        currencyAsFloat(objectData?.appropriated_spread || 0) +
        currencyAsFloat(objectData?.appropriated_addon_spread || 0) +
        currencyAsFloat(objectData?.appropriated_interest_amount_during_penalty || 0) +
        currencyAsFloat(objectData?.appropriated_spread_amount_during_penalty || 0) +
        currencyAsFloat(objectData?.appropriated_penal_interest_amount || 0) +
        currencyAsFloat(objectData?.appropriated_charges || 0)
      if (currencyAsFloat(values?.receipt_amount) || total) {
        return (currencyAsFloat(values?.receipt_amount || 0) + disbReceipt?.customer_excess_amount) >= total ?
          currencyAsFloat(values?.receipt_amount || 0) + disbReceipt?.customer_excess_amount - total : 0
      } else {
        return disbReceipt?.customer_excess_amount
      }
    }

    if (fieldType == 'manual') {
      return handleTotal(values) || 0
    }
    if (fieldType == 'auto') {
      return handleTotal(appropriation) || 0
    }
    return 0
  }

  /** this function is used to calculate the remaining value in tabel */
  const remainingValueCalculation = (val1, val2) => {
    if (val2) {
      if (val1 - currencyAsFloat(val2) < 0) {
        return 0
      } else {
        return val1 - currencyAsFloat(val2)
      }
    } else {
      return 0
    }
  }

  /** used to handle the renewal selection */
  const handleRenewalLoanSelection = (val) => {
    if (modalObj?.type == 'save') {
      handleSubmit(onSubmit)(val)
    } else {
      modalObj.isSelect = val
      setOpen(true);
      setStatus('review');
      setLabel(stages?.next_stage == 'approval' ? 'Send for Approval' : 'Send for Review');
    }
    modalObj.modal = false;
  }

  const handleUnidentifiedReceiptSelection = (data) => {
    console.log(data);
    if (data?.id) {
      setUnidentifiedReceipt(data)
      setValue('receipt_date', data?.unidentified_receipt_date)
      setValue('receipt_amount', data?.unidentified_receipt_amount)
      setValue('instrument_type', data?.instrument_type)
      setValue('payment_ref_no', data?.payment_ref_no)
      setValue('target_bank_id', data?.target_bank_id)
    } else {
      setUnidentifiedReceipt({})
      setValue('receipt_date', systemDate?.current_system_date)
      setValue('receipt_amount', '')
      setValue('instrument_type', '')
      setValue('payment_ref_no', '')
      setValue('target_bank_id', '')
    }
  }

  return (
    <>
      <Body background={false}>
        {/* name and id has send as props to display in the page */}
        <PageTitle title={disbReceipt?.customer_name || receiptDetails?.customer_name} id={disbReceipt?.customer_id || receiptDetails?.customer_id} action={<UnidentifiedReceiptListModal submitCallback={handleUnidentifiedReceiptSelection} selectedRowId={unidentifiedReceipt?.id} data={UnidentifiedReceiptData} mode={'edit'} />} />
      </Body>
      {/* id is send as a props to get and display the customer details  */}
      <DashBoardCustomerDetails id={receiptId} loansId={disbReceipt?.loan_id || receiptDetails?.loan_id} codeName={'Receipt Code'} code={receiptDetails?.code} />
      <Box sx={{ position: 'relative' }}>
        <Body>
          {/* receipt name is send as props to display and status value is send to change the color of the badge */}
          {receiptDetails?.prospect_code || disbReceipt?.prospect_code ? (
            <div style={ImdStyle.flag}>
              <IdBadge
                label={receiptDetails?.prospect_code || disbReceipt?.prospect_code}
              />
            </div>
          ) : null}
          <Grid gutter='xs' mt={'md'}>
            <Grid.Col span={4}>
              {!unidentifiedReceipt?.id ? (
                <>
                  <Grid.Col span={12}>
                    <ControllerDatePicker
                      name='receipt_date'
                      label='Receipt Date'
                      required
                      maxDate={new Date(systemDate?.current_system_date)}
                      minDate={new Date(calculateBackDate())}
                      control={control}
                      errors={errors?.receipt_date?.message}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ControllerCurrencyInput
                      name='receipt_amount'
                      label='Receipt Amount'
                      value={values?.receipt_amount}
                      amountInWords
                      /** asterisk will be vissible when there is no excess amount */
                      required={values?.excess_amount <= 0 ? true : false}
                      control={control}
                      errors={errors?.receipt_amount?.message}
                      autoComplete='off'
                    />
                  </Grid.Col>
                </>) : (
                <>
                  <Grid.Col span={12}>
                    <ViewData title={'Receipt Date'} value={values?.receipt_date} type={'date'} />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewData title={'Receipt Amount'} value={values?.receipt_amount} withTooltip withCopy />
                  </Grid.Col>
                </>)
              }
              <Grid.Col span={12}>
                <ViewData title={'Current Excess'} value={handleCurrentExcessAmount() || 0} />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewData title={'Due Amount'} value={disbReceipt?.loan_due_amount || receiptDetails?.loan_due_amount} withCopy />
              </Grid.Col>
              {disbReceipt?.dpd ?
                <Grid sx={{ display: 'flex', background: '#ff5a5a', width: '60%' }} ml={'1px'}>
                  <Grid.Col span={12}>
                    <Title order={6} sx={{ fontWeight: '500', color: '#ffd7d7' }}>DPD {disbReceipt?.dpd} Days</Title>
                  </Grid.Col>
                </Grid> : null
              }
            </Grid.Col>
            <Grid.Col span={4}>
              <Grid.Col span={12}>
                <ViewData
                  title='Instrument Date'
                  value={dateFormat(systemDate?.current_system_date || new Date())}
                  type={'text'}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                {!unidentifiedReceipt?.id ? (
                  <ControllerSelect
                    name='instrument_type'
                    label='Instrument Type'
                    required
                    control={control}
                    autoSelect
                    setValue={setValue}
                    data={[
                      ...instrumentType,
                      {
                        id: 4,
                        value: 'cash',
                        label: 'Cash',
                      },
                    ]}
                    errors={errors?.instrument_type?.message}
                  />) : (
                  <ViewData title={'Instrument Type'} type={'text'} value={values?.instrument_type?.toUpperCase()} />
                )
                }
              </Grid.Col>
              {values?.instrument_type != 'cash' ?
                <>
                  <Grid.Col>
                    <TitleViewer title='Source Bank: Customer' />
                  </Grid.Col>
                  <Grid.Col pt='md'>
                    <ControllerSelect
                      name='source_bank_id'
                      control={control}
                      type={'bank'}
                      autoSelect
                      setValue={setValue}
                      spanSize={12}
                      displayLabel={false}
                      placeholder='Select Bank Details'
                      data={customerBank}
                      errors={errors?.source_bank_id?.message}
                    />
                  </Grid.Col>
                </> : null
              }
              <Grid.Col span={12}>
                {
                  values?.instrument_type === 'cash' ?
                    <ViewData title={'Payment Ref.No'} value={'Cash'} type="text" /> :
                    !unidentifiedReceipt?.id ? (
                      <ControllerTextField
                        name='payment_ref_no'
                        label={
                          <Center mt={'-5px'}>
                            Payment Ref.No
                            {values?.receipt_proof_url ? <Tooltip
                              label={'Click to view Receipt Proof'}
                              color='gray'
                              withArrow
                              position='top-start'
                            >
                              <span>
                                <ActionIcon
                                  onClick={() => {
                                    setSignedUrl({ loading: true })
                                    getUrl()
                                  }}
                                  loading={signedUrl?.loading}
                                >
                                  <FileInfo size={16} color={'green'} />
                                </ActionIcon>
                              </span>
                            </Tooltip> : null}
                          </Center>
                        }
                        value={values?.payment_ref_no?.toUpperCase()}
                        disabled={values?.instrument_type === 'cash'}
                        control={control}
                        errors={errors?.payment_ref_no?.message}
                        autoComplete='off'
                      />
                    ) : (
                      <ViewData title={'Payment Ref.No'} value={values?.payment_ref_no} type={'text'} />
                    )
                }
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={4}>
              <Grid.Col>
                <TitleViewer title='Target Bank: Petromoney' />
              </Grid.Col>
              {!unidentifiedReceipt?.id ? (
                <Grid.Col span={12} mt={'xs'}>
                  <ControllerSelect
                    name='target_bank_id'
                    control={control}
                    type={'bank'}
                    autoSelect
                    setValue={setValue}
                    spanSize={12}
                    displayLabel={false}
                    placeholder='Select Bank Details'
                    data={targetBank}
                    errors={errors?.target_bank_id?.message}
                  />
                </Grid.Col>) :
                <>
                  <Grid.Col span={12}>
                    <ViewData
                      title='Account No'
                      value={targetBank?.find(item => item.id === values?.target_bank_id)?.account_number}
                      type={'text'}
                      style={{ marginBottom: '0' }}
                      loading={targetBankLoading}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewData
                      title='IFSC'
                      value={targetBank?.find(item => item.id === values?.target_bank_id)?.ifsc}
                      type={'text'}
                      loading={targetBankLoading}
                    />
                  </Grid.Col>
                </>}
              <Grid.Col span={12}>
                <FormLabel
                  label='TDS'
                  render={
                    <Divider
                      labelPosition='right'
                      label={
                        <Box sx={{ display: 'flex' }}>
                          <Text size='xs' mr='sm'>
                            No
                          </Text>
                          <Switch checked={checked} onChange={(event) => setChecked(event.currentTarget.checked)} />
                          <Text size='xs' ml='sm'>
                            Yes
                          </Text>
                        </Box>
                      }
                    />
                  }
                />
              </Grid.Col>
              {checked ?
                <Grid.Col span={12}>
                  <ControllerCurrencyInput
                    name='tds_amount'
                    label='TDS Amount'
                    value={values?.tds_amount}
                    amountInWords
                    control={control}
                    errors={errors?.tds_amount?.message}
                  />
                </Grid.Col> : null
              }
            </Grid.Col>
            <Grid.Col span={8}>
              <Grid.Col span={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '2px dashed rgb(0,0,0,0.2)',
                  }}
                >
                  <Title order={6} sx={{ fontWeight: '500' }}>Receipt Appropriation</Title>
                  <Box>
                    {fieldType == 'auto' && <Button size={'xs'} sx={{ height: 27 }} variant={'subtle'} onClick={calculateAppropration} loading={buttonLoader?.calculate}>Calculate Appropration</Button>}
                    {isAllowed(auth?.group_id, '', action_id?.button?.appropriation_type) ?
                      <SegmentedControl
                        value={fieldType}
                        onChange={(e) => { setFieldType(e); setAppropriation({}) }}
                        color='blue'
                        size="xs"
                        ml={'10px'}
                        mb={'xs'}
                        data={[
                          { label: 'Auto', value: 'auto' },
                          { label: 'Manual', value: 'manual' },
                        ]}
                      /> : null
                    }
                  </Box>
                </Box>
              </Grid.Col>
              <Grid.Col>
                <Table verticalSpacing='xs' fontSize='xs'>
                  <thead style={{ backgroundColor: COLORS.primary.light1 }}>
                    <tr>
                      <th>Pay Head</th>
                      <th>Due Amount</th>
                      <th>Appropriated Amount</th>
                      <th>Remaining Due</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td>Principal</td>
                      <td>
                        <Nums value={disbReceipt?.outstanding_principle} />
                      </td>
                      <td>
                        {fieldType == 'auto' ?
                          <Nums value={appropriation?.appropriated_principle || receiptDetails?.appropriated_principle} />
                          :
                          <ControllerCurrencyInput
                            name={`appropriated_principle`}
                            control={control}
                            defaultValues={receiptDetails?.appropriated_principle}
                            displayLabel={false}
                            errors={handleErrorMsg(values?.appropriated_principle, appropriation?.outstanding_principle || disbReceipt?.outstanding_principle || receiptDetails?.appropriated_principle)}
                            size='xs'
                            styles={{ padding: '0', minHeight: '0' }}
                            sx={{ width: 150 }}
                          />
                        }
                      </td>
                      <td>
                        <Nums value=
                          {fieldType == 'auto' ? (appropriation?.remaining_principle || receiptDetails?.remaining_principle) : remainingValueCalculation(disbReceipt?.outstanding_principle, values?.appropriated_principle)}
                        />
                      </td>
                    </tr>

                    {disbReceipt?.outstanding_interest ?
                      <tr>
                        <td>Interest ({disbReceipt?.interest_rate}%)</td>
                        <td>
                          <Nums value={disbReceipt?.outstanding_interest} />
                        </td>
                        <td>
                          {fieldType == 'auto' ?
                            <Nums value={appropriation?.appropriated_interest || receiptDetails?.appropriated_interest} />
                            :
                            <ControllerCurrencyInput
                              name={`appropriated_interest`}
                              control={control}
                              defaultValues={receiptDetails?.appropriated_interest}
                              displayLabel={false}
                              errors={handleErrorMsg(values?.appropriated_interest, appropriation?.outstanding_interest || disbReceipt?.outstanding_interest || receiptDetails?.appropriated_interest)}
                              size='xs'
                              styles={{ padding: '0', minHeight: '0' }}
                              sx={{ width: 150 }}
                            />
                          }
                        </td>
                        <td>
                          <Nums value=
                            {fieldType == 'auto' ? (appropriation?.remaining_interest || receiptDetails?.remaining_interest) : remainingValueCalculation(disbReceipt?.outstanding_interest, values?.appropriated_interest)}
                          />
                        </td>
                      </tr> : null
                    }

                    {disbReceipt?.outstanding_spread ?
                      <tr>
                        <td>Spread ({disbReceipt?.spread_rate}%)</td>
                        <td>
                          <Nums value={disbReceipt?.outstanding_spread} />
                        </td>
                        <td>
                          {fieldType == 'auto' ?
                            <Nums value={appropriation?.appropriated_spread || receiptDetails?.appropriated_spread} />
                            :
                            <ControllerCurrencyInput
                              name={`appropriated_spread`}
                              control={control}
                              defaultValues={receiptDetails?.appropriated_spread}
                              displayLabel={false}
                              errors={handleErrorMsg(values?.appropriated_spread, appropriation?.outstanding_spread || disbReceipt?.outstanding_spread)}
                              size='xs'
                              styles={{ padding: '0', minHeight: '0' }}
                              sx={{ width: 150 }}
                            />
                          }
                        </td>
                        <td>
                          <Nums value=
                            {fieldType == 'auto' ? (appropriation?.remaining_spread || receiptDetails?.remaining_spread) : remainingValueCalculation(disbReceipt?.outstanding_spread, values?.appropriated_spread)}
                          />
                        </td>
                      </tr> : null
                    }

                    {disbReceipt?.outstanding_addon_spread ?
                      <tr>
                        <td>Addon Spread ({disbReceipt?.addon_spread_rate}%)</td>
                        <td>
                          <Nums value={disbReceipt?.outstanding_addon_spread} />
                        </td>
                        <td>
                          {fieldType == 'auto' ?
                            <Nums value={appropriation?.appropriated_addon_spread || receiptDetails?.appropriated_addon_spread} />
                            :
                            <ControllerCurrencyInput
                              name={`appropriated_addon_spread`}
                              control={control}
                              defaultValues={receiptDetails?.appropriated_addon_spread}
                              displayLabel={false}
                              errors={handleErrorMsg(values?.appropriated_addon_spread, appropriation?.outstanding_addon_spread || disbReceipt?.outstanding_addon_spread)}
                              size='xs'
                              styles={{ padding: '0', minHeight: '0' }}
                              sx={{ width: 150 }}
                            />
                          }
                        </td>
                        <td>
                          <Nums value=
                            {fieldType == 'auto' ? (appropriation?.remaining_addon_spread || receiptDetails?.remaining_addon_spread) : remainingValueCalculation(disbReceipt?.outstanding_addon_spread, values?.appropriated_addon_spread)}
                          />
                        </td>
                      </tr> : null
                    }

                    {/** penal for interest */}
                    {(disbReceipt?.outstanding_interest_amount_during_penalty) ?
                      <tr>
                        <td>Interest F1 ({disbReceipt?.interest_rate}%)</td>
                        <td>
                          <Nums value={disbReceipt?.outstanding_interest_amount_during_penalty} />
                        </td>
                        <td>
                          {fieldType == 'auto' ?
                            <Nums value={appropriation?.appropriated_interest_amount_during_penalty || receiptDetails?.appropriated_interest_amount_during_penalty} />
                            :
                            <ControllerCurrencyInput
                              name={`appropriated_interest_amount_during_penalty`}
                              control={control}
                              defaultValues={receiptDetails?.appropriated_interest_amount_during_penalty}
                              displayLabel={false}
                              errors={handleErrorMsg(values?.appropriated_interest_amount_during_penalty, appropriation?.outstanding_interest_amount_during_penalty || disbReceipt?.outstanding_interest_amount_during_penalty)}
                              size='xs'
                              styles={{ padding: '0', minHeight: '0' }}
                              sx={{ width: 150 }}
                            />
                          }
                        </td>
                        <td>
                          <Nums value=
                            {fieldType == 'auto' ? (appropriation?.remaining_interest_amount_during_penalty || receiptDetails?.remaining_interest_amount_during_penalty) : remainingValueCalculation(disbReceipt?.outstanding_interest_amount_during_penalty, values?.appropriated_interest_amount_during_penalty)}
                          />
                        </td>
                      </tr> : null
                    }

                    {/** penal for spread */}
                    {(disbReceipt?.outstanding_spread_amount_during_penalty) ?
                      <tr>
                        <td>Spread F1 ({disbReceipt?.spread_rate}%)</td>
                        <td>
                          <Nums value={disbReceipt?.outstanding_spread_amount_during_penalty} />
                        </td>
                        <td>
                          {fieldType == 'auto' ?
                            <Nums value={appropriation?.appropriated_spread_amount_during_penalty || receiptDetails?.appropriated_spread_amount_during_penalty} />
                            :
                            <ControllerCurrencyInput
                              name={`appropriated_spread_amount_during_penalty`}
                              control={control}
                              defaultValues={receiptDetails?.appropriated_spread_amount_during_penalty}
                              displayLabel={false}
                              errors={handleErrorMsg(values?.appropriated_spread_amount_during_penalty, appropriation?.outstanding_spread_amount_during_penalty || disbReceipt?.outstanding_spread_amount_during_penalty)}
                              size='xs'
                              styles={{ padding: '0', minHeight: '0' }}
                              sx={{ width: 150 }}
                            />
                          }
                        </td>
                        <td>
                          <Nums value=
                            {fieldType == 'auto' ? (appropriation?.remaining_spread_amount_during_penalty || receiptDetails?.remaining_spread_amount_during_penalty) : remainingValueCalculation(disbReceipt?.outstanding_spread_amount_during_penalty, values?.appropriated_spread_amount_during_penalty)}
                          />
                        </td>
                      </tr> : null
                    }

                    {/** penal for addon_spread */}
                    {(disbReceipt?.outstanding_addon_spread_amount_during_penalty) ?
                      <tr>
                        <td>Addon Spread F1 ({disbReceipt?.addon_spread_rate}%)</td>
                        <td>
                          <Nums value={disbReceipt?.outstanding_addon_spread_amount_during_penalty} />
                        </td>
                        <td>
                          {fieldType == 'auto' ?
                            <Nums value={appropriation?.appropriated_addon_spread_amount_during_penalty || receiptDetails?.appropriated_addon_spread_amount_during_penalty} />
                            :
                            <ControllerCurrencyInput
                              name={`appropriated_addon_spread_amount_during_penalty`}
                              control={control}
                              defaultValues={receiptDetails?.appropriated_addon_spread_amount_during_penalty}
                              displayLabel={false}
                              errors={handleErrorMsg(values?.appropriated_addon_spread_amount_during_penalty, appropriation?.outstanding_addon_spread_amount_during_penalty || disbReceipt?.outstanding_addon_spread_amount_during_penalty)}
                              size='xs'
                              styles={{ padding: '0', minHeight: '0' }}
                              sx={{ width: 150 }}
                            />
                          }
                        </td>
                        <td>
                          <Nums value=
                            {fieldType == 'auto' ? (appropriation?.remaining_addon_spread_amount_during_penalty || receiptDetails?.remaining_addon_spread_amount_during_penalty) : remainingValueCalculation(disbReceipt?.outstanding_addon_spread_amount_during_penalty, values?.appropriated_addon_spread_amount_during_penalty)}
                          />
                        </td>
                      </tr> : null
                    }

                    {(disbReceipt?.outstanding_penal_interest_amount) ?
                      <tr>
                        <td>Penal F2 ({disbReceipt?.penal_interest_percent}%)</td>
                        <td>
                          <Nums value={disbReceipt?.outstanding_penal_interest_amount} />
                        </td>
                        <td>
                          {fieldType == 'auto' ?
                            <Nums value={appropriation?.appropriated_penal_interest_amount || receiptDetails?.appropriated_penal_interest_amount} />
                            :
                            <ControllerCurrencyInput
                              name={`appropriated_penal_interest_amount`}
                              control={control}
                              defaultValues={receiptDetails?.appropriated_penal_interest_amount}
                              displayLabel={false}
                              errors={handleErrorMsg(values?.appropriated_penal_interest_amount, appropriation?.outstanding_penal_interest_amount || disbReceipt?.outstanding_penal_interest_amount)}
                              size='xs'
                              styles={{ padding: '0', minHeight: '0' }}
                              sx={{ width: 150 }}
                            />
                          }
                        </td>
                        <td>
                          <Nums value=
                            {fieldType == 'auto' ? (appropriation?.remaining_penal_interest_amount || receiptDetails?.remaining_penal_interest_amount) : remainingValueCalculation(disbReceipt?.outstanding_penal_interest_amount, values?.appropriated_penal_interest_amount)}
                          />
                        </td>
                      </tr> : null
                    }

                    {disbReceipt?.outstanding_charges ?
                      <tr>
                        <td>Fee</td>
                        <td>
                          <Nums value={disbReceipt?.outstanding_charges} />
                        </td>
                        <td>
                          {fieldType == 'auto' ?
                            <Nums value={appropriation?.appropriated_charges || receiptDetails?.appropriated_charges} />
                            :
                            <ControllerCurrencyInput
                              name={`appropriated_charges`}
                              control={control}
                              defaultValues={receiptDetails?.appropriated_charges}
                              displayLabel={false}
                              errors={handleErrorMsg(values?.appropriated_charges, appropriation?.outstanding_charges || disbReceipt?.outstanding_charges)}
                              size='xs'
                              styles={{ padding: '0', minHeight: '0' }}
                              sx={{ width: 150 }}
                            />
                          }
                        </td>
                        <td>
                          <Nums value=
                            {fieldType == 'auto' ? (appropriation?.remaining_charges || receiptDetails?.remaining_charges) : remainingValueCalculation(disbReceipt?.outstanding_charges, values?.appropriated_charges)}
                          />
                        </td>
                      </tr> : null
                    }

                  </tbody>
                </Table>
              </Grid.Col>
            </Grid.Col>
          </Grid>
          {/** This Action buttons component is used to display the buttons in the flow */}
          <ActionButtons
            status={receiptDetails?.status_value ? receiptDetails?.status_value : 'draft'}
            base={'creation'}
            nextStage={stages?.next_stage}
            sendForReviewCB={handleReviewModal}
            sendForApprovalCB={handleReviewModal}
            saveCB={() => {
              // currencyAsFloat(values?.appropriated_principle) >= min_prin_renewal_amount ||
              // appropriation?.appropriated_principle >= min_prin_renewal_amount
              // ? setModalObj({modal: true, type: 'save'}) : 
              handleSubmit(onSubmit)()
            }}
            loading={{ save: buttonLoader?.save }}
            remarksCB={() => setRemarksOpen(true)}
            remarks={reviewTrail}
            currentStatus={values?.status_value}
            moduleApplicationId={values?.id}
            moduleName={'receipt'}
            isReady={Boolean(stages?.next_stage)} />
          {/** display the modal on sucessfull status change */}
          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={receiptDetails?.code}
            title={sucessfulmodal?.title}
            href={'/receipt'}
            hreftext={'Back to list table'}
          />
          {/** used to get the remarks from the user */}
          <Remarks
            open={open}
            setOpen={setOpen}
            type={stages?.next_stage}
            callback={OnSendForReview}
            remarksTitle={stages?.next_stage == 'review' ? 'Review Remarks' : 'Approval Remarks'}
            dueAmount={disbReceipt?.loan_due_amount}
            totalAmount={currencyAsFloat(values?.receipt_amount) + currencyAsFloat(values?.excess_amount)}
            check={status}
            label={label}
            loading={remarksLoading}
            currentStatus={values?.status_value}
            moduleApplicationId={values?.id}
            moduleName={'Receipt'}
          />
        </Body>
        <DialogModal centered opened={modalObj?.modal} onClose={() => { setModalObj((old) => ({ ...old, modal: false })) }} title={modalObj?.type === 'proof' ? 'Receipt Proof' : null} withCloseButton={modalObj?.type === 'proof' ? true : false}>
          {modalObj?.type === 'proof' ?
            <>
              <Box sx={{ maxHeight: '500px', overflowY: 'scroll' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Image
                    src={signedUrl?.url}
                    alt="proof"
                    maw={200}
                  />
                </Box>
              </Box>
            </> :
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Title order={6} sx={{ fontWeight: '500' }}>Do you want to create Revolving loan for this?..</Title>
              <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }} mt={'lg'}>
                <Button onClick={() => { handleRenewalLoanSelection('no') }} variant="subtle" color={'red'}>No</Button>
                <Button onClick={() => { handleRenewalLoanSelection('yes') }} color='green'>Yes</Button>
              </Box>
            </Box>
          }
        </DialogModal>
        {/** used to display the remarks */}
        <ViewRemark open={remarksOpen} onClose={setRemarksOpen} remarkTrail={reviewTrail} />

      </Box>
    </>
  );
};

export default ReceiptCreation;
