import { Button, Group, Text } from '@mantine/core';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import Body from '../../components/Body/Body';
import { useQuery } from '@tanstack/react-query';
import { LoanStatusToken } from '../../components/CommonComponents/Preview/FilePreview';
import DataTableWrapper from '../../components/CommonComponents/Table/DataTableWrapper';
import { createColumnHelper } from '@tanstack/react-table';
import PaymentDrawer from './PaymentDrawer';
import { getAllPayments, getBulkPayments, uploadBulkPayments } from '../../services/payment.service';
import { LOAN_STATUS_COLORS } from '../../utils/StatusTable';
import {
  APPROVED,
  CANCELLED,
  IN_APPROVAL,
  IN_REVIEW,
  OPEN,
  REJECTED,
  CANCELLATION_REVIEW,
  CANCELLATION_APPROVAL,
  AUTO_CANCELLED
} from '../../constants/status';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import Nums from '../../components/Body/Nums';
import EntityFilter from './../../components/CommonComponents/Filter/EntityFilter';
import useModuleRecordCountStore from '../../store/moduleRecordCountStore';
import FileUploadDownloadComp from '../../components/CommonComponents/FileUpDown/FileUploadDownloadComp';
import { openFile } from '../../utils/openFile';
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification';
import LoanTypeFilter from '../../components/CommonComponents/Filter/LoanTypeFilter';

const PaymentTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const columnHelper = createColumnHelper();
  const { moduleCount } = useModuleRecordCountStore();
  const [addpayment, setAddPayment] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [records, setRecords] = useState({ label: 'show 10', value: 10 });
  const [fileObj, setFileObj] = useState({});
  // get the search params
  const [searchParams, setSearchParams] = useSearchParams();
  const params = {}
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  const formData = new FormData();

  /** used to change the page to 1 while the search us used 
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1)
  }, [search, searchParams.get("status"), searchParams.get("entity"), searchParams.get("reload_type"), records])

  const { data: disbData = [], isLoading, refetch } = useQuery(
    ['all-payment', params, page, search, records],
    () => getAllPayments({ ...params, page, search, records: records?.value }),
    {
      enabled: Boolean(searchParams.get("status") && searchParams.get("status") !== "none"),
      refetchOnWindowFocus: false,
    }
  );

  const getBulkPaymentsQuery = useQuery({
    queryKey: ['bulk-payment', params],
    queryFn: () => getBulkPayments({ ...params }),
    onSuccess: (data) => {
      setFileObj({});
      openFile(data?.[0]?.file_storage_url);
    },
    enabled: false,
  });

  const bulkPaymentUpload = (value) => {
    console.log(value[0]);
    formData.append('file', value[0]);
    console.log(formData);
    if (!value?.length) {
      displayNotification({
        message: "Please select any file to upload",
        variant: 'error',
      });
      setFileObj(old => ({ ...old, loading: true }));
    } else {
      uploadBulkPayments(formData)
        .then((res) => {
          if (res?.data?.[0]?.file_storage_url) {
            openFile(res?.data?.[0]?.file_storage_url);
          }
          displayNotification({
            message: res?.message || res,
            variant: 'success',
          });
          setFileObj({});
          searchParams.set("refetch", true);
          setSearchParams(searchParams);
          refetch();
        })
        .catch((e) => {
          displayNotification({
            message: e?.message || e,
            variant: 'error',
          });
          setFileObj(old => ({ ...old, loading: false }));
        })
    }
  }

  const onRowClick = (i) => {
    if (i?.status_value == OPEN) {
      navigate(`/payment/view/${i?.id}/${i?.customer_id}/${i?.disbursement_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == IN_REVIEW || i?.status_value == IN_APPROVAL) {
      navigate(`/payment/review/${i?.id}/${i?.customer_id}/${i?.disbursement_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == APPROVED) {
      navigate(`/payment/approved/${i?.id}/${i?.customer_id}/${i?.disbursement_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == AUTO_CANCELLED) {
      navigate(`/payment/cancelled/${i?.id}/${i?.customer_id}/${i?.disbursement_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == REJECTED) {
      navigate(`/payment/rejected/${i?.id}/${i?.customer_id}/${i?.disbursement_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == CANCELLED) {
      navigate(`/cancellation/payment-cancelled/${i?.id}/${i?.customer_id}/${i?.disbursement_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == CANCELLATION_REVIEW || i?.status_value == CANCELLATION_APPROVAL) {
      navigate(`/cancellation/payment-cancel/${i?.id}/${i?.customer_id}/${i?.disbursement_id}`, { state: { from: location.pathname } });
    }
  };

  const onClickAction = (value) => {
    return (
      <span
        style={{ color: '#0063FF', cursor: 'pointer' }}
      >
        {value.getValue()}
      </span>
    );
  };

  const tableAction = (
    <>
      {searchParams.get("status") === 'draft' ?
        <>
          <FileUploadDownloadComp
            open={fileObj?.modal}
            onClose={() => setFileObj({ modal: false, menu: false })}
            uploadLoading={fileObj?.loading}
            downloadLoading={getBulkPaymentsQuery?.isFetching}
            setFileState={setFileObj}
            fileState={fileObj}
            onFileDownload={() => getBulkPaymentsQuery.refetch()}
            onFileUpload={(file) => bulkPaymentUpload(file)}
            title="Bulk Payment"
            label={"Bulk Payment"}
          />
        </> : null}
      <Button
        variant='filled'
        leftIcon={<Plus size={18} />}
        size='sm'
        onClick={() => setAddPayment(true)}
      >
        Create Payment
      </Button>
    </>
  );

  const columnData = [
    columnHelper.accessor('prospect_code', {
      header: 'Tranche Code',
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor('customer_name', {
      header: 'Customer Name',
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor('customer_id', {
      header: 'Customer Code',
    }),
    columnHelper.accessor('loan_amount', {
      header: <Text sx={{ textAlign: 'right' }}>Tranche Amount</Text>,
      cell: (value) => <Nums value={value.getValue()} styles={{ textAlign: 'right' }} />,
    }),
    columnHelper.accessor('disbursed_date', {
      header: 'Disbursed Date',
      cell: (value) => dateFormat(value.getValue()),
    }),
    columnHelper.accessor('maker_name', {
      header: 'Created By',
    }),
    columnHelper.accessor('loan_status', {
      header: 'Loan Status',
      cell: (value) => (
        <LoanStatusToken
          label={value.getValue()}
          color={LOAN_STATUS_COLORS?.[value.getValue()]}
        />
      ),
    }),
  ];

  return (
    <Body background={false}>
      <DataTableWrapper
        title='Payments'
        filter={false}
        rowData={disbData?.data}
        columnData={columnData}
        noDataText="No Payment details yet!"
        noDataSubText='This Section will contain list of created Loan details.'
        useApiPagination
        totalNoOfPages={Math.ceil(parseInt(moduleCount?.count) / records?.value)}
        totalNoOfRecords={moduleCount?.count}
        page={page}
        onRowClick={onRowClick}
        setPage={setPage}
        rowLength={records}
        setRowLength={setRecords}
        apiSearch={setSearch}
        // when the status is none then the loading is set to false other wise it will dependent on isFetching
        loading={searchParams.get("status") !== "none" ? isLoading : searchParams.get("status") === null ? true : false}
        showStatus
        module_name='payment'
        addDataCallback={() => setAddPayment(true)}
        /** used to have tab in receipt table to filter active and closed loans */
        options={<Group spacing={2}><EntityFilter useQueryParams /><LoanTypeFilter /></Group>}
        actions={tableAction}
      />
      <PaymentDrawer open={addpayment} onClose={() => setAddPayment(false)} />
    </Body>
  );
};

export default PaymentTable;
