import { getAPICall, postAPICall } from ".";

export const getAccountingTable = (body = {}) => {
  return postAPICall("/accounting/report", { body });
};

export const getAccountingTableAll = (id, body = {}) => {
  return postAPICall(`/module/accounting/report/${id}`, { body });
};

/** get the report details by report name */
export const getReportbyReportName = (
  report_name,
  { from_date, to_date, download_csv, download_text, entity_id, cibil_report_date, tat_range, tat_cut_of_time, loan_created_time, loan_type },
  { page, search, records },
  signal,
) => {
  let query = "";
  let queryUrl = `/report?report_name=${report_name}&page=${page}`;
  if (from_date) query += `&from_date=${from_date}`;
  if (to_date) query += `&to_date=${to_date}`;
  if (entity_id) query += `&entity_id=${entity_id}`;
  if (download_csv === 'yes') query += `&download_csv=${download_csv}`;
  if (download_text === 'yes') query += `&download_text=${download_text}`;
  if (search) query += `&customer_id_name=${search}`;
  if (records) query += `&records=${records}`;
  if (cibil_report_date) query += `&cibil_report_date=${cibil_report_date}`
  if (tat_range && tat_range !== 'all') query += `&tat_range=${tat_range}`
  if (tat_range && tat_range !== 'all') query += `&tat_cut_off_time=${tat_cut_of_time}`
  if (loan_type && loan_type !== 'all') query += `&loan_mode=${loan_type}`;
  if (loan_created_time && loan_created_time !== 'all-time') query += `&loan_created_time=${loan_created_time}`;
  if (query?.length) queryUrl += query;

  return getAPICall(queryUrl, { returnObject: true, signal });
};

export const downloadReportbyReportName = ({id}) => {
  return getAPICall(`report/download/url/${id}`, { returnObject: true });
};

/** get the report page count, total records by report name */
export const getReportPaginationDetails = (
  { report_name, search, records },
  { from_date, to_date, entity_id, cibil_report_date, tat_range, tat_cut_of_time, loan_created_time, loan_type },
  signal,
) => {
  let query = "";
  let queryUrl = `record_count?report_name=${report_name}`;
  if (search) query += `&customer_id_name=${search}`;
  if (records) query += `&records=${records}`;
  if (from_date) query += `&from_date=${from_date}`;
  if (to_date) query += `&to_date=${to_date}`;
  if (entity_id) query += `&entity_id=${entity_id}`;
  if (cibil_report_date) query += `&cibil_report_date=${cibil_report_date}`
  if (tat_range && tat_range !== 'all') query += `&tat_range=${tat_range}`
  if (tat_range && tat_range !== 'all') query += `&tat_cut_off_time=${tat_cut_of_time}`
  if (loan_type && loan_type !== 'all') query += `&loan_mode=${loan_type}`;
  if (loan_created_time && loan_created_time !== 'all-time') query += `&loan_created_time=${loan_created_time}`;
  if (query?.length) queryUrl += query;

  return getAPICall(queryUrl, { returnObject: true, signal });
};

/*
  function gives passbook details of customer by sending the customer id
*/
export const getPassbookDetails = (customerId, body) => {
  return getAPICall(
    `/passbook/${customerId}?download_csv=${body?.download_csv || "no"}`,
    { returnObject: true }
  );
};

/** get the report summary by report name */
export const getReportSummaryDetails = (
  { report_name, search, records },
  { from_date, to_date, entity_id, loan_type, tat_range, tat_cut_of_time, loan_created_time },
  signal,
) => {
  let queryUrl = `report-summary?report_name=${report_name}`;
  if (search) queryUrl += `&customer_id_name=${search}`;
  if (from_date) queryUrl += `&from_date=${from_date}`;
  if (to_date) queryUrl += `&to_date=${to_date}`;
  if (entity_id) queryUrl += `&entity_id=${entity_id}`;
  if (loan_type && loan_type !== 'all') queryUrl += `&loan_mode=${loan_type}`;
  if (tat_range && tat_range !== 'all') queryUrl += `&tat_range=${tat_range}`
  if (tat_range && tat_range !== 'all') queryUrl += `&tat_cut_off_time=${tat_cut_of_time}`
  if (loan_created_time && loan_created_time !== 'all-time') queryUrl += `&loan_created_time=${loan_created_time}`;

  return getAPICall(queryUrl, { returnObject: true, signal });
};

export const uploadCibilBorrowersData = (body) => {
  return postAPICall(`excel-to-text`, {
    body, returnObject: true, headers:
      { headers: { "Content-Type": "multipart/form-data", }, }
  });
}
