import React, { useState } from "react";
import { Box, Button, Grid, Text, Title } from "@mantine/core";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../../../components/CommonComponents/Table/ReactTable";
import { COLORS, SIZES } from '../../../../constants';
import { dateFormat } from '../../../../components/DateFormat/dateFormat';
import { useQuery } from '@tanstack/react-query';
import Body from '../../../../components/Body/Body';
import {
  getValidatedHoliday,
  getallEODDetails,
  getLastDateofEOD,
  handleRunEOD
} from '../../../../services/eod.service';
import DialogModal from "../../../../components/CommonComponents/Modal/DialogModal";
import useAuthStore from "../../../../store/authStore";
import { displayNotification } from "../../../../components/CommonComponents/Notification/displayNotification";
import CheckAllowed from "../../../../utils/roleBaseAccess";
import { action_id } from "../../../../config/userRules";
import EODSchedule from "./EODSchedule";

const EODTable = () => {
  const { auth, logout } = useAuthStore(store => ({
    auth: store.auth,
    logout: store.resetAuth
  }));
  const columnHelper = createColumnHelper();

  const [loading, setLoading] = useState(false);
  const [showEodDialog, setShowEodDialog] = useState(false);
  const [scheduledData, setScheduledData] = useState({});

  /** used to list all the EOD */
  const { data: allEOD = [], isLoading } = useQuery(
    ['all-eod'],
    () => getallEODDetails(),
    { refetchOnWindowFocus: false }
  )

  /** used to get the last run date */
  const { data: lastRun = {} } = useQuery(
    ['last-date-eod'],
    () => getLastDateofEOD(),
    { refetchOnWindowFocus: false }
  )

  /* 
   * When the current system date is greater then december 
   * month then this API will get call each time when the 
   * user get into eod page until the holiday is updated for next year
  */
  const { data: isHolidayUpdated = [] } = useQuery(
    ['validate-holiday', lastRun?.current_system_date],
    () => getValidatedHoliday(),
    {
      /* checking the date, If month is greater then the december then this api will get call */
      enabled: Boolean(new Date(lastRun?.current_system_date) > new Date(`12/1/${new Date(lastRun?.current_system_date)?.getFullYear()}`)),
      onError: (data) => {
        displayNotification({ message: data, variant: 'error' })
      },
      refetchOnWindowFocus: false
    }
  )

  const columnData = [
    columnHelper.accessor("created_date", {
      header: "Executed on",
      cell: (value) => (dateFormat(value.getValue())),
    }),
    columnHelper.accessor("current_system_date", {
      header: "EOD Date",
      cell: (value) => (dateFormat(value.getValue())),
    }),
    columnHelper.accessor("start_time", {
      header: "Start Time",
      cell: (value) => (dateFormat(value.getValue(), 'hh:mm:ss'))
    }),
    columnHelper.accessor("end_time", {
      header: "End Time",
      cell: (value) => (dateFormat(value.getValue(), 'hh:mm:ss'))
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (value) => (<Text color={(value.getValue() == 'success') && 'green' || (value.getValue() == 'error') && 'red' || (value.getValue() == 'running') && 'blue'}>{value.getValue().toUpperCase()}</Text>)
    }),
  ];

  // Used to run the EOD
  const handleStartEod = () => {
    setLoading(true)
    handleRunEOD()
      .then((res) => {
        displayNotification({ message: 'EOD execution started successfully', variant: 'success' });
        logout()
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
        setShowEodDialog(false)
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 2000)
      })
  }

  const confirmEODClick = () => {
    setShowEodDialog(true)
  }

  return (
    <Body>
      <Box>
        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid.Col span={6} sx={{ display: 'flex', alignItems: 'center' }}>
            {/** if loading is true for table then the button will not visible */}
            <Title order={4} pl={'5px'}>EOD Status</Title>
            {!scheduledData?.eod_schedule_status
              ? (!isLoading
                ? <Button onClick={confirmEODClick} loading={loading} mx={10}>Run Now</Button>
                : <Text size={12} mx={10} color="gray">Please wait until we fetch the data or refresh the page</Text>
              )
              : null
            }
          </Grid.Col>
          <Grid.Col span={6} sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Box>
              <Text size={SIZES.body6} pl={SIZES.base / 2}>Last ran on</Text>
              <Text size={SIZES.body6} pl={SIZES.base / 2} sx={{ fontWeight: '500' }} color={COLORS.primary.main}> {dateFormat(lastRun?.current_system_date) || '-'}</Text>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
              <Text size={SIZES.body6}>Executed By</Text>
              <Text size={SIZES.body6} color={COLORS.primary.main} sx={{ fontWeight: 500 }}> {lastRun?.user_name || '-'}</Text>
            </Box>
          </Grid.Col>
          <CheckAllowed currentUser={auth?.group_id} action={action_id?.master?.eod_schedule}>
            <Grid.Col m={0} pt={0}>
              {/** used to schedule the EOD */}
              <EODSchedule setScheduledData={setScheduledData} />
            </Grid.Col>
          </CheckAllowed>
        </Grid>
      </Box>
      <ReactTable
        title="EOD"
        rowData={allEOD}
        columnData={columnData}
        loading={isLoading}
      />
      <DialogModal
        transitionProps={{ transition: 'skew-up' }}
        open={showEodDialog}
        title={<Text weight={700} size={20}>Do you want to proceed?</Text>}
        onClose={() => setShowEodDialog(false)}
      >
        <Box mt="md">
          <Text>Clicking <strong>Confirm</strong> will start the EOD process.</Text>
          <Text mt={'xs'}>
            Few things to keep in mind,
            <Box pb={'xs'} ml="lg" mt={'xs'}>
              <ul>
                <li>This is will log everyone out of the system.</li>
                <li>Users will not be able to raise any Reload requests.</li>
                <li>LMS will be inoperable until the EOD is complete.</li>
              </ul>
            </Box>
          </Text>
        </Box>
        <Box mt="xl" sx={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
          <Button variant="subtle" onClick={() => setShowEodDialog(false)}>
            Cancel
          </Button>
          <Button
            color={"red"}
            variant={"filled"}
            onClick={handleStartEod}
            loading={loading}
          >
            Confirm
          </Button>
        </Box>
      </DialogModal>
    </Body>
  );
};

export default EODTable;
