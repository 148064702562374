import { Grid, Text } from '@mantine/core';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import TitleComp from '../TextComponent/TitleComp';
import { COLORS } from '../../../constants';

const ControllerSelect = ({
  name,
  label,
  placeholder,
  control,
  required = false,
  onChangeTrigger = () => {},
  displayLabel = true,
  spanSize = 7,
  data,
  errors,
  type = 'select',
  minHeight = '57px',
  setValue,
  autoSelect = false,
  ...props
}) => {
  /** Auto select the data if there is only one option and autoselect is true */
  useEffect(() => {
    if(autoSelect && data?.length === 1) {
      setValue(name, data[0]?.value)
    }
  }, [data?.length])
  
  return (
    <Grid sx={{ display: 'flex', alignItems: 'center', fontWeight: '400' }}>
    {/* displayLabel used to display lable if true, By defalut it is true */}
      { displayLabel &&
        <Grid.Col span={5} sx={{ minHeight: minHeight, padding: '10px 8px 0 8px', display: 'flex' }}>
          <TitleComp label={label} />
          {required ? <Text ml={'4px'} color="red">*</Text> : null}
        </Grid.Col>
      }
      <Grid.Col span={spanSize}  sx={{ minHeight: minHeight, position: 'relative'}} py={'0'} px={'8px'}>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value, name, ref, ...rest } }) => (
            <>
              <Select
                id={name}
                placeholder={placeholder}
                styles={{ menu: (provided) => ({ ...provided, zIndex: 9999, }), option: (provided) => ({ ...provided, fontSize: '13px', padding: '5px 15px 5px 15px' }), control: (provided) => ({ ...provided, fontSize: '13px'})}}
                options={data}
                // used to clear the value
                // clearValue={!value ? true : false}
                value={data.find((c) => c?.value == value) || ''}
                onChange={(val) => {onChange(val.value); onChangeTrigger(val.value)}}
                error={errors? false : true}
                {...props}
                {...rest}
              />
              {type === 'bank' && value && !errors ? <Text size={'xs'} sx={{color: COLORS.gray(50), position: 'absolute', bottom: 3, left: 8}}>IFSC: {data.find((c) => c?.value == value)?.ifsc}</Text> : null}
            </>
          )}
        />
        {errors && <Text size={'xs'} sx={{color: '#f13e3e'}}>{errors}</Text>}
      </Grid.Col>
    </Grid>
  );
};

export default ControllerSelect;
