import { currencyAsInt } from "../../Currency/currencyFormat";

export const currencyInWords = (value) => {
  if(!value) return;
  var num = currencyAsInt(value);
  if ((num = num.toString()).length > 9) return;
  var n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? n[1] + " crore "
      : "";
  str +=
    n[2] != 0
      ? n[2] + " lakh "
      : "";
  str +=
    n[3] != 0
      ? n[3] + " thousand "
      : "";
  str +=
    n[4] != 0
      ? n[4] + " hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : " ") +
        [n[5]]
      : "";
  return str + " rupees";
};
