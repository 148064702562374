import React from 'react'
import { Grid, Box } from '@mantine/core';
import DataViewerWapper from './../../../components/CommonComponents/Preview/DataViewerWapper';
import TitleViewer from '../../../components/CommonComponents/Title/TitleViewer';
import { ViewFormData } from '../../../components/CommonComponents/Preview/FilePreview';

/**
 * Renders the Communication Invoice Review component.
 *
 * @param {object} data - The data object containing information to be displayed in the component.
 */
const CommunicationInvoiceReviewComp = ({ data }) => {
  return (
    <>
    <Grid gutter={'md'}>
      <Grid.Col span={6}>
        <DataViewerWapper>
          <Box mb={'sm'}>
            <TitleViewer title={"Communication"} />
          </Box>
          <ViewFormData
            label={'Phone No'}
            value={data?.data?.mobile}
            loading={data?.isLoading}
          />
          <ViewFormData
            label={'Fax No'}
            value={data?.data?.fax_no}
            loading={data?.isLoading}
          />
          <ViewFormData
            label={'E-Mail'}
            value={data?.data?.email}
            loading={data?.isLoading}
          />
          <ViewFormData
            label={'Home Page'}
            value={data?.data?.home_page}
            loading={data?.isLoading}
          />
          <ViewFormData
            label={'IC Partner Code'}
            value={data?.data?.ic_partner_code}
            loading={data?.isLoading}
          />
          <ViewFormData
            label={'Document Sending Profile'}
            value={data?.data?.document_sending_profile}
            loading={data?.isLoading}
          />
        </DataViewerWapper>
      </Grid.Col>
    </Grid>
    </>
  )
}

export default CommunicationInvoiceReviewComp