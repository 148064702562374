import React, { useEffect, useState } from "react";
import Body from "../../components/Body/Body";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllCustomersData, getCRROverrideRemarks, getCustomerTrancheDetails, getEnhancementDetails, getReceiptCountLimit, overrideCRRCheck, syncCustomerChangesById, updateReceiptCountLimit } from "../../services/customer.service";
import CustomerDetailsCard from "./../../components/Borrower/CustomerDetailsCard";
import {
  Box,
  useMantineTheme,
  Title,
  Text,
  Grid,
  Group,
  Stack,
  Switch,
  createStyles,
  Tooltip,
  Button,
  Accordion,
  ActionIcon,
  NumberInput,
  Tabs,
} from "@mantine/core";
import { COLORS } from "../../constants";
import Nums from "./../../components/Body/Nums";
import { dateFormat } from "./../../components/DateFormat/dateFormat";
import { sumBy } from "lodash";
import { DeviceFloppy, Edit, InfoCircle, TrendingDown, TrendingUp, X } from "tabler-icons-react";
import TextFieldEditor from './../../components/CommonComponents/TextEditor/TextFieldEditor';
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import DialogModal from "../../components/CommonComponents/Modal/DialogModal";
import { DrawerModal } from "../../components/CommonComponents/Drawer/DrawerModal";
import ContentBadge from "./CustomerBadge";
import { getAllProducts } from "../../services/master.service";
import { isAllowed } from "../../utils/roleBaseAccess";
import { action_id } from "../../config/userRules";
import useAuthStore from "../../store/authStore";

const syncHeaderKeys = [
  {
    label: "Agreement Initiated",
    key: "agreement_start_date",
    type: "date",
  },
  {
    label: "Agreement Ends",
    key: "agreement_end_date",
    type: "date",
  },
  {
    label: "Agreement Validity (Days)",
    key: "agreement_day_count",
    type: "string",
  },
  {
    label: "Documents Signed Status",
    key: "document_signed_status",
    type: "string",
  },
  {
    label: "Noc Status",
    key: "noc_status",
    type: "string",
  },
  {
    label: "Renewal Status",
    key: "renewal_status",
    type: "string",
  },
  {
    label: "Last Tranche with DPD",
    key: "is_last_tranches_with_dpd",
    type: "boolean",
  },
  {
    label: "Is Non Usage Funds",
    key: "is_non_usage_of_funds",
    type: "boolean",
  },
  {
    label: "Is NPA",
    key: "is_npa",
    type: "boolean",
  },
  {
    label: "NUF Day Count",
    key: "nuf_day_count",
    type: "string",
  },
  {
    label: "NOC Issued Date",
    key: "noc_issued_date",
    type: "date",
  },
  {
    label: "Product Name",
    key: "product_id",
    type: "string",
  }
]

const useStyles = createStyles((theme) => ({
  amountData: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    padding: "10px",
  },
  amountDataTitle: {
    display: "flex",
    gap: "20px",
    alignItems: "center"
  },
  borderBottom: {
    border: "1px solid #d6d6d6",
    height: "40px"
  },
  card: {
    width: "100%",
    border: '1px solid #eaeaea'
  },
  innerCardDetails: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  },
  titleDrawer: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '4px'
  },
  dataViwer: {
    background: COLORS.white,
    borderRadius: "5px",
    border: '1px solid #e0e0e0',
  },
  moduleInner: {
    background: "#f7f7f7",
    borderRadius: "8px",
    height: "480px",
    overflow: "hidden",
    overflowY: "scroll",
  },
  moduleInnerScroll: {
    height: "400px",
    overflow: "hidden",
    overflowY: "scroll",
  },
  cardDisplayArea: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    justifyContent: "center",
  },
  limitCard: {
    width: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  innerDataCard: {
    borderBottom: '1px solid #e8e8e8',
    '&:last-child': {
      border: 'none',
    }
  },
  root: {
    borderRadius: theme.radius.sm,
  },
  item: {
    backgroundColor: theme.colors.gray[0],
    borderBottom: '1px solid #e0e0e0',
    position: 'relative',
    zIndex: 0,
    transition: 'transform 150ms ease',
    '&[data-active]': {
      transform: 'scale(1.01)',
      backgroundColor: theme.white,
      boxShadow: theme.shadows.md,
      borderColor: theme.colors.gray[2],
      borderRadius: theme.radius.md,
      zIndex: 1,
    },
  },
  control: {
    '&[aria-expanded=true]': {
      color: COLORS.primary.main,
      background: 'rgb(38 139 255 / 8%)',
    },
  },
  chevron: {
    '&[data-rotate]': {
      transform: 'rotate(-180deg)',
    },
  },
  flexAlignCenter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '3px',
  },
  enhancementDetailBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '10px'
  },
  enhancementDetailBoxInner: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
  },
  enhancementDetailBoxLabel: {
    fontSize: '12px',
    color: 'gray'
  },
  enhancementDetailBoxValue: {
    fontSize: '12px',
    lineHeight: 0
  },
  syncModalCard: {
    background: COLORS.white,
    borderRadius: '5px',
    padding: '0px 12px',
    border: `1px solid ${COLORS.border.light}`,
    cursor: 'pointer',
    minWidth: '400px',
  }
}));

/**
 * Display amount
 * 
 * 
 * @param {Array} data - The data to display.
 * @param {string} labelSize - The size of the label text. [default value is 14px]
 * @param {number} order - The order of the heading. [default value is 2]
 */

const DisplayAmountData = ({ data, labelSize = '14px', order = 2 }) => {
  const { classes } = useStyles();

  return (
    <Body background={false}>
      <Box className={classes.amountData}>
        <Box className={classes.limitCard}>
          <Text size={labelSize} color="gray" mb={"5px"}>
            Approved
          </Text>
          <Box className={classes.amountDataTitle}>
            <Title order={order}>
              <Nums value={sumBy(data, 'approved_amount')} />
            </Title>
          </Box>
        </Box>
        <Box className={classes.limitCard}>
          <Text size={labelSize} color="gray" mb={"5px"}>
            Available
          </Text>
          <Box className={classes.amountDataTitle}>
            <Title order={order}>
              <Nums value={sumBy(data, 'available_amount')} />
            </Title>
          </Box>
        </Box>
        <Box className={classes.limitCard}>
          <Text size={labelSize} color="gray" mb={"5px"}>
            Disbursed {data?.[0]?.tranche_with_outstanding_principle_count ? <Tooltip position="bottom" color="gray" withArrow label='Total no of active tranches'><span>({data?.[0]?.tranche_with_outstanding_principle_count})</span></Tooltip> : null}
          </Text>
          <Box className={classes.amountDataTitle}>
            <Title order={order}>
              <Nums value={sumBy(data, 'disbursed_amount')} />
            </Title>
          </Box>
        </Box>
        <Box className={classes.limitCard}>
          <Text size={labelSize} color="gray" mb={"5px"}>
            In Transit
          </Text>
          <Box className={classes.amountDataTitle}>
            <Title order={order}>
              <Nums value={(parseFloat(sumBy(data, "used_amount"))) - parseFloat(sumBy(data, "disbursed_amount"))} />
            </Title>
          </Box>
        </Box>
        <Box className={classes.limitCard}>
          <Text size={labelSize} color="gray" mb={"5px"}>
            Excess
          </Text>
          <Box className={classes.amountDataTitle}>
            <Title order={order}>
              <Nums value={parseFloat(data?.[0]?.excess_amount)} />
            </Title>
          </Box>
        </Box>
      </Box>
    </Body>
  );
};

/**
 * Renders a display of data with a label, value, and type. If the type is a string, renders the value
 * in a Title component. If the type is a boolean, renders "Yes" if the value is true and "No" if the value is
 * false in a Title component. If the type is an amount, renders the value in a Num component. If the type 
 * is a date, renders the value in a Title component with the format "DD MMM YYYY". If the type is a check,
 * renders a Switch component with the checked value equal to the "value" prop. Clicking the Switch opens a modal
 * that allows the user to change the value and submit the change to the server. 
 *
 * @param {string} label - the label to display for the data
 * @param {string} value - the value of the data to display
 * @param {string} type - the type of data to display (string, boolean, amount, date, or check)
 * @param {number} id - the id of the customer application
 */

const DisplayData = ({ label, value, type, id, auth }) => {
  const { classes } = useStyles();
  const queryClient = useQueryClient();
  const [isCheck, setIsCheck] = useState({ modal: false, value: type === 'check' && value })
  const [remarks, setRemarks] = useState('');
  const [receiptCount, setReceiptCount] = useState({});

  const { data: receiptLimitCount = {}, refetch: refetchReceiptLimitCount } = useQuery(
    ['receipt-limit-count', id],
    () => getReceiptCountLimit({ custAppId: id }),
    {
      onSuccess: (data) => {
        setReceiptCount({ value: data?.receipt_limit });
      },
      enabled: Boolean(type === 'object' && id),
    }
  );

  const updateReceiptCountQuery = useMutation((body) => updateReceiptCountLimit(body));

  const changeCRRCheck = () => {
    overrideCRRCheck({ remarks, customer_application_id: id, override_crr_validation: isCheck.value ? 1 : 0 })
      .then((res) => {
        displayNotification({ message: 'CRR Check Override Successfully', variant: 'success' })
        queryClient.invalidateQueries('customer-details-by-id')
      })
      .catch((err) => {
        displayNotification({ message: err.message || err, variant: 'error' })
      })
      .finally(() => {
        setIsCheck({ modal: false, value: isCheck.value })
      });
  }

  const updateReceiptCountCall = () => {
    setReceiptCount((old) => ({ ...old, modal: false }));
    updateReceiptCountQuery.mutate({ body: { customer_application_id: id, receipt_limit: receiptCount.value } }, {
      onSuccess: (data) => {
        displayNotification({ message: 'Receipt Limit Updated Successfully', variant: 'success' });
      },
      onError: (err) => {
        displayNotification({ message: err.message || err, variant: 'error' });
      },
      onSettled: () => {
        setReceiptCount({});
        refetchReceiptLimitCount();
      }
    })
  }

  const handleData = () => {
    if (type === "string") return <Title order={6}>{value || "-"}</Title>;
    else if (type === "boolean")
      return (
        <Title order={6} sx={{ textTransform: "capitalize" }}>
          {Boolean(value) ? "Yes" : "No"}
        </Title>
      );
    else if (type === "amount")
      return (
        <Title order={6}>
          <Nums value={value} />
        </Title>
      );
    else if (type === "date")
      return <Title order={6}>{dateFormat(value, 'DD MMM YYYY')}</Title>;
    else if (type === "check")
      return <Switch checked={isCheck.value} onChange={(event) => setIsCheck({ modal: true, value: event.currentTarget.checked })} onLabel="ON" offLabel="OFF" size="md" color="teal" styles={() => ({ input: { cursor: 'pointer' } })} />;
    else if (type === "object")
      return (
        receiptCount?.modal ? (
          <Group>
            <NumberInput
              value={receiptCount?.value}
              onChange={(value) => setReceiptCount((old) => ({ ...old, value }))}
              min={1}
              size="xs"
              sx={{ width: 50 }}
              step={1}
            />
            <Tooltip label={<Text size={'xs'}>Save</Text>} color="gray" withArrow position="bottom"><ActionIcon p={0} m={0} size={'xs'} onClick={() => updateReceiptCountCall()}><DeviceFloppy color="teal" /></ActionIcon></Tooltip>
            <Tooltip label={<Text size={'xs'}>Cancel</Text>} color="gray" withArrow position="bottom"><ActionIcon p={0} m={0} size={'xs'} onClick={() => setReceiptCount({ modal: false, value })}><X color="red" /></ActionIcon></Tooltip>
          </Group>
        ) : (
          <Title order={6}>{receiptLimitCount?.receipt_limit || "-"}</Title>)
      );
  };
  return (
    <>
      <Group py={'xs'} position={'apart'}>
        <Text size={'12px'} color="gray" className={classes.innerCardDetails}>
          {label}{isAllowed(auth?.group_id, '', action_id?.button?.receipt_limit) && type === 'object' && !receiptCount?.modal
            ? <Tooltip label={<Text size={'xs'}>Click to edit the receipt count</Text>} color="gray" withArrow position="bottom"><ActionIcon size={'xs'} onClick={() => setReceiptCount((old) => ({ ...old, modal: true }))} loading={updateReceiptCountQuery?.isLoading}><Edit size={12} /></ActionIcon></Tooltip>
            : null}
          {type === 'check' ?
            <Tooltip multiline label={'Disabling CRR checks will skip all checks during reload requests.'} width={200} color={'gray'} withArrow position="top-end">
              <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <InfoCircle size={14} />
              </span>
            </Tooltip>
            : null}
        </Text>
        <Box className={classes.amountDataTitle}>
          {handleData()}
        </Box>
      </Group>
      <DialogModal size={'600px'} open={isCheck.modal} onClose={() => setIsCheck({ modal: false, value: !isCheck.value })} title="Are you sure?">
        {isCheck?.value
          ? <Text size={'sm'}>Post submission all checks will be skipped while raising a reload request for this application. Customer can raise CRR if they have valid limit!</Text>
          : <Text size={'sm'}>Post submission all checks will get applied while raising a reload request for this application.</Text>
        }
        <Box>
          <TextFieldEditor
            value={remarks}
            onChange={setRemarks}
            style={{ minHeight: 200, marginTop: '10px' }}
          />
          <Box mt={'xs'} sx={{ display: "flex", justifyContent: "flex-end", gap: '10px' }}>
            <Button variant="outline" onClick={() => setIsCheck({ modal: false, value: !isCheck.value })}>Cancel</Button>
            <Button onClick={() => changeCRRCheck()}>Submit</Button>
          </Box>
        </Box>
      </DialogModal>
    </>
  );
};

/**
 * Render a list of accordion items based on the provided data and filter.
 *
 * @param {Array} data - The array of data to be rendered in accordion items.
 * @param {string} filter - The filter to be applied on the data.
 */
const AccordionDataList = ({ data = [], filter = 'enhancement' }) => {
  const { classes } = useStyles();
  return (
    <Accordion
      mx="auto"
      defaultValue="customization"
      classNames={classes}
      className={classes.root}
    >
      {data?.map((item, index) =>
        <Accordion.Item value={`${item?.new_product_name}-${index}`}>
          <Accordion.Control>
            <Group position="apart">
              <Group spacing={'md'} sx={{ fontWeight: 500 }}>
                <Title order={5} sx={{ fontSize: '14px' }}>
                  {item?.new_product_name}
                </Title>
                {item?.enhancement_amount ? <Text size={'14px'} className={classes.flexAlignCenter}>
                  <Nums value={item?.enhancement_amount} />
                  {item?.new_approved_amount < item?.old_approved_amount
                    ? <TrendingDown color="red" size={16} />
                    : item?.new_approved_amount > item?.old_approved_amount
                      ? <TrendingUp color="green" size={16} />
                      : null
                  }
                </Text> : null}
              </Group>
              <Text size={'14px'} color="gray">{dateFormat(item?.enhancement_date, "DD MMM YYYY")}</Text>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Stack spacing={'10px'} mt={'10px'}>
              <Box className={classes?.enhancementDetailBox}>
                <Box className={classes?.enhancementDetailBoxInner} sx={{ width: '350px' }}>
                  <Text className={classes?.enhancementDetailBoxLabel}>Existing Product:</Text>
                  <Title className={classes?.enhancementDetailBoxValue}>{item?.old_product_name}</Title>
                </Box>
                <Box className={classes?.enhancementDetailBoxInner}>
                  <Text className={classes?.enhancementDetailBoxLabel}>New Product:</Text>
                  <Title className={classes?.enhancementDetailBoxValue}>{item?.new_product_name}</Title>
                </Box>
              </Box>
              <Box className={classes?.enhancementDetailBox}>
                <Box className={classes?.enhancementDetailBoxInner} sx={{ width: '350px' }}>
                  <Text className={classes?.enhancementDetailBoxLabel}>Existing Approved Amount:</Text>
                  <Title className={classes?.enhancementDetailBoxValue}><Nums value={item?.old_approved_amount} /></Title>
                </Box>
                <Box className={classes?.enhancementDetailBoxInner}>
                  <Text className={classes?.enhancementDetailBoxLabel}>New Approved Amount:</Text>
                  <Title className={classes?.enhancementDetailBoxValue}><Nums value={item?.new_approved_amount} /></Title>
                </Box>
              </Box>
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
      )}
    </Accordion>
  )
}

/**
 * CustomerDetailsSideModal renders a modal window that displays customer details.
 *
 * @param {object} props - The props object.
 * @param {boolean} props.open - Indicates whether or not the modal is open.
 * @param {function} props.onClose - The function to be called when the modal is closed.
 * @param {object} props.data - An object containing customer data.
 * @param {array} props.headers - An array of objects containing information about each header.
 * @param {string} props.headers.label - The label of the header.
 * @param {string} props.headers.key - The key of the header in the customer data object.
 * @param {string} props.headers.type - The type of data contained in the header.
 * @param {string} props.headers.info - Additional information about the header.
 * @param {string} props.headers.progress_color - The color of the progress bar associated with the header.
 * @param {string} props.headers.progress_key - The key to use when calculating the progress bar value.
 * @return {JSX.Element} The modal window containing customer details.
 */

const CustomerDetailsSideModal = ({ open, onClose, data, headers }) => {
  const theme = useMantineTheme();
  const auth = useAuthStore(store => store.auth)
  const queryClient = useQueryClient();
  const { classes } = useStyles();
  const [modalObj, setModalObj] = useState({});
  const [filterEnc, setFilterEnc] = useState();

  const overrideCRRRemarksQuery = useQuery(
    ["override-crr-remarks", data?.customer_application_id],
    () => getCRROverrideRemarks(data?.customer_application_id),
    {
      enabled: Boolean(data?.customer_application_id),
    }
  )

  const enhancementDetailsQuery = useQuery(
    ["enhancement-details", data?.customer_application_id],
    () => getEnhancementDetails(data?.customer_application_id),
    {
      enabled: Boolean(data?.customer_application_id),
      select: (data) => {
        return {
          all_data: [...data],
          enhancement_data: data?.filter((i => i?.module_name === 'enhancement')),
          renewal_data: data?.filter((i => i?.module_name === 'renewal')),
          noc_data: data?.filter((i => i?.module_name === 'noc'))
        }
      }
    }
  )

  const productsDetailsQuery = useQuery(
    ["all-products"],
    () => getAllProducts(),
  )

  useEffect(() => {
    if (enhancementDetailsQuery?.data?.enhancement_data?.length) {
      setFilterEnc('enhancement')
    } else if (enhancementDetailsQuery?.data?.renewal_data?.length) {
      setFilterEnc('renewal')
    } else if (enhancementDetailsQuery?.data?.noc_data?.length) {
      setFilterEnc('noc')
    }
  }, [enhancementDetailsQuery?.data])

  /**
   * Handles the synchronization of customer changes by ID.
   *
   * @param {boolean} is_merge - Indicates whether to merge changes or not.
   * @return {Promise} A promise that resolves with the result of the synchronization.
   */
  const handleSync = (is_merge = false) => {
    setModalObj((old) => ({
      ...old, sync_loading: true,
    }))
    syncCustomerChangesById({ customer_application_id: data?.customer_application_id, is_merge })
      .then((res) => {
        if (!is_merge && Object.keys(res?.data?.[0]?.new_application_meta_details)?.length > 0) {
          displayNotification({ message: 'There are changes!', variant: 'warning' })
          /**
           * @todo: It will be refactor once the API changes are done (needed the product_name instead of product_id in sync API)
           * 
           * This is used to handle the product id changes
           * if there is a change in product we need to display the product name but in api there is only product_id
           * to handle this we are using the following if statements
           */
          if (res?.data?.[0]?.new_application_meta_details?.product_id) {
            // if there is a change in product id if statement will get executed
            // here we will find the product name and assign it to the state
            setModalObj({
              modal: true,
              old_data: { ...res?.data?.[0]?.current_application_meta_details, product_id: productsDetailsQuery?.data?.find((e) => e?.id === res?.data?.[0]?.current_application_meta_details?.product_id)?.name },
              new_data: { ...res?.data?.[0]?.new_application_meta_details, product_id: productsDetailsQuery?.data?.find((e) => e?.id === res?.data?.[0]?.new_application_meta_details?.product_id)?.name },
            })
          } else {
            setModalObj({
              modal: true,
              old_data: res?.data?.[0]?.current_application_meta_details,
              new_data: res?.data?.[0]?.new_application_meta_details,
            })
          }
        } else if (!is_merge && !Object.keys(res?.data?.[0]?.new_application_meta_details)?.length) {
          displayNotification({ message: 'There are no changes available, it is update to date.', variant: 'success' })
        } else if (is_merge === true) {
          setModalObj({
            modal: false,
          })
          displayNotification({ message: res?.message, variant: 'success' })
          queryClient.invalidateQueries('customer-details-by-id');
          onClose();
        }
      })
      .catch((err) => {
        displayNotification({ message: err?.message || err, variant: 'error' })
      })
  }

  const handleData = (type, value) => {
    if (type === "string") return <Title order={6}>{value || "-"}</Title>;
    else if (type === "boolean")
      return (
        <Title order={6} sx={{ textTransform: "capitalize" }}>
          {Boolean(value) ? "Yes" : "No"}
        </Title>
      );
    else if (type === "amount")
      return (
        <Title order={6}>
          <Nums value={value} />
        </Title>
      );
    else if (type === "date")
      return <Title order={6}>{dateFormat(value, 'DD MMM YYYY')}</Title>;
  };

  return (
    <>
      <DrawerModal
        open={open}
        onClose={onClose}
        title={<Box className={classes.titleDrawer}><Title order={3}>{data?.customer_name}</Title><Text size={'xs'}>({data?.customer_id})</Text></Box>}
        overlayProps={{ opacity: 0.15, blur: 1, color: theme.colors.dark[2] }}
        position="right"
        transitionProps={{ transition: "slide-left", duration: 250, timingFunction: 'ease' }}
        padding="xl"
        size="60%"
        closeOnEscape={false}
      >
        <Box sx={{ width: '100%' }} mb={'sm'}>
          <DisplayAmountData data={[data]} labelSize={"12px"} order={3} />
        </Box>
        <Grid sx={{ height: "90%", overflow: "scroll", marginBottom: "20px" }}>
          {headers?.map((innerHeaders, i) => (
            <Grid.Col span={6} mb={"12px"} key={i}>
              <Box
                px={'md'}
                sx={{
                  border: "1px solid #e6e6e6",
                  borderRadius: "8px",
                  minHeight: '245px',
                }}
              >
                {innerHeaders?.map((item, index) => (
                  <Box key={index} className={classes.innerDataCard}>
                    <DisplayData
                      value={data?.[item?.key]}
                      label={item.label}
                      type={item.type}
                      info={item.info}
                      id={data?.customer_application_id}
                      auth={auth}
                    />
                  </Box>
                ))}
              </Box>
            </Grid.Col>
          ))}
          {enhancementDetailsQuery?.data?.all_data?.length
            ? (
              <Grid.Col mb={'lg'}>
                <Tabs value={filterEnc} onTabChange={setFilterEnc}>
                  <Tabs.List>
                    {enhancementDetailsQuery?.data?.enhancement_data?.length ? <Tabs.Tab value={'enhancement'}>Enhancement</Tabs.Tab> : null}
                    {enhancementDetailsQuery?.data?.renewal_data?.length ? <Tabs.Tab value={'renewal'}>Renewal</Tabs.Tab> : null}
                    {enhancementDetailsQuery?.data?.noc_data?.length ? <Tabs.Tab value={'noc'}>NOC</Tabs.Tab> : null}
                  </Tabs.List>
                  <Tabs.Panel value="enhancement">
                    <AccordionDataList data={enhancementDetailsQuery?.data?.enhancement_data} filter="enhancement" />
                  </Tabs.Panel>
                  <Tabs.Panel value="renewal">
                    <AccordionDataList data={enhancementDetailsQuery?.data?.renewal_data} filter="renewal" />
                  </Tabs.Panel>
                  <Tabs.Panel value="noc">
                    <AccordionDataList data={enhancementDetailsQuery?.data?.noc_data} filter="noc" />
                  </Tabs.Panel>
                </Tabs>
              </Grid.Col>
            )
            : null
          }
          <Group position="right" sx={{ width: '100%', }} mb={'xl'}>
            <Button color="orange" onClick={() => handleSync(false)}>
              Sync
            </Button>
          </Group>
        </Grid>
      </DrawerModal>
      <DialogModal
        title={'Changes'}
        opened={modalObj?.modal}
        onClose={() => {
          setModalObj({ modal: false })
        }}
        size={'auto'}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '24px' }}>
          <Box className="syncModalCard">
            <Group position="right" mr={'xs'}>
              <Group position="right" sx={{ width: '200px' }}>
                <Text size={'xs'} sx={{ fontWeight: 600 }} color={COLORS.gray(50)}>
                  Existing
                </Text>
              </Group>
              <Group position="right" sx={{ width: '200px' }}>
                <Text size={'xs'} sx={{ fontWeight: 600 }} color={COLORS.gray(50)}>
                  New
                </Text>
              </Group>
            </Group>
            <Box className={classes.syncModalCard}>
              {modalObj?.old_data ? Object.entries(modalObj?.old_data)?.map(([key, value], index) => {
                return (
                  <Group key={index} py={'xs'} position={'apart'} className={classes.innerDataCard}>
                    <Text size={'14px'} color={COLORS.gray()} className={classes.innerCardDetails} sx={{ width: '200px' }}>
                      {syncHeaderKeys?.find(e => e?.key === key)?.label}
                    </Text>
                    <Group sx={{ width: '200px' }} position={'right'}>
                      {handleData(syncHeaderKeys?.find(e => e?.key === key)?.type, value)}
                    </Group>
                    <Group sx={{ width: '200px' }} position={'right'}>
                      {handleData(syncHeaderKeys?.find(e => e?.key === key)?.type, modalObj?.new_data?.[key])}
                    </Group>
                  </Group>
                )
              }) : null
              }
            </Box>
          </Box>
        </Box>
        <Group position="right" mt={'md'}>
          <Tooltip color="gray" label="Once you done, the following Changes will get reflected in LMS" withArrow>
            <Button color="orange" loading={modalObj?.sync_loading} onClick={() => handleSync(true)}>Sync Changes</Button>
          </Tooltip>
        </Group>
      </DialogModal>
    </>
  );
};

const CustomerDetails = () => {
  const { customerAppId, custId } = useParams();
  const [sideDrawerOpen, setSideDrawerOpen] = useState({});
  const [isCheck, setIsCheck] = useState();
  const [badgeType, setBadgeType] = useState();

  // get the customer details buy cust id
  const customerDetailsQuery = useQuery(
    ["customer-details-by-id", customerAppId],
    () => getAllCustomersData({ page: 1, customer_application_id: customerAppId }),
    {
      enabled: Boolean(customerAppId),
    }
  );

  const { data: trancheDetails = {} } = useQuery(
    ["tranche-details", isCheck, customerAppId],
    () => getCustomerTrancheDetails(custId, isCheck ? 12 : null),
    {
      onSuccess: (data) => {
        setBadgeType(data?.badge)
      }
    }
  );

  const sideDrawerHeaders = [
    [
      {
        label: "Scheme",
        key: "product_name",
        type: "string",
        title: "Customer Details",
      },
      {
        label: "Agreement Initiated",
        key: "agreement_start_date",
        type: "date",
      },
      {
        label: "Agreement Ends",
        key: "agreement_end_date",
        type: "date",
      },
      {
        label: "Agreement Validity (Days)",
        key: "agreement_day_count",
        type: "string",
        info: "No of days to get document expired",
      },
      {
        label: "Renewal Fee Date",
        key: "renewal_fee_date",
        type: "date",
      },
      {
        label: "Documents Signed Status",
        key: "document_signed_status",
        type: "string",
      },
      {
        label: "Renewal Status",
        key: "renewal_status",
        type: "string",
        title: "Loan Details",
      },
      {
        label: "Deferral Status",
        key: "deferral_status",
        type: "string",
      },
      {
        label: "Deviation Status",
        key: "deviation_status",
        type: "string",
      },
    ],
    [
      {
        label: "Active Tranche Count",
        key: "tranche_with_outstanding_principle_count",
        type: "string",
        info: "Active tranche with outstanding principle count",
      },
      {
        label: "Advance Interest",
        key: "total_advance_interest",
        type: "amount",
        // info: "Active tranche with outstanding principle count",
      },
      {
        label: "Last Tranche with DPD",
        key: "is_last_tranches_with_dpd",
        type: "boolean",
        info: "Dose last tranche has DPD or not",
      },
      {
        label: `Is Non Usage Funds ${sideDrawerOpen?.data?.nuf_day_count ? '(' + sideDrawerOpen?.data?.nuf_day_count + ')' : ''}`,
        key: "is_non_usage_of_funds",
        type: "boolean",
      },
      {
        label: 'Receipt Count',
        key: "nuf_day_count",
        type: "object",
      },
      {
        label: "Is NPA",
        key: "is_npa",
        type: "boolean",
      },
      {
        label: "NOC Status",
        key: "noc_status",
        type: "string",
      },
      {
        label: "CRR Allowed",
        key: "is_crr_allowed",
        type: "boolean",
      },
      {
        label: "Override CRR Checks",
        key: "override_crr_validation",
        type: "check",
      },
    ],
  ];

  return (
    <>
      <Body background={false}>
        {badgeType ? <ContentBadge type={badgeType} /> : null}
        <PageTitle
          title={customerDetailsQuery?.data?.data?.[0]?.customer_name}
          id={customerDetailsQuery?.data?.data?.[0]?.customer_id}
          breadcrumb={false}
        />
        <Box mt={'50px'}>
          <CustomerDetailsCard id={custId} data={customerDetailsQuery?.data?.data} trancheDetails={trancheDetails} setSideDrawerOpen={setSideDrawerOpen} isCheck={isCheck} setIsCheck={setIsCheck} />
        </Box>
      </Body>
      <CustomerDetailsSideModal
        open={sideDrawerOpen?.modal}
        onClose={() => setSideDrawerOpen({})}
        headers={sideDrawerHeaders}
        data={sideDrawerOpen?.data}
      />
    </>
  );
};

export default CustomerDetails;
