import React from 'react'
import Body from '../../components/Body/Body';
import PageTitle from '../../components/PageTitle/PageTitle';
import CustomerCreationInvoiceTabs from './InvoiceCustomerComp/CustomerCreationInvoiceTabs';
import { Box, createStyles } from '@mantine/core';
import { COLORS } from '../../constants';

const useStyles = createStyles({
  mainBox: {
    background: COLORS.white,
    borderRadius: 8,
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1)",

  },
  tabPanelRoot: {
    padding: '24px',
    marginTop: '10px'
  }
})

const CustomerInvoiceCreation = () => {
  const {classes } = useStyles();

  return (
    <>
      <Body background={false}>
        <PageTitle title={'e-Invoice'} />
      </Body>
      <Body background={false}>
        <Box className={classes?.mainBox}>
          <CustomerCreationInvoiceTabs />
        </Box>
      </Body>
    </>
  )
}

export default CustomerInvoiceCreation