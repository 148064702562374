import React, { useState } from "react";
import { Box, Button, Divider, Title, createStyles } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Plus } from "tabler-icons-react";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import Fee from "./Fee";
import ReactTable from "../../../../components/CommonComponents/Table/ReactTable";
import { getMasterFee } from "../../../../services/master.service";
import Body from '../../../../components/Body/Body';
import { DrawerModal } from "../../../../components/CommonComponents/Drawer/DrawerModal";

const useStyles = createStyles({
  main: {    
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "center",
    width: "100%",
  }
})

const FeeTable = () => {
  const { classes } = useStyles();
  const columnHelper = createColumnHelper();
  const [feeFormData, setFeeFormData] = useState();
  const [feeForm, formHandler] = useDisclosure(false);
  /* use-disclosure hook manages boolean state. It provides open, close and toggle */
  const { data: fees = [], isLoading } = useQuery(
    ["master-fee"],
    () => getMasterFee(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const columnData = [
    columnHelper.accessor("name", {
      header: "Name",
      cell: (value) => (
        <span
          style={{ color: "#0063FF", cursor: "pointer" }}
          onClick={() => {
            setFeeFormData(value.row.original);
            formHandler.open();
          }}
        >
          {value.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor("description", {
      header: "Description",
    }),
  ];

  const handleClose = () => {
    formHandler.close();
    setFeeFormData();
  };

  return (
    <Body>
      <Box className={classes.main}>
        <Title order={4} pl={'5px'}>Fee</Title>
        <Button
          leftIcon={<Plus size={16} />}
          variant="subtle"
          onClick={() => formHandler.open()}
        >
          Add Fee
        </Button>
      </Box>
      <ReactTable
        title="Fee"
        rowData={fees}
        columnData={columnData}
        loading={isLoading}
      />
      <DrawerModal
        open={feeForm}
        onClose={() => {
          handleClose();
        }}
        position="right"
        padding="xl"
        title={feeFormData?.name ? feeFormData?.name : "Add New Fee"}
        overlayProps={{ blur: 2 }}
        size="43%"
      >
        <Divider />
        <Fee
          id={feeFormData?.id}
          formData={feeFormData}
          onClose={() => handleClose()}
        />
      </DrawerModal>
    </Body>
  );
};

export default FeeTable;
