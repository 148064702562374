import React, { useState } from 'react'
import {
  Group,
  Text,
  Title,
  Grid,
  Button,
  Stack,
  Menu,
} from '@mantine/core'
import { Dropzone, MS_EXCEL_MIME_TYPE } from '@mantine/dropzone'
import { IconCheck, IconTrash, IconUpload } from '@tabler/icons-react'
import { IconX } from '@tabler/icons-react'
import DialogModal from '../Modal/DialogModal'
import { COLORS } from '../../../constants'
import styled from '@emotion/styled'
import { IconFileDots } from '@tabler/icons-react'
import { ChevronDown, Download, Upload } from 'tabler-icons-react'

const DataWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  border-left: 0.125rem dashed red;
  border-bottom: 0.125rem dashed red;
  border-bottom-left-radius: 5px;
  padding-left: 0.3rem;
  padding-right: 0.2rem;
  padding-top: 0.2rem;
  z-index: 999;
`
const SelectedFileName = styled.div`
  font-size: 10px;
  position: absolute;
  top: 90%;
  left: 13px;
  width: 92%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  color: teal;
  justify-content: center;
`

// handling the file upload and download for bulk payment
const FileUploadDownloadComp = ({
  open,
  onClose,
  title,
  label,
  uploadLoading = false,
  downloadLoading = false,
  onFileUpload = () => { },
  onFileDownload = () => { },
  setFileState = () => { },
  fileState = {},
  dropDownText = 'Drag file here or click to select file',
  dropDownTextHelper = 'Attach a file which you need to upload, file should not exceed 5mb',
  uploadText = 'Upload Bulk Payment',
  uploadTextHelper = 'Once the file has been uploaded, payment will move to next stage',
  ...props
}) => {
  const [fileObj, setFileObj] = useState({});

  return (
    <>
      <Menu
        shadow="md"
        width={200}
        position='bottom-end'
        withArrow
        arrowPosition='center'
        opened={Boolean(fileState?.menu)}
        onClose={() => setFileState((old) => ({ ...old, menu: false }))}
      >
        <Menu.Target>
          <Button
            variant='outline'
            rightIcon={<ChevronDown size={18} />}
            size='sm'
            loading={downloadLoading}
            onClick={() => setFileState({ menu: true })}
            color={'blue'}
          >
            {label}
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Actions</Menu.Label>
          <Menu.Item icon={<Upload size={18} />} onClick={() => setFileState({ modal: true })}>
            Upload
          </Menu.Item>
          <Menu.Item icon={<Download size={18} />} onClick={() => {setFileState({}); onFileDownload();}}>
            Download
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      {/* Upload modal */}
      <DialogModal
        opened={open}
        onClose={() => {onClose(); setFileObj({});}}
        title={<Title order={5}>{title}</Title>}
        overlayBlur={3}
        size='lg'
        centered
        padding='xl'
      >
        <Grid justify="center">
          <Grid.Col span={7} w={'60%'} sx={{ position: 'relative' }}>
            <Dropzone
              onDrop={(files) => setFileObj({ ...fileObj, file: files })}
              onReject={(files) => console.log('rejected files', files)}
              maxSize={3 * 1024 ** 2}
              accept={MS_EXCEL_MIME_TYPE}
              loading={uploadLoading}
              {...props}
              styles={{
                root: {
                  border: `0.125rem dashed ${COLORS.blue(50)}`,
                }
              }}
            >
              <Group position="center" spacing="xl" style={{ pointerEvents: 'none', minHeight: 200 }}>
                <Dropzone.Accept>
                  <IconCheck
                    size="3.2rem"
                    stroke={1.5}
                    color={COLORS.green(50)}
                  />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX
                    size="3.2rem"
                    stroke={1.5}
                  />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  {!fileObj?.file
                    ? <IconUpload size="3.2rem" stroke={1.5} color={COLORS.blue(50)} />
                    : <IconFileDots size="3.2rem" stroke={1.5} color={COLORS.green(50)} />
                  }
                </Dropzone.Idle>
                <center>
                  <Text size="xl">{dropDownText}</Text>
                  <Text mt={'md'} sx={{ fontSize: 11 }} color={COLORS.gray(50)}>{dropDownTextHelper}</Text>
                </center>
              </Group>
            </Dropzone>
            {fileObj?.file ? (
              <DataWrapper onClick={() => { setFileObj({}) }}>
                <IconTrash size="1.2rem" stroke={1.5} color={COLORS.red()} />
              </DataWrapper>
            ) : null}
            {fileObj?.file ? (
              <SelectedFileName>{fileObj?.file?.[0]?.name}</SelectedFileName>
            ) : null}
          </Grid.Col>
          <Grid.Col span={5}>
            <Stack h={210} justify='center'>
              <center>
                <Text>{uploadText}</Text>
                <Text mt={'md'} sx={{ fontSize: 11 }} color={COLORS.gray(50)}>{uploadTextHelper}</Text>
                {fileObj?.file
                  ? <Button
                    leftIcon={<IconUpload />}
                    color={'green'}
                    onClick={() => {
                      setFileState(old => ({ ...old, loading: true }));
                      onFileUpload(fileObj?.file)
                    }}
                    fullWidth
                    loading={uploadLoading}
                    mt={'md'}
                  >
                    Upload
                  </Button> : null
                }
              </center>
            </Stack>
          </Grid.Col>
        </Grid>
      </DialogModal>
    </>
  )
}

export default FileUploadDownloadComp