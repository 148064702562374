import TitleComp from "../CommonComponents/TextComponent/TitleComp";
import {
  ActionIcon,
  Grid,
  Popover,
  Text,
  SegmentedControl,
} from "@mantine/core";
import Nums from "../Body/Nums";
import { COLORS } from "../../constants";
import { AlertCircle } from "tabler-icons-react";
import { useEffect, useState } from "react";
import { currencyAsInt } from "../Currency/currencyFormat";
import { getFeeAmountWithGST } from "../../services/customer.service";
import { displayNotification } from "../CommonComponents/Notification/displayNotification";

// used to display the fee data with key and label
const DisplayFeeData = ({ value, label, type = "currency" }) => {
  return (
    <Grid gutter="md">
      <Grid.Col span={8}>
        <TitleComp label={label} />
      </Grid.Col>
      <Grid.Col span={4}>
        <Text size="sm" weight={600} sx={{ color: COLORS.primary.dark }}>
          {type === "currency" ? (
            <Nums value={value} />
          ) : (
            value
          )}
        </Text>
      </Grid.Col>
    </Grid>
  );
};

// used to display the fee data with key and label in contained mode
const DisplayContainedFeeData = ({ value, label }) => {
  return (
    <Grid
      gutter="md"
      sx={{ backgroundColor: COLORS.primary.main, color: COLORS.white }}
    >
      <Grid.Col span={8}>
        <TitleComp label={label} />
      </Grid.Col>
      <Grid.Col span={4}>
        <Text size="sm" weight={600}>
          <Nums value={value} />
        </Text>
      </Grid.Col>
    </Grid>
  );
};

/** In this comp there is a popover which contain the fee calculation with GST */
export const ViewFeeDetailsWithGST = ({
  type,
  value,
  loanType,
  setValue,
  loanAmount,
  currentData,
  selectedData = {},
  createdLoanId,
  customerAppId,
  calculatedFee,
  approvedAmount,
  setCalculateFee,
  additionalDeduction,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  // handling the api for fee calculation with gst
  // feeData has a type for what amount the fee is calculating
  const getFeeAmount = (feeData) => {
    let result = feeData || value?.fee_type;
    let calculateBody = {
      fee_id: selectedData?.fee_id,
      loan_id: createdLoanId,
      fee_collection_type: result,
      loan_type: loanType,
      fee_base_amount:
        result == "fee_base_is_approved_amount"
          ? currencyAsInt(approvedAmount)
          : currencyAsInt(loanAmount),
    };
    if (calculateBody?.fee_base_amount && selectedData?.fee_id) {
      getFeeAmountWithGST(customerAppId, calculateBody)
        .then((res) => {
          if (res?.message) {
            displayNotification({ message: res?.message, variant: 'warning' })
          } else {
            additionalDeduction?.map((item) => {
              if (item?.fee_type === "fee") {
                item.fee_collection_type = calculateBody?.fee_collection_type;
                item.total_amount = res?.total_amount;
                item.fee_base_amount = res?.fee_base_amount;
                item.fee_value = res?.total_amount;
                item.fee_id = selectedData?.fee_id;
              }
            })
          }
          setCalculateFee(res);
        })
        .catch((e) => {
          setCalculateFee({});
          displayNotification({ message: e?.message || e, variant: 'error' });
        });
    }
  };

  useEffect(() => {
    if (currentData) {
      setValue((old) => ({ ...old, data: currentData, fee_type: type ? type : old?.fee_type }));
      getFeeAmount(type);
    }
  }, [selectedData, loanAmount]);

  return (
    <Popover
      position="top"
      shadow="lg"
      width={340}
      styles={(them) => ({
        dropdown: {
          marginLeft: "-130px",
        },
      })}
      opened={popoverOpen}
      onChange={setPopoverOpen}
      closeOnClickOutside
    >
      <Popover.Target>
        <ActionIcon onClick={() => setPopoverOpen(!popoverOpen)}>
          <AlertCircle size={"20px"} color={"#ff6666"} />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown sx={{ backgroundColor: COLORS.primary.light1 }}>
        <Grid gutter="md">
          {value && (
            <Grid.Col span={12}>
              {!loanAmount && value?.fee_type === "tranche" && (
                <Text size={"10px"} color={"red"}>
                  Please Enter Loan Amount
                </Text>
              )}
              <SegmentedControl
                data={[
                  {
                    label: "Approved Amount",
                    value: "fee_base_is_approved_amount",
                  },
                  {
                    label: "Tranche Amount",
                    value: "fee_base_is_tranche_amount",
                  },
                ]}
                fullWidth
                styles={(them) => ({
                  root: {
                    backgroundColor: "white",
                  },
                })}
                color={"blue"}
                value={value?.fee_type}
                onChange={(e) => {
                  setValue((old) => ({ ...old, fee_type: e }));
                  getFeeAmount(e);
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col span={12}>
            <DisplayFeeData
              type={"text"}
              label={"Amount Type"}
              value={
                calculatedFee?.fee_collection_type ===
                  "fee_base_is_approved_amount"
                  ? "Approved Amount"
                  : "Tranche Amount"
              }
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <DisplayFeeData
              label={"Fee Base Amount"}
              value={calculatedFee?.fee_base_amount}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <DisplayFeeData
              label={"Fee Amount"}
              value={
                value ? calculatedFee?.fee_amount : calculatedFee?.fee_value
              }
            />
          </Grid.Col>
          {calculatedFee?.igst ? (
            <Grid.Col span={12}>
              <DisplayFeeData label={"IGST"} value={calculatedFee?.igst} />
            </Grid.Col>
          ) : null}
          {calculatedFee?.sgst ? (
            <Grid.Col span={12}>
              <DisplayFeeData label={"SGST"} value={calculatedFee?.sgst} />
            </Grid.Col>
          ) : null}
          {calculatedFee?.cgst ? (
            <Grid.Col span={12}>
              <DisplayFeeData label={"CGST"} value={calculatedFee?.cgst} />
            </Grid.Col>
          ) : null}
          <Grid.Col span={12}>
            <DisplayContainedFeeData
              label={"Total Processing Fee"}
              value={calculatedFee?.total_amount}
            />
          </Grid.Col>
        </Grid>
      </Popover.Dropdown>
    </Popover>
  );
};
