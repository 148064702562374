import { getAPICall } from "./getAPICall";
import { postAPICall } from "./postAPICall";

/**Retrieves all bulk receipts from the API. */
export const getAllBulkReceipts = ({page, search, records = 10, status, entity}) => {
  /** used to get all bulk receipt */
  let url = `bulk-receipts?page=${page}&records=${records}&status=${status}`
  if(search) url = url + `&customer_id_name=${search}`;
  if(entity && entity !== 'all') url = url + `&entity_id=${entity}`;
  return getAPICall(url, { returnObject: true });
}

/**
 * Retrieves a bulk receipt by its ID.
 * 
 * @param {string} bulkReceiptId The ID of the bulk receipt.
 */
export const getBulkReceiptById = (bulkReceiptId) => {
  return getAPICall(`bulk-receipt/${bulkReceiptId}`);
}

/**
 * Sends a bulk receipt for the next stage.
 *
 * @param {string} bulkReceiptId - The ID of the bulk receipt to send.
 * @param {object} body - The body of the API call.
 */
export const sendBulkReceiptForNextStage = (bulkReceiptId, body) => {
  return postAPICall(`bulk-receipt/${bulkReceiptId}/status/change`, { body});
}

/**
 * Sends a bulk receipt to be rejected using the provided bulkReceiptId and body.
 *
 * @param {string} bulkReceiptId - The ID of the bulk receipt to be rejected.
 * @param {object} body - The body of the request.
 */
export const sendBulkReceiptToReject = (bulkReceiptId, body) => {
  return postAPICall(`bulk-receipt/${bulkReceiptId}/rejected`, { body});
}

/**
 * Sends a bulk receipt for pushback.
 *
 * @param {string} bulkReceiptId - The ID of the bulk receipt.
 * @param {Object} body - The request body.
 */
export const sendBulkReceiptForPushback = (bulkReceiptId, body) => {
  return postAPICall(`bulk-receipt/${bulkReceiptId}/pushback`, { body});
}

/**
 * Sends a bulk receipt for cancel.
 *
 * @param {string} bulkReceiptId - The ID of the bulk receipt.
 * @param {Object} body - The request body.
 */
export const sendBulkReceiptForCancel = (bulkReceiptId, body) => {
  return postAPICall(`bulk-receipt/${bulkReceiptId}/cancelled`, { body});
}

/**
 * Retrieve remarks for a bulk receipt by ID.
 *
 * @param {string} bulkReceiptId - The ID of the bulk receipt.
 */
export const getBulkReceiptRemarks = (bulkReceiptId) => {
  return getAPICall(`bulk-receipt/${bulkReceiptId}/remark`);
}

/**
 * Creates a bulk receipt by making a POST API call with excel sheet to 'bulk-receipt' endpoint.
 *
 * @param {Object} body - The body of the request.
 */
export const createBulkReceipt = (body) => {
  return postAPICall(`bulk-receipt`, { body: body, returnObject: true, returnErrorObject: true, headers: 
    {headers: {"Content-Type": "multipart/form-data",},} });
}