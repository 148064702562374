import { IMAGE_MIME_TYPE, MIME_TYPES, PDF_MIME_TYPE } from "@mantine/dropzone";

// current application registered state
export const STATE_CODE = 33;
// default supported image types
export const DEFAULT_SUPPORTED_IMAGE_TYPES = [IMAGE_MIME_TYPE];
// default supported pdf types
export const DEFAULT_SUPPORTED_PDF_TYPES = [PDF_MIME_TYPE];
// default supported excel types
export const DEFAULT_SUPPORTED_EXCEL_TYPES = [MIME_TYPES.csv, MIME_TYPES.xls, MIME_TYPES.xlsx];
// default supported doc types
export const DEFAULT_SUPPORTED_DOC_TYPES = [MIME_TYPES.doc, MIME_TYPES.docx];
// default supported all file types
export const DEFAULT_SUPPORTED_FILE_TYPES = [
  ...DEFAULT_SUPPORTED_IMAGE_TYPES,
  ...DEFAULT_SUPPORTED_PDF_TYPES,
  ...DEFAULT_SUPPORTED_EXCEL_TYPES,
  ...DEFAULT_SUPPORTED_DOC_TYPES,
]

export const BULK_RECEIPT_SAMPLE_URL = 'https://digiops-uat-docs.petromoney.in/samples/bulk_receipt_upload_file.xlsx';