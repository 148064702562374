import { postAPICall } from ".";
import { getAPICall } from ".";
import { deleteAPICall } from "./deleteAPICall";

export const getAllDisbursementBatch = ({
  status,
  page,
  search,
  records = 10,
  from_date,
  to_date,
  entity,
}) => {
    // get all the disbursement to display in table
  let url = `/disbursement-batches?page=${page}&customer_id_name=${search}&records=${records}&status=${status}`;
  if (search) url = url + `&customer_id_name=${search}`;
  if (from_date) url = url + `&from_date=${from_date}`;
  if (to_date) url = url + `&to_date=${to_date}`;
  if (entity && entity !== "all") url = url + `&entity_id=${entity}`;
  return getAPICall(url, { returnObject: true });
};

export const createDisbursementBatch = (body) => {
    // used to create the disbursement Batch
  return postAPICall("/disbursement-batch", { body, returnObject: true });
};

export const getDisbursementBatchById = (BatchId) => {
    // used to get the disbursement Batch by its id
  return getAPICall(`/disbursement-batch/${BatchId}`);
};

export const updateDisbursementBatch = (id, body) => {
    // used to update the disbursement Batch
  return postAPICall(`/disbursement-Batch/${id}`, { body });
};

export const saveAndSendDisbursementBatchForNextStage = (body) => {
    // used create the disbursement Batch and send to next stage
  return postAPICall("/disbursements-Batch", { body });
};

export const sendDisbursementBatchForNextStage = (id, body) => {
    // used to send the disbursement Batch to  the next stage
  return postAPICall(`/disbursement-batch/${id}/status/change`, { body });
};

export const sendDisbursementBatchForPushback = (id, body) => {
    // used to pushback the disbursement Batch
  return postAPICall(`/disbursement-batch/${id}/pushback`, { body: body });
};

export const sendDisbursementBatchForCancel = (id, body) => {
    // used to cancel the disbursement Batch
  return postAPICall(`/disbursement-batch/${id}/cancelled`, { body: body });
};

export const sendDisbursementBatchForReject = (id, body) => {
    // used to reject the disbursement Batch
  return postAPICall(`/disbursement-batch/${id}/rejected`, { body: body });
};

export const getAllRemarksDisbursementBatch = (id) => {
    // used to get all remarks
  return getAPICall(`/disbursement-batch/${id}/remark`);
};

export const removeDisbMEMOInBatch = (batchId, disbId) => {
    // used to get all remarks
  return deleteAPICall(`/disbursement-batch/${batchId}/batch_disbursement_map/${disbId}`, { returnObject: true });
};

export const getDisbursementBatchBankFile = ({ batch_id, bank_category }) => {
  // used to download the disb file
  return getAPICall(`/disbursement-batch/${batch_id}/textfile/download?bank_category=${bank_category}`, {headers: {
    headers: {"response-Type": "blob"}
  }, file: true});
}
