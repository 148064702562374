import { useQuery } from "@tanstack/react-query";
import Select, { components } from 'react-select'
import { Box } from "@mantine/core";
import { useSearchParams } from "react-router-dom";
import { getAllRegions } from "../../../services/master.service";

const styles = {
    control: (provided) => ({
      ...provided,
      borderColor: 'hsl(0, 0%, 90%)',
    //   marginRight: 10,
      fontSize: '12px', 
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    option: (provided) => ({
        ...provided,
        fontSize: '12px',
        padding: '4px 10px 4px 10px'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      maxHeight: '29px',
      padding: '0 6px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'initial'
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      '> div': {
        padding: 5
      }
    }),
    indicatorContainer: (provided) => ({
      ...provided,
    })
}

const multiValueContainer = ({ selectProps, data }) => {
    const label = data?.label;
    const allSelected = selectProps?.value;
    const index = allSelected?.length && allSelected?.findIndex((selected) => selected?.label === label);
    const isLastSelected = index === allSelected?.length - 1;
    const labelSuffix = isLastSelected ? '' : ', ';
    const val = `${label}${labelSuffix}`;
    return val;
};

const Option = (props) => {
    return (
        <components.Option {...props} >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                <label>&nbsp;{props.label}</label>
            </div>
        </components.Option>
    );
};

const defaultValue = { label: "All Regions", value: 0 };

const RegionFilterDropdown = ({ value, onChange }) => {
    const { data: regions = [defaultValue] } = useQuery({
        queryKey: ["regions"],
        queryFn: getAllRegions,
        select: (data) => {
            var result = data?.map((item) => {
                return { label: item.name, value: item.id }
            })
            return [defaultValue, ...result];
        },
        refetchOnWindowFocus: false,
    });

    const arr = value?.map(e => regions?.find((r) => r.value === e)) || [defaultValue];

    return (
        <Box style={{ width: 140 }}>
            <Select
                isMulti={true}
                defaultValue={defaultValue}
                value={arr}
                options={regions}
                styles={styles}
                isSearchable={false}
                isClearable
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                onChange={(selectedOption, triggeredAction) => onChange(selectedOption, triggeredAction)}
                components={{
                    MultiValueContainer: multiValueContainer,
                    Option,
                }}
            />
        </Box>
    )
}

const RegionFilter = ({ value = 0, onChange, useQueryParams }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    if (useQueryParams) {
        var arr = searchParams?.get("regions")?.split(',').map(numStr => parseInt(numStr))
        return (
            <RegionFilterDropdown
                value={arr || [0]}
                onChange={(selectedOption, triggeredAction) => {
                    if (triggeredAction?.action === 'clear' || selectedOption?.[selectedOption?.length - 1]?.value === 0 || !selectedOption?.length) {
                        searchParams?.delete('regions'); setSearchParams(searchParams)
                    } else {
                        var selectedData = []
                        selectedOption?.map(e => {e?.value != 0 && selectedData.push(e?.value)})
                        searchParams?.set("regions", selectedData?.join()); setSearchParams(searchParams)
                    }
                }}
            />
        )
    }

    return (
        <RegionFilterDropdown value={value} onChange={(d) => onChange(d?.value)} />
    )

}

export default RegionFilter;