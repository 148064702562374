import { Box, Grid, Image } from '@mantine/core';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import DataViewerWapper from '../../components/CommonComponents/Preview/DataViewerWapper';
import { ViewFormData, ViewFormDataContained } from '../../components/CommonComponents/Preview/FilePreview';
import ImdStyle from '../IMD/Imd.styles';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import DashBoardCustomerDetails from '../../components/DashboardCard/dashboard';
import Body from '../../components/Body/Body';
import PageTitle from '../../components/PageTitle/PageTitle';
import {
    getByInvoiceID,
    getInvoiceRemarksById,
    sendInvoiceReject,
    sendInvoicePushback,
    getAutoInvoiceData,
    generateManualInvoicePDF,
    generateAutoInvoicePDF,
    sendInvoiceforNextStage,
    sendInvoiceforCancellation
} from '../../services/invoice.service';
import { useQuery } from '@tanstack/react-query';
import { Textarea } from '@mantine/core';
import ActionButtons from '../../components/CommonComponents/ActionButtons/ActionButtons';
import Remarks from '../../components/CommonComponents/Remarks/Remarks';
import Nums from '../../components/Body/Nums';
import ViewRemark from '../../components/Remark/ViewRemark';
import ModalDataViwer from '../../components/SucessfullModal/ModalDataViwer';
import IdBadge from '../../components/CommonComponents/Preview/IdBadge';
import { STAGE_CHANGE_TEXT, PDF_TEXT_MESSAGE, GENERAL_IMG } from '../../constants';
import { useQueryClient } from '@tanstack/react-query';
import { getStageforModules } from '../../services/module.service';
import { CANCELLATION_REVIEW, IN_APPROVAL, IN_REVIEW } from '../../constants/status';
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification';
import AccountingInterfaceModal from '../../components/CommonComponents/AccuntingInterface/AccountingInterfaceModal';
import { openFile } from '../../utils/openFile';
import TitleViewer from '../../components/CommonComponents/Title/TitleViewer';
import { CompanyBankDetails } from '../../services/imd.service';

const InvoiceReview = ({ action = true, home = '/invoice-manual' }) => {
  const { dealerID, createdID } = useParams();
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState();
  const [status, setStatus] = useState();
  const [remarksTitle, setRemarksTitle] = useState();
  const [remarksOpen, setRemarksOpen] = useState();
  const [remarksLoading, setRemarksLoading] = useState();
  const [sucessfulmodal, setSucessfulmodal] = useState(false);
  const [interfaceOpen, setInterfaceOpen] = useState(false);
  const [pdfLoading, setPDFLoading] = useState(false)
  const queryClient = useQueryClient();
  
  const { data: autoData = [], isLoading } = useQuery(
    ['invoice-auto-id', createdID],
    () => dealerID && getAutoInvoiceData(createdID),
    { refetchOnWindowFocus: false }
  )

  const { data = [], isLoading: loading } = useQuery(
    ['invoice-id', createdID],
    () => createdID && getByInvoiceID(createdID),
    { refetchOnWindowFocus: false }
  )

  const { data: stages = [] } = useQuery(
    ['invoice-stages'],
    () => getStageforModules('invoice'),
    {
      enabled: Boolean(data?.status_value),
      select: (dataItem) => {
        return {
          'current_stage': data?.status_value,
          'next_stage': dataItem[dataItem.indexOf(data?.status_value) + 1]
        }
      },
      refetchOnWindowFocus: false
    }
  );

  const { data: targetBank = [], isLoading: targetBankLoading } = useQuery(
    ['company-bank', data?.business_bank_id],
    () => CompanyBankDetails(data?.business_bank_id),
    { 
      enabled: Boolean(data?.business_bank_id),
      refetchOnWindowFocus: false 
    }
  );

  const { data: reviewTrail = [] } = useQuery(
    ['remarks-Invoice', createdID],
    () => getInvoiceRemarksById(createdID),
    { refetchOnWindowFocus: false }
  );

  const onSendInvoiceforNextStage = (remarks, status) => {
    setRemarksLoading(true)
    const body = {
      remarks,
    };

    sendInvoiceforNextStage(createdID, body)
      .then((res) => {
        setSucessfulmodal({open: true, title:
          status == IN_REVIEW ? STAGE_CHANGE_TEXT?.approval
          : status == IN_APPROVAL ? STAGE_CHANGE_TEXT?.approved
          : status == CANCELLATION_REVIEW ? STAGE_CHANGE_TEXT?.reviewCancel
          : STAGE_CHANGE_TEXT?.approveCancel
        });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error'})
      })
      .finally(() => {
        setOpen(false)
        setRemarksLoading(false)
      })
  }

  const handleReject = (remarks) => {
    setRemarksLoading(true)
    const body = {
      remarks,
    };

    sendInvoiceReject(createdID, body)
      .then((res) => {
        setSucessfulmodal({open: true, title: STAGE_CHANGE_TEXT?.reject});
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error'})
      })
      .finally(() => {
        setOpen(false)
        setRemarksLoading(false)
      })
  }

  const handlePushback = (remarks) => {
    setRemarksLoading(true)
    const body = {
      remarks,
    };

    sendInvoicePushback(createdID, body)
      .then((res) => {
        setSucessfulmodal({open: true, title: STAGE_CHANGE_TEXT?.pushback});
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error'})
      })
      .finally(() => {
        setOpen(false)
        setRemarksLoading(false)
      })
  }

  /** used to handle the cancellation */
  const handleInvoiceCancellation = (remarks, reason) => {
    setRemarksLoading(true)
    const body = {
      remarks,
      reason: reason?.reason
    }
    sendInvoiceforCancellation(createdID, body)
      .then((res) => {
        setSucessfulmodal({open: true, title: STAGE_CHANGE_TEXT?.cancel});
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error'})
      })
      .finally(() => {
        setOpen(false)
        setRemarksLoading(false)
      })
  }

  const callFunction = (remarks, status, reason) => {
    switch (status) {
      case 'review':
      case 'approval':
      case 'cancellation' :
        onSendInvoiceforNextStage(remarks, status);
        break;
      case 'reject':
        handleReject(remarks);
        break;
      case 'pushback':
        handlePushback(remarks);
        break;
      case 'cancel':
        handleInvoiceCancellation(remarks, reason)
        break;
      default:
        displayNotification({
          message: 'Something went wrong, Please contact support!',
          variant: 'error',
        });
        break;
    }
  };

  /** used to handle the manual pdf */
  const handleManualPDF = () => {
    setPDFLoading(true)
    generateManualInvoicePDF(createdID)
      .then((res) => {
        setPDFLoading(false)        
        queryClient.invalidateQueries(['invoice-id', createdID]);
        displayNotification({ message: PDF_TEXT_MESSAGE?.invoice, variant: 'success'})
        openFile(res)
      })
      .catch((e) => {
        setPDFLoading(false)
        displayNotification({ message: e?.message || e, variant: 'error'})
      })
  }

  /** used to handle the auto pdf */
  const handleAutoPDF = () => {
    setPDFLoading(true)
    generateAutoInvoicePDF(createdID)
      .then((res) => {
        setPDFLoading(false)        
        queryClient.invalidateQueries(['invoice-autoid', createdID]);
        displayNotification({ message: PDF_TEXT_MESSAGE?.invoice, variant: 'success'})
        openFile(res)
      })
      .catch((e) => {
        setPDFLoading(false)
        displayNotification({ message: e?.message || e, variant: 'error'})
      })
  }

  return (
    <>
    <Body background={false}>
      <PageTitle
        title={data?.customer_name || autoData?.customer_name} id={data?.customer_id || autoData?.customer_id} 
      />
    </Body>
    <DashBoardCustomerDetails id={dealerID || data?.customer_id} />
    <Box sx={{ position: 'relative' }}>
      <Body>
        {data?.code || autoData?.prospect_code ? (
          <div style={ImdStyle.flag}>
            <IdBadge
              remarks={data?.status_value || autoData?.status_value}
              label={data?.code || autoData?.prospect_code}
            />
          </div>
        ) : null}
        <Grid gutter='lg'>
          {/* <Grid.Col span={4}>
            <Grid.Col span={12}>
              <Box p={32} sx={ImdStyle.alignCenter}>
                <Image src={GENERAL_IMG} alt='Illustration' />
              </Box>
            </Grid.Col>
          </Grid.Col> */}
          <Grid.Col span={12} sx={{display: 'flex'}}>
            <Grid.Col span={4}>
              <DataViewerWapper p={30}>
                  <Grid gutter='md'>
                    {dealerID ?
                      <>
                        <Grid.Col span={12}>
                          <ViewFormData label='Product Type' value={autoData?.product} loading={isLoading || loading} />
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <ViewFormData label='Created Date' value={dateFormat(autoData?.created_date)} loading={isLoading || loading} />
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <ViewFormData label='Tranche Amount' value={<Nums withCopy value={autoData?.loan_amount} />} loading={isLoading || loading} />
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <ViewFormData label='Principal Amount' value={<Nums withCopy value={autoData?.outstanding_principle} />} loading={isLoading || loading} />
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <ViewFormData label='Interest Amount' value={<Nums value={autoData?.interest} />} loading={isLoading || loading} />
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <ViewFormData label='Penal Amount' value={<Nums value={autoData?.penal} />} loading={isLoading || loading} />
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <ViewFormData label='Disbured Amount' value={<Nums withCopy value={autoData?.disbursed_amount} />} loading={isLoading || loading} />
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <ViewFormData label='Disbursed Date' value={dateFormat(autoData?.disbursed_date)} loading={isLoading || loading} />
                        </Grid.Col>
                      </>
                      :
                      <>
                        <Grid.Col span={12}>
                          <ViewFormData label='Customer Name' value={data?.customer_name} loading={isLoading || loading} />
                        </Grid.Col>   
                        <Grid.Col span={12}>
                          <ViewFormData label='Customer ID' value={data?.customer_id} loading={isLoading || loading} />
                        </Grid.Col>   
                        <Grid.Col span={12}>
                          <ViewFormData label='Charge Date' value={dateFormat(data?.charge_date)} loading={isLoading || loading} />
                        </Grid.Col>                
                        <Grid.Col span={12}>
                          <ViewFormData label='Charge Type' value={data?.charge_type} loading={isLoading || loading} />
                        </Grid.Col>               
                        <Grid.Col span={12}>
                          <ViewFormData label='Charge Name' value={data?.charge_name} loading={isLoading || loading} />
                        </Grid.Col>  
                      </>
                    }        
                </Grid>         
              </DataViewerWapper>
            </Grid.Col>

            <Grid.Col span={4}>
              <DataViewerWapper p={30}>
                <Grid gutter='md'>
                  { !dealerID &&     
                    <Grid.Col span={12}>
                      <ViewFormData label='Charge Amount' value={<Nums withCopy value={data?.charge_amount} />} loading={isLoading || loading} />
                    </Grid.Col>
                  }
                  { !dealerID &&
                    <Grid.Col span={12}>
                      <ViewFormData label='Customer State' value={data?.customer_state} loading={isLoading || loading} />
                    </Grid.Col>
                  }
                  { data?.product_type &&
                    <Grid.Col span={12}>
                      <ViewFormData label='Product Type' value={data?.product_type} loading={isLoading || loading} />
                    </Grid.Col>
                  }
                  <Grid.Col span={12}>
                    <ViewFormData label='GST Applicable' value={data?.gst_applicable?.toUpperCase() || autoData?.gst_applicable?.toUpperCase()} loading={isLoading || loading} />
                  </Grid.Col>
                  { data?.igst || autoData?.igst ?
                    <Grid.Col span={12}>
                      <ViewFormData label='IGST' value={<Nums value={data?.igst || autoData?.igst} />} loading={isLoading || loading} />
                    </Grid.Col> : null
                  }
                  { data?.cgst || autoData?.cgst ?
                    <Grid.Col span={12}>
                      <ViewFormData label='CGST' value={<Nums value={data?.cgst || autoData?.cgst} />} loading={isLoading || loading} />
                    </Grid.Col> : null
                  }
                  { data?.sgst || autoData?.sgst ?
                    <Grid.Col span={12}>
                      <ViewFormData label='SGST' value={<Nums value={data?.sgst || autoData?.sgst} />} loading={isLoading || loading} />
                    </Grid.Col> : null
                  }
                  <Grid.Col span={12} mt='sm'>
                    <ViewFormDataContained label='Total Amount' value={<Nums withCopy value={data?.total_amount || parseInt(autoData?.loan_amount)+autoData?.interest+autoData?.penal } />} loading={isLoading || loading} />
                  </Grid.Col>
                </Grid>
              </DataViewerWapper>
            </Grid.Col>
            <Grid.Col span={4}>
              <DataViewerWapper p={30}>
                <Grid gutter='md'>
                  <Grid.Col span={12} py={0} pb={'sm'}>
                    <TitleViewer title={'Target Banks: Petromoney'} />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData label='Bank Name' value={targetBank?.[0]?.bank_name} loading={targetBankLoading} />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData label='Acc No' value={targetBank?.[0]?.account_number} loading={targetBankLoading} />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData label='IFSC' value={targetBank?.[0]?.ifsc} loading={targetBankLoading} />
                  </Grid.Col>
                </Grid>
              </DataViewerWapper>
            </Grid.Col>
          </Grid.Col>
          { data?.description ?
            <Grid.Col span={6} pt={0} mt={0}>
              <Textarea
                placeholder="Enter your notes..."
                label="Notes"
                disabled
                minRows={4}
                defaultValue={data?.description}
              />   
            </Grid.Col> : null
          }
          
        </Grid>

        {/** This Action buttons component is used to display the buttons in the flow */}
        <ActionButtons
          status={stages?.current_stage || autoData?.status}
          isReady={Boolean(stages?.current_stage || autoData?.status)}
          nextStage={stages?.next_stage}
          remarks={reviewTrail}
          pdfLoading={pdfLoading}
          handlePDF={() => (autoData?.invoice_url || data?.invoice_url) ? openFile(autoData?.invoice_url || data?.invoice_url) : dealerID ? handleAutoPDF() : handleManualPDF()}
          accountingCB={() => setInterfaceOpen(true)}
          currentStatus={data?.status_value}
          moduleApplicationId={data?.id}
          moduleName={'invoice'}
          cancelCB={() => { setOpen(true); setRemarksTitle("Cancellation Remarks"); setStatus('cancellation'); setLabel('Send for Cancellation') }}
          approveCancelCB={() => { setOpen(true); setRemarksTitle("Are you sure?"); setStatus('cancel'); setLabel('Confirm') }}
          downloadText={(autoData?.invoice_url || data?.invoice_url) ? 'Download Invoice' : 'Generate Invoice'}
          sendForApprovalCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('review'); setLabel('Send for Approval') }}
          approvedCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('approval'); setLabel('Approve') }}
          pushbackCB={() => { setOpen(true); setRemarksTitle("Pushback Remarks"); setStatus('pushback'); setLabel('Pushback') }}
          rejectCB={() => { setOpen(true); setRemarksTitle("Reject Remarks"); setStatus('reject'); setLabel('Reject') }}
          remarksCB={() => setRemarksOpen(true)}/>
        
        {/** used to display the modal for accounting data */}
        <AccountingInterfaceModal open={interfaceOpen} close={() => setInterfaceOpen(false)} id={createdID} name={'manual_invoice'} code={data?.code} />

        {/** used to get remarks from user */}
        <Remarks
          open={open}
          setOpen={setOpen}
          type={stages?.next_stage}
          callback={callFunction}
          remarksTitle={remarksTitle}
          moduleName={'Invoice'}
          check={status}
          label={label}
          loading={remarksLoading}
          currentStatus={data?.status_value}
          moduleApplicationId={data?.id}
        />

        {/** used to display the suceccfull stage change modal */}      
        <ModalDataViwer
          opened={sucessfulmodal?.open}
          label={data?.code}
          title={sucessfulmodal?.title}
          href={home}
          hreftext={'Back to list table'}
        />
      </Body>
    </Box>

      {/** used to display the remarks */}      
      <ViewRemark open={remarksOpen} onClose={setRemarksOpen} remarkTrail={reviewTrail} />
    </>
  );
};

export default InvoiceReview;
