import {
  Drawer,
  Box,
  Text,
  Title,
  SegmentedControl,
  Table,
  Tabs,
  Skeleton,
} from '@mantine/core'
import React, { useState } from 'react'
import { COLORS } from '../../constants';
import { dateFormat } from '../DateFormat/dateFormat';
import { sumBy } from 'lodash';
import styled from '@emotion/styled';
import { getScoreCardDetailsByCustomerId } from '../../services/borrowers.service';
import { useQuery } from '@tanstack/react-query';

const DataDivOMCL = styled.div`
  margin-top: 20px;
  tr td, tr th {
    border: 1px solid ${COLORS.gray(10)};
  }
  tr td: first-of-type, tr th: first-of-type {
    border: 1px solid ${COLORS.gray(10)};
  }
`;

const DataDivBureau = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  tr td, tr th {
    border: 1px solid ${COLORS.gray(10)};
  }
  tr td: first-of-type, tr th: first-of-type {
    border: 1px solid ${COLORS.gray(10)};
  }
  tr td: first-of-type {
    background: ${COLORS.blue(5)};
  }
  thead {
    background: ${COLORS.blue(5)};
  }
`;

const tableViewArray = [
  { label: "Previous FY", value: 'previous_fy' },
  { label: "Current FY", value: 'latest_completed_fy' },
  { label: "Broken Period", value: 'broken_period' }
]

const BureauSalesDataHeader = [
  { header: 'Name', key: 'name' },
  { header: 'Vintage/Age (Years)', key: "vintage" },
  { header: 'Commercial Bureau Used', key: "commercial_bureau" },
  { header: 'CMR Equivalent/CIBIL Score', key: "cmr_cibil_score" },
  { header: 'PM Score of Individual Co-applicants', key: "co_pm_score" },
  { header: 'No of CIBIL enquiries in last 6 months', key: "cibil_enquiry_cnt" },
  { header: 'Vintage with Credit Bureau in Years', key: "vintage_with_credit_bureau" },
  { header: 'No of loans closed with no negative status (current bal zero)', key: "loans_closed_with_no_negative_status" },
  { header: 'Loans in Bureau Report (Yes/No)', key: "loans_in_bureau_report" },
  { header: 'Highest DPD Bracket (in last 6 months) - For Loans', key: "highest_dpt_bracket_for_loans" },
  { header: 'No of times in highest DPD (in last 6 months) - For Loans', key: "no_of_times_in_highest_dpt_for_loans" },
  { header: 'Credit Card in Bureau Report (Yes/No)', key: "credit_card_in_bureau_report" },
  { header: 'Highest DPD Bracket (in last 6 months) - For Credit Cards', key: "highest_dpt_bracket_for_credit_cards" },
  { header: 'No of times in highest DPD (in last 6 months) - For Credit Cards', key: "no_of_times_in_highest_dpt_for_credit_cards" },
  { header: 'Status - For Loans & Credit Cards', key: "status_for_loans_and_credit_cards" },
]

const OMCSalesDataTable = ({ data, tableView, loading }) => {
  // used to calculate total
  const calculateTotal = (key) => {
    return sumBy(data, function (item) {
      if (item?.type === tableView)
        return item?.[key]
    })
  }
  // used to calculate avg
  const calculateAvg = (key) => {
    let array = data?.filter(e => e?.type === tableView && e?.[key] && e?.[key] !== 0) || []
    return (sumBy(array, key)/array?.length).toFixed(2)
  }
  return (
    <DataDivOMCL>
    <Title order={6}>OMC Sales Data</Title>
      {
        !loading && !data?.length ? (
          <Text color={COLORS.gray(90)} size={'xs'} style={{ textAlign: 'center' }}>No Data to display</Text>
        ) : (
          <>
            <Table fontSize="xs" mt={'xs'}>
              <thead style={{ background: COLORS.blue(5) }}>
                <tr>
                  <th style={{ textAlign: 'center' }} colSpan={6}>{tableViewArray?.find(e => e?.value === tableView)?.label}</th>
                </tr>
                <tr>
                  <th>Sr.No</th>
                  <th>Month</th>
                  <th>MS (KL)</th>
                  <th>HSD (KL)</th>
                  <th>CNG/Auto LPG (Liters)</th>
                  <th>Total (KL)</th>
                </tr>
              </thead>
              {!loading ?
                <tbody>
                  {
                    data?.filter((item) => item?.type === tableView).map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{dateFormat(`${item?.month}-01-${item?.year}`, 'MMM YYYY')}</td>
                        <td>{item?.ms}</td>
                        <td>{item?.hsd}</td>
                        <td>{item?.lpg}</td>
                        <td>{item?.total}</td>
                      </tr>
                    ))
                  }
                  <tr style={{ background: COLORS.blue(5) }}>
                    <td colSpan={2}>Total (KL)</td>
                    <td>
                      {calculateTotal('ms')}
                    </td>
                    <td>
                      {calculateTotal("hsd")}
                    </td>
                    <td>
                      {calculateTotal('lpg')}
                    </td>
                    <td>
                      {calculateTotal('total')}
                    </td>
                  </tr>
                  <tr style={{ background: COLORS.blue(5) }}>
                    <td colSpan={2}>AVG (KL)</td>
                    <td>
                      {calculateAvg('ms')}
                    </td>
                    <td>
                      {calculateAvg('hsd')}
                    </td>
                    <td>
                      {calculateAvg('lpg')}
                    </td>
                    <td>
                      {calculateAvg('total')}
                    </td>
                  </tr>
                </tbody>
                : (
                  <tbody>
                    {
                      [1,2,3,4,5].map((item) => (
                        <tr key={item}>
                          <td><Skeleton width={50} height={20} /></td>
                          <td><Skeleton width={100} height={20} /></td>
                          <td><Skeleton width={100} height={20} /></td>
                          <td><Skeleton width={100} height={20} /></td>
                          <td><Skeleton width={100} height={20} /></td>
                          <td><Skeleton width={50} height={20} /></td>
                        </tr>
                      ))
                    }
                  </tbody>
                )
              }
            </Table>
          </>
        )
      }
    </DataDivOMCL>
  )
}

const EligibilityCategory = ({ totalData, avgData, loading }) => {
  return (
    <DataDivOMCL mt={'lg'}>
      <Title order={6}>Annual Fuel Sale considered for Eligibility Calculation</Title>
      {
        !loading && !totalData?.length ? (
          <Text color={COLORS.gray(90)} size={'xs'} style={{ textAlign: 'center' }}>No Data to display</Text>
        ): (          
          <>
            <Table fontSize="xs" mt={'xs'}>
              <thead style={{ background: COLORS.blue(5) }}>
                <tr>
                  <th rowSpan={2}>Sr.No</th>
                  <th rowSpan={2}>Fuel Credit Category</th>
                  <th style={{ textAlign: 'center' }} colSpan={4}>Total KL Considered</th>
                  <th style={{ textAlign: 'center' }} colSpan={4}>Average Monthly KL Considered</th>
                </tr>
                <tr>
                  <th>MS</th>
                  <th>HSD</th>
                  <th>CNG/Auto LPG</th>
                  <th>Total</th>
                  <th>MS</th>
                  <th>HSD</th>
                  <th>CNG/Auto LPG</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {!loading ?
                  totalData?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item?.category}</td>
                      <td>{item?.ms}</td>
                      <td>{item?.hsd}</td>
                      <td>{item?.lpg}</td>
                      <td>{item?.total}</td>
                      <td>{avgData?.[index]?.ms}</td>
                      <td>{avgData?.[index]?.hsd}</td>
                      <td>{avgData?.[index]?.lpg}</td>
                      <td>{avgData?.[index]?.total}</td>
                    </tr>
                  )) : (
                    [1,2,3,4,5].map((item) => (
                      <tr key={item}>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                      </tr>
                    ))
                  )
                }
              </tbody>
            </Table>
          </>
        )
      }
    </DataDivOMCL>
  )
}

const StandardCategory = ({ totalData, avgData, loading }) => {
  return (
    <DataDivOMCL mt={'lg'}>
      <Title order={6}>Annual Fuel Sale considered in limit setting for standard category</Title>
      {
        !loading && !totalData?.length ? (
          <Text color={COLORS.gray(90)} size={'xs'} style={{ textAlign: 'center' }}>No Data to display</Text>
        ) : (
          <>
            <Table fontSize="xs" mt={'xs'} mb={'md'}>
              <thead style={{ background: COLORS.blue(5) }}>
                <tr>
                  <th rowSpan={2}>Sr.No</th>
                  <th rowSpan={2}>Fuel Credit Category</th>
                  <th style={{ textAlign: 'center' }} colSpan={4}>Total KL Considered</th>
                  <th style={{ textAlign: 'center' }} colSpan={4}>Average Monthly KL Considered</th>
                </tr>
                <tr>
                  <th>MS</th>
                  <th>HSD</th>
                  <th>CNG/Auto LPG</th>
                  <th>Total</th>
                  <th>MS</th>
                  <th>HSD</th>
                  <th>CNG/Auto LPG</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {!loading ?
                  totalData?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item?.category}</td>
                      <td>{item?.ms}</td>
                      <td>{item?.hsd}</td>
                      <td>{item?.lpg}</td>
                      <td>{item?.total}</td>
                      <td>{avgData?.[index]?.ms}</td>
                      <td>{avgData?.[index]?.hsd}</td>
                      <td>{avgData?.[index]?.lpg}</td>
                      <td>{avgData?.[index]?.total}</td>
                    </tr>
                  )) : (
                    [1,2,3,4,5].map((item) => (
                      <tr key={item}>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                      </tr>
                    ))
                  )
                }
              </tbody>
            </Table>
          </>
        )
      }
    </DataDivOMCL>
  )
}

const DemographicsTable = ({data, header, loading}) => {
  return (
    <DataDivBureau>
      {
        !loading && !data?.length ? (
          <Text color={COLORS.gray(90)} size={'xs'} style={{ textAlign: 'center' }}>No Data to display</Text>
        ) : (
          <>
            <Table fontSize="xs" mt={'xs'}>
              <thead>
                <tr>
                  <th colSpan={3}>Name of Fuel Station</th>
                  <th colSpan={5}>{header?.fuel_station_name}</th>
                </tr>
                <tr>
                  <th colSpan={3}>Entity Type</th>
                  <th colSpan={5}>{header?.entity_type}</th>
                </tr>
                <tr>
                  <th>Sr.no</th>
                  <th>Particular</th>
                  <th>Main Applicant (Entity)</th>
                  <th>Individual Co-applicant No 1 (Main Individual Co-applicant)</th>
                  <th>Individual Co-applicant No 2</th>
                  <th>Individual Co-applicant No 3</th>
                  <th>Individual Co-applicant No 4</th>
                  <th>Individual Co-applicant No 5</th>
                </tr>
              </thead>
              <tbody>
                {!loading ?
                  BureauSalesDataHeader?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item?.header}</td>
                      <td>{data?.[0]?.[item?.key]}</td>
                      <td>{data?.[1]?.[item?.key]}</td>
                      <td>{data?.[2]?.[item?.key]}</td>
                      <td>{data?.[3]?.[item?.key]}</td>
                      <td>{data?.[4]?.[item?.key]}</td>
                      <td>{data?.[5]?.[item?.key]}</td>
                    </tr>
                  )) : (
                    [1,2,3,4,5].map((item) => (
                      <tr key={item}>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                        <td><Skeleton width={100} height={20} /></td>
                        <td><Skeleton width={100} height={20} /></td>
                        <td><Skeleton width={100} height={20} /></td>
                        <td><Skeleton width={100} height={20} /></td>
                        <td><Skeleton width={50} height={20} /></td>
                      </tr>
                    ))
                  )
                }
              </tbody>
            </Table>
          </>
        )
      }
    </DataDivBureau>
  )
}

/**
 * Renders the ScoreCard component.
 *
 * @param {Object} modalObj - The modal object.
 * @param {Function} setModalObj - The function to set the modal object.
 * @param {Object} customerData - The customer data object.
 * @param {string} id - The ID of the customer.
 */
const ScoreCardComp = ({ modalObj, setModalObj, customerData, id }) => {
  const [tableView, setTableView] = useState('previous_fy');
  // get the score card data
  const scoreCardDetailsQuery = useQuery({
    queryKey: ["score-card-details", id],
    queryFn: () => getScoreCardDetailsByCustomerId(id),
    enabled: Boolean(id),
    refetchOnWindowFocus: false
  })

  return (
    <>
      <Drawer
        opened={modalObj?.modal}
        onClose={() => setModalObj((old) => ({ ...old, modal: false }))}
        padding="xl"
        title={
          <Box sx={{display: 'flex',flexDirection: 'row', alignItems: 'center', gap: '10px'}}>
            <Title order={5}>{customerData?.name} - {customerData?.customer_code}</Title><Text size={'xs'}>({customerData?.region})</Text>
          </Box>
        }
        size={"80%"}
        position="right"
      >

        <Tabs
          my={'md'}
          defaultValue={'omc_sales_data'}
        >

          <Tabs.List grow>
            <Tabs.Tab value={'omc_sales_data'}>
              <Text size={'sm'}>OMC Sales Data</Text>
            </Tabs.Tab>
            <Tabs.Tab value={'demo_and_bureau'}>
            <Text size={'sm'}>Demo & Bureau Inputs</Text>
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value={'omc_sales_data'}>
            <Box sx={{ height: '550px', overflow: 'hidden', overflowY: 'scroll' }} mt={'md'}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                <Box sx={{ display: 'flex', gap: '2px', flexDirection: 'column' }}>
                  <Text size={'xs'} color={COLORS.gray(90)}>OMC Name</Text>
                  <Title order={6}>{scoreCardDetailsQuery?.data?.headers_across_sheets?.scorecard_master_data?.[0]?.omc_name}</Title>
                </Box>
                <Box>
                  <Text size={'xs'} color={COLORS.gray(90)}>Table View</Text>
                  <SegmentedControl size="xs" color={'blue'} value={tableView} onChange={setTableView} data={tableViewArray} />
                </Box>
              </Box>
              <OMCSalesDataTable data={scoreCardDetailsQuery?.data?.omc_sale_sheet?.omc_sales_monthwise} tableView={tableView} loading={scoreCardDetailsQuery?.isLoading} />
              <EligibilityCategory totalData={scoreCardDetailsQuery?.data?.omc_sale_sheet?.omc_fuel_data_for_elig_calc_total} avgData={scoreCardDetailsQuery?.data?.omc_sale_sheet?.omc_fuel_data_for_elig_calc_avg} loading={scoreCardDetailsQuery?.isLoading} />
              <StandardCategory totalData={scoreCardDetailsQuery?.data?.omc_sale_sheet?.omc_fuel_data_for_limit_setting_total} avgData={scoreCardDetailsQuery?.data?.omc_sale_sheet?.omc_fuel_data_for_limit_setting_avg} loading={scoreCardDetailsQuery?.isLoading} />
            </Box>
          </Tabs.Panel>

          <Tabs.Panel value={'demo_and_bureau'}>
            <Box sx={{ height: '550px', overflow: 'hidden', overflowY: 'scroll' }}>
              <Box mt={'md'}>
                <Title order={6}>Demographics & Credit Bureau Inputs</Title>
              </Box>
              <DemographicsTable data={scoreCardDetailsQuery?.data?.demo_and_bureau_inputs_sheet?.demo_bureau_inputs} header={scoreCardDetailsQuery?.data?.headers_across_sheets?.scorecard_master_data?.[0]} loading={scoreCardDetailsQuery?.isLoading} />
            </Box>
          </Tabs.Panel>

        </Tabs> 

      </Drawer>
    </>
  )
}

export default ScoreCardComp