import { Grid, MantineProvider, Text, TextInput } from '@mantine/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import TitleComp from '../TextComponent/TitleComp';
import { COLORS } from '../../../constants';
import { currencyInWords } from './NumberToWords';

const currencyFormats = value => {
  let val = value || 0
  if(typeof value == 'string') {
      val = parseInt(value?.replace(/,/g, ''))
  }
  return Intl.NumberFormat('en-IN').format(parseInt(val))
}

const escapeRegExp = (stringToGoIntoTheRegex) => {
  return stringToGoIntoTheRegex.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

const ControllerCurrencyInput = ({
  name,
  label,
  displayLabel = true,
  placeholder,
  required = false,
  control,
  amountInWords = false,
  onChangeTrigger = () => {},
  errors,
  spanSize = 7,
  minHeight = '57px',
  value,
  styles = ({padding: '0 8px 0 8px'}),
  autoComplete = 'on',
  ...props
}) => {
  // Abbreviation Values
  const abbrMap = { k: 1000, l: 100000, c: 10000000 };

  // Get values from Text Input and transform values
  const currencyHandler = (value) => {
    const decimalSeparator = '.';

    //Allow only K L C
    const reg = new RegExp(
      `(\\d+(${escapeRegExp(decimalSeparator)}\\d*)?)([klc])$`,
      'i'
    );
    let txtVal = value.target.value;
    const match = txtVal.match(reg);
    if (match) {
      const [, digits, , abbr] = match;
      const multiplier = abbrMap[abbr.toLowerCase()];
      return currencyFormats(Number(digits.replace(decimalSeparator, '.')) * multiplier);
    }
    return value;
  };

  return (
    <Grid sx={{ display: 'flex', alignItems: 'center' }}>
      {/* displayLabel used to display lable if true, By defalut it is true */}
      {displayLabel &&
        <Grid.Col span={5} sx={{ minHeight: minHeight, padding: '10px 8px 0 8px', display: 'flex' }}>
          <TitleComp label={label} />
          {required ? <Text ml={'4px'} color="red">*</Text> : null}
        </Grid.Col>
      }
      <Grid.Col span={spanSize} sx={{ minHeight: minHeight, ...styles }}>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange, ...rest } }) => {
            return (
              <MantineProvider theme={{ fontFamily: 'sans-serif' }}>
                <TextInput
                  id={name}
                  value={value}
                  onChange={(value) => {
                    onChange(currencyHandler(value));
                    onChangeTrigger(currencyHandler(value));
                  }}
                  icon={'₹'}
                  error={errors ? true : false}
                  clearable={false}
                  {...props}
                  {...rest}
                  autoComplete={autoComplete}
                />
              </MantineProvider>
            )
          }}
        />
        {errors && (
          <Text size={'xs'} sx={{ color: '#f13e3e' }}>
            {errors}
          </Text>
        )}
        {amountInWords && !errors && (
          <Text size={'xs'} sx={{ color: COLORS.text.light1, textTransform: "capitalize" }}>
            {currencyInWords(value)}
          </Text>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default ControllerCurrencyInput;
