import { createColumnHelper } from "@tanstack/table-core";
import { LoanStatusToken } from "../components/CommonComponents/Preview/FilePreview";
import { DASHBOARD_STATUS_COLOR, DASHBOARD_STATUS_VALUE } from "../utils/StatusTable";
import Nums from "../components/Body/Nums";
import { dateFormat } from "../components/DateFormat/dateFormat";


const columnHelper = createColumnHelper();

const management_dashboard_headers = {
  credit_mis: [
    columnHelper.accessor('dealership_id', {
      header: 'Dealership Id',
    }),
    columnHelper.accessor('dealership_name', {
      header: 'Name',
      cell: (val) => val.getValue()?.trim()
    }),
    columnHelper.accessor('amount', {
      header: 'Amount',
      cell: (value) => <Nums value={value.getValue()} />
    }),
    columnHelper.accessor('product_name', {
      header: 'Schema',
    }),
    columnHelper.accessor('region', {
      header: 'Region',
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (value) => (
        <LoanStatusToken
          label={DASHBOARD_STATUS_VALUE?.[value.getValue()] || value.getValue()}
          color={DASHBOARD_STATUS_COLOR?.[value.getValue()]}
        />
      )
    })
  ],
  new_loan: [
    columnHelper.accessor('customer_id', {
      header: 'Customer Id',
    }),
    columnHelper.accessor('customer_name', {
      header: 'Name',
      cell: (val) => val.getValue()?.trim()
    }),
    columnHelper.accessor('prospect_code', {
      header: 'Loan Code',
    }),
    columnHelper.accessor('disbursed_date', {
      header: 'Loan created Date',
      cell: (value) => dateFormat(value.getValue(), 'DD/MM/YYYY')
    }),
    columnHelper.accessor('loan_amount', {
      header: 'Loan Amount',
      cell: (value) => <Nums value={value.getValue()} />
    }),
  ],
  advance_interest: [
    columnHelper.accessor('customer_id', {
      header: 'Customer Id',
    }),
    columnHelper.accessor('customer_name', {
      header: 'Name',
      cell: (val) => val.getValue()?.trim()
    }),
    columnHelper.accessor('prospect_code', {
      header: 'Loan Code',
    }),
    columnHelper.accessor('disbursed_date', {
      header: 'Loan created Date',
      cell: (value) => dateFormat(value.getValue(), 'DD/MM/YYYY')
    }),
    columnHelper.accessor('loan_amount', {
      header: 'Loan Amount',
      cell: (value) => <Nums value={value.getValue()} />
    }),
    columnHelper.accessor('advance_interest', {
      header: 'Advance Interest',
      cell: (value) => <Nums value={value.getValue()} />
    }),
  ],
  receipt: [
    columnHelper.accessor('customer_id', {
      header: 'Customer Id',
    }),
    columnHelper.accessor('customer_name', {
      header: 'Name',
      cell: (val) => val.getValue()?.trim()
    }),
    columnHelper.accessor('prospect_code', {
      header: 'Loan Code',
    }),
    columnHelper.accessor('date', {
      header: 'Receipt created Date',
      cell: (value) => dateFormat(value.getValue(), 'DD/MM/YYYY')
    }),
    columnHelper.accessor('loan_type', {
      header: 'Loan Type',
    }),
    columnHelper.accessor('receipt_amount', {
      header: 'Receipt Amount',
      cell: (value) => <Nums value={value.getValue()} />
    }),
  ],
  roll_over: [
    columnHelper.accessor('customer_id', {
      header: 'Customer Id',
    }),
    columnHelper.accessor('customer_name', {
      header: 'Name',
      cell: (val) => val.getValue()?.trim()
    }),
    columnHelper.accessor('prospect_code', {
      header: 'Loan Code',
    }),
    columnHelper.accessor('date', {
      header: 'Renewal created Date',
      cell: (value) => dateFormat(value.getValue(), 'DD/MM/YYYY')
    }),
    columnHelper.accessor('loan_type', {
      header: 'Loan Type',
    }),
    columnHelper.accessor('revolving_loan_component', {
      header: 'Rollover Amount',
      cell: (value) => <Nums value={value.getValue()} />
    }),
  ],
  current: [
    columnHelper.accessor('customer_id', {
      header: 'Customer Id',
    }),
    columnHelper.accessor('customer_name', {
      header: 'Name',
      cell: (val) => val.getValue()?.trim()
    }),
    columnHelper.accessor('prospect_code', {
      header: 'Loan Code',
    }),
    columnHelper.accessor('dfd', {
      header: 'Day for Due',
    }),
    columnHelper.accessor('principle_yet_to_due', {
      header: 'Receipt Amount',
      cell: (value) => <Nums value={value.getValue()} />
    }),
  ],
  due: [
    columnHelper.accessor('customer_id', {
      header: 'Customer Id',
    }),
    columnHelper.accessor('customer_name', {
      header: 'Name',
      cell: (val) => val.getValue()?.trim()
    }),
    columnHelper.accessor('prospect_code', {
      header: 'Loan Code',
    }),
    columnHelper.accessor('principle_due', {
      header: 'DUE',
      cell: (value) => <Nums value={value.getValue()} />
    }),
  ],
  npa: [
    columnHelper.accessor('customer_id', {
      header: 'Customer Id',
      enableSorting: false,
    }),
    columnHelper.accessor('customer_name', {
      header: 'Name',
      cell: (val) => val.getValue()?.trim(),
      enableSorting: false,
    }),
    columnHelper.accessor('region', {
      header: 'Region',
      enableSorting: false,
    }),
    columnHelper.accessor('max_dpd', {
      header: 'DPD',
    }),
    columnHelper.accessor('principle_over_due', {
      header: 'Principle Overdue',
      cell: (value) => <Nums value={value.getValue()} />,
      enableSorting: false,
    }),
    columnHelper.accessor('total_due', {
      header: 'Total Overdue',
      cell: (value) => <Nums value={value.getValue()} />,
      enableSorting: false,
    }),
  ],
  'fee': [
    columnHelper.accessor('customer_id', {
      header: 'Customer Id',
    }),
    columnHelper.accessor('customer_name', {
      header: 'Name',
      cell: (val) => val.getValue()?.trim()
    }),
    columnHelper.accessor('approved_amount', {
      header: 'Approved Amount',
      cell: (value) => <Nums value={value.getValue()} />
    }),
    columnHelper.accessor('fee_base_amount', {
      header: 'Fee Base Amount',
      cell: (value) => <Nums value={value.getValue()} />
    }),
    columnHelper.accessor('total_processing_fee', {
      header: 'Total Processing Fee',
      cell: (value) => <Nums value={value.getValue()} />
    }),
  ],
  write_off: [
    columnHelper.accessor('customer_id', {
      header: 'Customer Id',
      enableSorting: false,
    }),
    columnHelper.accessor('customer_name', {
      header: 'Name',
      cell: (val) => val.getValue()?.trim(),
      enableSorting: false,
    }),
    columnHelper.accessor('region', {
      header: 'Region',
      enableSorting: false,
    }),
    columnHelper.accessor('max_dpd', {
      header: 'DPD',
    }),
    columnHelper.accessor('principle_over_due', {
      header: 'Principle Overdue',
      cell: (value) => <Nums value={value.getValue()} />,
      enableSorting: false,
    }),
    columnHelper.accessor('total_due', {
      header: 'Total Overdue',
      cell: (value) => <Nums value={value.getValue()} />,
      enableSorting: false,
    }),
  ],
};

management_dashboard_headers.enhancement_loan = management_dashboard_headers.new_loan;
management_dashboard_headers.transfer = management_dashboard_headers.new_loan;

// this contains all the headers for the management dashboard detailing
export default management_dashboard_headers;