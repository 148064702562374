const defaultRules = {
  dashboard_view: "EFFECT_ALLOW",
  fee_collection_view: "EFFECT_ALLOW",
  tranch_view: "EFFECT_ALLOW",
  e_invoice_view: "EFFECT_ALLOW",
  disb_memo_view: "EFFECT_ALLOW",
  payments_view: "EFFECT_ALLOW",
  invoice_view: "EFFECT_ALLOW",
  receipts_view: "EFFECT_ALLOW",
  foreclosure_view: "EFFECT_ALLOW",
  refund_view: "EFFECT_ALLOW",
  cancellation_view: "EFFECT_ALLOW",
  report_view: "EFFECT_ALLOW",
  payment_history: "EFFECT_ALLOW",
  soa_view: "EFFECT_ALLOW",
  historical_report_view: "EFFECT_ALLOW",
  accounting_entries_report: "EFFECT_ALLOW",
  due_report: "EFFECT_ALLOW",
  over_due_report: "EFFECT_ALLOW",
  loan_book_report: "EFFECT_ALLOW",
  disbursement_report: "EFFECT_ALLOW",
  renewal_mapping_report: "EFFECT_ALLOW",
  receipt_report: "EFFECT_ALLOW",
  detailed_receipt_report: "EFFECT_ALLOW",
  receipt_excess_report: "EFFECT_ALLOW",
  fee_report: "EFFECT_ALLOW",
  partywise_loan_book_report: "EFFECT_ALLOW",
  dpd_report: "EFFECT_ALLOW",
  passbook_report: "EFFECT_ALLOW",
  unidentified_receipt_report: "EFFECT_ALLOW",
  historical_loan_book_report: "EFFECT_ALLOW",
  legacy_disbursal_report: "EFFECT_ALLOW",
  legacy_receipt_report: "EFFECT_ALLOW",
  legacy_accounting_detail_report: "EFFECT_ALLOW",
  advance_interest_report: "EFFECT_ALLOW",
}

export const rulesForUserRoles = (groupId) => {
  switch (groupId) {
    case 3: // Accounting
    case 4: // Billing
    case 11: // Viewer
      return defaultRules;
    case 12: // Viewer
      return {
        ...defaultRules,
        management_dashboard_view: "EFFECT_ALLOW",
        customer_report: "EFFECT_ALLOW",
      };
    case 8: // Approver
    case 13: // ops head
      return {
        ...defaultRules,
        account_view: "EFFECT_ALLOW",
        eod_view: "EFFECT_ALLOW",
        eod_schedule: "EFFECT_ALLOW",
        gst_e_bill: "EFFECT_ALLOW",
        triggers: "EFFECT_ALLOW",
        management_dashboard_view: "EFFECT_ALLOW",
        waiver_dashboard_view: "EFFECT_ALLOW",
        receipt_limit: "EFFECT_ALLOW",
        tat_report: "EFFECT_ALLOW",
        appropriation_type: groupId === 13 ? "EFFECT_ALLOW" : "EFFECT_DENY",
      }
    case 1: // Admin
    case 10: // Super Admin
      return {
        ...defaultRules,
        gst_e_bill: "EFFECT_ALLOW",
        account_view: "EFFECT_ALLOW",
        product_config_view: "EFFECT_ALLOW",
        fee_view: "EFFECT_ALLOW",
        users_view: "EFFECT_ALLOW",
        data_config_view: "EFFECT_ALLOW",
        eod_view: "EFFECT_ALLOW",
        triggers: "EFFECT_ALLOW",
        holiday_list: "EFFECT_ALLOW",
        management_dashboard_view: "EFFECT_ALLOW",
        customer_report: "EFFECT_ALLOW",
        tat_report: "EFFECT_ALLOW",
        waiver_dashboard_view: "EFFECT_ALLOW",
        eod_schedule: "EFFECT_ALLOW",
        receipt_limit: "EFFECT_ALLOW",
        appropriation_type: "EFFECT_ALLOW",
      };
    default:
      return defaultRules;
  }

}