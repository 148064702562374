import { apiCallProtected } from '../api/axios';
import { deleteAPICall } from './deleteAPICall';
import { getAPICall } from './getAPICall';
import { patchAPICall } from './patchAPICall';
import { postAPICall } from './postAPICall';

export const getAllProducts = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get('/products')
      .then(({ status, data, message }) => {
        if (status === 'success') {
          resolve(data);
        } else {
          reject(message);
        }
      })
      .catch(reject);
  });
};

export const getRepaymentType = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get('/repayment-tenures')
      .then(({ status, data, message }) => {
        if (status === 'success') {
          const result = data?.map(item => ({
            label: item.name,
            value: item.id,
            ...item
          }))
          resolve(result || [])
        } else {
          reject(message);
        }
      })
      .catch((error) => {
        reject(error.meassage);
      });
  });
};

export const getFeeType = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get('/fees')
      .then(({ status, data, message }) => {
        if (status === 'success') {
          const result = data?.map(item => ({
            label: item.name,
            value: item.name,
            isDisabled: false,
            ...item
          }))
          resolve(result || [])
        } else {
          reject(message);
        }
      })
      .catch((error) => {
        reject(error.meassage);
      });
  });
};

export const getAppropriation = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get('/appropriation-definitions')
      .then(({ status, data, message }) => {
        if (status === 'success') {
          resolve(data || [])
        } else {
          reject(message);
        }
      })
      .catch(reject);
  });
};

export const getStakeholders = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get('/product-entities')
      .then(({ status, data, message }) => {
        if (status === 'success') {
          const result = data?.map(item => ({
            label: item.name,
            value: item.id,
            ...item
          }))
          resolve(result || [])
        } else {
          reject(message);
        }
      })
      .catch(reject);
  });
};

export const saveProduct = (body) => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .post('/product', body)
      .then((response) => {
        if (response?.status === 'success') {
          resolve(response?.meassage)
        } else {
          reject(response?.message)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateProduct = (id, body) => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .patch(`/product/${id}`, body)
      .then((response) => {
        if (response?.status === 'success') {
          resolve(response?.message)
        } else {
          reject(response?.message)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateProductFee = (id, body) => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .patch(`/product/${id}/fees`, body)
      .then((response) => {
        if (response?.status === 'success') {
          resolve(response?.message)
        } else {
          reject(response?.message)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getCustomerDetailsById = (id) => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get(`customer/${id}`)
      .then(({ status, data, message }) => {
        if (status === 'success') {
          resolve(data || [])
        } else {
          reject(message);
        }
      })
      .catch(reject);
  });
};

export const getMasterFee = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get('/fees')
      .then(({ status, data, message }) => {
        if (status === 'success') {
          resolve(data || [])
        } else {
          reject(message);
        }
      })
      .catch(reject);
  });
};

export const addMasterFee = (body) => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .post('/fee', body)
      .then((response) => {
        if (response?.status === 'success') {
          resolve(response?.meassage)
        } else {
          reject(response?.message)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getMasterRegions = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get('/zone-state-region-map')
      .then(({ status, data, message }) => {
        if (status === 'success') {
          resolve(data || [])
        } else {
          reject(message);
        }
      })
      .catch(reject);
  });
};

export const getAllRegions = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get('/regions')
      .then(({ status, data, message }) => {
        if (status === 'success') {
          resolve(data || [])
        } else {
          reject(message);
        }
      })
      .catch(reject);
  });
};

export const getAllZones = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get('/zones')
      .then(({ status, data, message }) => {
        if (status === 'success') {
          resolve(data || [])
        } else {
          reject(message);
        }
      })
      .catch(reject);
  });
};

export const getHolidayMaster = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get('/holidays')
      .then(({ status, data, message }) => {
        if (status === 'success') {
          resolve(data)
        } else {
          reject(message)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getHolidayById = (stateName) => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get(`/holiday?state=${stateName}`)
      .then((response) => {
        if (response?.status === 'success') {
          resolve(response)

        } else {
          reject(response?.message)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getTrancheAmountDetails = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get(`/loan-limits/master`)
      .then(({ status, data, message }) => {
        if (status === 'success') {
          resolve(data)
        } else {
          reject(message)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getTrancheAmountDetailsByProductId = (productId) => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get(`loan-limits-based-on-product/${productId}`)
      .then(({ status, data, message }) => {
        if (status === 'success') {
          resolve(data)
        } else {
          reject(message)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const updateTrancheAmountDetailsByProductId = (id, body) => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .patch(`loan-limits-based-on-product/${id}`, body)
      .then((response) => {
        if (response?.status === 'success') {
          resolve(response)
        } else {
          reject(response?.message)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// used to remove the product bank by product id and bank id
export const removeProductBankByIds = ({product_id, bank_id}) => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .delete(`remove/product-banks/${product_id}/${bank_id}`)
      .then((response) => {
        if (response?.status === 'success') {
          resolve(response)
        } else {
          reject(response?.message)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// used to get all fees by product id
export const getAllFeesByProductId = ({product_id}) => {
  return getAPICall(`product/${product_id}/mapped/fees`, {returnObject: true})
}

// used to add fee using the product id
export const addProductFeeById = ({product_id, data}) => {
  return postAPICall(`product/${product_id}/fees`, {body: data, returnObject: true})
}

// used to update the product fee by id
export const updateProductFeeById = ({product_id, fee_id, data}) => {
  return patchAPICall(`product/${product_id}/${fee_id}`, {body: data, returnObject: true})
}

// used to remove the product fee by product id
export const deleteProductFeeById = ({product_id, fee_id}) => {
  return deleteAPICall(`product/${product_id}/${fee_id}`, {returnObject: true})
}

export const updateOfficeTiming = ({body}) => {
  return patchAPICall(`office-hours`, { body, returnObject: true })
}

export const getOfficeTiming = () => {
  return getAPICall(`office-hours`)
}

export const getOfficeHolidays = () => {
  return getAPICall(`holidays/office`)
}

export const updateOfficeHoliday = ({body, id}) => {
  return patchAPICall(`holidays/office/${id}`, { body, returnObject: true })
}

export const createOfficeHoliday = ({body}) => {
  return postAPICall(`holidays/office`, { body, returnObject: true })
}

export const deleteOfficeHoliday = ({id}) => {
  return deleteAPICall(`holidays/office/${id}`, { returnObject: true })
}