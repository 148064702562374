import { Box, Button, Grid, Table, Title } from '@mantine/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Body from '../../components/Body/Body';
import { ViewFormData } from '../../components/CommonComponents/Preview/FilePreview';
import TitleViewer from '../../components/CommonComponents/Title/TitleViewer';
import DashBoardCustomerDetails from '../../components/DashboardCard/dashboard';
import PageTitle from '../../components/PageTitle/PageTitle';
import { COLORS } from '../../constants';
import ImdStyle from '../IMD/Imd.styles';
import { CompanyBankDetails } from '../../services/imd.service';
import Nums from '../../components/Body/Nums';
import IdBadge from '../../components/CommonComponents/Preview/IdBadge';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import { useQuery } from '@tanstack/react-query';
import ActionButtons
    from '../../components/CommonComponents/ActionButtons/ActionButtons';
import { generateReceiptPdfbyID } from '../../services/pdf.service';
import { useQueryClient } from "@tanstack/react-query";
import { getForeclosureDatabyId } from '../../services/foreclosure.service';
import { getRepaymentType } from '../../services/master.service';
import { getLoanDetailsById } from '../../services/loan.service';
import dayjs from 'dayjs';
import { Skeleton } from '@mantine/core';
import { getCustomerBankById } from '../../services/customer.service';
import { openFile } from '../../utils/openFile';
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification';

const ForeclosureReview = ({ action = true }) => {

  const { custId, createdForeclosure } = useParams();
  const [pdfLoading, setpdfLoading] = useState();
  const queryClient = useQueryClient();

  const { data: loanDetails = {}, isLoading: loanDetailsLoading } = useQuery(
    ['loan-detail', createdForeclosure], 
    () => createdForeclosure && getLoanDetailsById(createdForeclosure), 
    { refetchOnWindowFocus: false }
  );

  const { data: foreclosureData = [], isLoading : foreclosureDataLoading } = useQuery(
    ['foreclosure-id', createdForeclosure],
    () => createdForeclosure && getForeclosureDatabyId(createdForeclosure),
    { refetchOnWindowFocus: false }
  )
  
  const { data: customerBank = [] } = useQuery(
    ['customer-bank', custId],
    () => getCustomerBankById({customerId: custId}),
    { enabled: Boolean(loanDetails?.customer_name) }
  );

  const { data: sourceBank = [] } = useQuery(
    ['company-bank', foreclosureData[0]?.target_bank_id],
    () => CompanyBankDetails(foreclosureData[0]?.target_bank_id),
    { 
      enabled: Boolean(foreclosureData[0]?.target_bank_id),
      refetchOnWindowFocus: false 
    }
  );

  const { data: repaymentType = [] } = useQuery(
    ['repayment-tenures'],
    () => getRepaymentType(),
    { 
      enabled: !loanDetails?.repayment_tenure_id,
      select: (data) => {
        return data?.find((item) => item?.id == loanDetails?.repayment_tenure_id)
      },
      refetchOnWindowFocus: false 
    }
  );

  const handlePDF = (url, id, code) => {
    if(url) {
      openFile(url)
    } else {
      setpdfLoading({ [code]: true })
      generateReceiptPdfbyID(id)
        .then((res) => {
          queryClient.invalidateQueries(['foreclosure-id', id]);
          displayNotification({ message: 'PDF Generated Successfully', variant: 'success' })
          openFile(res)
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' })
        })
        .finally(() => {
          setpdfLoading({ [code]: false })
        })
      }
  }

  /** used to handle the no of days between disb date and due date */
  const handleNoOfDays = () => {
    var due_date = dayjs(loanDetails?.due_date)
    var no_of_days;
    no_of_days = due_date.diff(loanDetails?.disbursed_date, 'day')    
    return no_of_days;
  }

  return (
    <>
    <Body background={false}>
      {/* name and id has send as props to display in the page */}
      <PageTitle title={loanDetails?.customer_name} id={loanDetails?.customer_id}  />
    </Body>
    {/* id is send as a props to get and display the customer details  */}
    <DashBoardCustomerDetails id={custId} loansId={createdForeclosure} />
    <Box sx={{ position: 'relative' }}>
      <Body>
        {/* receipt name is send as props to display and status value is send to change the color of the badge */}
        {loanDetails?.prospect_code && (
          <div style={ImdStyle.flag}>
            <IdBadge
              remarks={loanDetails?.status_value}
              label={loanDetails?.prospect_code}
            />
          </div>
        )}
        <Grid gutter='sm' mt={'md'}>
          <Grid.Col span={4}>
            {/* DataViewerWapper component is used to display the data inside the bule background */}
            <Grid gutter='md'>
              <Grid.Col span={12}>
                <ViewFormData label='Loan Type' value={loanDetails?.loan_type == 'new' ? 'New' : 'Revolving'} loading={loanDetailsLoading} />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewFormData label='Product' value={loanDetails?.product_name} loading={loanDetailsLoading} />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewFormData label='Tranche Amount' value={<Nums withCopy value={loanDetails?.loan_amount} />} loading={loanDetailsLoading} />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewFormData label='Due Amount' value={<Nums withCopy value={loanDetails?.due_amount} />} loading={loanDetailsLoading} />
              </Grid.Col>  
              <Grid.Col span={12}>
                <ViewFormData label='Re-Payment Type' value={repaymentType?.label} loading={loanDetailsLoading}  />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={4}>
            <Grid gutter='md'>
              <Grid.Col span={12}>
                <ViewFormData label='Disb Date' value={dateFormat(loanDetails?.disbursed_date)} loading={loanDetailsLoading} />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewFormData label='Due Date' value={dateFormat(loanDetails?.due_date)} loading={loanDetailsLoading} />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewFormData label='Foreclosure Date' value={dateFormat(loanDetails?.foreclosure_approved_date)} loading={loanDetailsLoading} />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewFormData label='No.of Days' value={handleNoOfDays()} loading={loanDetailsLoading} />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={4}>
            <Grid gutter='md'>

              <Grid.Col span={12}>
                <ViewFormData label='Principal' value={<Nums withCopy value={loanDetails?.loan_amount} />} loading={loanDetailsLoading} valueStyle={{textAlign: 'right'}} />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewFormData label={`Interest (${loanDetails?.interest_rate}%)`} value={<Nums value={loanDetails?.interest_amount} />} loading={loanDetailsLoading} valueStyle={{textAlign: 'right'}} />
              </Grid.Col>                    
              { loanDetails?.spread_rate ?
                <Grid.Col span={12}>
                  <ViewFormData label={`Spread (${loanDetails?.spread_rate}%)`} value={<Nums value={loanDetails?.spread_amount} />} loading={loanDetailsLoading} valueStyle={{textAlign: 'right'}} />
                </Grid.Col> : null
              }
              { loanDetails?.addon_spread_rate ?            
                <Grid.Col span={12}>
                  <ViewFormData label={`Addon Spread (${loanDetails?.addon_spread_rate}%)`} value={<Nums value={loanDetails?.addon_spread_amount} />} loading={loanDetailsLoading} valueStyle={{textAlign: 'right'}} />
                </Grid.Col> : null
              }                  
              { loanDetails?.advance_interest ?
                <Grid.Col span={12}>
                  <ViewFormData label='Advance Interest' value={<Nums withCopy value={loanDetails?.advance_interest} />} loading={loanDetailsLoading} valueStyle={{textAlign: 'right'}} />
                </Grid.Col> : null
              }
              { loanDetails?.additional_deduction?.length ?
                <>
                  <Grid.Col span={12}>
                    <Title order={4} sx={ImdStyle.dashedTitle}>
                      Deductions
                    </Title>
                  </Grid.Col>
                  {
                    loanDetails?.additional_deduction?.map((item) => (
                      <Grid.Col span={12}>
                        <ViewFormData label={item?.fee_name} value={<Nums withCopy value={item?.fee_value} />} valueStyle={{textAlign: 'right'}}  />
                      </Grid.Col>
                    ))
                  }
                </> : null
              }
              <Grid.Col span={12}>
                <ViewFormData label='Net Disbursement Amount' value={<Nums withCopy value={loanDetails?.disbursed_amount} />} loading={loanDetailsLoading} valueStyle={{textAlign: 'right'}} />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={12} mt={'md'}>
            <TitleViewer title='Payment History' />
            <Table verticalSpacing='xs' fontSize='xs' mt={'md'}>
              <thead style={{ backgroundColor: COLORS.primary.light1 }}>
                <tr>
                  <th>Receipt Code</th>
                  <th>Receipt Amount</th>
                  <th>Pay Head</th>
                  <th>Amount</th>
                  <th>Receipt Date</th>
                  <th>From Bank</th>
                  <th>To Bank</th>
                  <th>Payment Ref.no</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>    
                {foreclosureDataLoading ? Array(4).fill(1)?.map(() => 
                  <>
                    <tr>
                      <td><Skeleton visible={foreclosureDataLoading} height={10} /></td>
                      <td><Skeleton visible={foreclosureDataLoading} height={10} /></td>
                      <td><Skeleton visible={foreclosureDataLoading} height={10} /></td>
                      <td><Skeleton visible={foreclosureDataLoading} height={10} /></td>
                      <td><Skeleton visible={foreclosureDataLoading} height={10} /></td>
                      <td><Skeleton visible={foreclosureDataLoading} height={10} /></td>
                      <td><Skeleton visible={foreclosureDataLoading} height={10} /></td>
                      <td><Skeleton visible={foreclosureDataLoading} height={10} /></td>
                      <td><Skeleton visible={foreclosureDataLoading} height={10} /></td>
                    </tr>
                  </>
                ) : null }            
                { foreclosureData?.map((item) => (
                  <>
                    {item?.appropriated_principle ?
                      <tr>
                        <td>{item?.code}</td>
                        <td>
                          <Nums value={item?.receipt_amount} />
                        </td>
                        <td>Principal</td>
                        <td>
                          <Nums value={item?.appropriated_principle} />
                        </td>
                        <td>                    
                          {item?.receipt_date}
                        </td>
                        <td>
                          {customerBank?.find((bank) => bank.id == item?.source_bank_id)?.bank_name}
                        </td>
                        <td>
                          {sourceBank[0]?.bank_name}
                        </td>
                        <td>
                          {item?.payment_ref_no}
                        </td>
                        <td>
                          <Button variant='subtle' onClick={() => {handlePDF(item?.receipt_url, item?.id, item?.code)}} loading={pdfLoading?.[item?.code]} compact size="xs">
                            View Receipt
                          </Button>
                        </td>
                      </tr> : null
                    }
                    {item?.appropriated_interest ?
                      <tr>
                        <td>{item?.code}</td>
                        <td>
                          <Nums value={item?.receipt_amount} />
                        </td>
                        <td>Interest</td>
                        <td>
                          <Nums value={item?.appropriated_interest} />
                        </td>
                        <td>                    
                          {item?.receipt_date}
                        </td>
                        <td>
                          {customerBank?.find((bank) => bank.id == item?.source_bank_id)?.bank_name}
                        </td>
                        <td>
                          {sourceBank[0]?.bank_name}
                        </td>
                        <td>
                          {item?.payment_ref_no}
                        </td>
                        <td>
                          <Button variant='subtle' onClick={() => {handlePDF(item?.receipt_url, item?.id, item?.code)}} loading={pdfLoading?.[item?.code]} compact size="xs">
                            View Receipt
                          </Button>
                        </td>
                      </tr> : null
                    }
                    {item?.appropriated_spread ?
                      <tr>
                        <td>{item?.code}</td>
                        <td>
                          <Nums value={item?.receipt_amount} />
                        </td>
                        <td>Spread</td>
                        <td>
                          <Nums value={item?.appropriated_spread} />
                        </td>
                        <td>                    
                          {dateFormat(item?.receipt_date)}
                        </td>
                        <td>
                          {customerBank?.find((bank) => bank.id == item?.source_bank_id)?.bank_name}
                        </td>
                        <td>
                          {sourceBank[0]?.bank_name}
                        </td>
                        <td>
                          {item?.payment_ref_no}
                        </td>
                        <td>
                          <Button variant='subtle' onClick={() => {handlePDF(item?.receipt_url, item?.id, item?.code)}} loading={pdfLoading?.[item?.code]} compact size="xs">
                            View Receipt
                          </Button>
                        </td>
                      </tr> : null
                    }
                    {item?.appropriated_addon_spread ?
                      <tr>
                        <td>{item?.code}</td>
                        <td>
                          <Nums value={item?.receipt_amount} />
                        </td>
                        <td>AddOn Spread</td>
                        <td>
                          <Nums value={item?.appropriated_addon_spread} />
                        </td>
                        <td>                    
                          {dateFormat(item?.receipt_date)}                           
                        </td>
                        <td>
                          {customerBank?.find((bank) => bank.id == item?.source_bank_id)?.bank_name}
                        </td>
                        <td>
                          {sourceBank[0]?.bank_name}
                        </td>
                        <td>
                          {item?.payment_ref_no}
                        </td>
                        <td>
                          <Button variant='subtle' onClick={() => {handlePDF(item?.receipt_url, item?.id, item?.code)}} loading={pdfLoading?.[item?.code]} compact size="xs">
                            View Receipt
                          </Button>
                        </td>
                      </tr> : null
                    }
                    {item?.appropriated_interest_amount_during_penalty ?
                      <tr>
                        <td>{item?.code}</td>
                        <td>
                          <Nums value={item?.receipt_amount} />
                        </td>
                        <td>Interest F1</td>
                        <td>
                          <Nums value={item?.appropriated_interest_amount_during_penalty} />
                        </td>
                        <td>                    
                          {dateFormat(item?.receipt_date)}
                        </td>
                        <td>
                          {customerBank?.find((bank) => bank.id == item?.source_bank_id)?.bank_name}
                        </td>
                        <td>
                          {sourceBank[0]?.bank_name}
                        </td>
                        <td>
                          {item?.payment_ref_no}
                        </td>
                        <td>
                          <Button variant='subtle' onClick={() => {handlePDF(item?.receipt_url, item?.id, item?.code)}} loading={pdfLoading?.[item?.code]} compact size="xs">
                            View Receipt
                          </Button>
                        </td>
                      </tr> : null
                    }
                    {item?.appropriated_spread_amount_during_penalty ?
                      <tr>
                        <td>{item?.code}</td>
                        <td>
                          <Nums value={item?.receipt_amount} />
                        </td>
                        <td>Spread F1</td>
                        <td>
                          <Nums value={item?.appropriated_spread_amount_during_penalty} />
                        </td>
                        <td>                    
                          {dateFormat(item?.receipt_date)}
                        </td>
                        <td>
                          {customerBank?.find((bank) => bank.id == item?.source_bank_id)?.bank_name}
                        </td>
                        <td>
                          {sourceBank[0]?.bank_name}
                        </td>
                        <td>
                          {item?.payment_ref_no}
                        </td>
                        <td>
                          <Button variant='subtle' onClick={() => {handlePDF(item?.receipt_url, item?.id, item?.code)}} loading={pdfLoading?.[item?.code]} compact size="xs">
                            View Receipt
                          </Button>
                        </td>
                      </tr> : null
                    }
                    {item?.appropriated_addon_spread_amount_during_penalty ?
                      <tr>
                        <td>{item?.code}</td>
                        <td>
                          <Nums value={item?.receipt_amount} />
                        </td>
                        <td>Addon Spread F1</td>
                        <td>
                          <Nums value={item?.appropriated_addon_spread_amount_during_penalty} />
                        </td>
                        <td>                    
                          {dateFormat(item?.receipt_date)}
                        </td>
                        <td>
                          {customerBank?.find((bank) => bank.id == item?.source_bank_id)?.bank_name}
                        </td>
                        <td>
                          {sourceBank[0]?.bank_name}
                        </td>
                        <td>
                          {item?.payment_ref_no}
                        </td>
                        <td>
                          <Button variant='subtle' onClick={() => {handlePDF(item?.receipt_url, item?.id, item?.code)}} loading={pdfLoading?.[item?.code]} compact size="xs">
                            View Receipt
                          </Button>
                        </td>
                      </tr> : null
                    }
                    {item?.appropriated_penal_interest_amount ?
                      <tr>
                        <td>{item?.code}</td>
                        <td>
                          <Nums value={item?.receipt_amount} />
                        </td>
                        <td>Penal F2</td>
                        <td>
                          <Nums value={item?.appropriated_penal_interest_amount} />
                        </td>
                        <td>                    
                          {dateFormat(item?.receipt_date)}
                        </td>
                        <td>
                          {customerBank?.find((bank) => bank.id == item?.source_bank_id)?.bank_name}
                        </td>
                        <td>
                            {sourceBank[0]?.bank_name}
                        </td>
                        <td>
                          {item?.payment_ref_no}
                        </td>
                        <td>
                          <Button variant='subtle' onClick={() => {handlePDF(item?.receipt_url, item?.id, item?.code)}} loading={pdfLoading?.[item?.code]} compact size="xs">
                            View Receipt
                          </Button>
                        </td>
                      </tr> : null
                    }
                  </>
                ))}
              </tbody>
            </Table>
          </Grid.Col>
        </Grid>
        {/** This Action buttons component is used to display the buttons in the flow */}
        <ActionButtons
          status={loanDetails?.status}
          isReady={loanDetails?.status}
          accountingCB={false}
          handlePDF={() => foreclosureData?.receipt_url ? openFile(foreclosureData?.receipt_url) : handlePDF()}/>
          
      </Body>
    </Box>
    </>
  );
};

export default ForeclosureReview;
