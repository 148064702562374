export const instrumentType = [
    {
      id: 1,
      value: 'imps',
      label: 'IMPS',
    },
    {
      id: 2,
      value: 'neft',
      label: 'NEFT',
    },
    {
      id: 3,
      value: 'rtgs',
      label: 'RTGS',
    },
  ];