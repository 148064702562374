import { Button } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import Body from '../../components/Body/Body';
import DataTableWrapper from '../../components/CommonComponents/Table/DataTableWrapper';
import {
    APPROVED,
    CANCELLED,
    IN_APPROVAL,
    IN_REVIEW,
    OPEN,
    REJECTED,
    CANCELLATION_REVIEW,
    CANCELLATION_APPROVAL,
} from '../../constants/status';
import { getAlleInvoices } from '../../services/e-invoice.service';
import { useQuery } from '@tanstack/react-query';
import useModuleRecordCountStore from '../../store/moduleRecordCountStore';

const CustomerInvoiceTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const columnHelper = createColumnHelper();
  const { moduleCount } = useModuleRecordCountStore();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [records, setRecords] = useState({label: 'show 10', value: 10});
  // get the search params
  const [searchParams, setSearchParams] = useSearchParams();
  const params = {}
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  /** used to change the page to 1 while the search us used 
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1)
  }, [search, searchParams.get("status"), searchParams.get("entity"), records])

  const { data: customerInvoice = [], isLoading } = useQuery(
    ['e-invoice all', page, search, records, params],
    () => getAlleInvoices({page, search, records: records?.value, ...params}),
    {
      enabled: Boolean(searchParams.get("status") && searchParams.get("status") !== "none"),
      refetchOnWindowFocus: false
    }
  )

  const onRowClick = (i) => {
    if (i?.status_value == OPEN) {
      navigate(`/external-customer/view/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == IN_APPROVAL || i?.status_value == IN_REVIEW) {
      navigate(`/external-customer/review/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == APPROVED) {
      navigate(`/external-customer/approved/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == REJECTED) {
      navigate(`/external-customer/rejected/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if(i?.status_value == CANCELLED) {
      navigate(`/external-customer/cancelled/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if(i?.status_value == CANCELLATION_REVIEW || i?.status_value == CANCELLATION_APPROVAL) {
      navigate(`/external-customer/cancel/${i?.customer_id}`, { state: { from: location.pathname } });
    }
  };

  const onClickAction = (value) => {
    return (
      <span
        style={{ color: '#0063FF', cursor: 'pointer' }}
      >
        {value.getValue()}
      </span>
    );
  };

  const tableAction = (
      <Button 
        variant='filled' 
        color='dark' 
        size='sm' 
        sx={{ marginLeft: 12 }} 
        leftIcon={<Plus size={18} />} 
        onClick={() => navigate(`/external-customer/create`, { state: { from: location.pathname } })}
      >
        Create Customer
      </Button>
  );

  const columnData = [
    columnHelper.accessor('customer_code', {
      header: 'Customer Code',
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor('customer_id', {
      header: 'Customer Id',
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor('customer_name', {
      header: 'Customer Name',
    }),
    columnHelper.accessor('gst_number', {
      header: 'GST No',
    }),
    columnHelper.accessor('maker_name', {
      header: 'Maker Name',
    }),
  ];

  return (
    <Body background={false}>
      <DataTableWrapper
        title='External Customer'
        filter={false}
        rowData={customerInvoice?.data}
        columnData={columnData}
        onRowClick={onRowClick}
        noDataText='No External Customer yet!'
        noDataSubText='This section will contain list of created External Customer details.'
        useApiPagination
        totalNoOfPages= {Math.ceil(parseInt(moduleCount?.count)/records?.value)}
        totalNoOfRecords={moduleCount?.count}
        page={page}
        setPage={setPage}
        rowLength={records}
        setRowLength={setRecords}
        apiSearch={setSearch}
        showStatus
        module_name='external_customer'
        addDataCallback={() => navigate(`/external-customer/create`, { state: { from: location.pathname } })}
        actions={tableAction}
        // when the status is none then the loading is set to false other wise it will dependent on isFetching
        loading={searchParams.get("status") !== "none" ? isLoading : searchParams.get("status") === null ? true : false}
      />
    </Body>
  );
};

export default CustomerInvoiceTable;
