import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Nums from '../../components/Body/Nums';
import { Text, Drawer } from '@mantine/core';
import { getEligibleRefundDetails } from '../../services/refund.service';
import Filter from '../../components/CommonComponents/Filter/Filter';
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification';
import { createColumnHelper } from '@tanstack/react-table';
import SimpleReactTable from '../../components/CommonComponents/Table/SimpleReactTable';

const RefundDrawer = ({open, onClose}) => {
  const columnHelper = createColumnHelper();
  const [filter, setFilter] = useState(true);
  const [loading, setLoading] = useState();
  const [approvedIMD, setApprovedIMD] = useState([]);
  const [filterDetails, setFilterDetails] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (values, filterData) => {
    setFilterDetails(filterData)
    setLoading(true)
    getEligibleRefundDetails({...values})
      .then((res) => {
        setApprovedIMD(res)
      })
      .catch((e) => {
        setApprovedIMD()
        displayNotification({id: 'customer-imd', message: e?.message || e, variant: 'error'});
      })
      .finally(() => {
        setLoading(false)
        setFilter(false)
      })
  }

  const columns = [
    columnHelper.accessor('customer_id', {
      header: 'Customer Id',
    }),
    columnHelper.accessor('customer_name', {
      header: 'Name',
    }),
    columnHelper.accessor('customer_excess_amount', {
      header: <Text sx={{textAlign: 'right'}}>Excess Amount</Text>,
      cell: (value) =>  <Nums value={value.getValue()} styles={{textAlign: 'right'}} />,
    }),
  ];
  return (
    <Drawer
      opened={open}
      onClose={onClose}
      filterData={filterDetails}
      title='Find Loan Details'
      position='right'
      padding='xl'
      size='50%'
    >
      {/* Used to show filter */}
      { filter && open ?
        <Filter
          callback={onSubmit} 
          onClose={onClose} 
          loading={loading} 
          type={'refund'} 
        /> :
        <SimpleReactTable
          rowData={approvedIMD}
          columnData={columns}
          filterData={filterDetails}
          setFilter={setFilter}
          showFilter
          sx={{ height: '70vh' }}
          rowKeyGetter={(row) => {
            return row?.id;
          }}
          onRowClick={(i, o) =>
            navigate(`/refund/${i?.customer_id}`, { state: { from: location.pathname } })
          }
        />
      }
    </Drawer>
  )
}

export default RefundDrawer