import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { SpotlightRoutes } from './routes/SpotlightRoutes';
import Routing from './routes/Routing';
import defaultTheme from './theme/petromoneyThemeDefault';
import useAuthStore from './store/authStore';
import Login from './pages/login/Login';
import { useEffect } from 'react';
import requestInterceptor from './api/requestInterceptor';
import responseInterceptor from './api/responseInterceptor';
import { apiCallProtected } from './api/axios';

function App() {
  const { access_token } = useAuthStore(state => ({
    access_token: state.auth?.access_token,
    authObj: state?.auth,
    resetAuth: state?.resetAuth
  }));
  
  requestInterceptor()
  responseInterceptor();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  useEffect(() => {
    return () => {
      apiCallProtected.interceptors.request.eject(requestInterceptor)
      apiCallProtected.interceptors.response.eject(responseInterceptor)
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        theme={defaultTheme}
        withCSSVariables
        withGlobalStyles
        withNormalizeCSS
      >
        <Notifications position='top-right' zIndex={2077} limit={5} />
          <BrowserRouter>
            <SpotlightRoutes>
              <Routes>
                {
                  access_token ? 
                    <Route path='/*' element={<Routing />} />
                    : <Route
                        path='/*'
                        element={<Login />}
                      />
                }
              </Routes>
            </SpotlightRoutes>
          </BrowserRouter>
      </MantineProvider>
    </QueryClientProvider>
  );
}

export default App;
