import { Box, Grid, Image } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import Body from '../../components/Body/Body'
import PageTitle from '../../components/PageTitle/PageTitle'
import DashBoardCustomerDetails from '../../components/DashboardCard/dashboard'
import DataViewerWapper from '../../components/CommonComponents/Preview/DataViewerWapper'
import { ViewFormData } from '../../components/CommonComponents/Preview/FilePreview'
import ControllerCurrencyInput from '../../components/CommonComponents/TextField/ControllerCurrencyInput'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ControllerSelect from '../../components/CommonComponents/TextField/ControllerSelect'
import { customerBankDetails } from '../../services/imd.service'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { createRefund, getAllRefundRemarksById, getEligibleRefundDetails, getRefundById, refundSaveAndSendForNextStage, refundStatusChange, updateRefund } from '../../services/refund.service'
import { getBusinessBanks } from '../../services/banks.service'
import TitleViewer from '../../components/CommonComponents/Title/TitleViewer'
import useSystemConfigStore from '../../store/systemConfigStore'
import { dateFormat } from '../../components/DateFormat/dateFormat'
import { getStageforModules } from '../../services/module.service'
import ActionButtons from '../../components/CommonComponents/ActionButtons/ActionButtons'
import ModalDataViwer from '../../components/SucessfullModal/ModalDataViwer'
import Remarks from '../../components/CommonComponents/Remarks/Remarks'
import { GENERAL_IMG, STAGE_CHANGE_TEXT } from '../../constants'
import ImdStyle from '../IMD/Imd.styles'
import { instrumentType } from '../../utils/InstrumentTypes'
import ControllerTextField from '../../components/CommonComponents/TextField/ControllerTextField'
import ViewRemark from '../../components/Remark/ViewRemark'
import Nums from '../../components/Body/Nums'
import { currencyAsInt } from '../../components/Currency/currencyFormat'
import IdBadge from '../../components/CommonComponents/Preview/IdBadge'
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification'

const schema = yup.object({
  refund_amount: yup.string().required("Please Enter Amount"),
  instrument_type: yup.string().required("Please Select Instrument Type"),
  target_bank_id: yup.string().required("Please Select Bank"),
  source_bank_id: yup.string().required("Please Select Bank"),
  payment_ref_no: yup.string().nullable().required("Please Enter Payment Reference number"),
})

const RefundCreation = ({ formType }) => {

  const { customerId, refundId } = useParams();
  const systemDate = useSystemConfigStore(store => store.config);
  const [open, setOpen] = useState(false);
  const [remarksOpen, setRemarksOpen] = useState();
  const [sucessfulmodal, setSucessfulmodal] = useState({});
  const [status, setStatus] = useState();
  const [label, setLabel] = useState();
  const [remarksLoading, setRemarksLoading] = useState();
  const [buttonLoader, setButtonLoader] = useState();

  const eligibleRefundCustomer = useMutation((body) => getEligibleRefundDetails(body))

  const { data: refundData = {} } = useQuery(
    ['refund', refundId],
    () => getRefundById(refundId),
    {
      enabled: Boolean(refundId),
      select: (data) => {
        return data?.[0] || {}
      }
    }
  )

  const { data: stages = [] } = useQuery(
    ['refund-stage'],
    () => getStageforModules('refund'),
    {
      select: (data) => {
        return {
          'current_stage': 'draft',
          'next_stage': data[1]
        }
      },
    }
  );

  const { data: reviewTrail = [] } = useQuery(
    ['remarks', refundId],
    () => getAllRefundRemarksById(refundId),
    {
      enabled: Boolean(refundId),
    }
  );

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...refundData
    }
  });
  const values = watch()

  useEffect(() => {
    const body = { customer_id: customerId }
    if (customerId) {
      eligibleRefundCustomer.mutate(body, {
        onSuccess: (data) => {
          console.log(data)
        }
      })
    }
  }, [customerId])

  useEffect(() => {
    if (Object.keys(refundData).length) {
      reset(refundData);
    }
  }, [refundData]);

  /** get customer banks */
  const { data: customerBank = [] } = useQuery(
    ['customer-bank', customerId],
    () => customerBankDetails(customerId),
    { enabled: Boolean(customerId) }
  );

  // get company banks
  const { data: companyBank = [] } = useQuery(
    ['company-bank', eligibleRefundCustomer?.data?.[0]?.entity_id],
    () => getBusinessBanks({ entity: [eligibleRefundCustomer?.data?.[0]?.entity_id] }),
    {
      enabled: Boolean(eligibleRefundCustomer?.data?.[0]?.entity_id),
    }
  );

  const handleReviewModal = () => {
    schema.validate(values)
      .then(res => {
        setOpen(true);
        setStatus('review');
        setLabel(stages?.next_stage == 'approval' ? 'Send for Approval' : 'Send for Review');
      })
      .catch(err => {
        displayNotification({ message: err?.errors, variant: 'error' })
      })
  }

  const onSubmit = () => {
    const body = {
      refund_amount: currencyAsInt(values?.refund_amount),
      target_bank_id: values?.target_bank_id,
      source_bank_id: values?.source_bank_id,
      instrument_type: values?.instrument_type,
      payment_ref_no: values?.payment_ref_no,
      customer_id: customerId,
    }
    setButtonLoader({ save: true });
    if (!refundId) {
      createRefund(body)
        .then(res => {
          setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.save });
        })
        .catch(e => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({
              id: 'pmt-creation',
              message: e?.message || e,
              variant: 'error',
            });
          }
        })
        .finally(() => {
          setButtonLoader({ save: false });
        })
    } else {
      updateRefund(refundId, body)
        .then(res => {
          setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.update });
        })
        .catch(e => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({
              id: 'pmt-creation',
              message: e?.message || e,
              variant: 'error',
            });
          }
        })
        .finally(() => {
          setButtonLoader({ save: false });
        })
    }
  }

  const OnSendForReview = (remarks) => {
    const body = {
      refund_amount: currencyAsInt(values?.refund_amount),
      target_bank_id: values?.target_bank_id,
      source_bank_id: values?.source_bank_id,
      instrument_type: values?.instrument_type,
      payment_ref_no: values?.payment_ref_no,
      customer_id: customerId,
    }
    const remarksBody = {
      remark_type: 'maker',
      remarks: remarks,
    }

    setRemarksLoading(true);
    if (!refundId) {
      refundSaveAndSendForNextStage({ ...body, ...remarksBody })
        .then((res) => {
          setSucessfulmodal({ open: true, title: stages?.next_stage == 'approval' ? STAGE_CHANGE_TEXT?.approval : STAGE_CHANGE_TEXT?.review });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' });
          }
        })
        .finally(() => {
          setRemarksLoading(false);
          setOpen(false);
        })
    } else {
      updateRefund(refundId, body)
        .then((res) => {
          refundStatusChange(refundId, remarksBody)
            .then((res) => {
              setSucessfulmodal({ open: true, title: stages?.next_stage == 'approval' ? STAGE_CHANGE_TEXT?.approval : STAGE_CHANGE_TEXT?.review });
            })
            .catch((e) => {
              if (e?.RequestValidationError) {
                Object.keys(e?.RequestValidationError).forEach((item, index) => {
                  setError(item, { message: e?.RequestValidationError[item] });
                });
              } else {
                displayNotification({ message: e?.message || e, variant: 'error' });
              }
            });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' });
          }
        })
        .finally(() => {
          setRemarksLoading(false);
          setOpen(false);
        })
    }
  }

  /** this function is used to handle the refund amount field error */
  const validateRefundAmount = () => {
    let msg = "Amount should not be greater than excess amount";
    if (eligibleRefundCustomer?.data?.[0]?.customer_excess_amount) {
      if (
        currencyAsInt(values?.refund_amount) >
        parseInt(eligibleRefundCustomer?.data?.[0]?.customer_excess_amount)
      ) {
        return msg;
      } else {
        return errors?.refund_amount?.message;
      }
    }
  };

  return (
    <Box>
      <Body background={false}>
        {/* name and id has send as props to display in the page */}
        <PageTitle title={eligibleRefundCustomer?.data?.[0]?.customer_name} id={eligibleRefundCustomer?.data?.[0]?.customer_id} />
      </Body>
      {/* id is send as a props to get and display the customer details  */}
      <DashBoardCustomerDetails id={customerId} codeName={'Refund Code'} code={refundData?.code} />
      <Box sx={{ position: 'relative' }}>
        <Body>
          {refundData?.code ? (
            <div style={ImdStyle.flag}>
              <IdBadge
                label={refundData?.code}
              />
            </div>
          ) : null}
          <Grid gutter='xs' mt={'md'}>
            <Grid.Col span={4}>
              <Grid.Col
                span={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  src={GENERAL_IMG}
                  alt="Illustration"
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={4}>
              <DataViewerWapper>
                <ViewFormData label={'Excess Amount'} value={<Nums value={eligibleRefundCustomer?.data?.[0]?.customer_excess_amount} />} loading={eligibleRefundCustomer?.isLoading} />
                <ViewFormData label={'Refund Date'} value={dateFormat(systemDate?.current_system_date, 'DD-MM-YYYY')} loading={eligibleRefundCustomer?.isLoading} />
              </DataViewerWapper>
              <Grid.Col span={12} mt={'md'} px={0}>
                <ControllerCurrencyInput
                  name='refund_amount'
                  label='Refund Amount'
                  value={values?.refund_amount}
                  amountInWords
                  /** asterisk will be vissible when there is no excess amount */
                  required={true}
                  control={control}
                  errors={validateRefundAmount()}
                />
              </Grid.Col>
              <Grid.Col span={12} px={0}>
                <ControllerSelect
                  name='instrument_type'
                  label={'Instrument Type'}
                  control={control}
                  required={true}
                  placeholder='Select'
                  data={instrumentType}
                  errors={errors?.source_bank_id?.message}
                />
              </Grid.Col>
              <Grid.Col span={12} px={0}>
                <ControllerTextField
                  name='payment_ref_no'
                  label={'Payment Ref.No'}
                  value={values?.payment_ref_no?.toUpperCase()}
                  control={control}
                  errors={errors?.payment_ref_no?.message}
                  autoComplete='off'
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={4}>
              <Grid.Col span={12}>
                <TitleViewer title={'Source Bank: Petromoney'} />
              </Grid.Col>
              <Grid.Col span={12} mt={'xs'}>
                <ControllerSelect
                  name='source_bank_id'
                  control={control}
                  autoSelect
                  setValue={setValue}
                  spanSize={12}
                  displayLabel={false}
                  placeholder='Select Bank Details'
                  data={companyBank}
                  errors={errors?.source_bank_id?.message}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <TitleViewer title={'Target Bank: Customer'} />
              </Grid.Col>
              <Grid.Col span={12} mt={'xs'}>
                <ControllerSelect
                  name='target_bank_id'
                  control={control}
                  autoSelect
                  setValue={setValue}
                  spanSize={12}
                  displayLabel={false}
                  placeholder='Select Bank Details'
                  data={customerBank}
                  errors={errors?.target_bank_id?.message}
                />
              </Grid.Col>
            </Grid.Col>
          </Grid>
          {/** This Action buttons component is used to display the buttons in the flow */}
          <ActionButtons
            status={refundData?.status_value ? refundData?.status_value : 'draft'}
            base={'creation'}
            nextStage={stages?.next_stage}
            sendForReviewCB={handleSubmit(handleReviewModal)}
            sendForApprovalCB={handleSubmit(handleReviewModal)}
            saveCB={() => {
              handleSubmit(onSubmit)()
            }}
            loading={{ save: buttonLoader?.save }}
            remarksCB={() => setRemarksOpen(true)}
            remarks={reviewTrail}
            currentStatus={values?.status_value}
            moduleApplicationId={values?.id}
            moduleName={'refund'}
            isReady={Boolean(stages?.next_stage)} />
          {/** display the modal on sucessfull status change */}
          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={values?.code}
            title={sucessfulmodal?.title}
            href={'/refund'}
            hreftext={'Back to list table'}
          />
          {/** used to get the remarks from the user */}
          <Remarks
            open={open}
            setOpen={setOpen}
            type={stages?.next_stage}
            callback={OnSendForReview}
            remarksTitle={stages?.next_stage == 'review' ? 'Review Remarks' : 'Approval Remarks'}
            check={status}
            label={label}
            loading={remarksLoading}
            currentStatus={values?.status_value}
            moduleApplicationId={values?.id}
            moduleName={'refund'}
          />
        </Body>
        <ViewRemark open={remarksOpen} onClose={setRemarksOpen} remarkTrail={reviewTrail} />
      </Box>
    </Box>
  )
}

export default RefundCreation