import React, { useEffect, useState } from "react";
import { Button } from "@mantine/core";
import { Text } from "@mantine/core";
import { Group } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { Trash } from "tabler-icons-react";
import DialogModal from './../Modal/DialogModal';
import { DEFAULT_SUPPORTED_FILE_TYPES } from "../../../constants";

const supportedFile = DEFAULT_SUPPORTED_FILE_TYPES;

const AddFile = ({
  open,
  setOpen,
  callback,
  handleDelete,
  file,
  acceptedFileTypes = supportedFile,
  Title = false,
  subText = false,
}) => {
  const [fileUpload, setFileUpload] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);

  useEffect(() => {
    setFileUpload([])
  }, [open])

  return (
    <DialogModal
      opened={open}
      size={"lg"}
      centered
      onClose={() => setOpen(false)}
    >
      <Dropzone
        onDrop={(files) => setFileUpload(files)}
        onReject={(e) => console.log(e)}
        accept={{acceptedFileTypes}}
        maxSize={3 * 1024 ** 2}
      >
        <Group
          position="center"
          spacing="xl"
          style={{ minHeight: 220, pointerEvents: "none" }}
        >
          <div>
            <Text size="xl" inline>
              {Title ? Title : "Drag files here or click to select files"}
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              {subText
                ? subText
                : "Attach as many files as you like, each file should not exceed 4mb"}
            </Text>
          </div>
        </Group>
      </Dropzone>
      {fileUpload && (
        <Text size={"13px"} color={"green"}>
          {fileUpload[0]?.name}
        </Text>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          gap: "10px",
          paddingTop: "10px",
        }}
      >
        {fileUpload?.length ? (
          <Button
            variant="outline"
            color={"red"}
            leftIcon={<Trash />}
            onClick={() => {
              setFileUpload();
              handleDelete();
            }}
          >
            Drop
          </Button>
        ) : null}
        <Button variant="subtle" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          loading={fileLoading}
          onClick={() => {
            setFileLoading(true);
            callback(fileUpload, setFileLoading, setFileUpload);
          }}
        >
          Upload
        </Button>
      </div>
    </DialogModal>
  );
};

export default AddFile;
