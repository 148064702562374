import React from 'react'
import { Grid, Stack, Text, Button, Title } from '@mantine/core';
import COLORS from './../../../constants/colors';
import ControllerTextField from '../../../components/CommonComponents/TextField/ControllerTextField';
import ControllerSwitch from '../../../components/CommonComponents/TextField/ControllerSwitch';
import ControllerSelect from '../../../components/CommonComponents/TextField/ControllerSelect';
import { useQuery } from '@tanstack/react-query';
import { getAllCities, getAllStates } from '../../../services/common.service';
import ControllerDatePicker from '../../../components/CommonComponents/TextField/ControllerDatePicker';

/**
 * Renders a component for a general invoice with inputs for GST number, personal details, address details, and finance details.
 *
 * @param {Object} props - An object containing the props for the component.
 * @param {Object} props.control - Controller object for the form inputs.
 * @param {Object} props.errors - Object containing errors for the form inputs.
 * @param {Function} props.setValue - Function to set the value of the form inputs.
 * @param {Object} props.placeholderStyle - Object containing styling for the input placeholders.
 * @param {Function} props.verifyGST - Function to verify the GST number.
 * @param {Boolean} props.gstLoading - A boolean flag indicating whether GST verification is loading.
 * @param {Object} props.placeholderSelectStyle - Object containing styling for the select input placeholders.
 */
const GeneralInvoiceComp = ({ control, errors, setValue, placeholderStyle, verifyGST, gstLoading, placeholderSelectStyle }) => {

  const allCitiesQuery = useQuery({
    queryKey: ['allCities'],
    queryFn: () => getAllCities(),
  });

  const allStatesQuery = useQuery({
    queryKey: ['allStates'],
    queryFn: () => getAllStates(),
  });

  return (
    <Grid gutter={'md'} sx={{ maxHeight:"55vh",overflow:'hidden',overflowY: 'auto'}}>
      <Grid.Col span={12} mt={'sm'}>
        <Stack spacing={'xs'}>
          <ControllerTextField
            name={'gst_number'}
            label={'GST No'}
            displayLabel={false}
            spanSize={4}
            rightSection={<Button loading={gstLoading} color='green' size='sm' onClick={() => verifyGST()}>Proceed</Button>}
            placeholder={'GST No'}
            control={control}
            styles={placeholderStyle}
            errors={errors?.gst_no?.message}
            required
          />
        </Stack>
      </Grid.Col>
      <Grid.Col span={8} m={0} p={0} pr={'xs'} sx={{borderRight: `1px solid ${COLORS.gray(10)}`}}>
        <Grid.Col span={12} sx={{ height: 'fit-content' }}>
          <Title order={6} mb={'md'}>Personal Details</Title>
          <Grid gutter={'md'}>
            <Grid.Col span={12} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>Name</Text>
                <ControllerTextField
                  name={'name'}
                  label={'Name'}
                  displayLabel={false}
                  spanSize={6}
                  placeholder={'Name of Customer'}
                  control={control}
                  styles={placeholderStyle}
                  errors={errors?.name?.message}
                  required
                />
              </Stack>
            </Grid.Col>
            {/* <Grid.Col span={6} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>Search Name</Text>
                <ControllerTextField
                  name={'search_name'}
                  displayLabel={false}
                  spanSize={12}
                  placeholder={'Enter Search Name'}
                  control={control}
                  styles={placeholderStyle}
                  errors={errors?.search_name?.message}
                />
              </Stack>
            </Grid.Col> */}
            <Grid.Col span={4} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>Phone Number</Text>
                <ControllerTextField
                  name={'mobile'}
                  displayLabel={false}
                  spanSize={12}
                  placeholder={'Enter Phone Number'}
                  control={control}
                  styles={placeholderStyle}
                  errors={errors?.phone_number?.message}
                />
              </Stack>
            </Grid.Col>
            <Grid.Col span={4} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>Primary Contact No</Text>
                <ControllerTextField
                  name={'primary_contact_no'}
                  label={'Primary Contact No'}
                  displayLabel={false}
                  spanSize={12}
                  placeholder={'Primary Contact No'}
                  control={control}
                  styles={placeholderStyle}
                  errors={errors?.primary_contact_no?.message}
                />
              </Stack>
            </Grid.Col>
            <Grid.Col span={4} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>Contact</Text>
                <ControllerTextField
                  name={'contact'}
                  label={'Contact'}
                  displayLabel={false}
                  spanSize={12}
                  placeholder={'Contact'}
                  control={control}
                  styles={placeholderStyle}
                  errors={errors?.contact?.message}
                />
              </Stack>
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={12} sx={{ height: 'fit-content' }}>
          <Title order={6} mb={'md'}>Address Details</Title>
          {/* <Text size={'xs'} mb={'md'} sx={{color: COLORS.gray(50), width: '400px'}}>Once the GST has verified then the address will get filled automatically or else user need to enter it manually.</Text> */}
          <Grid gutter={'md'}>
            <Grid.Col span={12} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>Address</Text>
                <ControllerTextField
                  name={'address'}
                  label={'Address'}
                  displayLabel={false}
                  spanSize={12}
                  placeholder={'Address of Customer'}
                  control={control}
                  styles={placeholderStyle}
                  errors={errors?.address?.message}
                />
              </Stack>
            </Grid.Col>
            <Grid.Col span={4} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>City</Text>
                <ControllerSelect
                  name='city'
                  label='City'
                  placeholder='Select City'
                  displayLabel={false}
                  spanSize={12}
                  control={control}
                  autoSelect
                  setValue={setValue}
                  styles={placeholderSelectStyle}
                  required
                  errors={errors?.source_bank?.message}
                  data={allCitiesQuery?.data || []}
                />
              </Stack>
            </Grid.Col>
            <Grid.Col span={4} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>Post Code</Text>
                <ControllerTextField
                  name={'post_code'}
                  label={'Post Code'}
                  displayLabel={false}
                  spanSize={12}
                  placeholder={'Enter Post Code'}
                  control={control}
                  styles={placeholderStyle}
                  errors={errors?.post_code?.message}
                />
              </Stack>
            </Grid.Col>
            <Grid.Col span={4} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>State</Text>
                <ControllerSelect
                  name='state'
                  label='State'
                  placeholder='Select state'
                  displayLabel={false}
                  spanSize={12}
                  control={control}
                  autoSelect
                  setValue={setValue}
                  styles={placeholderSelectStyle}
                  required
                  errors={errors?.source_bank?.message}
                  data={allStatesQuery?.data || []}
                />
              </Stack>
            </Grid.Col>
            {/* <Grid.Col span={4} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>State Code</Text>
                <ControllerTextField
                  name={'state_code'}
                  label={'State Code'}
                  displayLabel={false}
                  spanSize={12}
                  placeholder={'Enter State Code'}
                  control={control}
                  styles={placeholderStyle}
                  errors={errors?.state_code?.message}
                />
              </Stack>
            </Grid.Col> */}
          </Grid>
        </Grid.Col>
      </Grid.Col>
      <Grid.Col span={4} m={0} p={0} pl={'xs'}>
        <Grid.Col span={12} sx={{ height: 'fit-content' }}>
          <Title order={6} mb={'md'}>Finance Details</Title>
          {/* <Text size={'xs'} mb={'md'} sx={{color: COLORS.gray(50)}}>This section will contain list of Finance Details of the customer</Text> */}
          <Grid gutter={'md'}>
            <Grid.Col span={12} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>Balance (LCY)</Text>
                <ControllerTextField
                  name={'balance'}
                  label={'Balance (LCY)'}
                  displayLabel={false}
                  spanSize={12}
                  placeholder={'Enter Balance (LCY)'}
                  control={control}
                  styles={placeholderStyle}
                  errors={errors?.balance?.message}
                />
              </Stack>
            </Grid.Col>
            <Grid.Col span={12} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>Credit Limit</Text>
                <ControllerTextField
                  name={'credit_limit'}
                  label={'Credit Limit'}
                  displayLabel={false}
                  spanSize={12}
                  placeholder={'Enter Credit Limit'}
                  control={control}
                  styles={placeholderStyle}
                  errors={errors?.credit_limit?.message}
                />
              </Stack>
            </Grid.Col>
            <Grid.Col span={12} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>Sales Person Code</Text>
                <ControllerTextField
                  name={'sales_person_code'}
                  label={'Sales Person Code'}
                  displayLabel={false}
                  spanSize={12}
                  placeholder={'Enter Sales Person Code'}
                  control={control}
                  styles={placeholderStyle}
                  errors={errors?.sales_person_code?.message}
                />
              </Stack>
            </Grid.Col>
            <Grid.Col span={12} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>Responsibility Center</Text>
                <ControllerTextField
                  name={'responsibility_center'}
                  label={'Responsibility Center'}
                  displayLabel={false}
                  spanSize={12}
                  placeholder={'Enter Responsibility Center'}
                  control={control}
                  styles={placeholderStyle}
                  errors={errors?.responsibility_center?.message}
                />
              </Stack>
            </Grid.Col>
            <Grid.Col span={12} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>Service Zone Code</Text>
                <ControllerTextField
                  name={'service_zone_code'}
                  label={'Service Zone Code'}
                  displayLabel={false}
                  spanSize={12}
                  placeholder={'Enter Service Zone Code'}
                  control={control}
                  styles={placeholderStyle}
                  errors={errors?.service_zone_code?.message}
                />
              </Stack>
            </Grid.Col>
            <Grid.Col span={6} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>Blocked</Text>
                <ControllerSwitch
                  name={'blocked'}
                  label={'Blocked'}
                  displayLabel={false}
                  spanSize={12}
                  onLabel="Yes"
                  offLabel="No"
                  size={'md'}
                  color="red"
                  control={control}
                />
              </Stack>
            </Grid.Col>
            <Grid.Col span={6} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>Privacy Blocked</Text>
                <ControllerSwitch
                  name={'privacy_blocked'}
                  label={'Privacy Blocked'}
                  displayLabel={false}
                  spanSize={12}
                  onLabel="Yes"
                  offLabel="No"
                  size={'md'}
                  color="red"
                  control={control}
                />
              </Stack>
            </Grid.Col>
            <Grid.Col span={12} py={0}>
              <Stack spacing={'xs'}>
                <Text size={'xs'}>Late Date Modified</Text>
                {/* <ControllerTextField
                  name={'late_date_modified'}
                  label={'Late Date Modified'}
                  displayLabel={false}
                  spanSize={12}
                  placeholder={'Enter Late Date Modified'}
                  control={control}
                  styles={placeholderStyle}
                  errors={errors?.late_date_modified?.message}
                /> */}
                <ControllerDatePicker
                  name='last_date_modified'
                  label='Fee Date'
                  required
                  displayLabel={false}
                  spanSize={12}
                  maxDate={new Date()}
                  control={control}
                  errors={errors?.imd_date?.message}
                />
              </Stack>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid.Col>
    </Grid>
  )
}

export default GeneralInvoiceComp