import { createColumnHelper } from '@tanstack/react-table';
import changeValueFormat from '../../utils/changeValueFormat';
import { Text } from '@mantine/core';

const columnHelper = createColumnHelper();

/*
* this function is used to generate the headers 
* @props
* data: contains the array of object with the keys of [name, key, type]
*/
export const generateReportHeaders = (data) => {
  return data?.map((item) => {
    return columnHelper.accessor(item?.key, {
      header: item.name,
      cell: (value) => {
        return changeValueFormat(value.getValue(), item?.type);
      }
    })
  });
}