import { Box, Button, Grid } from "@mantine/core";
import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import ControllerTextArea from "../../../../components/CommonComponents/TextField/ControllerTextArea";
import ControllerTextField from "../../../../components/CommonComponents/TextField/ControllerTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { addMasterFee } from "../../../../services/master.service";
import { displayNotification } from "../../../../components/CommonComponents/Notification/displayNotification";

const feeSchema = yup.object({
  name: yup.string().required("Please Enter Fee name"),
  description: yup.string().required("Please Enter a description for this fee"),
});

const Fee = ({ id, formData, onClose }) => {
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(feeSchema),
    defaultValues: {
      ...formData,
    },
  });

  const onSaveFee = (value) => {
    const body = {
      ...value,
    };

    addMasterFee(body)
      .then((res) => {
        queryClient.invalidateQueries(["master-fee"]);
        displayNotification({
          message: "Fee added successfully!",
          variant: 'success',
        });
        onClose();
      })
      .catch((e) =>
        displayNotification({ message: e?.message || e, variant: 'error' })
      );
  };

  return (
    <Box>
      <Box>
        <Grid gutter="xs" mt="md">
          <Grid.Col span={12}>
            <ControllerTextField
              name="name"
              label="Fee Name"
              control={control}
              errors={errors?.name?.message}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <ControllerTextArea
              name="description"
              label="Fee Description"
              control={control}
              errors={errors?.description?.message}
            />
          </Grid.Col>
        </Grid>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "12px 0",
          }}
        >
          {!id && (
            <>
              <Button variant="subtle" onClick={onClose}>
                Cancel
              </Button>
              <Button color="green" ml="md" onClick={handleSubmit(onSaveFee)}>
                Create Fee
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Fee;
