import { MantineProvider, Text, Tooltip } from '@mantine/core';
import React from 'react';
import { currencyInWords } from '../CommonComponents/TextField/NumberToWords';
import { currencyAsFloat, currencyAsInt } from '../Currency/currencyFormat';
import { COLORS } from '../../constants';
import { Check } from 'tabler-icons-react';
import { displayNotification } from '../CommonComponents/Notification/displayNotification';

const Nums = ({ value = 0, withTooltip = false, styles, withCopy = false, round=true }) => {
  
  let amount = currencyAsFloat(value);
  const finalAmount = round ? Math.round(amount) : amount?.toFixed(2);

  const copy = () => {
    if('clipboard' in navigator && amount > 0) {
      navigator.clipboard.writeText(currencyAsInt(amount))
      displayNotification({message: 'Copied', variant: 'success', icon: <Check  />, autoClose: 1000})
    } else {
      displayNotification({message: `Couldn't copy`, variant: 'warning'})
    }
  }

  return (
    <MantineProvider
      theme={{ fontFamily: 'sans-serif', fontWeight: 800 }}
    >
      <Tooltip label={<Text sx={{fontWeight: 200, textTransform: 'capitalize'}}>{currencyInWords(currencyAsInt(amount))}</Text>} withArrow disabled={currencyAsInt(amount) && withTooltip ? false : true} color={COLORS?.text?.main}>
        <Text onClick={() => withCopy && copy()} sx={{cursor: withCopy ? 'pointer' : '', ...styles}}>₹&nbsp;<span style={{ fontFamily: 'Poppins, sans-serif' }}>{amount ? Intl.NumberFormat('en-IN').format(finalAmount) : 0}</span></Text>
      </Tooltip>
    </MantineProvider>
  );
};

export default Nums;
