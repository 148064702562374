import { Box, Button, Grid, Image, Title } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ImdStyle from "../IMD/Imd.styles";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { ArrowNarrowLeft } from "tabler-icons-react";
import ModalDataViwer from "../../components/SucessfullModal/ModalDataViwer";
import Select from "react-select";
import {
  getLoanDetailsById,
  getApprovedIMDById,
  getAllRemarks,
  getFeeTypesByProductIdforLoan,
} from "../../services/loan.service";
import { getRepaymentType } from "../../services/master.service";
import DashBoardCustomerDetails from "../../components/DashboardCard/dashboard";
import Body from "../../components/Body/Body";
import PageTitle from "../../components/PageTitle/PageTitle";
import ViewRemark from "../../components/Remark/ViewRemark";
import IdBadge from "../../components/CommonComponents/Preview/IdBadge";
import TitleComp from "../../components/CommonComponents/TextComponent/TitleComp";
import { getStageforModules } from "../../services/module.service";
import CreditReloadDetails from "../../components/CommonComponents/CreditReload/CreditReloadDetails";
import { getCreditReloadRequestByCustomerAppId } from "../../services/customer.service";
import NewLoan from "./NewLoan";
import RevolvingLoan from "./RevolvingLoan";
import { OPEN } from "../../constants/status";
import useSystemConfigStore from "../../store/systemConfigStore";
import TitleViewer from "../../components/CommonComponents/Title/TitleViewer";

const LoanCreation = ({ formType }) => {
  const { loanId, customerAppId, createdLoanId } = useParams();
  const systemDate = useSystemConfigStore(store => store.config);
  const [sucessfulmodal, setSucessfulmodal] = useState({});
  const [creditReloadData, setCreditReloadData] = useState({});
  const [loanTypes, setLoanTypes] = useState([]);
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [selectedLoanType, setSelectedLoanType] = useState();

  const navigate = useNavigate();

  // used to get the saved loan data
  const { data: loanData = {} } = useQuery(
    ["loan-detail", createdLoanId],
    () => createdLoanId && getLoanDetailsById(createdLoanId),
    {
      onSuccess: (data) => {
        setSelectedLoanType(data?.loan_type);
      },
      refetchOnWindowFocus: false,
    }
  );

  // used to get the next stage
  const { data: stages = [] } = useQuery(
    ["loan-stage"],
    () => getStageforModules("loan"),
    {
      select: (data) => {
        return {
          current_stage: OPEN,
          next_stage: data[1],
        };
      },
      refetchOnWindowFocus: false,
    }
  );

  // get the approved imd data
  const { data: approvedIMD = [] } = useQuery(
    ["loandata-approved", customerAppId],
    () => customerAppId && getApprovedIMDById(customerAppId),
    {
      refetchOnWindowFocus: false,
    }
  );

  // get the list of fee
  const { data: feeType = [] } = useQuery(
    ["feeType", approvedIMD?.product_id],
    () =>
      approvedIMD?.product_id &&
      getFeeTypesByProductIdforLoan(approvedIMD?.product_id, customerAppId),
    { refetchOnWindowFocus: false }
  );

  // used to get the repayment type
  const { data: repaymentType = [] } = useQuery(
    ["repayment-tenures"],
    () => getRepaymentType(),
    {
      enabled: Boolean(approvedIMD?.repayment_tenure_id),
      select: (data) => {
        return data.filter(
          (item) => item?.id == approvedIMD?.repayment_tenure_id
        )[0];
      },
      refetchOnWindowFocus: false,
    }
  );

  // get the credit reload request data from LOS if revolving loan selected
  const { data: creditReloadRequestData = [] } = useQuery(
    ["credit-reload", customerAppId],
    () =>
      getCreditReloadRequestByCustomerAppId({
        crr_id: loanData?.crr_id,
        customer_application_id: loanData?.crr_id ? null : customerAppId,
      }),
    {
      onSuccess: (data) => {
        setCreditReloadData(
          data?.filter((item) => item?.crr_id == loanData?.crr_id)[0]
        );
      },
      select: (response) => {
        return response.map((item) => ({
          ...item,
          search_key: Object.values(item).join().toLowerCase(),
        }));
      },
      enabled: Boolean(
        ["revolving", "enhancement", "transfer"]?.includes(loanData?.loan_type) || ["revolving", "enhancement", "transfer"]?.includes(selectedLoanType)
      ),
      refetchOnWindowFocus: false,
    }
  );

  // get the remarks by created loan Id
  const { data: reviewTrail = [] } = useQuery(
    ["remarks", createdLoanId],
    () => createdLoanId && getAllRemarks(createdLoanId),
    { refetchOnWindowFocus: false }
  );

  /** this useEffect is used to handle the loan types */
  useEffect(() => {
    setLoanTypes([]);
    if (loanData?.allowed_loan_types) {
      loanData?.allowed_loan_types?.map((item, index) => {
        setLoanTypes((loanTypes) => [
          ...loanTypes,
          {
            label: item == "new" ? "New" : item == 'enhancement' ? 'Enhancement' : item == 'transfer' ? 'Transfer' : "Revolving",
            value: item,
          },
        ]);
        if (item === 'enhancement') {
          setSelectedLoanType(item);
        }
      });
    } else {
      approvedIMD?.loan_types?.map((item, index) => {
        setLoanTypes((loanTypes) => [
          ...loanTypes,
          {
            label: item == "new" ? "New" : item == 'enhancement' ? 'Enhancement' : item == 'transfer' ? 'Transfer' : "Revolving",
            value: item,
          },
        ]);
        if (item === 'enhancement') {
          setSelectedLoanType(item);
        }
      });
    }
  }, [approvedIMD?.loan_types, loanData?.allowed_loan_types]);

  useEffect(() => {
    if (selectedLoanType === 'revolving' && loanData?.crr_id) {
      setCreditReloadData(
        creditReloadRequestData?.filter((item) => item?.crr_id == loanData?.crr_id)[0]
      );
    } else {
      setCreditReloadData({});
    }
  }, [selectedLoanType])

  return (
    <>
      <Body background={false}>
        <PageTitle
          title={approvedIMD?.customer_name}
          id={approvedIMD?.customer_id}
          action={
            ["revolving", "enhancement", "transfer"]?.includes(selectedLoanType) &&
              creditReloadRequestData?.length ? (
              /** display the credit reload request */
              <CreditReloadDetails
                submitCallback={setCreditReloadData}
                data={creditReloadRequestData}
                selectedRowId={creditReloadData?.crr_id}
                defaultSelectedId={loanData?.crr_id}
                type={loanData?.additional_deduction?.find(i => i?.fee_type === 'express_reload_fee') ? 'express' : null}
                mode={"edit"}
              />
            ) : null
          }
        />
      </Body>
      <DashBoardCustomerDetails id={loanId} />
      <Box sx={{ position: "relative" }}>
        <Body>
          {loanData?.prospect_code ? (
            <div style={ImdStyle.flag}>
              <IdBadge
                remarks={loanData?.status_value}
                label={loanData?.prospect_code}
              />
            </div>
          ) : null}
          {approvedIMD?.loan_types?.length ||
            loanData?.allowed_loan_types?.length ? (
            <Grid gutter="xl" pt={"sx"} mt={"sm"}>
              <Grid.Col span={7}>
                <Grid gutter="lg">
                  <Grid.Col span={12}>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "400",
                      }}
                    >
                      <Grid.Col span={5}>
                        <TitleComp label={"Loan Type"} />
                      </Grid.Col>
                      <Grid.Col span={7}>
                        <Select
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                            option: (provided) => ({
                              ...provided,
                              fontSize: "13px",
                              padding: "5px 15px 5px 15px",
                            }),
                            control: (provided) => ({
                              ...provided,
                              fontSize: "13px",
                            }),
                          }}
                          options={loanTypes.length ? loanTypes : []}
                          value={loanTypes?.find(
                            (e) => selectedLoanType === e?.value
                          )}
                          onChange={(e) => setSelectedLoanType(e?.value)}
                        />
                      </Grid.Col>
                    </Grid>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col span={5} mt={"xs"} p={0}>
                {selectedLoanType === 'enhancement' || selectedLoanType === 'new' || selectedLoanType === 'transfer' ? (
                  <Grid.Col span={12}>
                    <TitleViewer title={`Cash Flow ${selectedLoanType === "enhancement" ? "(Enhancement)" : selectedLoanType === 'transfer' ? "(Transfer)" : ""}`} />
                  </Grid.Col>
                ) : selectedLoanType === "revolving" ? (
                  <Grid.Col span={12}>
                    <TitleViewer title={'Revolving'} />
                  </Grid.Col>
                ) : null}
              </Grid.Col>
            </Grid>
          ) : (
            <Grid>
              <Grid.Col span={4}>
                <Image
                  src={"https://imgur.com/fcDgKUG.png"}
                  alt="img"
                  width={"280px"}
                />
              </Grid.Col>
              <Grid.Col span={7}>
                <Title order={6} sx={{ color: "#dd2b0e" }} mt={"130px"}>
                  Limit is not available to proceed further!
                </Title>
                <Button
                  variant="subtle"
                  ml={"70px"}
                  mt={"md"}
                  leftIcon={<ArrowNarrowLeft />}
                  onClick={() => navigate(-1)}
                >
                  Go back
                </Button>
              </Grid.Col>
            </Grid>
          )}
          {selectedLoanType == "new" || selectedLoanType == "enhancement" || selectedLoanType === 'transfer' ? (
            <NewLoan
              formType={formType}
              repayment={repaymentType}
              approvedIMD={approvedIMD}
              enhancement={selectedLoanType === 'enhancement' ? true : false}
              transfer={selectedLoanType === 'transfer' ? true : false}
              loanId={loanId}
              customerAppId={customerAppId}
              createdLoanId={createdLoanId}
              systemDate={systemDate}
              loanData={loanData}
              stages={stages}
              feeType={feeType}
              creditReloadData={creditReloadData}
              setViewRemarks={setRemarksOpen}
              setSucessfulmodal={setSucessfulmodal}
            />
          ) : null}
          {selectedLoanType == "revolving" && (
            <RevolvingLoan
              formType={formType}
              repayment={repaymentType}
              approvedIMD={approvedIMD}
              loanId={loanId}
              customerAppId={customerAppId}
              createdLoanId={createdLoanId}
              systemDate={systemDate}
              loanData={loanData}
              stages={stages}
              feeType={feeType}
              creditReloadData={creditReloadData}
              setViewRemarks={setRemarksOpen}
              setSucessfulmodal={setSucessfulmodal}
            />
          )}
          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={loanData?.code}
            title={sucessfulmodal?.title}
            href={"/tranche"}
            hreftext={"Back to list table"}
          />
        </Body>
        <ViewRemark
          open={remarksOpen}
          onClose={setRemarksOpen}
          remarkTrail={reviewTrail}
        />
      </Box>
    </>
  );
};

export default LoanCreation;
