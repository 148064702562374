import { Box, Paper } from '@mantine/core';
import React from 'react';

const Body = ({ children, background = true }) => {
  return (
    <>
      {background ? (
        <Paper shadow='xs' radius='md' p='xl' mt={20} sx={styles.root}>
          {children}
        </Paper>
      ) : (
        <Box sx={styles.root}>
          {children}
        </Box>
      )}
    </>
  );
};

const styles = {
  root: {
    maxWidth: '1300px',
    margin: 'auto',
  },
};

export default Body;
