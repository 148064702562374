import { getAPICall, patchAPICall, postAPICall } from ".";
import { dateFormat } from '../components/DateFormat/dateFormat';

export const getAllCreatedCustomerReceipts = ({
  status,
  page,
  search,
  records = 10,
}) => {
  return getAPICall(
    `customer-level-receipt?status=${status}&customer_id_name=${search}&page=${page}&records=${records}`,
    { returnObject: true }
  );
};

export const getCustomerDetailsForCustomerReceiptById = (custId) => {
  return getAPICall(`customer-level-receipt/eligible-customers/${custId}`, {
    returnFirstItem: true,
  });
};

export const getCustomerLevelReceiptById = (custReceiptId) => {
  return getAPICall(`customer-level-receipt/${custReceiptId}`, {
    returnFirstItem: true,
  });
};

export const getAllEligibleCustomerLevelReceiptCust = (body) => {
  return postAPICall(`customer-level-receipt/eligible-customers`, { body });
};

export const saveCustomerReceiptAndSendForNextStage = (body) => {
  return postAPICall(`customer-level-receipt/direct_save`, { body });
};

export const getAllDisbursedLoansByCustomerId = ({
  customer_id,
  receipt_date,
  receipt_amount,
  unidentified_receipt_id
}) => {
  let query = `customer/disbursed-loans/${customer_id}?receipt_date=${receipt_date}`;
  if (receipt_amount) query = query + `&customer_level_receipt_amount=${receipt_amount}`;
  if (unidentified_receipt_id) query = query + `&unidentified_receipt_id=${unidentified_receipt_id}`;
  return getAPICall(query);
};

export const updateCustomerReceipt = (custReceiptId, body) => {
  return patchAPICall(`/customer-level-receipt/${custReceiptId}`, { body });
};

export const createCustomerReceipt = (body) => {
  return postAPICall(`/customer-level-receipt`, { body });
};

export const sendCustomerReceiptForNextStage = (custReceiptId, body) => {
  return postAPICall(`/customer-level-receipt/${custReceiptId}/status/change`, {
    body,
  });
};

export const CancelCustomerReceipt = (custReceiptId, body) => {
  return postAPICall(`/customer-level-receipt/${custReceiptId}/cancelled`, {
    body,
  });
};

export const RejectCustomerReceipt = (custReceiptId, body) => {
  return postAPICall(`/customer-level-receipt/${custReceiptId}/rejected`, {
    body,
  });
};

export const PushbackCustomerReceipt = (custReceiptId, body) => {
  return postAPICall(`/customer-level-receipt/${custReceiptId}/pushback`, {
    body,
  });
};

export const checkLoansEligibleForBackDateByCustomerId = (
  custId,
  receipt_date
) => {
  return getAPICall(
    `/check/customer-level-receipt/eligible-loans?customer_id=${custId}&receipt_date=${dateFormat(receipt_date, 'YYYY-MM-DD')}`
  );
};

export const getCustomerReceiptRemarks = (custReceiptId) => {
  return getAPICall(`/customer-level-receipt/${custReceiptId}/remark`);
};

export const getCustomerLevelReceiptLoansAfterApproved = (custReceiptId) => {
  return getAPICall(`/customer-level-receipt/approved/loans/${custReceiptId}`);
};
