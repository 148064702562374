const OPEN = "draft";
const IN_REVIEW = "review";
const IN_APPROVAL = "approval";
const APPROVED = "approved";
const CANCELLED = "cancelled";
const CANCELLATION_REVIEW = "cancel_review";
const CANCELLATION_APPROVAL = "cancel_approval";
const REJECTED = "rejected";
const IN_CANCELLATION = "in_cancellation";
const AUTO_APPROVED = "auto_approved";
const AUTO_CANCELLED = "auto_cancelled";
export {
  OPEN,
  IN_REVIEW,
  IN_APPROVAL,
  APPROVED,
  CANCELLED,
  REJECTED,
  AUTO_APPROVED,
  AUTO_CANCELLED,
  CANCELLATION_REVIEW,
  CANCELLATION_APPROVAL,
  IN_CANCELLATION,
};

/**
 * here the status for all modules are there
 * If any changes are there in status naming then we can change in this modules only
 */
