import { Grid, Table } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Body from '../../components/Body/Body';
import ControllerDatePicker from '../../components/CommonComponents/TextField/ControllerDatePicker';
import ControllerSelect from '../../components/CommonComponents/TextField/ControllerSelect';
import TitleViewer from '../../components/CommonComponents/Title/TitleViewer';
import PageTitle from '../../components/PageTitle/PageTitle';
import { COLORS, STAGE_CHANGE_TEXT } from '../../constants';
import Nums from '../../components/Body/Nums';
import ControllerCurrencyInput
    from '../../components/CommonComponents/TextField/ControllerCurrencyInput';
import ImdStyle from '../IMD/Imd.styles';
import IdBadge from '../../components/CommonComponents/Preview/IdBadge';
import {
    getAllRemarksWaiver,
    getAllReasonsWaiver,
    getWaiverbyId,
    createWaiver,
    updateWaiver,
    sendWaiverForNextStage,
    saveWaiverAndsendforNextStage,
    getLoanDetailsforWaiverbyId
} from '../../services/waiver.service';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Box } from '@mantine/core';
import { useParams } from 'react-router-dom';
import ViewRemark from '../../components/Remark/ViewRemark';
import * as yup from 'yup';
import ModalDataViwer from '../../components/SucessfullModal/ModalDataViwer';
import Remarks from '../../components/CommonComponents/Remarks/Remarks';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import DashBoardCustomerDetails from '../../components/DashboardCard/dashboard';
import { currencyAsFloat } from '../../components/Currency/currencyFormat';
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import ActionButtons
    from '../../components/CommonComponents/ActionButtons/ActionButtons';
import { getStageforModules } from '../../services/module.service';
import useSystemConfigStore from '../../store/systemConfigStore';

const schema = yup.object({
  waiver_amount: yup
    .string()
    .nullable()
    .required('Please Enter waiver amount'),
  waiver_date: yup.string().nullable().required('Please Select Date'),
  waiver_reason: yup.string().nullable().required('Please Select Reason'),
});

const WaiverCreation = ({formType}) => {
  const { custId, loansId, createdwaiverId } = useParams();
  const systemDate = useSystemConfigStore(store => store.config);
  const [status, setStatus] = useState();
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [sucessfulmodal, setSucessfulmodal] = useState({});
  const [remarksLoading, setRemarksLoading] = useState();
  const [label, setLabel] = useState();
  const [open, setOpen] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [loansDetails, setLoansDetails] = useState({});

  const getWaiverDetails = useMutation((loansId, body) => getLoanDetailsforWaiverbyId(loansId, body))

  const { data: waiverDetails = [] } = useQuery(
    ['waiver-id', createdwaiverId],
    () => createdwaiverId && getWaiverbyId(createdwaiverId),
    {
      refetchOnWindowFocus: false,
    }
  )
  
  const { data: stages = [] } = useQuery(
    ['waiver-stage', 'waiver'],
    () => getStageforModules('waiver'),
    {
      select: (data) => {
        return {
          'current_stage': 'draft',
          'next_stage': data[1]
        }
      },
      refetchOnWindowFocus: false
    } 
  );
  
  const handleReviewModal = () => {
    schema.validate(values)
    .then(res => {
      setOpen(true);
      setStatus('review');
      setLabel(stages?.next_stage == 'approval' ? 'Send for Approval' : 'Send for Review');
    })
    .catch(err => {
      displayNotification({message: err?.errors, variant: 'error'})
    })
  }

  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formType == 'view' ? { ...waiverDetails } : { waiver_date: dayjs(new Date()).format(), ...loansDetails },
  });
  
  const values = watch()

  useEffect(() => {
    const body = {
      receipt_date: dateFormat(values?.waiver_date, 'YYYY-MM-DD'),
    }
    if(systemDate?.current_system_date) {
      console.log(systemDate?.current_system_date);
      getWaiverDetails.mutate({loansId, body}, {
        onSuccess: msg => {
          setLoansDetails(msg[0]);
        },
        onError: e => {
          displayNotification({ message: e?.message || e, variant: 'error' })
        }
      })
    }
  }, [values?.waiver_date])
  
  useEffect(() => {
    if (Object.keys(waiverDetails)?.length) {
      reset(waiverDetails);
    }
  }, [waiverDetails]);

  useEffect(() => {
    if (Object.keys(systemDate).length && !formType) {
      setValue('waiver_date', systemDate?.current_system_date);
    }
  }, [systemDate]);

  const { data: reviewTrail = [] } = useQuery(
    ['remarks', createdwaiverId],
    () => createdwaiverId && getAllRemarksWaiver(createdwaiverId),
    { refetchOnWindowFocus: false }
  );

  const { data: reasons = [] } = useQuery(
    ['reasons'],
    () => getAllReasonsWaiver(),
    { 
      refetchOnWindowFocus: false, 
    }
  )

  const calculateBackDate = () => {
    const backDate = new Date(systemDate?.current_system_date)
    return backDate.setDate(backDate.getDate(systemDate?.current_system_date) - loansDetails?.backdate);    
  }

  const onSubmit = () => {
    const body = {
      loan_id: parseInt(loansId),
      waiver_amount: currencyAsFloat(values?.waiver_amount) || 0,
      waiver_charges: currencyAsFloat(values?.waiver_charges) || 0,
      waiver_interest: currencyAsFloat(values?.waiver_interest) || 0,
      waiver_spread: currencyAsFloat(values?.waiver_spread) || 0,
      waiver_penal_interest_amount: currencyAsFloat(values?.waiver_penal_interest_amount) || 0,
      waiver_interest_amount_during_penalty: currencyAsFloat(values?.waiver_interest_amount_during_penalty) || 0,
      waiver_spread_amount_during_penalty: currencyAsFloat(values?.waiver_spread_amount_during_penalty) || 0,
      waiver_addon_spread_amount_during_penalty: currencyAsFloat(values?.waiver_addon_spread_amount_during_penalty) || 0,
      waiver_addon_spread: currencyAsFloat(values?.waiver_addon_spread) || 0,
      waiver_reason: values?.waiver_reason,
      waiver_principle: 0,
      waiver_date: dateFormat(values?.waiver_date, 'YYYY-MM-DD'),
    }
    
    setButtonLoader({ save: true });
    if(!createdwaiverId) {
      createWaiver(body)
        .then((res) => {
          setSucessfulmodal({open: true, title: STAGE_CHANGE_TEXT?.save});
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({
              id: 'pmt-creation',
              message: e?.message || e,
              variant: 'error',
            });
          }
        })
        .finally(() => {
          setButtonLoader({ save: false });
        })
    } else {
      updateWaiver(createdwaiverId, body)
        .then((res) => {
          setSucessfulmodal({open: true, title: STAGE_CHANGE_TEXT?.update});
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({
              id: 'pmt-creation',
              message: e?.message || e,
              variant: 'error',
            });
          }
        })
        .finally(() => {
          setButtonLoader({ save: false });
        })
    }
  }

  const onsendForReview = (remarks) => {
    const body = {
      remark_type: 'maker',
      remarks: remarks,
    };
    const saveBoby = {        
      loan_id: parseInt(loansId),
      waiver_amount: currencyAsFloat(values?.waiver_amount) || 0,
      waiver_charges: currencyAsFloat(values?.waiver_charges) || 0,
      waiver_interest: currencyAsFloat(values?.waiver_interest) || 0,
      waiver_spread: currencyAsFloat(values?.waiver_spread) || 0,
      waiver_penal_interest_amount: currencyAsFloat(values?.waiver_penal_interest_amount) || 0,
      waiver_interest_amount_during_penalty: currencyAsFloat(values?.waiver_interest_amount_during_penalty) || 0,
      waiver_spread_amount_during_penalty: currencyAsFloat(values?.waiver_spread_amount_during_penalty) || 0,
      waiver_addon_spread_amount_during_penalty: currencyAsFloat(values?.waiver_addon_spread_amount_during_penalty) || 0,
      waiver_addon_spread: currencyAsFloat(values?.waiver_addon_spread) || 0,
      waiver_reason: values?.waiver_reason,
      waiver_principle: 0,
      waiver_date: dateFormat(values?.waiver_date, 'YYYY-MM-DD'),
      ...body
    };

    setRemarksLoading(true);
    if (!createdwaiverId) {
      saveWaiverAndsendforNextStage(saveBoby)
        .then((res) => {
          setSucessfulmodal({open: true, title: stages?.next_stage == 'approval' ? STAGE_CHANGE_TEXT?.approval : STAGE_CHANGE_TEXT?.review});
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' });
          }
        })
        .finally(() => {
          setRemarksLoading(false);
          setOpen(false);
        })
    } else {
      updateWaiver(createdwaiverId, saveBoby)
        .then((res) => {
          sendWaiverForNextStage(createdwaiverId, body)
            .then((res) => {
              setSucessfulmodal({open: true, title: stages?.next_stage == 'approval' ? STAGE_CHANGE_TEXT?.approval : STAGE_CHANGE_TEXT?.review});
            })
            .catch((e) => {
              if (e?.RequestValidationError) {
                Object.keys(e?.RequestValidationError).forEach((item, index) => {
                  setError(item, { message: e?.RequestValidationError[item] });
                });
              } else {
                displayNotification({ message: e?.message || e, variant: 'error' });
              }
            });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' });
          }
        })
        .finally(() => {
          setRemarksLoading(false);
          setOpen(false);
        })
    }
  };

  /* this function is used to display error msg in tabel */
  const handleErrorMsg = (val1, val2) => {    
    if(currencyAsFloat(val1) > val2) {
      return 'Please enter valid amount.'
    }
  }

  /** this function is used to calculate the remaining value in tabel */
  const remainingValueCalculation = (val1, val2) => {
    if(val2) {
      if(val1 - currencyAsFloat(val2) < 0){
        return 0
      } else {
        return val1 - currencyAsFloat(val2)
      }
    } else {
      return 0
    }
  }

  return (
    <>
    <Body background={false}>
      <PageTitle title={loansDetails?.customer_name} id={loansDetails?.customer_id} />        
    </Body>
    <DashBoardCustomerDetails id={custId} loansId={loansDetails?.loan_id} codeName={'Waiver Code'} code={waiverDetails?.code} />
    <Box  sx={{ position: 'relative' }}>
      <Body>
        {waiverDetails?.prospect_code || loansDetails?.prospect_code ? (
          <div style={ImdStyle.flag}>
            <IdBadge
              label={waiverDetails?.prospect_code || loansDetails?.prospect_code}
            />
          </div>
        ) : null }
        <Grid gutter='xs' mt={'sm'}>
          <Grid.Col span={4} mt={'xs'}>          
            <Grid.Col>
              <ControllerDatePicker
                name='waiver_date'
                label='Waiver Date'
                maxDate={new Date(systemDate?.current_system_date)}
                minDate={new Date(calculateBackDate())}
                control={control}
                errors={errors?.waiver_date?.message}
              />
            </Grid.Col>
            <Grid.Col>
              <ControllerCurrencyInput
                name='waiver_amount'
                label='Waiver Amount'
                value={values?.waiver_amount}
                amountInWords
                control={control}
                errors={errors?.waiver_amount?.message}
              />
            </Grid.Col>
            <Grid.Col>
              <ControllerSelect  
                name='waiver_reason'
                label='Reason'
                control={control}
                data={reasons}
                errors={errors?.waiver_reason?.message}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={8}>
            <Grid.Col>
              <TitleViewer title='Waiver Amount' />
            </Grid.Col>
            <Grid.Col>
              <Table verticalSpacing='xs' fontSize='xs'>
                <thead style={{ backgroundColor: COLORS.primary.light1 }}>
                  <tr>
                    <th>Pay Head</th>
                    <th>Due Amount</th>
                    <th>Waiver Amount</th>
                    <th>Remaining Due</th>
                  </tr>
                </thead>
                <tbody>         

                  <tr>
                    <td>Principal</td>
                    <td>
                      <Nums value={loansDetails?.outstanding_principle ||waiverDetails?.outstanding_principle} />
                    </td>
                    <td>
                      {'NA'}
                    </td>                                              
                    <td>
                      <Nums value=
                        {loansDetails?.outstanding_principle ||waiverDetails?.remaining_principle}
                      />
                    </td>
                  </tr>

                  { loansDetails?.outstanding_interest || waiverDetails?.outstanding_interest ?
                  <tr>
                    <td>Interest ({loansDetails?.interest_rate || waiverDetails?.interest_rate}%)</td>
                    <td>
                      <Nums value={loansDetails?.outstanding_interest || waiverDetails?.outstanding_interest} />
                    </td>
                    <td>                      
                      <ControllerCurrencyInput
                        name={`waiver_interest`}
                        control={control}
                        defaultValues={waiverDetails?.waiver_interest}
                        displayLabel={false}
                        errors={handleErrorMsg(values?.waiver_interest, loansDetails?.outstanding_interest || waiverDetails?.outstanding_interest)}
                        size='xs'             
                        styles = {{padding: '0', minHeight: '0'}}
                        sx={{ width: 150 }}
                      />
                    </td>                                              
                    <td>
                      <Nums value=
                        {remainingValueCalculation(loansDetails?.outstanding_interest, values?.waiver_interest)}
                      />
                    </td>
                  </tr> : null
                  }

                  { loansDetails?.outstanding_spread || waiverDetails?.outstanding_spread ?
                    <tr>
                      <td>Spread ({loansDetails?.spread_rate || waiverDetails?.spread_rate}%)</td>
                      <td>
                        <Nums value={loansDetails?.outstanding_spread || waiverDetails?.outstanding_spread} />
                      </td>
                      <td>                      
                        <ControllerCurrencyInput
                          name={`waiver_spread`}
                          control={control}
                          defaultValues={waiverDetails?.waiver_spread}
                          displayLabel={false}
                          errors={handleErrorMsg(values?.waiver_spread, loansDetails?.outstanding_spread || waiverDetails?.outstanding_spread)}
                          size='xs'             
                          styles = {{padding: '0', minHeight: '0'}}
                          sx={{ width: 150 }}
                        />
                      </td>                                              
                      <td>
                        <Nums value=
                          {remainingValueCalculation(loansDetails?.outstanding_spread, values?.waiver_spread)}
                        />
                      </td>
                    </tr> : null
                  }

                  { loansDetails?.outstanding_addon_spread || waiverDetails?.outstanding_addon_spread ?
                    <tr>
                      <td>Addon Spread ({loansDetails?.addon_spread_rate || waiverDetails?.addon_spread_rate}%)</td>
                      <td>
                        <Nums value={loansDetails?.outstanding_addon_spread || waiverDetails?.outstanding_addon_spread} />
                      </td>
                      <td>                      
                        <ControllerCurrencyInput
                          name={`waiver_addon_spread`}
                          control={control}
                          defaultValues={waiverDetails?.waiver_addon_spread}
                          displayLabel={false}
                          errors={handleErrorMsg(values?.waiver_addon_spread, loansDetails?.outstanding_addon_spread || waiverDetails?.outstanding_addon_spread)}
                          size='xs'             
                          styles = {{padding: '0', minHeight: '0'}}
                          sx={{ width: 150 }}
                        />
                      </td>                                              
                      <td>
                        <Nums value=
                          {remainingValueCalculation(loansDetails?.outstanding_addon_spread, values?.waiver_addon_spread)}
                        />
                      </td>
                    </tr> : null
                  }

                  {/** penal for interest */}
                  { loansDetails?.outstanding_interest_amount_during_penalty || waiverDetails?.outstanding_interest_amount_during_penalty ?
                    <tr>
                      <td>Interest F1 ({loansDetails?.interest_rate || waiverDetails?.interest_rate}%)</td>
                      <td>
                        <Nums value={loansDetails?.outstanding_interest_amount_during_penalty || waiverDetails?.outstanding_interest_amount_during_penalty} />
                      </td>
                      <td>
                        <ControllerCurrencyInput
                          name={`waiver_interest_amount_during_penalty`}
                          control={control}
                          defaultValues={values?.waiver_interest_amount_during_penalty}
                          displayLabel={false}
                          errors={handleErrorMsg(values?.waiver_interest_amount_during_penalty, loansDetails?.outstanding_interest_amount_during_penalty || waiverDetails?.outstanding_interest_amount_during_penalty)}
                          size='xs'             
                          styles = {{padding: '0', minHeight: '0'}}
                          sx={{ width: 150 }}
                        />
                      </td>                                              
                      <td>
                        <Nums value=
                          {remainingValueCalculation(loansDetails?.outstanding_interest_amount_during_penalty, values?.waiver_interest_amount_during_penalty)}
                        />
                      </td>
                    </tr> : null
                  }

                  {/** penal for spread */}
                  { loansDetails?.outstanding_spread_amount_during_penalty || waiverDetails?.outstanding_spread_amount_during_penalty ?
                    <tr>
                      <td>Spread F1 ({loansDetails?.spread_rate || waiverDetails?.spread_rate}%)</td>
                      <td>
                        <Nums value={loansDetails?.outstanding_spread_amount_during_penalty || waiverDetails?.outstanding_spread_amount_during_penalty} />
                      </td>
                      <td>
                        <ControllerCurrencyInput
                          name={`waiver_spread_amount_during_penalty`}
                          control={control}
                          defaultValues={values?.waiver_spread_amount_during_penalty}
                          displayLabel={false}
                          errors={handleErrorMsg(values?.waiver_spread_amount_during_penalty, loansDetails?.outstanding_spread_amount_during_penalty || waiverDetails?.outstanding_spread_amount_during_penalty)}
                          size='xs'             
                          styles = {{padding: '0', minHeight: '0'}}
                          sx={{ width: 150 }}
                        />
                      </td>                                              
                      <td>
                        <Nums value=
                          {remainingValueCalculation(loansDetails?.outstanding_spread_amount_during_penalty, values?.waiver_spread_amount_during_penalty)}
                        />
                      </td>
                    </tr> : null
                  }

                  {/** penal for addon_spread */}
                  { loansDetails?.outstanding_addon_spread_amount_during_penalty || waiverDetails?.outstanding_addon_spread_amount_during_penalty ?
                    <tr>
                      <td>Addon Spread F1 ({loansDetails?.addon_spread_rate || waiverDetails?.addon_spread_rate}%)</td>
                      <td>
                        <Nums value={loansDetails?.outstanding_addon_spread_amount_during_penalty || waiverDetails?.outstanding_addon_spread_amount_during_penalty} />
                      </td>
                      <td>
                        <ControllerCurrencyInput
                          name={`waiver_addon_spread_amount_during_penalty`}
                          control={control}
                          defaultValues={values?.waiver_addon_spread_amount_during_penalty}
                          displayLabel={false}
                          errors={handleErrorMsg(values?.waiver_addon_spread_amount_during_penalty, loansDetails?.outstanding_addon_spread_amount_during_penalty || waiverDetails?.outstanding_addon_spread_amount_during_penalty)}
                          size='xs'             
                          styles = {{padding: '0', minHeight: '0'}}
                          sx={{ width: 150 }}
                        />
                      </td>                                              
                      <td>
                        <Nums value=
                          {remainingValueCalculation(loansDetails?.outstanding_addon_spread_amount_during_penalty, values?.waiver_addon_spread_amount_during_penalty)}
                        />
                      </td>
                    </tr> : null
                  }

                  { loansDetails?.outstanding_penal_interest_amount || waiverDetails?.outstanding_penal_interest_amount ?
                    <tr>
                      <td>Penal F2 ({loansDetails?.penal_rate || waiverDetails?.penal_rate}%)</td>
                      <td>
                        <Nums value={loansDetails?.outstanding_penal_interest_amount || waiverDetails?.outstanding_penal_interest_amount} />
                      </td>
                      <td>
                        <ControllerCurrencyInput
                          name={`waiver_penal_interest_amount`}
                          control={control}
                          defaultValues={values?.waiver_penal_interest_amount}
                          displayLabel={false}
                          errors={handleErrorMsg(values?.waiver_penal_interest_amount, loansDetails?.outstanding_penal_interest_amount || waiverDetails?.outstanding_penal_interest_amount)}
                          size='xs'             
                          styles = {{padding: '0', minHeight: '0'}}
                          sx={{ width: 150 }}
                        />
                      </td>                                              
                      <td>
                        <Nums value=
                          {remainingValueCalculation(loansDetails?.outstanding_penal_interest_amount, values?.waiver_penal_interest_amount)}
                        />
                      </td>
                    </tr> : null
                  }

                  { loansDetails?.outstanding_charges || waiverDetails?.outstanding_charges ?
                    <tr>
                      <td>Fee</td>
                      <td>
                        <Nums value={loansDetails?.outstanding_charges || waiverDetails?.outstanding_charges} />
                      </td>
                      <td>
                        <ControllerCurrencyInput
                          name={`waiver_charges`}
                          control={control}
                          defaultValues={values?.waiver_charges}
                          displayLabel={false}
                          errors={handleErrorMsg(values?.waiver_charges, loansDetails?.outstanding_charges || waiverDetails?.outstanding_charges)}
                          size='xs'             
                          styles = {{padding: '0', minHeight: '0'}}
                          sx={{ width: 150 }}
                        />
                      </td>                                              
                      <td>
                        <Nums value=
                          {remainingValueCalculation(loansDetails?.outstanding_charges, values?.waiver_charges)}
                        />
                      </td>
                    </tr> : null
                  }

                </tbody>
              </Table>
            </Grid.Col>
          </Grid.Col>
        </Grid>
          {/** This Action buttons component is used to display the buttons in the flow */}
        <ActionButtons
          status={waiverDetails?.status_value ? waiverDetails?.status_value : 'draft'}
          base={'creation'}
          nextStage={stages?.next_stage}
          sendForReviewCB={handleReviewModal} 
          sendForApprovalCB={handleReviewModal} 
          saveCB={handleSubmit(onSubmit)} 
          loading={{ save: buttonLoader?.save }} 
          remarksCB={() => setRemarksOpen(true)}
          remarks={reviewTrail} 
          currentStatus={values?.status_value}
          moduleApplicationId={values?.id}
          moduleName={'waiver'}
          isReady={Boolean(stages?.next_stage)}/>
        <ModalDataViwer
          opened={sucessfulmodal?.open}
          label={waiverDetails?.code}
          title={sucessfulmodal?.title}
          href={'/waiver'}
          hreftext={'Back to list table'}
        />
        <Remarks
          open={open}
          setOpen={setOpen}
          type={stages?.next_stage}
          callback={onsendForReview}
          remarksTitle={stages?.next_stage == 'review' ? 'Review Remarks' : 'Approval Remarks'}
          check={status}
          label={label}
          loading={remarksLoading} 
          currentStatus={values?.status_value}
          moduleApplicationId={values?.id}
          moduleName={'Waiver'}
        />
      </Body>
      
      <ViewRemark open={remarksOpen} onClose={setRemarksOpen} remarkTrail={reviewTrail} />

    </Box>
    </>
  );
};

export default WaiverCreation;
