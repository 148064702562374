import { Box, Title, Divider, Grid, ActionIcon, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import ControllerSelect from "../../components/CommonComponents/TextField/ControllerSelect";
import ControllerCurrencyInput from "../../components/CommonComponents/TextField/ControllerCurrencyInput";
import ControllerTextField from "../../components/CommonComponents/TextField/ControllerTextField";
import { getBusinessBanks } from "../../services/banks.service";
import { currencyAsInt } from "../../components/Currency/currencyFormat";
import { useQuery } from "@tanstack/react-query";
import { useListState } from "@mantine/hooks";
import Nums from "../../components/Body/Nums";
import { ViewFeeDetailsWithGST } from "../../components/FeePopover/FeeCalculationPopover";
import { Plus, Trash } from "tabler-icons-react";
import { COLORS } from "../../constants";
import { ViewData } from "../../components/CommonComponents/Preview/FilePreview";
import Select from 'react-select';
import ReloadFeePopover from "../../components/FeePopover/ReloadFeePopover";

/* Used to add additional deduction fee
 * This will be deducted in net disb amount
 */

const AdditionalDeduction = ({
  values,
  control,
  approvedIMD,
  customerAppId,
  createdLoanId,
  loanData,
  advanceInterest,
  calculatedFee,
  setCalculateFee,
  additionalFee = [],
  deductionFieldArray,
  feeType = [],
  loanType,
  ...props
}) => {
  const [calculatedFeeType, setCalculateFeeType] = useState({
    fee_type: "fee_base_is_approved_amount",
  });

  const [additionalDeductionFeeTypes, additionalDeductionFeeTypeshandlers] =
    useListState([
      {
        label: "Fee",
        value: "fee",
        id: 1,
        isDisabled: false,
      },
      /** here id is used to handle in UI level */
      {
        label: "Control Account",
        value: "control_account",
        id: 2,
        isDisabled: false,
      },
      ...additionalFee,
    ]);

  /** passing the type as "ca" to get control account options */
  const { data: controlAccountTypes = [] } = useQuery(
    ["control-account"],
    () => getBusinessBanks({ category: "ca" }),
    { refetchOnWindowFocus: false }
  );

  /** used to handle the advance interest amount */
  const handlenonDeduction = (payHead, val) => {
    deductionFieldArray?.fields?.forEach((item, i) => {
      if (item?.fee_type == payHead) {
        deductionFieldArray?.update(i, { fee_type: payHead, fee_value: val });
      }
    });
  };

  /** this useEffect is used to add the advance interest for new loan */
  useEffect(() => {
    /** used to calculate the interest */
    let advanceInterestArray = values?.additional_deduction?.filter(
      (item) => item?.fee_type == "advance_interest"
    );
    if (values?.loan_type === "new") {
      props?.setAdvanceInterest(
        Math.round(
          ((currencyAsInt(values?.loan_amount) *
            ((approvedIMD?.interest_rate +
              approvedIMD?.spread_rate +
              approvedIMD?.addon_spread_rate) /
              100)) /
            approvedIMD?.interest_calc_period) *
          approvedIMD?.no_of_days
        ) || 0
      );
      /** updating the value of advanceInterest when the loan amount get changed */
      if (
        advanceInterestArray?.length &&
        advanceInterestArray[0]?.fee_value != advanceInterest
      ) {
        handlenonDeduction("advance_interest", advanceInterest);
      }
    }
  }, [values?.loan_amount]);

  // used to disable the options[fee, advance_interest] if selected
  useEffect(() => {
    const updateAdditionalDeductionList = (index, visible) => {
      additionalDeductionFeeTypeshandlers.setItemProp(
        index,
        "isDisabled",
        visible
      );
    };
    additionalDeductionFeeTypes?.map((item, index) => {
      if (item?.value == "advance_interest") {
        if (
          values?.additional_deduction?.filter(
            (e) => e?.fee_type === "advance_interest"
          )?.length
        ) {
          updateAdditionalDeductionList(index, true);
        } else if (
          !values?.additional_deduction?.filter(
            (e) => e?.fee_type === "advance_interest"
          )?.length
        ) {
          updateAdditionalDeductionList(index, false);
        }
      }
      if (item?.value === "fee") {
        if (
          values?.additional_deduction?.filter((e) => e?.fee_type === "fee")
            ?.length
        ) {
          updateAdditionalDeductionList(index, true);
        } else if (
          !values?.additional_deduction?.filter((e) => e?.fee_type === "fee")
            ?.length
        ) {
          updateAdditionalDeductionList(index, false);
        }
      }
    });
  }, [deductionFieldArray?.fields]);

  return (
    <Box sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
      <Divider
        size="md"
        labelPosition="right"
        variant="dashed"
        sx={{ paddingBottom: "20px" }}
        label={
          <Select
            placeholder={"+ Add Deduction"}
            clearValue={true}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999, }),
              option: (provided) => ({ ...provided, fontSize: '13px', padding: '5px 15px 5px 15px' }),
              control: (provided) => ({ ...provided, fontSize: '13px', border: 'none' })
            }}
            value={null}
            options={additionalDeductionFeeTypes}
            onChange={(val) => {
              deductionFieldArray?.append({
                fee_type: val.value,
              })
            }}
          />
        }
      />
      {deductionFieldArray?.fields?.map((item, index) => {
        return (
          <>
            <Grid key={`${item?.id}-${item?.fee_type}`}>
              {!["advance_interest", "express_reload_fee"]?.includes(values.additional_deduction[index].fee_type) ?
                <>
                  <Grid.Col span={3}>
                    {/** if new loan then it will display the fee typeNewLoan types or it will display  the fee type types*/}
                    <Title
                      order={6}
                      sx={{
                        textTransform: "capitalize",
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {item?.fee_type?.replace("_", " ")}
                    </Title>
                  </Grid.Col>
                  {["control_account", "fee"]?.includes(
                    values.additional_deduction[index].fee_type
                  ) && (
                      <>
                        <Grid.Col span={4} key={`${item?.fee_type}`}>
                          <ControllerSelect
                            name={`additional_deduction[${index}].fee_name`}
                            control={control}
                            menuPlacement={"top"}
                            data={
                              values.additional_deduction[index].fee_type == "fee"
                                ? feeType
                                : controlAccountTypes
                            }
                            displayLabel={false}
                            spanSize={12}
                          />
                        </Grid.Col>
                        {values.additional_deduction[index].fee_type ==
                          "control_account" ? (
                          <Grid.Col span={4}>
                            <ControllerCurrencyInput
                              placeholder="Fee"
                              name={`additional_deduction[${index}].fee_value`}
                              displayLabel={false}
                              control={control}
                              spanSize={12}
                            />
                          </Grid.Col>
                        ) : (
                          <Grid.Col span={4}>
                            <Text
                              size={'sm'}
                              sx={{
                                color: COLORS.primary.dark,
                                fontWeight: 600,
                                marginTop: "-9px",
                                padding: "5px 10px 5px",
                                borderRadius: "5px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              {
                                <Nums value={calculatedFee?.total_processing_fee} />
                              }
                              <ViewFeeDetailsWithGST
                                value={calculatedFeeType}
                                setValue={setCalculateFeeType}
                                calculatedFee={calculatedFee}
                                setCalculateFee={setCalculateFee}
                                customerAppId={customerAppId}
                                createdLoanId={createdLoanId}
                                loanAmount={values?.loan_amount}
                                approvedAmount={approvedIMD?.approved_amount}
                                loanType={loanType}
                                currentData={
                                  values.additional_deduction[index].fee_name
                                }
                                selectedData={
                                  feeType?.find((e) => values.additional_deduction[index].fee_name == e?.value) || {}
                                }
                                type={
                                  createdLoanId &&
                                  values.additional_deduction[index]
                                    .fee_collection_type
                                }
                                additionalDeduction={values?.additional_deduction}
                              />
                            </Text>
                          </Grid.Col>
                        )}
                      </>
                    )}
                  {values.additional_deduction[index].fee_type == "others" && (
                    <>
                      <Grid.Col span={4}>
                        <ControllerTextField
                          name={`additional_deduction[${index}].fee_name`}
                          control={control}
                          displayLabel={false}
                          spanSize={12}
                          placeholder="Fee name"
                        />
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <ControllerCurrencyInput
                          placeholder="Fee"
                          name={`additional_deduction[${index}].fee_value`}
                          displayLabel={false}
                          control={control}
                          spanSize={12}
                        />
                      </Grid.Col>
                    </>
                  )}
                  <Grid.Col span={1}>
                    <ActionIcon
                      onClick={() => {
                        values.additional_deduction[index].fee_type == "fee" &&
                          setCalculateFee({}) &&
                          setCalculateFeeType({ fee_type: "approved" });
                        deductionFieldArray.remove(index);
                      }}
                    >
                      <Trash size={16} color="red" />
                    </ActionIcon>
                  </Grid.Col>
                </>
                :
                (values?.additional_deduction[index]?.fee_type === 'advance_interest' ?
                  <Grid.Col span={12}>
                    <ViewData
                      title={"Advance Interest"}
                      value={advanceInterest}
                    />
                  </Grid.Col>
                  : (values.additional_deduction[index].fee_type == "express_reload_fee" && (
                    <>
                      <Grid.Col span={8}>
                        <Title
                          order={6}
                          sx={{
                            textTransform: "capitalize",
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {item?.fee_name?.replace("_", " ")}
                        </Title>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <Text
                          size={'sm'}
                          sx={{
                            color: COLORS.primary.dark,
                            fontWeight: 600,
                            marginTop: "-9px",
                            padding: "5px 10px 5px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {
                            <Nums value={item?.total_amount} />
                          }
                          <ReloadFeePopover
                            value={values.additional_deduction[index]}
                          />
                        </Text>
                      </Grid.Col>
                    </>
                  ))
                )
              }
            </Grid>
          </>
        );
      })}
    </Box>
  );
};

export default AdditionalDeduction;
