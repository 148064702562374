import { ActionIcon, Autocomplete, Box, FocusTrap, Overlay } from '@mantine/core'
import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion';
import { COLORS } from '../../constants';
import { getAllCustomersData } from '../../services/customer.service';
import { Search } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';

const STYLES = {
    modalStyles: {
        position: 'fixed',
        top: 'calc(10vh + 20px)',
        width: '100%',
        margin: '0 auto',
        zIndex: 300,
        padding: '5vh 5vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}

const CustomerSearchModal = ({ onClose }) => {

    const navigate = useNavigate();
    const ref = useRef();
    const [searchedValue, setSearchedValue] = useState("");

    /**
     * Handles the click event for closing a modal.
     *
     * @param {Object} event - The event object.
     */
    const handleClickEventForClose = useCallback((event) => {
        if (event.key === "Escape") {
            onClose();
        } else if (ref.current && !ref.current.contains(event.target)) {
            onClose();
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", handleClickEventForClose, false);
        document.addEventListener("click", handleClickEventForClose, true);
        return () => {
            document.removeEventListener("keydown", handleClickEventForClose, false);
            document.removeEventListener("click", handleClickEventForClose, true);
        };
    }, [handleClickEventForClose]);

    const searchedResult = useQuery(
        ["search-data", searchedValue],
        /*
         * sending the signal to cancel the per pending api call
         * signal will contain the abort signal
         */
        ({ signal }) => getAllCustomersData({ search: searchedValue, page: 1, signal }),
        {
            /** this api call will call when the searched value length is min of 2 */
            enabled: Boolean(searchedValue?.length > 2),
            select: (data) => {
                const result = data?.data?.map((item) => ({
                    label: `${item.customer_name} (${item.customer_id})`,
                    value: item.id,
                    ...item,
                }));
                return result || [];
            },
            refetchOnWindowFocus: false,
        }
    );

    /**
     * Handles the selection of a value and navigates to the corresponding borrower page.
     *
     * @param {object} val - The value that was selected.
     */
    const handleOnSelect = (val) => {
        navigate(`/borrower/${val?.customer_application_id}/${val?.customer_id}`)
        onClose();
    };

    return (
        <>
            <Overlay
                component={motion.div}
                opacity={0.5}
                color={COLORS.black}
                sx={{ position: 'fixed' }}
                exit={{ opacity: 0 }}
            />
            <Box
                p={'xl'}
                sx={STYLES.modalStyles}
                component={motion.div}
                initial={{ opacity: 0, scale: 0.5, top: 'calc(10vh + 0px)', }}
                animate={{ opacity: 1, scale: 1, top: 'calc(10vh + 0px)', }}
                transition={{ type: "spring", damping: 10 }}
            >
                <Box
                    sx={{ width: '50%' }}
                    ref={ref}
                    role="dialog"
                    tabIndex={-1}
                    aria-modal
                    radius={'md'}
                    shadow="xs"
                    component={motion.div}
                >
                    <FocusTrap active={true}>
                        <Autocomplete
                            value={searchedValue}
                            size='md'
                            onChange={(value) => {
                                setSearchedValue(value);
                            }}
                            /** this prop will trigger when the user select the option in drop down */
                            onItemSubmit={(value) => {
                                handleOnSelect(value);
                            }}
                            styles={{
                                item: {
                                    fontSize: '14px',
                                },                           
                            }}
                            icon={<ActionIcon loading={searchedResult?.isFetching}><Search size={20} color='gray' /></ActionIcon>}
                            data={searchedResult?.data || []}
                            placeholder="Search by customer Id / Name"
                            filter={(value, item) =>
                                item?.value?.includes(value?.trim()) ||
                                item?.label?.toLowerCase()?.includes(value?.toLowerCase()?.trim())
                            }
                        />
                    </FocusTrap>
                </Box>
            </Box>
        </>
    )
}

export default CustomerSearchModal
