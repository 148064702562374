import { Box, Button, Divider } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Plus } from 'tabler-icons-react';
import ReactTable from '../../../../components/CommonComponents/Table/ReactTable';
import { getAllProducts, getRepaymentType } from '../../../../services/master.service';
import Product from './Product';
import { DrawerModal } from '../../../../components/CommonComponents/Drawer/DrawerModal';

const ProductTable = ({ opened, onClose }) => {
  // ReactQuery Api calls
  const { data: products = [], isLoading } = useQuery(['products'], () => getAllProducts(), {
    refetchOnWindowFocus: false,
  });
  const columnHelper = createColumnHelper();

  const { data: repaymentType = [] } = useQuery(
    'repayment-tenures',
    () => getRepaymentType(),
    { refetchOnWindowFocus: false }
  );

  // React States
  const [addProduct, addProductHandler] = useDisclosure(false);
  const [productFormData, setProductFormData] = useState();

  // Other Data
  const columnData = [
    columnHelper.accessor('code', {
      header: 'Id',
      cell: (value) => (
        <span
          style={{ color: '#0063FF', cursor: 'pointer' }}
          onClick={() => {
            setProductFormData(value.row.original);
            addProductHandler.open();
          }}
        >
          {value.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor('name', {
      header: 'Name',
      cell: (value) => (
        <span
          style={{ color: '#0063FF', cursor: 'pointer' }}
          onClick={() => {
            setProductFormData(value.row.original);
            addProductHandler.open();
          }}
        >
          {value.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor('interest_rate', {
      header: 'Interest Rate',
      cell: (value) => value.getValue() + ' %',
    }),
    columnHelper.accessor('penal_rate', {
      header: 'Penal Rate',
      cell: (value) => value.getValue() + ' %',
    }),
    columnHelper.accessor('repayment_tenure_id', {
      header: 'Repayment Type',
      cell: (value) => {
        return `${value.row.original?.tenure_name} (${value.row.original?.no_of_days} Days)`
      }
    }),
  ];

  // Functions
  const handleClose = () => {
    addProductHandler.close();
    setProductFormData();
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Button
          leftIcon={<Plus size={16} />}
          onClick={() => addProductHandler.open()}
          variant='subtle'
        >
          Add Products
        </Button>
      </Box>
      <ReactTable
        title='Products'
        rowData={products}
        columnData={columnData}
        loading={isLoading}
      />
      <DrawerModal
        open={addProduct}
        onClose={() => {
          handleClose();
        }}
        position='right'
        padding='xl'
        title={productFormData?.name ? `${productFormData?.name} [${productFormData?.code}]` : 'Add New Product'}
        overlayProps={{ blur: 2 }}
        size='50%'
      >
        <Divider />
        <Product
          formData={productFormData}
          id={productFormData?.id}
          onClose={() => handleClose()}
        />
      </DrawerModal>
    </>
  );
};

export default ProductTable;
