const STAGE_CHANGE_TEXT = {
    save: 'Saved',
    update: 'Updated Successfully',
    review: 'Submitted for Review',
    approval: 'Submitted for Approval',
    approved: 'Approved Successful',
    reject: 'Rejected',
    pushback: 'Pushed back for Changes',
    cancel: 'Cancelled',
    reviewCancel: 'Submitted for Cancellation',
    approveCancel: 'Submitted for Cancellation',
}

const PDF_TEXT_MESSAGE = {
    imd: 'Invoice Generated Successfully',
    disbursement: 'Disbursement MEMO Generated Successfully',
    receipt: 'PDF Generated Successfully',
    invoice: 'Invoice Generated Successfully',
    report: 'Report Downloaded Successfully',
}

export { STAGE_CHANGE_TEXT, PDF_TEXT_MESSAGE }