import { getAPICall } from ".";

/** entity will contain entities id [array]
 * type will contain "CA" to get control account
 */
export const getBusinessBanks = ({ entity, category, product_id }) => {
  let query = `/business/banks/`;
  let queryParameter = [];
  if (entity) queryParameter.push(`entity_ids=[${entity}]`);
  if (category) queryParameter.push(`category=${category}`);
  if (product_id) queryParameter.push(`product_id=${product_id}`);
  if (queryParameter?.length) query = query + "?" + queryParameter.join("&");

  const handleValue = (data) => {
    /*
     # !TODO:
      There will be no new control account data
      So this is unwanted one,
      will remove this once the existing control account data has cleared
      And this need some backend changes also.....
      DATE: 24-02-2023 [commented date]
     */
    const result = data?.map((item) => ({
      label: category != 'ca'
        ? item.account_number + ` (${item.bank_name})`
        : item?.control_account,
      value: category != 'ca' ? item.id : item?.control_account,
      ...item,
    }));
    return result || [];
  };

  return getAPICall(query, { selectValue: handleValue });
};
