import styled from '@emotion/styled';
import React from 'react';

const PreviewWrapper = styled.div`
    width:fit-content;
    .image {
        width: 500px;
        object-fit: contain;
        }
    .iframe-container {
        height:72vh;
        overflow: hidden;
        padding-top: 45%;
        position: relative;
    }
    .iframe-container iframe {
        width:100%;
        height:100%;
        left: 0;
        position: absolute;
        top: 0;
    }
`;

const PreviewFile = ({ data }) => {
    return (
      <PreviewWrapper>
        {/* {
                  ['jpg', 'png', 'jpeg'].includes(data.type) ?
                      <img className="image" src={data.image} alt="image-viewer" /> :
                      <div className="iframe-container">
                          <PdfViewer file={data.image} /> 
                          <iframe src={data.image} frameBorder="0" ></iframe>
                      </div>
              } */}
        {
          data.type == true || data.type == 'pdf' ?
            <div className="iframe-container">
              <iframe title='File Preview' src={data.image} frameBorder="0" ></iframe>
            </div> :
            <img className="image" src={data.image} alt='viewer' />
        }
      </PreviewWrapper>
    )
  
  }
  export default PreviewFile;