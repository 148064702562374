import { getAPICall, patchAPICall, postAPICall } from ".";

/**
 * Saves the e-invoice directly for the given body.
 *
 * @param {Object} body - The body of the e-invoice.
 */
export const eInvoiceDirectSave = ({ body }) => {
  return postAPICall("/external-customer/direct_save", { body });
};

/**
 * Retrieves all invoices based on the provided search criteria.
 *
 * @param {Object} options - The options for retrieving invoices.
 * @param {number} options.page - The page number of the results.
 * @param {string} options.search - The search query for filtering invoices.
 * @param {number} [options.records] - The number of records to return per page. Defaults to 10.
 * @param {string} options.status - The status of the invoices to retrieve.
 */
export const getAlleInvoices = ({page, search, records = 10, status}) => {
  return getAPICall(`/external-customer?page=${page}&customer_id_name=${search}&records=${records}&status=${status}`, { returnObject: true });
}

/**
 * Retrieves an e-invoice by its ID.
 *
 * @param {Object} id - The ID of the e-invoice.
 */
export const geteInvoiceById = ({id}) => {
  return getAPICall(`/external-customer/${id}`, { returnFirstItem: true });
}

/**
 * Sends a POST request to change the status of an external customer's invoice.
 *
 * @param {string} id - The ID of the external customer.
 * @param {object} body - The data to be sent in the request body.
 */
export const eInvoiceStatusChange = ({ id, body }) => {
  return postAPICall(`/external-customer/${id}/status/change`, { body });
}

/**
 * Creates an e-invoice for the external customer.
 *
 * @param {Object} body - The data for creating the e-invoice.
 */
export const eInvoiceCreation = ({ body }) => {
  return postAPICall(`/external-customer`, { body });
}

/**
 * Used to update the e-Invoice
 * 
 * @param {string} id - The ID of the external customer.
 * @param {object} body - The data to be sent in the request body.
 */
export const eInvoiceUpdate = ({ id, body }) => {
  return patchAPICall(`/external-customer/${id}`, { body });
}

/**
 * Used to cancel the e-Invoice
 * 
 * @param {string} id - The ID of the external customer.
 * @param {object} body - The data to be sent in the request body.
 */
export const eInvoiceCancellation = ({ id, body }) => {
  return postAPICall(`/external-customer/${id}/cancelled`, { body });
}

/**
 * Handling the reject functionality
 * 
 * @param {string} id - The ID of the external customer.
 * @param {object} body - The data to be sent in the request body.
 */
export const eInvoiceReject = ({ id, body }) => {
  return postAPICall(`/external-customer/${id}/rejected`, { body });
}

/**
 * Used the change the current application status to the pervious one
 * 
 * @param {string} id - The ID of the external customer.
 * @param {object} body - The data to be sent in the request body.
 */
export const eInvoicePushback = ({ id, body }) => {
  return postAPICall(`/external-customer/${id}/pushback`, { body });
}

/**
 * Verify GST for an invoice.
 *
 * @param {Object} gst - The GST information.
 */
export const gstVerifyForInvoice = ({ gst }) => {
  return getAPICall(`/external-customer/${gst}/info`, { returnObject: true });
}

/**
 * Retrieves all remarks for a given eInvoice ID.
 *
 * @param {string} params.id - The ID of the eInvoice.
 */
export const getAllRemarkseInvoice = ({ id }) => {
  return getAPICall(`/external-customer/${id}/remark`);
}

/**
 * Retrieves all manual customer invoices based on the provided parameters.
 *
 * @param {Object} options - The options for retrieving the invoices.
 * @param {number} options.page - The page number to retrieve.
 * @param {string} options.search - The search term for customer ID/name.
 * @param {number} [options.records] - The number of records to retrieve (default is 10).
 * @param {string} options.status - The status of the invoices to retrieve.
 */
export const getAllManualCustomerInvoice = ({page, search, records = 10, status}) => {
  return getAPICall(`/external-customer-invoice?page=${page}&customer_id_name=${search}&records=${records}&status=${status}`, { returnObject: true });
}

/**
 * Used to calculate the GST Changes for an invoice.
 * 
 * @param {object} body - The data to be sent in the request body.
 */
export const externalCustomerInvoiceGSTCalculation = ({ body }) => {
  return postAPICall(`/external-customer/invoice/gst-calculation`, { body });
}

/**
 * Handle the status change functionality
 * 
 * @param {string} id - The ID of the external customer.
 * @param {object} body - The data to be sent in the request body.
 */
export const externalCustomerInvoiceStatusChange = ({ id, body }) => {
  return postAPICall(`/external-customer/invoice/${id}/status/change`, { body });
}

/**
 * Change the current application status to the pervious one
 * 
 * @param {string} id - The ID of the external customer.
 * @param {object} body - The data to be sent in the request body.
 */
export const externalCustomerInvoicePushback = ({ id, body }) => {
  return postAPICall(`/external-customer/invoice/${id}/pushback`, { body });
}

/**
 * Handle the reject functionality
 * 
 * @param {string} id - The ID of the external customer.
 * @param {object} body - The data to be sent in the request body.
 */
export const externalCustomerInvoiceRejected = ({ id, body }) => {
  return postAPICall(`/external-customer/invoice/${id}/rejected`, { body });
}

/**
 * Handled the cancel functionality
 * 
 * @param {string} id - The ID of the external customer.
 * @param {object} body - The data to be sent in the request body.
 */
export const externalCustomerInvoiceCancel = ({ id, body }) => {
  return postAPICall(`/external-customer/invoice/${id}/cancelled`, { body });
}

/**
 * Used to create the invoice for the external customer
 * 
 * @param {object} body - The data to be sent in the request body.
 */
export const externalCustomerInvoiceCreate = ({ body }) => {
  return postAPICall(`/external-customer/invoice`, { body });
}

/**
 * used to create the invoice for the external customer
 * 
 * @param {object} body - The data to be sent in the request body.
 */
export const externalCustomerInvoiceCreateAndStatusChange = ({ body }) => {
  return postAPICall(`/external-customer/invoice/direct_save`, { body });
}

/**
 * Used to get invoice data by its Id
 * 
 * @param {string} id - The ID of the external customer.
 */
export const getExternalCustomerInvoiceById = ({ id }) => {
  return getAPICall(`/external-customer/invoice/${id}`, { returnFirstItem: true });
}

/**
 * Handling the update functionality
 * 
 * @param {string} id - The ID of the external customer.
 * @param {object} body - The data to be sent in the request body.
 */
export const updateExternalCustomerInvoice = ({ id, body }) => {
  return patchAPICall(`/external-customer/invoice/${id}`, { body });
}

/**
 * Handling the customer search for External Customer Invoice
 * 
 * @param {string} id - The ID of the external customer.
 */
export const searchCustomerForExternalInvoice = ({ id }) => {
  const handleValue = (data) => {
    const result = data?.map((item) => ({
      label: `${item.name} (${item.id})`,
      value: item.id,
      ...item,
    }));
    return result || [];
  };
  return getAPICall(`customer/search/?customer_id_name=${id}&module_type=external_customer_invoice`, { selectValue: handleValue });
}

/**
 * Used to get all changes for external customer invoice
 */
export const getAlleInvoiceCharges = () => {
  const handleValue = (data) => {
    const result = data?.map((item) => ({
      label: item?.name,
      value: item?.id,
      sgst: item?.sgst_rate || 0,
      cgst: item?.cgst_rate || 0,
      igst: item?.igst_rate || 0,
      ...item,
    }));
    return result || [];
  };
  return getAPICall(`/external-customer-invoice/charges`, {
    selectValue: handleValue,
  });
}

/**
 * Retrieves the remarks of an external customer invoice.
 *
 * @param {string} options.id - The ID of the invoice.
 */
export const getRemarksOfExternalCustomerInvoice = ({ id }) => {
  return getAPICall(`/external-customer/invoice/${id}/remark`);
}