import React, { useState } from 'react'
import Body from '../../components/Body/Body'
import PageTitle from '../../components/PageTitle/PageTitle'
import { Table, Box, Grid, Tooltip } from '@mantine/core'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getBulkReceiptById, getBulkReceiptRemarks, sendBulkReceiptForCancel, sendBulkReceiptForNextStage, sendBulkReceiptForPushback, sendBulkReceiptToReject } from '../../services/bulkReceipt.service'
import { COLORS, STAGE_CHANGE_TEXT } from '../../constants'
import ActionButtons from '../../components/CommonComponents/ActionButtons/ActionButtons'
import { APPROVED, AUTO_APPROVED, AUTO_CANCELLED, CANCELLATION_APPROVAL, CANCELLATION_REVIEW, CANCELLED, IN_APPROVAL, IN_REVIEW, REJECTED } from '../../constants/status'
import { getStageforModules } from '../../services/module.service'
import { StatusToken, ViewFormData } from '../../components/CommonComponents/Preview/FilePreview'
import { sumBy } from 'lodash'
import DataViewerWapper from '../../components/CommonComponents/Preview/DataViewerWapper'
import { dateFormat } from '../../components/DateFormat/dateFormat'
import Nums from '../../components/Body/Nums'
import ImdStyle from '../IMD/Imd.styles'
import IdBadge from './../../components/CommonComponents/Preview/IdBadge';
import ViewRemark from '../../components/Remark/ViewRemark'
import Remarks from '../../components/CommonComponents/Remarks/Remarks'
import ModalDataViwer from '../../components/SucessfullModal/ModalDataViwer'
import { ExternalLink } from 'tabler-icons-react'
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification'
import { STATUS_COLORS, STATUS_VALUE } from '../../utils/StatusTable'

const BulkReceiptReview = ({ home = '/bulk-receipt' }) => {

  const { bulkReceiptId } = useParams();

  const [open, setOpen] = useState(false);
  const [remarksLoading, setRemarksLoading] = useState(false);
  const [remarksTitle, setRemarksTitle] = useState(false);
  const [status, setStatus] = useState(false);
  const [label, setLabel] = useState(false);
  const navigate = useNavigate();
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [sucessfulmodal, setSucessfulmodal] = useState({
    open: false,
    title: ''
  })

  const bulkReceiptQuery = useQuery({
    queryKey: ['bulkReceipt', bulkReceiptId],
    queryFn: () => getBulkReceiptById(bulkReceiptId),
    enabled: Boolean(bulkReceiptId)
  })

  const { data: stages = [] } = useQuery({
    queryKey: ['receipt-stages'],
    queryFn: () => getStageforModules('bulk_receipt'),
    enabled: Boolean(bulkReceiptQuery?.data?.bulk_receipt?.status_value),
    select: (dataItem) => {
      return {
        'current_stage': bulkReceiptQuery?.data?.bulk_receipt?.status_value,
        'next_stage': dataItem[dataItem.indexOf(bulkReceiptQuery?.data?.bulk_receipt?.status_value) + 1]
      }
    },
  });

  const remarksTrailQuery = useQuery({
    queryKey: ['bulk-receipt-remarks', bulkReceiptId],
    queryFn: () => getBulkReceiptRemarks(bulkReceiptId),
    enabled: Boolean(bulkReceiptId),
    initialData: [],
  })

  /**
 * Sends a bulk receipt for the next stage and opens a successful modal if successful, 
 * or shows an error notification if unsuccessful.
 *
 * @param {string} remarks - The remarks for the bulk receipt.
 * @param {string} status - The status of the bulk receipt.
 */
  const onSendReceiptforNextStage = (remarks, status) => {
    const body = {
      remarks,
    };

    setRemarksLoading(true);
    sendBulkReceiptForNextStage(bulkReceiptId, body)
      .then((data) => {
        setSucessfulmodal({
          open: true, title:
            status == IN_REVIEW ? STAGE_CHANGE_TEXT?.approval
              : status == IN_APPROVAL ? STAGE_CHANGE_TEXT?.approved
                : status == CANCELLATION_REVIEW ? STAGE_CHANGE_TEXT?.reviewCancel
                  : STAGE_CHANGE_TEXT?.approveCancel
        });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        bulkReceiptQuery.refetch();
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  /**
 * Handle pushback by sending bulk receipt remarks to server
 *
 * @param {string} remarks - Remarks to be sent with bulk receipt
 */
  const handlePushback = (remarks) => {
    const body = {
      remarks,
    };

    setRemarksLoading(true);
    sendBulkReceiptForPushback(bulkReceiptId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.pushback });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        bulkReceiptQuery.refetch();
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  /**
 * Handles rejection of bulk receipt by sending remarks to server and displaying
 * appropriate notification.
 *
 * @param {string} remarks - The remarks to send to the server.
 */
  const handleReject = (remarks) => {
    const body = {
      remarks,
    };

    setRemarksLoading(true);
    sendBulkReceiptToReject(bulkReceiptId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.reject });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        bulkReceiptQuery.refetch();
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  /**
 * Handles the cancellation of a bulk receipt.
 *
 * @param {string} remarks - The remarks for the cancellation.
 */
  const handleCancellation = (remarks, reason) => {
    const body = {
      remarks,
      reason: reason?.reason,
    };

    setRemarksLoading(true);
    sendBulkReceiptForCancel(bulkReceiptId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.cancel });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        bulkReceiptQuery.refetch();
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  /**
 * Calls the appropriate function based on the `status` parameter to handle the given `remarks`.
 *
 * @param {string} remarks - The remarks to be processed.
 * @param {string} status - The status to determine which function to call.
 * @param {string} reason - The reason for cancellation (if applicable).
 */
  const callFunction = (remarks, status, reason) => {
    switch (status) {
      case "review":
      case "approval":
      case 'cancellation':
        onSendReceiptforNextStage(remarks, status);
        break;
      case 'pushback':
        handlePushback(remarks, status);
        break;
      case "reject":
        handleReject(remarks);
        break;
      case "cancel":
        handleCancellation(remarks, reason);
        break;
      default:
        displayNotification({
          message: "Something went wrong. Please try again.",
          variant: "error"
        })
        break;
    }
  };

  // handle pushback callback 
  const pushbackcallback = () => {
    setOpen(true);
    setRemarksTitle("Pushback Remarks");
    setStatus("pushback");
    setLabel("Pushback");
  }

  const handleReceiptOpen = (i) => {
    if (i?.generated_receipt_status) {
      if (i?.generated_receipt_status == IN_APPROVAL || i?.generated_receipt_status == IN_REVIEW) {
        navigate(`/customer-receipt/review/${i?.customer_id}/${i?.generated_receipt_id}`);
      } else if (
        i?.generated_receipt_status == APPROVED ||
        i?.generated_receipt_status == AUTO_APPROVED
      ) {
        navigate(`/customer-receipt/approved/${i?.customer_id}/${i?.generated_receipt_id}`);
      } else if (i?.generated_receipt_status == REJECTED) {
        navigate(`/customer-receipt/rejected/${i?.customer_id}/${i?.generated_receipt_id}`);
      } else if (i?.generated_receipt_status == AUTO_CANCELLED) {
        navigate(`/customer-receipt/cancelled/${i?.customer_id}/${i?.generated_receipt_id}`);
      } else if (i?.generated_receipt_status == CANCELLED) {
        navigate(
          `/cancellation/customer-level-receipt-cancelled/${i?.generated_receipt_id}/${i?.customer_id}`
        );
      } else if (
        i?.generated_receipt_status == CANCELLATION_REVIEW ||
        i?.generated_receipt_status == CANCELLATION_APPROVAL
      ) {
        navigate(
          `/cancellation/customer-level-receipt-cancel/${i?.generated_receipt_id}/${i?.customer_id}`
        );
      }
    }
  }

  return (
    <>
      <Body background={false}>
        {/* name and id has send as props to display in the page */}
        <PageTitle title={'Bulk Receipt'} />
      </Body>
      <Box sx={{ position: "relative" }}>
        <Body>
          {bulkReceiptQuery?.data?.bulk_receipt?.batch_code && (
            <div style={ImdStyle.flag}>
              <IdBadge remarks={bulkReceiptQuery?.data?.bulk_receipt?.status_value} label={bulkReceiptQuery?.data?.bulk_receipt?.batch_code} />
            </div>
          )}
          <Grid gutter='xs' mt={'md'}>
            <Grid.Col span={4}>
              <DataViewerWapper>
                <Grid.Col span={12}>
                  <ViewFormData label={'Created On'} value={dateFormat(bulkReceiptQuery?.data?.bulk_receipt?.created_on, 'DD-MM-YYYY')} loading={bulkReceiptQuery?.isLoading} />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewFormData label={'Receipt Amount'} value={<Nums value={sumBy(bulkReceiptQuery?.data?.receivables, 'receipt_amount')} />} loading={bulkReceiptQuery?.isLoading} />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewFormData label={'No Of Receipts'} value={bulkReceiptQuery?.data?.bulk_receipt?.receivable_count} loading={bulkReceiptQuery?.isLoading} />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewFormData label={'Created By'} value={bulkReceiptQuery?.data?.bulk_receipt?.maker_name} loading={bulkReceiptQuery?.isLoading} />
                </Grid.Col>
              </DataViewerWapper>
            </Grid.Col>
          </Grid>
          {bulkReceiptQuery?.data?.receivables?.length
            ? (
              <Box sx={{ maxHeight: '400px', overflow: 'hidden', overflowY: 'scroll', overflowX: 'scroll', marginTop: '20px' }}>
                <Table verticalSpacing='xs' fontSize='xs' horizontalSpacing="xl" sx={{ whiteSpace: "nowrap", maxWidth: "100%", display: 'inline-table' }}>
                  <thead style={{ backgroundColor: COLORS.primary.light1, fontSize: '14px', position: 'sticky', top: 0 }}>
                    <tr>
                      <th>Customer Code</th>
                      <th>Customer Id</th>
                      <th>Customer Name</th>
                      {!['approval', 'rejected']?.includes(bulkReceiptQuery?.data?.bulk_receipt?.status_value) ? <th>Receipt Code</th> : null}
                      <th>Due Amount</th>
                      <th>Receipt Amount</th>
                      <th>Receipt Date</th>
                      <th>Instrument Type</th>
                      <th>Payment Ref No</th>
                      {!['approval', 'rejected']?.includes(bulkReceiptQuery?.data?.bulk_receipt?.status_value) ? <th>Status</th> : null}
                      <th>Comments</th>
                      {!['approval', 'rejected']?.includes(bulkReceiptQuery?.data?.bulk_receipt?.status_value) ? <th style={{ width: '50px' }}></th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      bulkReceiptQuery?.data?.receivables?.map((i) =>
                        <tr key={i.id} style={{ background: i?.comments && '#ffdede' }}>
                          <td>{i.customer_code}</td>
                          <td>{i.customer_id}</td>
                          <td>{i.customer_name}</td>
                          {!['approval', 'rejected']?.includes(bulkReceiptQuery?.data?.bulk_receipt?.status_value) ? <td>{i.generated_receipt_code}</td> : null}
                          <td><Nums value={i.total_due_amount} /></td>
                          <td><Nums value={i.receipt_amount} /></td>
                          <td>{dateFormat(i.receipt_date, 'DD/MM/YYYY')}</td>
                          <td>{i.instrument_type?.toUpperCase()}</td>
                          <td>{i.payment_ref_no}</td>
                          {!['approval', 'rejected']?.includes(bulkReceiptQuery?.data?.bulk_receipt?.status_value)
                            ? (<td>
                              <StatusToken
                                label={STATUS_VALUE?.[i.generated_receipt_status]}
                                color={STATUS_COLORS?.[i.generated_receipt_status]}
                              />
                            </td>)
                            : null
                          }
                          <td>{i.comments ? i.comments : '-'}</td>
                          {!['approval', 'rejected']?.includes(bulkReceiptQuery?.data?.bulk_receipt?.status_value)
                            ? (<td>
                              <Tooltip label={'Click to view Receipt'} color='gray' position='top-start'>
                                <span>
                                  <ExternalLink onClick={() => handleReceiptOpen(i)} color='green' size={14} />
                                </span>
                              </Tooltip>
                            </td>)
                            : null
                          }
                        </tr>
                      )
                    }
                  </tbody>
                </Table>
              </Box>
            )
            : null
          }
          {/** This Action buttons component is used to display the buttons in the flow */}
          <ActionButtons
            status={stages?.current_stage}
            isReady={Boolean(stages?.current_stage)}
            nextStage={stages?.next_stage}
            remarks={remarksTrailQuery?.data}
            accountingCB={false}
            sendForApprovalCB={() => {
              setOpen(true);
              setRemarksTitle("Approval Remarks");
              setStatus("review");
              setLabel("Send for Approval");
            }}
            approvedCB={() => {
              setOpen(true);
              setRemarksTitle("Approval Remarks");
              setStatus("approval");
              setLabel("Approve");
            }}
            pushbackCB={[CANCELLATION_APPROVAL, CANCELLATION_REVIEW]?.includes(bulkReceiptQuery?.data?.bulk_receipt?.status_value) ? pushbackcallback : false}
            rejectCB={() => {
              setOpen(true);
              setRemarksTitle("Reject Remarks");
              setStatus("reject");
              setLabel("Reject");
            }}
            remarksCB={() => setRemarksOpen(true)}
            cancelCB={() => {
              setOpen(true);
              setRemarksTitle("Cancellation Remarks");
              setStatus('cancellation');
              setLabel('Send for Cancellation')
            }}
            approveCancelCB={() => {
              setOpen(true);
              setRemarksTitle("Cancellation Remarks");
              setStatus('cancel');
              setLabel('Approve Cancellation')
            }}
          />

          {/** used to get remarks from user */}
          <Remarks
            open={open}
            setOpen={setOpen}
            type={stages?.next_stage}
            callback={callFunction}
            remarksTitle={remarksTitle || []}
            check={status}
            moduleName={"bulk_receipt"}
            label={label}
            loading={remarksLoading}
          />

          {/** used to display the suceccfull stage change modal */}
          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={bulkReceiptQuery?.data?.bulk_receipt?.batch_code}
            title={sucessfulmodal?.title}
            href={home}
            hreftext={"Back to list table"}
          />
        </Body>
        {/** used to display the remarks */}

        <ViewRemark
          open={remarksOpen}
          onClose={setRemarksOpen}
          remarkTrail={remarksTrailQuery?.data}
        />
      </Box>
    </>
  )
}

export default BulkReceiptReview