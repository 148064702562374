import { getAPICall, postAPICall } from ".";
import { dateFormat } from "../components/DateFormat/dateFormat";

export const getAllModulesStatus = (module_name = "") => {
  return getAPICall(`/modules/status/master?module_name=${module_name}`);
};

export const getStageforModules = (module_name = "") => {
  return getAPICall(`/module/active-status?module_name=${module_name}`);
};

export const sendModuleStatusActiveInActive = (body) => {
  return postAPICall(`/modules/status/modifier`, { body });
};

/** used to get the cancellation remarks by module name */
export const getCancellationRemarks = (moduleName) => {
  const handleValue = (data) => {
    const result = data?.map((item, i) => ({
      label: item?.reason,
      value: item?.reason,
      ...item,
    }));
    return result || [];
  };
  return getAPICall(`/cancellation-reasons?module_name=${moduleName}`, {
    selectValue: handleValue,
  });
};

export const getAllCancellationData = ({
  page,
  status,
  records,
  customer_id,
  from_date,
  end_date,
  product_id,
  module_name,
}) => {
  const query = [];
  if (customer_id) query.push(`customer_id_name=${customer_id}`);
  if (from_date) query.push(`from_date=${dateFormat(from_date, "YYYY-MM-DD")}`);
  if (end_date) query.push(`to_date=${dateFormat(end_date, "YYYY-MM-DD")}`);
  if (product_id) query.push(`product_id=${product_id}`);
  if (module_name) query.push(`module_name=${module_name}`);
  const url =
    `/module-cancellations?page=${page}&status=${status}&records=${records}` +
    "&" +
    query.join("&");
  return getAPICall(url, { returnObject: true });
};

export const getListOfStatusWithCount = ({
  module_name,
  entity,
  reload_type,
}) => {
  let query = `/module-record-count?module_name=${module_name}`;
  if (entity && entity !== 'all') query = query + `&entity_id=${entity}`;
  if (reload_type && reload_type !== 'all') query = query + `&reload_type=${reload_type}`;
  return getAPICall(query)
}

export const getCurrentStatusByModuleNameAndId = ({ moduleName, moduleApplicationId }) => {
  /*
  @props:
    moduleName: (string) => name of the module,
    moduleApplicationId: (string || number) => module id of that application
  */
  return getAPICall(`module-current-status/${moduleName}/${moduleApplicationId}`)
}