import { Grid, Title, ActionIcon, Box, Button } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import ControllerSelect from "../../components/CommonComponents/TextField/ControllerSelect";
import ImdStyle from "../IMD/Imd.styles";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalDataViwer from "../../components/SucessfullModal/ModalDataViwer";
import {
  ViewData,
  ViewFormData,
  ViewFormDataContained,
} from "../../components/CommonComponents/Preview/FilePreview";
import { useQuery } from "@tanstack/react-query";
import {
  customerBankDetails,
  CompanyBankDetails,
} from "../../services/imd.service";
import Body from "../../components/Body/Body";
import DashBoardCustomerDetails from "../../components/DashboardCard/dashboard";
import DataViewerWapper from "../../components/CommonComponents/Preview/DataViewerWapper";
import TitleViewer from "../../components/CommonComponents/Title/TitleViewer";
import {
  getApprovedDisbById,
  PaymentCreationSave,
  PaymentPatch,
  getPaymentById,
  FileUpload,
  getAllRemarksPayment,
  sendPaymentforNextStage,
  savePaymentAndsendForNextStage,
} from "../../services/payment.service";
import ControllerTextField from "../../components/CommonComponents/TextField/ControllerTextField";
import Remarks from "../../components/CommonComponents/Remarks/Remarks";
import { Upload } from "tabler-icons-react";
import TextComp from "../../components/CommonComponents/TextComponent/TextComponent";
import AddFile from "../../components/CommonComponents/FileUpload/FileUpload";
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { AvatarCard } from "../../components/CommonComponents/Preview/FileAvatar";
import PageTitle from "../../components/PageTitle/PageTitle";
import Nums from "../../components/Body/Nums";
import { DEFAULT_SUPPORTED_IMAGE_TYPES, DEFAULT_SUPPORTED_PDF_TYPES, STAGE_CHANGE_TEXT } from "../../constants";
import { getDeductionLoansbyID } from "../../services/loan.service";
import IdBadge from "../../components/CommonComponents/Preview/IdBadge";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import ViewRemark from "../../components/Remark/ViewRemark";
import ActionButtons from "../../components/CommonComponents/ActionButtons/ActionButtons";
import { instrumentType } from "../../utils/InstrumentTypes";
import LoanRenewalTable from "../../components/CommonComponents/RenewalTable/LoanRenewalTable";
import { getStageforModules } from "../../services/module.service";
import CreditReloadDetails from "../../components/CommonComponents/CreditReload/CreditReloadDetails";
import { ViewFeeDetailsWithGST } from "../../components/FeePopover/FeeCalculationPopover";
import useSystemConfigStore from "../../store/systemConfigStore";
import { getCustomerBankById } from './../../services/customer.service';
import { PAYMENT_REF_NO_LIMIT } from "../../constants/validationCheck";
import ReloadFeePopover from "../../components/FeePopover/ReloadFeePopover";

const schema = yup.object({
  instrument_type: yup
    .string()
    .nullable()
    .required("Please Select Instrument Type"),
  payment_reference_number: yup
    .string()
    .max(parseInt(PAYMENT_REF_NO_LIMIT), `payment no must be less then ${PAYMENT_REF_NO_LIMIT} characters`)
    .nullable()
    .required("Please Enter Payment Reference no"),
});

const PaymentCreation = ({ formType }) => {
  const { paymentId, loansId, createdPaymentId } = useParams();
  const systemDate = useSystemConfigStore((store) => store.config);
  const [open, setOpen] = useState(false);
  const [sucessfulmodal, setSucessfulmodal] = useState({});
  const [status, setStatus] = useState();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [fileOpen, setFileOpen] = useState(false);
  const [prospectcodebody, setProspectcodebody] = useState(null);
  const [label, setLabel] = useState();
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [remarksLoading, setRemarksLoading] = useState();
  const [file, setFile] = useState();
  const formData = new FormData();

  const { data: disbData = [], isLoading: loading } = useQuery(
    ["disb-id", loansId],
    () => loansId && getApprovedDisbById(loansId),
    {
      onSuccess: (data) => {
        setProspectcodebody({
          id: data?.deduction_loan_ids?.length
            ? data?.deduction_loan_ids
            : null,
        });
      },
    },
    { refetchOnWindowFocus: false }
  );

  const { data: stages = [] } = useQuery(
    ["payment-stage", "payment"],
    () => getStageforModules("payment"),
    {
      select: (data) => {
        return {
          current_stage: "draft",
          next_stage: data[1],
        };
      },
      refetchOnWindowFocus: false,
    }
  );

  const { data: targetBank = {} } = useQuery(
    ["customer-bank", paymentId],
    () => getCustomerBankById({ customerId: paymentId, bankId: disbData?.target_bank_id }),
    {
      enabled: Boolean(disbData?.target_bank_id),
      select: (data) => {
        return data[0]
      },
    }
  );

  const { data: sourceBank = [], isLoading: sourceBankLoading } = useQuery(
    ["conpany-bank", disbData?.source_bank_id],
    () => CompanyBankDetails(disbData?.source_bank_id),
    {
      enabled: Boolean(disbData?.source_bank_id),
      refetchOnWindowFocus: false,
    }
  );

  const { data: reviewTrail = [] } = useQuery(
    ["remarks", createdPaymentId],
    () => createdPaymentId && getAllRemarksPayment(createdPaymentId),
    { refetchOnWindowFocus: false }
  );

  const { data: ProspectCode = [] } = useQuery(
    ["prospectcode", prospectcodebody],
    () =>
      prospectcodebody &&
      getDeductionLoansbyID(disbData?.loan_id, prospectcodebody),
    { refetchOnWindowFocus: false }
  );

  const { data: paymentDetails = [] } = useQuery(
    ["payment-id", createdPaymentId],
    () => createdPaymentId && getPaymentById(createdPaymentId),
    { refetchOnWindowFocus: false }
  );

  const {
    handleSubmit,
    setError,
    setValue,
    control,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues:
      formType == "view"
        ? {
          ...paymentDetails,
          instrument_date: systemDate?.current_system_date,
        }
        : {
          instrument_date: systemDate?.current_system_date,
          instrument_type: "rtgs",
        },
  });

  useEffect(() => {
    if (Object.keys(paymentDetails).length) {
      reset(paymentDetails);
      setValue("instrument_date", systemDate?.current_system_date);
    }
  }, [paymentDetails]);

  useEffect(() => {
    if (Object.keys(systemDate).length && !formType) {
      setValue("instrument_date", systemDate?.current_system_date);
    }
  }, [systemDate]);

  const values = watch();

  const handleReviewModal = () => {
    schema
      .validate(values)
      .then((res) => {
        setOpen(true);
        setStatus("review");
        setLabel(
          stages?.next_stage == "approval"
            ? "Send for Approval"
            : "Send for Review"
        );
      })
      .catch((err) => {
        displayNotification({ message: err?.errors, variant: 'error' });
      });
  };

  const onSubmit = () => {
    const body = {
      customer_application_id: disbData?.customer_application_id,
      customer_id: disbData?.customer_id,
      disbursement_id: parseInt(loansId),
      instrument_type: values?.instrument_type,
      payment_reference_number: values?.payment_reference_number?.toUpperCase(),
      payment_reference_attachment_url: file
        ? file
        : paymentDetails?.payment_reference_attachment_url,
    };
    const savebody = {
      ...disbData,
      disbursement_id: parseInt(loansId),
      ...body,
    };

    setButtonLoader({ save: true });
    if (!createdPaymentId) {
      PaymentCreationSave(savebody)
        .then((res) => {
          setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.save });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({
              id: 'pmt-creation',
              message: e?.message || e,
              variant: 'error',
            });
          }
        })
        .finally(() => {
          setButtonLoader({ save: false });
        });
    } else {
      PaymentPatch(createdPaymentId, body)
        .then((res) => {
          setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.update });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({
              id: 'pmt-update',
              message: e?.message || e,
              variant: 'error',
            });
          }
        })
        .finally(() => {
          setButtonLoader({ save: false });
        });
    }
  };

  const onsendForReview = (remarks) => {
    const body = {
      remark_type: "maker",
      remarks: remarks,
    };

    const UpdateBody = {
      customer_application_id: disbData?.customer_application_id,
      customer_id: disbData?.customer_id,
      disbursement_id: parseInt(loansId),
      instrument_type: values?.instrument_type,
      payment_reference_number: values?.payment_reference_number?.toUpperCase(),
      payment_reference_attachment_url: file
        ? file
        : paymentDetails?.payment_reference_attachment_url,
      ...body,
    };
    setRemarksLoading(true);
    if (!createdPaymentId) {
      savePaymentAndsendForNextStage(UpdateBody)
        .then((res) => {
          setSucessfulmodal({
            open: true,
            title:
              stages?.next_stage == "approval"
                ? STAGE_CHANGE_TEXT?.approval
                : STAGE_CHANGE_TEXT?.review,
          });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' });
          }
        })
        .finally(() => {
          setRemarksLoading(false);
          setOpen(false);
        });
    } else {
      PaymentPatch(createdPaymentId, UpdateBody)
        .then((res) => {
          sendPaymentforNextStage(createdPaymentId, body)
            .then((res) => {
              setSucessfulmodal({
                open: true,
                title:
                  stages?.next_stage == "approval"
                    ? STAGE_CHANGE_TEXT?.approval
                    : STAGE_CHANGE_TEXT?.review,
              });
            })
            .catch((e) => {
              if (e?.RequestValidationError) {
                Object.keys(e?.RequestValidationError).forEach(
                  (item, index) => {
                    setError(item, {
                      message: e?.RequestValidationError[item],
                    });
                  }
                );
              } else {
                displayNotification({ message: e?.message || e, variant: 'error' });
              }
            });
        })
        .finally(() => {
          setRemarksLoading(false);
          setOpen(false);
        });
    }
  };

  const handleFileUpload = (fileUpload, setFileLoading) => {
    formData.append("customer_id", paymentId);
    formData.append("file", fileUpload[0]);
    if (!fileUpload?.length) {
      displayNotification({
        message: "Please select any file to upload",
        variant: 'error',
      });
      setFileLoading(false);
    } else {
      FileUpload(paymentId, formData)
        .then((res) => {
          setFile(res);
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' });
        })
        .finally(() => {
          setFileLoading(false);
          setFileOpen(false);
        });
    }
  };

  const handleDeleteFile = () => {
    setFile();
    displayNotification({ message: 'File removed Sucessfully', variant: 'success' });
  };

  return (
    <>
      <Body background={false}>
        <PageTitle
          title={disbData?.customer_name}
          id={disbData?.customer_id}
          action={
            disbData?.crr_id ? (
              /** display the credit reload request */
              <CreditReloadDetails selectedRowId={disbData?.crr_id} type={disbData?.additional_deduction?.find(i => i?.fee_type === 'express_reload_fee') ? 'express' : null} />
            ) : null
          }
        />
      </Body>
      <DashBoardCustomerDetails
        id={paymentId}
        loansId={disbData?.loan_id}
        codeName={"Payment Code"}
        code={paymentDetails?.code}
      />
      <Box sx={{ position: "relative" }}>
        <Body>
          {disbData?.prospect_code && (
            <div style={ImdStyle.flag}>
              <IdBadge remarks={"draft"} label={disbData?.prospect_code} />
            </div>
          )}
          <Grid gutter="xl" pt={"sx"} mt={"sm"}>
            <Grid.Col span={6}>
              <DataViewerWapper>
                <Grid gutter="lg">
                  <Grid.Col span={12}>
                    <ViewFormData
                      label={"Loan Type"}
                      value={
                        disbData?.loan_type === "new"
                          ? "New"
                          : disbData?.loan_type === "enhancement"
                            ? "Enhancement"
                            : disbData?.loan_type === 'transfer'
                              ? "Transfer"
                              : "Revolving"
                      }
                      loading={loading}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label={"Product"}
                      value={disbData?.product_name}
                      loading={loading}
                    />
                  </Grid.Col>
                </Grid>
                {disbData?.loan_type === "new" || disbData?.loan_type === 'enhancement' || disbData?.loan_type === 'transfer' ? (
                  <>
                    <Grid gutter="lg">
                      <Grid.Col span={12} mt="xs">
                        <TitleViewer title="Cash Flow" />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={"Disb Date"}
                          value={dateFormat(disbData?.disbursed_date)}
                          loading={loading}
                        />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={"Due Date"}
                          value={dateFormat(disbData?.due_date)}
                          loading={loading}
                        />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={"Principal"}
                          value={<Nums value={disbData?.loan_amount} />}
                          loading={loading}
                        />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={`Interest`}
                          secondaryLabel={disbData?.interest_rate}
                          value={<Nums value={disbData?.interest_amount} />}
                          loading={loading}
                        />
                      </Grid.Col>
                      {/* If spread rate percentage is avalible then it will display */}
                      {disbData?.spread_rate ? (
                        <Grid.Col span={12}>
                          <ViewFormData
                            label={`Spread`}
                            secondaryLabel={disbData?.spread_rate}
                            value={<Nums value={disbData?.spread_amount} />}
                            loading={loading}
                          />
                        </Grid.Col>
                      ) : null}
                      {/* If spread rate percentage is avalible then it will display */}
                      {disbData?.addon_spread_rate ? (
                        <Grid.Col span={12}>
                          <ViewFormData
                            label={`Addon Spread`}
                            secondaryLabel={disbData?.addon_spread_rate}
                            value={
                              <Nums value={disbData?.addon_spread_amount} />
                            }
                            loading={loading}
                          />
                        </Grid.Col>
                      ) : null}
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={"Due Amount"}
                          value={<Nums withCopy value={disbData?.due_amount} />}
                          loading={loading}
                        />
                      </Grid.Col>
                      {disbData?.advance_interest ? (
                        <Grid.Col span={12}>
                          <ViewFormData
                            label={`Advance Interest`}
                            value={
                              <Nums
                                withCopy
                                value={disbData?.advance_interest}
                              />
                            }
                            loading={loading}
                          />
                        </Grid.Col>
                      ) : null}
                      {disbData?.additional_deduction?.length ? (
                        <>
                          <Grid.Col span={12} mt="xs">
                            <TitleViewer title="Deductions" />
                          </Grid.Col>
                          {disbData?.additional_deduction?.map((item) => (
                            <Grid.Col span={12}>
                              <ViewFormData
                                label={item?.fee_name}
                                value={
                                  <Nums withCopy value={item?.total_amount} />
                                }
                                action={
                                  item?.fee_type == "fee" ? (
                                    <ViewFeeDetailsWithGST
                                      calculatedFee={item}
                                    />
                                  ) : (
                                    item?.fee_type === 'express_reload_fee' ? <ReloadFeePopover value={item} /> : null
                                  )
                                }
                              />
                            </Grid.Col>
                          ))}
                        </>
                      ) : null}
                      <Grid.Col span={12}>
                        <ViewFormDataContained
                          label={"Net Disb Amount"}
                          value={
                            <Nums withCopy value={disbData?.disbursed_amount} />
                          }
                          loading={loading}
                        />
                      </Grid.Col>
                    </Grid>
                  </>
                ) : null}
                {disbData?.loan_type == "revolving" ? (
                  <>
                    <Grid gutter="lg" mt="md">
                      <Grid.Col span={12}>
                        <TitleViewer title={'Revolving'} />
                      </Grid.Col>

                      <Grid.Col span={12}>
                        <ViewFormData
                          label={"Tranche Amount"}
                          value={
                            <Nums withCopy value={disbData?.loan_amount} />
                          }
                        />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={"Disb Date"}
                          value={dateFormat(disbData?.disbursed_date)}
                          loading={loading}
                        />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={"Due Date"}
                          value={dateFormat(disbData?.due_date)}
                          loading={loading}
                        />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={`Interest`}
                          secondaryLabel={disbData?.interest_rate}
                          value={<Nums value={disbData?.interest_amount} />}
                          loading={loading}
                        />
                      </Grid.Col>
                      {/* If spread rate percentage is avalible then it will display */}
                      {disbData?.spread_rate ? (
                        <Grid.Col span={12}>
                          <ViewFormData
                            label={`Spread`}
                            secondaryLabel={disbData?.spread_rate}
                            value={<Nums value={disbData?.spread_amount} />}
                            loading={loading}
                          />
                        </Grid.Col>
                      ) : null}
                      {/* If addon spread rate percentage is avalible then it will display */}
                      {disbData?.addon_spread_rate ? (
                        <Grid.Col span={12}>
                          <ViewFormData
                            label={`Addon Spread`}
                            secondaryLabel={disbData?.addon_spread_rate}
                            value={
                              <Nums value={disbData?.addon_spread_amount} />
                            }
                            loading={loading}
                          />
                        </Grid.Col>
                      ) : null}
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={"Due Amount"}
                          value={<Nums withCopy value={disbData?.due_amount} />}
                        />
                      </Grid.Col>
                      {disbData?.advance_interest ? (
                        <Grid.Col span={12}>
                          <ViewFormData
                            label={`Advance Interest`}
                            value={
                              <Nums
                                withCopy
                                value={disbData?.advance_interest}
                              />
                            }
                            loading={loading}
                          />
                        </Grid.Col>
                      ) : null}
                      {disbData?.renewal_fee?.length ? (
                        <Grid.Col span={12}>
                          <ViewFormData
                            label="Renewal Fee"
                            value={
                              <Nums
                                withCopy
                                value={disbData?.renewal_fee[0]?.total_amount}
                              />
                            }
                            loading={loading}
                            action={
                              <ViewFeeDetailsWithGST
                                calculatedFee={disbData?.renewal_fee[0]}
                              />
                            }
                          />
                        </Grid.Col>
                      ) : null}
                      {disbData?.additional_deduction?.length ? (
                        <>
                          <Grid.Col span={12} mt="xs">
                            <TitleViewer title="Deductions" />
                          </Grid.Col>
                          {disbData?.additional_deduction?.map((item) => (
                            <Grid.Col span={12}>
                              <ViewFormData
                                label={item?.fee_name}
                                value={
                                  <Nums withCopy value={item?.total_amount} />
                                }
                                action={
                                  item?.fee_type == "fee" ? (
                                    <ViewFeeDetailsWithGST
                                      calculatedFee={item}
                                    />
                                  ) : (
                                    item?.fee_type === 'express_reload_fee' ? <ReloadFeePopover value={item} /> : null
                                  )
                                }
                              />
                            </Grid.Col>
                          ))}
                        </>
                      ) : null}
                      <Grid.Col span={12}>
                        <ViewFormDataContained
                          label="Net Disb Amount"
                          value={
                            <Nums withCopy value={disbData?.disbursed_amount} />
                          }
                          loading={loading}
                        />
                      </Grid.Col>
                    </Grid>
                  </>
                ) : null}
              </DataViewerWapper>
            </Grid.Col>
            <Grid.Col span={6}>
              <Box mb="sm">
                <DataViewerWapper>
                  <TitleViewer title="Bank Details of the Petromoney" />
                  <Grid style={{ paddingTop: "10px" }}>
                    <Grid.Col span={12}>
                      <ViewFormData
                        label="Account No"
                        value={sourceBank[0]?.account_number}
                        loading={sourceBankLoading}
                      />
                    </Grid.Col>
                    <Grid.Col span={12} sx={{ paddingBottom: "20px" }}>
                      <ViewFormData
                        label="IFSC"
                        value={sourceBank[0]?.ifsc}
                        loading={sourceBankLoading}
                      />
                    </Grid.Col>
                  </Grid>
                  {targetBank && (
                    <>
                      <TitleViewer title="Bank Details of the customer" />
                      <Grid style={{ paddingTop: "10px" }}>
                        <Grid.Col span={12}>
                          <ViewFormData
                            label="Account No"
                            value={targetBank?.account_number}
                            loading={sourceBankLoading}
                          />
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <ViewFormData
                            label="IFSC"
                            value={targetBank?.ifsc}
                            loading={sourceBankLoading}
                          />
                        </Grid.Col>
                      </Grid>
                    </>
                  )}
                </DataViewerWapper>
              </Box>
              <TitleViewer title="Enter Payment Details" />
              <Grid style={{ paddingTop: "20px" }}>
                <Grid.Col span={12}>
                  <ViewData
                    title={"Instrument Date"}
                    value={values?.instrument_date}
                    type={"date"}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ControllerSelect
                    label={"Instrument Type"}
                    name={"instrument_type"}
                    required
                    data={instrumentType}
                    control={control}
                    autoSelect
                    setValue={setValue}
                    errors={errors?.instrument_type?.message}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ControllerTextField
                    label={"Payment Reference No"}
                    name={"payment_reference_number"}
                    required
                    value={values?.payment_reference_number?.toUpperCase()}
                    control={control}
                    errors={errors?.payment_reference_number?.message}
                    autoComplete='off'
                  />
                </Grid.Col>
              </Grid>
              <Grid style={{ paddingTop: "20px" }}>
                <Grid.Col span={5}>
                  <TextComp label={"Reference Document"} />
                </Grid.Col>
                <Grid.Col span={2} sx={{ display: "flex" }}>
                  <ActionIcon onClick={() => setFileOpen(true)}>
                    <Upload />
                  </ActionIcon>
                </Grid.Col>
                {paymentDetails?.payment_reference_attachment_url || file ? (
                  <Grid.Col span={3}>
                    <AvatarCard
                      file={
                        file
                          ? file
                          : paymentDetails?.payment_reference_attachment_url
                      }
                      fileName={'Payment Reference file'}
                      handleDeleteFile={handleDeleteFile}
                    />
                  </Grid.Col>
                ) : null}
              </Grid>
            </Grid.Col>
            {disbData?.deduction_loan_payload?.selection?.length &&
              ProspectCode?.length ? (
              /** used to displey the table if it is available */
              <Grid.Col span={12}>
                <LoanRenewalTable
                  renewalLoans={ProspectCode}
                  loanDetails={disbData}
                />
              </Grid.Col>
            ) : null}
          </Grid>
          {/** This Action buttons component is used to display the buttons in the flow */}
          <ActionButtons
            status={paymentDetails?.status_value ? paymentDetails?.status_value : "draft"}
            base={'creation'}
            nextStage={stages?.next_stage}
            sendForReviewCB={handleReviewModal}
            sendForApprovalCB={handleReviewModal}
            saveCB={handleSubmit(onSubmit)}
            loading={{ save: buttonLoader?.save }}
            remarks={reviewTrail}
            remarksCB={() => setRemarksOpen(true)}
            isReady={Boolean(stages?.next_stage)}
            currentStatus={paymentDetails?.status_value}
            moduleApplicationId={paymentDetails?.id}
            moduleName={'payment'}
          />

          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={paymentDetails?.code}
            title={sucessfulmodal?.title}
            href={"/payment"}
            hreftext={"Back to list table"}
          />

          <Remarks
            open={open}
            setOpen={setOpen}
            type={stages?.next_stage}
            callback={onsendForReview}
            remarksTitle={
              stages?.next_stage == "review"
                ? "Review Remarks"
                : "Approval Remarks"
            }
            check={status}
            label={label}
            loading={remarksLoading}
            currentStatus={paymentDetails?.status_value}
            moduleApplicationId={paymentDetails?.id}
            moduleName={'Payment'}
          />

          <AddFile
            open={fileOpen}
            setOpen={setFileOpen}
            acceptedFileTypes={[...DEFAULT_SUPPORTED_IMAGE_TYPES, ...DEFAULT_SUPPORTED_PDF_TYPES]}
            callback={handleFileUpload}
            handleDelete={handleDeleteFile}
            file={file}
          />
        </Body>

        <ViewRemark
          open={remarksOpen}
          onClose={setRemarksOpen}
          remarkTrail={reviewTrail}
        />
      </Box>
    </>
  );
};

export default PaymentCreation;
