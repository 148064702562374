const SIZES = {
    // global sizes
    base: 8,
    font: 14,
    radius: 30,
    padding: 10,
    padding2: 12,
  
    // font sizes
    largeTitle: 50,
    h1: 26,
    h2: 22,
    h3: 20,
    h4: 18,
    h5: 16,
    h6: 13,
  
    body1: 26,
    body2: 22,
    body3: 20,
    body4: 18,
    body5: 16,
    body6: 14,
    small: 11
  };
  
  export default SIZES;
  