//loan services here
import { getAPICall, patchAPICall, postAPICall } from '.';

export const getApprovedIMD = (body = {}) => {
  /** used to get approved application details */
  return postAPICall("approved/applications", { body: body });
};

export const getApprovedIMDById = (id) => {
  /** used to get approved application details by id */
  return getAPICall(`approved/applications/${id}`);
};

export const getAllActiveLoans = ({ page, search, records = 10, status, entity, reload_type }) => {
  /** used to get all active loans for table */
  let url = `active-loans?page=${page}&records=${records}&status=${status}`;
  if (search) url = url + `&customer_id_name=${search}`;
  if (reload_type && reload_type !== 'all') url = url + `&reload_type=${reload_type}`;
  if (entity && entity !== 'all') url = url + `&entity_id=${entity}`;
  return getAPICall(url, { returnObject: true });
};

export const getAllClosedLoans = ({ page, search, records = 10 }) => {
  /** used to get all closed loans for table */
  return getAPICall(`closed-loans?page=${page}&customer_id_name=${search}&records=${records}`, { returnObject: true });
};

export const createNewLoan = (body = {}) => {
  /** used to create new loan */
  return postAPICall("loan", { body: body });
};

export const updateLoanById = (id, body) => {
  /** ussed to update the loan */
  return patchAPICall(`loan/${id}`, { body: body });
};

export const saveLoanAndsendForNextStage = (body) => {
  /** used to send the loan to next stage */
  return postAPICall(`loan/direct_save`, { body: body });
};

export const getLoanDetailsById = (id) => {
  /** used to get the loan details by id */
  return getAPICall(`loan/${id}`, { returnFirstItem: true });
};

export const getLoanByDealershipId = (custApplicationId, body, loanId) => {
  const handleValue = (data) => {
    const result = data?.map((item, i) => ({
      label: item.id,
      value: item.id,
      visible: i == 0 ? false : true,
      penal_f: item?.outstanding_interest_amount_during_penalty + item?.outstanding_addon_spread_amount_during_penalty + item?.outstanding_spread_amount_during_penalty,
      ...item,
    }));
    return result || [];
  };

  let query = `/disbursed-loans/${custApplicationId}`;
  if (loanId) query = query + `?loan_id=${loanId}`
  /** used to get the dealership details by id */
  return postAPICall(query, {
    body: body,
    selectValue: handleValue,
  });
};

export const getDeductionLoansbyID = (id, body) => {
  const handleValue = (data) => {
    const result = data?.map((item, i) => ({
      label: item.id,
      value: item.id,
      id: item.loan_id,
      visible: true,
      penal_f: item?.outstanding_addon_spread_amount_during_penalty + item?.outstanding_interest_amount_during_penalty + item?.outstanding_spread_amount_during_penalty,
      ...item,
    }));
    return result || [];
  };

  /** used to get the deduction loan details */
  return postAPICall(`/deduction/loan/details/${id}`, {
    body: body,
    selectValue: handleValue,
  });
};

export const sendLoanforNextStage = (id, body) => {
  /** used to send loan for next stage by id */
  return postAPICall(`loan/${id}/status/change`, { body: body });
};

export const sendForPushback = (id, body) => {
  /** used to pushback the loan */
  return postAPICall(`loan/${id}/pushback`, { body: body });
};

export const sendForReject = (id, body) => {
  /** used to reject the loan */
  return postAPICall(`loan/${id}/rejected`, { body: body });
};

export const sendLoansForCancellation = (id, body) => {
  /** used to cancel the loan */
  return postAPICall(`loan/${id}/cancelled`, { body: body });
};

export const getAllRemarks = (id) => {
  /** used to get the remarks for loan by id */
  return getAPICall(`loan/${id}/remark`);
};

export const getFeeTypesByProductIdforLoan = (productId, customerAppId) => {
  const handleValue = (data) => {
    const result = data?.map((fee) => ({
      label: fee.fee_name,
      value: fee.fee_name,
      ...fee,
    }));
    return result || [];
  };

  /** used to get fee by product id */
  return getAPICall(`product/${productId}/fees?customer_application_id=${customerAppId}`, { selectValue: handleValue });
};

export const getRenewalFee = (custAPPId) => {
  /** used to get the renewal fee for customer */
  return getAPICall(`/customer/renewal-fee/${custAPPId}`, { returnFirstItem: true });
};
