import React, { useState } from "react";
import { Box, Button, Divider } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Plus } from "tabler-icons-react";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../../../components/CommonComponents/Table/ReactTable";
import { getAllReasonsWaiver } from '../../../../services/waiver.service';
import Reason from './Reason';
import { dateFormat } from '../../../../components/DateFormat/dateFormat';
import { DrawerModal } from "../../../../components/CommonComponents/Drawer/DrawerModal";

const ReasonTabel = () => {
  const columnHelper = createColumnHelper();
  const [reasonFormData, setReasonFormData] = useState();
  const [reasonForm, formHandler] = useDisclosure(false);
  /* use-disclosure hook manages boolean state. It provides open, close and toggle */
  const { data: reasons = [], isLoading } = useQuery(
    ['all-reason'],
    () => getAllReasonsWaiver(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const columnData = [
    columnHelper.accessor("name", {
      header: "Name",
      cell: (value) => (
        <span
          style={{ color: "#0063FF", cursor: "pointer" }}
          onClick={() => {
            setReasonFormData(value.row.original);
            formHandler.open();
          }}
        >
          {value.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor("created_date", {
      header: "Created On",
      cell: (value) => dateFormat(value.getValue(), 'MMM DD YYYY'),
    }),
  ];

  const handleClose = () => {
    formHandler.close();
    setReasonFormData();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Button
          leftIcon={<Plus size={16} />}
          variant="subtle"
          onClick={() => formHandler.open()}
        >
          Add Reason
        </Button>
      </Box>
      <ReactTable
        title="Reason"
        rowData={reasons}
        columnData={columnData}
        loading={isLoading}
      />
      <DrawerModal
        open={reasonForm}
        onClose={() => {
          handleClose();
        }}
        position="right"
        padding="xl"
        title={reasonFormData?.name ? reasonFormData?.name : "Add New Reason"}
        overlayProps={{ blur: 2 }}
        size="43%"
      >
        <Divider />
        <Reason
          id={reasonFormData?.id}
          formData={reasonFormData}
          onClose={() => handleClose()}
        />
      </DrawerModal>
    </>
  );
};

export default ReasonTabel;
