import { getAPICall, patchAPICall } from ".";
import { postAPICall } from './postAPICall';

export const searchCustomerApplicationId = (custIdName, signal) => {
  const handleValue = (n) => {
    const result = n?.map((item) => ({
      label: `${item.customer_name} (${item.customer_code})`,
      value: item.customer_code,
      ...item,
    }));
    return result || [];
  };
  return getAPICall(
    `customer-application-search?customer_name_code=${custIdName}`,
    { selectValue: handleValue, signal }
  );
};

export const getCustomerStatementOfAccounts = ({
  customerApplicantId,
  fromDate,
  toDate,
}) => {
  return getAPICall(
    `statement-of-accounts?customer_application_id=${customerApplicantId}&from_date=${fromDate}&to_date=${toDate}`,
    { returnObject: true }
  );
};

export const getCreditReloadRequestByCustomerAppId = ({ customer_application_id, crr_id }) => {
  const returnModifiedData = (data) => {
    let result = data?.map((item) => ({
      bank: `${item?.bank_name}-(${item?.bank_account_number})`,
      ...item
    }))
    return result
  }
  let query = 'credit-reload-request';
  if (customer_application_id) query = query + `?customer_application_id=${customer_application_id}`;
  if (crr_id) query = query + `?crr_id=${crr_id}`;
  return getAPICall(query, { selectValue: returnModifiedData });
};

export const getFeeAmountWithGST = (customerAppId, body) => {
  return postAPICall(`loan/processing-fee/${customerAppId}`, { body, returnFirstItem: true })
}

// to get the active and inactive bank to display by customer id and bank id
export const getCustomerBankById = ({ customerId, bankId }) => {
  let query = `customer-bank?customer_id=${customerId}`;
  if (bankId) query = query + `&bank_id=${bankId}`;
  return getAPICall(query);
}

// get all customers data
export const getAllCustomersData = ({ page, search, records = 10, entity, customer_application_id, signal }) => {
  let url = `/customer/application/meta?&page=${page}&records=${records}`
  if (search) url = url + `&customer_id_name=${search}`;
  if (customer_application_id) url = url + `&customer_application_id=${customer_application_id}`;
  if (entity && entity !== 'all') url = url + `&entity_id=${entity}`;
  return getAPICall(url, { returnObject: true, signal });
}

// get all customers data
export const getCRROverrideRemarks = (appId) => {
  let url = `/crr_override_applications/${appId}/remarks`
  return getAPICall(url, { returnObject: true });
}

// change the crr check
export const overrideCRRCheck = (body) => {
  let url = `/override-crr-validation`
  return postAPICall(url, { body });
}

// get the enhancement details
export const getEnhancementDetails = (appId) => {
  let url = `/customer/enhancement/${appId}/meta`
  return getAPICall(url);
}

/**
 * Retrieves the customer tranche details for the given customer ID.
 *
 * @param {number} custId - The ID of the customer.
 * @param {number} no_of_months - The number of months to get the data.
 */
export const getCustomerTrancheDetails = (custId, no_of_months) => {
  let url = `customer/repayment-behavior/${custId}`;
  if (no_of_months) url = url + `?no_of_months=${no_of_months}`;
  return getAPICall(url);
}


/**
 * Retrieves the customer tranche amount utilization percentage.
 *
 * @param {number} custId - The ID of the customer.
 * @param {number} no_of_months - The number of months to get the data.
 */
export const getUtilizationPercentageDetails = (custId, no_of_months = 0) => {
  let url = `customer/utilization-percentage/${custId}`;
  if (no_of_months) url = url + `?no_of_months=${no_of_months}`;
  return getAPICall(url, { returnFirstItem: true });
}

export const syncCustomerChangesById = ({ customer_application_id, is_merge }) => {
  let query = `customer/application/meta/${customer_application_id}/sync`;
  if (is_merge === true) query += `?action=merge`;
  return getAPICall(query, { returnObject: true })
}

export const getReceiptCountLimit = ({ custAppId }) => {
  return getAPICall(`customer-applications/${custAppId}/receipt-limit`);
}

export const updateReceiptCountLimit = ({ body }) => {
  return postAPICall(`update/customer-applications/receipt-limit`, { body, returnObject: true });
}

// to get the last 10 tranche details.
export const getLatestTrancheDetails = ({ customerId }) => {
  return getAPICall(`customer/loans/${customerId}`);
}