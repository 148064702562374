import { apiCallProtected } from '../api/axios'
import { URL } from '../api/serverUrls'

export const loginLMS = (mobile, password) => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .post(
        URL.loginUser,
        { username: mobile, password },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then((response) => {
        if (response?.status === 'success') {
          resolve(response?.data)
        } else {
          reject(response?.message)
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error)
      })
  })
}

export const getUsersList = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get('users')
      .then(({ status, data, message }) => {
        if (status === 'SUCCESS') {
          resolve(data)
        } else {
          reject(message)
        }
      })
      .catch(reject);
  })
}
