import { Button, Text } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import Body from '../../components/Body/Body';
import DataTableWrapper from '../../components/CommonComponents/Table/DataTableWrapper';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import WavierDrawer from './WavierDrawer';
import { getAllWaiver } from '../../services/waiver.service';
import Nums from '../../components/Body/Nums';
import { useQuery } from '@tanstack/react-query';
import EntityFilter from './../../components/CommonComponents/Filter/EntityFilter';
import {
    APPROVED,
    CANCELLED,
    IN_APPROVAL,
    IN_REVIEW,
    OPEN,
    REJECTED,
    CANCELLATION_REVIEW,
    CANCELLATION_APPROVAL,
} from '../../constants/status';
import useModuleRecordCountStore from '../../store/moduleRecordCountStore';

const WaiverTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const columnHelper = createColumnHelper();
  const { moduleCount } = useModuleRecordCountStore();
  const [addIMD, setAddIMD] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [records, setRecords] = useState({label: 'show 10', value: 10});
  // get the search params
  const [searchParams, setSearchParams] = useSearchParams();
  const params = {}
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  /** used to change the page to 1 while the search us used 
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1)
  }, [search, searchParams.get("status"), searchParams.get("entity"), records])

  const { data: waiverData = [], isLoading } = useQuery(
    ['waiver-table', params, page, search, records], 
    () => getAllWaiver({...params, page, search, records: records?.value}), 
    {
      enabled: Boolean(searchParams.get("status") && searchParams.get("status") !== "none"),
      refetchOnWindowFocus: false
    }
  );

  const onRowClick = (i) => {
    if (i?.status_value == OPEN) {
      navigate(`/waiver/view/${i?.customer_id}/${i?.loan_id}/${i?.id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == IN_APPROVAL || i?.status_value == IN_REVIEW) {
      navigate(`/waiver/review/${i?.customer_id}/${i?.id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == APPROVED) {
      navigate(`/waiver/approved/${i?.customer_id}/${i?.id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == REJECTED) {
      navigate(`/waiver/rejected/${i?.customer_id}/${i?.id}`, { state: { from: location.pathname } });
    } else if(i?.status_value == CANCELLED) {
      navigate(`/cancellation/waiver-cancelled/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if(i?.status_value == CANCELLATION_REVIEW || i?.status_value == CANCELLATION_APPROVAL) {
      navigate(`/cancellation/waiver-cancel/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    }
  };

  const onClickAction = (value) => {
    return (
      <span
        style={{ color: '#0063FF', cursor: 'pointer' }}
      >
        {value.getValue()}
      </span>
    );
  };

  const tableAction = (
    <Button
      variant='filled'
      color='primary'
      leftIcon={<Plus size={18} />}
      size='sm'
      onClick={() => setAddIMD(true)}
    >
      Create Waiver
    </Button>
  );

  const columnData = [
    columnHelper.accessor('prospect_code', {
      header: 'Tranche Code',
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor('customer_name', {
      header: 'Customer Name',
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor('product_name', {
      header: 'Product Type',
      cell: (value) => value.getValue(),
    }),
    columnHelper.accessor('waiver_amount', {
      header: 'Waiver Amount',
      cell: (value) => <Nums value={value.getValue()} />,
    }),
    columnHelper.accessor('disbursed_amount', {
      header: <Text sx={{textAlign: 'right'}}t>Disbursed Amount</Text>,
      cell: (value) => <Nums value={value.getValue()} styles={{textAlign: 'right'}} />,
    }),
    columnHelper.accessor('created_date', {
      header: 'Created Date',
      cell: (value) => dateFormat(value.getValue()),
    }),
    columnHelper.accessor('maker_name', {
      header: 'Created By',
    }),
  ];

  return (
    <Body background={false}>
      <DataTableWrapper
        title='Waiver'
        filter={false}
        rowData={waiverData?.data}
        columnData={columnData}
        onRowClick={onRowClick}
        noDataText="No waiver details yet!"
        noDataSubText='This Section will contain list of created waiver details.'
        useApiPagination
        totalNoOfPages= {Math.ceil(parseInt(moduleCount?.count)/records?.value)}
        totalNoOfRecords={moduleCount?.count}
        page={page}
        setPage={setPage}
        rowLength={records}
        setRowLength={setRecords}
        apiSearch={setSearch}
        showStatus
        module_name='waiver'
        addDataCallback={() => setAddIMD(true)}
        /** used to have tab in waiver table to filter active and closed loans */
        options={<EntityFilter useQueryParams />}
        // when the status is none then the loading is set to false other wise it will dependent on isLoading
        loading={searchParams.get("status") !== "none" ? isLoading : searchParams.get("status") === null ? true : false}
        actions={tableAction}
      />
      <WavierDrawer open={addIMD} onClose={() => setAddIMD(false)} />
    </Body>
  );
};

export default WaiverTable;
