import React, { useEffect, useState } from "react";
import Body from "../../components/Body/Body";
import DataTableWrapper from "../../components/CommonComponents/Table/DataTableWrapper";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import { getReportbyReportName, getReportPaginationDetails, getReportSummaryDetails } from "../../services/report.service";
import {
  ActionIcon,
  Box,
  Button,
  createStyles,
  Popover,
  Text,
  Tooltip,
  SegmentedControl,
  Group,
} from "@mantine/core";
import Select, { components } from 'react-select';
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { useParams } from "react-router-dom";
import { Calendar, Download } from "tabler-icons-react";
import { useQuery } from "@tanstack/react-query";
import { PDF_TEXT_MESSAGE } from '../../constants';
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { ReportFilterKey } from "../../constants/symbols";
import { generateReportHeaders } from "./generateReportHeaders";
import EntityFilter from "../../components/CommonComponents/Filter/EntityFilter";
import useSystemConfigStore from "../../store/systemConfigStore";
import SummaryDetails from "./SummaryDetails";
import { getReportObjectByReportName } from "./utils/getReportStateByName";
import { openFile } from "../../utils/openFile";
import TimeFilterTATReport from "./Custom Filter/TimeFilterTATReport";
import { reportDownload } from "./reportDownload/reportDownload";

const createStyle = createStyles({
  dateSelection: {
    background: "#FFFF",
    display: "flex",
    justifyContent: "center",
    width: "260px",
    padding: "8px",
    columnGap: "5px",
    borderRadius: "5px",
    marginLeft: "10px",
    cursor: "pointer",
    border: "1px solid #e1e1e1",
  },
});

const groupStyles = {
  borderBottom: `2px dotted gray`,
};

/**
 * Renders a group component with given props.
 * Used to style the group selection in react select component.
 * This is only applicable for group selection.
 *
 * @param {object} props - The props for the group component.
 * @return {JSX.Element} The rendered group component.
 */
const GroupSelect = (props) => (
  <div style={groupStyles}>
    <components.Group {...props} />
  </div>
);

const ReportTable = ({ withEntityFilter }) => {
  const { reportName } = useParams();

  const reportStateObj = getReportObjectByReportName(reportName)

  const systemDate = useSystemConfigStore(store => store.config);
  const { classes } = createStyle();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(reportStateObj?.defaultValue);
  const [reportDownloadLoading, setReportDownloadLoading] = useState(false);
  const [records, setRecords] = useState({ label: "Show 10", value: 10 });
  const [modalOpen, setModalOpen] = useState(false);
  const [customFilterObj, setCustomFilterObj] = useState({ time: 4 });
  const [searchData, setSearchData] = useState({
    from_date: dateFormat(
      new Date(systemDate?.current_system_date),
      "YYYY-MM-DD"
    ),
    to_date: dateFormat(
      new Date(systemDate?.current_system_date),
      "YYYY-MM-DD"
    ),
    download_csv: "no",
  });
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  /** used to change the page to 1 while the search us used
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1);
  }, [search, customFilterObj]);

  /** used to get the report by sending the report name and from_date, to_date and csv */
  const { data: reportData = {}, isLoading, isFetching } = useQuery(
    [`report-details-${reportStateObj?.report_name}`, status, searchData, page, search, records, customFilterObj],
    ({ signal }) =>
      getReportbyReportName(
        ["detailed_receipt", "unidentified_receipt_report", "borrowers_report"].includes(reportStateObj?.report_name) ? status : reportStateObj?.report_name,
        {
          ...searchData,
          tat_range: customFilterObj?.value?.find(i => i?.type === 'time')?.value || null,
          tat_cut_of_time: dateFormat(new Date(`01-01-2023 ${customFilterObj?.time}:00 am`), 'hh:mm'),
          loan_created_time: customFilterObj?.value?.find(i => i?.type === 'created')?.value || null,
          loan_type: status
        },
        { page, search, records: records?.value },
        signal
      ),
    {
      select: (data) => {
        return {
          ...data,
          headers: generateReportHeaders(data?.headers),
        };
      },
      enabled: Boolean(systemDate?.current_system_date),
      refetchOnWindowFocus: false,
    }
  );

  /** used to get the page count and total records */
  const { data: paginationDetails = {} } = useQuery(
    [`pagination-${reportStateObj?.report_name}`, status, search, records, searchData, customFilterObj],
    ({ signal }) => getReportPaginationDetails(
      {
        report_name: ["detailed_receipt", "unidentified_receipt_report", "borrowers_report"].includes(reportStateObj?.report_name) ? status : reportStateObj?.report_name,
        search,
        records: records?.value
      },
      {
        ...searchData,
        tat_range: customFilterObj?.value?.find(i => i?.type === 'time')?.value || null,
        tat_cut_of_time: dateFormat(new Date(`01-01-2023 ${customFilterObj?.time}:00 am`), 'hh:mm'),
        loan_created_time: customFilterObj?.value?.find(i => i?.type === 'created')?.value || null,
        loan_type: status
      },
      signal
    ),
    {
      refetchOnWindowFocus: false,
    }
  )

  /** used to get the page count and total records */
  const { data: summaryDetails = [], refetch: summaryRefetch } = useQuery(
    [`pagination-${reportStateObj?.report_name}`, status, search, searchData, customFilterObj],
    ({ signal }) => getReportSummaryDetails(
      {
        report_name: ["detailed_receipt", "unidentified_receipt_report", "borrowers_report"].includes(reportStateObj?.report_name) ? status : reportStateObj?.report_name,
        search,
      },
      {
        ...searchData,
        tat_range: customFilterObj?.value?.find(i => i?.type === 'time')?.value || null,
        tat_cut_of_time: dateFormat(new Date(`01-01-2023 ${customFilterObj?.time}:00 am`), 'hh:mm'),
        loan_created_time: customFilterObj?.value?.find(i => i?.type === 'created')?.value || null,
        loan_type: status
      },
      signal
    ),
    {
      select: (data) => {
        if (reportStateObj?.report_name === 'tat_report') {
          return data?.data?.filter(e => e?.overall_status)?.map((item) => {
            return {
              label: <span style={{ textTransform: 'capitalize' }}>{item?.overall_status?.split(" ")?.includes('approved') ? item?.overall_status?.replace(/in|-|_/g, "") : item?.overall_status?.replace(/_|-/g, " ")}</span>,
              value: item?.count,
              type: 'number',
            }
          });
        }
        const summaryData = data?.data?.[0];
        return data?.headers?.map(d => {
          return {
            label: d?.name,
            value: summaryData[d?.key],
            type: d?.type,
          }
        })
      },
      enabled: Boolean(reportStateObj?.report_name),
    }
  )

  /** used to set the default date as system date when the system date context is loaded */
  useEffect(() => {
    if (systemDate?.current_system_date) {
      setState([
        {
          startDate: new Date(systemDate?.current_system_date),
          endDate: new Date(systemDate?.current_system_date),
          key: "selection",
        },
      ]);
    }
  }, [systemDate?.current_system_date]);

  /** used to handle the date */
  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setState([selection]);
  };

  /** used to handle the date filter */
  const handleFilter = () => {
    setModalOpen(false);
    setSearchData(oldSearchData => ({
      ...oldSearchData,
      from_date: dateFormat(state[0]?.startDate, "YYYY-MM-DD"),
      to_date: dateFormat(state[0]?.endDate, "YYYY-MM-DD"),
      download_csv: "no",
    }))
  };

  /** used to handle the file download */
  const handleDownload = () => {
    if (reportData?.report_url) {
      openFile(reportData?.report_url);
    } else {
      setReportDownloadLoading(true);
      getReportbyReportName(
        ["detailed_receipt", "unidentified_receipt_report", "borrowers_report"].includes(reportStateObj?.report_name) ? status : reportStateObj?.report_name,
        {
          ...searchData,
          download_csv: 'yes',
          tat_range: customFilterObj?.value?.find(i => i?.type === 'time')?.value || null,
          tat_cut_of_time: dateFormat(new Date(`01-01-2023 ${customFilterObj?.time}:00 am`), 'hh:mm'),
          loan_created_time: customFilterObj?.value?.find(i => i?.type === 'created')?.value || null,
          loan_type: status
        },
        { page, search, records: records?.value },
      )
        .then((res) => {
          displayNotification({
            message: 'Report will download shortly',
            variant: 'warning',
            loading: true,
            id: 'report-loading',
            autoClose: false
          });
          reportDownload({id: res?.report_download_id})
          // openFile(res?.report_url);
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' });
        })
        .finally(() => {
          setReportDownloadLoading(false);
        });
    }
  };

  const tableAction = (
    <Group spacing={'6px'} ml={'6px'}>
      {reportData?.data?.length ? (
        <Tooltip
          label={<Text size={"xs"}>Download Report</Text>}
          color={"green"}
          transitionProps={{ transition: 'pop', duration: 300 }}
          withArrow
        >
          <ActionIcon
            onClick={handleDownload}
            loading={reportDownloadLoading}
          >
            <Download color={"#40c057"} />
          </ActionIcon>
        </Tooltip>
      ) : null}

      {/* tat time filter */}
      {["tat_report"].includes(reportStateObj?.header) ? (
        <TimeFilterTATReport
          onTimeChange={(val) => setCustomFilterObj({ ...customFilterObj, time: val })}
          onChange={(val) => setCustomFilterObj({ ...customFilterObj, value: val })}
          time={customFilterObj?.time}
          value={customFilterObj?.value}
        />
      ) : null}

      {
        reportStateObj?.hideEntityFilter ? null :
          (withEntityFilter) ? (
            <EntityFilter
              value={searchData}
              onChange={v => {
                setSearchData(oldSearchData => ({
                  ...oldSearchData,
                  entity_id: v === 'all' ? undefined : v
                }))
              }}
            />
          ) : null
      }

      {reportStateObj?.hideDateFilter
        ? (null)
        : (
          <Popover
            position="bottom-end"
            shadow="md"
            opened={modalOpen}
            onChange={setModalOpen}
          >
            <Popover.Target>
              <Text
                onClick={() => setModalOpen(true)}
                size={"12px"}
                className={classes?.dateSelection}
                m={0}
              >
                <Calendar strokeWidth={1.5} color={"gray"} size={"20"} />{" "}
                <strong>From:</strong> {dateFormat(state[0]?.startDate)}{" "}
                <strong style={{ marginLeft: "6px" }}>To:</strong>{" "}
                {dateFormat(state[0]?.endDate)}
              </Text>
            </Popover.Target>
            <Popover.Dropdown>
              <Box sx={{ display: "flex", flexDirection: "column", }}>
                <DateRange
                  onChange={handleOnChange}
                  months={1}
                  maxDate={new Date(systemDate?.current_system_date ? systemDate?.current_system_date : new Date())}
                  ranges={state}
                  retainEndDateOnFirstSelection
                  direction="horizontal"
                />
                <Button onClick={handleFilter}>Apply</Button>
              </Box>
            </Popover.Dropdown>
          </Popover>
        )
      }
    </Group>
  );

  return (
    <Body background={false}>
      <DataTableWrapper
        title={reportStateObj?.title + " Report"}
        localKey={reportStateObj?.options ? status : ReportFilterKey[reportStateObj?.header]}
        noDataText={`No ${reportStateObj?.title} yet!`}
        noDataSubText={`This Section will contain list ${reportStateObj?.title} details.`}
        actions={tableAction}
        addDataOption={false}
        rowData={reportData?.data || []}
        styles={{ overflowX: "auto", whiteSpace: "nowrap", maxWidth: "100vw" }}
        columnData={reportData?.headers || []}
        useApiPagination
        totalNoOfPages={paginationDetails?.total_number_of_pages}
        totalNoOfRecords={paginationDetails?.total_number_of_records}
        page={page}
        options={
          <>
            {["detailed_receipt", "borrowers_report", "tat_report"].includes(reportStateObj?.header) ? (
              <SegmentedControl
                value={status}
                onChange={setStatus}
                mr={"6px"}
                size={"md"}
                styles={(theme) => ({
                  root: {
                    background: "white",
                    padding: "3px",
                  },
                  label: {
                    fontSize: "12px",
                  },
                })}
                color={'green'}
                data={reportStateObj?.options}
              />
            ) : ["unidentified_receipt_report"].includes(reportStateObj?.header) ? (
              <Box sx={{ minWidth: 200 }}>
                <Select
                  value={reportStateObj?.selectOption?.find(e => e?.value === status) || ''}
                  onChange={(val) => setStatus(val.value)}
                  options={reportStateObj?.options}
                  components={{ GroupSelect }}
                  styles={{ menu: (provided) => ({ ...provided, zIndex: 9999, }), option: (provided) => ({ ...provided, fontSize: '13px', padding: '5px 15px 5px 15px' }), control: (provided) => ({ ...provided, fontSize: '13px' }) }}
                />
              </Box>
            ) : null}
          </>
        }
        setPage={setPage}
        apiSearch={setSearch}
        rowLength={records}
        summaryDetailsJSX={summaryDetails?.length ? <SummaryDetails data={summaryDetails} /> : null}
        setRowLength={setRecords}
        loading={isLoading || isFetching}
      />
    </Body>
  );
};

export default ReportTable;
