import { getAPICall } from "./getAPICall"
import { patchAPICall } from "./patchAPICall";
import { postAPICall } from "./postAPICall";


export const getAllRefundData = ({page, search, records = 10, status, entity}) => {
  let url = `/refunds?page=${page}&records=${records}&status=${status}`
  if(search) url = url + `&customer_id_name=${search}`;
  if(entity && entity !== 'all') url = url + `&entity_id=${entity}`;
  return getAPICall(url, { returnObject: true })
}

export const getEligibleRefundDetails = (body) => {
  return postAPICall(`refund/eligible/customers`, { body })
}

export const getAllRefundRemarksById = (id) => {
  return getAPICall(`refund/${id}/remark`)
}

export const createRefund = (body) => {
  return postAPICall(`refund`, { body })
}

export const updateRefund = (id, body) => {
  return patchAPICall(`refund/${id}`, { body })
}

export const refundSaveAndSendForNextStage = (body) => {
  return postAPICall(`refund/direct_save`, { body })
}

export const refundStatusChange = (id, body) => {
  return postAPICall(`refund/${id}/status/change`, { body })
}

export const refundReject = (id, body) => {
  return postAPICall(`refund/${id}/rejected`, { body })
}

export const refundPushback = (id, body) => {
  return postAPICall(`refund/${id}/pushback`, { body })
}

export const refundCancel = (id, body) => {
  return postAPICall(`refund/${id}/cancelled`, { body })
}

export const getRefundById = (id) => {
  return getAPICall(`refund/${id}`)
}