import { Grid, Text, Title } from '@mantine/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
import TitleComp from '../TextComponent/TitleComp';

const multiValueContainer = ({ selectProps, data }) => {
  const label = data?.label;
  const allSelected = selectProps?.value;
  const index = allSelected?.length && allSelected?.findIndex((selected) => selected?.label === label);
  const isLastSelected = index === allSelected?.length - 1;
  const labelSuffix = isLastSelected ? '' : ', ';
  const val = `${label}${labelSuffix}`;
  return val;
};

// { 
//   ...menu: (provided) => ({ ...provided, zIndex: 9999 }), 
//   ...option: (provided) => ({ ...provided, fontSize: '14px', padding: '5px 15px 5px 15px' }), 
// control: (provided) => ({ ...provided, fontSize: '14px'}) }
const styles = {
  control: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '14px',
    padding: '5px 15px 5px 15px'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    maxHeight: '29px',
    padding: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'initial'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    // '> div': {
    //   // padding: 5
    // }
  }),
  indicatorContainer: (provided) => ({
    ...provided,
  })
}

const Option = (props) => {
  return (
    <components.Option {...props} >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label>&nbsp;{props.label}</label>
      </div>
    </components.Option>
  );
};

const ControllerMultiSelect = ({
  name,
  label,
  placeholder,
  control,
  value = [],
  required = false,
  data,
  minHeight = '57px',
  displayLabel = true,
  spanSize = 7,
  errors,
  ...props
}) => {
  const arr = value?.map(e => data?.find((r) => r.value === e));
  return (
    <Grid sx={{ display: 'flex', alignItems: 'center' }}>
      {displayLabel ?
        <Grid.Col span={5} sx={{ minHeight: '57px', padding: '10px 8px 0 8px', display: 'flex' }}>
          <TitleComp label={label} />
          {required ? <Text ml={'4px'} color="red">*</Text> : null}
        </Grid.Col> : null
      }
      <Grid.Col span={spanSize} sx={{ minHeight: minHeight, padding: '0 8px 0 8px' }}>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value, name, ref, ...rest } }) => (
            <Select
              id={name}
              placeholder={placeholder}
              isMulti={true}
              isSearchable={true}
              isClearable
              hideSelectedOptions={true}
              closeMenuOnSelect={false}
              styles={styles}
              options={data}
              value={arr}
              onChange={(val) => {onChange(val.map(c => c.value))}}
              components={{
                MultiValueContainer: multiValueContainer,
                Option,
              }}
              errors
              {...props}
              {...rest}
            />
          )}
        />
        {errors && <Text size={'xs'} sx={{ color: '#f13e3e' }}>{errors}</Text>}
      </Grid.Col>
    </Grid>
  );
};

export default ControllerMultiSelect;
