import { rulesForUserRoles } from "../config/accessControl";

export const isAllowed = (groupId, resource_list, action_id) => {
  const data = rulesForUserRoles(groupId)
  return data[action_id] === 'EFFECT_ALLOW';
}


const CheckAllowed = ({ currentUser, children, resource = '', action }) => {
  return (
    isAllowed(currentUser, resource, action) &&
    <>
      {children}
    </>
  )
}


export default CheckAllowed;