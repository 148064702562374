import Nums from "../../components/Body/Nums";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import { Text } from "@mantine/core";

export const VictoryCustomTooltipSpline = (props) => {
  const { datum, y, x } = props;
  return (
    <g style={{ pointerEvents: "none", zIndex: '99' }}>
      <foreignObject
        x={x > 600 ? x - 200 : x}
        y={y > 150 ? y - 50 : y}
        width="200"
        height="10"
        style={{ overflow: "visible" }}
      >
        <div
          style={{
            background: "#Ffff",
            borderRadius: "5px",
            zIndex: 9999,
            boxShadow:
              "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
          }}
        >
          <Text fw={600} style={{ color: "green", fontSize: '9px' }} ml={"sm"}>
            {dateFormat(datum?.xName, "DD-MM-YYYY") + ' ' + dateFormat(datum?.xName, "ddd")}
          </Text>
          <Text sx={{ display: "flex", fontSize: '9px' }} ml={"sm"}>
            {"Due Amount:"} <Nums value={datum?._y}  />(<Nums value={datum?._y/10000000} />Cr)
          </Text>
        </div>
      </foreignObject>
    </g>
  );
};
