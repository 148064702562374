import { Box, Divider, Text, Timeline } from '@mantine/core';
import React, { useState } from 'react';
import DialogModal from '../CommonComponents/Modal/DialogModal';
import { dateFormat } from '../DateFormat/dateFormat';
import { DrawerModal } from '../CommonComponents/Drawer/DrawerModal';

const ViewRemark = ({ open, onClose, remarkTrail }) => {
  
  const [comment, setComment] = useState();

  return (
    <>
      <DrawerModal
        open={open}
        onClose={() => onClose(false)}
        transitionProps={{ duration: 600 }}
        title="Remarks"
        position='right'
        padding='xl'
        size='30%'
      >          
        <Divider  />
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: '90%',
          }}
          my={'md'}
        >
          <Box sx={{ flex: 1, overflow: 'auto', overflowX: 'hidden' }}>
            <Timeline radius='md' bulletSize={12} lineWidth={2}>
              {[...remarkTrail]?.reverse()?.map((remark, index) => {
                return (
                  <Timeline.Item
                    title={
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'start',
                        }}
                      >
                        <Box mt={'-2px'}>
                          <Text size='12px'><strong>{remark.creator_name}</strong></Text>
                          {remark?.remark_type ? <Text sx={{ fontSize: 9, marginTop: '-5px' }}>
                            ({remark?.remark_type})
                          </Text> : null}
                        </Box>
                        <Box mt={'-2px'}>
                          <Text sx={{ fontSize: 9 }}>
                            {dateFormat(remark?.created_date, 'DD MMM YY hh:mm A')}
                          </Text>
                        </Box>
                      </Box>
                    }
                  > 
                    {remark?.reason ? 
                      <Text size='xs'>
                        Reason: {remark?.reason}
                      </Text> : null
                    }
                    <Text
                      size='xs'
                      sx={{
                        maxWidth: '80%',
                        maxHeight: 90,
                        overflow: 'hidden',
                      }}
                      dangerouslySetInnerHTML={{ __html: remark?.remarks }}
                    />
                    {remark?.remarks?.length > 250 && (
                      <Text
                        size='xs'
                        variant='link'
                        onClick={() => setComment(remark)}
                        sx={{ cursor: 'pointer' }}
                      >
                        ...view more
                      </Text>
                    )}
                  </Timeline.Item>
                );
              })}
            </Timeline>
            <DialogModal
              open={comment}
              onClose={() => setComment()}
              title='Comment'
              size='lg'
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <Text size='sm'>
                  <strong>{comment?.creator_name}</strong>
                </Text>
                <Box>
                  <Text sx={{ fontSize: 9 }} color='dimmed'>
                    {dateFormat(comment?.created_date, 'DD MMM YY HH:MM')}
                  </Text>
                  <Text sx={{ fontSize: 9 }} color='dimmed'>
                    {comment?.remark_type}
                  </Text>
                </Box>
              </Box>
              <Text
                size='sm'
                dangerouslySetInnerHTML={{ __html: comment?.remarks }}
              />
            </DialogModal>
          </Box>
        </Box>
      </DrawerModal>
    </>
  );
};

export default ViewRemark;
