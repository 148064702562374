import { Grid, Table, Text, Title, Tooltip } from '@mantine/core'
import { useListState } from '@mantine/hooks'
import { sumBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { COLORS } from '../../../constants'
import ImdStyle from '../../../pages/IMD/Imd.styles'
import Nums from '../../Body/Nums'
import { dateFormat } from '../../DateFormat/dateFormat'

const LoanRenewalTable = ({ renewalLoans, loanDetails }) => {

  const [valideData, setValideData] = useState({});
  const [verticalSelection, verticalHandlers] = useListState([false,false, false, false, false, false])

  useEffect(() => {
    /** used to get the avilable head */
    setValideData({
        'if_principle': sumBy(renewalLoans, 'outstanding_principle'),
        'if_interest': sumBy(renewalLoans, 'outstanding_interest'),
        'if_spread': sumBy(renewalLoans, 'outstanding_spread'),
        'if_addon_spread': sumBy(renewalLoans, 'outstanding_addon_spread'),
        'if_interest_penal': sumBy(renewalLoans, 'outstanding_interest_amount_during_penalty'),
        'if_spread_penal': sumBy(renewalLoans, 'outstanding_spread_amount_during_penalty'),
        'if_addon_penal': sumBy(renewalLoans, 'outstanding_addon_spread_amount_during_penalty'),
        'if_penal_f': sumBy(renewalLoans, 'penal_f'),
        'if_penal': sumBy(renewalLoans, 'outstanding_penal_interest_amount'),
        'dpd': sumBy(renewalLoans, 'days_past_due'),
    })
    
    /** used to mark the selected payhead */
    const payHead = ['outstanding_principle', 'outstanding_interest', 'outstanding_spread', 'outstanding_addon_spread', 'outstanding_interest_amount_during_penalty', 'outstanding_penal_interest_amount']
    loanDetails?.deduction_loan_ids?.forEach((item, i) => {
        const data = loanDetails?.deduction_loan_payload?.selection?.length ? Object.values(loanDetails?.deduction_loan_payload?.selection[i]) : null
        data[0]?.map((val, index) => {
          if(val === 'outstanding_principle') {    
            renewalLoans.map((keys) => {
              if(keys?.id == item) {
                keys.principle_selected = true
              }
            })        
          }
          verticalHandlers.applyWhere(
          (item, index) => index == payHead?.indexOf(val),
          () => (true)
          ); 
        })
    })
  }, [renewalLoans])

  /** this function is used to handle the total value of selected pay amount */
  const handleSelectedTrancheTotalAmount = () => {
    let total = 0;
    let values = [];
    let keys = [];
    const handleSubTotalforTable = (arr) => {        
        loanDetails?.deduction_loan_ids?.forEach((keys, index) => {
            renewalLoans?.map((item, i) => {
          let val = 0
          values = arr?.length > index ? Object.values(arr[index])[0] : []
          if(keys == item?.id){
            values?.map((payhead) => {
              val = item[payhead] + val;
            })
            total = val + total;
            // return;
          }
        })
      })
    }
    if(loanDetails?.deduction_loan_payload?.selection?.length) {
      handleSubTotalforTable(loanDetails?.deduction_loan_payload?.selection)
    }
    return total;
  };

  /** handling the total tranche wise amount by sending the tranche id */
  const handleLoanWiseTotal = (id) => {
    const payHead = ['outstanding_principle', 'outstanding_interest', 'outstanding_spread', 'outstanding_addon_spread', 'penal_f', 'outstanding_penal_interest_amount']
    let total = 0;
    renewalLoans?.map((item) => {
        if(item?.id == id) {
            payHead?.map((pay, i) => {
                if(pay == 'outstanding_principle' && item?.principle_selected) {
                    total = item[pay] + total;
                }
                else if(verticalSelection[i] && pay != 'outstanding_principle') {
                    total = item[pay] + total
                }
            })
        }
    })
    return total;
  }

  const handleSelectedTranchePayheadAmount = (target_val) => {
    let val = 0;
    let values = [];
    /** used to handle the payhead wise total */
    if(loanDetails?.deduction_loan_payload?.selection?.length) {      
        loanDetails?.deduction_loan_ids?.forEach((keys, index) => {
            renewalLoans?.forEach((item, i) => {
              /** used to take the array of values */
              values = loanDetails?.deduction_loan_payload?.selection?.length > index ? Object.values(loanDetails?.deduction_loan_payload?.selection[index])[0] : []
              /*
               * if penal f means it will not check the values array else it will  
              */
              if(target_val == 'penal_f' && values?.includes('outstanding_interest_amount_during_penalty')) {
                if(keys == item?.id){
                  val = item[target_val] + val;
                }
              }else {
                if(keys == item?.id && values?.includes(target_val)){
                  val = item[target_val] + val;
                }
              }
            })
          })
    }
    return Math.round(val * 100)/100 || 0
  }

  return (
    <>
    {renewalLoans && loanDetails?.deduction_loan_ids ?
        <Grid.Col span={12} sx={{padding: '0'}}>
            <Title order={4} sx={ImdStyle.dashedTitle} mb={'md'}>
                Deduction Loans
            </Title>
            <Table verticalSpacing='xs' fontSize='xs' horizontalSpacing="xl" sx={{whiteSpace: "nowrap", maxWidth: "100%", display: 'inline-table'}}>
                <thead style={{ backgroundColor: COLORS.primary.light1 }}>
                    <tr>
                        <th>Earlier Tranche Code</th>
                        {valideData?.dpd ? <th style={{ textAlign: 'right' }}>DPD Days</th> : null}
                        {valideData?.if_principle && verticalSelection[0] ? <th style={{ textAlign: 'right' }}>Principal</th> : null}
                        {valideData?.if_interest && verticalSelection[1] ? <th style={{ textAlign: 'right'}}>Interest</th> : null}
                        {valideData?.if_spread && verticalSelection[2] ? <th style={{ textAlign: 'right'}}>Spread</th> : null}
                        {valideData?.if_addon_spread && verticalSelection[3] ? <th style={{ textAlign: 'right'}}>Addon Spread</th> : null}
                        {valideData?.if_penal_f && verticalSelection[4] ? <th style={{ textAlign: 'right'}}><Tooltip color={'gray'} withArrow label={`Including ${valideData?.if_spread_penal ? 'Spread F1' : ''} ${valideData?.if_addon_penal ? ', Addon Spread F1' : ''}`} disabled={(valideData?.if_spread_penal || valideData?.if_addon_penal) ? false : true}><span>Interest F1</span></Tooltip></th> : null}
                        {valideData?.if_penal && verticalSelection[5] ? <th style={{ textAlign: 'right'}}>Penal F2</th> : null}
                        <th style={{ textAlign: 'right' }}>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {renewalLoans?.map((item, index) => loanDetails?.deduction_loan_ids?.includes(item?.id) && (                                                      
                        <tr>
                            <td style={{ padding: '5px 5px' }}><Text ml={'sm'} sx={{display: 'flex', flexDirection: 'column'}}><span style={{fontWeight: '500'}}>{item?.prospect_code}</span><small>Disb Date: {dateFormat(item?.disbursed_date)}</small><small>Receipt Date: {item?.receipt_date ? dateFormat(item?.receipt_date) : '-'}</small></Text></td>
                            {valideData?.dpd ? <td style={{textAlign: 'right'}}>{item?.days_past_due || '-'}</td> : null}
                            {valideData?.if_principle && verticalSelection[0] ? <td style={{textAlign: 'right'}}><Nums value={item?.principle_selected ? item?.outstanding_principle : '-'} /></td> : null}
                            {valideData?.if_interest && verticalSelection[1] ? <td style={{textAlign: 'right'}}><Nums value={item?.outstanding_interest ? item?.outstanding_interest : '-'} /></td> : null}
                            {valideData?.if_spread && verticalSelection[2] ? <td style={{textAlign: 'right'}}><Nums value={item?.outstanding_spread ? item?.outstanding_spread : '-'} /></td> : null}
                            {valideData?.if_addon_spread && verticalSelection[3] ? <td style={{textAlign: 'right'}}><Nums value={item?.outstanding_addon_spread ? item?.outstanding_addon_spread : '-'} /></td> : null}
                            {valideData?.if_penal_f && verticalSelection[4] ? <td style={{textAlign: 'right'}}><Nums value={item?.penal_f ? item?.penal_f : '-'} /></td> : null}
                            {valideData?.if_penal && verticalSelection[5] ? <td style={{textAlign: 'right'}}><Nums value={item?.outstanding_penal_interest_amount ? item?.outstanding_penal_interest_amount : '-'} /></td> : null}
                            <td style={{textAlign: 'right', backgroundColor: COLORS.primary.light2 }}><Nums value={handleLoanWiseTotal(item?.id)} /></td>
                        </tr>
                    ))}
                    <tr style={{ backgroundColor: COLORS.primary.light1 }}>
                        <td colSpan={valideData?.dpd ? "2" : "1"}><strong>SubTotal</strong></td>
                        {valideData?.if_principle && verticalSelection[0] ? <td style={{ textAlign: 'right' }}>
                            <Nums value={handleSelectedTranchePayheadAmount('outstanding_principle')} />
                        </td> : null}
                        {valideData?.if_interest && verticalSelection[1] ? <td style={{ textAlign: 'right' }}>
                            <Nums value={handleSelectedTranchePayheadAmount('outstanding_interest')}  />
                        </td> : null}
                        {valideData?.if_spread && verticalSelection[2] ? <td style={{ textAlign: 'right' }}>
                            <Nums value={handleSelectedTranchePayheadAmount('outstanding_spread')}  />
                        </td> : null}
                        {valideData?.if_addon_spread && verticalSelection[3] ? <td style={{ textAlign: 'right' }}>
                            <Nums value={handleSelectedTranchePayheadAmount('outstanding_addon_spread')}  />
                        </td> : null}
                        {valideData?.if_penal_f && verticalSelection[4] ? <td style={{ textAlign: 'right' }}>
                            <Nums value={handleSelectedTranchePayheadAmount('penal_f')} />
                        </td> : null}
                        {valideData?.if_penal && verticalSelection[5] ? <td style={{ textAlign: 'right' }}>
                            <Nums value={handleSelectedTranchePayheadAmount('outstanding_penal_interest_amount')} />
                        </td> : null}
                        <td style={{ textAlign: 'right', backgroundColor: COLORS.primary.light2 }}>
                            <Nums value={handleSelectedTrancheTotalAmount()} />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Grid.Col> : null
    }
    </>
  )
}

export default LoanRenewalTable
