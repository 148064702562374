import {
  License,
  ChartInfographic,
  Cash,
  BrandStackoverflow,
  Route,
  Receipt,
  Notebook,
  ChartArea,
  ChartAreaLine,
  CalendarTime,
  Ticket,
  AddressBook,
  Article,
  Book,
  Users,
  BrandCoinbase,
  ClockRecord,
  Moneybag,
} from 'tabler-icons-react';
import { action_id } from '../../../config/userRules';

const reportsMetaData: Array<object> = [
  {
    label: "Accounting Entries Report",
    report_name: "accounting_entries",
    header: "accountingInterfaceHeader",
    title: "Accounting Entries",
    href: "accounting-entries",
    icon: License,
    isShow: action_id?.reports?.accounting_entries,
  },
  {
    label: "Due Report",
    report_name: "due",
    header: "due",
    title: "Due",
    href: "due-report",
    hideDateFilter: true,
    icon: ChartArea,
    isShow: action_id?.reports?.due,
  },
  {
    label: "Overdue Report",
    report_name: "over_due",
    header: "over_due",
    title: "Overdue",
    href: "overdue-report",
    hideDateFilter: true,
    icon: ChartAreaLine,
    isShow: action_id?.reports?.over_due,
  },
  {
    label: "Loan Book Report",
    report_name: "loan_book",
    header: "due_over_due",
    title: "Loan Book",
    href: "tranche-loan-book-report",
    hideDateFilter: true,
    icon: ChartInfographic,
    isShow: action_id?.reports?.loan_book,
  },
  {
    label: "Disbursement Report",
    report_name: "disbursement",
    header: "disbursement",
    title: "Disbursement",
    href: "disbursement-report",
    icon: Cash,
    isShow: action_id?.reports?.disbursement,
  },
  {
    label: "Renewal Maping Report",
    report_name: "renewal_mapping",
    header: "renewalMaping",
    title: "Renewal Maping",
    href: "renewal-mapping-report",
    icon: Route,
    isShow: action_id?.reports?.renewal_mapping,
  },
  {
    label: "Receipt Report",
    report_name: "receipt", header: "receipt", title: "Receipt",
    href: "receipt-report",
    icon: Receipt,
    isShow: action_id?.reports?.receipt,
  },
  {
    label: "Detailed Receipt Report",
    report_name: "detailed_receipt",
    header: "detailed_receipt",
    title: "Detailed Receipt",
    options: [
      { label: 'All', value: 'detailed_receipt' },
      { label: 'Summary', value: 'summary_receipt' },
    ],
    defaultValue: 'detailed_receipt',
    href: "detailed-receipt-report",
    icon: BrandStackoverflow,
    isShow: action_id?.reports?.detailed_receipt,
  },
  {
    label: "Excess Report",
    report_name: "receipt_excess",
    header: "receipt_excess",
    title: "Excess",
    href: "receipt-excess-report",
    icon: AddressBook,
    isShow: action_id?.reports?.receipt_excess,
  },
  {
    label: "Fee Report",
    report_name: "fee",
    header: "fee",
    title: "Fee",
    href: "fee-report",
    icon: Article,
    isShow: action_id?.reports?.fee,
  },
  {
    label: "Partywise Loan Book Report",
    report_name: "partywise_loan_book",
    header: "partywise_loan_book",
    title: "Partywise Loan Book",
    href: "partywise-loan-book-report",
    hideDateFilter: true,
    icon: Notebook,
    isShow: action_id?.reports?.partywise_loan_book,
  },
  {
    label: "DPD Report",
    report_name: "dpd", header: "dpd", title: "DPD",
    href: "dpd-report",
    icon: CalendarTime,
    isShow: action_id?.reports?.dpd,
  },
  {
    label: "Passbook Report",
    report_name: "passbook",
    title: "Passbook",
    href: "passbook-report",
    icon: Ticket,
    isShow: action_id?.reports?.passbook,
  },
  {
    label: "Unidentified Receipt Report",
    report_name: "unidentified_receipt_report",
    header: "unidentified_receipt_report",
    title: "Unidentified Receipt",
    href: "unidentified-receipt-report",
    selectOption: [
      { label: 'Unmapped', value: 'unmapped_unidentified_receipt_report' },
      { label: 'Receipt', value: 'mapped_unidentified_receipt_report' },
      { label: 'Customer Receipt', value: 'mapped_unidentified_receipt_clr_report' },
    ],
    options: [
      {
        label: 'Mapped',
        options: [
          { label: 'Receipt', value: 'mapped_unidentified_receipt_report' },
          { label: 'Customer Receipt', value: 'mapped_unidentified_receipt_clr_report' },
        ]
      },
      { label: 'Unmapped', value: 'unmapped_unidentified_receipt_report' },
    ],
    defaultValue: 'mapped_unidentified_receipt_report',
    hideEntityFilter: true,
    icon: ChartArea,
    isShow: action_id?.reports?.unidentified_receipt_report,
  },
  {
    label: "Borrowers Report",
    report_name: "borrowers_report",
    header: "borrowers_report",
    title: "Borrowers",
    href: "borrowers-report",
    options: [
      { label: 'Borrowers', value: 'customers' },
      { label: 'Applicants', value: 'customer_applicants' },
      { label: 'Banks', value: 'customer_banks' },
      { label: 'Applications', value: 'customer_applications' },
    ],
    defaultValue: 'customers',
    hideEntityFilter: true,
    hideDateFilter: true,
    icon: Users,
    isShow: action_id?.reports?.customer_report,
  },
  {
    label: "Bureau Report",
    report_name: "bureau_report",
    header: "bureau_report",
    title: "Bureau",
    href: "bureau-report",
    options: [
      { label: 'Commercial', value: 'cibil_borrower' },
      { label: 'Consumer', value: 'cibil_consumer' },
    ],
    defaultValue: 'cibil_borrower',
    hideEntityFilter: true,
    icon: BrandCoinbase,
    isShow: action_id?.reports?.customer_report,
  },
  {
    label: "TAT Report",
    report_name: "tat_report",
    header: "tat_report",
    title: "TAT",
    href: "tat-report",
    options: [
      { label: 'All Loans', value: 'all' },
      { label: 'Revolving', value: 'revolving' },
    ],
    defaultValue: 'all',
    hideEntityFilter: true,
    icon: ClockRecord,
    isShow: action_id?.reports?.tat_report,
  },
  {
    label: 'Advance Interest Report',
    report_name: "advance_interest",
    header: "advance_interest",
    title: "Advance Interest",
    href: "advance-interest-report",
    icon: Moneybag,
    isShow: action_id?.reports?.advance_interest_report
  }
];

const historicalReportMetaData = [
  {
    label: "Historical Loan Book Report",
    report_name: "historical_loan_book",
    header: "due_over_due",
    title: "Historical Loan Book",
    href: "historical-loan-book-report",
    icon: Book,
    isShow: action_id?.reports?.historical_loan_book,
  },
  {
    label: "MiFin Disbursal Report",
    report_name: "legacy_disbursal_reports",
    header: "mifin_disbursal",
    title: "MiFin Disbursal",
    href: "mifin-disbursal-report",
    icon: Cash,
    isShow: action_id?.reports?.legacy_disbursal_reports,
  },
  {
    label: "MiFin Receipt Report",
    report_name: "legacy_receipt_reports",
    header: "mifin_receipt",
    title: "MiFin Receipt",
    href: "mifin-receipt-report",
    icon: Receipt,
    isShow: action_id?.reports?.legacy_receipt_reports,
  },
  {
    label: "MiFin Accounting Detail",
    report_name: "legacy_accounting_detail_reports",
    header: "mifin_accounting_detail",
    title: "MiFin Accounting Detail",
    href: "mifin-accounting-detail-report",
    icon: License,
    isShow: action_id?.reports?.legacy_accounting_detail_reports,
  },
];

const getAllReports = () => {
  return reportsMetaData;
}

const getAllHistoricalReports = () => {
  return historicalReportMetaData;
}

const getReportObjectByReportName = (reportName: any) => {
  return [...reportsMetaData, ...historicalReportMetaData].find(
    (report: any) => report.href === reportName
  );
};

export {
  getAllReports,
  getAllHistoricalReports,
  getReportObjectByReportName,
}