import { Button } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import Body from '../../components/Body/Body';
import DataTableWrapper from '../../components/CommonComponents/Table/DataTableWrapper';
import {
    APPROVED,
    CANCELLED,
    IN_APPROVAL,
    IN_REVIEW,
    OPEN,
    REJECTED,
    CANCELLATION_REVIEW,
    CANCELLATION_APPROVAL,
} from '../../constants/status';
import { useQuery } from '@tanstack/react-query';
import { getAllManualCustomerInvoice } from '../../services/e-invoice.service';
import Nums from '../../components/Body/Nums';
import useModuleRecordCountStore from '../../store/moduleRecordCountStore';
import { COLORS } from '../../constants';

const ManualCustomerInvoiceTable = () => {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper();
  const { moduleCount } = useModuleRecordCountStore();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [records, setRecords] = useState({label: 'Show 10', value: 10});
  // get the search params
  const [searchParams, setSearchParams] = useSearchParams();
  const params = {}
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  /** used to change the page to 1 while the search us used 
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1)
  }, [search, searchParams.get("status"), searchParams.get("entity")])

  const { data: customerInvoice = [], isLoading } = useQuery(
    ['e-invoice all', page, search, records, params],
    () => getAllManualCustomerInvoice({page, search, records: records?.value, ...params}),
    {
      enabled: Boolean(searchParams.get("status") && searchParams.get("status") !== "none"),
      refetchOnWindowFocus: false
    }
  )

  const onRowClick = (i) => {
    if (i?.status_value == OPEN) {
      navigate(`/external-customer-invoice/view/${i?.id}`);
    } else if (i?.status_value == IN_APPROVAL || i?.status_value == IN_REVIEW) {
      navigate(`/external-customer-invoice/review/${i?.id}`);
    } else if (i?.status_value == APPROVED) {
      navigate(`/external-customer-invoice/approved/${i?.id}`);
    } else if (i?.status_value == REJECTED) {
      navigate(`/external-customer-invoice/rejected/${i?.id}`);
    } else if(i?.status_value == CANCELLED) {
      navigate(`/external-customer-invoice/cancelled/${i?.id}`);
    } else if(i?.status_value == CANCELLATION_REVIEW || i?.status_value == CANCELLATION_APPROVAL) {
      navigate(`/external-customer-invoice/cancel/${i?.id}`);
    }
  };

  const onClickAction = (value) => {
    return (
      <span
        style={{ color: `${COLORS.blue(100)}`, cursor: 'pointer' }}
      >
        {value.getValue()}
      </span>
    );
  };

  const tableAction = (
    <Button 
      variant='filled' 
      color='primary' 
      size='sm' 
      sx={{ marginLeft: 12 }} 
      leftIcon={<Plus size={18} />} 
      onClick={() => navigate(`/external-customer-invoice/creation`)}
    >
      Create Invoice
    </Button>
  );

  const columnData = [
    columnHelper.accessor('customer_name', {
      header: 'Customer Name',
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor('customer_code', {
      header: 'Customer Code',
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor('charge_amount', {
      header: 'Invoice Amount(excluding of tax)',
      cell: (value) => <Nums value={value.getValue()} />,
    }),
    columnHelper.accessor('maker_name', {
      header: 'Maker',
    }),
  ];

  return (
    <Body background={false}>
      <DataTableWrapper
        title='External Customer Invoice'
        filter={false}
        rowData={customerInvoice?.data}
        columnData={columnData}
        onRowClick={onRowClick}
        noDataText='No External Customer Invoice yet!'
        noDataSubText='This section will contain list of created External Customer Invoice details.'
        useApiPagination
        totalNoOfPages={Math.ceil(parseInt(moduleCount?.count)/records?.value)}
        totalNoOfRecords={moduleCount?.count}
        page={page}
        setPage={setPage}
        rowLength={records}
        setRowLength={setRecords}
        apiSearch={setSearch}
        showStatus
        module_name='external_customer_invoice'
        addDataCallback={() => navigate(`/external-customer-invoice/creation`)}
        actions={tableAction}
        // when the status is none then the loading is set to false other wise it will dependent on isLoading
        loading={searchParams.get("status") !== "none" ? isLoading : false}
      />
    </Body>
  );
};

export default ManualCustomerInvoiceTable;
