import { Box, Grid } from "@mantine/core";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Body from "../../components/Body/Body";
import DataViewerWapper from "../../components/CommonComponents/Preview/DataViewerWapper";
import { ViewFormData } from "../../components/CommonComponents/Preview/FilePreview";
import TitleViewer from "../../components/CommonComponents/Title/TitleViewer";
import DashBoardCustomerDetails from "../../components/DashboardCard/dashboard";
import PageTitle from "../../components/PageTitle/PageTitle";
import { AFTER_APPROVED_STAGES, STAGE_CHANGE_TEXT } from "../../constants";
import ImdStyle from "../IMD/Imd.styles";
import {
  customerBankDetails,
  CompanyBankDetails,
} from "../../services/imd.service";
import Remarks from "../../components/CommonComponents/Remarks/Remarks";
import ViewRemark from "../../components/Remark/ViewRemark";
import Nums from "../../components/Body/Nums";
import ModalDataViwer from "../../components/SucessfullModal/ModalDataViwer";
import IdBadge from "../../components/CommonComponents/Preview/IdBadge";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import { useQuery } from "@tanstack/react-query";
import ActionButtons from "../../components/CommonComponents/ActionButtons/ActionButtons";
import { getStageforModules } from "../../services/module.service";
import {
  CANCELLATION_REVIEW,
  IN_APPROVAL,
  IN_REVIEW,
} from "../../constants/status";
import UnidentifiedReceiptListModal from "./UnidentifiedReceiptListModal";
import {
  getCustomerLevelReceiptById,
  getCustomerReceiptRemarks,
  sendCustomerReceiptForNextStage,
  PushbackCustomerReceipt,
  RejectCustomerReceipt,
  CancelCustomerReceipt,
  getAllDisbursedLoansByCustomerId,
  getCustomerLevelReceiptLoansAfterApproved,
} from "../../services/customerReceipt.service";
import CustomerLevelReceiptLoansTable from "./CustomerLevelReceiptLoansTable";
import { sumBy } from "lodash";
import { currencyAsFloat } from "../../components/Currency/currencyFormat";
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";

const CustomerReceiptReview = ({ home = "/customer-receipt" }) => {
  const { customerId, receiptId } = useParams();
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState();
  const [remarksLoading, setRemarksLoading] = useState();
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [remarksTitle, setRemarksTitle] = useState();
  const [status, setStatus] = useState();
  const [sucessfulmodal, setSucessfulmodal] = useState({});
  const [loanDetails, setLoanDetails] = useState([]);

  // used to get the customer level receipt details
  const { data: receiptDetails = [], isLoading } = useQuery(
    ["customer-receipt-by-id", receiptId],
    () => receiptId && getCustomerLevelReceiptById(receiptId)
  );

  // used to get the stages
  const { data: stages = [] } = useQuery({
    queryKey: ["receipt-stages"],
    queryFn: () => getStageforModules("customer_level_receipt"),
    enabled: Boolean(receiptDetails?.status_value),
    select: (dataItem) => {
      return {
        current_stage: receiptDetails?.status_value,
        next_stage:
          dataItem[dataItem.indexOf(receiptDetails?.status_value) + 1],
      };
    },
  });

  // get the source bank
  const { data: sourceBank = [] } = useQuery({
    queryKey: ["customer-bank", customerId],
    queryFn: () => customerId && customerBankDetails(customerId),
    enabled: Boolean(receiptDetails?.source_bank_id),
    select: (data) => {
      return data?.find((item) => item.id == receiptDetails?.source_bank_id);
    },
  });

  // get the target bank
  const { data: targetBank = [], isLoading: targetBankLoading } = useQuery({
    queryKey: ["company-bank", receiptDetails?.target_bank_id],
    queryFn: () => CompanyBankDetails(receiptDetails?.target_bank_id),
    enabled: Boolean(receiptDetails?.target_bank_id),
  });

  /** used to get the loan details after approved */
  const { data: loanDetailsAfterApproved = [] } = useQuery({
    queryKey: ["customer-level-receipt-loan", customerId],
    queryFn: () => getCustomerLevelReceiptLoansAfterApproved(receiptId),
    onSuccess: (data) => {
      setLoanDetails(data);
    },
    enabled: Boolean(
      receiptDetails?.status_value &&
      AFTER_APPROVED_STAGES?.includes(receiptDetails?.status_value)
    ),
  });

  /** used to get the loan details before approved */
  const { data: loanDetailsBeforeApproved = [] } = useQuery({
    queryKey: ["customer-level-receipt-loan", customerId],
    queryFn: () =>
      getAllDisbursedLoansByCustomerId({
        customer_id: customerId,
        receipt_date: receiptDetails?.receipt_date,
        receipt_amount: currencyAsFloat(
          receiptDetails?.customer_level_receipt_amount
        ),
        unidentified_receipt_id: receiptDetails?.unidentified_receipt_id,
      }),
    onSuccess: (data) => {
      setLoanDetails(data);
    },
    enabled: Boolean(
      receiptDetails?.status_value &&
      !AFTER_APPROVED_STAGES?.includes(receiptDetails?.status_value)
    ),
  });

  // used to get the list of remarks
  const { data: reviewTrail = [] } = useQuery({
    queryKey: ["remarks", receiptId],
    queryFn: () => getCustomerReceiptRemarks(receiptId),
  });

  // sending the receipt for next stage
  const onSendReceiptforNextStage = (remarks, status) => {
    const body = {
      remarks,
    };

    setRemarksLoading(true);
    sendCustomerReceiptForNextStage(receiptId, body)
      .then((data) => {
        setSucessfulmodal({
          open: true,
          title:
            status == IN_REVIEW
              ? STAGE_CHANGE_TEXT?.approval
              : status == IN_APPROVAL
                ? STAGE_CHANGE_TEXT?.approved
                : status == CANCELLATION_REVIEW
                  ? STAGE_CHANGE_TEXT?.reviewCancel
                  : STAGE_CHANGE_TEXT?.approveCancel,
        });
      })
      .catch((e) => {
        displayNotification({
          id: "customer-receipt-approve",
          message: e?.message || e,
          variant: 'error',
        });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      });
  };

  // sending the receipt for pushback
  const handlePushback = (remarks) => {
    const body = {
      remarks,
    };

    setRemarksLoading(true);
    PushbackCustomerReceipt(receiptId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.pushback });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      });
  };

  // reject the receipt
  const handleReject = (remarks) => {
    const body = {
      remarks,
    };

    setRemarksLoading(true);
    RejectCustomerReceipt(receiptId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.reject });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      });
  };

  // cancel the receipt
  const handleCancel = (remarks, reason) => {
    const body = {
      remarks,
      reason: reason?.reason,
    };

    setRemarksLoading(true);
    CancelCustomerReceipt(receiptId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.cancel });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      });
  };

  // used to call the function by status from the remarks component and pass the remarks and assignedTo to the function
  const callFunction = (remarks, status, reason) => {
    switch (status) {
      case "review":
      case "approval":
      case "cancellation":
        onSendReceiptforNextStage(remarks, status);
        break;
      case "reject":
        handleReject(remarks);
        break;
      case "pushback":
        handlePushback(remarks);
        break;
      case "cancel":
        handleCancel(remarks, reason);
        break;
      default:
        displayNotification({
          message: "Something went wrong, Please contact support!",
          variant: 'error',
        });
        break;
    }
  };

  return (
    <>
      <Body background={false}>
        {/* name and id has send as props to display in the page */}
        <PageTitle
          title={receiptDetails?.customer_name}
          id={receiptDetails?.customer_id}
          action={
            receiptDetails?.unidentified_receipt_id ? (
              <UnidentifiedReceiptListModal
                selectedRowId={receiptDetails?.unidentified_receipt_id}
              />
            ) : null
          }
        />
      </Body>
      {/* id is send as a props to get and display the customer details  */}
      <DashBoardCustomerDetails
        id={customerId}
        loansId={receiptDetails?.loan_id}
        codeName={"Receipt Code"}
        code={receiptDetails?.code}
      />
      <Box sx={{ position: "relative" }}>
        <Body>
          {/* receipt name is send as props to display and status value is send to change the color of the badge */}
          {receiptDetails?.code && (
            <div style={ImdStyle.flag}>
              <IdBadge
                remarks={receiptDetails?.status_value}
                label={receiptDetails?.code}
              />
            </div>
          )}
          <Grid gutter="sm" mt={"md"}>
            <Grid.Col span={4}>
              {/* DataViewerWapper component is used to display the data inside the bule background */}
              <DataViewerWapper>
                <ViewFormData
                  label="Receipt No"
                  value={receiptDetails?.id}
                  loading={isLoading}
                />
                <ViewFormData
                  label="Receipt Date"
                  value={dateFormat(receiptDetails?.receipt_date, "DD/MM/YYYY")}
                  loading={isLoading}
                />
                <ViewFormData
                  label="Receipt Amount"
                  value={
                    <Nums
                      value={receiptDetails?.customer_level_receipt_amount}
                    />
                  }
                  loading={isLoading}
                />
                <ViewFormData
                  label="Due Amount"
                  value={<Nums value={sumBy(loanDetails, "loan_due_amount")} />}
                  loading={isLoading}
                />
                <ViewFormData
                  label="Current Excess"
                  value={
                    <Nums value={receiptDetails?.customer_excess_amount} />
                  }
                  loading={isLoading}
                />
                {receiptDetails?.used_excess_amount ? (
                  <ViewFormData
                    label="Used Excess"
                    value={<Nums value={receiptDetails?.used_excess_amount} />}
                    loading={isLoading}
                  />
                ) : null}
                <ViewFormData
                  label="Instrument Date"
                  value={dateFormat(
                    receiptDetails?.instrument_date,
                    "DD/MM/YYYY"
                  )}
                  loading={isLoading}
                />
                <ViewFormData
                  label="Instrument Type"
                  value={receiptDetails?.instrument_type?.toUpperCase()}
                  loading={isLoading}
                />
                {receiptDetails?.dpd ? (
                  <ViewFormData
                    label="DPD"
                    value={receiptDetails?.dpd}
                    loading={isLoading}
                  />
                ) : null}
                <ViewFormData
                  label="TDS"
                  value={receiptDetails?.tds == "1" ? "Yes" : "No"}
                  loading={isLoading}
                />
                {receiptDetails?.tds_amount ? (
                  <ViewFormData
                    label="TDS Amount"
                    value={<Nums value={receiptDetails?.tds_amount} />}
                    loading={isLoading}
                  />
                ) : null}
                <ViewFormData
                  label="Payment Ref.No"
                  value={receiptDetails?.payment_ref_no}
                  loading={isLoading}
                />
              </DataViewerWapper>
            </Grid.Col>
            <Grid.Col span={4}>
              {receiptDetails?.instrument_type != "cash" ? (
                <Grid.Col span={12}>
                  {/* TitleViewer is used to display the in the title format */}
                  <TitleViewer title="Bank Details of Customer" />
                  <ViewFormData
                    style={{ paddingTop: "10px" }}
                    label="Account No"
                    value={sourceBank?.account_number}
                    loading={targetBankLoading}
                  />
                  <ViewFormData
                    label="Bank Name"
                    value={sourceBank?.bank_name}
                    loading={targetBankLoading}
                  />
                </Grid.Col>
              ) : null}
              <Grid.Col span={12} mt={4}>
                <TitleViewer title="Bank Details of Petromoney" />
                <ViewFormData
                  style={{ paddingTop: "10px" }}
                  label="Account No"
                  value={targetBank[0]?.account_number}
                  loading={targetBankLoading}
                />
                <ViewFormData
                  label="Bank Name"
                  value={targetBank[0]?.bank_name}
                  loading={targetBankLoading}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12}>
              <Grid.Col>
                <TitleViewer title="Receipt Appropriation" />
              </Grid.Col>
              <Grid.Col>
                {/* {console.log(AFTER_APPROVED_STAGES?.includes(receiptDetails?.status))} */}
                <CustomerLevelReceiptLoansTable
                  data={loanDetails}
                  type={
                    AFTER_APPROVED_STAGES?.includes(
                      receiptDetails?.status_value
                    ) && "review"
                  }
                  currentStatus={receiptDetails?.status_value}
                  customerId={customerId}
                />
              </Grid.Col>
            </Grid.Col>
          </Grid>
          {/** This Action buttons component is used to display the buttons in the flow */}
          <ActionButtons
            status={stages?.current_stage}
            accountingCB={false}
            currentStatus={receiptDetails?.status_value}
            moduleApplicationId={receiptDetails?.id}
            moduleName={"customer_level_receipt"}
            nextStage={stages?.next_stage}
            isReady={Boolean(stages?.next_stage)}
            remarks={reviewTrail}
            sendForApprovalCB={() => {
              setOpen(true);
              setRemarksTitle("Approval Remarks");
              setStatus("review");
              setLabel("Send for Approval");
            }}
            approvedCB={() => {
              setOpen(true);
              setRemarksTitle("Approval Remarks");
              setStatus("approval");
              setLabel("Approve");
            }}
            pushbackCB={() => {
              setOpen(true);
              setRemarksTitle("Pushback Remarks");
              setStatus("pushback");
              setLabel("Pushback");
            }}
            rejectCB={() => {
              setOpen(true);
              setRemarksTitle("Reject Remarks");
              setStatus("reject");
              setLabel("Reject");
            }}
            remarksCB={() => setRemarksOpen(true)}
            cancelCB={() => {
              setOpen(true);
              setRemarksTitle("Cancellation Remarks");
              setStatus("cancellation");
              setLabel("Send for Cancellation");
            }}
            approveCancelCB={() => {
              setOpen(true);
              setRemarksTitle("Are you sure?");
              setStatus("cancel");
              setLabel("Confirm");
            }}
          />

          {/* remarks component is used to get remarks from user */}
          <Remarks
            open={open}
            setOpen={setOpen}
            type={stages?.next_stage}
            callback={callFunction}
            remarksTitle={remarksTitle}
            moduleName={"customer_level_receipt"}
            check={status}
            label={label}
            loading={remarksLoading}
          />
          {/* ModalDataViwer is used to display the status on sucess stage change */}
          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={receiptDetails?.code}
            title={sucessfulmodal?.title}
            href={home}
            hreftext={"Back to list table"}
          />
        </Body>
        {/* viewremarks is used to display remarks */}

        <ViewRemark
          open={remarksOpen}
          onClose={setRemarksOpen}
          remarkTrail={reviewTrail}
        />
      </Box>
    </>
  );
};

export default CustomerReceiptReview;
