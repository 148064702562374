import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Popover,
  Title,
  Group,
  createStyles,
  Text,
  SegmentedControl,
  Skeleton,
  Grid,
  Badge,
} from "@mantine/core";
import EntityFilter from "../components/CommonComponents/Filter/EntityFilter";
import { useSearchParams } from "react-router-dom";
import RegionFilter from "../components/CommonComponents/Filter/RegionFilter";
import ZoneFilter from "../components/CommonComponents/Filter/ZoneFilter";
import { subDays } from "date-fns";
import { dateFormat } from "../components/DateFormat/dateFormat";
import { DateRange } from "react-date-range";
import useSystemConfigStore from "../store/systemConfigStore";
import styled from "@emotion/styled";
import changeValueFormat from "../utils/changeValueFormat";
import { VALUE_TYPES } from "../utils/changeValueFormat";
import { useQuery } from "@tanstack/react-query";
import {
  getCustomerApplicationStatus,
  getFeeStats,
  getEntitiesIncomeDetails,
  getHistoricalLoanBook,
  getHistoricalNPA,
  getLoanStats,
  getReceiptStats,
  getCreditMISStatsMetaData,
  getHistoricalWriteOff,
} from "../services/dashboard.service";
import CreditBookComp from "../components/Dashboard/CreditBook";
import { COLORS } from "../constants";
import DisplayIncomeContentTable from "../components/Dashboard/InterestIncomeTable";
import { sumBy } from "lodash";
import DialogModal from "../components/CommonComponents/Modal/DialogModal";
import DataTableWrapper from "../components/CommonComponents/Table/DataTableWrapper";
import { useDebouncedValue } from "@mantine/hooks";
import { displayNotification } from "../components/CommonComponents/Notification/displayNotification";
import management_dashboard_headers from "./managementDashboardHeaders";

const DataTableWapper = styled.table`
  margin-right: 10px;
  min-width: 100%;
  font-size: 12px;
  tr:first-of-type th {
    border-bottom: 1px solid #bbb;
  }
  td {
    padding: 4px 16px;
    border-bottom: 1px dashed #bbb;
  }
  tr:last-of-type td {
    border-bottom: 0;
  }
  tr td:last-of-type {
    text-align: right;
  }
  th {
    text-align: left;
    padding: 8px 16px;
  }
`;

const DataTableIIIWapper = styled.table`
  margin-right: 10px;
  margin-top: 4px;
  min-width: 100%;
  font-size: 12px;
  td {
    padding: 4px 0px;
    border-bottom: 1px dashed rgba(100,100,100,0.2);
  }
  tr:first-of-type td {
    border-top: 1px dashed rgba(100,100,100,0.2);
  }
  tr:last-of-type td {
    border-bottom:  1px dashed rgba(100,100,100,0.2);
  }
  tr td:last-of-type {
    text-align: right;
  }
  th {
    text-align: left;
    padding: 8px 0px;
  }
`;

const useStyles = createStyles({
  filterRootSX: {
    borderRadius: "5px",
    display: "flex",
    justifyContent: "space-between",
    height: "fit-content",
  },
  filterInnerSX: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
  },
  filterSX: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
  },
  contentInnerSX: {
    // background: "#FFFFFF",
    borderRadius: "5px",
    // padding: "14px",
    height: "fit-content",
  },
  contentInner: {
    background: "#FFFFFF",
    borderRadius: "5px",
    padding: "14px",
    height: "fit-content",
  },
  contentCardSX: {
    background: "#FFFFFF",
    borderRadius: "5px",
    display: "block",
    padding: "14px",
    height: "fit-content",
  },
  contentCardInnerSx: {
    maxWidth: "100%",
    whiteSpace: "nowrap",
    display: "initial",
  },
  cardContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  cardRootSx: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  titleDate: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    gap: '10px'
  },
  displayContentTableMain: {
    padding: '10px',
    background: '#EEF7FF',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 6,
    // width: '180px',
  },
  tableThreeMain: {
    // background: '#ffffff',
    border: '1px solid #e4e4e4',
    padding: '8px',
    borderRadius: '6px',
  },
  feeDetailsRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
    marginTop: '20px',
  },
  displayContentTableRoot: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '10px',
  },
  taleThreeContentRoot: {
    borderRadius: '6px',
    background: 'white',
  },
  borderRadiusTopTableThree: {
    background: 'white',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
  },
  borderRadiusBottomTableThree: {
    background: 'white',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  tableThreeContentTitle: {
    width: '240px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  noDataText: {
    textAlign: "center",
    color: COLORS.gray(50),
    fontWeight: 500,
  },
  textColorBlue: {
    cursor: 'pointer',
  },
});

const CRORE = 10000000;

const DisplayValue = ({ value, type = "amount", justifyContent, color }) => {
  const [amountInCR, setAmountInCR] = useState(true);

  let FinalValue = changeValueFormat(value, type);

  if (type?.toLowerCase() !== VALUE_TYPES.amount) {
    return (
      <Text
        sx={{
          fontWeight: 500,
          display: "flex",
          flexDirection: "row",
          gap: "2px",
          justifyContent: "right",
          color: color && color,
        }}
      >
        {FinalValue}
      </Text>
    );
  }

  let suffix = "";
  if (type?.toLowerCase() === VALUE_TYPES.amount && amountInCR) {
    FinalValue = changeValueFormat(value / CRORE, type, { round: false });
    suffix = "Cr";
  }

  return (
    value
      ? <Box
        sx={{
          display: "flex",
          justifyContent: justifyContent ? justifyContent : "right",
          color: color && color,
        }}
      >
        <Group
          spacing={4}
          onClick={() => setAmountInCR(!amountInCR)}
          sx={{ fontWeight: 500, cursor: 'pointer' }}
        >
          {FinalValue} {suffix}
        </Group>
      </Box>
      : '-'
  );
};

const DisplayContentTable = ({ data, loading = false, onClickAction = true, dateObj, apiCall }) => {
  const { classes } = useStyles();
  const [modalObj, setModalObj] = useState({});

  // handle the modal open and close
  const handleClickAction = (title) => {
    setModalObj({ modal: true, title: { key: title?.type, name: title?.header, count: title?.count }, header: title?.type });
  }

  return (
    <>
      <DataTableIIIWapper>
        <tbody>
          {!loading ? (data?.length ? data?.map((item, index) => (
            <tr key={index} style={{ backgroundColor: item?.color && item?.color }}>
              <td onClick={() => onClickAction && handleClickAction(item)} className={onClickAction && classes.textColorBlue} width={'252px'}>{item?.header}</td>
              <td onClick={() => onClickAction && handleClickAction(item)} className={onClickAction && classes.textColorBlue}>{item?.count}</td>
              <td style={{ textAlign: 'right' }}>{item?.amount ? <DisplayValue value={item?.amount} /> : 0}</td>
            </tr>
          )) : null) :
            [1, 2, 3, 4, 5]?.map((item) =>
              <tr key={item}>
                <td><Skeleton height={15} width={"60%"} /></td>
                <td><Skeleton height={15} width={"60%"} /></td>
                <td><Skeleton height={15} width={"60%"} /></td>
              </tr>
            )}
        </tbody>
      </DataTableIIIWapper>
      {modalObj?.modal ? <DetailedViewModal open={modalObj?.modal} onClose={() => setModalObj({ modal: false })} titleObj={modalObj?.title} dateObj={dateObj} headerName={modalObj?.header} apiCall={apiCall} /> : null}
    </>
  )
}

const DetailedViewModal = ({ open, onClose, titleObj, dateObj, headerName, apiCall }) => {
  const systemDate = useSystemConfigStore((store) => store.config);
  const [searchParams, setSearchParams] = useSearchParams();
  var params = Object.fromEntries(searchParams?.entries());
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({});
  const [records, setRecords] = useState({ label: "Show 10", value: 10 });
  const [deBounceSearch] = useDebouncedValue(search?.length > 3 ? search : '', 700);
  const [tableData, setTableData] = useState([]);
  const [sorting, setSorting] = useState([{
    "id": "max_dpd",
    "desc": true
  }]);
  const headers = {
    new_loan: 'new',
    enhancement_loan: 'enhancement',
    advance_interest: 'advance_interest',
    'New Fee': 'new',
    Renewal: 'renewal',
    roll_over: 'rollover',
    npa: 'npa_details',
    write_off: 'writeoff_details'
  };

  useEffect(() => {
    page != 1 && setPage(1);
  }, [records, search])

  const credit_mis_detail_stats = useQuery({
    queryKey: ['credit-mis-stats-table', titleObj?.name, params, deBounceSearch, page, records],
    queryFn: () => getCreditMISStatsMetaData({ ...params, ...dateObj, key: titleObj?.key, page, records: records?.value, search: deBounceSearch }),
    onSuccess: (data) => {
      setTableData(data);
    },
    enabled: Boolean(apiCall === 'credit_mis'),
  });

  const loan_detail_stats = useQuery({
    queryKey: ['loan-stats-table', titleObj?.name, params, deBounceSearch, page, records],
    queryFn: () => getLoanStats({ ...params, ...dateObj, filter: headers?.[headerName] || headerName, page, records: records?.value, search: deBounceSearch }),
    onSuccess: (data) => {
      setTableData(data?.data);
      setPagination(data);
    },
    enabled: Boolean(apiCall === 'loan_details'),
  });

  const receipt_detail_stats = useQuery({
    queryKey: ["receipt-stats-table", titleObj?.name, params, deBounceSearch, page, records],
    queryFn: () => getReceiptStats({ ...params, ...dateObj, filter: headers?.[headerName] || headerName, page, records: records?.value, search: deBounceSearch }),
    onSuccess: (data) => {
      setTableData(data?.data);
      setPagination(data);
    },
    enabled: Boolean(apiCall === 'receipt'),
  })

  const fee_detail_stats = useQuery({
    queryKey: ["fee-stats-table", titleObj?.name, params, deBounceSearch, page, records],
    queryFn: () => getFeeStats({ ...params, ...dateObj, filter: headers?.[headerName] || headerName, page, records: records?.value, search: deBounceSearch }),
    onSuccess: (data) => {
      setTableData(data?.data);
      setPagination(data);
    },
    enabled: Boolean(apiCall === 'fee'),
  })

  const historical_nap_detail_stats = useQuery({
    queryKey: ["historical-npa-table", params, deBounceSearch, page, records, sorting],
    queryFn: () => getHistoricalNPA({ ...params, from: systemDate?.current_system_date, filter: headers?.[headerName] || headerName, page, records: records?.value, search: deBounceSearch, orderBy: sorting?.[0]?.desc === true ? 'desc' : 'asc' }),
    onSuccess: (data) => {
      setTableData(data?.data);
      setPagination(data);
    },
    enabled: Boolean(systemDate?.current_system_date && apiCall === 'npa'),
  });

  const historical_write_off_detail_stats = useQuery({
    queryKey: ["historical-write-off-table", params, deBounceSearch, page, records, sorting],
    queryFn: () => getHistoricalWriteOff({ ...params, from: systemDate?.current_system_date, filter: headers?.[headerName] || headerName, page, records: records?.value, search: deBounceSearch, orderBy: sorting?.[0]?.desc === true ? 'desc' : 'asc' }),
    onSuccess: (data) => {
      setTableData(data?.data);
      setPagination(data);
    },
    enabled: Boolean(systemDate?.current_system_date && apiCall === 'writeoff_details'),
  });

  return (
    <>
      <DialogModal
        open={open}
        onClose={onClose}
        title={titleObj?.name}
        centered
        transitionProps={{ transition: 'fade', duration: 600, timingFunction: 'linear' }}
        size="70%"
      >
        <DataTableWrapper
          addDataOption={false}
          rowData={tableData}
          styles={{ overflowX: "auto", whiteSpace: "nowrap", maxWidth: "100vw" }}
          columnData={management_dashboard_headers[headerName] || management_dashboard_headers[apiCall] || []}
          useApiPagination
          sorting={sorting}
          setSorting={setSorting}
          breadcrumb={false}
          totalNoOfPages={pagination?.total_number_of_pages || Math.ceil(parseInt(titleObj?.count) / records?.value)}
          totalNoOfRecords={pagination?.total_number_of_records || titleObj?.count}
          allowSorting={headerName === 'npa' || headerName === 'write_off'}
          apiSorting
          page={page}
          isSortingRemoval={false}
          filter={false}
          setPage={setPage}
          apiSearch={setSearch}
          rowLength={records}
          setRowLength={setRecords}
          loading={Boolean(apiCall === 'credit_mis') ? credit_mis_detail_stats?.isLoading :
            Boolean(apiCall === 'loan_details') ? loan_detail_stats?.isLoading :
              Boolean(apiCall === 'receipt') ? receipt_detail_stats?.isLoading :
                apiCall === 'npa' ? historical_nap_detail_stats?.isLoading :
                apiCall === 'writeoff_details' ? historical_write_off_detail_stats?.isLoading : fee_detail_stats?.isLoading}
        />
      </DialogModal>
    </>
  )
}

const ManagementDashboard = () => {
  const systemDate = useSystemConfigStore((store) => store.config);
  const [selectedPeriodType, setSelectedPeriodType] = useState("M");
  const [selectedPeriod, setSelectedPeriod] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalOpen, setModalOpen] = useState(false);
  const { classes } = useStyles();
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [modalObj, setModalObj] = useState({});

  var params = Object.fromEntries(searchParams?.entries());

  const historicalNPAQuery = useQuery({
    queryKey: ["historical-npa", params],
    queryFn: () => getHistoricalNPA({ ...params, from: systemDate?.current_system_date }),
    select: (data) => {
      return data?.data;
    },
    enabled: Boolean(systemDate?.current_system_date),
  });

  const historicalWriteOffQuery = useQuery({
    queryKey: ["historical-write-off", params],
    queryFn: () => getHistoricalWriteOff({ ...params, from: systemDate?.current_system_date }),
    select: (data) => {
      return data?.data;
    },
    enabled: Boolean(systemDate?.current_system_date),
  });

  const historicalLoanBookQuery = useQuery({
    queryKey: ["historical-loan-book", params],
    queryFn: () => getHistoricalLoanBook({ ...params, from: systemDate?.current_system_date }),
    select: (data) => {
      return data?.data;
    },
    enabled: Boolean(systemDate?.current_system_date),
  });

  const customerLoanStatusQuery = useQuery({
    queryKey: ["customer-loan-status", params, selectedPeriod],
    queryFn: () => getCustomerApplicationStatus({ ...params, ...selectedPeriod }),
    enabled: Boolean(selectedPeriod?.to),
  });

  const loanStatsQuery = useQuery({
    queryKey: ["loan-stats", params, selectedPeriod],
    queryFn: () => getLoanStats({ ...params, ...selectedPeriod }),
    initialData: [],
    select: (data) => {
      return data?.data;
    },
    enabled: Boolean(selectedPeriod?.to),
  });

  const receiptStatsQuery = useQuery({
    queryKey: ["receipt-stats", params, selectedPeriod],
    queryFn: () => getReceiptStats({ ...params, ...selectedPeriod }),
    initialData: [],
    select: (data) => {
      return data?.data;
    },
    enabled: Boolean(selectedPeriod?.to),
  });

  const feeStatsQuery = useQuery({
    queryKey: ["fee-stats", params, selectedPeriod],
    queryFn: () => getFeeStats({ ...params, ...selectedPeriod }),
    initialData: [],
    select: (data) => {
      return data?.data;
    },
    enabled: Boolean(selectedPeriod?.to),
  });

  const entitiesIncomeDetailsQuery = useQuery({
    queryKey: ["entity-income-details", params, selectedPeriod],
    queryFn: () => getEntitiesIncomeDetails({ ...params, ...selectedPeriod }),
    enabled: Boolean(selectedPeriod?.to),
    select: (data) => {
      let result = {
        list: data,
        total: {
          total_interest: sumBy(data, "interest"),
          total_penal: sumBy(data, "penal"),
        }
      }
      return result;
    }
  });

  const credit_book_details_table = [
    {
      header: "Loan Book",
      amount: historicalLoanBookQuery?.data?.[0]?.loan_book,
    }, {
      header: "Overdue",
      amount: historicalLoanBookQuery?.data?.[0]?.overdue_principle,
    }, {
      header: "Due",
      amount: historicalLoanBookQuery?.data?.[0]?.due_principle,
    }, {
      header: "Current",
      amount: historicalLoanBookQuery?.data?.[0]?.current_principle,
    },
  ];

  /** used to set the default date as system date when the system date context is loaded */
  useEffect(() => {
    if (systemDate?.current_system_date) {
      setState([
        {
          from: new Date(
            new Date(systemDate?.current_system_date).getFullYear(),
            new Date(systemDate?.current_system_date).getMonth()
          ),
          to: new Date(systemDate?.current_system_date),
          key: "selection",
        },
      ]);
      setSelectedPeriod({
        from: new Date(
          new Date(systemDate?.current_system_date).getFullYear(),
          new Date(systemDate?.current_system_date).getMonth()
        ),
        to: new Date(systemDate?.current_system_date),
      });
    }
  }, [systemDate?.current_system_date]);

  const handleClickAction = (val) => {
    let count;
    if (val === 'advance_interest') {
      count = loanStatsQuery?.data?.find(e => e?.type === 'advance_interest')?.count;
    } else if (val === 'npa') {
      count = historicalNPAQuery?.data?.[0]?.no_of_npa_customers;
    } else if (val === 'write_off'){
      count = historicalWriteOffQuery?.data?.[0]?.no_of_write_off_customers;
    } else {
      count = customerLoanStatusQuery?.data?.data?.find((e) => e?.status === val?.key)?.count;
    }
    if (count > 0) {
      setModalObj({ modal: true, title: { name: val === 'advance_interest' ? 'Advance Interest' : val === 'npa' ? 'NPA' : 'Write Off', ...val, count }, header: (typeof val === 'string') ? val : 'credit_mis', apiCall: val === 'advance_interest' ? 'loan_details' : val === 'npa' ? 'npa' : val === 'write_off' ? 'writeoff_details' : 'credit_mis',});
    } else {
      displayNotification({ message: '!There is no data to display', variant: 'warning' });
    }
  };

  /** used to handle the date */
  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setState([selection]);
  };

  /** used to handle the date filter */
  const handleFilter = () => {
    setModalOpen(false);
    var date = {
      from: dateFormat(state[0]?.startDate, "YYYY-MM-DD"),
      to: dateFormat(state[0]?.endDate, "YYYY-MM-DD"),
    }
    setSelectedPeriod({
      from: dateFormat(date?.from === "Invalid Date" ? new Date() : date?.from, "YYYY-MM-DD"),
      to: dateFormat(date?.to === "Invalid Date" ? new Date() : date?.to, "YYYY-MM-DD"),
    });
  };

  /**
   * Handles changes to the date picker and sets the selected date period based on the type of change.
   *
   * @param {string} type - The type of date change to be made.
   */
  const onDateChange = (type) => {
    if (type !== "Custom" && modalOpen) {
      setModalOpen(false);
    }
    switch (type) {
      case "D":
        setSelectedPeriod({
          from: new Date(systemDate?.current_system_date),
          to: new Date(systemDate?.current_system_date),
        });
        break;
      case "W":
        setSelectedPeriod({
          from: subDays(new Date(systemDate?.current_system_date), 8),
          to: new Date(systemDate?.current_system_date),
        });
        break;
      case "M":
        setSelectedPeriod({
          from: new Date(
            new Date(systemDate?.current_system_date).getFullYear(),
            new Date(systemDate?.current_system_date).getMonth()
          ),
          to: new Date(systemDate?.current_system_date),
        });
        break;
      case "Y":
        let today = new Date(systemDate?.current_system_date);
        let currentYear = today.getFullYear();
        let currentMonth = today.getMonth() + 1;
        let financialYearStart;

        if (currentMonth < 4) {
          financialYearStart = new Date(currentYear - 1, 3, 1);
        } else {
          financialYearStart = new Date(currentYear, 3, 1);
        }
        setSelectedPeriod({
          from: new Date(financialYearStart),
          to: new Date(systemDate?.current_system_date),
        });
        break;
      case "UTD":
        setSelectedPeriod({});
        break;
      case "Custom":
        setModalOpen(true);
        break;
      default:
        break;
    }
  };

  const handleTableDetails = (type, data, title) => {
    let key = type;
    let result = 0;
    data?.length && data?.forEach((item) => {
      if (item?.type === 'advance_interest' && title === 'loan') {
        return;
      }
      result += parseInt(item?.[key] ? item?.[key] : 0);
    })
    return result || 0;
  }

  return (
    <Box>
      <Box mb={"10px"} className={classes.filterRootSX}>
        <Box className={classes.filterInnerSX}>
          <Box className={classes.filterSX}>
            <Text size={"xs"} color="gray">
              Entity
            </Text>
            <EntityFilter useQueryParams />
          </Box>
          <Box className={classes.filterSX}>
            <Text size={"xs"} color="gray">
              Zone
            </Text>
            <ZoneFilter useQueryParams />
          </Box>
          <Box className={classes.filterSX}>
            <Text size={"xs"} color="gray">
              Region
            </Text>
            <RegionFilter useQueryParams />
          </Box>
        </Box>
        <Box>
          <Box className={classes.filterSX}>
            <Text size={"xs"} color="gray">
              Date
            </Text>
            <Popover
              position="bottom"
              shadow="md"
              opened={modalOpen}
              onChange={setModalOpen}
            >
              <Popover.Target>
                <SegmentedControl
                  value={selectedPeriodType}
                  size="sm"
                  color="blue"
                  onChange={(e) => {
                    setSelectedPeriodType(e);
                    onDateChange(e);
                  }}
                  onClick={() => {
                    selectedPeriodType === "Custom" && setModalOpen(true);
                  }}
                  data={[
                    { label: "Today", value: "D" },
                    { label: "1W", value: "W" },
                    { label: "MTD", value: "M" },
                    { label: "YTD", value: "Y" },
                    {
                      label:
                        selectedPeriodType !== "Custom"
                          ? "Custom"
                          : `${dateFormat(selectedPeriod?.from) +
                          "-" +
                          dateFormat(selectedPeriod?.to)
                          }`,
                      value: "Custom",
                    },
                  ]}
                  styles={(them) => ({
                    root: {
                      backgroundColor: "#FFFFFF",
                      border: "1px solid hsl(0, 0%, 90%)",
                    },
                    label: {
                      fontSize: "12px",
                    },
                  })}
                />
              </Popover.Target>
              <Popover.Dropdown>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <DateRange
                    onChange={handleOnChange}
                    months={1}
                    maxDate={
                      new Date(
                        systemDate?.current_system_date
                          ? systemDate?.current_system_date
                          : new Date()
                      )
                    }
                    ranges={state}
                    retainEndDateOnFirstSelection
                    direction="horizontal"
                  />
                  <Button onClick={handleFilter}>Apply</Button>
                </Box>
              </Popover.Dropdown>
            </Popover>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "14px" }}>
        <Box className={classes.contentInnerSX} sx={{ width: "50%" }}>
          <Box className={classes.contentInner}>
            <Group position="apart" mb={"md"}>
              <Title order={5}>
                Credit MIS
              </Title>
              <Box className={classes.titleDate}>
                {dateFormat(selectedPeriod?.from) === dateFormat(selectedPeriod?.to) ? null :
                  <Text size={"md"} weight={500} color={COLORS.text.light1}>{dateFormat(selectedPeriod?.from, 'DD MMM YYYY')} - </Text>
                }
                <Text size={"md"} weight={500} color={COLORS.text.light1}>{dateFormat(selectedPeriod?.to, 'DD MMM YYYY')}</Text>
              </Box>
            </Group>
            {!customerLoanStatusQuery?.isLoading && !customerLoanStatusQuery?.data?.data?.length ?
              <Text size={'sm'} color="gray" sx={{ textAlign: 'center' }}>No Data to display</Text>
              : <DataTableWapper>
                {!customerLoanStatusQuery?.isLoading && customerLoanStatusQuery?.data?.data?.length
                  ? <tbody>
                    <tr>
                      <td style={{ fontWeight: "500" }}>Queue Name</td>
                      <td style={{ fontWeight: "500", textAlign: "right" }}>
                        Count
                      </td>
                      <td style={{ fontWeight: "500" }}>Amount</td>
                    </tr>
                    {customerLoanStatusQuery?.data?.headers?.map((item, index) => (
                      <tr key={index}>
                        <td className={classes.textColorBlue} onClick={() => handleClickAction(item)}>{item?.name}</td>
                        <td style={{ textAlign: "right" }} className={classes.textColorBlue} onClick={() => handleClickAction(item)}>
                          {customerLoanStatusQuery?.data?.data?.find((e) => e?.status === item?.key)
                            ?.count || 0}
                        </td>
                        <td>
                          <DisplayValue
                            value={
                              ["submitted", "pre_submit", "rejected"]?.includes(item?.key) ? 0 :
                                customerLoanStatusQuery?.data?.data?.find((e) => e?.status === item?.key)
                                  ?.amount || 0
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  : <tbody>
                    {[1, 2, 3, 4, 5]?.map((item) => (
                      <tr>
                        {[1, 2, 3]?.map((item) => (
                          <td><Skeleton height={12} my={'2px'} /></td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                }
              </DataTableWapper>
            }
          </Box>
          <Grid mt={'xs'} gutter={'xs'}>
            <Grid.Col span={12}>
              {(loanStatsQuery?.data?.length + feeStatsQuery?.data?.length + receiptStatsQuery?.data?.length) > 0 || (loanStatsQuery?.isLoading || feeStatsQuery?.isLoading || receiptStatsQuery?.isLoading)
                ? (
                  <>
                    {loanStatsQuery?.data?.length || loanStatsQuery?.isLoading
                      ? (
                        <Box className={classes.borderRadiusTopTableThree} p={'md'}>
                          <Group position="apart">
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                              <Title order={5} className={classes.tableThreeContentTitle}>
                                New / Enhancement Loans
                              </Title>
                              {/* overall count and sum of transfer has been hidden */}
                              <Badge>{handleTableDetails('count', loanStatsQuery?.data?.filter((e) => e?.type !== "transfer"), 'loan')}</Badge>
                            </Box>
                            <Text>
                              <DisplayValue value={handleTableDetails('amount', loanStatsQuery?.data?.filter((e) => e?.type !== "transfer"), 'loan')} color='gray' />
                            </Text>
                          </Group>
                          <DisplayContentTable dateObj={selectedPeriod} data={loanStatsQuery?.data?.filter(e => e?.type != 'advance_interest')} loading={loanStatsQuery?.isLoading} apiCall={'loan_details'} isHeader={false} />
                        </Box>
                      )
                      : null
                    }
                    {receiptStatsQuery?.data?.length || receiptStatsQuery?.isLoading
                      ? (
                        <Box sx={{ background: 'white' }} p={'md'} mt={'2px'}>
                          <Group position="apart">
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                              <Title order={5} className={classes.tableThreeContentTitle}>
                                Receipt vs Rollover Details
                              </Title>
                              <Badge>{handleTableDetails('count', receiptStatsQuery?.data)}</Badge>
                            </Box>
                            <Text>
                              <DisplayValue value={handleTableDetails('amount', receiptStatsQuery?.data)} color='gray' />
                            </Text>
                          </Group>
                          <DisplayContentTable dateObj={selectedPeriod} data={receiptStatsQuery?.data} apiCall={'receipt'} loading={receiptStatsQuery?.isLoading} />
                        </Box>
                      )
                      : null
                    }
                    {loanStatsQuery?.data?.filter(e => e?.type === 'advance_interest')?.length || loanStatsQuery?.isLoading
                      ? (
                        <Box className={classes.borderRadiusBottomTableThree} p={'md'} mt={'2px'}>
                          <Group position="apart">
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', cursor: 'pointer' }} onClick={() => handleClickAction('advance_interest')}>
                              <Title order={5} className={classes.tableThreeContentTitle}>
                                Advance Interest
                              </Title>
                              <Badge>{handleTableDetails('count', loanStatsQuery?.data?.filter(e => e?.type === 'advance_interest'))}</Badge>
                            </Box>
                            <Text>
                              <DisplayValue value={handleTableDetails('amount', loanStatsQuery?.data?.filter(e => e?.type === 'advance_interest'))} color='gray' />
                            </Text>
                          </Group>
                        </Box>
                      )
                      : null
                    }
                  </>
                )
                : null
              }
            </Grid.Col>
          </Grid>
        </Box>
        <Box sx={{ width: "50%" }}>
          <Box className={classes.contentCardSX}>
            <CreditBookComp bookData={historicalLoanBookQuery} npaData={historicalNPAQuery} writeOffData={historicalWriteOffQuery} NpaCallback={() => handleClickAction('npa')} WriteOffCallback={() => handleClickAction('write_off')} />
          </Box>
          <Grid mt={'xs'} gutter={'xs'}>
            <Grid.Col span={12}>
              {feeStatsQuery?.data?.length || feeStatsQuery?.isLoading
                ? (
                  <Box className={classes.taleThreeContentRoot} p={'md'}>
                    <Group position="apart">
                      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                        <Title order={5} className={classes.tableThreeContentTitle}>
                          Fee Details
                          {/* <Tooltip label={`This Fee Details contains values from ${dateFormat('04-01-2023', 'DD MMM YYYY')}`} color="gray" position="top-start" withArrow><span><InfoCircle color="gray" size={16} /></span></Tooltip> */}
                        </Title>
                        <Badge>{handleTableDetails('count', feeStatsQuery?.data)}</Badge>
                      </Box>
                      <Text>
                        <DisplayValue value={handleTableDetails('amount', feeStatsQuery?.data)} color='gray' />
                      </Text>
                    </Group>
                    <DisplayContentTable data={feeStatsQuery?.data} loading={feeStatsQuery?.isLoading} dateObj={selectedPeriod} apiCall={'fee'} />
                  </Box>
                )
                : null
              }
            </Grid.Col>
            <Grid.Col span={12}>
              <Box className={classes.taleThreeContentRoot} p={'md'} mt={'5px'}>
                <Group position="apart">
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                    <Title order={5} className={classes.tableThreeContentTitle}>
                      Interest / Penal Income
                    </Title>
                  </Box>
                </Group>
                {entitiesIncomeDetailsQuery?.isLoading || entitiesIncomeDetailsQuery?.data?.list?.length
                  ? <DisplayIncomeContentTable data={entitiesIncomeDetailsQuery?.data?.list} total={entitiesIncomeDetailsQuery?.data?.total} loading={entitiesIncomeDetailsQuery?.isLoading} />
                  : <Text mt={'lg'} size={'sm'} className={classes.noDataText}>No Data to Display</Text>
                }
              </Box>
            </Grid.Col>
          </Grid>
        </Box>
      </Box>
      {modalObj?.modal ? <DetailedViewModal open={modalObj?.modal} onClose={() => setModalObj({ modal: false })} data={modalObj?.data} titleObj={modalObj?.title} dateObj={selectedPeriod} headerName={modalObj?.header} apiCall={modalObj?.apiCall} /> : null}
    </Box>
  );
};

export default ManagementDashboard;
