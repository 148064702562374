import { Text } from '@mantine/core';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getApprovedIMD } from '../../services/loan.service';
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import Filter from '../../components/CommonComponents/Filter/Filter';
import Nums from '../../components/Body/Nums';
import SimpleReactTable from '../../components/CommonComponents/Table/SimpleReactTable';
import { createColumnHelper } from '@tanstack/react-table';
import { DrawerModal } from '../../components/CommonComponents/Drawer/DrawerModal';

const Loans = ({ open, onClose }) => {

  const columnHelper = createColumnHelper();
  const location = useLocation();
  const [filter, setFilter] = useState(true)
  const [loading, setLoading] = useState()
  const [approvedIMD, setApprovedIMD] = useState([])
  const [filterDetails, setFilterDetails] = useState([]);

  const navigate = useNavigate();

  const onSubmit = (values, filterData) => {
    setFilterDetails(filterData)
    setLoading(true)
    getApprovedIMD({ ...values })
      .then((res) => {
        setApprovedIMD(res)
      })
      .catch((e) => {
        setApprovedIMD()
        displayNotification({ id: 'customer-imd', message: e?.message || e, variant: 'error' })
      })
      .finally(() => {
        setLoading(false)
        setFilter(false)
      })
  }

  const columns = [
    columnHelper.accessor('customer_id', {
      header: 'Dealership Id',
    }),
    columnHelper.accessor('customer_name', {
      header: 'Name',
    }),
    columnHelper.accessor('approved_amount', {
      header: <Text sx={{textAlign: 'right'}}>Approved Amount</Text>,
      cell: (value) =>  <Nums value={value.getValue()} styles={{textAlign: 'right'}} />,
    }),
  ];
  return (
    <DrawerModal
      open={open}
      onClose={onClose}
      filterData={filterDetails}
      title='Find Tranche Details'
      position='right'
      padding='xl'
      size='50%'
    >
      {/* Used to show filter */}
      {filter ?
        <Filter
          callback={onSubmit}
          onClose={onClose}
          open={open}
          loading={loading}
          type={'loan'}
        /> :
        <SimpleReactTable
          rowData={approvedIMD}
          columnData={columns}
          filterData={filterDetails}
          setFilter={setFilter}
          showFilter
          sx={{ height: '70vh' }}
          rowKeyGetter={(row) => {
            return row?.id;
          }}
          onRowClick={(i, o) => navigate(`/tranche/${i?.customer_id}/${i?.customer_application_id}`, { state: { from: location.pathname } })}
        />
      }
    </DrawerModal>
  );
};

export default Loans;