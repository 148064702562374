import {
  Radio,
  Table,
  Box,
  Button,
  Popover,
  Title,
  Skeleton,
  Text,
  Badge,
  createStyles,
  TextInput,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { COLORS } from "../../../constants";
import Nums from "../../Body/Nums";
import { dateFormat } from "../../DateFormat/dateFormat";
import { useQuery } from "@tanstack/react-query";
import { getCreditReloadRequestByCustomerAppId } from "../../../services/customer.service";
import { keyframes } from "@emotion/react";
import { Search } from "tabler-icons-react";

const pulseEffect = keyframes({
  "0%": { transform: "scale(1, 1)", opacity: 0.4 },
  "50%": { opacity: 0.25 },
  "100%": { transform: "scale(1.15, 1.7)", opacity: 0 },
});

const useStyle = createStyles((them) => ({
  pulse: {
    // backgroundColor: them?.colors?.red,
    zIndex: 99,
    position: "relative",
    "&:before": {
      content: '" "',
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "red",
      zIndex: -1,
      borderRadius: 5,
      animation: `${pulseEffect} 1s ease infinite`,
    },
  },
}));

/**
 * Used to display list of credit reload requests
 * @props
 * data (optional not needed in view mode) - The list of reload requests can be passed on to this
 * mode (optional) - to decide whether to call reload req API or not. And to enable edit option to the user to select a request
 * default mode = view
 * type (optional) - type is used to check it is express crr or not
 * selectedRowId (optional on edit mode) - contain the user selected reload request id, To select the value as default in edit mode
 * and to get the data from api by sending the Id in view mode
 */

const CreditReloadDetails = ({
  data = [],
  submitCallback,
  selectedRowId,
  defaultSelectedId,
  type,
  mode = "view",
}) => {
  const { classes } = useStyle();
  // used to handel search
  const [search, setSearch] = useState("");
  const [checked, setChecked] = useState();
  const [creditReloadModal, setCreditReloadModal] = useState(false);

  /** getting the credit reload request list */
  const { data: creditReloadData = [...data], isFetching } = useQuery(
    ["credit-reload", selectedRowId],
    () => getCreditReloadRequestByCustomerAppId({ crr_id: selectedRowId }),
    {
      select: (response) => {
        return response.map((item) => ({
          ...item,
          // handle search
          search_key: Object.values(item).join().toLowerCase(),
        }));
      },
      enabled: Boolean(mode == "view"),
      refetchOnWindowFocus: false,
    }
  );

  /** used to set the selectedRowId */
  useEffect(() => {
    setChecked(`${selectedRowId}`);
  }, [selectedRowId]);

  return (
    <Popover
      opened={creditReloadModal}
      onChange={setCreditReloadModal}
      width={"56rem"}
      position={"left-start"}
      title="Credit Reload Request"
      withArrow
      shadow={"rgba(0, 0, 0, 0.2) 0px 20px 30px;"}
    >
      <Popover.Target>
        <Box mt={"lg"}>
          <Button
            size={"xs"}
            variant={"filled"}
            className={
              ((mode === "edit" && !selectedRowId) || type === 'express') ? classes?.pulse : null
            }
            color={"red"}
            rightIcon={
              mode === "edit" ? (
                <Badge variant="light" color="cyan" sx={{ color: "black" }}>
                  {creditReloadData?.length}
                </Badge>
              ) : null
            }
            onClick={() => setCreditReloadModal(!creditReloadModal)}
          >
            {type === 'express' ? 'Express ' : null}Credit Reload Reqs
          </Button>
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            <Title order={4} mb={"md"}>
              Credit Reload Request
            </Title>
            <Radio.Group
              value={checked}
              onChange={setChecked}
              description={
                (mode == "edit") ? "Select the Credit Reload Request" : null
              }
              withAsterisk
              styles={(them) => ({
                label: {
                  cursor: "pointer",
                },
              })}
            >
              {!isFetching && !creditReloadData?.length ? (
                /** display the text when there is no data */
                <Text sx={{ justifyContent: "center" }}>
                  No credit Reload Requests found!
                </Text>
              ) : (
                <>
                  {(mode === "edit" && !defaultSelectedId) ? (
                    <TextInput
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                      icon={<Search size={16} />}
                      size={"sm"}
                      sx={{ position: "absolute", top: "30px", right: "16px" }}
                    />
                  ) : null}
                  <Table
                    fontSize="sm"
                    highlightOnHover
                    horizontalSpacing="xl"
                    striped
                    verticalSpacing="xs"
                  >
                    <thead
                      style={{
                        backgroundColor: COLORS.background,
                        borderRadius: "10px",
                      }}
                    >
                      <tr>
                        {mode == "edit" && !defaultSelectedId && <th></th>}
                        <th>Req Date</th>
                        <th>Id</th>
                        <th>Req Amount</th>
                        <th>Bank</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isFetching
                        ? creditReloadData?.map((item, index) => {
                          if (
                            item?.search_key?.includes(search.toLowerCase())
                          ) {
                            return (
                              <tr
                                key={index}
                                onClick={() => {
                                  setChecked(`${item?.crr_id}`);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {mode == "edit" && !defaultSelectedId && (
                                  <td>
                                    <Radio value={`${item?.crr_id}`} />
                                  </td>
                                )}
                                <td>
                                  {dateFormat(
                                    item?.crr_date,
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </td>
                                <td>{item?.request_id}</td>
                                <td style={{ textAlign: "right" }}>
                                  <Nums value={item?.amount} />
                                </td>
                                <td>{item?.bank}</td>
                              </tr>
                            );
                          }
                        })
                        : [1, 2, 3, 4].map((item) => (
                          <tr>
                            {/** the loading when the api is fetching */}
                            <td>
                              <Skeleton height={15} />
                            </td>
                            <td>
                              <Skeleton height={15} />
                            </td>
                            <td>
                              <Skeleton height={15} />
                            </td>
                            <td>
                              <Skeleton height={15} />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </>
              )}
            </Radio.Group>
          </Box>
          {mode == "edit" && !defaultSelectedId ? (
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
              mt={"md"}
            >
              <Button
                onClick={() => {
                  /** if user click the clear button then we will clear the data */
                  setChecked();
                  submitCallback({});
                }}
                variant={"subtle"}
              >
                Clear
              </Button>
              <Button
                onClick={() => {
                  /** if user click the submit button then we will filter the selected value and set it */
                  submitCallback(
                    creditReloadData?.filter(
                      (item) => item?.crr_id == checked
                    )[0]
                  );
                  setCreditReloadModal(false);
                }}
                color={"green"}
              >
                Submit
              </Button>
            </Box>
          ) : null}
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};

export default CreditReloadDetails;
