import React from 'react'
import { Grid, Box } from '@mantine/core';
import DataViewerWapper from '../../../components/CommonComponents/Preview/DataViewerWapper';
import TitleViewer from './../../../components/CommonComponents/Title/TitleViewer';
import { ViewFormData } from '../../../components/CommonComponents/Preview/FilePreview';

/**
 * Renders an invoicing invoice review component with the given data.
 *
 * @param {Object} data - The data for the component to display.
 */
const InvoicingInvoiceReviewComp = ({ data }) => {
  return (
    <>
      <Grid gutter={'md'}>
        <Grid.Col span={6}>
          <DataViewerWapper>
            <Box mb={'sm'}>
              <TitleViewer title={"Invoicing Details"} />
            </Box>
            <ViewFormData
              label={'Bill-to Customer No.'}
              value={data?.data?.bill_to_customer_no}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'VAT Registration No.'}
              value={data?.data?.vat_registration_no}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'GLN'}
              value={data?.data?.gln}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Invoice Copies'}
              value={data?.data?.invoice_copies}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Invoice Disc. Code'}
              value={data?.data?.invoice_disc_code}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Copy Sell-to Addr. to Qte From'}
              value={data?.data?.copy_sell_to_addr_to_qte_from}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Gen. Bus. Posting Group'}
              value={data?.data?.gen_bus_posting_group}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'VAT Bus. Posting Group'}
              value={data?.data?.vat_bus_posting_group}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Excise Bus. Posting Group'}
              value={data?.data?.excise_bus_posting_group}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Customer Posting Group'}
              value={data?.data?.customer_posting_group}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Customer Price Group'}
              value={data?.data?.customer_price_group}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Customer Disc. Group'}
              value={data?.data?.customer_disc_group}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Allow Line Disc.'}
              value={data?.data?.allow_line_disc ? 'Yes' : 'No'}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Prices Including VAT'}
              value={data?.data?.prices_including_vat ? 'Yes' : 'No'}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Prepayment %'}
              value={data?.data?.prepayment}
              loading={data?.isLoading}
            />
          </DataViewerWapper>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default InvoicingInvoiceReviewComp
