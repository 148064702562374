import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Body from '../../components/Body/Body';
import DataTableWrapper from '../../components/CommonComponents/Table/DataTableWrapper';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import Nums from '../../components/Body/Nums';
import EntityFilter from './../../components/CommonComponents/Filter/EntityFilter';
import { getAllCustomersData } from '../../services/customer.service';
import { Text } from '@mantine/core';
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification';

const CustomersTable = () => {
    const navigate = useNavigate();
    const columnHelper = createColumnHelper();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [records, setRecords] = useState({ label: 'Show 10', value: 10 });

    /** used to change the page to 1 while the search us used 
     * because if we are in page 10 and we are searching something we will get the result in page 10
     */
    useEffect(() => {
        page != 1 && setPage(1)
    }, [search])

    const { data: customerData = [], isLoading } = useQuery(
        ['all-customer-data', page, search, records],
        () => getAllCustomersData({ page, search, records: records?.value }),
        {
            refetchOnWindowFocus: false,
        }
    );

    const onRowClick = (i) => {
        if (i?.customer_id) {
            navigate(`/borrower/${i?.customer_application_id}/${i?.customer_id}`);
        } else {
            displayNotification({ message: 'Customer Id is not found', variant: 'error' });
        }
    };

    const onClickAction = (value) => {
        return (
            <span
                style={{ color: '#0063FF', cursor: 'pointer' }}
            >
                {value.getValue()}
            </span>
        );
    };

    const columnData = [
        columnHelper.accessor('customer_id', {
            header: 'Customer ID',
            cell: (value) => onClickAction(value),
        }),
        columnHelper.accessor('customer_name', {
            header: 'Customer Name',
            cell: (value) => onClickAction(value),
        }),
        columnHelper.accessor('product_name', {
            header: 'Scheme',
        }),
        columnHelper.accessor('approved_amount', {
            header: <Text sx={{ textAlign: 'right' }}>Approved Amount</Text>,
            cell: (value) => <Nums value={value.getValue()} styles={{ textAlign: 'right' }} />,
        }),
        columnHelper.accessor('available_amount', {
            header: <Text sx={{ textAlign: 'right' }}>Available Amount</Text>,
            cell: (value) => <Nums value={value.getValue()} styles={{ textAlign: 'right' }} />,
        }),
        columnHelper.accessor('agreement_end_date', {
            header: 'Agreement Ends',
            cell: (value) => dateFormat(value.getValue(), 'DD MMM YYYY'),
        }),
    ];

    return (
        <Body background={false}>
            <DataTableWrapper
                title='Borrowers Details'
                filter={false}
                rowData={customerData?.data}
                columnData={columnData}
                onRowClick={onRowClick}
                noDataText="No Borrowers yet!"
                noDataSubText='This section will contain list of Borrowers details.'
                useApiPagination
                totalNoOfPages={customerData?.total_number_of_pages}
                totalNoOfRecords={customerData?.total_number_of_records}
                page={page}
                setPage={setPage}
                rowLength={records}
                setRowLength={setRecords}
                apiSearch={setSearch}
                loading={isLoading}
            />
        </Body>
    );
};

export default CustomersTable;
