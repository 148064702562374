import { apiCallProtected } from '../api/axios'
import { URL } from '../api/serverUrls'
import { displayNotification } from '../components/CommonComponents/Notification/displayNotification';
import { postAPICall } from './postAPICall';

export const getUsersPermissions = (token) => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get(URL.userPermission, {
        headers: {
          Authorization: `bearer ${token}`
        }
      })
      .then(({ status, data, message }) => {
        if (status === 'success') {
          resolve(data)
        } else {
          reject(message)
        }
      })
      .catch(reject);
  })
}

export const getFeeTypesByProductId = (productId, customerAppId) => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get(`product/${productId}/fees?customer_application_id=${customerAppId}`)
      .then(({ status, data, message }) => {
        if (status === 'success') {
          const result = data?.map(fee => ({
            label: fee.fee_name,
            value: fee.fee_id,
            ...fee
          }))
          resolve(result || [])
        } else {
          reject(message);
        }
      })
      .catch((error) => {
        reject(error.meassage);
      });
  });
};

export const getReviewer = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get('/users/reviewers')
      .then(({ status, data, message }) => {
        if (status === 'success') {
          const result = data?.map((item) => ({
            label: `${item.first_name} ${item.last_name}`,
            value: item.id,
            ...item,
          }));
          resolve(result || []);
        } else {
          reject(message);
        }
      })
      .catch(reject);
  });
};

export const getApprover = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get('/users/approvers')
      .then(({ status, data, message }) => {
        if (status === 'success') {
          const result = data?.map((item) => ({
            label: `${item.first_name} ${item.last_name}`,
            value: item.id,
            ...item,
          }));
          resolve(result || []);
        } else {
          reject(message);
        }
      })
      .catch(reject);
  });
};

export const getAllProductForFilter = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get('products')
      .then(({ status, data, message }) => {
        if (status === 'success') {
          const result = data?.map((item) => ({
            label: item?.name,
            value: item.id,
            ...item,
          }));
          resolve(result || []);
        } else {
          reject(message);
        }
      })
      .catch((error) => {
        reject(error?.meassage);
      });
  });
};

export const getAllProductById = (id) => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get(`product/${id}`)
      .then(({ status, data, message }) => {
        if (status === 'success') {
          resolve(data);
        } else {
          reject(message);
        }
      })
      .catch((error) => {
        reject(error?.meassage);
      });
  });
};

export const getAllRegionsForFilter = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get('regions')
      .then(({ status, data, message }) => {
        if (status === 'success') {
          const result = data?.map((item) => ({
            label: item?.name,
            value: item.id,
            ...item,
          }));
          resolve(result || []);
        } else {
          reject(message);
        }
      })
      .catch((error) => {
        reject(error?.meassage);
      });
  });
};

export const getAllCities = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get('cities')
      .then(({ status, data, message }) => {
        if (status === 'success') {
          const result = data?.map((item) => ({
            label: item?.name,
            value: item.id,
            ...item,
          }));
          resolve(result || []);
        } else {
          reject(message);
        }
      })
      .catch((error) => {
        reject(error?.meassage);
      });
  });
};

export const getAllStates = () => {
  return new Promise((resolve, reject) => {
    apiCallProtected
      .get('states')
      .then(({ status, data, message }) => {
        if (status === 'success') {
          const result = data?.map((item) => ({
            label: item?.name,
            value: item.id,
            ...item,
          }));
          resolve(result || []);
        } else {
          reject(message);
        }
      })
      .catch((error) => {
        reject(error?.meassage);
      });
  });
};

export const getSignedUrl = (body) => {
  return postAPICall(`cloudfront-signer`, { body: { url: body } })
    .catch(err => {
      displayNotification({ message: err?.message || err, variant: 'error' })
    });
};