import { useQuery } from "@tanstack/react-query";
import { getAllEntities } from "../../../services/dashboard.service";
import Select from 'react-select';
import { Box } from "@mantine/core";
import { useSearchParams } from "react-router-dom";

const styles = {
  menu: (provided) => ({ ...provided, zIndex: 9999 }), 
  option: (provided) => ({ ...provided, fontSize: '12px', padding: '4px 10px 4px 10px' }), 
  control: (provided) => ({ ...provided, fontSize: '12px', border: '1px solid #ddd' })
}

const defaultValue = { label: "All Entities", value: 'all' };

const EntityFilterDropdown = ({ value, onChange }) => {
  const { data: entities = [defaultValue] } = useQuery({
    queryKey: ["entities"],
    queryFn: getAllEntities,
    select: (data) => {
      return [defaultValue, ...data];
    },
    refetchOnWindowFocus: false,
  });

  return (
    <Box style={{ width: 140 }}>
      <Select
        defaultValue={defaultValue}
        value={entities.find(v => v.value == value)}
        options={entities}
        styles={styles}
        onChange={d => onChange(d)}
      />
    </Box>
  )
}

const EntityFilter = ({ value='all', onChange, useQueryParams }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  if (useQueryParams) {
    return (
      <EntityFilterDropdown
        value={searchParams?.get("entity") || value}
        onChange={(d) => {
          searchParams?.set("entity", d?.value); setSearchParams(searchParams)
        }}
      />
    )
  }

  return (
    <EntityFilterDropdown value={value} onChange={(d) => onChange(d?.value)} />
  )

}

export default EntityFilter;