import { Button, Text } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import Body from '../../components/Body/Body';
import Nums from '../../components/Body/Nums';
import DataTableWrapper from '../../components/CommonComponents/Table/DataTableWrapper';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import { getAllImd } from '../../services/imd.service';
import DisbursedLoans from './DisbursedLoans';
import { FEE_STATUS_VALUE } from '../../utils/StatusTable';
import {
    APPROVED,
    CANCELLED,
    IN_APPROVAL,
    IN_REVIEW,
    OPEN,
    REJECTED,
    CANCELLATION_REVIEW,
    CANCELLATION_APPROVAL,
    AUTO_CANCELLED,
    AUTO_APPROVED
} from '../../constants/status';
import EntityFilter from './../../components/CommonComponents/Filter/EntityFilter';
import useModuleRecordCountStore from '../../store/moduleRecordCountStore';

const ImdTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const columnHelper = createColumnHelper();
  const { moduleCount } = useModuleRecordCountStore();
  const [addIMD, setAddIMD] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [records, setRecords] = useState({label: 'Show 10', value: 10});
  // get the search params
  const [searchParams, setSearchParams] = useSearchParams();
  const params = {}
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  
  const { data: imdData = {}, isLoading } = useQuery(
    ['imd-table', page, search, records, params],
    () => getAllImd({page, search, records: records?.value, ...params}),
    {
      enabled: Boolean(searchParams.get("status") && searchParams.get("status") !== "none"),
      refetchOnWindowFocus: false,
    }
  );

  /** used to change the page to 1 while the search us used 
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1)
  }, [search, searchParams.get("status"), searchParams.get("entity"), records])

  const onRowClick = (i) => {
    if (i?.status_value == OPEN) {
      navigate(`view/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == IN_APPROVAL || i?.status_value == IN_REVIEW) {
      navigate(`review/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == APPROVED || i?.status_value == AUTO_APPROVED) {
      navigate(`approved/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == REJECTED) {
      navigate(`rejected/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == AUTO_CANCELLED) {
      navigate(`cancelled/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == CANCELLATION_REVIEW) {
      navigate(`/cancellation/fee-cancel/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == CANCELLATION_APPROVAL) {
      navigate(`/cancellation/fee-cancel/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == CANCELLED) {
      navigate(`/cancellation/fee-cancelled/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    }
  };

  const columnData = [
    columnHelper.accessor('customer_id', {
      header: 'Customer Id',
      cell: (value) => (
        <span
          style={{ color: '#0063FF', cursor: 'pointer' }}
        >
          {value.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor('customer_name', {
      header: 'Customer Name',
      cell: (value) => (
        <span
          style={{ color: '#0063FF', cursor: 'pointer' }}
        >
          {value.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor('fee_amount', {
      header: <Text sx={{textAlign: 'right'}}>Fee Amount</Text>,
      cell: (value) =>  <Nums value={value.getValue()} styles={{textAlign: 'right'}} />,
    }),
    columnHelper.accessor('gst', {
      header: <Text sx={{textAlign: 'right'}}>GST Amount</Text>,
      cell: (value) =>  <Nums value={value.getValue()} styles={{textAlign: 'right'}} />,
    }),
    columnHelper.accessor('imd_date', {
      header: 'Fee Date',
      cell: (value) => dateFormat(value.getValue()),
    }),
    columnHelper.accessor('maker_name', {
      header: 'Created By',
    }),
    columnHelper.accessor('fee_collection_type', {
      header: 'Fee Invoice Type',
      cell: (value) => (FEE_STATUS_VALUE?.[value.row.original?.fee_collection_type]),
    }),
  ];

  return (
    <Body background={false}>
      <DataTableWrapper
        title='Fee Invoice'
        filter={false}
        rowData={imdData?.data}
        showStatus
        module_name='imd'
        columnData={columnData}
        onRowClick={onRowClick}
        noDataText='No IMD details yet!'
        noDataSubText='This Section will contain list of created IMD details.'
        addDataCallback={() => setAddIMD(true)}
        useApiPagination
        totalNoOfPages= {Math.ceil(parseInt(moduleCount?.count)/records?.value)}
        totalNoOfRecords={moduleCount?.count}
        page={page}
        setPage={setPage}
        apiSearch={setSearch}
        rowLength={records}
        setRowLength={setRecords}
        // when the status is none then the loading is set to false other wise it will dependent on isFetching
        loading={searchParams.get("status") !== "none" ? isLoading : searchParams.get("status") === null ? true : false}
        options={<EntityFilter useQueryParams />}
        actions={
          <Button
            variant='filled'
            color='primary'
            leftIcon={<Plus size={18} />}
            size='sm'
            onClick={() => setAddIMD(true)}
          >
            Create Fee
          </Button>
        }
      />
      <DisbursedLoans addIMD={addIMD} setAddIMD={setAddIMD} />
    </Body>
  );
};

export default ImdTable;
