import {
  Box,
  createStyles,
  Text,
  Title,
  Alert,
  Group,
  Center,
  Grid,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import changeValueFormat from "../../utils/changeValueFormat";
import { dateFormat } from "../DateFormat/dateFormat";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from "victory";
import { useHover } from "@mantine/hooks";
import { currencyFormat } from "../Currency/Currency";
import { COLORS } from "../../constants";
import { IconAlertCircle } from "@tabler/icons-react";

const useStyles = createStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    gap: "5px",
  },
  inner: {
    display: "flex",
    flexDirection: "column",
  },
  displayAmount: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    fontWeight: "600",
    gap: "2px",
  },
  labelRoot: {
    display: "flex",
    cursor: "pointer",
    flexDirection: "row",
    width: "100%",
  },
  labelInner: {
    width: "15px",
    height: "15px",
    borderRadius: "4px",
  },
  loanBookText: {
    color: COLORS.text.light1,
    position: "absolute",
    zIndex: 1,
    right: "5%",
    left: "0",
    display: "flex",
    gap: "8px",
  },
  alertText: {
    display: "flex",
    justifyContent: "row",
    gap: "10px",
    alignItems: 'end',
  }
});

const CRORE = 10000000;

// used to display the currency in cr by clicking on
const DisplayValue = ({ value, type = "amount" }) => {
  const { classes } = useStyles();
  const [amountInCR, setAmountInCR] = useState(true);

  let FinalValue = changeValueFormat(value, type);

  let suffix;
  if (amountInCR) {
    FinalValue = changeValueFormat(value / CRORE, type, { round: false });
    suffix = "Cr";
  }

  return value ? (
    <Title
      order={6}
      className={classes.displayAmount}
    >
      {FinalValue} {suffix}
    </Title>
  ) : (
    "-"
  );
};

// display the data value for graph elements
const Label = ({ title, value, event, index, setEvent }) => {
  const { classes } = useStyles();
  const { hovered, ref } = useHover();

  useEffect(() => {
    setEvent(hovered ? { x: title, y: value } : {});
  }, [hovered]);

  return (
    <Box
      className={classes.labelRoot}
      mb={"sm"}
      ref={ref}
    >
      <Box
        mr={"5px"}
        mt={"3px"}
        className={classes.labelInner}
        sx={{ backgroundColor: `${colors[index - 1]}` }}
      />
      <Box>
        <Box>
          <Title
            order={6}
            sx={
              event?.x === title || hovered
                ? { color: colors[index - 1] }
                : null
            }
          >
            <DisplayValue value={value} />
          </Title>
        </Box>
        <Box>
          <Text
            color={
              event?.x === title || hovered
                ? colors[index - 1]
                : COLORS.text.light2
            }
            size={"sm"}
          >
            {title}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

// used in graph
const colors = ["tomato", "orange", "#39d47a"];

const CreditBookComp = ({ bookData, npaData, writeOffData, children, NpaCallback, WriteOffCallback, }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.title} mb={'xs'}>
        <Box className={classes.title}>
          <Group spacing={"sm"}>
            <Title order={5}>Credit Book</Title>
            <Text size={"md"} weight={500} color={COLORS.text.light1}>
              {dateFormat(bookData?.data?.[0]?.system_date, "DD MMM YYYY")}
            </Text>
          </Group>
        </Box>
      </Box>
      {children}
      <Grid>
        <Grid.Col>
          {npaData?.data?.[0]?.no_of_npa_customers ? (
            <Alert
              icon={<IconAlertCircle size={16} />}
              title={
                <Box className={classes.alertText}>
                  <Center>NPA ({npaData?.data?.[0]?.no_of_npa_customers})</Center>
                  -
                  <DisplayValue value={npaData?.data?.[0]?.npa_overdue_principle} />
                  <Text>({npaData?.data?.[0]?.npa_overdue_principle_percentage}%)</Text>
                </Box>
              }
              styles={() => ({ title: { padding: 0, marginBottom: 0 }, root: { cursor: "pointer" } })}
              color="orange"
              onClick={NpaCallback}
            />
          ) : null}
        </Grid.Col>
        <Grid.Col>
          {writeOffData?.data?.[0]?.no_of_write_off_customers ? (
            <Alert
              icon={<IconAlertCircle size={16} />}
              title={
                <Box className={classes.alertText}>
                  <Center>Write Off ({writeOffData?.data?.[0]?.no_of_write_off_customers})</Center>
                  -
                  <DisplayValue value={writeOffData?.data?.[0]?.write_off_overdue_principle} />
                  <Text>({writeOffData?.data?.[0]?.write_off_overdue_principle_percentage}%)</Text>
                </Box>
              }
              styles={() => ({ title: { padding: 0, marginBottom: 0 }, root: { cursor: "pointer" } })}
              color="orange"
              onClick={WriteOffCallback}
            />  
           ) : null} 
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default CreditBookComp;
