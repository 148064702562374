import { Alert } from "@mantine/core";
import React from "react";
import { AlertCircle } from "tabler-icons-react";

// contains the msg to show alert
const ReceiptAmountAlert = () => {
  return (
    <>
      <Alert
        icon={<AlertCircle size="1rem" />}
        mt={"md"}
        title="Alert!"
        color="red"
        variant="filled"
      >
        Your receipt amount is greater than the outstanding Due.
        <br />
        Would you like to proceed further?
      </Alert>
    </>
  );
};

export { ReceiptAmountAlert };
