import { Button, Text } from "@mantine/core";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Plus } from "tabler-icons-react";
import Body from "../../components/Body/Body";
import Nums from "../../components/Body/Nums";
import { StatusToken } from "../../components/CommonComponents/Preview/FilePreview";
import DataTableWrapper from "../../components/CommonComponents/Table/DataTableWrapper";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import {
  APPROVED,
  AUTO_APPROVED,
  AUTO_CANCELLED,
  CANCELLATION_APPROVAL,
  CANCELLATION_REVIEW,
  CANCELLED,
  IN_APPROVAL,
  IN_REVIEW,
  OPEN,
  REJECTED,
} from "../../constants/status";
import { STATUS_COLORS, STATUS_VALUE } from "../../utils/StatusTable";
import CustomerReceiptList from "./CustomerReceiptList";
import { useQuery } from "@tanstack/react-query";
import { getAllCreatedCustomerReceipts } from "../../services/customerReceipt.service";
import useModuleRecordCountStore from "../../store/moduleRecordCountStore";

const CustomerReceipt = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const columnHelper = createColumnHelper();
  const { moduleCount } = useModuleRecordCountStore();
  const [addReceipt, setAddReceipt] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [records, setRecords] = useState({ label: "Show 10", value: 10 });
  // get the search params
  const [searchParams, setSearchParams] = useSearchParams();
  const params = {}
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  /** used to change the page to 1 while the search us used
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1);
  }, [search, searchParams.get("status"), records]);

  const { data: receiptTable = [], isLoading } = useQuery({
    queryKey: ["all-customer-receipt", params, page, search, records],
    queryFn: () =>
      getAllCreatedCustomerReceipts({
        ...params,
        page,
        search,
        records: records?.value,
      }),
    enabled: Boolean(searchParams.get("status") && searchParams.get("status") !== "none"),
  });

  const onClickAction = (value) => {
    return (
      <span
        style={{ color: "#0063FF", cursor: "pointer" }}
        onClick={() => onRowClick(value.row.original)}
      >
        {value.getValue()}
      </span>
    );
  };

  const onRowClick = (i) => {
    if (i?.status_value == OPEN) {
      navigate(`/customer-receipt/view/${i?.customer_id}/${i?.id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == IN_APPROVAL || i?.status_value == IN_REVIEW) {
      navigate(`/customer-receipt/review/${i?.customer_id}/${i?.id}`, { state: { from: location.pathname } });
    } else if (
      i?.status_value == APPROVED ||
      i?.status_value == AUTO_APPROVED
    ) {
      navigate(`/customer-receipt/approved/${i?.customer_id}/${i?.id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == REJECTED) {
      navigate(`/customer-receipt/rejected/${i?.customer_id}/${i?.id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == AUTO_CANCELLED) {
      navigate(`/customer-receipt/cancelled/${i?.customer_id}/${i?.id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == CANCELLED) {
      navigate(
        `/cancellation/customer-level-receipt-cancelled/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } }
      );
    } else if (
      i?.status_value == CANCELLATION_REVIEW ||
      i?.status_value == CANCELLATION_APPROVAL
    ) {
      navigate(
        `/cancellation/customer-level-receipt-cancel/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } }
      );
    }
  };

  const tableAction = (
    <Button
      variant="filled"
      color="primary"
      leftIcon={<Plus size={18} />}
      sx={{ marginLeft: 12 }}
      size="sm"
      onClick={() => setAddReceipt(true)}
    >
      Create Customer Receipt
    </Button>
  );

  const columnData = [
    columnHelper.accessor("code", {
      header: "Code",
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor("customer_name", {
      header: "Customer Name",
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor("customer_id", {
      header: "Customer Id",
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor("product_name", {
      header: "Product Type",
    }),
    columnHelper.accessor("customer_level_receipt_amount", {
      header: <Text sx={{textAlign: 'right'}}>Receipt Amount</Text>,
      cell: (value) => <Nums value={value.getValue()} styles={{textAlign: 'right'}} />,
    }),
    columnHelper.accessor("receipt_date", {
      header: "Receipt Date",
      cell: (value) => dateFormat(value.getValue()),
    }),
    columnHelper.accessor("product_name", {
      header: "Product Type",
    }),
    columnHelper.accessor("maker_name", {
      header: "Created By",
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (value) => (
        <StatusToken
          label={STATUS_VALUE?.[value.row.original?.status_value]}
          color={STATUS_COLORS?.[value.row.original?.status_value]}
        />
      ),
    }),
  ];

  return (
    <Body background={false}>
      <DataTableWrapper
        title="Customer Receipt"
        rowData={receiptTable?.data}
        columnData={columnData}
        noDataText="No customer Receipts yet!"
        noDataSubText="This section will contain list of created customer receipt details."
        useApiPagination
        addDataCallback={() => setAddReceipt(true)}
        totalNoOfPages= {Math.ceil(parseInt(moduleCount?.count)/records?.value)}
        totalNoOfRecords={moduleCount?.count}
        page={page}
        setPage={setPage}
        rowLength={records}
        setRowLength={setRecords}
        showStatus
        module_name='customer_level_receipt'
        apiSearch={setSearch}
        actions={tableAction}
        // when the status is none then the loading is set to false other wise it will dependent on isFetching
        loading={searchParams.get("status") !== "none" ? isLoading : searchParams.get("status") === null ? true : false}
        filter={false}
      />
      <CustomerReceiptList
        open={addReceipt}
        onClose={() => setAddReceipt(false)}
      />
    </Body>
  );
};

export default CustomerReceipt;
