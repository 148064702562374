
export const action_id = {
  navigation: {
    management_dashboard_view: 'management_dashboard_view',
    dashboard_view: 'dashboard_view',
    fee_collection_view: 'fee_collection_view',
    tranch_view: 'tranch_view',
    gst_e_bill: 'gst_e_bill',
    e_invoice_view: 'customer_invoice_view',
    disb_memo_view: 'disb_memo_view',
    payments_view: 'payments_view',
    invoice_view: 'invoice_view',
    receipts_view: 'receipts_view',
    foreclosure_view: 'foreclosure_view',
    refund_view: 'refund_view',
    waiver_dashboard_view: 'waiver_dashboard_view',
    cancellation_view: 'cancellation_view',
    report_view: 'report_view',
    historical_report_view: 'historical_report_view',
    payment_history: 'payment_history',
    soa_view: 'soa_view',
  },
  settings_nav: {
    account_view: 'product_view',
    users_view: 'users_view',
    data_config_view: 'data_config_view',
    fee_view: 'fee_view',
    eod_view: 'eod_view',
    triggers: 'triggers',
    holiday_list: 'holiday_list',
  },
  reports: {
    accounting_entries: "accounting_entries_report",
    due: "due_report",
    over_due: "over_due_report",
    loan_book: "loan_book_report",
    disbursement: "disbursement_report",
    renewal_mapping: "renewal_mapping_report",
    receipt: "receipt_report",
    detailed_receipt: "detailed_receipt_report",
    receipt_excess: "receipt_excess_report",
    fee: "fee_report",
    partywise_loan_book: "partywise_loan_book_report",
    dpd: "dpd_report",
    passbook: "passbook_report",
    customer_report: "customer_report",
    unidentified_receipt_report: "unidentified_receipt_report",
    historical_loan_book: "historical_loan_book_report",
    legacy_disbursal_reports: "legacy_disbursal_report",
    legacy_receipt_reports: "legacy_receipt_report",
    legacy_accounting_detail_reports: "legacy_accounting_detail_report",
    tat_report: "tat_report",
    advance_interest_report: "advance_interest_report",
  },
  master: {
    eod_schedule: "eod_schedule",
  },
  button: {
    receipt_limit: 'receipt_limit',
    appropriation_type: 'appropriation_type',
  }
}