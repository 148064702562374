import { create } from "zustand";

// used to store the records count to handle the pagination
const useModuleRecordCountStore = create(
    (set, get) => {
        return {
            moduleCount: { module_name: null, count: 0 },

            /**
            * Updates the module count for a given module.
            *
            * @param {Object} params - The parameters for updating the module count.
            * @param {string} params.module_name - The name of the module.
            * @param {number} params.count - The count of the module.
            */
            addModuleCount: ({ module_name, count }) => {
                set(() => ({
                    moduleCount: { module_name: module_name, count: count },
                }));
            },

            /* Clears the module count. */
            clearModuleCount: () => {
                set(() => ({
                    moduleCount: { module_name: null, count: 0 },
                }));
            }
        }
    }
)

export default useModuleRecordCountStore;