import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Layout from '../pages/Layout';
import NotFound from '../pages/NotFound';
import Unauthorized from '../pages/Unauthorized';
import Home from '../pages/Home';
import ImdTable from '../pages/IMD/ImdTable';
import ImdCreation from '../pages/IMD/ImdCreation';
import ImdReview from '../pages/IMD/ImdReview';
import LoansTable from '../pages/Loans/LoansTable';
import LoanCreation from '../pages/Loans/LoanCreation';
import LoanReview from '../pages/Loans/LoanReview';
import DisbTable from '../pages/Disb/DisbTable';
import DisbCreation from '../pages/Disb/DisbCreation';
import DisbReview from '../pages/Disb/DisbReview';
import InvoiceCreation from '../pages/Invoice/InvoiceCreation';
import InvoiceReview from '../pages/Invoice/InvoiceReview';
import ForeclosureTable from '../pages/Foreclosure/ForeclosureTable';
import ReceiptCreation from '../pages/Receipt/ReceiptCreation';
import ReceiptReview from '../pages/Receipt/ReceiptReview';
import ReceiptTable from '../pages/Receipt/ReceiptTable';
import ForeclosureReview from '../pages/Foreclosure/ForeclosureReview';
import WaiverTable from '../pages/Waiver/WaiverTable';
import WaiverCreation from '../pages/Waiver/WaiverCreation';
import WaiverReview from '../pages/Waiver/WaiverReview';
import PaymentTable from '../pages/Payment/PaymentTable';
import PaymentCreation from '../pages/Payment/PaymentCreation';
import PaymentReview from '../pages/Payment/PaymentReview';
import SettingsLayout from '../pages/Master/Layout/SettingsLayout';
import Master from '../pages/Master/Settings/Master';
import FeeTable from '../pages/Master/Settings/Fee/FeeTable';
import HolidayTable from '../pages/Master/Settings/HolidayMaster/HolidayTable';

// import Accounts from '../pages/Master/Settings/Accounts/Accounts';
import EODTable from '../pages/Master/Settings/EOD/EODTable';
import Report from '../pages/Report/Report';
import UnidentifiedReceiptTable from '../pages/Receipt/UnidentifiedReceipt';
import InvoiceManualTable from '../pages/Invoice/InvoiceManualTable';
import PaymentHistorySearch from '../pages/PaymentHistory/PaymentHistorySearch';
import PaymentHistoryPage from '../pages/PaymentHistory/PaymentHistory';
import ReportTable from '../pages/Report/ReportTable';
import Modules from '../pages/Master/Settings/ModulesPage/Modules';
import CancellationTable from '../pages/Cancel/CancellationTable';
import PassbookReport from '../pages/Report/PassbookReport';
import SOAFilter from '../pages/SOA/SOAFilter';
import UsersTable from '../pages/Master/Settings/User/UsersTable';
import Accounts from '../pages/Master/Settings/Accounts/Accounts';
import HistoricalReport from '../pages/Report/HistoricalReport';
import UnidentifiedReceiptCreation from '../pages/Receipt/UnidentifiedReceiptCreation';
import UnidentifiedReceiptReview from '../pages/Receipt/UnidentifiedReceiptReview';
import useSystemConfigStore from '../store/systemConfigStore';
import FeeReport from '../pages/Report/FeeReport';
import DisbBatchReview from '../pages/DisbBatch/DisbBatchReview';
import DisbBatchTable from '../pages/DisbBatch/DisbBatchTable';
import ManagementDashboard from '../pages/ManagementDashboard';
import CustomerReceipt from '../pages/Receipt/CustomerReceipt';
import CustomerReceiptCreation from '../pages/Receipt/CustomerReceiptCreation';
import CustomerReceiptReview from '../pages/Receipt/CustomerReceiptReview';
import CustomersTable from '../pages/Customers/CustomersTable';
import CustomerDetails from '../pages/Customers/CustomerDetails';
import { Triggers } from '../pages/Master/Settings/Triggers';
import GSTEbillTable from '../pages/GST E-bill/GSTEbillTable';
import CustomerInvoiceTable from '../pages/InvoiceCoutomer/CustomerInvoiceTable';
import CustomerInvoiceCreation from '../pages/InvoiceCoutomer/CustomerInvoiceCreation';
import CustomerInvoiceReview from '../pages/InvoiceCoutomer/CustomerInvoiceReview';
import ManualCustomerInvoiceCreation from '../pages/InvoiceCoutomer/ManualCustomerInvoiceCreation';
import ManualCustomerInvoiceTable from './../pages/InvoiceCoutomer/ManualCustomerInvoiceTable';
import ManualCustomerInvoiceReview from '../pages/InvoiceCoutomer/ManualCustomerInvoiceReview';
import { useNetwork } from '@mantine/hooks';
import { displayNotification } from '../components/CommonComponents/Notification/displayNotification';
import { displayUpdatedNotification } from '../components/CommonComponents/Notification/displayUpdatedNotification';
import RefundTable from '../pages/Refund/RefundTable';
import RefundCreation from '../pages/Refund/RefundCreation';
import RefundReview from '../pages/Refund/RefundReview';
import CibilReport from '../pages/Report/CibilReport';
import BulkReceipt from '../pages/Receipt/BulkReceipt';
import BulkReceiptReview from '../pages/Receipt/BulkReceiptReview';
import OfficeTiming from '../pages/Master/Settings/OfficeTiming/OfficeTiming';
import ExcessAmountReceiptTable from '../pages/Receipt/Excess amount/ExcessAmountReceiptTable';

const Routing = () => {
  const fetchSystemConfig = useSystemConfigStore(store => store.fetchSystemConfig);

  fetchSystemConfig();

  const network = useNetwork()
  if (!network.online) {
    displayNotification({ id: "offline", loading: true, title: "You're offline", message: "Oops! Internet is disconnected", variant: 'error', autoClose: false })
  } else {
    displayUpdatedNotification({ id: "offline", loading: false, title: "You're back online", message: "Hurray! Internet is connected", variant: 'success', autoClose: true })
  }

  return (
    <Routes>
      <Route path='/' element={<Layout />}>

        {/*
          Customer Route
        */}
        <Route element={<ProtectedRoute path='/imd/verify' />}>
          <Route path='borrower'>
            <Route path='' element={<CustomersTable />} />
            <Route path=':customerAppId/:custId' element={<CustomerDetails />} />
          </Route>
        </Route>

        {/* 
          IMD Route
        */}
        <Route element={<ProtectedRoute path='/imd/verify' />}>
          <Route path='fee-invoice'>
            <Route path='' element={<ImdTable />} />
            <Route path=':custId' element={<ImdCreation />} />
            <Route path='view/:imdId/:custId' element={<ImdCreation formType='draft' />} />
            <Route path='review/:imdId/:custId' element={<ImdReview />} />
            <Route path='approved/:imdId/:custId' element={<ImdReview action={false} />} />
            <Route path='rejected/:imdId/:custId' element={<ImdReview action={false} />} />
            <Route path='cancelled/:imdId/:custId' element={<ImdReview action={false} />} />
          </Route>
        </Route>

        {
          /*GST E-bill*/
        }
        <Route element={<ProtectedRoute path='/imd/verify' />}>
          <Route path='e-invoice'>
            <Route path='' element={<GSTEbillTable />} />
            <Route path=':imdId/:custId' element={<ImdReview type={'e-bill'} />} />
            <Route path='cancelled/:imdId/:custId' element={<ImdReview type={'e-bill'} action={false} />} />
          </Route>
        </Route>

        {/* 
          Loans Route
        */}
        <Route element={<ProtectedRoute path='/imd/verify' />}>
          <Route path='tranche' element={<LoansTable />} />
          <Route path='tranche/:loanId/:customerAppId' element={<LoanCreation />} />
          <Route path='tranche/view/:createdLoanId/:loanId/:customerAppId' element={<LoanCreation formType='view' />} />
          <Route path='tranche/review/:createdLoanId/:loanId' element={<LoanReview />} />
          <Route path='tranche/approved/:createdLoanId/:loanId' element={<LoanReview action={false} />} />
          <Route path='tranche/rejected/:createdLoanId/:loanId' element={<LoanReview action={false} />} />
          <Route path='tranche/cancelled/:createdLoanId/:loanId' element={<LoanReview action={false} />} />
        </Route>

        {/* 
          Disb Route
        */}
        <Route element={<ProtectedRoute path="/imd/verify" />}>
          <Route path='disb' element={<DisbTable />} />
          <Route path="disb/:disbId/:loansId" element={<DisbCreation />} />
          <Route path="disb/view/:createdDisbId/:disbId/:loansId" element={<DisbCreation formType="view" />} />
          <Route path="disb/review/:createdDisbId/:disbId" element={<DisbReview />} />
          <Route path='disb/approved/:createdDisbId/:disbId' element={<DisbReview action={false} />} />
          <Route path='disb/rejected/:createdDisbId/:disbId' element={<DisbReview action={false} />} />
          <Route path='disb/cancelled/:createdDisbId/:disbId' element={<DisbReview action={false} />} />
        </Route>

        {/* 
          Disb Badge Route
        */}
        <Route element={<ProtectedRoute path="/imd/verify" />}>
          <Route path='batch-disb'>
            <Route path='' element={<DisbBatchTable />} />
            <Route path=':BatchId' element={<DisbBatchReview />} />
            <Route path='rejected/:BatchId' element={<DisbBatchReview />} />
          </Route>
        </Route>

        {/* 
          Payment Route
        */}
        <Route element={<ProtectedRoute path='/imd/verify' />}>
          <Route path='/payment' element={<PaymentTable />} />
          <Route path="payment/:paymentId/:loansId" element={<PaymentCreation />} />
          <Route path="payment/view/:createdPaymentId/:paymentId/:loansId" element={<PaymentCreation formType="view" />} />
          <Route path="payment/review/:createdPaymentId/:paymentId/:loansId" element={<PaymentReview />} />
          <Route path='payment/approved/:createdPaymentId/:paymentId/:loansId' element={<PaymentReview action={false} />} />
          <Route path='payment/rejected/:createdPaymentId/:paymentId/:loansId' element={<PaymentReview action={false} />} />
          <Route path='payment/cancelled/:createdPaymentId/:paymentId/:loansId' element={<PaymentReview action={false} />} />
        </Route>

        {/* 
          Settings Route
        */}
        <Route element={<ProtectedRoute path='/imd/verify' />}>
          <Route path='/settings' element={<SettingsLayout />}>
            <Route path='account' element={<Accounts />} />
            <Route path='users' element={<UsersTable />} />
            <Route path='modules' element={<Modules />} />
            <Route path='data-config' element={<Master />} />
            <Route path='fee' element={<FeeTable />} />
            <Route path='eod-details' element={<EODTable />} />
            <Route path='triggers' element={<Triggers />} />
            <Route path='holiday-list' element={<HolidayTable />} />
            <Route path='office-timing' element={<OfficeTiming />} />
          </Route>
        </Route>

        {/* 
          Invoice Route
          Auto Invoice is hided in UI level due some naming issue
        */}
        <Route element={<ProtectedRoute path='/imd/verify' />}>
          <Route path='invoice-manual' element={<InvoiceManualTable formType="manual" />} />
          {/* <Route path='invoice-auto' element={<InvoiceAutoTable />} formType="auto" /> */}
          <Route path='invoice-manual/manual' element={<InvoiceCreation />} />
          <Route path="invoice-manual/view/:createdID" element={<InvoiceCreation formType="view" />} />
          <Route path='invoice-manual/approved/:createdID' element={<InvoiceReview action={false} />} />
          <Route path='invoice-manual/cancelled/:createdID' element={<InvoiceReview action={false} />} />
          {/* <Route path='invoice-auto/approved/:dealerID/:createdID' element={<InvoiceReview action={false} />} /> */}
          <Route path='invoice-manual/rejected/:createdID' element={<InvoiceReview action={false} />} />
          <Route path="invoice-manual/review/:createdID" element={<InvoiceReview />} />
        </Route>

        {/**
         * Manual Invoice for External Customer         
         */}
        <Route element={<ProtectedRoute path='/imd/verify' />}>
          <Route path='external-customer-invoice'>
            <Route path='' element={<ManualCustomerInvoiceTable />} />
            <Route path='creation' element={<ManualCustomerInvoiceCreation />} />
            <Route path='view/:createdID' element={<ManualCustomerInvoiceCreation />} />
            <Route path='review/:createdID' element={<ManualCustomerInvoiceReview />} />
            <Route path='approved/:createdID' element={<ManualCustomerInvoiceReview />} />
            <Route path='rejected/:createdID' element={<ManualCustomerInvoiceReview />} />
            <Route path='cancel/:createdID' element={<ManualCustomerInvoiceReview />} />
            <Route path='cancelled/:createdID' element={<ManualCustomerInvoiceReview />} />
          </Route>
        </Route>

        {/*
          Customer Invoice Route
        */}
        <Route element={<ProtectedRoute path='/imd/verify' />}>
          <Route path='external-customer'>
            <Route path='' element={<CustomerInvoiceTable />} />
            <Route path='create' element={<CustomerInvoiceCreation />} />
            <Route path='view/:createdInvoiceId' element={<CustomerInvoiceCreation />} />
            <Route path='review/:createdInvoiceId' element={<CustomerInvoiceReview />} />
            <Route path='approved/:createdInvoiceId' element={<CustomerInvoiceReview />} />
            <Route path='rejected/:createdInvoiceId' element={<CustomerInvoiceReview />} />
            <Route path='cancel/:createdInvoiceId' element={<CustomerInvoiceReview />} />
            <Route path='cancelled/:createdInvoiceId' element={<CustomerInvoiceReview />} />
          </Route>
        </Route>

        {/* 
          Receipt Route
        */}
        <Route element={<ProtectedRoute path='/imd/verify' />}>

          <Route path='/receipt' element={<ReceiptTable />} />
          <Route path='/receipt/:receiptId/:loanId' element={<ReceiptCreation />} />
          <Route path='/receipt/view/:receiptId/:loanId/:createdReceiptId' element={<ReceiptCreation formType="view" />} />
          <Route path='/receipt/approved/:receiptId/:createdReceiptId' element={<ReceiptReview action={false} />} />
          <Route path='/receipt/rejected/:receiptId/:createdReceiptId' element={<ReceiptReview action={false} />} />
          <Route path='/receipt/cancelled/:receiptId/:createdReceiptId' element={<ReceiptReview action={false} />} />
          <Route path='/receipt/review/:receiptId/:createdReceiptId' element={<ReceiptReview />} />

          <Route path='/bulk-receipt' element={<BulkReceipt />} />
          <Route path='/bulk-receipt/approved/:bulkReceiptId' element={<BulkReceiptReview action={false} />} />
          <Route path='/bulk-receipt/rejected/:bulkReceiptId' element={<BulkReceiptReview action={false} />} />
          <Route path='/bulk-receipt/cancelled/:bulkReceiptId' element={<BulkReceiptReview action={false} />} />
          <Route path='/bulk-receipt/cancellation/:bulkReceiptId' element={<BulkReceiptReview action={false} />} />
          <Route path='/bulk-receipt/review/:bulkReceiptId' element={<BulkReceiptReview />} />

          <Route path='/unidentified-receipt' element={<UnidentifiedReceiptTable />} />
          <Route path='/unidentified-receipt/creation' element={<UnidentifiedReceiptCreation formType={'view'} />} />
          <Route path='/unidentified-receipt/:createdUnReceiptId' element={<UnidentifiedReceiptCreation />} />
          <Route path='/unidentified-receipt/review/:createdUnReceiptId' element={<UnidentifiedReceiptReview />} />
          <Route path='/unidentified-receipt/approved/:createdUnReceiptId' element={<UnidentifiedReceiptReview formType="view" />} />
          <Route path='/unidentified-receipt/cancelled/:createdUnReceiptId' element={<UnidentifiedReceiptReview />} />
          <Route path='/unidentified-receipt/rejected/:createdUnReceiptId' element={<UnidentifiedReceiptReview />} />

          <Route path='/customer-receipt' element={<CustomerReceipt />} />
          <Route path='/customer-receipt/:customerId' element={<CustomerReceiptCreation />} />
          <Route path='/customer-receipt/view/:customerId/:receiptId' element={<CustomerReceiptCreation formType={'view'} />} />
          <Route path='/customer-receipt/review/:customerId/:receiptId' element={<CustomerReceiptReview />} />
          <Route path='/customer-receipt/approved/:customerId/:receiptId' element={<CustomerReceiptReview />} />
          <Route path='/customer-receipt/rejected/:customerId/:receiptId' element={<CustomerReceiptReview />} />
          <Route path='/customer-receipt/cancelled/:customerId/:receiptId' element={<CustomerReceiptReview />} />

          <Route path='/excess-amount' element={<ExcessAmountReceiptTable />} />

        </Route>

        {/* 
          SOA
        */}
        <Route element={<ProtectedRoute path='/imd/verify' />}>
          <Route path='/soa' element={<SOAFilter />} />
        </Route>

        {/* 
          Foreclosure Route
        */}
        <Route element={<ProtectedRoute path='/imd/verify' />}>
          <Route path='foreclosure' element={<ForeclosureTable />} />
          <Route path='foreclosure/approved/:custId/:createdForeclosure' element={<ForeclosureReview action={false} />} />
          <Route path='foreclosure/rejected/:custId/:createdForeclosure' element={<ForeclosureReview action={false} />} />
        </Route>

        {/* 
          PaymentHistory Route
        */}
        <Route element={<ProtectedRoute path='/imd/verify' />}>
          <Route path='payment-history' element={<PaymentHistorySearch />} />
          <Route path='payment-history/:prospectCode' element={<PaymentHistoryPage />} />
        </Route>

        {/* 
          Refund Route
        */}
        <Route element={<ProtectedRoute path='/imd/verify' />}>
          <Route path='refund'>
            <Route path='' element={<RefundTable />} />
            <Route path=':customerId' element={<RefundCreation />} />
            <Route path='view/:customerId/:refundId' element={<RefundCreation />} />
            <Route path='review/:customerId/:refundId' element={<RefundReview />} />
            <Route path='approval/:customerId/:refundId' element={<RefundReview />} />
            <Route path='approved/:customerId/:refundId' element={<RefundReview />} />
            <Route path='rejected/:customerId/:refundId' element={<RefundReview />} />
            <Route path='cancelled/:customerId/:refundId' element={<RefundReview />} />
          </Route>
        </Route>

        {/* 
          Waiver Route
        */}
        <Route element={<ProtectedRoute path='/imd/verify' />}>
          <Route path='/waiver' element={<WaiverTable />} />
          <Route path='/waiver/:custId/:loansId' element={<WaiverCreation />} />
          <Route path='/waiver/view/:custId/:loansId/:createdwaiverId' element={<WaiverCreation formType="view" />} />
          <Route path='/waiver/approved/:custId/:createdwaiverId' element={<WaiverReview action={false} />} />
          <Route path='/waiver/rejected/:custId/:createdwaiverId' element={<WaiverReview action={false} />} />
          <Route path='/waiver/cancelled/:custId/:createdwaiverId' element={<WaiverReview action={false} />} />
          <Route path='/waiver/review/:custId/:createdwaiverId' element={<WaiverReview />} />
        </Route>

        {/** Report Route */}
        <Route element={<ProtectedRoute path='/imd/verify' />}>
          <Route path='/cancellation' element={<CancellationTable />} />
          <Route path='/cancellation/fee-cancel/:imdId/:custId' element={<ImdReview home={'/cancellation'} />} />
          <Route path='/cancellation/fee-rejected/:imdId/:custId' element={<ImdReview home={'/cancellation'} />} />
          <Route path='/cancellation/fee-cancelled/:imdId/:custId' element={<ImdReview home={'/cancellation'} />} />
          <Route path='/cancellation/tranche-cancel/:createdLoanId/:loanId' element={<LoanReview home={'/cancellation'} />} />
          <Route path='/cancellation/tranche-rejected/:createdLoanId/:loanId' element={<LoanReview home={'/cancellation'} />} />
          <Route path='/cancellation/tranche-cancelled/:createdLoanId/:loanId' element={<LoanReview home={'/cancellation'} />} />
          <Route path='/cancellation/disb-cancel/:createdDisbId/:disbId' element={<DisbReview home={'/cancellation'} />} />
          <Route path='/cancellation/disb-rejected/:createdDisbId/:disbId' element={<DisbReview home={'/cancellation'} />} />
          <Route path='/cancellation/disb-cancelled/:createdDisbId/:disbId' element={<DisbReview home={'/cancellation'} />} />
          <Route path='/cancellation/payment-cancel/:createdPaymentId/:paymentId/:loansId' element={<PaymentReview home={'/cancellation'} />} />
          <Route path='/cancellation/payment-rejected/:createdPaymentId/:paymentId/:loansId' element={<PaymentReview home={'/cancellation'} />} />
          <Route path='/cancellation/payment-cancelled/:createdPaymentId/:paymentId/:loansId' element={<PaymentReview home={'/cancellation'} />} />
          <Route path='/cancellation/receipt-cancel/:createdReceiptId/:receiptId/' element={<ReceiptReview home={'/cancellation'} />} />
          <Route path='/cancellation/receipt-rejected/:createdReceiptId/:receiptId/' element={<ReceiptReview home={'/cancellation'} />} />
          <Route path='/cancellation/receipt-cancelled/:createdReceiptId/:receiptId/' element={<ReceiptReview home={'/cancellation'} />} />
          <Route path='/cancellation/unidentified-receipt-cancel/:createdUnReceiptId/' element={<UnidentifiedReceiptReview home={'/cancellation'} />} />
          <Route path='/cancellation/unidentified-receipt-rejected/:createdUnReceiptId/' element={<UnidentifiedReceiptReview home={'/cancellation'} />} />
          <Route path='/cancellation/unidentified-receipt-cancelled/:createdUnReceiptId/' element={<UnidentifiedReceiptReview home={'/cancellation'} />} />
          <Route path='/cancellation/customer-level-receipt-cancel/:receiptId/:customerId/' element={<CustomerReceiptReview home={'/cancellation'} />} />
          <Route path='/cancellation/customer-level-receipt-cancelled/:receiptId/:customerId/' element={<CustomerReceiptReview home={'/cancellation'} />} />
          <Route path='/cancellation/waiver-cancel/:createdwaiverId/:custId/' element={<WaiverReview home={'/cancellation'} />} />
          <Route path='/cancellation/waiver-rejected/:createdwaiverId/:custId/' element={<WaiverReview home={'/cancellation'} />} />
          <Route path='/cancellation/waiver-cancelled/:createdwaiverId/:custId/' element={<WaiverReview home={'/cancellation'} />} />
          <Route path='/cancellation/invoice-cancel/:createdID/:id' element={<InvoiceReview home={'/cancellation'} />} />
          <Route path='/cancellation/invoice-rejected/:createdID/:id' element={<InvoiceReview home={'/cancellation'} />} />
          <Route path='/cancellation/invoice-cancelled/:createdID/:id' element={<InvoiceReview home={'/cancellation'} />} />
          <Route path='/cancellation/batch-disb-cancel/:BatchId' element={<DisbBatchReview home={'/cancellation'} />} />
          <Route path='/cancellation/batch-disb-cancelled/:BatchId' element={<DisbBatchReview home={'/cancellation'} />} />
          <Route path='/cancellation/refund-cancel/:refundId/:customerId' element={<RefundReview home={'/cancellation'} />} />
          <Route path='/cancellation/refund-cancelled/:refundId/:customerId' element={<RefundReview home={'/cancellation'} />} />
        </Route>

        {/** Report Route */}
        <Route element={<ProtectedRoute path='/imd/verify' />}>
          <Route path='/report' element={<Report />} />
          <Route path='/historical-report' element={<HistoricalReport />} />
          <Route path='/report/passbook-report' element={<PassbookReport />} />
          <Route path='/report/bureau-report' element={<CibilReport />} />
          <Route path='/report/fee-report' element={<FeeReport withEntityFilter />} />
          <Route path='/report/:reportName' element={<ReportTable withEntityFilter />} />
          <Route path='/historical-report/:reportName' element={<ReportTable withEntityFilter={false} />} />
        </Route>

        {/* 
          Default Home Route
        */}
        <Route element={<ProtectedRoute path='/imd/verify' />}>
          <Route path='/' element={<Home />} />
          <Route path='/dashboard' element={<ManagementDashboard />} />
        </Route>

        {/* 
          Unprotected Route handling
        */}
        <Route path='/unauthorized' element={<Unauthorized />} />
        <Route path='/login' element={<Navigate to={'/'} />} />
        <Route path='/disbursement' element={<Navigate to={'/tranche'} />} />
        <Route path='/invoice' element={<Navigate to={'/invoice-manual'} />} />
        <Route path='/receipts' element={<Navigate to={'/receipt'} />} />
        <Route path='/reports' element={<Navigate to={'/report'} />} />
        <Route path='*' element={<NotFound />} />
      </Route>

    </Routes>
  );
};

export default Routing;
