import { Box, Grid, Image, Title } from '@mantine/core';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import DataViewerWapper from '../../components/CommonComponents/Preview/DataViewerWapper';
import {
  ViewFormData,
  ViewFormDataContained,
} from '../../components/CommonComponents/Preview/FilePreview';
import ImdStyle from '../IMD/Imd.styles';
import { useQuery } from '@tanstack/react-query';
import TitleViewer from '../../components/CommonComponents/Title/TitleViewer';
import Body from '../../components/Body/Body';
import DashBoardCustomerDetails from '../../components/DashboardCard/dashboard';
import {
  CompanyBankDetails,
} from '../../services/imd.service';
import {
  SendForRejectDisb,
  SendForPushbackDisb,
  getDisbById,
  getAllRemarksDisb,
  sendForCancellationDisb,
  sendDisbforNextStage
} from '../../services/disbursement.service';
import { getDeductionLoansbyID } from '../../services/loan.service';
import { STAGE_CHANGE_TEXT, PDF_TEXT_MESSAGE, GENERAL_IMG } from '../../constants';
import Remarks from '../../components/CommonComponents/Remarks/Remarks';
import PageTitle from '../../components/PageTitle/PageTitle';
import Nums from '../../components/Body/Nums';
import IdBadge from '../../components/CommonComponents/Preview/IdBadge';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import ViewRemark from '../../components/Remark/ViewRemark';
import ModalDataViwer from '../../components/SucessfullModal/ModalDataViwer';
import ActionButtons
  from '../../components/CommonComponents/ActionButtons/ActionButtons';
import { generateDisbMEMOPdfbyID } from '../../services/pdf.service';
import { useQueryClient } from "@tanstack/react-query";
import LoanRenewalTable from '../../components/CommonComponents/RenewalTable/LoanRenewalTable';
import { getStageforModules } from '../../services/module.service';
import { CANCELLATION_REVIEW, IN_APPROVAL, IN_REVIEW } from '../../constants/status';
import CreditReloadDetails
  from '../../components/CommonComponents/CreditReload/CreditReloadDetails';
import { ViewFeeDetailsWithGST } from '../../components/FeePopover/FeeCalculationPopover';
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification';
import { getCustomerBankById } from '../../services/customer.service';
import { openFile } from '../../utils/openFile';
import ReloadFeePopover from '../../components/FeePopover/ReloadFeePopover';

const DisbReview = ({ action = true, home = '/disb' }) => {
  const { disbId, createdDisbId } = useParams();
  const [loading, setLoading] = useState(true);
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState();
  const [remarksTitle, setRemarksTitle] = useState();
  const [label, setLabel] = useState();
  const [remarksLoading, setRemarksLoading] = useState();
  const [sucessfulmodal, setSucessfulmodal] = useState({});
  const [prospectcodebody, setProspectcodebody] = useState(null);
  const [pdfLoading, setpdfLoading] = useState();
  const queryClient = useQueryClient();

  const { data: disbDataById = [] } = useQuery(
    ['disb-byid', createdDisbId],
    () => createdDisbId && getDisbById(createdDisbId),
    {
      onSuccess: (data) => {
        setLoading(false);
        setProspectcodebody({
          id: data?.deduction_loan_ids?.length
            ? data?.deduction_loan_ids
            : null,
        });
      },
      refetchOnWindowFocus: false
    }
  );

  const { data: stages = [] } = useQuery(
    ['disb-stages'],
    () => getStageforModules('disbursement'),
    {
      enabled: Boolean(disbDataById?.status_value),
      select: (dataItem) => {
        return {
          'current_stage': disbDataById?.status_value,
          'next_stage': dataItem[dataItem.indexOf(disbDataById?.status_value) + 1]
        }
      },
      refetchOnWindowFocus: false
    }
  );

  const { data: ProspectCode = [] } = useQuery(
    ['prospectcode', prospectcodebody],
    () => prospectcodebody && getDeductionLoansbyID(disbDataById?.loan_id, prospectcodebody),
    { refetchOnWindowFocus: false }
  );

  const { data: targetBank = {} } = useQuery(
    ['customer-bank', disbId, disbDataById?.target_bank_id],
    () => getCustomerBankById({ customerId: disbId, bankId: disbDataById?.target_bank_id }),
    {
      enabled: Boolean(disbDataById?.target_bank_id),
      select: (data) => {
        return data[0]
      },
    }
  );

  const { data: reviewTrail = [] } = useQuery(
    ['remarks', createdDisbId],
    () => getAllRemarksDisb(createdDisbId),
    { refetchOnWindowFocus: false }
  );

  const { data: sourceBank = [], isLoading: sourceBankLoading } = useQuery(
    ['company-bank', disbDataById?.source_bank_id],
    () => CompanyBankDetails(disbDataById?.source_bank_id),
    {
      enabled: Boolean(disbDataById?.source_bank_id),
      refetchOnWindowFocus: false
    }
  );

  const handlePDF = () => {
    setpdfLoading(true)
    generateDisbMEMOPdfbyID(createdDisbId)
      .then((res) => {
        queryClient.invalidateQueries(['disb-byid', createdDisbId]);
        displayNotification({ message: PDF_TEXT_MESSAGE?.disbursement, variant: 'success' })
        openFile(res)
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' })
      })
      .finally(() => {
        setpdfLoading(false)
      })
  }

  const onSendforNextStageDisb = (remarks, status) => {
    const body = {
      remarks,
    };

    setRemarksLoading(true);
    sendDisbforNextStage(createdDisbId, body)
      .then((res) => {
        setSucessfulmodal({
          open: true, title:
            status == IN_REVIEW ? STAGE_CHANGE_TEXT?.approval
              : status == IN_APPROVAL ? STAGE_CHANGE_TEXT?.approved
                : status == CANCELLATION_REVIEW ? STAGE_CHANGE_TEXT?.reviewCancel
                  : STAGE_CHANGE_TEXT?.approveCancel
        });
      })
      .catch((e) => {
        displayNotification({
          id: 'disb-approval',
          message: e?.message || e,
          variant: 'error',
        });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const handlePushback = (remarks) => {
    const body = {
      remarks,
    };

    setRemarksLoading(true);
    SendForPushbackDisb(createdDisbId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.pushback });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const handleReject = (remarks) => {
    const body = {
      remarks,
    };

    setRemarksLoading(true);
    SendForRejectDisb(createdDisbId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.reject });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const handleCancel = (remarks, reason) => {
    const body = {
      remarks,
      reason: reason?.reason,
    };

    setRemarksLoading(true);
    sendForCancellationDisb(createdDisbId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.cancel });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const callFunction = (remarks, status, reason) => {
    switch (status) {
      case 'review':
      case 'approval':
      case 'cancellation':
        onSendforNextStageDisb(remarks, status)
        break;
      case 'reject':
        handleReject(remarks);
        break;
      case 'pushback':
        handlePushback(remarks);
        break;
      case 'cancel':
        handleCancel(remarks, reason);
        break;
      default:
        displayNotification({
          message: 'Something went wrong, Please contact support!',
          variant: 'error',
        });
        break;
    }
  };

  return (
    <>
      <Body background={false}>
        <PageTitle
          title={disbDataById?.customer_name}
          id={disbDataById?.customer_id}
          action={
            disbDataById?.crr_id ?
              /** display the credit reload request */
              <CreditReloadDetails
                selectedRowId={disbDataById?.crr_id}
                type={disbDataById?.additional_deduction?.find(i => i?.fee_type === 'express_reload_fee') ? 'express' : null}
              />
              : null
          }
        />
      </Body>
      <DashBoardCustomerDetails id={disbId} loansId={disbDataById?.loan_id} codeName={'Disb Code'} code={disbDataById?.code} />
      <Box sx={{ position: 'relative' }}>
        <Body>
          {disbDataById?.prospect_code && (
            <div style={ImdStyle.flag}>
              <IdBadge
                remarks={disbDataById?.status_value == 'cancelled' ? 'rejected' : disbDataById?.status_value}
                label={disbDataById?.prospect_code}
              />
            </div>
          )}
          <Grid gutter='lg'>
            <Grid.Col span={3} sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid.Col
                span={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Image
                  src={GENERAL_IMG}
                  alt='Illustration'
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={5}>
              <DataViewerWapper p={30}>
                <ViewFormData
                  label='Loan Type'
                  value={
                    disbDataById?.loan_type == 'new'
                      ? 'New'
                      : disbDataById?.loan_type == 'enhancement'
                        ? 'Enhancement'
                        : disbDataById?.loan_type == 'transfer'
                          ? 'Transfer'
                          : 'Revolving'
                  }
                  loading={loading}
                />
                <ViewFormData
                  label='Product'
                  value={disbDataById?.product_name}
                  loading={loading}
                />
                {disbDataById?.loan_type == 'revolving' ? (
                  <Grid gutter='md'>
                    <Grid.Col span={12} mt='xs'>
                      <TitleViewer title={'Revolving'} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <ViewFormData
                        label='Tranche Amount'
                        value={
                          <Nums withCopy value={disbDataById?.loan_amount} />
                        }
                        loading={loading}
                      />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <ViewFormData
                        label='Disb Date'
                        value={dateFormat(disbDataById?.disbursed_date)}
                        loading={loading}
                      />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <ViewFormData
                        label='Due Date'
                        value={dateFormat(disbDataById?.due_date)}
                        loading={loading}
                      />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <ViewFormData
                        label={`Interest`}
                        secondaryLabel={disbDataById?.interest_rate}
                        value={
                          <Nums value={disbDataById?.interest_amount} />
                        }
                        loading={loading}
                      />
                    </Grid.Col>
                    {/* If spread rate percentage is avalible then it will display */}
                    {disbDataById?.spread_rate ?
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={`Spread`}
                          secondaryLabel={disbDataById?.spread_rate}
                          value={
                            <Nums value={disbDataById?.spread_amount} />
                          }
                          loading={loading}
                        />
                      </Grid.Col> : null
                    }
                    {/* If addon spread rate percentage is avalible then it will display */}
                    {disbDataById?.addon_spread_rate ?
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={`Addon Spread`}
                          secondaryLabel={disbDataById?.addon_spread_rate}
                          value={
                            <Nums value={disbDataById?.addon_spread_amount} />
                          }
                          loading={loading}
                        />
                      </Grid.Col> : null
                    }
                    <Grid.Col span={12}>
                      <ViewFormData
                        label='Due Amount'
                        value={
                          <Nums withCopy value={disbDataById?.due_amount} />
                        }
                        loading={loading}
                      />
                    </Grid.Col>
                    {disbDataById?.advance_interest ?
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={`Advance Interest`}
                          value={
                            <Nums withCopy value={disbDataById?.advance_interest} />
                          }
                          loading={loading}
                        />
                      </Grid.Col> : null
                    }
                    {disbDataById?.renewal_fee?.length ?
                      <Grid.Col span={12}>
                        <ViewFormData
                          label='Renewal Fee'
                          value={
                            <Nums withCopy value={disbDataById?.renewal_fee[0]?.total_amount} />
                          }
                          loading={loading}
                          action={<ViewFeeDetailsWithGST calculatedFee={disbDataById?.renewal_fee[0]} />}
                        />
                      </Grid.Col> : null
                    }
                    {disbDataById?.additional_deduction?.length ?
                      <>
                        <Grid.Col span={12}>
                          <TitleViewer title={'Deductions'} />
                        </Grid.Col>
                        {disbDataById?.additional_deduction?.map((item) => (
                          <Grid.Col span={12}>
                            <ViewFormData
                              label={item?.fee_name}
                              value={
                                <Nums withCopy value={item?.total_amount} />
                              }
                              action={item?.fee_type == 'fee' ? <ViewFeeDetailsWithGST calculatedFee={item} /> : item?.fee_type === 'express_reload_fee' ? <ReloadFeePopover value={item} /> : null}
                            />
                          </Grid.Col>
                        ))}
                      </> : null
                    }
                    <Grid.Col span={12}>
                      <ViewFormDataContained
                        label='Net Disb Amount'
                        value={
                          <Nums withCopy value={disbDataById?.disbursed_amount} />
                        }
                        loading={loading}
                      />
                    </Grid.Col>
                  </Grid>
                )
                  : (
                    <Grid gutter='md'>
                      <Grid.Col span={12}>
                        <TitleViewer title='Cash Flow (OTP Loan)' />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label='Disb Date'
                          value={dateFormat(disbDataById?.disbursed_date)}
                          loading={loading}
                        />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label='Due Date'
                          value={dateFormat(disbDataById?.due_date)}
                          loading={loading}
                        />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label='Prinicple'
                          value={
                            <Nums withCopy value={disbDataById?.loan_amount} />
                          }
                          loading={loading}
                        />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={`Interest`}
                          secondaryLabel={disbDataById?.interest_rate}
                          value={
                            <Nums value={disbDataById?.interest_amount} />
                          }
                          loading={loading}
                        />
                      </Grid.Col>
                      {/* If spread rate percentage is avalible then it will display */}
                      {disbDataById?.spread_rate ?
                        <Grid.Col span={12}>
                          <ViewFormData
                            label={`Spread`}
                            secondaryLabel={disbDataById?.spread_rate}
                            value={
                              <Nums value={disbDataById?.spread_amount} />
                            }
                            loading={loading}
                          />
                        </Grid.Col> : null
                      }
                      {/* If addon spread rate percentage is avalible then it will display */}
                      {disbDataById?.addon_spread_rate ?
                        <Grid.Col span={12}>
                          <ViewFormData
                            label={`Addon Spread`}
                            secondaryLabel={disbDataById?.addon_spread_rate}
                            value={
                              <Nums value={disbDataById?.addon_spread_amount} />
                            }
                            loading={loading}
                          />
                        </Grid.Col> : null
                      }
                      <Grid.Col span={12}>
                        <ViewFormData
                          label='Due Amount'
                          value={
                            <Nums withCopy value={disbDataById?.due_amount} />
                          }
                          loading={loading}
                        />
                      </Grid.Col>
                      {disbDataById?.advance_interest ?
                        <Grid.Col span={12}>
                          <ViewFormData
                            label={`Advance Interest`}
                            value={
                              <Nums withCopy value={disbDataById?.advance_interest} />
                            }
                            loading={loading}
                          />
                        </Grid.Col> : null
                      }
                      {disbDataById?.additional_deduction?.length ? (
                        <>
                          <Grid.Col span={12}>
                            <TitleViewer title={'Deduction'} />
                          </Grid.Col>
                          {disbDataById?.additional_deduction?.map((item) => (
                            <Grid.Col span={12}>
                              <ViewFormData
                                label={item?.fee_name}
                                value={
                                  <Nums withCopy value={item?.total_amount} />
                                }
                                action={item?.fee_type == 'fee' ? <ViewFeeDetailsWithGST calculatedFee={item} /> : item?.fee_type === 'express_reload_fee' ? <ReloadFeePopover value={item} /> : null}
                              />
                            </Grid.Col>
                          ))}
                        </>
                      ) : null}
                      <Grid.Col span={12}>
                        <ViewFormDataContained
                          label='Net Disb Amount'
                          value={
                            <Nums withCopy value={disbDataById?.disbursed_amount} />
                          }
                          loading={loading}
                        />
                      </Grid.Col>
                    </Grid>
                  )}
              </DataViewerWapper>
            </Grid.Col>
            <Grid.Col span={4}>
              <DataViewerWapper p={20}>
                <TitleViewer title='Bank Details of the Petromoney' />
                <Grid style={{ paddingTop: '10px' }}>
                  <Grid.Col span={12} pt={'sm'}>
                    <ViewFormData
                      label={'Account No'}
                      value={sourceBank[0]?.account_number}
                      loading={sourceBankLoading} />
                  </Grid.Col>
                  <Grid.Col span={12} mb={'md'}>
                    <ViewFormData
                      label={'Bank Name'}
                      value={sourceBank[0]?.bank_name}
                      loading={sourceBankLoading} />
                  </Grid.Col>
                </Grid>
                <TitleViewer title='Bank Details of the customer' />
                <Grid style={{ paddingTop: '10px' }}>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label='Account No'
                      value={targetBank?.account_number}
                      loading={loading}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label='Bank Name'
                      value={targetBank?.bank_name}
                      loading={loading}
                    />
                  </Grid.Col>
                </Grid>
              </DataViewerWapper>
            </Grid.Col>
            {disbDataById?.deduction_loan_payload?.selection?.length && ProspectCode?.length ?
              /** used to displey the table if it is available */
              <Grid.Col span={12}>
                <LoanRenewalTable renewalLoans={ProspectCode} loanDetails={disbDataById} />
              </Grid.Col> : null
            }
          </Grid>
          {/** This Action buttons component is used to display the buttons in the flow */}
          <ActionButtons
            status={stages?.current_stage}
            isReady={Boolean(stages?.current_stage)}
            nextStage={stages?.next_stage}
            remarks={reviewTrail}
            currentStatus={disbDataById?.status_value}
            moduleApplicationId={disbDataById?.id}
            moduleName={'disbursement'}
            pdfLoading={pdfLoading}
            handlePDF={() => disbDataById?.disbursement_memo_url ? openFile(disbDataById?.disbursement_memo_url) : handlePDF()}
            accountingCB={false}
            downloadText={disbDataById?.disbursement_memo_url ? 'Download MEMO' : 'Generate MEMO'}
            sendForApprovalCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('review'); setLabel('Send for Approval') }}
            approvedCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('approval'); setLabel('Approve') }}
            pushbackCB={() => { setOpen(true); setRemarksTitle("Pushback Remarks"); setStatus('pushback'); setLabel('Pushback') }}
            rejectCB={() => { setOpen(true); setRemarksTitle("Reject Remarks"); setStatus('reject'); setLabel('Reject') }}
            remarksCB={() => setRemarksOpen(true)}
            cancelCB={() => { setOpen(true); setRemarksTitle("Cancellation Remarks"); setStatus('cancellation'); setLabel('Send for Cancellation') }}
            approveCancelCB={() => { setOpen(true); setRemarksTitle("Are you sure?"); setStatus('cancel'); setLabel('Confirm') }} />

          {/** used to get remarks from user */}
          <Remarks
            open={open}
            setOpen={setOpen}
            type={stages?.next_stage}
            callback={callFunction}
            remarksTitle={remarksTitle}
            check={status}
            moduleName={'Disbursement'}
            label={label}
            loading={remarksLoading}
            currentStatus={disbDataById?.status_value}
            moduleApplicationId={disbDataById?.id}
          />

          {/** used to display the suceccfull stage change modal */}
          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={disbDataById?.code}
            title={sucessfulmodal?.title}
            href={home}
            hreftext={'Back to list table'}
          />

        </Body>
        {/** used to display the remarks */}

        <ViewRemark open={remarksOpen} onClose={setRemarksOpen} remarkTrail={reviewTrail} />

      </Box>
    </>
  );
};

export default DisbReview;
