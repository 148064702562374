import { Button, Group, Text } from '@mantine/core';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import Body from '../../components/Body/Body';
import { useQuery } from '@tanstack/react-query';
import { LoanStatusToken } from '../../components/CommonComponents/Preview/FilePreview';
import Loans from './Loans';
import { getAllActiveLoans } from '../../services/loan.service';
import DataTableWrapper from '../../components/CommonComponents/Table/DataTableWrapper';
import { createColumnHelper } from '@tanstack/react-table';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import Nums from '../../components/Body/Nums';
import { LOAN_STATUS_COLORS } from '../../utils/StatusTable';
import {
  APPROVED,
  CANCELLED,
  IN_APPROVAL,
  IN_REVIEW,
  OPEN,
  REJECTED,
  CANCELLATION_REVIEW,
  CANCELLATION_APPROVAL,
  AUTO_CANCELLED
} from '../../constants/status';
import EntityFilter from './../../components/CommonComponents/Filter/EntityFilter';
import useModuleRecordCountStore from '../../store/moduleRecordCountStore';
import LoanTypeFilter from '../../components/CommonComponents/Filter/LoanTypeFilter';

const LoansTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const columnHelper = createColumnHelper();
  const { moduleCount } = useModuleRecordCountStore();
  const [addLoans, setAddLoan] = useState(false);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState({ label: 'show 10', value: 10 });
  const [search, setSearch] = useState('');
  // get the search params
  const [searchParams, setSearchParams] = useSearchParams();
  const params = {}
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  /** used to change the page to 1 while the search us used 
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1)
  }, [search, searchParams.get("status"), searchParams.get("entity"), searchParams.get("reload_type"), records])

  /** 
   * It will fetch data when the tab is in active
   * By default it will be in active
   */
  const { data: activeLoans = [], isLoading } = useQuery(
    ['active-loans', page, records, params, search],
    () => getAllActiveLoans({ page, search, records: records?.value, ...params }),
    {
      enabled: Boolean(searchParams.get("status") && searchParams.get("status") !== "none"),
      refetchOnWindowFocus: false
    }
  );

  const onRowClick = (i) => {
    if (i?.status_value == OPEN) {
      navigate(`/tranche/view/${i?.id}/${i?.customer_id}/${i?.customer_application_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == IN_APPROVAL || i?.status_value == IN_REVIEW) {
      navigate(`/tranche/review/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == APPROVED) {
      navigate(`/tranche/approved/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == AUTO_CANCELLED) {
      navigate(`/tranche/cancelled/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == REJECTED) {
      navigate(`/tranche/rejected/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == CANCELLED) {
      navigate(`/cancellation/tranche-cancelled/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == CANCELLATION_REVIEW || i?.status_value == CANCELLATION_APPROVAL) {
      navigate(`/cancellation/tranche-cancel/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    }
  };

  const onClickAction = (value) => {
    return (
      <span
        style={{ color: '#0063FF', cursor: 'pointer' }}
      >
        {value.getValue()}
      </span>
    );
  };

  const tableAction = (
    <Button
      variant='filled'
      color='primary'
      leftIcon={<Plus size={18} />}
      size='sm'
      onClick={() => setAddLoan(true)}
    >
      Create Loan
    </Button>
  );

  const columnData = [
    columnHelper.accessor('prospect_code', {
      header: 'Tranche Code',
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor('customer_name', {
      header: 'Customer Name',
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor('customer_id', {
      header: 'Customer Code',
    }),
    columnHelper.accessor('product_name', {
      header: 'Scheme',
    }),
    columnHelper.accessor('loan_amount', {
      header: <Text sx={{ textAlign: 'right' }}>Tranche Amount</Text>,
      cell: (value) => <Nums value={value.getValue()} styles={{ textAlign: 'right' }} />,
    }),
    columnHelper.accessor('created_date', {
      header: 'Created Date',
      cell: (value) => dateFormat(value.getValue()),
    }),
    columnHelper.accessor('maker_name', {
      header: 'Created By',
    }),
    columnHelper.accessor('loan_status', {
      header: 'Loan Status',
      cell: (value) => (
        <LoanStatusToken
          label={value.getValue()}
          color={LOAN_STATUS_COLORS?.[value.getValue()]}
        />
      ),
    }),
  ];

  return (
    <Body background={false}>
      <DataTableWrapper
        title='Tranches'
        filter={false}
        rowData={activeLoans?.data}
        columnData={columnData}
        onRowClick={onRowClick}
        noDataText="No Tranche details yet!"
        noDataSubText='This Section will contain list of created Tranche details.'
        // when the status is none then the loading is set to false other wise it will dependent on isFetching
        loading={searchParams.get("status") !== "none" ? isLoading : searchParams.get("status") === null ? true : false}
        addDataCallback={() => setAddLoan(true)}
        useApiPagination
        totalNoOfPages={Math.ceil(parseInt(moduleCount?.count) / records?.value)}
        totalNoOfRecords={moduleCount?.count}
        page={page}
        showStatus
        module_name='loan'
        setPage={setPage}
        apiSearch={setSearch}
        rowLength={records}
        setRowLength={setRecords}
        actions={tableAction}
        /** used to have tab in loans table to filter active and closed loans */
        options={<Group spacing={2}><EntityFilter useQueryParams /><LoanTypeFilter /></Group>}
      />
      <Loans open={addLoans} onClose={() => setAddLoan(false)} />
    </Body>
  );
};

export default LoansTable;
