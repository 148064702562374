import { Box, Button, Grid } from "@mantine/core";
import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import ControllerTextField from "../../../../components/CommonComponents/TextField/ControllerTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { createWaiverReasons } from '../../../../services/waiver.service';
import { displayNotification } from "../../../../components/CommonComponents/Notification/displayNotification";

const ReasonSchema = yup.object({
  name: yup.string().required("Please Enter Reason"),
});

const Reason = ({ id, formData, onClose }) => {
  const queryClient = useQueryClient();
  // Form Handlers
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ReasonSchema),
    defaultValues: {
      ...formData,
    },
  });

  // Functions
  const onSaveReason = (value) => {
    const body = {
      name: value?.name,
    };

    // Body construction
    createWaiverReasons(body)
      .then((res) => {
        queryClient.invalidateQueries(['all-reason']);
        displayNotification({
          message: "Reason added successfully!",
          variant: 'success',
        });
        onClose();
      })
      .catch((e) =>
        displayNotification({ message: e?.message || e, variant: 'error' })
      );
  };

  return (
    <Box>
      <Box>
        <Grid gutter="xs" mt="md">
          <Grid.Col span={12}>
            <ControllerTextField
              name="name"
              label="Reason Name"
              control={control}
              errors={errors?.name?.message}
            />
          </Grid.Col>
        </Grid>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "12px 0",
          }}
        >
          <Button variant="subtle" onClick={onClose}>
            Cancel
          </Button>
          {!id && (
            <Button color="green" ml="md" onClick={handleSubmit(onSaveReason)}>
              Create Reason
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Reason;
