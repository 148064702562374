import React, { useState } from "react";
import { Box, Text, Title, SimpleGrid, Tooltip } from "@mantine/core";
import { currencyInWords } from "../../components/CommonComponents/TextField/NumberToWords";
import SummaryToggleBlock from "./SummaryToggleBlock";
import changeValueFormat, { VALUE_TYPES } from "../../utils/changeValueFormat";
import { motion } from "framer-motion";

const ITEM_PER_COLUMN = 5;

const CRORE = 10000000;

const calculateColumn = (data) =>
  1 + Math.floor((data?.length - 1) / ITEM_PER_COLUMN);

const DisplayValue = ({ value, type }) => {
  const [amountInCR, setAmountInCR] = useState(true);

  let FinalValue = changeValueFormat(value, type);
  
  if (type?.toLowerCase() !== VALUE_TYPES.amount) {
    return (
      <Text size={16} sx={{ fontWeight: 600, cursor: "pointer", display: 'flex', flexDirection: 'row', gap: '2px' }}>
        {FinalValue}
      </Text>
    )
  }
  
  let suffix = '';
  if (type?.toLowerCase() === VALUE_TYPES.amount && amountInCR) {
    FinalValue = changeValueFormat(value / CRORE, type, { round: false });
    suffix = 'Cr';
  }

  return (
    <Tooltip label={currencyInWords(parseInt(value))} disabled={value<0 || !value} color="gray">
      <Text
        onClick={() => setAmountInCR(!amountInCR)}
        size={16}
        sx={{ fontWeight: 600, cursor: "pointer", display: 'flex', flexDirection: 'row', gap: '2px' }}
      >
        {FinalValue} {suffix}
      </Text>
    </Tooltip>
  );
};

const SummaryDetails = ({ data = [] }) => {
  const [open, setOpen] = useState(true);
  return (
    <Box
      mt={10}
      sx={{
        position: "relative",
        height: "500px",
      }}
    >
      <SummaryToggleBlock open={open} onClick={() => setOpen(v => !v)} />
      {open ? (
        <SimpleGrid
          cols={calculateColumn(data)}
          sx={{ paddingRight: "14px", gap: "8px" }}
          py={0}
        >
          {data?.map((dataItem, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, marginTop: 30, scale: 0.8 }}
                animate={{ opacity: 1, marginTop: 0, scale: 1 }}
                exit={{ opacity: 0, marginTop: 30, scale: 0.8 }}
                transition={{ type: "spring", duration: 1 }}
                >
                <Box
                  px={"lg"}
                  py={"sm"}
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                  }}
                >
                  <DisplayValue value={dataItem?.value} type={dataItem?.type} />
                  <Title
                    order={6}
                    sx={{
                      color: "gray"
                    }}
                  >
                    {dataItem?.label}
                  </Title>
                </Box>
              </motion.div>
            )
          )}
        </SimpleGrid>
      ) : null}
    </Box>
  );
};

export default SummaryDetails;
