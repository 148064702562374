import React, { useEffect, useState } from "react";
import { COLORS } from "../../constants";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import Nums from "../../components/Body/Nums";
import { getUnidentifiedReceiptById } from "../../services/unidentifiedReceipt.service";
import {
  Button,
  Skeleton,
  Table,
  Text,
  Radio,
  Title,
  Box,
  TextInput,
  Group,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { Search } from "tabler-icons-react";
import DialogModal from './../../components/CommonComponents/Modal/DialogModal';

/**
 * used to display the list of unidentified receipt
 *
 * @props
 * submitCallback: used to send the selected value
 * selectedRowId: contain the selected unidentified receipt id
 * data: contain the  list of unidentified receipt
 * mode: (view, edit): used to check the type
 */
const UnidentifiedReceiptListModal = ({
  submitCallback,
  data = [],
  selectedRowId,
  mode = "view",
}) => {
  const [checked, setChecked] = useState();
  const [search, setSearch] = useState("");
  const [unidentifiedReceiptModal, setUnidentifiedReceiptModal] =
    useState(false);

  /** getting the unidentified receipt details  by id */
  const { data: unidentifiedReceiptData = [...data], isFetching } = useQuery(
    ["unidentified-receipt-id", selectedRowId],
    () => getUnidentifiedReceiptById(selectedRowId),
    {
      select: (response) => {
        return response.map((item) => ({
          ...item,
          search_key: Object.values(item).join().toLowerCase(),
        }));
      },
      enabled: Boolean(mode === "view"),
      refetchOnWindowFocus: false,
    }
  );

  /** used to set the selectedRowId */
  useEffect(() => {
    setChecked(`${selectedRowId}`);
  }, [selectedRowId]);

  return (
    <>
      {unidentifiedReceiptData?.length ? (
        <Button mt={"lg"} onClick={() => setUnidentifiedReceiptModal(true)}>
          {`Unidentified Receipt ${mode === "edit" ? "(" + unidentifiedReceiptData?.length + ")" : ""
            }`}
        </Button>
      ) : null}
      <DialogModal
        opened={unidentifiedReceiptModal}
        onClose={() => setUnidentifiedReceiptModal(false)}
        size={"82rem"}
        title={<Title order={4}>Unidentified Receipt</Title>}
        withArrow
        shadow={"rgba(0, 0, 0, 0.2) 0px 20px 30px;"}
      >
        <>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box>
              <Radio.Group
                value={checked}
                onChange={setChecked}
                withAsterisk
                styles={(them) => ({
                  label: {
                    cursor: "pointer",
                  },
                })}
              >
                {!isFetching && !unidentifiedReceiptData?.length ? (
                  /** display the text when there is no data */
                  <Text sx={{ justifyContent: "center" }}>
                    No Unidentified Receipt found!
                  </Text>
                ) : (
                  <Box sx={{ width: "100%" }}>
                    {(mode !== "view") ? (
                      <Group position="apart" mb={2}>
                        <Text sx={{ fontSize: '12px' }} color="gray">Select the Unidentified Receipt to use it!</Text>
                        <TextInput
                          placeholder="Search"
                          onChange={(e) => setSearch(e.target.value)}
                          icon={<Search size={16} />}
                          size={"sm"}
                        />
                      </Group>
                    ) : null}
                    <Box sx={{ maxHeight: "400px", overflow: "scroll" }}>
                      <Table
                        fontSize="sm"
                        highlightOnHover
                        horizontalSpacing="xl"
                        striped
                        verticalSpacing="xs"
                      >
                        <thead
                          style={{
                            backgroundColor: COLORS.background,
                            borderRadius: "10px",
                            position: 'sticky',
                            top: 0,
                            zIndex: '99',
                          }}
                        >
                          <tr>
                            {mode == "edit" && <th></th>}
                            <th>Code</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Receipt Amount</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Receipt Date</th>
                            <th>Payment Ref No</th>
                            <th>Bank</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isFetching
                            ? unidentifiedReceiptData?.map((item, index) => {
                              if (
                                item?.search_key?.includes(search.toLowerCase())
                              ) {
                                return (
                                  <tr
                                    key={index}
                                    onClick={() => {
                                      setChecked(`${item?.id}`);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {mode == "edit" && (
                                      <td>
                                        <Radio value={`${item?.id}`} />
                                      </td>
                                    )}
                                    <td style={{ whiteSpace: 'nowrap' }}>{item?.code}</td>
                                    <td style={{ textAlign: "right", whiteSpace: 'nowrap' }}>
                                      <Nums value={item?.unidentified_receipt_amount} />
                                    </td>
                                    <td style={{ whiteSpace: 'nowrap' }}>
                                      {dateFormat(
                                        item?.unidentified_receipt_date
                                      )}
                                    </td>
                                    <td>{item?.payment_ref_no}</td>
                                    <td>{item?.bank}</td>
                                  </tr>
                                );
                              }
                              return null;
                            })
                            : [1, 2, 3, 4].map((item) => (
                              <tr>
                                {/** the loading when the api is fetching */}
                                <td>
                                  <Skeleton height={15} />
                                </td>
                                <td>
                                  <Skeleton height={15} />
                                </td>
                                <td>
                                  <Skeleton height={15} />
                                </td>
                                <td>
                                  <Skeleton height={15} />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </Box>
                  </Box>
                )}
              </Radio.Group>
            </Box>
            {mode == "edit" && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
                mt={"md"}
              >
                <Button
                  onClick={() => {
                    /** if user click the clear button then we will clear the data */
                    setChecked();
                    submitCallback({});
                  }}
                  variant={"subtle"}
                >
                  Clear
                </Button>
                <Button
                  onClick={() => {
                    /** if user click the submit button then we will filter the selected value and set it */
                    submitCallback(
                      unidentifiedReceiptData?.filter(
                        (item) => item?.id == checked
                      )[0]
                    );
                    setUnidentifiedReceiptModal(false);
                  }}
                  color={"green"}
                >
                  Submit
                </Button>
              </Box>
            )}
          </Box>
        </>
      </DialogModal>
    </>
  );
};

export default UnidentifiedReceiptListModal;
