import {
  createStyles,
  Divider,
  Kbd,
  Navbar,
  NavLink,
  ScrollArea,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { NavLink as NaviLink, useLocation } from "react-router-dom";
import React, { useState } from "react";
import {
  Archive,
  BuildingBank,
  Cash,
  ChevronsLeft,
  Receipt,
  Receipt2,
  ReportMoney,
  Wallet,
  ChevronsRight,
  ReportSearch,
  CurrencyRupee,
  CircleX,
  FileInvoice,
  Briefcase,
  Dashboard,
  ClipboardList,
  ClipboardCheck,
  Users,
  Tree,
  DeviceAnalytics,
  TruckLoading,
  BrandStackoverflow,
  Coin,
} from "tabler-icons-react";
import { isAllowed } from "../../utils/roleBaseAccess";
import { action_id } from "../../config/userRules";
import useAuthStore from "../../store/authStore";
const packageJson = require("../../../package.json");
/*
 * parent nav items
 * use sub path if the main screen href is plural
 */

const navbarItemsDashboard = [
  {
    label: "Dashboard",
    href: "/dashboard",
    icon: Dashboard,
    isShow: action_id?.navigation?.management_dashboard_view,
  }, {
    label: "Dashboard II",
    href: "/",
    icon: Dashboard,
    isShow: action_id?.navigation?.dashboard_view,
  }
]
const navbarItems = [
  {
    label: "Disbursement",
    href: "/disbursement",
    icon: Cash,
    isShow: action_id?.navigation?.disb_memo_view,
  },
  {
    label: "Receipts",
    href: "/receipts",
    icon: Receipt,
    isShow: action_id?.navigation?.receipts_view,
  },
  {
    label: "Invoice",
    href: "/invoice",
    icon: Receipt2,
    isShow: action_id?.navigation?.invoice_view,
  },
  {
    label: "Foreclosure",
    href: "/foreclosure",
    icon: ReportMoney,
    isShow: action_id?.navigation?.foreclosure_view,
  },
  {
    label: "Refund",
    href: "/refund",
    icon: ReportMoney,
    isShow: action_id?.navigation?.refund_view,
  },
  {
    label: "Waiver",
    href: "/waiver",
    icon: Archive,
    isShow: action_id?.navigation?.waiver_dashboard_view
  },
  {
    label: "Cancellation",
    href: "/cancellation",
    icon: CircleX,
    isShow: action_id?.navigation?.cancellation_view,
  },
  {
    label: "Reports",
    href: "/reports",
    icon: ReportSearch,
    isShow: action_id?.navigation?.report_view,
  },
  {
    label: "Payment History",
    href: "/payment-history",
    icon: Wallet,
    isShow: action_id?.navigation?.payment_history,
  },
  {
    label: "Borrowers",
    href: "/borrower",
    icon: Users,
    isShow: action_id?.navigation?.fee_collection_view,
  },
];

/** child nav items */
const subnav = [
  {
    label: "Tranche",
    href: "/tranche",
    parent: "Disbursement",
    icon: BuildingBank,
    isShow: action_id?.navigation?.tranch_view,
  },
  {
    label: "Disb Memo",
    parent: "Disbursement",
    href: "/disb",
    icon: Cash,
    isShow: action_id?.navigation?.disb_memo_view,
  },
  {
    label: "Disb Batch",
    parent: "Disbursement",
    href: "/batch-disb",
    icon: Tree,
    isShow: action_id?.navigation?.disb_memo_view,
  },
  {
    label: "Payment",
    parent: "Disbursement",
    href: "/payment",
    icon: CurrencyRupee,
    isShow: action_id?.navigation?.payments_view,
  },
  {
    label: "Fee Invoice",
    href: "/fee-invoice",
    parent: "Invoice",
    icon: ReportMoney,
    isShow: action_id?.navigation?.fee_collection_view,
  },
  {
    label: "GST e-Invoice",
    href: "/e-invoice",
    parent: "Invoice",
    icon: DeviceAnalytics,
    isShow: action_id?.navigation?.gst_e_bill,
  },
  {
    label: "External Customer",
    href: "/external-customer",
    parent: "Invoice",
    icon: ReportMoney,
    isShow: action_id?.navigation?.e_invoice_view,
  },
  {
    label: "External Customer Invoice",
    href: "/external-customer-invoice",
    parent: "Invoice",
    icon: ReportMoney,
    isShow: action_id?.navigation?.e_invoice_view,
  },
  {
    label: "Manual Invoice",
    href: "/invoice-manual",
    parent: "Invoice",
    icon: Receipt2,
    isShow: action_id?.navigation?.invoice_view,
  },
  {
    label: "Receipt",
    parent: "Receipts",
    href: "/receipt",
    icon: ClipboardCheck,
  },
  {
    label: "Bulk Receipt",
    parent: "Receipts",
    href: "/bulk-receipt",
    icon: TruckLoading,
  },
  {
    label: "Unidentified",
    parent: "Receipts",
    href: "/unidentified-receipt",
    icon: ClipboardList,
  },
  {
    label: "Customer Receipt",
    parent: "Receipts",
    href: "/customer-receipt",
    icon: BrandStackoverflow,
  },
  {
    label: "Excess Amount",
    parent: "Receipts",
    href: "/excess-amount",
    icon: Coin,
  },
  {
    label: "Report",
    parent: "Reports",
    href: "/report",
    icon: ReportSearch,
    isShow: action_id?.navigation?.report_view,
  },
  {
    label: "Historical Report",
    parent: "Reports",
    href: "/historical-report",
    icon: Briefcase,
    isShow: action_id?.navigation?.historical_report_view,
  },
  {
    label: "SOA",
    href: "/soa",
    parent: "Reports",
    icon: FileInvoice,
    isShow: action_id?.navigation?.soa_view,
  },
];

const useStyles = createStyles(() => ({
  active: {
    color: "#0063FF",
    backgroundColor: "#e7f5ff",
    borderRadius: 4,
  },
}));

const generateNavLinkStyles = (open, type) => ({
  root: {
    borderRadius: 4,
    padding: open
      ? type == "parent"
        ? "8px 8px 8px 15px"
        : "8px 4px 8px 6px"
      : "8px 12px",
  },
  body: {
    display: open && "none",
  },
  label: {
    display: open && "none",
  },
  icon: {
    marginRight: open ? "0px" : "12px",
  },
  rightSection: {
    display: open && "none",
  },
});

const NavgationBar = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { classes } = useStyles();
  const auth = useAuthStore(store => store.auth)
  /** used to handle the collapse */
  const handleCollapse = () => {
    setOpen(!open);
  };

  /** styles for parent nav */
  const navLink_Styles = generateNavLinkStyles(open, "parent");

  /** styles for child nav */
  const child_navLink_Styles = generateNavLinkStyles(open, "child");

  /** used to handle the sub nav */
  const handleSubNav = (value) => {
    if (value === "Receipts" || value === 'Disbursement' || value === 'Invoice' || value === 'Reports') {
      return true;
    }
    return false;
  };

  return (
    <Navbar width={open ? { base: 75 } : { base: 250 }} hidden={true}>
      <Navbar.Section grow p="sm" component={ScrollArea}>
        <Navbar.Section>
          <Stack spacing={2} mb={'sm'}>
            {navbarItemsDashboard?.map((nav, index) => (
              isAllowed(auth?.group_id, '', nav?.isShow) && (
                <Tooltip
                  key={nav.href}
                  label={nav.label}
                  position="right"
                  offset={20}
                  openDelay={400}
                  transition="fade"
                  transitionDuration={300}
                  disabled={open}
                >
                  <NaviLink
                    to={nav.href}
                    className={({ isActive }) =>
                      isActive && !handleSubNav(nav.label) ? classes.active : undefined
                    }
                  >
                    <NavLink
                      icon={
                        <nav.icon size={18} style={{ color: "rgb(0,0,0,0.3)" }} />
                      }
                      label={
                        !open && <Text sx={{ fontWeight: 500 }}>{nav.label}</Text>
                      }
                      styles={() => ({
                        ...navLink_Styles,
                      })}
                      variant="subtle"
                    />
                  </NaviLink>
                </Tooltip>
              )
            ))}
          </Stack>
        </Navbar.Section>
        <Navbar.Section>
          <Stack justify="center" spacing={2}>
            {navbarItems?.map((nav, index) => (
              isAllowed(auth?.group_id, '', nav?.isShow) && (
                <Tooltip
                  key={nav.href}
                  label={nav.label}
                  position="right"
                  offset={20}
                  openDelay={400}
                  transitionProps={{ transition: 'fade', duration: 300 }}
                  disabled={open}
                >
                  <NaviLink
                    to={nav.href}
                    className={({ isActive }) =>
                      isActive && !handleSubNav(nav.label) ? classes.active : undefined
                    }
                  >
                    <NavLink
                      icon={
                        <nav.icon size={18} style={{ color: "rgb(0,0,0,0.3)" }} />
                      }
                      label={
                        !open && <Text sx={{ fontWeight: 500 }}>{nav.label}</Text>
                      }
                      styles={() => ({
                        ...navLink_Styles,
                      })}
                      variant="subtle"
                    >
                      {handleSubNav(nav.label) &&
                        subnav?.map(
                          (navsub, i) =>
                            nav.label == navsub.parent && (
                              <NaviLink
                                to={navsub.href}
                                key={navsub.href}
                              >
                                <NavLink
                                  icon={
                                    <navsub.icon
                                      size={18}
                                      style={{ color: "rgb(0,0,0,0.3)" }}
                                    />
                                  }
                                  label={
                                    !open && (
                                      <Text sx={{ fontWeight: 500 }}>
                                        {navsub.label}
                                      </Text>
                                    )
                                  }
                                  styles={() => ({
                                    ...child_navLink_Styles,
                                  })}
                                  variant="light"
                                  active={('/' + location?.pathname?.split('/')[1]) === navsub.href}
                                />
                              </NaviLink>
                            )
                        )
                      }
                    </NavLink>
                  </NaviLink>
                </Tooltip>
              )
            ))}
          </Stack>
        </Navbar.Section>
      </Navbar.Section>
      <Divider />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Navbar.Section p="sm">
          <NavLink
            icon={
              open ? (
                <ChevronsRight size={18} style={{ color: "rgb(0,0,0,0.3)" }} />
              ) : (
                <ChevronsLeft size={18} style={{ color: "rgb(0,0,0,0.3)" }} />
              )
            }
            label={
              !open ? (
                <Text size={"xs"} ml={"lg"} color={"gray"}>
                  v{packageJson.version}
                </Text>
              ) : null
            }
            sx={{ borderRadius: 4 }}
            variant="light"
            onClick={handleCollapse}
          />
        </Navbar.Section>
        {!open && (
          <Tooltip
            label="Quick Search"
            width={"auto"}
            position="top"
            color={"gray"}
            withArrow
          >
            <span>
              {window.navigator?.userAgentData?.platform === "macOS" ? (
                <Kbd mb="xs" mr={"lg"}>
                  ⌘ + K
                </Kbd>
              ) : (
                <Kbd mb="xs" mr={"lg"}>
                  Ctrl + K
                </Kbd>
              )}
            </span>
          </Tooltip>
        )}
      </div>
    </Navbar>
  );
};

export default NavgationBar;
