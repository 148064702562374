import {
  APPROVED,
  AUTO_APPROVED,
  AUTO_CANCELLED,
  CANCELLATION_APPROVAL,
  CANCELLATION_REVIEW,
  CANCELLED,
  IN_CANCELLATION
} from "./status";

export const AFTER_APPROVED_STAGES = [
  APPROVED,
  CANCELLATION_APPROVAL,
  CANCELLATION_REVIEW,
  CANCELLED,
  AUTO_APPROVED,
  AUTO_CANCELLED,
  IN_CANCELLATION,
];
