import React from 'react'
import {
  Box,
  Text,
  createStyles,
} from "@mantine/core";
import { motion } from 'framer-motion';

let colorObject = {
  gold: {
    backgroundBorder: "#bcb28a",
    backgroundLight: "rgb(251 234 164)",
    backgroundColor: "linear-gradient(180deg, #D9AF1A 0%, rgba(255, 208, 41, 0.61) 100%)",
    textColor: "#997701",
  },
  platinum: {
    backgroundBorder: "rgb(208 208 208)",
    backgroundLight: "rgb(226 226 226 / 80%)",
    backgroundColor: "linear-gradient(rgb(165, 165, 165) 0%, rgb(202, 202, 202) 38%, rgb(226 226 226) 100%)",
    textColor: "#858585",
  },
  silver: {
    backgroundBorder: "rgb(224 224 224)",
    backgroundLight: "rgb(247 247 247 / 80%)",
    backgroundColor: "linear-gradient(90deg, rgba(233,233,233,1) 0%, rgba(219,219,219,1) 35%, rgba(229,229,229,1) 73%, rgba(237,237,237,1) 100%)",
    textColor: "#858585",
  }
}

const useStyles = createStyles((theme, { type }) => ({
  borderDiv: {
    clipPath: "polygon(0 0, 100% 0, 100% 57%, 50% 100%, 0 57%)",
    background: colorObject?.[type]?.backgroundBorder,
    width: 158,
    height: 112,
  },
  innerDiv: {
    clipPath: "polygon(0 0, 100% 0, 100% 57%, 50% 100%, 0 57%)",
    background: colorObject?.[type]?.backgroundLight,
    width: 155,
    height: 110,
    position: 'absolute',
    top: 0,
    left: 2,
  },
  mainDiv: {
    clipPath: "polygon(0 0, 100% 0, 100% 57%, 50% 100%, 0 57%)",
    background: colorObject?.[type]?.backgroundColor,
    width: 140,
    height: 102,
    position: 'absolute',
    top: 0,
    left: 9,
  },
  outerDiv: {
    position: 'relative',
  },
}));

const ContentBadge = ({ type }) => {
  const { classes } = useStyles({ type });

  return (
    <Box>
      <motion.div
        style={{ position: "absolute", top: 60, right: 30 }}
        initial={{ opacity: 0, scale: 0, top: 0, }}
        animate={{ opacity: 1, scale: 1, top: 60, }}
        transition={{ duration: 1 }}
      >
        <div className={classes.outerDiv}>
          <div
            className={classes.borderDiv}
          />
          <div
            className={classes.innerDiv}
          />
          <div
            className={classes.mainDiv}
          >
            <Text sx={{ fontWeight: 600, textAlign: 'center', lineHeight: '1.3' }} mt={'20px'} color={colorObject?.[type]?.textColor} size={'md'}>{type?.toUpperCase()} Member</Text>
          </div>
        </div>
      </motion.div>
    </Box>
  )
}

export default ContentBadge