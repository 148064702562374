import { Box, Grid, Text } from '@mantine/core';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import DataViewerWapper from '../../components/CommonComponents/Preview/DataViewerWapper';
import { ViewFormData } from '../../components/CommonComponents/Preview/FilePreview';
import ImdStyle from '../IMD/Imd.styles';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import Body from '../../components/Body/Body';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import ActionButtons from '../../components/CommonComponents/ActionButtons/ActionButtons';
import Remarks from '../../components/CommonComponents/Remarks/Remarks';
import Nums from '../../components/Body/Nums';
import ViewRemark from '../../components/Remark/ViewRemark';
import ModalDataViwer from '../../components/SucessfullModal/ModalDataViwer';
import IdBadge from '../../components/CommonComponents/Preview/IdBadge';
import { STAGE_CHANGE_TEXT, COLORS, STATE_CODE } from '../../constants';
import { getStageforModules } from '../../services/module.service';
import { CANCELLATION_REVIEW, IN_APPROVAL, IN_REVIEW } from '../../constants/status';
import AccountingInterfaceModal from '../../components/CommonComponents/AccuntingInterface/AccountingInterfaceModal';
import styled from '@emotion/styled';
import { externalCustomerInvoiceCancel, externalCustomerInvoicePushback, externalCustomerInvoiceRejected, externalCustomerInvoiceStatusChange, getExternalCustomerInvoiceById, getRemarksOfExternalCustomerInvoice } from '../../services/e-invoice.service';
import { sumBy } from 'lodash';
import { openFile } from '../../utils/openFile';
import { generateExternalCustomerInvoicePDFbyID } from '../../services/pdf.service';
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification';

const DataWapperTable = styled.table`
  .css-1hb7zxy-IndicatorsContainer {
    background: none;
    display: none;
  }
  
  border-collapse: collapse;
  font-size: 12px;
  width: 100%;
  text-align: left;

  td, th {
    padding: 6px 16px;
    border: 1px dashed ${COLORS.gray(15)};
    text-align: right;
  }
  tr td:nth-child(2) {
    padding: 0px 16px;
    max-width: 300px;
  }
  tr th:first-of-type {
    border-left: 0;
    color: ${COLORS.gray(40)};
    text-align: center;
    width: 50px;
  }
  tr th:last-of-type {
    border-right: 0;
  }
  tr:first-of-type th {
    border-top: 0;
  }
  tr td:first-of-type {
    border-left: 0;
    color: ${COLORS.gray(40)};
    text-align: center;
  }
  tr:last-of-type td {
    border-bottom: 0;
  }
  tr td:last-of-type {
    border-right: 0;
  }
  tr td:nth-child(2),
  tr th:nth-child(2) {
    text-align: left;
  }
  
  .trash {
    display: none;
  }
  .indexList {
    display: block;
  }
  .rowList:hover {
    .indexList {
      display: none;
    }
    .trash {
      display: block;
    }
  }
`;

const ManualCustomerInvoiceReview = ({ action = true, home = '/external-customer-invoice' }) => {
  const { dealerID, createdID } = useParams();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState();
  const [status, setStatus] = useState();
  const [remarksTitle, setRemarksTitle] = useState();
  const [remarksOpen, setRemarksOpen] = useState();
  const [remarksLoading, setRemarksLoading] = useState();
  const [sucessfulmodal, setSucessfulmodal] = useState(false);
  const [interfaceOpen, setInterfaceOpen] = useState(false);
  const [pdfLoading, setPDFLoading] = useState(false)

  const { data = [], isLoading: loading } = useQuery(
    ['invoice-id', createdID],
    () => createdID && getExternalCustomerInvoiceById({ id: createdID }),
    { refetchOnWindowFocus: false }
  )

  const { data: stages = [] } = useQuery(
    ['invoice-stages'],
    () => getStageforModules('external_customer_invoice'),
    {
      enabled: Boolean(data?.status_value),
      select: (dataItem) => {
        return {
          'current_stage': data?.status_value,
          'next_stage': dataItem[dataItem.indexOf(data?.status_value) + 1]
        }
      },
      refetchOnWindowFocus: false
    }
  );

  const { data: reviewTrail = [] } = useQuery(
    ['remarks-Invoice', createdID],
    () => getRemarksOfExternalCustomerInvoice({id: createdID}),
    { refetchOnWindowFocus: false }
  );

  /**
   * Handling the status change functionality.
   *
   * @param {string} remarks - The remarks for body.
   * @param {string} status - The status to handle the message.
   */
  const onSendInvoiceforNextStage = (remarks, status) => {
    setRemarksLoading(true)
    const body = {
      remarks,
    };

    externalCustomerInvoiceStatusChange({id: createdID, body})
      .then((res) => {
        setSucessfulmodal({open: true, title:
          status == IN_REVIEW ? STAGE_CHANGE_TEXT?.approval
          : status == IN_APPROVAL ? STAGE_CHANGE_TEXT?.approved
          : status == CANCELLATION_REVIEW ? STAGE_CHANGE_TEXT?.reviewCancel
          : STAGE_CHANGE_TEXT?.approveCancel
        });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error'})
      })
      .finally(() => {
        setOpen(false)
        setRemarksLoading(false)
      })
  }

  /**
   * Handles the Reject functionality.
   *
   * @param {string} remarks - The remarks for the body.
   */
  const handleReject = (remarks) => {
    setRemarksLoading(true)
    const body = {
      remarks,
    };

    externalCustomerInvoiceRejected({id: createdID, body})
      .then((res) => {
        setSucessfulmodal({open: true, title: STAGE_CHANGE_TEXT?.reject});
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error'})
      })
      .finally(() => {
        setOpen(false)
        setRemarksLoading(false)
      })
  }

  /**
   * Handles the pushback functionality.
   *
   * @param {string} remarks - The remarks for the pushback.
   */
  const handlePushback = (remarks) => {
    setRemarksLoading(true)
    const body = {
      remarks,
    };

    externalCustomerInvoicePushback({id: createdID, body})
      .then((res) => {
        setSucessfulmodal({open: true, title: STAGE_CHANGE_TEXT?.pushback});
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error'})
      })
      .finally(() => {
        setOpen(false)
        setRemarksLoading(false)
      })
  }

  /**
   * Handles the cancellation of an invoice.
   *
   * @param {string} remarks - The remarks for the cancellation.
   * @param {object} reason - The reason for the cancellation.
   * @param {string} reason.reason - The specific reason for the cancellation.
   */
  const handleInvoiceCancellation = (remarks, reason) => {
    setRemarksLoading(true)
    const body = {
      remarks,
      reason: reason?.reason
    }
    externalCustomerInvoiceCancel({id: createdID, body})
      .then((res) => {
        setSucessfulmodal({open: true, title: STAGE_CHANGE_TEXT?.cancel});
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error'})
      })
      .finally(() => {
        setOpen(false)
        setRemarksLoading(false)
      })
  }

  /**
   * Executes different actions based on the given status.
   */
  const callFunction = (remarks, status, reason) => {
    switch (status) {
      case 'review':
      case 'approval':
      case 'cancellation' :
        onSendInvoiceforNextStage(remarks, status);
        break;
      case 'reject':
        handleReject(remarks);
        break;
      case 'pushback':
        handlePushback(remarks);
        break;
      case 'cancel':
        handleInvoiceCancellation(remarks, reason)
        break;
      default:
        displayNotification({
          message: 'Something went wrong, Please contact support!',
          variant: 'error',
        });
        break;
    }
  };

  const handleInvoicePDF = () => {
    if(data?.invoice_url) {
      openFile(data?.invoice_url)
    } else {
      setPDFLoading(true)
      generateExternalCustomerInvoicePDFbyID(createdID)
        .then((res) => {
          res && openFile(res)
          queryClient.invalidateQueries(['invoice-id', createdID])
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' })
        })
        .finally(() => {
          setPDFLoading(false)
        })
    }
  }

  return (
    <>
    <Body background={false}>
      <PageTitle
        title={data?.customer_name} id={data?.customer_id} 
      />
    </Body>
    {/* <DashBoardCustomerDetails id={dealerID || data?.customer_id} /> */}
    <Box sx={{ position: 'relative' }}>
      <Body>
        {data?.code ? (
          <div style={ImdStyle.flag}>
            <IdBadge
              remarks={data?.status_value}
              label={data?.code}
            />
          </div>
        ) : null}
        <Grid gutter='lg'>
          <Grid.Col span={4} sx={{display: 'flex'}}>
              <DataViewerWapper p={30}>
                  <Grid gutter='md'>
                      <>
                        <Grid.Col span={12}>
                          <ViewFormData label='Customer Name' value={data?.customer_name} loading={loading} />
                        </Grid.Col>   
                        <Grid.Col span={12}>
                          <ViewFormData label='Customer ID' value={data?.customer_id} loading={loading} />
                        </Grid.Col>   
                        <Grid.Col span={12}>
                          <ViewFormData label='Charge Date' value={dateFormat(data?.charge_date)} loading={loading} />
                        </Grid.Col>                
                        <Grid.Col span={12}>
                          <ViewFormData label='Charge Type' value={data?.charge_type} loading={loading} />
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <ViewFormData label='Total Amount' value={<Nums withCopy value={data?.total_amount} />} loading={loading} />
                        </Grid.Col>
                      </>     
                </Grid>         
              </DataViewerWapper>
        </Grid.Col>
        {data?.invoice_charges?.length ?
            <Grid.Col span={8}>
              <DataViewerWapper p={30}>
                <DataWapperTable>
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>Charges</th>
                        <th>Amount</th>
                        {STATE_CODE != data?.state && data?.state ?
                          <th>IGST</th>
                        : null}
                        {STATE_CODE == data?.state ?
                          <th>CGST</th>
                        : null}
                        {STATE_CODE == data?.state ?
                          <th>SGST</th>
                        : null}
                        <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                      {data?.invoice_charges?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>{item?.charge_name}</td>
                          <td><Nums value={item?.charge_amount} /></td>
                          {STATE_CODE != data?.state && data?.state ?
                            <td><Nums value={item?.igst} /></td>
                          : null}
                          {STATE_CODE == data?.state ?
                            <td><Nums value={item?.cgst} /></td>
                          : null}
                          {STATE_CODE == data?.state ?
                            <td><Nums value={item?.sgst} /></td>
                          : null}
                          <td><Nums value={item?.total_amount} /></td>
                        </tr>
                      ))}
                      {
                        data?.invoice_charges?.length > 1 ?                           
                          <tr>
                            <td></td>
                            <td>Total</td>
                            <td><Nums value={sumBy(data?.invoice_charges, "charge_amount")} /></td>
                            {STATE_CODE != data?.state && data?.state ?
                              <td><Nums value={sumBy(data?.invoice_charges, "igst")} /></td>
                            : null}
                            {STATE_CODE == data?.state ?
                              <td><Nums value={sumBy(data?.invoice_charges, "cgst")} /></td>
                            : null}
                            {STATE_CODE == data?.state ?
                              <td><Nums value={sumBy(data?.invoice_charges, "sgst")} /></td>
                            : null}
                            <td><Nums value={sumBy(data?.invoice_charges, "total_amount")} /></td>
                          </tr>
                        : null
                      }
                    </tbody>
                </DataWapperTable>
              </DataViewerWapper>
            </Grid.Col> : null
        }
          { data?.description ?
            <Grid.Col span={6} pt={0} mt={0}>
              <Text size={"md"} weight={500}>Notes</Text>
              <Text size={"sm"} ml={'md'}>
                <div
                  dangerouslySetInnerHTML={{__html: data?.description}}
                />
              </Text>
            </Grid.Col> : null
          }
          
        </Grid>

        {/** This Action buttons component is used to display the buttons in the flow */}
        <ActionButtons
          status={stages?.current_stage}
          isReady={Boolean(stages?.current_stage)}
          nextStage={stages?.next_stage}
          remarks={reviewTrail}
          pdfLoading={pdfLoading}
          handlePDF={() => handleInvoicePDF()}
          accountingCB={false}
          currentStatus={data?.status_value}
          moduleApplicationId={data?.id}
          moduleName={'external_customer_invoice'}
          cancelCB={() => { setOpen(true); setRemarksTitle("Cancellation Remarks"); setStatus('cancellation'); setLabel('Send for Cancellation') }}
          approveCancelCB={() => { setOpen(true); setRemarksTitle("Are you sure?"); setStatus('cancel'); setLabel('Confirm') }}
          downloadText={data?.invoice_url ? 'Download Invoice' : 'Generate Invoice'}
          sendForApprovalCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('review'); setLabel('Send for Approval') }}
          approvedCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('approval'); setLabel('Approve') }}
          pushbackCB={() => { setOpen(true); setRemarksTitle("Pushback Remarks"); setStatus('pushback'); setLabel('Pushback') }}
          rejectCB={() => { setOpen(true); setRemarksTitle("Reject Remarks"); setStatus('reject'); setLabel('Reject') }}
          remarksCB={() => setRemarksOpen(true)}/>
        
        {/** used to display the modal for accounting data */}
        <AccountingInterfaceModal open={interfaceOpen} close={() => setInterfaceOpen(false)} id={createdID} name={'manual_invoice'} code={data?.code} />

        {/** used to get remarks from user */}
        <Remarks
          open={open}
          setOpen={setOpen}
          type={stages?.next_stage}
          callback={callFunction}
          remarksTitle={remarksTitle}
          moduleName={'external_customer_invoice'}
          check={status}
          label={label}
          loading={remarksLoading}
          currentStatus={data?.status_value}
          moduleApplicationId={data?.id}
        />

        {/** used to display the suceccfull stage change modal */}      
        <ModalDataViwer
          opened={sucessfulmodal?.open}
          label={data?.code}
          title={sucessfulmodal?.title}
          href={home}
        />
      </Body>
    </Box>

      {/** used to display the remarks */}      
      <ViewRemark open={remarksOpen} onClose={setRemarksOpen} remarkTrail={reviewTrail} />
    </>
  );
};

export default ManualCustomerInvoiceReview;
