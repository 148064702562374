import { getAPICall } from "./getAPICall";
import { postAPICall } from "./postAPICall"

/**
 * Sends an SMS via Whatsapp.
 *
 *  @param {object} body - The category to send the SMS.
 */
export const sendSMSviaWhatsapp = (body) => {
  return postAPICall('/mis-daily-via-whatsapp', { body, returnObject: true });
}

export const automateTriggers = (body) => {
  return postAPICall('send-mis-via-whatsapp/automation', { body, returnObject: true });
}

// used to get the triggers status
export const getTriggersStatus = () => {
  return getAPICall('get-mis-whatsapp-config', { returnFirstItem: true });
}