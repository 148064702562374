import { Box, Grid, Table } from '@mantine/core';
import React, { useState } from 'react';
import Body from '../../components/Body/Body';
import DataViewerWapper from '../../components/CommonComponents/Preview/DataViewerWapper';
import { ViewFormData } from '../../components/CommonComponents/Preview/FilePreview';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import PageTitle from '../../components/PageTitle/PageTitle';
import { COLORS, STAGE_CHANGE_TEXT } from '../../constants';
import ImdStyle from '../IMD/Imd.styles';
import IdBadge from '../../components/CommonComponents/Preview/IdBadge';
import Nums from '../../components/Body/Nums';
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from 'react-router-dom';
import {
    sendForPushbackWaiver,
    sendForRejectWaiver,
    getWaiverbyId,
    getAllRemarksWaiver,
    sendWaiverforCancellation,
    sendWaiverForNextStage
} from '../../services/waiver.service';
import ModalDataViwer from '../../components/SucessfullModal/ModalDataViwer';
import ViewRemark from '../../components/Remark/ViewRemark';
import Remarks from '../../components/CommonComponents/Remarks/Remarks';
import TitleViewer from '../../components/CommonComponents/Title/TitleViewer';
import DashBoardCustomerDetails from '../../components/DashboardCard/dashboard';
import ActionButtons
    from '../../components/CommonComponents/ActionButtons/ActionButtons';
import { generateWaiverPdfbyID } from '../../services/pdf.service';
import AccountingInterfaceModal
    from '../../components/CommonComponents/AccuntingInterface/AccountingInterfaceModal';
import { useQuery } from '@tanstack/react-query';
import { getStageforModules } from '../../services/module.service';
import { CANCELLATION_REVIEW, IN_APPROVAL, IN_REVIEW } from '../../constants/status';
import { openFile } from '../../utils/openFile';

const WaiverReview = ({ action = true, home = '/waiver' }) => {

  const { custId, createdwaiverId } = useParams();
  const queryClient = useQueryClient();
  
  const [open, setOpen] = useState(false);
  const [sucessfulmodal, setSucessfulmodal] = useState({});
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [remarksLoading, setRemarksLoading] = useState();
  const [remarksTitle, setRemarksTitle] = useState();
  const [status, setStatus] = useState();
  const [pdfLoading, setpdfLoading] = useState();
  const [label, setLabel] = useState();
  const [interfaceOpen, setInterfaceOpen] = useState(false)
  
  const { data: waiverDetails = [], isLoading } = useQuery(
    ['waiver-id', createdwaiverId],
    () => createdwaiverId && getWaiverbyId(createdwaiverId),
    { refetchOnWindowFocus: false }
  )

  const { data: stages = [] } = useQuery(
    ['receipt-stages'],
    () => getStageforModules('receipt'),
    {
      enabled: Boolean(waiverDetails?.status_value),
      select: (dataItem) => {
        return {
          'current_stage': waiverDetails?.status_value,
          'next_stage': dataItem[dataItem.indexOf(waiverDetails?.status_value) + 1]
        }
      },
      refetchOnWindowFocus: false
    }
  );

  const { data: reviewTrail = [] } = useQuery(
    ['remarks', createdwaiverId],
    () => createdwaiverId && getAllRemarksWaiver(createdwaiverId),
    { refetchOnWindowFocus: false }
  );

  const handlePDF = () => {
    setpdfLoading(true)
    generateWaiverPdfbyID(createdwaiverId)
      .then((res) => {
        queryClient.invalidateQueries(['waiver-id', createdwaiverId]);
        displayNotification({ message: 'PDF Generated Successfully', variant: 'success' })
        openFile(res)
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' })
      })
      .finally(() => {
        setpdfLoading(false)
      })
  }

  const onsendReceiptForNextStage = (remarks, status) => {
    const body = {
      remarks: remarks,
    };
    setRemarksLoading(true);
    sendWaiverForNextStage(createdwaiverId, body)
      .then((res) => {
        setSucessfulmodal({open: true, title:
          status == IN_REVIEW ? STAGE_CHANGE_TEXT?.approval
          : status == IN_APPROVAL ? STAGE_CHANGE_TEXT?.approved
          : status == CANCELLATION_REVIEW ? STAGE_CHANGE_TEXT?.reviewCancel
          : STAGE_CHANGE_TEXT?.approveCancel
        });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const handlePushback = (remarks) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true);
    sendForPushbackWaiver(createdwaiverId, body)
      .then((res) => {
        setSucessfulmodal({open: true, title: STAGE_CHANGE_TEXT?.pushback});
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const handleReject = (remarks) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true);
    sendForRejectWaiver(createdwaiverId, body)
      .then((res) => {
        setSucessfulmodal({open: true, title: STAGE_CHANGE_TEXT?.reject});
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const handleCancel = (remarks, reason) => {
    const body = {
      remarks,
      reason: reason?.reason,
    };
    setRemarksLoading(true);
    sendWaiverforCancellation(createdwaiverId, body)
      .then((res) => {
        setSucessfulmodal({open: true, title: STAGE_CHANGE_TEXT?.cancel});
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const callFunction = (remarks, status, reason) => {
    switch (status) {
      case 'review':
      case 'approval':
      case 'cancellation' :
        onsendReceiptForNextStage(remarks, status);
        break;
      case 'reject':
        handleReject(remarks);
        break;
      case 'pushback':
        handlePushback(remarks);
        break;
      case 'cancel':
        handleCancel(remarks, reason);
        break;
      default:
        displayNotification({
          message: 'Something went wrong, Please contact support!',
          variant: 'error',
        });
        break;
    }
  };

  return (
    <>
    <Body background={false}>
      <PageTitle title={waiverDetails?.customer_name} id={waiverDetails?.customer_id} />        
    </Body>
    <DashBoardCustomerDetails id={custId} loansId={waiverDetails?.loan_id} codeName={'Waiver Code'} code={waiverDetails?.code} />
    <Box sx={{ position: 'relative' }}>
      <Body>
        {waiverDetails?.prospect_code && (
          <div style={ImdStyle.flag}>
            <IdBadge
              remarks={waiverDetails?.status_value}
              label={waiverDetails?.prospect_code}
            />
          </div>
        )}
        <Grid gutter='xs' mt={'md'}>
          <Grid.Col span={4}>
            <Grid.Col>
              <DataViewerWapper>
                <ViewFormData label='Waiver No' value={waiverDetails?.id} loading={isLoading} />
                <ViewFormData
                  label='Waiver Date'
                  value={dateFormat(waiverDetails?.waiver_date, 'DD/MM/YYYY')}
                  loading={isLoading}
                />
                <ViewFormData
                  label='Waiver Amount'
                  value={<Nums withTooltip withCopy value={waiverDetails?.waiver_amount} />}
                  loading={isLoading}
                />
                <ViewFormData label='Reason' value={waiverDetails?.waiver_reason} loading={isLoading} />
                {waiverDetails?.waiver_reasons == 'Others' && <ViewFormData label='Reason' value={waiverDetails?.other_reason} />}
              </DataViewerWapper>
            </Grid.Col>
          </Grid.Col>        
          <Grid.Col span={8}>
              <Grid.Col>
                <TitleViewer title='Waiver Amount' />
              </Grid.Col>
              <Table verticalSpacing='xs' fontSize='xs'>
                <thead style={{ backgroundColor: COLORS.primary.light1 }}>
                  <tr>
                    <th>Pay Head</th>
                    <th>Due Amount</th>
                    <th>Waiver Amount</th>
                    <th>Remaining Due</th>
                  </tr>
                </thead>
                <tbody>
                  
                  <tr>
                    <td>Principal</td>
                    <td>
                      <Nums value={waiverDetails?.outstanding_principle} />
                    </td>
                    <td>                    
                        NA                           
                    </td>                                              
                    <td>
                      <Nums value=
                        {waiverDetails?.remaining_principle}
                      />
                    </td>
                  </tr>

                  { waiverDetails?.outstanding_interest ?
                    <tr>
                      <td>Interest ({waiverDetails?.interest_rate}%)</td>
                      <td>
                        <Nums value={waiverDetails?.outstanding_interest} />
                      </td>
                      <td>                      
                          <Nums value={waiverDetails?.waiver_interest} />                           
                      </td>                                              
                      <td>
                        <Nums value=
                          {waiverDetails?.remaining_interest}
                        />
                      </td>
                    </tr> : null
                  }

                  { waiverDetails?.outstanding_spread ?
                    <tr>
                      <td>Spread ({waiverDetails?.spread_rate}%)</td>
                      <td>
                        <Nums value={waiverDetails?.outstanding_spread} />
                      </td>
                      <td>                      
                          <Nums value={waiverDetails?.waiver_spread} />
                      </td>                                              
                      <td>
                        <Nums value=
                          {waiverDetails?.remaining_spread}
                        />
                      </td>
                    </tr> : null
                  }

                  { waiverDetails?.outstanding_addon_spread ?
                    <tr>
                      <td>Addon Spread ({waiverDetails?.addon_spread_rate}%)</td>
                      <td>
                        <Nums value={waiverDetails?.outstanding_addon_spread} />
                      </td>
                      <td>                      
                          <Nums value={waiverDetails?.waiver_addon_spread} />
                      </td>                                              
                      <td>
                        <Nums value=
                          {waiverDetails?.remaining_addon_spread}
                        />
                      </td>
                    </tr> : null
                  }

                  {/** penal for interest */}
                  { waiverDetails?.outstanding_interest_amount_during_penalty ?
                  <tr>
                    <td>Interest F1 ({waiverDetails?.interest_rate}%)</td>
                    <td>
                      <Nums value={waiverDetails?.outstanding_interest_amount_during_penalty} />
                    </td>
                    <td>
                        <Nums value={waiverDetails?.waiver_interest_amount_during_penalty} />                           
                    </td>                                              
                    <td>
                      <Nums value=
                        {waiverDetails?.remaining_interest_amount_during_penalty}
                      />
                    </td>
                  </tr> : null
                  }

                  {/** penal for spread */}
                  { waiverDetails?.outstanding_spread_amount_during_penalty ?
                  <tr>
                    <td>Spread F1 ({waiverDetails?.spread_rate}%)</td>
                    <td>
                      <Nums value={waiverDetails?.outstanding_spread_amount_during_penalty} />
                    </td>
                    <td>
                        <Nums value={waiverDetails?.waiver_spread_amount_during_penalty} />
                    </td>                                              
                    <td>
                      <Nums value=
                        {waiverDetails?.remaining_spread_amount_during_penalty}
                      />
                    </td>
                  </tr> : null
                  }

                  {/** penal for addon_spread */}
                  { waiverDetails?.outstanding_addon_spread_amount_during_penalty ?
                  <tr>
                    <td>Addon Spread F1 ({waiverDetails?.addon_spread_rate}%)</td>
                    <td>
                      <Nums value={waiverDetails?.outstanding_addon_spread_amount_during_penalty} />
                    </td>
                    <td>
                        <Nums value={waiverDetails?.waiver_addon_spread_amount_during_penalty} />                           
                    </td>                                              
                    <td>
                      <Nums value=
                        {waiverDetails?.remaining_addon_spread_amount_during_penalty}
                      />
                    </td>
                  </tr> : null
                  }

                  { waiverDetails?.outstanding_penal_interest_amount ?
                    <tr>
                      <td>Penal F2 ({waiverDetails?.penal_rate}%)</td>
                      <td>
                        <Nums value={waiverDetails?.outstanding_penal_interest_amount} />
                      </td>
                      <td>
                          <Nums value={waiverDetails?.waiver_penal_interest_amount} />                           
                      </td>                                              
                      <td>
                        <Nums value=
                          {waiverDetails?.remaining_penal_interest_amount}
                        />
                      </td>
                    </tr> : null
                  }

                  { waiverDetails?.outstanding_charges ?
                    <tr>
                      <td>Fee</td>
                      <td>
                        <Nums value={waiverDetails?.outstanding_charges} />
                      </td>
                      <td>                      
                          <Nums value={waiverDetails?.waiver_charges} />                           
                      </td>                                              
                      <td>
                        <Nums value=
                          {waiverDetails?.remaining_charges}
                        />
                      </td>
                    </tr> : null
                  }
                  
                </tbody>
              </Table>
            </Grid.Col>
        </Grid>  
          {/** This Action buttons component is used to display the buttons in the flow */}
        <ActionButtons
          status={stages?.current_stage}
          currentStatus={waiverDetails?.status_value}
          moduleApplicationId={waiverDetails?.id}
          moduleName={'waiver'}
          nextStage={stages?.next_stage}
          isReady={Boolean(stages?.next_stage)}
          remarks={reviewTrail}
          accountingCB={() => setInterfaceOpen(true)}
          pdfLoading={pdfLoading}
          handlePDF={() => waiverDetails?.waiver_url ? openFile(waiverDetails?.waiver_url) : handlePDF()}
          downloadText={waiverDetails?.waiver_url ? 'Download Waiver PDF' : 'Generate Waiver PDF'}
          sendForApprovalCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('review'); setLabel('Send for Approval') }}
          approvedCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('approval'); setLabel('Approve') }}
          pushbackCB={() => { setOpen(true); setRemarksTitle("Pushback Remarks"); setStatus('pushback'); setLabel('Pushback') }}
          rejectCB={() => { setOpen(true); setRemarksTitle("Reject Remarks"); setStatus('reject'); setLabel('Reject') }}
          cancelCB={() => { setOpen(true); setRemarksTitle("Cancellation Remarks"); setStatus('cancellation'); setLabel('Send for Cancellation') }}
          approveCancelCB={() => { setOpen(true); setRemarksTitle("Are you sure?"); setStatus('cancel'); setLabel('Confirm') }}
          remarksCB={() => setRemarksOpen(true)}/>
          {/** used to get the remarks from the user */}        
        <Remarks
            open={open}
            setOpen={setOpen}
            type={stages?.next_stage}
            callback={callFunction}
            remarksTitle={remarksTitle}
            moduleName={'Waiver'}
            check={status}
            label={label}
            loading={remarksLoading}
            currentStatus={waiverDetails?.status_value}
            moduleApplicationId={waiverDetails?.id}
          />
          {/** display the modal on sucessfull status change */}
          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={waiverDetails?.code}
            title={sucessfulmodal?.title}
            href={home}
            hreftext={'Back to list table'}
          />
        </Body>
        
        {/** used to display the modal for accounting data */}
        <AccountingInterfaceModal open={interfaceOpen} close={() => setInterfaceOpen(false)} id={createdwaiverId} name={'waiver'} code={waiverDetails?.code} />      

        {/** used to display the remarks */}
        <ViewRemark open={remarksOpen} onClose={setRemarksOpen} remarkTrail={reviewTrail} />
      </Box>
    </>
  );
};

export default WaiverReview;
