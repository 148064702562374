import React, { useState } from "react";
import { Box, Title, createStyles } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../../../components/CommonComponents/Table/ReactTable";
import { getMasterRegions } from '../../../../services/master.service';
import { Search } from 'tabler-icons-react';
import { TextInput } from '@mantine/core';

const useStyles = createStyles({
  main: {    
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "center",
    width: "100%",
  }
})

const RegionTable = () => {
  const { classes } = useStyles();
  const columnHelper = createColumnHelper();
  const [search, setSearch] = useState();

  const { data: region = [], isLoading } = useQuery(
    ["master-region"],
    () => getMasterRegions(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const columnData = [
    columnHelper.accessor("region_name", {
      header: "Region Name",
    }),
    columnHelper.accessor("state_name", {
      header: "State Name",
    }),
    columnHelper.accessor("zone_name", {
      header: "Zone Name",
    }),
  ];

  return (
    <>
      <Box className={classes.main}>
        <Title order={4} pl={'5px'}>Regions</Title>        
        <TextInput
          placeholder='Search'
          onChange={(e) => setSearch(e.target.value)}
          icon={<Search size={16} />}
        />
      </Box>
      <ReactTable
        title="Region"
        rowData={region}
        columnData={columnData}
        allowSorting={true}
        loading={isLoading}
        search={search}
        setSearch={setSearch}
      />
    </>
  );
};

export default RegionTable;
