import React, { useRef } from 'react'
import { Grid, Text, Stack, Group, ActionIcon, NumberInput, Title } from '@mantine/core';
import ControllerTextField from '../../../components/CommonComponents/TextField/ControllerTextField';
import ControllerSwitch from '../../../components/CommonComponents/TextField/ControllerSwitch';

/**
 * Renders an invoicing invoice component with customer details and options.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.control - Controller object from react-hook-form.
 * @param {Object} props.errors - Error object from react-hook-form.
 * @param {Function} props.setValue - Function to set form value from react-hook-form.
 * @param {Object} props.placeholderStyle - Styles for input placeholders.
 * @param {Object} props.values - Values object from react-hook-form.
 */
const InvoicingInvoiceComp = ({ control, errors, setValue, placeholderStyle, values }) => {
  const handlers = useRef();
  return (
    <Grid gutter={'md'} sx={{maxHeight:"55vh",overflow:'hidden',overflowY: 'auto'}}>
      <Grid.Col span={12} sx={{ height: 'fit-content' }}>
        <Title order={6} mb={'md'}>Invoicing</Title>
        <Grid gutter={'md'}>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Bill-To Customer No</Text>
              <ControllerTextField
                name={'bill_to_customer_no'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Customer No'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.bill_to?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>VAT Registration No</Text>
              <ControllerTextField
                name={'vat_registration_no'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter VAT Registration No'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.vat_no?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>GLN</Text>
              <ControllerTextField
                name={'gln'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter GLN'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.gln?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Invoice Disc. Code</Text>
              <ControllerTextField
                name={'invoice_disc_code'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Invoice Disc. Code'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.invoice_disc_code?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Copy Sell-to Addr. to Qte from</Text>
              <ControllerTextField
                name={'copy_sell_to_addr_to_qte_from'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Copy Sell-to Addr. to Qte from'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.sell_to?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Gen. Bus. Posting Group</Text>
              <ControllerTextField
                name={'gen_bus_posting_group'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Gen. Bus. Posting Group'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.posting_group?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>VAT Bus. Posting Group</Text>
              <ControllerTextField
                name={'vat_bus_posting_group'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter VAT Bus. Posting Group'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.excise_posting_group?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Excise Bus. Posting Group</Text>
              <ControllerTextField
                name={'excise_bus_posting_group'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Excise Bus. Posting Group'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.excise_posting_group?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Customer Posting Group</Text>
              <ControllerTextField
                name={'customer_posting_group'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Customer Posting Group'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.customer_posting_group?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Customer Price Group</Text>
              <ControllerTextField
                name={'customer_price_group'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Customer Price Group'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.customer_price_group?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Customer Disc. Group</Text>
              <ControllerTextField
                name={'customer_disc_group'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Customer Disc. Group'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.customer_disc_group?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Prepayment %</Text>
              <ControllerTextField
                name={'prepayment'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Prepayment %'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.prepayment?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={2} py={0}>
            <Stack spacing={'2px'}>
              <Text size={'xs'}>Invoice Copies</Text>
               <Group spacing={5} >
                <ActionIcon  onClick={() => handlers.current.decrement()}>
                  -
                </ActionIcon>

                <NumberInput
                  hideControls
                  value={values?.invoice_copies || ''}
                  onChange={(val) => setValue("invoice_copies", val)}
                  handlersRef={handlers}
                  styles={{ input: { width: '45px', textAlign: 'center' } }}
                />

                <ActionIcon  onClick={() => handlers.current.increment()}>
                  +
                </ActionIcon>
              </Group>
            </Stack>
          </Grid.Col>
          <Grid.Col span={2} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Allow Line Disc.</Text>
              <ControllerSwitch
                name={'allow_line_disc'}
                displayLabel={false}
                spanSize={12}
                control={control}
                onLabel="Yes"
                offLabel="No"
                size={'md'}
                color="green"
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={2} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Prices Including VAT</Text>
              <ControllerSwitch
                name={'prices_including_vat'}
                displayLabel={false}
                spanSize={12}
                control={control}
                onLabel="Yes"
                offLabel="No"
                size={'md'}
                color="green"
              />
            </Stack>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  )
}

export default InvoicingInvoiceComp