import { Button, Text } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Plus } from 'tabler-icons-react';
import Body from '../../components/Body/Body';
import DataTableWrapper from '../../components/CommonComponents/Table/DataTableWrapper';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import { 
  getAllManualInvoice } from '../../services/invoice.service';
import Nums from '../../components/Body/Nums';
import {
    APPROVED,
    CANCELLED,
    IN_APPROVAL,
    IN_REVIEW,
    OPEN,
    REJECTED,
    CANCELLATION_REVIEW,
    CANCELLATION_APPROVAL,
} from '../../constants/status';
import { useQuery } from '@tanstack/react-query';
import useModuleRecordCountStore from '../../store/moduleRecordCountStore';

const InvoiceManualTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const columnHelper = createColumnHelper();
  const { moduleCount } = useModuleRecordCountStore();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [records, setRecords] = useState({label: 'Show 10', value: 10});
  // get the search params
  const [searchParams, setSearchParams] = useSearchParams();
  const params = {}
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  /** used to change the page to 1 while the search us used 
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1)
  }, [search, searchParams.get("status"), searchParams.get("entity"), records])

  const { data: manualInvoice = [], isLoading } = useQuery(
    ['invoice-manual', page, search, records, params],
    () => getAllManualInvoice({page, search, records: records?.value, ...params}),
    {
      enabled: Boolean(searchParams.get("status") && searchParams.get("status") !== "none"),
      refetchOnWindowFocus: false
    }
  )

  const onRowClick = (i) => {
    if (i?.status_value == OPEN) {
      navigate(`/invoice-manual/view/${i?.id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == IN_APPROVAL || i?.status_value == IN_REVIEW) {
      navigate(`/invoice-manual/review/${i?.id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == APPROVED) {
      navigate(`/invoice-manual/approved/${i?.id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == REJECTED) {
      navigate(`/invoice-manual/rejected/${i?.id}`, { state: { from: location.pathname } });
    } else if(i?.status_value == CANCELLED) {
      navigate(`/cancellation/invoice-cancelled/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if(i?.status_value == CANCELLATION_REVIEW || i?.status_value == CANCELLATION_APPROVAL) {
      navigate(`/cancellation/invoice-cancel/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    }
  };

  const onClickAction = (value) => {
    return (
      <span
        style={{ color: '#0063FF', cursor: 'pointer' }}
      >
        {value.getValue()}
      </span>
    );
  };

  const tableAction = (
    <Button 
      variant='filled' 
      color='primary' 
      size='sm' 
      leftIcon={<Plus size={18} />} 
      onClick={() => navigate(`/invoice-manual/manual`, { state: { from: location.pathname } })}
    >
      Create Manual Invoice
    </Button>
  );

  const columnData = [
    columnHelper.accessor('code', {
      header: 'Manual Invoice Id',
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor('customer_name', {
      header: 'Customer Name',
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor('customer_id', {
      header: 'Customer Code',
    }),
    columnHelper.accessor('charge_amount', {
      header: <Text sx={{textAlign: 'right'}}>Charge Amount</Text>,
      cell: (value) => <Nums value={value.getValue()} styles={{textAlign: 'right'}} />,
    }),
    columnHelper.accessor('created_date', {
      header: 'Created Date',
      cell: (value) => dateFormat(value.getValue()),
    }),
    columnHelper.accessor('maker_name', {
      header: 'Created By',
    }),
  ];

  return (
    <Body background={false}>
      <DataTableWrapper
        title='Manual Invoice'
        filter={false}
        rowData={manualInvoice?.data}
        columnData={columnData}
        onRowClick={onRowClick}
        noDataText='No Manual Invoices yet!'
        noDataSubText='This section will contain list of created manual invoice details.'
        useApiPagination
        totalNoOfPages= {Math.ceil(parseInt(moduleCount?.count)/records?.value)}
        totalNoOfRecords={moduleCount?.count}
        page={page}
        setPage={setPage}
        rowLength={records}
        setRowLength={setRecords}
        apiSearch={setSearch}
        showStatus
        module_name='invoice'
        addDataCallback={() => navigate(`/invoice-manual/manual`, { state: { from: location.pathname } })}
        actions={tableAction}
        // when the status is none then the loading is set to false other wise it will dependent on isLoading
        loading={searchParams.get("status") !== "none" ? isLoading : searchParams.get("status") === null ? true : false}
      />
    </Body>
  );
};

export default InvoiceManualTable;
