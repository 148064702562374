import React, { useEffect, useState } from "react";
import DialogModal from "../Modal/DialogModal";
import { Box, Text, Button, Modal, Title, createStyles } from "@mantine/core";
import TextFieldEditor from "../TextEditor/TextFieldEditor";
import Select from 'react-select';
import { REMARKS_MESSAGE } from "../../../constants";
import { useQuery } from '@tanstack/react-query';
import { getCancellationRemarks } from "../../../services/module.service";
import { ReceiptAmountAlert } from "../../../constants/alertMessage";
import { getCurrentStatusByModuleNameAndId } from './../../../services/module.service';
import { ArrowNarrowLeft } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import { displayNotification } from "../Notification/displayNotification";

const useStyles = createStyles({
  modal: {
    '.mantine-Modal-content': {
      overflowY: 'visible',
    }
  }
})

// 1.5 is value which we multiply with due amount to check receipt amount is greater then the multiplied amount amount
const dueAmountMultiplier = 1.5;

/** 
 * open = will contain true or false to open or close the modal
 * setOpen = used to set the modal to close
 * type = contains next stage name
 * callback = callback will contain the function
 * remarksTitle = this will contain the title of the remarks
 * check = this will contain the next stage
 * moduleName = this will contain the module name to get the cancellation reason
 * label = will have button name
 * loading = will contain loading is true or false
 */

// !TODO: this has to be removed once Race Condition is handled at Server level
let callbackTimeout = null;

const Remarks = ({
  open,
  setOpen,
  type,
  callback,
  remarksTitle,
  moduleName,
  check,
  label,
  loading,
  dueAmount = 0,
  totalAmount = 0,
  currentStatus,
  moduleApplicationId,
}) => {
  
  const { classes } = useStyles();
  const [disableAction, setDisableAction] = useState(false);
  const [remarks, setRemarks] = useState();
  const [error, setError] = useState();
  const [selectedReason, setSelectedReason] = useState();
  const [statusModal, setStatusModal] = useState({open:false, status: ''});

  const navigate = useNavigate();

  const { data: cancellationReason = [] } = useQuery(
    ['cancellation-reason'],
    () => getCancellationRemarks(moduleName),
    {
      enabled: Boolean(['cancel', 'e-invoice_cancel']?.includes(check)),
      refetchOnWindowFocus: false,
    }
  )

  // check the receipt amount is 1.5x greater then the due amount
  const checkTotalAmount = () => {
    if(dueAmount*dueAmountMultiplier < totalAmount) {
      return true
    }
  }

  // checking the current status of the application if they are same then the callback will get executed
  // else the alert modal will get displayed
  const checkCurrentStatusCallback = (remarks, check, reason) => {
    if(moduleApplicationId) {
      getCurrentStatusByModuleNameAndId({ moduleName: moduleName?.toLowerCase(), moduleApplicationId })
        .then((res) => {
          if (res?.current_status === currentStatus || res?.current_status === null) {
            callback(remarks, check, reason);
          } else {
            setOpen(!open)
            setStatusModal({ open: true, status: res?.current_status });
          }
        })
        .catch((e) => {
          console.log(e);
          setDisableAction(false);
          displayNotification({
            message: "Server is busy. Please try again after sometime.",
            variant: 'error'
          });
        });
    } else {
      callback(remarks, check, reason);
    }
  };

  useEffect(() => {
    setError();
  }, [remarks, selectedReason]);

  /** used to empty the state when the open changes */
  useEffect(() => {
    setRemarks((check == "reject" || check == "cancel" || check == "pushback") ? '' : REMARKS_MESSAGE[type]);
    setSelectedReason()
  }, [open]);

  /** used to enable actions if the API fails */
  useEffect(() => {
    if (!loading && disableAction) {
      setDisableAction(false);
    }
  }, [loading])

  // Wait 1 second before executing the callback
  const checkCurrentStatus = (remarks, check, reason) => {
    // disable the action at the first time
    setDisableAction(true);
    if (callbackTimeout) clearTimeout(callbackTimeout);
    callbackTimeout = setTimeout(() => {
      checkCurrentStatusCallback(remarks, check, reason);
    }, 500);
  }

  /** used to handle the null remarks */
  const handleNullRemarks = (val) => {
    var textval = val;
    var div = document.createElement("div");
    div.innerHTML = textval;
    var isNull = (div.textContent || div.innerText || "").trim();
    return isNull?.length > 0 ? val : null;
  };

  /** used to handle the check */
  const handleRemarks = () => {
    if(['cancel', 'e-invoice_cancel']?.includes(check)) {
      if(!selectedReason?.reason)
        setError('Please select the reason')
      else if(Boolean(selectedReason?.mandate_user_input) && !handleNullRemarks(remarks)?.length)
        setError('Please Enter the remarks')
      else {
        checkCurrentStatus(handleNullRemarks(remarks), check, selectedReason)
      }
    }
    else {
      checkCurrentStatus(handleNullRemarks(remarks), check)
    }
  }

  return (
    <>      
      <DialogModal
        open={open}
        className={classes.modal}
        title={<Title order={5} sx={{fontWeight: '500'}}>{remarksTitle}</Title>}
        onClose={() => setOpen(false)}
        size="lg"
        centered
        transitionProps={{ transition: 'fade', duration: 600 }}
      >
        <Box mt="md">
          {
            ['cancel', 'e-invoice_cancel']?.includes(check) ? 
            <Box sx={{padding: '10px 0'}}>
              <Text size={'md'} mb={'xs'}>Please choose a reason for cancelling this.</Text>
              <Select 
                options={cancellationReason} 
                value={selectedReason} 
                onChange={setSelectedReason} 
                placeholder={'Select cancellation Reason'}
                styles={{
                  menu: (baseStyle) => ({
                    ...baseStyle,
                    zIndex: '9',
                    overflow: 'hidden',
                    overflowY: 'scroll',
                  }),
                  control: (styles) => ({
                    ...styles,
                    overflowY: 'scroll'
                  }),
                }} 
              />
            </Box> : null
          }
          {
            /** 
             * while check is cancel and the selected reason has the remarks as mandatory then text input will display
             *  or other wise if check is not cancel then it will display
            */
            (['cancel', 'e-invoice_cancel']?.includes(check) && Boolean(selectedReason?.mandate_user_input)) || !['cancel', 'e-invoice_cancel']?.includes(check) ? 
            <TextFieldEditor
              value={
                check == "reject" || check == "cancel" || check == "pushback"
                  ? remarks
                  : REMARKS_MESSAGE[type]
              }
              onChange={setRemarks}
            /> : null
          }
        </Box>
        <Text size={"xs"} color={"red"}>
          {error}
        </Text>
        {checkTotalAmount()
          ? ReceiptAmountAlert()
          : null
        }
        <Box mt="xl" sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
            <Button variant="subtle" onClick={() => setOpen(!open)}>
              Cancel
            </Button>
            <Button
              color={["cancellation", "cancel", "reject"].includes(check) ? "red" : "green"}
              variant={"filled"}
              onClick={() => {
                handleRemarks()
              }}
              loading={loading || disableAction}
            >
              {label}
            </Button>
        </Box>
      </DialogModal>
      <DialogModal centered size={'32rem'} open={statusModal?.open} onClose={() => setStatusModal({open: false})} title="Alert!" styles={(them) => ({title: {fontWeight: '500'}})}>
          <Text>
              The current application is already moved to <strong>{statusModal?.status}</strong> status.
              Please go back to proceed further
          </Text>
          <Box sx={{display: 'flex', justifyContent: 'right', gap:'20px'}} mt={'md'}>
              <Button variant='outline' leftIcon={<ArrowNarrowLeft   />} onClick={() => navigate(-1)}>Go Back</Button>
          </Box>
      </DialogModal>
    </>
  );
};

export default Remarks;
