import {
  Box,
  Grid,
  Text,
  SegmentedControl,
  Button,
  Autocomplete,
  Popover,
} from "@mantine/core";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import Body from "../../components/Body/Body";
import PageTitle from "../../components/PageTitle/PageTitle";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import { DateRange } from "react-date-range";
import { subDays } from "date-fns";
import {
  searchCustomerApplicationId,
  getCustomerStatementOfAccounts,
} from "../../services/customer.service";
import { FocusTrap } from "@mantine/core";
import { Title } from "@mantine/core";
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { useQuery } from "@tanstack/react-query";
import useSystemConfigStore from './../../store/systemConfigStore';
import { getSignedUrl } from "../../services/common.service";

const PreviewWrapper = styled.div`
  width: 100%;
  .container {
    height: 72vh;
    overflow: hidden;
    position: relative;
  }
`;

const SOAFilter = () => {
  const [showPicker, setShowPicker] = useState(false);
  const systemDate = useSystemConfigStore(store => store.config);
  const [searchedValue, setSearchedValue] = useState("");
  const [active, setActive] = useState(true);
  const [selectedValue, setSelectedValue] = useState({});
  const [error, setError] = useState();
  const [signedUrl, setSignedUrl] = useState();
  const [selectedPeriodType, setSelectedPeriodType] = useState("cfy");
  /** here we are set the default value as current fiance statement */
  const [selectedPeriod, setSelectedPeriod] = useState(
    new Date().getMonth() + 1 <= 3
      ? {
        from: `04-01-${new Date().getFullYear() - 1}`,
        to: new Date(),
      }
      : {
        from: `04-01-${new Date().getFullYear()}`,
        to: new Date(),
      }
  );
  const [dateRange, setDateRange] = useState({
    startDate: subDays(new Date(), 8),
    endDate: new Date(),
    key: "range",
  });

  const { data: onSearchData = [], isLoading: searchLoading } = useQuery(
    ["search-data", searchedValue],
    /*
     * sending the signal to cancel the per pending api call
     * signal will contain the abort signal
     */
    ({ signal }) => searchCustomerApplicationId(searchedValue, signal),
    {
      /** this api call will call when the searched value length is min of 2 */
      enabled: Boolean(searchedValue?.length > 2),
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: reportUrl,
    isFetching,
    refetch,
  } = useQuery(
    ["soa-statement", selectedValue, selectedPeriod],
    ({ signal }) =>
      getCustomerStatementOfAccounts(
        {
          customerApplicantId: selectedValue?.id,
          fromDate: dateFormat(selectedPeriod?.from, "YYYY-MM-DD"),
          toDate: dateFormat(selectedPeriod?.to, "YYYY-MM-DD"),
        },
        signal
      ),
    {
      onSuccess: (data) => {
        displayNotification({
          message: data?.data?.length
            ? "PDF Created Successfully"
            : data?.message,
            variant: 'success',
        });
      },
      onError: (e) => {
        displayNotification({
          message: e?.error?.message || e?.message,
          variant: 'error',
        });
      },
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    getUrl();
  }, [reportUrl])

  const getUrl = async () => {
    if (reportUrl?.data) {
      const response = await getSignedUrl(reportUrl?.data)
      setSignedUrl(response?.url)
    }
  }

  /** changing the date which user has selected */
  const onDatePickerChange = ({ range }) => {
    setDateRange(range);
  };

  /** handling the custom date */
  const onDateRangeClose = () => {
    setSelectedPeriod({
      from: dateRange.startDate,
      to: dateRange.endDate,
    });
    setShowPicker();
  };

  /** used to handle the from-date and to-date */
  const onDateChange = (type) => {
    /** set the selected value of segment control */
    setSelectedPeriodType(type);
    /** changing the from-date and to-date according to the selectedPeriodType */
    switch (type) {
      case "pfy":
        showPicker && setShowPicker(false);
        if (new Date().getMonth() + 1 <= 3) {
          setSelectedPeriod({
            from: `04-01-${new Date().getFullYear() - 2}`,
            to: `03-31-${new Date().getFullYear() - 1}`,
          });
        } else {
          setSelectedPeriod({
            from: `04-01-${new Date().getFullYear() - 1}`,
            to: `03-31-${new Date().getFullYear()}`,
          });
        }
        break;
      case "cfy":
        showPicker && setShowPicker(false);
        if (new Date().getMonth() + 1 <= 3) {
          setSelectedPeriod({
            from: `04-01-${new Date().getFullYear() - 1}`,
            to: new Date(),
          });
        } else {
          setSelectedPeriod({
            from: `04-01-${new Date().getFullYear()}`,
            to: new Date(),
          });
        }
        break;
      case "custom":
        /** opening the modal to select the date */
        setShowPicker(true);
        break;
      default:
        break;
    }
  };

  return (
    <Body background={false}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          marginBottom: 1,
          alignItems: "center",
        }}
      >
        <PageTitle title={"Statement Of Accounts"} />
      </Box>
      <Box>
        <Grid>
          <Grid.Col span={4} mt={"md"}>
            <Grid.Col span={12}>
              <Text size={"sm"} color={"gray"}>
                Customer Search
              </Text>
              <FocusTrap active={active}>
                <Autocomplete
                  data-autofocus
                  value={searchedValue}
                  onChange={(value) => {
                    setSearchedValue(value);
                    error && setError();
                  }}
                  /** this prop will trigger when the user select the option in drop down */
                  onItemSubmit={(value) => {
                    setSelectedValue(value);
                    setActive(false);
                  }}
                  data={onSearchData}
                  placeholder="Search by customer code / Name"
                  filter={(value, item) =>
                    item.value.includes(value.trim()) ||
                    item.label.toLowerCase().includes(value.toLowerCase().trim())
                  }
                />
              </FocusTrap>
              {error && (
                <Text size={"10px"} color={"red"}>
                  {error}
                </Text>
              )}
            </Grid.Col>
            {selectedValue?.customer_name ? (
              <>
                <Grid.Col span={12}>
                  <Text size={"xs"} color={"gray"}>
                    Name
                  </Text>
                  <Title order={6} mb={"xs"}>
                    {selectedValue?.customer_name}
                  </Title>
                  <Text size={"xs"} color={"gray"}>
                    Product
                  </Text>
                  <Title order={6} mb={"xs"}>
                    {selectedValue?.product_name}
                  </Title>
                  <Text size={"xs"} color={"gray"}>
                    Approved Amount
                  </Text>
                  <Title order={6}>{selectedValue?.approved_amount}</Title>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Text size={"sm"} color={"gray"}>
                    Date
                  </Text>
                  <Popover
                    position="right"
                    shadow="md"
                    opened={showPicker}
                    onChange={setShowPicker}
                  >
                    <Popover.Target>
                      <SegmentedControl
                        value={selectedPeriodType}
                        size={"xs"}
                        onChange={(e) => {
                          onDateChange(e);
                        }}
                        styles={() => ({
                          root: {
                            background: "white",
                          },
                        })}
                        data={[
                          { label: "Previous FY", value: "pfy" },
                          { label: "Current FY", value: "cfy" },
                          {
                            label:
                              selectedPeriodType == "custom"
                                ? `${dateFormat(
                                  selectedPeriod?.from
                                )} to ${dateFormat(selectedPeriod?.to)}`
                                : "Custom",
                            value: "custom",
                          },
                        ]}
                        color={"blue"}
                      />
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <DateRange
                          ranges={[dateRange]}
                          onChange={onDatePickerChange}
                          maxDate={new Date()}
                          months={2}
                          direction="horizontal"
                          retainEndDateOnFirstSelection
                        />
                        <Button onClick={onDateRangeClose}>Apply</Button>
                      </Box>
                    </Popover.Dropdown>
                  </Popover>
                </Grid.Col>
                <Grid.Col
                  mt={"lg"}
                  span={12}
                  sx={{ display: "flex", gap: "10px" }}
                >
                  <Button
                    onClick={() => refetch()}
                    loading={isFetching}
                    color={"green"}
                  >
                    Fetch
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => {
                      setSelectedValue({});
                      setSearchedValue("");
                      setActive(true);
                      reportUrl.data = null;
                    }}
                  >
                    Reset
                  </Button>
                </Grid.Col>
              </>
            ) : null}
          </Grid.Col>
          <Grid.Col span={8}>
            <PreviewWrapper>
              <div className="container">
                <embed
                  title="PDF"
                  type="application/pdf"
                  src={signedUrl}
                  frameBorder="0"
                  width={"100%"}
                  height={"100%"}
                />
              </div>
            </PreviewWrapper>
          </Grid.Col>
        </Grid>
      </Box>
    </Body>
  );
};

export default SOAFilter;
