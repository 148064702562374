import { Grid, Text, Title } from '@mantine/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import TitleComp from '../TextComponent/TitleComp';

const ControllerCreatableSelect = ({
  name,
  label,
  placeholder,
  setSearchData,
  displayLabel = true,
  spanSize = 7,
  control,
  onCreateOption,
  required = false,
  data,
  errors,
  ...props
}) => {
  return (
    <Grid sx={{ display: 'flex', alignItems: 'center', fontWeight: '400' }}>
      {displayLabel ? <Grid.Col span={5} sx={{ minHeight: '57px', padding: '10px 8px 0 8px', display: 'flex' }}>
        <TitleComp label={label} />
        {required ? <Text ml={'4px'} color="red">*</Text> : null}
      </Grid.Col> : null}
      <Grid.Col span={spanSize}  sx={{ minHeight: '57px', padding: '0 8px 0 8px' }}>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value, name, ref, ...rest } }) => (
            <CreatableSelect
              id={name}
              placeholder={placeholder}
              styles={{ menu: (provided) => ({ ...provided, zIndex: 9999, }), option: (provided) => ({ ...provided, fontSize: '13px', padding: '5px 15px 5px 15px' }), control: (provided) => ({ ...provided, fontSize: '13px'})}}
              options={data}
              value={data.find((c) => c?.value == value)}
              onInputChange={(e) => setSearchData(e)}
              onCreateOption={(e) => onCreateOption()}
              onChange={(val) => onChange(val.value)}
              error={errors? false : true}
              {...props}
              {...rest}
            />
          )}
        />
        {errors && <Text size={'xs'} sx={{color: '#f13e3e'}}>{errors}</Text>}
      </Grid.Col>
    </Grid>
  );
};

export default ControllerCreatableSelect;
