import { Box, Grid, Image, Button } from "@mantine/core";
import React, { useState } from "react";
import { useParams } from "react-router";
import DataViewerWapper from "../../components/CommonComponents/Preview/DataViewerWapper";
import {
  ViewFormData,
  ViewFormDataContained,
} from "../../components/CommonComponents/Preview/FilePreview";
import ImdStyle from "../IMD/Imd.styles";
import { useQuery } from "@tanstack/react-query";
import TitleViewer from "../../components/CommonComponents/Title/TitleViewer";
import Body from "../../components/Body/Body";
import DashBoardCustomerDetails from "../../components/DashboardCard/dashboard";
import {
  customerBankDetails,
  CompanyBankDetails,
} from "../../services/imd.service";
import {
  getApprovedDisbById,
  getPaymentById,
  SendForPushbackPayment,
  SendForRejectPayment,
  getAllRemarksPayment,
  sendPaymentforCancellation,
  sendPaymentforNextStage,
} from "../../services/payment.service";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import Remarks from "../../components/CommonComponents/Remarks/Remarks";
import { AvatarCard } from "../../components/CommonComponents/Preview/FileAvatar";
import TitleComp from "../../components/CommonComponents/TextComponent/TitleComp";
import ViewRemark from "../../components/Remark/ViewRemark";
import PageTitle from "../../components/PageTitle/PageTitle";
import Nums from "../../components/Body/Nums";
import { GENERAL_IMG, STAGE_CHANGE_TEXT } from "../../constants";
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { getDeductionLoansbyID } from "../../services/loan.service";
import IdBadge from "../../components/CommonComponents/Preview/IdBadge";
import ModalDataViwer from "../../components/SucessfullModal/ModalDataViwer";
import ActionButtons from "../../components/CommonComponents/ActionButtons/ActionButtons";
import AccountingInterfaceModal from "../../components/CommonComponents/AccuntingInterface/AccountingInterfaceModal";
import LoanRenewalTable from "../../components/CommonComponents/RenewalTable/LoanRenewalTable";
import { getStageforModules } from "../../services/module.service";
import {
  CANCELLATION_REVIEW,
  IN_APPROVAL,
  IN_REVIEW,
} from "../../constants/status";
import CreditReloadDetails from "../../components/CommonComponents/CreditReload/CreditReloadDetails";
import { ViewFeeDetailsWithGST } from "../../components/FeePopover/FeeCalculationPopover";
import useSystemConfigStore from "../../store/systemConfigStore";
import { getCustomerBankById } from "../../services/customer.service";
import ReloadFeePopover from "../../components/FeePopover/ReloadFeePopover";

const PaymentReview = ({ action = true, home = "/payment" }) => {
  const { paymentId, createdPaymentId, loansId } = useParams();
  const systemDate = useSystemConfigStore((store) => store.config);
  const [open, setOpen] = useState(false);
  const [remarksTitle, setRemarksTitle] = useState();
  const [status, setStatus] = useState();
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [prospectcodebody, setProspectcodebody] = useState(null);
  const [label, setLabel] = useState();
  const [remarksLoading, setRemarksLoading] = useState();
  const [sucessfulmodal, setSucessfulmodal] = useState(false);
  const [interfaceOpen, setInterfaceOpen] = useState(false);

  const { data: disbData = [], isLoading: loading } = useQuery(
    ["disb-byid", loansId],
    () => loansId && getApprovedDisbById(loansId),
    {
      onSuccess: (data) => {
        setProspectcodebody({
          id: data?.deduction_loan_ids?.length
            ? data?.deduction_loan_ids
            : null,
        });
      },
      refetchOnWindowFocus: false,
    }
  );

  const { data: ProspectCode = [] } = useQuery(
    ["prospectcode", prospectcodebody],
    () =>
      prospectcodebody &&
      getDeductionLoansbyID(disbData?.loan_id, prospectcodebody),
    { refetchOnWindowFocus: false }
  );

  const { data: paymentDetails = [] } = useQuery(
    ["payment-id", createdPaymentId],
    () => createdPaymentId && getPaymentById(createdPaymentId),
    { refetchOnWindowFocus: false }
  );

  const { data: stages = [] } = useQuery(
    ["payment-stages"],
    () => getStageforModules("payment"),
    {
      enabled: Boolean(paymentDetails?.status_value),
      select: (dataItem) => {
        return {
          current_stage: paymentDetails?.status_value,
          next_stage:
            dataItem[dataItem.indexOf(paymentDetails?.status_value) + 1],
        };
      },
      refetchOnWindowFocus: false,
    }
  );

  const { data: reviewTrail = [] } = useQuery(
    ["remarks", createdPaymentId],
    () => getAllRemarksPayment(createdPaymentId),
    { refetchOnWindowFocus: false }
  );

  const { data: targetBank = {} } = useQuery(
    ["customer-bank", disbData?.customer_id, disbData?.target_bank_id],
    () => getCustomerBankById({ customerId: disbData?.customer_id, bankId: disbData?.target_bank_id }),
    {
      enabled: Boolean(disbData?.target_bank_id),
      select: (data) => {
        return data[0]
      },
    }
  );

  const { data: sourceBank = [], isLoading: sourceBankLoading } = useQuery(
    ["company-bank", disbData?.source_bank_id],
    () => CompanyBankDetails(disbData?.source_bank_id),
    {
      enabled: Boolean(disbData?.source_bank_id),
      refetchOnWindowFocus: false,
    }
  );

  const onSendPaymentForNextStage = (remarks) => {
    const body = {
      remarks: remarks,
    };
    setRemarksLoading(true);
    sendPaymentforNextStage(createdPaymentId, body)
      .then((res) => {
        setSucessfulmodal({
          open: true,
          title:
            status == IN_REVIEW
              ? STAGE_CHANGE_TEXT?.approval
              : status == IN_APPROVAL
                ? STAGE_CHANGE_TEXT?.approved
                : status == CANCELLATION_REVIEW
                  ? STAGE_CHANGE_TEXT?.reviewCancel
                  : STAGE_CHANGE_TEXT?.approveCancel,
        });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      });
  };

  const handlePushback = (remarks) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true);
    SendForPushbackPayment(createdPaymentId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.pushback });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      });
  };

  const handleReject = (remarks) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true);
    SendForRejectPayment(createdPaymentId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.reject });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      });
  };

  const handleCancel = (remarks, reason) => {
    const body = {
      remarks,
      reason: reason?.reason,
    };
    setRemarksLoading(true);
    sendPaymentforCancellation(createdPaymentId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.cancel });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      });
  };

  const callFunction = (remarks, status, reason) => {
    switch (status) {
      case "approval":
      case "review":
      case "cancellation":
        onSendPaymentForNextStage(remarks, status);
        break;
      case "reject":
        handleReject(remarks);
        break;
      case "pushback":
        handlePushback(remarks);
        break;
      case "cancel":
        handleCancel(remarks, reason);
        break;
      default:
        displayNotification({
          message: "Something went wrong, Please contact support!",
          variant: 'error',
        });
        break;
    }
  };

  return (
    <>
      <Body background={false}>
        <PageTitle
          title={disbData?.customer_name}
          id={disbData?.customer_id}
          action={
            disbData?.crr_id ? (
              /** display the credit reload request */
              <CreditReloadDetails selectedRowId={disbData?.crr_id} type={disbData?.additional_deduction?.find(i => i?.fee_type === 'express_reload_fee') ? 'express' : null} />
            ) : null
          }
        />
      </Body>
      <DashBoardCustomerDetails
        id={paymentId}
        loansId={disbData?.loan_id}
        codeName={"Payment Code"}
        code={paymentDetails?.code}
      />
      <Box sx={{ position: "relative" }}>
        <Body>
          {paymentDetails?.prospect_code && (
            <div style={ImdStyle.flag}>
              <IdBadge
                remarks={paymentDetails?.status_value}
                label={paymentDetails?.prospect_code}
              />
            </div>
          )}
          <Grid gutter="lg">
            <Grid.Col span={4} sx={{ display: "flex", alignItems: "center" }}>
              <Grid.Col
                span={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  src={GENERAL_IMG}
                  alt="Illustration"
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={4}>
              <DataViewerWapper p={30}>
                {disbData?.loan_type == "revolving" ? (
                  <Grid gutter="md" pb={"lg"}>
                    <Grid.Col span={12}>
                      <ViewFormData
                        label="Loan Type"
                        value={"Revolving"}
                        loading={loading}
                      />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <ViewFormData
                        label="Product"
                        value={disbData?.product_name}
                        loading={loading}
                      />
                    </Grid.Col>
                    <Grid.Col span={12} mt="xs">
                      <TitleViewer title="Revolving" />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <ViewFormData
                        label="Tranche Amount"
                        value={<Nums withCopy value={disbData?.loan_amount} />}
                        loading={loading}
                      />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <ViewFormData
                        label="Disb Date"
                        value={dateFormat(disbData?.disbursed_date)}
                        loading={loading}
                      />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <ViewFormData
                        label="Due Date"
                        value={dateFormat(disbData?.due_date)}
                        loading={loading}
                      />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <ViewFormData
                        label={`Interest`}
                        secondaryLabel={disbData?.interest_rate}
                        value={<Nums value={disbData?.interest_amount} />}
                        loading={loading}
                      />
                    </Grid.Col>
                    {/* If spread rate percentage is avalible then it will display */}
                    {disbData?.spread_rate ? (
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={`Spread`}
                          secondaryLabel={disbData?.spread_rate}
                          value={<Nums value={disbData?.spread_amount} />}
                          loading={loading}
                        />
                      </Grid.Col>
                    ) : null}
                    {/* If addon spread rate percentage is avalible then it will display */}
                    {disbData?.addon_spread_rate ? (
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={`Addon Spread`}
                          secondaryLabel={disbData?.addon_spread_rate}
                          value={<Nums value={disbData?.addon_spread_amount} />}
                          loading={loading}
                        />
                      </Grid.Col>
                    ) : null}
                    <Grid.Col span={12}>
                      <ViewFormData
                        label="Due Amount"
                        value={<Nums withCopy value={disbData?.due_amount} />}
                        loading={loading}
                      />
                    </Grid.Col>
                    {disbData?.advance_interest ? (
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={`Advance Interest`}
                          value={
                            <Nums withCopy value={disbData?.advance_interest} />
                          }
                          loading={loading}
                        />
                      </Grid.Col>
                    ) : null}
                    {disbData?.renewal_fee?.length ? (
                      <Grid.Col span={12}>
                        <ViewFormData
                          label="Renewal Fee"
                          value={
                            <Nums
                              withCopy
                              value={disbData?.renewal_fee[0]?.total_amount}
                            />
                          }
                          loading={loading}
                          action={
                            <ViewFeeDetailsWithGST
                              calculatedFee={disbData?.renewal_fee[0]}
                            />
                          }
                        />
                      </Grid.Col>
                    ) : null}
                    {disbData?.additional_deduction?.length ? (
                      <>
                        <Grid.Col span={12} mt="xs">
                          <TitleViewer title="Deduction" />
                        </Grid.Col>
                        {disbData?.additional_deduction?.map((item) => (
                          <Grid.Col span={12}>
                            <ViewFormData
                              label={item?.fee_name}
                              value={
                                <Nums withCopy value={item?.total_amount} />
                              }
                              action={
                                item?.fee_type == "fee" ? (
                                  <ViewFeeDetailsWithGST calculatedFee={item} />
                                ) : (
                                  item?.fee_type === 'express_reload_fee' ? <ReloadFeePopover value={item} /> : null
                                )
                              }
                            />
                          </Grid.Col>
                        ))}
                      </>
                    ) : null}
                    <Grid.Col span={12}>
                      <ViewFormDataContained
                        label={"Net Disb Amount"}
                        value={
                          <Nums withCopy value={disbData?.disbursed_amount} />
                        }
                        loading={loading}
                      />
                    </Grid.Col>
                  </Grid>
                )
                  : (
                    <>
                      <Grid gutter="md" pb={"lg"}>
                        <Grid.Col span={12}>
                          <ViewFormData
                            label="Loan Type"
                            value={disbData?.loan_type === 'new' ? "New" : disbData?.loan_type === "transfer" ? "Transfer" : "Enhancement"}
                            loading={loading}
                          />
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <ViewFormData
                            label="Product"
                            value={disbData?.product_name}
                            loading={loading}
                          />
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <TitleViewer title="Cash Flow (OTP Loan)" />
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <ViewFormData
                            label="Disb Date"
                            value={dateFormat(disbData?.disbursed_date)}
                            loading={loading}
                          />
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <ViewFormData
                            label="Due Date"
                            value={dateFormat(disbData?.due_date)}
                            loading={loading}
                          />
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <ViewFormData
                            label="Tranche Amount"
                            value={
                              <Nums withCopy value={disbData?.loan_amount} />
                            }
                            loading={loading}
                          />
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <ViewFormData
                            label={`Interest`}
                            secondaryLabel={disbData?.interest_rate}
                            value={<Nums value={disbData?.interest_amount} />}
                            loading={loading}
                          />
                        </Grid.Col>
                        {/* If spread rate percentage is avalible then it will display */}
                        {disbData?.spread_rate ? (
                          <Grid.Col span={12}>
                            <ViewFormData
                              label={`Spread`}
                              secondaryLabel={disbData?.spread_rate}
                              value={<Nums value={disbData?.spread_amount} />}
                              loading={loading}
                            />
                          </Grid.Col>
                        ) : null}
                        {/* If addon spread rate percentage is avalible then it will display */}
                        {disbData?.addon_spread_rate ? (
                          <Grid.Col span={12}>
                            <ViewFormData
                              label={`Addon Spread`}
                              secondaryLabel={disbData?.addon_spread_rate}
                              value={
                                <Nums value={disbData?.addon_spread_amount} />
                              }
                              loading={loading}
                            />
                          </Grid.Col>
                        ) : null}
                        <Grid.Col span={12}>
                          <ViewFormData
                            label="Due Amount"
                            value={<Nums withCopy value={disbData?.due_amount} />}
                            loading={loading}
                          />
                        </Grid.Col>
                        {disbData?.advance_interest ? (
                          <Grid.Col span={12}>
                            <ViewFormData
                              label={`Advance Interest`}
                              value={
                                <Nums
                                  withCopy
                                  value={disbData?.advance_interest}
                                />
                              }
                              loading={loading}
                            />
                          </Grid.Col>
                        ) : null}
                        {disbData?.additional_deduction?.length ? (
                          <>
                            <Grid.Col span={12}>
                              <TitleViewer title="Deduction" />
                            </Grid.Col>
                            {disbData?.additional_deduction?.map((item) => (
                              <Grid.Col span={12}>
                                <ViewFormData
                                  label={item?.fee_name}
                                  value={
                                    <Nums withCopy value={item?.total_amount} />
                                  }
                                  action={
                                    item?.fee_type == "fee" ? (
                                      <ViewFeeDetailsWithGST
                                        calculatedFee={item}
                                      />
                                    ) : (
                                      item?.fee_type === 'express_reload_fee' ? <ReloadFeePopover value={item} /> : null
                                    )
                                  }
                                />
                              </Grid.Col>
                            ))}
                          </>
                        ) : null}
                        <Grid.Col span={12}>
                          <ViewFormDataContained
                            label={"Net Disb Amount"}
                            value={
                              <Nums withCopy value={disbData?.disbursed_amount} />
                            }
                            loading={loading}
                          />
                        </Grid.Col>
                      </Grid>
                    </>
                  )}
              </DataViewerWapper>
            </Grid.Col>
            <Grid.Col span={4}>
              <DataViewerWapper p={20}>
                <TitleViewer title="Bank Details of the Petromoney" />
                <Grid gutter="md" pt={"sm"}>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label="Bank Name"
                      value={sourceBank[0]?.bank_name}
                      loading={sourceBankLoading}
                    />
                  </Grid.Col>
                  <Grid.Col span={12} pb={"lg"}>
                    <ViewFormData
                      label="Account No"
                      value={sourceBank[0]?.account_number}
                      loading={sourceBankLoading}
                    />
                  </Grid.Col>
                </Grid>
                {targetBank && (
                  <>
                    <TitleViewer title="Bank Details of the customer" />
                    <Grid style={{ paddingTop: "10px" }}>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label="Bank Name"
                          value={targetBank?.bank_name}
                          loading={sourceBankLoading}
                        />
                      </Grid.Col>
                      <Grid.Col span={12} pb={"lg"}>
                        <ViewFormData
                          label="Account No"
                          value={targetBank?.account_number}
                          loading={sourceBankLoading}
                        />
                      </Grid.Col>
                    </Grid>
                  </>
                )}
                <TitleViewer title="Payment Reference" />
                <Grid pt={"sm"}>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label="Instrument Type"
                      value={paymentDetails?.instrument_type?.toUpperCase()}
                      loading={loading}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label="Instrument Date"
                      value={dateFormat(
                        paymentDetails?.status_value != "approved"
                          ? systemDate?.current_system_date
                          : paymentDetails?.instrument_date
                      )}
                      loading={loading}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label="Payment Reference No"
                      value={paymentDetails?.payment_reference_number}
                      loading={loading}
                    />
                  </Grid.Col>
                  {paymentDetails?.payment_reference_attachment_url ? (
                    <Grid.Col span={12} pt={"md"}>
                      <Grid>
                        <Grid.Col span={5}>
                          <TitleComp label={"Reference Document"} />
                        </Grid.Col>
                        <Grid.Col span={4}>
                          <AvatarCard
                            file={
                              paymentDetails?.payment_reference_attachment_url
                            }
                            fileName={'Payment Reference file'}
                            creation={false}
                          />
                        </Grid.Col>
                      </Grid>
                    </Grid.Col>
                  ) : null}
                </Grid>
              </DataViewerWapper>
            </Grid.Col>
            {disbData?.deduction_loan_payload?.selection?.length &&
              ProspectCode?.length ? (
              /** used to displey the table if it is available */
              <Grid.Col span={12}>
                <LoanRenewalTable
                  renewalLoans={ProspectCode}
                  loanDetails={disbData}
                />
              </Grid.Col>
            ) : null}
          </Grid>
          {/** This Action buttons component is used to display the buttons in the flow */}
          <ActionButtons
            status={stages?.current_stage}
            nextStage={stages?.next_stage}
            isReady={Boolean(stages?.next_stage)}
            remarks={reviewTrail}
            accountingCB={() => setInterfaceOpen(true)}
            sendForApprovalCB={() => {
              setOpen(true);
              setRemarksTitle("Approval Remarks");
              setStatus("review");
              setLabel("Send for Approval");
            }}
            approvedCB={() => {
              setOpen(true);
              setRemarksTitle("Approval Remarks");
              setStatus("approval");
              setLabel("Approve");
            }}
            pushbackCB={() => {
              setOpen(true);
              setRemarksTitle("Pushback Remarks");
              setStatus("pushback");
              setLabel("Pushback");
            }}
            rejectCB={() => {
              setOpen(true);
              setRemarksTitle("Reject Remarks");
              setStatus("reject");
              setLabel("Reject");
            }}
            cancelCB={() => {
              setOpen(true);
              setRemarksTitle("Cancellation Remarks");
              setStatus("cancellation");
              setLabel("Send for Cancellation");
            }}
            approveCancelCB={() => {
              setOpen(true);
              setRemarksTitle("Are you sure?");
              setStatus("cancel");
              setLabel("Confirm");
            }}
            remarksCB={() => setRemarksOpen(true)}
            currentStatus={paymentDetails?.status_value}
            moduleApplicationId={paymentDetails?.id}
            moduleName={'payment'}
          />

          {/** used to display the modal for accounting data */}
          <AccountingInterfaceModal
            open={interfaceOpen}
            close={() => setInterfaceOpen(false)}
            id={createdPaymentId}
            name={"payment"}
            code={paymentDetails?.code}
          />

          <Remarks
            open={open}
            setOpen={setOpen}
            type={stages?.next_stage}
            callback={callFunction}
            remarksTitle={remarksTitle}
            moduleName={"Payment"}
            check={status}
            label={label}
            loading={remarksLoading}
            currentStatus={paymentDetails?.status_value}
            moduleApplicationId={paymentDetails?.id}
          />

          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={paymentDetails?.code}
            title={sucessfulmodal?.title}
            href={home}
            hreftext={"Back to list table"}
          />
        </Body>

        <ViewRemark
          open={remarksOpen}
          onClose={setRemarksOpen}
          remarkTrail={reviewTrail}
        />
      </Box>
    </>
  );
};

export default PaymentReview;
