const ImdStyle = {
  flag: {
    marginBottom: 1,
    position: 'absolute',
    marginLeft: '-24px',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  white: { color: 'white' },
  flexEnd: { display: 'flex', justifyContent: 'flex-end' },
  viewPaper: {
    border: '1px solid #D0E6FF',
    backgroundColor: 'rgba(208, 230, 255, 0.3)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dashedTitle: {
    borderBottom: '2px dashed rgb(0,0,0,0.2)',
    paddingBottom: 8,
  },
  spaceBetween: { display: 'flex', justifyContent: 'space-between' },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableOverFlow: {
    overflowX: 'auto', 
    whiteSpace: 'nowrap', 
    display: 'block'
  }
};

export default ImdStyle;
