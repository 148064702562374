import { ExclamationMark } from 'tabler-icons-react';
import { apiCallProtected } from './axios';
import useAuthStore from '../store/authStore';
import { displayNotification } from '../components/CommonComponents/Notification/displayNotification';
import { notifications } from "@mantine/notifications";


const responseInterceptor = () => apiCallProtected.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      const data = response?.data
      return data
    } else if (response.status === 204) {
      const data = { status: 'success' }
      return data
    } else {
      return response
    }
  },
  async (error) => {
    const { auth: authObj, resetAuth: logout } = useAuthStore?.getState();
    /*
      User will be logged out once the access token expires and while the eod get's started
    */
    if (
      (error?.response?.status === 401 || error?.response?.status === 412) &&
      authObj?.access_token &&
      authObj?.group_id
    ) {
      notifications.clean();
      displayNotification({
        id: 'lms-session-out',
        title: 'Session Expired!',
        variant: 'error',
        icon: <ExclamationMark />,
        autoClose: 2000,
        message: error?.response?.status === 412 ? 'EOD is currently running. Please try again after some time.' : 'Your session got expired! Please Login again to continue.'
      })
      logout();
    }
    /* 
      Response data from error
    */
    if(typeof(error?.response?.data) == 'undefined' || error?.status?.toLowerCase() === 'error') {
      const data = error?.data || {};
      return Promise.reject({ message: error?.message || data?.message || 'Seems server is busy. Please try again after sometime!' })
    } else {
      return Promise.reject(error?.response?.data)
    }
  }
)

export default responseInterceptor;