

export const ReportFilterKey = {
  accountingInterfaceHeader: 'accounting_interface',
  renewalMaping: 'renewal_mapping',
  due_over_due: 'due_overdue',
  over_due: 'overdue',
  due: 'due',
  dpd: 'dpd',
  disbursement: 'disbursement',
  detailed_receipt: 'detailed_receipt',
  receipt: 'receipt',
  partywise_loan_book: 'partywise_loan_book',
  mifin_disbursal: 'mifin_disbursal',
  mifin_receipt: 'mifin_receipt',
  mifin_accounting_detail: 'mifin_accounting_detail',
  cibil_borrower: 'cibil_borrower',
  cibil_consumer: 'cibil_consumer',
  receipt_excess: 'receipt_excess',
}