import { Autocomplete, Text, Grid, Button, Box } from "@mantine/core";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Select from "react-select";
import { getAllProductForFilter } from "../../services/common.service";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import { customerSearch } from "../../services/imd.service";
import { DatePickerInput } from "@mantine/dates";
import useSystemConfigStore from "../../store/systemConfigStore";

const styles = {
  select: {
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    option: (provided) => ({
      ...provided,
      fontSize: "13px",
      padding: "5px 15px 5px 15px",
    }),
    control: (provided) => ({ ...provided, fontSize: "13px" }),
  },
};

const CancellationFilter = ({
  onClose,
  loading,
  setSearchData,
  searchedData,
}) => {
  const systemDate = useSystemConfigStore(store => store.config);
  const [data, setData] = useState(searchedData);
  const [searchedValue, setSearchedValue] = useState("");
  const [selectedValue, setSelectedValue] = useState({});

  const searchedResult = useQuery(
    ["search-data", searchedValue],
    /*
     * sending the signal to cancel the per pending api call
     * signal will contain the abort signal
     */
    ({ signal }) => customerSearch(searchedValue, signal),
    {
      /** this api call will call when the searched value length is min of 2 */
      enabled: Boolean(searchedValue?.length > 2),
      refetchOnWindowFocus: false,
    }
  );

  /** contains all modules name for filter */
  const modules = [
    { value: "fee", label: "Fee Invoice" },
    { value: "tranche", label: "Tranches" },
    { value: "disb", label: "Disb Memo" },
    { value: "payment", label: "Payments" },
    { value: "invoice", label: "Invoice" },
    { value: "receipt", label: "Receipts" },
    { value: "waiver", label: "Waiver" },
  ];

  /** get all product to list in filter */
  const { data: products = [] } = useQuery(
    ["all-products"],
    () => getAllProductForFilter(),
    { refetchOnWindowFocus: false }
  );

  /** handling the filter */
  const onSubmit = () => {
    const body = {
      ...data,
      customer_id: selectedValue?.id ? parseInt(selectedValue?.id) : undefined,
      from_date: dateFormat(data?.from_date, "YYYY-MM-DD"),
      end_date: dateFormat(data?.end_date, "YYYY-MM-DD"),
    };
    setSearchData(body);
    onClose();
  };

  /** handling the clear filter */
  const handleClearFilter = () => {
    setSearchData();
    onClose();
  };

  return (
    <Box>
      <Box>
        <Grid gutter="xl" mt="sm" pt={"xs"}>
          <Grid.Col span={12} pt={"0"}>
            <Grid.Col pb={"0"}>
              <Text size={"xs"}>Customer Id/Name</Text>
            </Grid.Col>
            <Grid.Col pt={"0"}>
              <Autocomplete
                value={searchedValue}
                onChange={(value) => {
                  setSearchedValue(value);
                }}
                /** this prop will trigger when the user select the option in drop down */
                onItemSubmit={(value) => {
                  setSelectedValue(value);
                }}
                data={searchedResult?.data || []}
                placeholder="Search by customer Id / Name"
                filter={(value, item) =>
                  item.value.includes(value.trim()) ||
                  item.label.toLowerCase().includes(value.toLowerCase().trim())
                }
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={6} pt={"0"}>
            <Grid.Col pb={"0"}>
              <Text size={"xs"}>Select Product</Text>
            </Grid.Col>
            <Grid.Col pt={"0"}>
              <Select
                placeholder="select"
                options={products}
                onChange={(value) =>
                  setData({ ...data, product_id: value?.value })
                }
                defaultValue={
                  data?.product_id
                    ? products?.filter(
                        (item) => item?.value == data?.product_id
                      )[0]
                    : null
                }
                styles={styles.select}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={6} pt={"0"}>
            <Grid.Col pb={"0"}>
              <Text size={"xs"}>Select Module</Text>
            </Grid.Col>
            <Grid.Col pt={"0"}>
              <Select
                placeholder="select"
                options={modules}
                onChange={(value) => {
                  console.log(value);
                  setData({ ...data, module_name: value?.value });
                }}
                defaultValue={
                  data?.module_name
                    ? modules?.filter(
                        (item) => item?.value == data?.module_name
                      )[0]
                    : null
                }
                styles={styles.select}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={6} pt={"0"}>
            <Grid.Col pb={"0"}>
              <Text size={"xs"}>From Date</Text>
            </Grid.Col>
            <Grid.Col pt={"0"}>
              <DatePickerInput
                placeholder="select Date"
                size={"sm"}
                valueFormat='DD/MM/YYYY'
                onChange={(value) => setData({ ...data, from_date: value })}
                value={data?.from_date ? new Date(data?.from_date) : null}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={6} pt={"0"}>
            <Grid.Col pb={"0"}>
              <Text size={"xs"}>To Date</Text>
            </Grid.Col>
            <Grid.Col pt={"0"}>
              <DatePickerInput
                size="sm"
                valueFormat='DD/MM/YYYY'
                onChange={(value) => setData({ ...data, end_date: value })}
                maxDate={systemDate?.current_system_date}
                value={
                  data?.end_date
                    ? new Date(data?.end_date)
                    : new Date(systemDate?.current_system_date)
                }
              />
            </Grid.Col>
          </Grid.Col>
        </Grid>
      </Box>
      <Box>
        <Box sx={{ display: "flex", padding: "20px 10px" }}>
          <Grid>
            <Grid.Col span={4}>
              <Button onClick={onSubmit} loading={loading}>
                Search
              </Button>
            </Grid.Col>
            <Grid.Col span={4}>
              <Button variant="light" onClick={handleClearFilter}>
                Clear
              </Button>
            </Grid.Col>
            <Grid.Col span={4}>
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
            </Grid.Col>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default CancellationFilter;
