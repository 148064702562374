import { Grid, Switch, Text, TextInput, Title } from '@mantine/core';
import React from 'react';
import { Controller } from 'react-hook-form';

export const textinputstyles = {
  filled: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#0063FF',
  },
  main: {
    display: 'flex',
    alignItems: 'center',
  },
  filledtext: {
    color: '#FFFFFF',
  },
  normal: {
    minHeight: '57px',
    padding: '10px 8px 0 8px',
  },
  filledinput: {
    minHeight: '53px',
    padding: '8px 8px 0 8px',
  },
  outline: {
    border: '1px solid #0063FF',
  },
  outlinetext: {
    minHeight: '57px',
    padding: '17px 8px 0 8px',
  },
  outlineinput: {
    minHeight: '57px',
    padding: '10px 8px 0 8px',
  },
  maininput: {
    minHeight: '57px',
    padding: '0 8px 0 8px',
  },
};

const ControllerSwitch = ({
  name,
  label,
  placeholder,
  control,
  errors,
  subText,
  displayLabel = true,
  spanSize = 7,
  required = false,
  variant = 'none',
  ...props
}) => {
  return (
    <Grid
      sx={
        variant == 'filled'
          ? textinputstyles.filled
          : variant == 'outline'
          ? textinputstyles.outline
          : textinputstyles.main
      }
    >
    {/* displayLabel used to display lable if true, By defalut it is true */}
      { displayLabel &&
        <Grid.Col
          span={5}
          sx={
            variant == 'filled'
              ? textinputstyles.filledtext
              : variant == 'outline'
              ? textinputstyles.outlinetext
              : textinputstyles.normal
          }
        >
        <Title style={{ fontSize: '14px', display: 'flex' }} order={4}>
          {label}
          {required ? <Text ml={'4px'} color="red">*</Text> : null}
        </Title>
        <Text sx={{ color: 'rgb(0,0,0,0.3)', fontSize: '12px' }}>{subText}</Text>
      </Grid.Col>
      }
      <Grid.Col
        span={spanSize}
        sx={
          variant == 'filled'
            ? textinputstyles.filledinput
            : variant == 'outline'
            ? textinputstyles.outlineinput
            : textinputstyles.maininput
        }
      >
        <Controller
          name={name}
          control={control}
          render={({ field: { value, ...rest } }) => (
            <Switch
              id={name}
              checked={value}
              {...rest}
              {...props}
            />
          )}
        />
        {errors && (
          <Text size={'xs'} sx={{ color: '#f13e3e' }}>
            {errors}
          </Text>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default ControllerSwitch;
