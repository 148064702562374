import { SegmentedControl, Button, Popover } from "@mantine/core";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Body from "../../components/Body/Body";
import { StatusToken } from "../../components/CommonComponents/Preview/FilePreview";
import DataTableWrapper from "../../components/CommonComponents/Table/DataTableWrapper";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import { useQuery } from "@tanstack/react-query";
import {
  STATUS_COLORS,
  CANCELLATION_STATUS_VALUE,
} from "../../utils/StatusTable";
import {
  CANCELLATION_APPROVAL,
  CANCELLATION_REVIEW,
  APPROVED,
  REJECTED,
} from "../../constants/status";
import CancellationFilter from "./CancellationFilter";
import { Plus } from "tabler-icons-react";
import { getAllCancellationData } from "../../services/module.service";

const CancellationTable = () => {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper();
  const [filterOpen, setFilterOpen] = useState(false);
  const [status, setStatus] = useState("active");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState({});
  const [records, setRecords] = useState({ label: "Show 10", value: 10 });

  /** used to change the page to 1 while the search us used
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1);
  }, [search, status]);

  const { data: cancellationData = [], isLoading } = useQuery(
    ["all-cancellation", status, page, search, records],
    () =>
      getAllCancellationData({
        status,
        page,
        records: records?.value,
        ...search,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onClickAction = (i) => {
    /** url will contain the module name and loanId will contain the id
     * this props is used to navigate to the module 
     */
    const handleNavigate = (url, loanId = "") => {
      if (
        i?.status == CANCELLATION_APPROVAL ||
        i?.status == CANCELLATION_REVIEW
      ) {
        let query =
          `/cancellation/${url}-cancel/${i?.module_id}/${i?.customer_id}` +
          loanId;
        navigate(query);
      } else if (i?.status == APPROVED) {
        let query =
          `/cancellation/${url}-cancelled/${i?.module_id}/${i?.customer_id}` +
          loanId;
        navigate(query);
      } else if (i?.status == REJECTED) {
        let query =
          `/cancellation/${url}-rejected/${i?.module_id}/${i?.customer_id}` +
          loanId;
        navigate(query);
      }
    };
    if (i?.module_name == "Fee") {
      handleNavigate("fee");
    } else if (i?.module_name == "Tranche") {
      handleNavigate("tranche");
    } else if (i?.module_name == "Disbursement") {
      handleNavigate("disb");
    } else if (i?.module_name == "Payment") {
      handleNavigate("payment", `/${i?.disbursement_id}`);
    } else if (i?.module_name == "Invoice") {
      handleNavigate("invoice");
    } else if (i?.module_name == "Receipt") {
      handleNavigate("receipt");
    } else if (i?.module_name == "Waiver") {
      handleNavigate("waiver");
    } else if (i?.module_name == "Unidentified_receipt") {
      handleNavigate("unidentified_receipt");
    } else if (i?.module_name == "Customer Level Receipt") {
      handleNavigate("customer-level-receipt");
    } else if (i?.module_name == "Refund") {
      handleNavigate("refund");
    }
  };

  const linkText = (value) => {
    return (
      <span
        style={{ color: "#0063FF", cursor: "pointer" }}
        onClick={() => onClickAction(value.row.original)}
      >
        {value.getValue()}
      </span>
    );
  };

  const columnData = [
    columnHelper.accessor("module_code", {
      header: "Code",
      cell: (value) => linkText(value),
    }),
    columnHelper.accessor("customer_name", {
      header: "Customer Name",
      cell: (value) => linkText(value),
    }),
    columnHelper.accessor("customer_id", {
      header: "Customer Code",
    }),
    columnHelper.accessor("product_name", {
      header: "Scheme",
    }),
    columnHelper.accessor("module_name", {
      header: "Module Name",
    }),
    columnHelper.accessor("assignment_date", {
      header: "Created Date",
      cell: (value) => dateFormat(value.getValue()),
    }),
    columnHelper.accessor("maker_name", {
      header: "Created By",
    }),
    columnHelper.accessor("status", {
      header: "Cancellation Status",
      cell: (value) => (
        <StatusToken
          label={CANCELLATION_STATUS_VALUE?.[value.row.original?.status]}
          color={STATUS_COLORS?.[value.row.original?.status]}
        />
      ),
    }),
  ];

  /** filter option */
  const tableAction = (
    <Popover
      position="bottom"
      shadow="md"
      opened={filterOpen}
      onChange={setFilterOpen}
      closeOnClickOutside={false}
    >
      <Popover.Target>
        <Button
          variant="filled"
          color="dark"
          leftIcon={<Plus size={18} />}
          sx={{ marginLeft: 12 }}
          size="sm"
          onClick={() => setFilterOpen(true)}
        >
          Filter
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <CancellationFilter
          onClose={() => setFilterOpen(!filterOpen)}
          searchedData={search}
          setSearchData={setSearch}
        />
      </Popover.Dropdown>
    </Popover>
  );

  return (
    <Body background={false}>
      <DataTableWrapper
        title="Cancellation Application"
        rowData={cancellationData?.data}
        columnData={columnData}
        noDataText="No Cancellation Application yet!"
        noDataSubText="This section will contain list of cancelled application details."
        useApiPagination
        totalNoOfPages={cancellationData?.meta_data?.total_number_of_pages}
        totalNoOfRecords={cancellationData?.meta_data?.total_number_of_records}
        page={page}
        setPage={setPage}
        rowLength={records}
        setRowLength={setRecords}
        filter={false}
        apiSearch={setSearch}
        loading={isLoading}
        /** used to have tab in cancellation table to filter active and closed cancellation details list */
        options={
          <SegmentedControl
            value={status}
            onChange={setStatus}
            mr={"md"}
            styles={(theme) => ({
              root: {
                background: "white",
                height: "36px",
                padding: "3px",
              },
            })}
            data={[
              { label: "Active", value: "active" },
              { label: "Closed", value: "closed" },
            ]}
            color={status == "closed" ? "red" : "green"}
          />
        }
        actions={tableAction}
      />
    </Body>
  );
};

export default CancellationTable;
