import { Grid, SegmentedControl, Text, Title } from '@mantine/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import TitleComp from '../TextComponent/TitleComp';

const textinputstyles = {
  filled: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#0063FF',
  },
  main: {
    display: 'flex',
    alignItems: 'center',
  },
  filledtext: {
    color: '#FFFFFF',
  },
  normal: {
    minHeight: '57px',
    padding: '10px 8px 0 8px',
  },
  filledinput: {
    minHeight: '53px',
    padding: '0 8px 0 8px',
  },
  maininput: {
    minHeight: '57px',
    padding: '0 8px 0 8px',
  },
};

const ControllerSegmentedControl = ({
  name,
  label,
  control,
  errors,
  displayLabel = true,
  required = false,
  spanSize = 7,
  subText,
  color='blue',
  ...props
}) => {
  return (
    <Grid sx={textinputstyles.main}>
    {/* displayLabel used to display lable if true, By defalut it is true */}
      { displayLabel &&
        <Grid.Col span={5} sx={textinputstyles.normal}>
          <div style={{display: 'flex'}}>
            <TitleComp label={label} />
            {required ? <Text ml={'4px'} color="red">*</Text> : null}
          </div>
          <Text sx={{ color: 'rgb(0,0,0,0.3)', fontSize: '12px' }}>{subText}</Text>
        </Grid.Col>
      }
      <Grid.Col span={spanSize} sx={textinputstyles.maininput}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <SegmentedControl
              name={name}
              color={color}
              transitionDuration={300}
              {...field}
              {...props}
            />
          )}
        />
        {/* {errors && (
          <Text size={'xs'} sx={{ color: '#f13e3e' }}>
            {errors}
          </Text>
        )} */}
      </Grid.Col>
    </Grid>
  );
};

export default ControllerSegmentedControl;
