import { ActionIcon, Grid, Popover, Text } from "@mantine/core";
import React from "react";
import { AlertCircle } from "tabler-icons-react";
import { COLORS } from "../../constants";
import TitleComp from "../CommonComponents/TextComponent/TitleComp";
import Nums from "../Body/Nums";

// used to display the fee data with key and label
const DisplayFeeData = ({ value, label, type = "currency" }) => {
  return (
    <Grid gutter="md">
      <Grid.Col span={8}>
        <TitleComp label={label} />
      </Grid.Col>
      <Grid.Col span={4}>
        <Text size="sm" weight={600} sx={{ color: COLORS.primary.dark }}>
          {type === "currency" ? (
            <Nums value={value} />
          ) : (
            value
          )}
        </Text>
      </Grid.Col>
    </Grid>
  );
};

// used to display the fee data with key and label in contained mode
const DisplayContainedFeeData = ({ value, label }) => {
  return (
    <Grid
      gutter="md"
      sx={{ backgroundColor: COLORS.primary.main, color: COLORS.white }}
    >
      <Grid.Col span={8}>
        <TitleComp label={label} />
      </Grid.Col>
      <Grid.Col span={4}>
        <Text size="sm" weight={600}>
          <Nums value={value} />
        </Text>
      </Grid.Col>
    </Grid>
  );
};

const ReloadFeePopover = ({ value }) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  return (
    <Popover
      position="top"
      shadow="lg"
      width={340}
      styles={() => ({
        dropdown: {
          marginLeft: "-130px",
        },
      })}
      opened={popoverOpen}
      onChange={setPopoverOpen}
      closeOnClickOutside
    >
      <Popover.Target>
        <ActionIcon onClick={() => setPopoverOpen(!popoverOpen)}>
          <AlertCircle size={"20px"} color={"#ff6666"} />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown sx={{ backgroundColor: COLORS.primary.light1 }}>
        <Grid gutter="md">
          <Grid.Col span={12}>
            <DisplayFeeData
              label={"Fee Amount"}
              value={value?.fee_value}
            />
          </Grid.Col>
          {value?.igst ? (
            <Grid.Col span={12}>
              <DisplayFeeData label={"IGST"} value={value?.igst} />
            </Grid.Col>
          ) : null}
          {value?.sgst ? (
            <Grid.Col span={12}>
              <DisplayFeeData label={"SGST"} value={value?.sgst} />
            </Grid.Col>
          ) : null}
          {value?.cgst ? (
            <Grid.Col span={12}>
              <DisplayFeeData label={"CGST"} value={value?.cgst} />
            </Grid.Col>
          ) : null}
          <Grid.Col span={12}>
            <DisplayContainedFeeData
              label={"Total Processing Fee"}
              value={value?.total_amount}
            />
          </Grid.Col>
        </Grid>
      </Popover.Dropdown>
    </Popover>
  )
};

export default ReloadFeePopover;