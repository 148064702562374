import {
  Checkbox,
  createStyles,
  Grid,
  Table,
  Text,
  Tooltip,
  Modal,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import TitleComp from "../../components/CommonComponents/TextComponent/TitleComp";
import { COLORS, STAGE_CHANGE_TEXT } from "../../constants";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import Nums from "../../components/Body/Nums";
import ImdStyle from "../IMD/Imd.styles";
import {
  getLoanByDealershipId,
  getRenewalFee,
  createNewLoan,
  saveLoanAndsendForNextStage,
  updateLoanById,
  sendLoanforNextStage,
} from "../../services/loan.service";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { sumBy } from "lodash";
import {
  currencyAsFloat,
  currencyAsInt,
} from "../../components/Currency/currencyFormat";
import { ViewData } from "../../components/CommonComponents/Preview/FilePreview";
import ControllerCurrencyInput from "../../components/CommonComponents/TextField/ControllerCurrencyInput";
import { useQuery } from "@tanstack/react-query";
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { useListState } from "@mantine/hooks";
import dayjs from "dayjs";
import AdditionalDeduction from "./AdditionalDeduction";
import ActionButtons from "../../components/CommonComponents/ActionButtons/ActionButtons";
import * as yup from "yup";
import { IN_APPROVAL } from "../../constants/status";
import SideDrawerRenewal from "../../components/CommonComponents/Modal/SideDrawerRenewal";
import Remarks from "../../components/CommonComponents/Remarks/Remarks";
import DialogModal from "../../components/CommonComponents/Modal/DialogModal";

const useStyles = createStyles({
  totalTablevalue: {
    textAlign: "right",
    backgroundColor: COLORS.primary.light2,
    "&:hover": {
      textDecoration: "underline",
      color: COLORS.primary.main,
    },
  },
});

const loanSchema = yup.object({
  loan_amount: yup
    .string()
    .nullable()
    .required("Please Enter a Tranche Amount"),
});


/** here we are handling the renewal loan features 
 * @props
 * formType: contain string used to set the default value
 * repaymentType: contain object of the customer repayment details
 * approvedImd: contain object of approved IMD
 * systemDate: contain object of system info
 * loanData: contain object of saved loan details
 * createdLoanId: contain created Loan Id
 * customerAppId: contain customer Application Id
 * stages: contain list of stages available
 * reviewTrail: contain list of remarks of user
 * creditReloadData: contain the credit reload data
 * props: contain the set states
 */
const RevolvingLoan = ({
  formType,
  createdLoanId,
  loanId,
  customerAppId,
  loanData,
  approvedIMD,
  repayment,
  systemDate,
  stages,
  reviewTrail,
  feeType,
  creditReloadData,
  ...props
}) => {
  const { classes } = useStyles();
  const [label, setLabel] = useState();
  const [status, setStatus] = useState();
  const [open, setOpen] = useState(false);
  const [valideData, setValideData] = useState({});
  const [sideModal, setSideModal] = useState(false);
  const [calculatedFee, setCalculateFee] = useState({});
  const [remarksLoading, setRemarksLoading] = useState();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [renewalDetails, setRenewalDetails] = useState([]);
  const [selectedTrancheIDs, setSelectedTrancheIDs] = useState([]);
  const [revlovingSelectionType, setRevolvingSelectionType] = useState();
  const [selectedTrancheRow, selectedTrancheRowhandlers] = useListState([]);
  const [selectedTrancheColumn, selectedTrancheColumnHandlers] = useListState([]);
  const [deductionAlertModal, setDeductionAlertModal] = useState(false)
  const [selectedPayHead, selectedPayHeadHandlers] = useListState([
    false,
    false,
    false,
    false,
    false,
  ]);

  /** used to store the last array values */
  let arrData = selectedTrancheColumn?.length
    ? Object.values(selectedTrancheColumn[selectedTrancheColumn?.length - 1])
    : null;

  let total;

  const {
    setValue,
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
    watch,
  } = useForm({
    mode: "all",
    resolver: yupResolver(loanSchema),
    defaultValues:
      formType == "view"
        ? {
          ...loanData,
          loan_type: "revolving",
        }
        : {
          disbursed_date: dayjs(new Date()).format(),
          loan_type: "revolving",
        },
  });
  const values = watch();

  // used to handle the additional deduction
  const deductionFieldArray = useFieldArray({
    control,
    name: "additional_deduction",
  });

  // used to get the renewal fee
  const { data: renewalFee = [] } = useQuery(
    ["renewal-fee", customerAppId],
    () => getRenewalFee(customerAppId),
    {
      onSuccess: (data) => {
        if (data?.renewal_fee_collection) {
          displayNotification({
            message: "There is a renewal fee for this customer",
            variant: 'success',
          });
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (Object.keys(approvedIMD)?.length && !formType) {
      setValue("eligible_amount", approvedIMD?.eligible_amount);
    }
    if (Object.keys(systemDate)?.length && !formType) {
      setValue("disbursed_date", systemDate?.current_system_date);
    }
  }, [approvedIMD, systemDate]);

  useEffect(() => {
    // set the saved loan data as default value 
    if (Object.keys(loanData)?.length) {
      setValue("eligible_amount", loanData?.eligible_amount);
      reset(loanData);
      setValue("loan_type", 'revolving')
    }
    // handling the renewal_fee in saved stage
    if (values?.renewal_fee?.length) {
      deductionFieldArray?.append({
        fee_type: "fee",
        fee_name: 'processing fee',
        fee_value: values?.renewal_fee[0]?.fee_value,
        fee_collection_type: values?.renewal_fee[0]?.fee_collection_type
      });
    }
  }, [loanData]);

  const handleReviewModal = () => {
    // handling the review or approval stage
    loanSchema
      .validate(values)
      .then((res) => {
        setOpen(true);
        setStatus("review");
        setLabel(
          stages?.next_stage == "approval"
            ? "Send for Approval"
            : "Send for Review"
        );
      })
      .catch((err) => {
        displayNotification({ message: err?.errors, variant: 'error' });
      });
  };

  /** this function is used to handle the loan amount field */
  const validateLoanAmount = () => {
    let msg = "Tranche amount should be less than approved amount";
    if (values?.eligible_amount) {
      if (
        currencyAsInt(values?.loan_amount) > parseInt(values?.eligible_amount)
      ) {
        return msg;
      } else {
        return errors?.loan_amount?.message;
      }
    }
  };

  /** this function is used to handle the dueDate */
  let newdate = new Date(values?.disbursed_date);
  const calculateDueDate = () => {
    if (loanData?.due_date) {
      return loanData?.due_date;
    } else if (values?.disbursed_date) {
      return newdate.setDate(newdate.getDate() + repayment?.no_of_days);
    }
  };

  /** this function is used to handle the no.of Days between disb date and due date */
  let disb_date = dayjs(values?.disbursed_date);
  let no_of_days;
  const calculateNoOfDays = () => {
    if (loanData?.due_date) {
      let due_date = dayjs(loanData?.due_date);
      no_of_days = due_date.diff(disb_date, "day");
      return no_of_days;
    } else {
      let due_date = dayjs().add(repayment?.no_of_days, "day");
      no_of_days = disb_date.diff(newdate, "day");
      return Math.abs(no_of_days);
    }
  };

  /** used to set loan amount when the credit reload request is selected */
  useEffect(() => {
    if (creditReloadData?.amount) {
      setValue("loan_amount", creditReloadData?.amount);
    }
  }, [creditReloadData]);

  /** used to get the prev loan by sending the loan type as revolving */
  useEffect(() => {
    const body = {
      loan_type: "revolving",
    };
    getLoanByDealershipId(customerAppId, body, createdLoanId)
      .then((data) => {
        /** used to handle the same disb date check */
        setRenewalDetails(
          data?.map((item, i) => {
            item.check = false;
            item.principle_selected = false;
            item.visible =
              i == 0 ||
                (item?.disbursed_date == data[i - 1]?.disbursed_date &&
                  data[i - 1]?.visible == false)
                ? false
                : true;
            return item;
          })
        );
        /** used to add the all payhead */
        setValideData({
          if_principle: sumBy(data, "outstanding_principle"),
          if_interest: sumBy(data, "outstanding_interest"),
          if_spread: sumBy(data, "outstanding_spread"),
          if_addon_spread: sumBy(data, "outstanding_addon_spread"),
          if_interest_penal: sumBy(
            data,
            "outstanding_interest_amount_during_penalty"
          ),
          if_spread_penal: sumBy(
            data,
            "outstanding_spread_amount_during_penalty"
          ),
          if_addon_penal: sumBy(
            data,
            "outstanding_addon_spread_amount_during_penalty"
          ),
          if_penal_f: sumBy(data, "penal_f"),
          if_penal: sumBy(data, "outstanding_penal_interest_amount"),
          dpd: sumBy(data, "days_past_due"),
        });
        /** used to handle the prospect code table */
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
        setRenewalDetails();
      });
  }, []);

  /** used to set the deduction loan */
  useEffect(() => {
    const handleTrancheSelectedIDs = (arr) => {
      let deductionLoanIds = [];
      Array(arr?.length)
        .fill(arr?.length)
        ?.forEach((item, index) => {
          deductionLoanIds.push(parseInt(Object.keys(arr[index])[0]));
        });
      setSelectedTrancheIDs(deductionLoanIds);
    };
    if (selectedTrancheRow?.length)
      handleTrancheSelectedIDs(selectedTrancheRow);
    else if (selectedTrancheColumn?.length)
      handleTrancheSelectedIDs(selectedTrancheColumn);
    else setSelectedTrancheIDs([]);
  }, [selectedTrancheRow, selectedTrancheColumn]);

  /** used to change the eligible amount when the principle is selected in revolving */
  useEffect(() => {
    if (formType) {
      setValue(
        "eligible_amount",
        loanData?.eligible_amount +
        handleSelectedTranchePayheadAmount("outstanding_principle")
      );
    } else {
      setValue(
        "eligible_amount",
        approvedIMD?.eligible_amount +
        handleSelectedTranchePayheadAmount("outstanding_principle")
      );
    }
    /** increasing the loan amount when the user select the principle in revolving loan */
    if (handleSelectedTranchePayheadAmount("outstanding_principle")) {
      creditReloadData?.amount &&
        setValue(
          "loan_amount",
          currencyAsFloat(creditReloadData?.amount) +
          handleSelectedTranchePayheadAmount("outstanding_principle")
        );
      /** if principle amount is not there then the credit reload requested amount will be the loan amount */
    } else {
      creditReloadData?.amount &&
        setValue("loan_amount", creditReloadData?.amount);
    }
  }, [selectedTrancheIDs, creditReloadData?.amount]);

  useEffect(() => {
    /** handling the revolving loan selection after save */
    if (loanData?.deduction_loan_ids?.length > 0 && renewalDetails?.length) {
      /** assign the saved loan deduction type */
      setRevolvingSelectionType(loanData?.deduction_loan_payload?.orientation);
      /** assign the saved loan deduction ids */
      setSelectedTrancheIDs(loanData?.deduction_loan_ids);
      /** used to get the index of the payHead */
      const payHead = [
        "outstanding_interest",
        "outstanding_spread",
        "outstanding_addon_spread",
        "outstanding_interest_amount_during_penalty",
        "outstanding_penal_interest_amount",
      ];
      /** used to load the selected deduction loan details */
      loanData?.deduction_loan_ids?.forEach((item, i) => {
        /** used to select loan by horizontal  */
        /** here we are handling the empty renewal payload
         * this scenario will occur when the user is not selecting the renewal loan
         * the api will auto select the last closed loan by default and send the id in deduction id with empty deduction payload
         */
        const data = loanData?.deduction_loan_payload?.selection
          ? Object.values(loanData?.deduction_loan_payload?.selection[i])
          : [];
        if (loanData?.deduction_loan_payload?.orientation == "horizontal") {
          let isPrinciple = false;
          /** used to select the principle */
          data[0]?.map((val, index) => {
            if (val === "outstanding_principle") {
              renewalDetails.map((keys) => {
                if (keys?.id == item) {
                  handleRowWiseTrancheSelection(true, i, item, 0);
                  keys.principle_selected = true;
                  isPrinciple = true;
                }
              });
            }
          });
          /**
           * stops the loop while the principle is selected
           * else it will call the function to set the data
           */
          if (isPrinciple) return;
          handleRowWiseTrancheSelection(true, i, item);
          /** used to select loan by vertical */
        } else if (
          loanData?.deduction_loan_payload?.orientation == "vertical"
        ) {
          data[0]?.map((val, index) => {
            /** used to select the principle */
            if (val === "outstanding_principle") {
              renewalDetails[i].principle_selected = true;
            }
            /** used to load the loan deduction details for vertical one */
            if (val != "outstanding_principle" && i == 0) {
              selectedTrancheColumnHandlers.setState([
                ...loanData?.deduction_loan_payload?.selection,
              ]);
              selectedPayHeadHandlers.applyWhere(
                (item, index) => index == payHead?.indexOf(val),
                () => true
              );
            }
          });
        }
      });
    }
    /** this useEffect is used to handle the saved values of prosepct code in table */
  }, [renewalDetails]);

  /**
   * this function is used to handle the total value of selected amount from prosepct code table
   * here target_val is the pay head name
   */
  const handleSelectedTranchePayheadAmount = (target_val) => {
    let val = 0;
    let values = [];
    /** used to handle the payhead wise total */
    const subHandleTotalValue = (arr) => {
      selectedTrancheIDs?.forEach((keys, index) => {
        renewalDetails?.forEach((item, i) => {
          /** used to take the array of values */
          values = arr?.length > index ? Object.values(arr[index])[0] : [];
          /*
           * if penal f means it will not check the values array else it will
           */
          if (
            target_val == "penal_f" &&
            values?.includes("outstanding_interest_amount_during_penalty")
          ) {
            if (keys == item?.id) {
              val = item[target_val] + val;
            }
          } else {
            if (keys == item?.id && values?.includes(target_val)) {
              val = item[target_val] + val;
            }
          }
        });
      });
    };
    /** used to check the type and length */
    if (selectedTrancheRow?.length && revlovingSelectionType == "horizontal") {
      subHandleTotalValue(selectedTrancheRow);
    }
    if (selectedTrancheColumn?.length && revlovingSelectionType == "vertical") {
      subHandleTotalValue(selectedTrancheColumn);
    }
    /** it will return the total value of given pay head */
    return Math.round(val * 100) / 100 || 0;
  };

  /** handling the principle selection */
  const handleProspectCodePrinciple = (id, isSelected) => {
    let index = selectedTrancheIDs?.indexOf(id);
    let deduct = 0;
    renewalDetails?.forEach((item, i) => {
      if (!isSelected && item?.id == id) {
        /** function is used to handle the principle selection */
        const handleSelection = () => {
          if (selectedTrancheRow?.length > index) {
            item.principle_selected = true;
            selectedTrancheRowhandlers.remove(index);
            const value = {
              [id]: [
                "outstanding_principle",
                "outstanding_interest",
                "outstanding_spread",
                "outstanding_addon_spread",
                "outstanding_interest_amount_during_penalty",
                "outstanding_spread_amount_during_penalty",
                "outstanding_addon_spread_amount_during_penalty",
                "outstanding_penal_interest_amount",
              ],
            };
            selectedTrancheRowhandlers.insert(index, value);
          }
          if (selectedTrancheColumn?.length) {
            item.principle_selected = true;
            selectedTrancheColumnHandlers.remove(index);
            const value = {
              [id]: ["outstanding_principle", ...arrData[0]],
            };
            selectedTrancheColumnHandlers.insert(index, value);
          }
        };
        /** here we are checking the condition */
        if (index > 0) {
          if (renewalDetails[index - 1]?.principle_selected) {
            handleSelection();
          } else if (
            !renewalDetails[index - 1]?.principle_selected &&
            renewalDetails[index - 1]?.outstanding_principle == 0
          ) {
            handleSelection();
          }
        } else if (index === 0) {
          handleSelection();
        }
      }
      /** handling the unselecting the principle */
      if (isSelected) {
        /** unselecting the principle for horizandal selection */
        if (selectedTrancheRow?.length) {
          item.principle_selected = false;
          if (selectedTrancheIDs?.includes(item?.id)) {
            selectedTrancheRowhandlers.remove(deduct);
            const value = {
              [item?.id]: [
                "outstanding_interest",
                "outstanding_spread",
                "outstanding_addon_spread",
                "outstanding_interest_amount_during_penalty",
                "outstanding_spread_amount_during_penalty",
                "outstanding_addon_spread_amount_during_penalty",
                "outstanding_penal_interest_amount",
              ],
            };
            selectedTrancheRowhandlers.insert(deduct, value);
            if (selectedTrancheIDs?.length > deduct + 1) {
              deduct++;
            } else {
              return;
            }
          }
        }
        /** unselecting the principle for vertical selection */
        if (selectedTrancheColumn?.length) {
          item.principle_selected = false;
          selectedTrancheColumnHandlers.remove(i);
          if (arrData[0]?.includes("outstanding_principle")) {
            arrData[0].splice(arrData[0]?.indexOf("outstanding_principle"), 1);
          }
          const value = {
            [item?.id]: [...arrData[0]],
          };
          selectedTrancheColumnHandlers.insert(i, value);
        }
      }
    });
  };

  /** used to handle the vertical selection */
  const handleColumnWiseTrancheSelection = (event, name, id, isOut = false) => {
    !isOut &&
      revlovingSelectionType != "vertical" &&
      setRevolvingSelectionType("vertical");
    /** used to unselect the horizandal selection */
    if (selectedTrancheRow?.length && !isOut)
      handleRowWiseTrancheSelection(false, 0, id, 2, false, true);
    event && selectedTrancheColumnHandlers.setState([]);
    renewalDetails?.forEach((item, i) => {
      /** handling the selection for vertical */
      if (!event) {
        /** used to handle the color while selection */
        selectedPayHeadHandlers.applyWhere(
          (item, index) => index == id,
          () => true
        );
        /** used to handle the selection */
        if (selectedTrancheColumn?.length) {
          if (!arrData[0]?.includes(...name)) {
            selectedTrancheColumnHandlers.applyWhere(
              (val) => val[item?.id],
              (val) => ({ [item?.id]: [...val[item?.id], ...name] })
            );
          }
        } else {
          const value = {
            [`${item?.id}`]: [...name],
          };
          selectedTrancheColumnHandlers.append(value);
        }
      }
      /** used to unselect the vertical selection */
      if (event) {
        /** used to handle the color while unselect */
        selectedPayHeadHandlers.applyWhere(
          (item, index) => index == id,
          () => false
        );
        /** used to remove the selected pay head */
        if (i == 0) {
          if (name?.length == 1) {
            arrData[0]?.splice(arrData[0].indexOf(...name), 1);
          } else {
            arrData[0]?.splice(arrData[0].indexOf(name[0]), 3);
          }
          if (arrData[0]?.includes("outstanding_principle")) {
            arrData[0]?.splice(arrData[0].indexOf("outstanding_principle"), 1);
          }
        }
        /** used to empty the list when name is equal to remove */
        if (name == "remove") {
          selectedTrancheColumnHandlers.setState([]);
          selectedPayHeadHandlers.setState([false, false, false, false, false]);
          item.principle_selected = false;
        } else if (arrData[0]?.length) {
          /** handling the selection if only there is principle */
          if (
            arrData[0]?.length == 1 &&
            arrData[0]?.includes("outstanding_principle")
          ) {
            selectedTrancheColumnHandlers.setState([]);
            item.principle_selected = false;
          } else {
            item.principle_selected = false;
            const value = {
              [item?.id]: [...arrData[0]],
            };
            selectedTrancheColumnHandlers.append(value);
          }
        } else {
          selectedTrancheColumnHandlers.setState([]);
          item.principle_selected = false;
        }
      }
    });
  };

  /** this function is used to handle the prosepct code table */
  const handleRowWiseTrancheSelection = (
    event,
    index,
    id,
    indexDate = 2,
    addItem = false,
    isOut = false
  ) => {
    !isOut &&
      revlovingSelectionType != "horizontal" &&
      setRevolvingSelectionType("horizontal");
    if (selectedTrancheColumn?.length && !isOut)
      handleColumnWiseTrancheSelection(true, "remove", index, true);
    const len = renewalDetails?.length;
    let checkArray = renewalDetails.slice();
    renewalDetails?.forEach((item, i) => {
      /** this if statement is used to add values and make visible the check box */
      if (event == true && item?.visible == false) {
        /** this statement is used to add item into array */
        if (id == item?.id && !addItem) {
          item.check = true;
          const value = {
            [`${id}`]: [
              "outstanding_interest",
              "outstanding_spread",
              "outstanding_addon_spread",
              "outstanding_interest_amount_during_penalty",
              "outstanding_spread_amount_during_penalty",
              "outstanding_addon_spread_amount_during_penalty",
              "outstanding_penal_interest_amount",
            ],
          };
          if (indexDate == 0 || indexDate == 1)
            value[id]?.push("outstanding_principle");
          selectedTrancheRowhandlers.append(value);
        }
        /** this function is used to visible next the check box */
        if (
          index + 1 < len &&
          (index == 0 || renewalDetails[index - 1].check == true)
        ) {
          renewalDetails[index + 1].visible = false;
        }
        /** this function is used to handle the disb date selection */
        if (index + 1 < len && index + indexDate < len) {
          /** this statement is used to call the function itself untill the disb date is not same to make checkbox to visible all same disb date */
          if (
            renewalDetails[index + 1].disbursed_date ==
            renewalDetails[index + indexDate].disbursed_date
          ) {
            renewalDetails[index + indexDate].visible = false;
            if (index + indexDate + 1 < len)
              indexDate++ &&
                handleRowWiseTrancheSelection(
                  event,
                  index,
                  id,
                  indexDate,
                  true
                );
            /** this statement is used to visible the checkbox in unorder selection */
          } else if (
            renewalDetails[index + 1].disbursed_date !=
            renewalDetails[index + indexDate].disbursed_date
          ) {
            checkArray.splice(selectedTrancheRow?.length + 1);
            let check = checkArray.some((r) =>
              selectedTrancheRow.includes(r?.id)
            );
            if (
              index + indexDate < len &&
              check &&
              renewalDetails[index + indexDate - 1].check == true
            )
              renewalDetails[index + indexDate].visible = false;
          }
        }
      }
      /** this statement is used to uncheck and remove the array elements upto index */
      if (event == false && item?.visible == false) {
        const array = new Array(selectedTrancheRow?.length).fill(1);
        /** this statement is used to pop the array elements up to index */
        if (index == i && !addItem) {
          if (index < selectedTrancheRow?.length) {
            array?.forEach((item, reg) => {
              if (index <= reg) {
                selectedTrancheRowhandlers.pop();
              }
            });
          } else if (index >= selectedTrancheRow?.length) {
            selectedTrancheRowhandlers.pop();
          }
          item.check = false;
          item.principle_selected = false;
        }
        /** this statement is used to uncheck the checkbox and visible false */
        if (index + 1 <= i) {
          if (indexDate == 2 && !addItem) {
            item.visible = true;
          }
          item.check = false;
          item.principle_selected = false;
        }
        /** this statement is used to make the visible true untill it has same disb date */
        if (index < len && index + indexDate - 1 < len) {
          if (
            renewalDetails[index].disbursed_date ==
            renewalDetails[index + indexDate - 1].disbursed_date
          ) {
            renewalDetails[index + indexDate - 1].visible = false;
            renewalDetails[index + indexDate - 1].principle_selected = false;
            if (index + indexDate < len) {
              renewalDetails[index + indexDate].visible = true;
              renewalDetails[index + indexDate].check = false;
              renewalDetails[index + indexDate].principle_selected = false;
            }
          }
        }
      }
    });
  };

  /** this function is used to handle the total value of selected pay amount */
  const handleSelectedTrancheTotalAmount = () => {
    total = 0;
    let values = [];
    const handleSubTotalforTable = (arr) => {
      selectedTrancheIDs?.forEach((keys, index) => {
        renewalDetails?.map((item, i) => {
          let val = 0;
          values = arr?.length > index ? Object.values(arr[index])[0] : [];
          if (keys == item?.id) {
            values?.map((payhead) => {
              val = item[payhead] + val;
            });
            total = val + total;
            return;
          }
        });
      });
    };
    if (selectedTrancheRow?.length && revlovingSelectionType == "horizontal") {
      handleSubTotalforTable(selectedTrancheRow);
    }
    if (selectedTrancheColumn?.length && revlovingSelectionType == "vertical") {
      handleSubTotalforTable(selectedTrancheColumn);
    }
  };

  /** handling the tranche wise total amount bby sending the tranche id */
  const handleLoanWiseTotal = (id) => {
    let total = 0;
    const handleTotalTrancheAmount = (arr) => {
      renewalDetails?.map((tranche) => {
        if (tranche?.id == id) {
          arr?.map((pay, i) => {
            pay[tranche?.id]?.map((data) => {
              total = tranche[data] + total;
            });
          });
        }
      });
      return total;
    };
    /** used to check the type and length */
    if (selectedTrancheRow?.length && revlovingSelectionType == "horizontal") {
      return handleTotalTrancheAmount(selectedTrancheRow);
    }
    if (selectedTrancheColumn?.length && revlovingSelectionType == "vertical") {
      return handleTotalTrancheAmount(selectedTrancheColumn);
    }
  };

  /** used to handle the background color for selected tranche */
  const handleSelectedTrancheBackground = (index) => {
    return selectedPayHead[index] ? "#D3F9D8" : undefined;
  };
  /** used to handle the checkbox for selected payhaed
   * id will contain the value of check box
   */
  const handleSelectedPayhead = (id) => {
    return selectedPayHead[id] ? true : false;
  };

  /** used to handle the text color for selected tranche */
  const handleSelectedTrancheTextColor = (index, isSelected) => {
    return selectedPayHead[index] || isSelected ? "#E03131" : undefined;
  };

  /** this function is used to calculate the net disb amount */
  const calculateNetDisbAmount = () => {
    let amount = currencyAsInt(values?.loan_amount);
    if (values?.additional_deduction?.length) {
      values?.additional_deduction?.map((item) => {
        if (["fee", "express_reload_fee"]?.includes(item?.fee_type) && item?.total_amount) {
          amount = amount - currencyAsInt(item?.total_amount);
        } else if (item?.fee_value) {
          amount = amount - currencyAsInt(item?.fee_value);
        }
      });
    } else {
      amount = amount;
    }
    if (total && currencyAsInt(values?.loan_amount)) {
      amount = amount - total;
    }
    return amount > 0 ? amount : "0";
  };

  /** this function is used to handle the additional deduction and renewal fee amount */
  const handleDeduction = (val) => {
    let deduction = [];
    values?.additional_deduction?.map((item, i) => {
      item.fee_value = currencyAsInt(item?.fee_value);
      if (val === "deductions") {
        if (item?.fee_name?.toLowerCase() !== "processing fee") {
          deduction.push(item);
        }
        else if (item?.fee_type !== 'fee') {
          deduction.push(item);
        }
      } else if (item?.fee_name === val && item?.fee_type === 'fee') {
        deduction.push(item)
      }
    });

    /** if u need the array then pass the value as deduction or amount means pass as renewal_fee */
    if (val === "deductions") {
      return deduction;
    } else if (val != "deductions") {
      return deduction;
    }

    return deduction;
  };

  /** used to handle the data give to the api */
  const handleTrancheDatatoAPI = (type) => {
    let data = [];
    if (values?.loan_type == "revolving") {
      const subFunHandlers = (arr) => {
        Array(arr?.length)
          .fill(arr?.length)
          ?.map((item, index) => {
            data.push(parseInt(Object.keys(arr[index])[0]));
          });
      };
      if (type == "arr") {
        if (selectedTrancheRow?.length) return selectedTrancheRow;
        else if (selectedTrancheColumn?.length) return selectedTrancheColumn;
        else return null;
      } else {
        if (selectedTrancheRow?.length) subFunHandlers(selectedTrancheRow);
        else if (selectedTrancheColumn?.length)
          subFunHandlers(selectedTrancheColumn);
        return data;
      }
    } else {
      return null;
    }
  };

  // used to check the additional deduction array if the fee name is present in the feeType array
  const handleAdditionalDeductionsError = (deductionArray) => {
    var isAllow = true;
    if (deductionArray?.length) {
      deductionArray?.some((item) => {
        if (feeType?.filter((e) => e?.fee_name?.toLowerCase() === item?.fee_name?.toLowerCase())?.length && item?.fee_type === 'fee') {
          isAllow = false;
          return true;
        }
      });
    }
    if (!isAllow) {
      displayNotification({
        id: "imd",
        variant: 'error',
        message: 'Please Check additional deductions',
      });
    }
    return isAllow;
  }

  // handling the save and update api call
  const onSubmit = (values) => {
    const body = {
      customer_application_id: customerAppId,
      customer_id: approvedIMD?.customer_id,
      loan_type: "revolving",
      crr_id: creditReloadData?.crr_id,
      deduction_loan_ids: handleTrancheDatatoAPI(),
      deduction_loan_payload: {
        orientation: selectedTrancheRow?.length
          ? "horizontal"
          : selectedTrancheColumn?.length
            ? "vertical"
            : null,
        selection: handleTrancheDatatoAPI("arr"),
      },
      renewal_fee: handleDeduction("processing fee"),
      loan_amount: currencyAsFloat(values?.loan_amount),
      payment_mode: "OTP",
      disbursed_date: dateFormat(values?.disbursed_date, "YYYY-MM-DD"),
      additional_deduction: handleDeduction("deductions"),
    };

    if (handleAdditionalDeductionsError(body?.additional_deduction)) {
      setButtonLoader(true);
      if (!createdLoanId) {
        createNewLoan(body)
          .then((res) => {
            props?.setSucessfulmodal({
              open: true,
              title: STAGE_CHANGE_TEXT?.save,
            });
          })
          .catch((e) => {
            if (e?.RequestValidationError) {
              Object.keys(e?.RequestValidationError).forEach((item, index) => {
                setError(item, { message: e?.RequestValidationError[item] });
              });
            } else {
              displayNotification({
                id: "imd-save",
                message: e?.message || e,
                variant: 'error',
              });
            }
          })
          .finally(() => {
            setButtonLoader(false);
          });
      } else {
        updateLoanById(createdLoanId, body)
          .then((res) => {
            props?.setSucessfulmodal({
              open: true,
              title: STAGE_CHANGE_TEXT?.update,
            });
          })
          .catch((e) => {
            if (e?.RequestValidationError) {
              Object.keys(e?.RequestValidationError).forEach((item, index) => {
                setError(item, { message: e?.RequestValidationError[item] });
              });
            } else {
              displayNotification({
                id: "imd-save",
                message: e?.message || e,
                variant: 'error',
              });
            }
          })
          .finally(() => {
            setButtonLoader(false);
          });
      }
    }
  };

  //  handling the review or approval api call
  const onSendForReview = (remarks) => {
    const body = {
      remarks: remarks,
    };
    const saveLoanBody = {
      customer_application_id: customerAppId,
      customer_id: approvedIMD?.customer_id,
      ...body,
      crr_id: creditReloadData?.crr_id,
      loan_type: values?.loan_type,
      deduction_loan_ids: handleTrancheDatatoAPI(),
      deduction_loan_payload: {
        orientation: selectedTrancheRow?.length
          ? "horizontal"
          : selectedTrancheColumn?.length
            ? "vertical"
            : null,
        selection: handleTrancheDatatoAPI("arr"),
      },
      loan_amount: currencyAsFloat(values?.loan_amount),
      payment_mode: "OTP",
      disbursed_date: dateFormat(values?.disbursed_date, "YYYY-MM-DD"),
      additional_deduction: handleDeduction("deductions"),
      renewal_fee: handleDeduction("processing fee"),
    };
    if (handleAdditionalDeductionsError(saveLoanBody?.additional_deduction)) {
      setRemarksLoading(true);
      if (!createdLoanId) {
        const saveLoanBodyWithRemarks = {
          ...saveLoanBody,
          ...body,
        };
        saveLoanAndsendForNextStage(saveLoanBodyWithRemarks)
          .then((res) => {
            setRemarksLoading(false);
            setOpen(false);
            props?.setSucessfulmodal({
              open: true,
              title:
                stages?.next_stage == IN_APPROVAL
                  ? STAGE_CHANGE_TEXT?.approval
                  : STAGE_CHANGE_TEXT?.review,
            });
          })
          .catch((e) => {
            // This catch block sets field validation errors from api whic are not handled in UI.
            if (e?.RequestValidationError) {
              Object.keys(e?.RequestValidationError).forEach((item, index) => {
                setError(item, { message: e?.RequestValidationError[item] });
              });
            } else {
              displayNotification({
                id: "imd-save",
                message: e?.message || e,
                variant: 'error',
              });
            }
          })
          .finally(() => {
            setRemarksLoading(false);
            setOpen(false);
          });
      } else {
        updateLoanById(createdLoanId, saveLoanBody)
          .then(() => {
            sendLoanforNextStage(createdLoanId, body)
              .then((res) => {
                props?.setSucessfulmodal({
                  open: true,
                  title:
                    stages?.next_stage == IN_APPROVAL
                      ? STAGE_CHANGE_TEXT?.approval
                      : STAGE_CHANGE_TEXT?.review,
                });
              })
              .catch((e) => {
                if (e?.RequestValidationError) {
                  Object.keys(e?.RequestValidationError).forEach(
                    (item, index) => {
                      setError(item, {
                        message: e?.RequestValidationError[item],
                      });
                    }
                  );
                } else {
                  displayNotification({
                    id: "loan-save",
                    message: e?.message || e,
                    variant: 'error',
                  });
                }
              });
          })
          .catch((e) => {
            if (e?.RequestValidationError) {
              Object.keys(e?.RequestValidationError).forEach((item, index) => {
                setError(item, { message: e?.RequestValidationError[item] });
              });
            } else {
              displayNotification({
                id: "loan-save",
                message: e?.message || e,
                variant: 'error',
              });
            }
          })
          .finally(() => {
            setRemarksLoading(false);
            setOpen(false);
          });
      }
    }
  };

  // used to check whether the selected deduction has some amount are not
  const handleDeductionLoanPrinciple = () => {
    let data = handleTrancheDatatoAPI('arr');
    if ((data?.length && total) || !data?.length) {
      handleReviewModal()
    } else {
      setDeductionAlertModal(true)
    }
  }

  return (
    <>
      <Grid>
        <Grid.Col span={7}>
          <Grid.Col span={12} p={0} mt={'md'}>
            <ViewData
              title={"Product"}
              value={approvedIMD?.product_name}
              type={"text"}
            />
          </Grid.Col>
          {renewalDetails?.length ? (
            <Grid.Col span={12} sx={{ padding: "0px" }} mt={"md"}>
              <TitleComp label={"Earlier Tranche Code"} />
              <Text size={"xs"} color={"gray"}>
                Please select the loan for revolving
              </Text>
              <Table
                mt={"xs"}
                verticalSpacing="xs"
                fontSize="xs"
                highlightOnHover
                sx={ImdStyle?.tableOverFlow}
              >
                <thead style={{ backgroundColor: COLORS.primary.light1 }}>
                  <tr>
                    <th></th>
                    <th>Earlier Tranche Code</th>
                    {valideData?.dpd ? (
                      <th style={{ textAlign: "right" }}>DPD Days</th>
                    ) : null}
                    {valideData?.if_principle ? (
                      <th style={{ textAlign: "right" }}>Principal</th>
                    ) : null}
                    {valideData?.if_interest ? (
                      <th
                        style={{
                          textAlign: "right",
                          background: handleSelectedTrancheBackground(0),
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleColumnWiseTrancheSelection(
                            selectedPayHead[0],
                            ["outstanding_interest"],
                            0
                          );
                        }}
                      >
                        <div style={{ display: "flex", gap: "5px" }}>
                          <Checkbox
                            size={"12px"}
                            checked={handleSelectedPayhead(0)}
                            value={"0"}
                          />
                          Interest
                        </div>
                      </th>
                    ) : null}
                    {valideData?.if_spread ? (
                      <th
                        style={{
                          textAlign: "right",
                          background: handleSelectedTrancheBackground(1),
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleColumnWiseTrancheSelection(
                            selectedPayHead[1],
                            ["outstanding_spread"],
                            1
                          );
                        }}
                      >
                        <div style={{ display: "flex", gap: "5px" }}>
                          <Checkbox
                            size={"12px"}
                            checked={handleSelectedPayhead(1)}
                            value={"1"}
                          />
                          Spread
                        </div>
                      </th>
                    ) : null}
                    {valideData?.if_addon_spread ? (
                      <th
                        style={{
                          textAlign: "right",
                          background: handleSelectedTrancheBackground(2),
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleColumnWiseTrancheSelection(
                            selectedPayHead[2],
                            ["outstanding_addon_spread"],
                            2
                          );
                        }}
                      >
                        <div style={{ display: "flex", gap: "5px" }}>
                          <Checkbox
                            size={"12px"}
                            checked={handleSelectedPayhead(2)}
                            value={"2"}
                          />
                          Addon Spread
                        </div>
                      </th>
                    ) : null}
                    {valideData?.if_penal_f ? (
                      <th
                        style={{
                          textAlign: "right",
                          background: handleSelectedTrancheBackground(3),
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleColumnWiseTrancheSelection(
                            selectedPayHead[3],
                            [
                              "outstanding_interest_amount_during_penalty",
                              "outstanding_spread_amount_during_penalty",
                              "outstanding_addon_spread_amount_during_penalty",
                            ],
                            3
                          );
                        }}
                      >
                        <Tooltip
                          color={"gray"}
                          withArrow
                          label={`Including ${valideData?.if_spread_penal ? "Spread F1" : ""
                            } ${valideData?.if_addon_penal
                              ? ", Addon Spread F1"
                              : ""
                            }`}
                          disabled={
                            valideData?.if_spread_penal ||
                              valideData?.if_addon_penal
                              ? false
                              : true
                          }
                        >
                          <div style={{ display: "flex", gap: "5px" }}>
                            <Checkbox
                              size={"12px"}
                              checked={handleSelectedPayhead(3)}
                              value={"3"}
                            />
                            Interest F1
                          </div>
                        </Tooltip>
                      </th>
                    ) : null}
                    {valideData?.if_penal ? (
                      <th
                        style={{
                          textAlign: "right",
                          background: handleSelectedTrancheBackground(4),
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleColumnWiseTrancheSelection(
                            selectedPayHead[4],
                            ["outstanding_penal_interest_amount"],
                            4
                          );
                        }}
                      >
                        <div style={{ display: "flex", gap: "5px" }}>
                          <Checkbox
                            size={"12px"}
                            checked={handleSelectedPayhead(4)}
                            value={"4"}
                          />
                          Penal F2
                        </div>
                      </th>
                    ) : null}
                    <th style={{ textAlign: "right" }}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {renewalDetails?.map((item, index) => (
                    <tr
                      key={index}
                      style={{ background: item?.check ? "#D3F9D8" : "" }}
                    >
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          item?.visible == false &&
                          handleRowWiseTrancheSelection(
                            item?.check == true ? false : true,
                            index,
                            item?.id,
                            2
                          )
                        }
                      >
                        <Checkbox
                          size={"12px"}
                          disabled={item?.visible}
                          checked={item?.check}
                          value={item?.id}
                        />
                      </td>
                      <td style={{ padding: "5px 5px" }}>
                        <Text
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontWeight: "500" }}>
                            {item?.prospect_code}
                          </span>
                          <small>
                            Disb Date: {dateFormat(item?.disbursed_date)}
                          </small>
                          <small>
                            Receipt Date:{" "}
                            {item?.receipt_date
                              ? dateFormat(item?.receipt_date)
                              : "-"}
                          </small>
                        </Text>
                      </td>
                      {valideData?.dpd ? <td>{item?.days_past_due}</td> : null}
                      {valideData?.if_principle ? (
                        <td
                          style={{
                            textAlign: "right",
                            background: item?.principle_selected
                              ? "#D3F9D8"
                              : "#ffff",
                            cursor: "pointer",
                            color: item?.principle_selected ? "#F03E3E" : "",
                          }}
                          onClick={() => {
                            handleProspectCodePrinciple(
                              item?.id,
                              item?.principle_selected
                            );
                          }}
                        >
                          <Nums value={item?.outstanding_principle} />
                        </td>
                      ) : null}
                      {valideData?.if_interest ? (
                        <td
                          style={{
                            textAlign: "right",
                            background: handleSelectedTrancheBackground(0),
                            color: handleSelectedTrancheTextColor(
                              0,
                              item?.check
                            ),
                          }}
                        >
                          <Nums value={item?.outstanding_interest} />
                        </td>
                      ) : null}
                      {valideData?.if_spread ? (
                        <td
                          style={{
                            textAlign: "right",
                            background: handleSelectedTrancheBackground(1),
                            color: handleSelectedTrancheTextColor(
                              1,
                              item?.check
                            ),
                          }}
                        >
                          <Nums value={item?.outstanding_spread} />
                        </td>
                      ) : null}
                      {valideData?.if_addon_spread ? (
                        <td
                          style={{
                            textAlign: "right",
                            background: handleSelectedTrancheBackground(2),
                            color: handleSelectedTrancheTextColor(
                              2,
                              item?.check
                            ),
                          }}
                        >
                          <Nums value={item?.outstanding_addon_spread} />
                        </td>
                      ) : null}
                      {valideData?.if_penal_f ? (
                        <td
                          style={{
                            textAlign: "right",
                            background: handleSelectedTrancheBackground(3),
                            color: handleSelectedTrancheTextColor(
                              3,
                              item?.check
                            ),
                          }}
                        >
                          <Nums value={item?.penal_f} />
                        </td>
                      ) : null}
                      {valideData?.if_penal ? (
                        <td
                          style={{
                            textAlign: "right",
                            background: handleSelectedTrancheBackground(4),
                            color: handleSelectedTrancheTextColor(
                              4,
                              item?.check
                            ),
                          }}
                        >
                          <Nums value={item?.outstanding_penal_interest_amount} />
                        </td>
                      ) : null}
                      <td
                        style={{
                          textAlign: "right",
                          backgroundColor: COLORS.primary.light2,
                        }}
                      >
                        <Nums value={handleLoanWiseTotal(item?.id)} />
                      </td>
                    </tr>
                  ))}
                  <tr
                    style={{
                      backgroundColor: COLORS.primary.light1,
                      cursor: "pointer",
                    }}
                    onClick={() => setSideModal(true)}
                  >
                    <td colSpan={valideData?.dpd ? "3" : "2"}>
                      <Tooltip
                        label={"click to view more..."}
                        color="gray"
                        withArrow
                        disabled={!selectedTrancheRow?.length}
                      >
                        <strong>Deductions</strong>
                      </Tooltip>
                    </td>
                    {/* <td></td> */}
                    {handleSelectedTrancheTotalAmount()}
                    {valideData?.if_principle ? (
                      <td style={{ textAlign: "right" }}>
                        <Nums value=
                          {
                            handleSelectedTranchePayheadAmount(
                              "outstanding_principle"
                            )
                          }
                        />
                      </td>
                    ) : null}
                    {valideData?.if_interest ? (
                      <td style={{ textAlign: "right" }}>
                        <Nums value=
                          {
                            handleSelectedTranchePayheadAmount(
                              "outstanding_interest"
                            )
                          }
                        />
                      </td>
                    ) : null}
                    {valideData?.if_spread ? (
                      <td style={{ textAlign: "right" }}>
                        <Nums value=
                          {
                            handleSelectedTranchePayheadAmount(
                              "outstanding_spread"
                            )
                          }
                        />
                      </td>
                    ) : null}
                    {valideData?.if_addon_spread ? (
                      <td style={{ textAlign: "right" }}>
                        <Nums value=
                          {
                            handleSelectedTranchePayheadAmount(
                              "outstanding_addon_spread"
                            )
                          }
                        />
                      </td>
                    ) : null}
                    {valideData?.if_penal_f ? (
                      <td style={{ textAlign: "right" }}>
                        <Nums value=
                          {
                            handleSelectedTranchePayheadAmount("penal_f")
                          }
                        />
                      </td>
                    ) : null}
                    {valideData?.if_penal ? (
                      <td style={{ textAlign: "right" }}>
                        <Nums value=
                          {
                            handleSelectedTranchePayheadAmount(
                              "outstanding_penal_interest_amount"
                            )
                          }
                        />
                      </td>
                    ) : null}
                    <td className={classes?.totalTablevalue}>
                      <Tooltip
                        label={"click to view more..."}
                        color="gray"
                        withArrow
                        disabled={!selectedTrancheRow?.length}
                      >
                        <Text>
                          <Nums value={total} />
                        </Text>
                      </Tooltip>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Grid.Col>
          ) : null}
        </Grid.Col>
        <Grid.Col span={5} mt={"lg"}>
          <Grid gutter="lg">
            <Grid.Col span={12} sx={{ padding: "1px 10px" }}>
              <ViewData
                title={"Available Limit"}
                value={Math.max(0, values?.eligible_amount)}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              {!creditReloadData?.crr_id ? (
                <ControllerCurrencyInput
                  name="loan_amount"
                  label="Tranche Amount"
                  value={values?.loan_amount}
                  amountInWords
                  required
                  disabled={creditReloadData?.crr_id ? true : false}
                  control={control}
                  errors={validateLoanAmount()}
                />
              ) : (
                <ViewData
                  title={"Loan Amount"}
                  value={values?.loan_amount}
                  type="currency"
                  withTooltip
                />
              )}
            </Grid.Col>
            <Grid.Col span={12} sx={{ padding: "1px 10px" }}>
              <ViewData
                title={"Disb Date"}
                value={values?.disbursed_date}
                type={"date"}
              />
            </Grid.Col>
            <Grid.Col span={12} sx={{ padding: "1px 10px" }}>
              <ViewData
                title={"Approved Limit"}
                value={Math.max(0, approvedIMD?.approved_amount)}
              />
            </Grid.Col>
            <Grid.Col span={12} sx={{ padding: "1px 10px" }}>
              <ViewData
                title={"Repayment Type"}
                value={repayment?.label}
                type="text"
              />
            </Grid.Col>
            <Grid.Col span={12} sx={{ padding: "1px 10px" }}>
              <ViewData
                title={`Interest`}
                secondaryLabel={approvedIMD?.interest_rate}
                value={Math.round(
                  ((currencyAsInt(values?.loan_amount) *
                    (approvedIMD?.interest_rate / 100)) /
                    approvedIMD?.interest_calc_period) *
                  approvedIMD?.no_of_days
                ) || loanData?.interest_amount
                }
              />
            </Grid.Col>
            {/* If spread rate percentage is avalible then it will display */}
            {approvedIMD?.spread_rate ? (
              <Grid.Col span={12} sx={{ padding: "1px 10px" }}>
                <ViewData
                  title={`Spread`}
                  secondaryLabel={approvedIMD?.spread_rate}
                  style={{ marginBottom: "0px" }}
                  value={
                    ((currencyAsInt(values?.loan_amount) *
                      (approvedIMD?.spread_rate / 100)) /
                      approvedIMD?.interest_calc_period) *
                    approvedIMD?.no_of_days || loanData?.spread_rate
                  }
                />
              </Grid.Col>
            ) : null}
            {/* If spread rate percentage is avalible then it will display */}
            {approvedIMD?.addon_spread_rate ? (
              <Grid.Col span={12} sx={{ padding: "1px 10px" }}>
                <ViewData
                  title={`Addon Spread`}
                  secondaryLabel={approvedIMD?.addon_spread_rate}
                  style={{ marginBottom: "0px" }}
                  value={
                    ((currencyAsInt(values?.loan_amount) *
                      (approvedIMD?.addon_spread_rate / 100)) /
                      approvedIMD?.interest_calc_period) *
                    approvedIMD?.no_of_days || loanData?.addon_spread_rate
                  }
                />
              </Grid.Col>
            ) : null}
            <Grid.Col span={12} sx={{ padding: "1px 10px" }}>
              <ViewData
                title={"Due Date"}
                value={calculateDueDate() || loanData?.due_date}
                type="date"
                info={true}
                infotext={
                  "Due date may be change according to holidays so please save and check the date"
                }
              />
            </Grid.Col>
            <Grid.Col span={12} sx={{ padding: "1px 10px" }}>
              <ViewData
                title={"No.of Days"}
                value={calculateNoOfDays()}
                type={"text"}
                info={true}
                infotext={
                  "No.of Days may be change according to holidays so please save and check the date"
                }
                style={{ marginBottom: "0px" }}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <AdditionalDeduction
                control={control}
                values={values}
                calculatedFee={calculatedFee}
                setCalculateFee={setCalculateFee}
                createdLoanId={createdLoanId}
                approvedIMD={approvedIMD}
                loanType={'revolving'}
                customerAppId={customerAppId}
                feeType={feeType}
                deductionFieldArray={deductionFieldArray}
              />
            </Grid.Col>
            <Grid.Col
              span={12}
              mt={"xs"}
              style={{ padding: "0", border: "2px solid #228be6" }}
            >
              <ViewData
                title={"Net Disb Amount"}
                style={{
                  marginBottom: "0px",
                  padding: "10px",
                  margin: "0px",
                }}
                value={calculateNetDisbAmount()}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
      {/** This Action buttons component is used to display the buttons in the flow */}
      <ActionButtons
        status={loanData?.status_value ? loanData?.status_value : "draft"}
        base={'creation'}
        nextStage={stages?.next_stage}
        sendForReviewCB={handleDeductionLoanPrinciple}
        sendForApprovalCB={handleDeductionLoanPrinciple}
        saveCB={handleSubmit(onSubmit)}
        loading={{ save: buttonLoader }}
        remarksCB={() => props?.setRemarksOpen(true)}
        remarks={reviewTrail}
        isReady={Boolean(stages?.next_stage)}
        currentStatus={values?.status_value}
        moduleApplicationId={values?.id}
        moduleName={'loan'}
      />
      {sideModal ? (
        <SideDrawerRenewal
          open={sideModal}
          onClose={setSideModal}
          data={renewalDetails}
          checkedData={selectedTrancheIDs}
          payHead={
            selectedTrancheRow?.length
              ? selectedTrancheRow
              : selectedTrancheColumn?.length
                ? selectedTrancheColumn
                : []
          }
          avaliableHead={valideData}
        />
      ) : null}
      <Remarks
        open={open}
        setOpen={setOpen}
        type={stages?.next_stage}
        callback={onSendForReview}
        remarksTitle={
          stages?.next_stage == IN_APPROVAL
            ? "Approval Remarks"
            : "Review Remarks"
        }
        check={status}
        label={label}
        loading={remarksLoading}
        currentStatus={values?.status_value}
        moduleApplicationId={values?.id}
        moduleName={'Loan'}
      />
      {/* displayed the modal to show warning msg to select or remove the deduction pay head */}
      <DialogModal
        open={deductionAlertModal}
        onClose={() => setDeductionAlertModal(false)}
        size="24rem"
        styles={(them) => ({
          title: {
            fontWeight: "600",
          },
        })}
        title="Review Deductions"
        transition="rotate-left"
        transitionDuration={400}
        transitionTimingFunction="ease"
        centered
      >
        <Text size={"sm"} sx={{ fontWeight: "500" }}>
          Selected earlier tranches does not have any valid deductions. Please check selected deductions.
        </Text>
      </DialogModal>
    </>
  );
};

export default RevolvingLoan;
