import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { TABLE_COLUMNS_STORE } from './storeKeys';

const useTableColumnsStore = create(
  persist(
    (set, get) => {
      return {
        tableData: {},
        saveTableData: (tableDataObj) => {
          set(currentState => ({
            tableData: {
              ...currentState?.tableData,
              ...tableDataObj
            }
          }))
        },
        getTableData: (tableKey) => {
          return get().tableData?.[tableKey]
        }
      }
    },
    {
      name: TABLE_COLUMNS_STORE,
      storage: createJSONStorage(() => localStorage)
    }
  )
)

export default useTableColumnsStore;