import React from 'react'
import DataViewerWapper from '../../../components/CommonComponents/Preview/DataViewerWapper'
import { ViewFormData } from '../../../components/CommonComponents/Preview/FilePreview'
import { Grid, Box } from '@mantine/core';
import TitleViewer from '../../../components/CommonComponents/Title/TitleViewer';

/**
 * Renders a component that displays the personal details, address, and other
 * information of an invoice for review. The data to be displayed is passed as
 * props to the component.
 *
 * @param {object} data - An object containing the invoice data to be displayed.
 */
const GeneralInvoiceReviewComp = ({ data }) => {
  return (
    <>
      <Grid gutter={'md'}>
        <Grid.Col span={4}>
          <DataViewerWapper>
            <Box mb={'sm'}>
              <TitleViewer title={"Personal Details"} />
            </Box>
            <ViewFormData
              label={'GST No'}
              value={data?.data?.gst_number}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Name'}
              value={data?.data?.name}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Phone No'}
              value={data?.data?.mobile}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Primary Contact No'}
              value={data?.data?.primary_contact_no}
              loading={data?.isLoading}
            />
          </DataViewerWapper>
        </Grid.Col>
        <Grid.Col span={4}>
          <DataViewerWapper>
            <Box mb={'sm'}>
              <TitleViewer title={"Address"} />
            </Box>
            <ViewFormData
              label={'Address'}
              value={data?.data?.address}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Post Code'}
              value={data?.data?.post_code}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'City'}
              value={data?.data?.city}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Country'}
              value={data?.data?.country}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'State Code'}
              value={data?.data?.state_code}
              loading={data?.isLoading}
            />
          </DataViewerWapper>
        </Grid.Col>
        <Grid.Col span={4}>
          <DataViewerWapper>
            <Box mb={'sm'}>
              <TitleViewer title={"Others"} />
            </Box>
            <ViewFormData
              label={'Balance (LCY)'}
              value={data?.data?.balance}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Credit Limit (LCY)'}
              value={data?.data?.credit_limit}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Salesperson Code'}
              value={data?.data?.sales_person_code}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Responsibility Center'}
              value={data?.data?.responsibility_center}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Service Zone Code'}
              value={data?.data?.service_zone_code}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Blocked'}
              value={data?.data?.blocked ? 'Yes' : 'No'}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Privacy Blocked'}
              value={data?.data?.privacy_blocked ? 'Yes' : 'No'}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Last Date Modified'}
              value={data?.data?.last_date_modified}
              loading={data?.isLoading}
            />
          </DataViewerWapper>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default GeneralInvoiceReviewComp
