import * as yup from 'yup';

export const productSchema = yup.object({
    name: yup.string().required('Please Enter product name'),
    description: yup.string().required('Please Enter product description'),
    repayment_tenure_id: yup.string().required('Please Select a Repayment type'),
    product_banks: yup
      .array()
      .required('Please Select Bank'),
    interest_rate: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .required('Please Enter Interest Rate')
      .min(0)
      .max(50, 'Maximum of 50% allowed'),
    penal_rate: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .required('Please Enter Penal Rate')
      .min(0)
      .max(50, 'Maximum of 50% allowed'),
    grace_days: yup
      .number()
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .integer()
      .min(0),
    spread_rate: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .max(50, 'Maximum of 50% allowed'),
    addon_spread_rate: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .max(50, 'Maximum of 50% allowed'),
    no_of_days_backdate: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .max(50, 'Maximum of 50 days allowed')
      .min(0)
      .integer(),
    code: yup
      .string()
      .trim()
      .strict(true)
      .matches(/^[0-9A-Z]*$/, "Only Alphanumeric are allowed")
      .test('len', 'product code must be 5 to 6 Alphanumeric', 
        (val) => val.toString().length <= 6 && val.toString().length >= 5 && !val.toString().includes(' ') && /[a-z]/i.test(val))
      .required('Please Enter product code'),
    // stakeholder_2: yup
    //   .string()
    //   .test('is-unique', 'Duplicate Stakeholder', (value, ctx) =>
    //     value != 0 || value != undefined
    //       ? ctx.parent.stakeholder_1 != value
    //       : false
    //   ),
  });