import { Autocomplete, Text, Grid, Button } from '@mantine/core';
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { dateFormat } from '../../DateFormat/dateFormat';
import Select from 'react-select';
import { getAllRegionsForFilter, getAllProductForFilter } from '../../../services/common.service';
import { customerSearch } from '../../../services/imd.service';
import { Box } from '@mantine/core';
import { DatePickerInput} from '@mantine/dates';
import { Calendar } from 'tabler-icons-react';

const styles = {
  select:{
    menu: (provided) => ({ ...provided, zIndex: 9999, }), 
    option: (provided) => ({ ...provided, fontSize: '13px', padding: '5px 15px 5px 15px' }), 
    control: (provided) => ({ ...provided, fontSize: '13px'})
  }
}

const Filter = ({ onClose, callback, loading, type, open }) => {

  const [data, setData] = useState({})
  const [searchedValue, setSearchedValue] = useState('');

  const serachQuery = useQuery(
    ["search-data", searchedValue, type],
    /*
     * sending the signal to cancel the per pending api call
     * signal will contain the abort signal
     */
    ({ signal }) => customerSearch(searchedValue, type, signal),
    {
      /** this api call will call when the searched value length is min of 2 */
      enabled: Boolean(searchedValue?.length > 2),
      refetchOnWindowFocus: false,
    }
  );

  const { data: regions = [] } = useQuery(
    ['all-regions'],
    () => getAllRegionsForFilter(),
    { enabled: Boolean(open) }
  );
  
  const { data: products = [] } = useQuery(
    ['all-products'],
    () => getAllProductForFilter(),
    { enabled: Boolean(open) }
  );

  const onSubmit = () => {
    const body = {
      ...data,
      customer_id: searchedValue.length ? parseInt(searchedValue) : undefined,
      from_date: dateFormat(data?.from_date, 'YYYY-MM-DD'),
      end_date: dateFormat(data?.end_date, 'YYYY-MM-DD')
    }
    /** used to filter region which user has selected */
    const regionLabel = regions.filter((item) => item.value == data?.region_id)
    /** used to filter product which user has selected */
    const productLabel = products.filter((item) => item?.value == data?.product_id)
    const filterResult = {
      "Customer": searchedValue.length ? searchedValue : undefined,
      "Region": data?.region_id ? regionLabel[0]?.label : undefined,
      "Product": data?.product_id ? productLabel[0]?.label : undefined,
      "From Date": data?.from_date ? dateFormat(data?.from_date, 'YYYY-MM-DD') : undefined,
      "End Date": data?.end_date ? dateFormat(data?.end_date, 'YYYY-MM-DD') : undefined,
    }
    /** 
     * body is used to get data
     * filterResult is used to show the details for the user which filter has been used  
     */
    callback(body, filterResult)
  }

  return (
    <Box sx={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '97%',
    }}>
      <Box>
        <Grid gutter='xl' mt='sm' pt={'xs'} pl={0}>
          <Grid.Col span={12} pt={'0'} pl={0}>            
            <Grid.Col pb={'0'}>
              <Text size={'xs'}>Customer Id/Name</Text>
            </Grid.Col>
            <Grid.Col pt={'0'}>
              <Autocomplete value={searchedValue} onChange={(value) => {setSearchedValue(value)}} data={serachQuery?.data || []} placeholder="Search by customer Id / Name" filter={(value, item) => item.value.includes(value.trim()) || item.label.toLowerCase().includes(value.toLowerCase().trim())} />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={6} pt={'0'} pl={0}>
            <Grid.Col pb={'0'}>
              <Text size={'xs'}>Select Product</Text>
            </Grid.Col>
            <Grid.Col pt={'0'}>
              <Select placeholder="select" options={products} onChange={(value) => setData({ ...data, product_id: value?.value,})} styles={styles.select}  />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={6} pt={'0'} pl={0}>
            <Grid.Col pb={'0'}>
              <Text size={'xs'}>Select Region</Text>
            </Grid.Col>
            <Grid.Col pt={'0'}>
              <Select placeholder="select" options={regions} onChange={(value) => setData({ ...data, region_id: value?.value,})} styles={styles.select}  />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={6} pt={'0'} pl={0}>
            <Grid.Col pb={'0'}>
              <Text size={'xs'}>From Date</Text>
            </Grid.Col>
            <Grid.Col pt={'0'}>
              <DatePickerInput icon={<Calendar  />} size={'sm'} placeholder={'select from date'} valueFormat='DD/MM/YYYY' onChange={(value) => {console.log(value); setData({ ...data, from_date: new Date(value)})}} value={data?.from_date}  />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={6} pt={'0'} pl={0}>
            <Grid.Col pb={'0'}>
              <Text size={'xs'}>To Date</Text>
            </Grid.Col>
            <Grid.Col pt={'0'}>
              <DatePickerInput icon={<Calendar  />} size={'sm'} placeholder={'select to date'} valueFormat='DD/MM/YYYY' onChange={(value) => setData({ ...data, end_date: new Date(value)})} value={data?.end_date}  />
            </Grid.Col>
          </Grid.Col>
        </Grid>
      </Box>
      <Box>
        <Box sx={{display: 'flex', padding: '20px 10px',}}>
          <Grid >
            <Grid.Col span={6} pl={0}>
              <Button onClick={onSubmit} loading={loading}>Search</Button>
            </Grid.Col>
            <Grid.Col span={6}>
              <Button variant='light' onClick={onClose}>Cancel</Button>
            </Grid.Col>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Filter;
