import { getAPICall, patchAPICall, postAPICall } from ".";

// list of all unidentified receipt 
export const getAllUnidentifiedReceipts = ({
  page,
  search,
  records = 10,
  status,
  entity,
}) => {
  let url = `/unidentified-receipt?page=${page}&records=${records}&status=${status}`
  if (search) url = url + `&unidentified_receipt_amount_date=${search}`;
  if (entity && entity !== 'all') url = url + `&entity_id=${entity}`;
  return getAPICall(
    url,
    { returnObject: true }
  );
};

// get unidentified receipt details by id
export const getUnidentifiedReceiptById = (unidentifiedReceiptId) => {
  const handleValue = (n) => {
    const result = n?.map((item) => ({
      bank: item.target_bank_name + ` (${item.target_bank_no})`,
      ...item,
    }));
    return result || [];
  };
  return getAPICall(`/unidentified-receipt/${unidentifiedReceiptId}`, { selectValue: handleValue, returnFirstItem: true });
};

/** used to get list of unidentified receipt by sending the loan id */
export const getEligibleUnidentifiedReceiptByLoanId = ({ loanId, receiptId, customerId, feeAmount, moduleName }) => {
  const handleValue = (n) => {
    const result = n?.map((item) => ({
      bank: item.target_bank_name + ` (${item.target_bank_no})`,
      ...item,
    }));
    return result || [];
  };
  let qry = `/receipt/eligible/unidentified-receipt?`;
  if (loanId) qry = qry + `&loan_id=${loanId}`;
  if (receiptId) qry = qry + `&receipt_id=${receiptId}`;
  if (customerId) qry = qry + `&customer_id=${customerId}`;
  if (feeAmount) qry = qry + `&fee_amount=${feeAmount}`;
  if (moduleName) qry = qry + `&module_name=${moduleName}`;
  return getAPICall(qry, { selectValue: handleValue });
};

// create the unidentified receipt by sending the creation details as object
export const createUnidentifiedReceipt = (body) => {
  return postAPICall(`/unidentified-receipt`, { body });
};

// move the unidentified receipt to next stage without save
export const saveAndSendUnidentifiedReceiptForNextStage = (body) => {
  return postAPICall(`/unidentified-receipt/direct_save`, { body });
};

// update the unidentified receipt by sending the unidentified receipt id and updated details
export const updateUnidentifiedReceipt = (unidentifiedReceiptId, body) => {
  return patchAPICall(`/unidentified-receipt/${unidentifiedReceiptId}`, {
    body,
  });
};

// send the unidentified receipt to the next stage by sending the unidentified receipt Id
export const sendUnidentifiedReceiptForNextStage = (
  unidentifiedReceiptId,
  body
) => {
  return postAPICall(
    `/unidentified-receipt/${unidentifiedReceiptId}/status/change`,
    { body }
  );
};

// send the unidentified receipt for cancellation by sending the unidentified receipt id and body
export const sendUnidentifiedReceiptFroCancellation = (
  unidentifiedReceiptId,
  body
) => {
  return postAPICall(
    `/unidentified-receipt/${unidentifiedReceiptId}/cancelled`,
    { body }
  );
};

// pushback the unidentified receipt by sending the unidentified receipt id and body
export const sendUnidentifiedReceiptForPushback = (
  unidentifiedReceiptId,
  body
) => {
  return postAPICall(
    `/unidentified-receipt/${unidentifiedReceiptId}/pushback`,
    { body }
  );
};

// reject the unidentified receipt by sending the unidentified receipt id and body
export const sendUnidentifiedReceiptForRejected = (
  unidentifiedReceiptId,
  body
) => {
  return postAPICall(
    `/unidentified-receipt/${unidentifiedReceiptId}/rejected`,
    { body }
  );
};

// list of unidentified receipt remarks by sending the unidentified receipt id
export const getUnidentifiedReceiptRemarksById = (
  unidentifiedReceiptId,
) => {
  return getAPICall(`/unidentified-receipt/${unidentifiedReceiptId}/remark`);
};

// this api call is used to display message if there is a receipt in between the receipt date and the system date
// this api is used in receipt module
export const unidentifiedReceiptEligibleCheck = (body) => {
  return postAPICall(`/unidentified-receipt/eligibility/check`, { body })
}

export const identifiedReceiptDetailsById = (receipt_id) => {
  return getAPICall(`/unidentified-receipt/mapped/receipt/${receipt_id}`, { returnFirstItem: true })
}
