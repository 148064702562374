import React from 'react'
import { Grid, Box } from '@mantine/core';
import DataViewerWapper from '../../../components/CommonComponents/Preview/DataViewerWapper';
import TitleViewer from '../../../components/CommonComponents/Title/TitleViewer';
import { ViewFormData } from '../../../components/CommonComponents/Preview/FilePreview';

/**
 * Renders a component to review shipping invoice data.
 *
 * @param {object} data - The shipping invoice data to display.
 */
const ShippingInvoiceReviewComp = ({ data }) => {
  return (
    <>
      <Grid gutter={'md'}>
        <Grid.Col span={6}>
          <DataViewerWapper>
            <Box mb={'sm'}>
              <TitleViewer title={"Shipping Details"} />
            </Box>
            <ViewFormData
              label={'Location Code'}
              value={data?.data?.location_code}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Combine Shipments'}
              value={data?.data?.combine_shipments ? 'Yes' : 'No'}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Reserve'}
              value={data?.data?.reserve}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Shipping Advice'}
              value={data?.data?.shipping_advice}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Shipment Method Code'}
              value={data?.data?.shipment_method_code}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Shipping Agent Code'}
              value={data?.data?.shipping_agent_code}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Shipping Agent Service Code'}
              value={data?.data?.shipping_agent_service_code}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Shipping Time'}
              value={data?.data?.shipping_time}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Base Calendar Code'}
              value={data?.data?.base_calendar_code}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Customized Calendar'}
              value={data?.data?.customized_calendar ? 'Yes' : 'No'}
              loading={data?.isLoading}
            />
          </DataViewerWapper>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default ShippingInvoiceReviewComp
