import { Box, Title, Text, createStyles } from '@mantine/core';
import React from 'react';
import LoginForm from './LoginForm';
const packageJson = require('../../../package.json');

export const loginStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundImage: 'url("https://i.imgur.com/q5fROUN.png")',
    padding: '4%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      justifyContent: 'center',
    },
  },
  text_content: {
    position: 'absolute',
    bottom: 160,
    left: 90,
    color: '#002859',
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: 'none',
    },
  },
  title_text: {
    fontSize: '2.3rem',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 600,
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      fontSize: '1.5rem',
    },
  },
  sub_text: {
    fontSize: '1.4rem',
    marginTop: 10,
    fontFamily: 'Poppins, sans-serif',
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      fontSize: '1.2rem',
      marginTop: 5,
    },
  },
  login_card: {
    width: '30%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '40px',
    paddingTop: 30,
    minHeight: '78%',
    marginTop: 60,
    marginRight: 80,
    backgroundColor: 'rgba(54, 128, 255, 0.12)',
    borderRadius: 10,
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    position: 'relative',
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      width: '40%',
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      width: '60%',
      marginRight: 0,
    },
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      width: '100%',
      marginTop: 0,
    },
  },
  logo: {
    height: 75,
    width: 70,
    marginBottom: '2rem',
  },
  logo_container: {
    display: 'flex',
    width: '100%',
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      justifyContent: 'center',
    },
  },
}));

const Login = () => {
  const { classes } = loginStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.text_content}>
        <Title className={classes.title_text}>LMS</Title>
        <Text className={classes.sub_text}>Financing Made Simple</Text>
        <Text size='xs' className={classes.ver_text}>
          <strong>v{packageJson.version}</strong>
        </Text>
      </Box>
      <LoginForm />
    </Box>
  );
};

export default Login;
