import { getAPICall } from '.';
import { dateFormat } from '../components/DateFormat/dateFormat';

/** handling the entity
 * if entity is 100 then we need to send empty to api to get all the data
 */
const handleEntity = (url, entity, name = 'entity_id') => {
  if (entity != 100 && entity != 'all' && entity) return url.concat(`${name}=${entity}`)
  else return url
}

/** get the credit book details */
export const getCreditBookDetails = (entity) => {
  let url = handleEntity('/credit_book?', entity)
  return getAPICall(url, { returnFirstItem: true });
};

/** get the credit book details */
export const getCreditBookDetailsDynamic = (entity, system_date) => {
  let url = handleEntity('/historical_credit_book?', entity)
  if (system_date) url = url + `&system_date=${system_date}`;
  return getAPICall(url, { returnFirstItem: true });
};

/** get the DPD wise data */
export const getDPDWiseData = (entity) => {
  let url = handleEntity('/dpd-wise?', entity)
  return getAPICall(url);
};

/** get the credit book details */
export const getDPDvsOMCWiseData = (entity) => {
  let url = handleEntity('/omc-vs-dpd-wise?', entity)
  return getAPICall(url);
};

/** get the region wise over due details */
export const getRegionWiseOverDue = (entity) => {
  let url = handleEntity('/region-wise-overdue?', entity)
  return getAPICall(url);
};

/** get the region vs dpd wise details */
export const getRegionVsDpDWise = (entity) => {
  let url = handleEntity('/region-vs-dpd-wise?', entity)
  return getAPICall(url);
};

/** get the projection report details */
export const getProjectionReport = (entity) => {
  let url = handleEntity('/principal-projection?', entity)
  return getAPICall(url);
};

/** list of all entities */
export const getAllEntities = () => {
  const handleValue = (data) => {
    let result = data?.map((item) => ({
      value: item?.id,
      label: item?.name,
      ...item,
    }));
    return result;
  };
  return getAPICall('/entities', { selectValue: handleValue });
};

/**
 * Retrieves the historical NPA data based on the specified parameters.
 *
 * @param {string} entity - The entity to retrieve data for.
 * @param {string} regions - The region IDs to filter the data by.
 * @param {string} from - The start date for the data range.
 * @param {string} to - The end date for the data range.
 * @param {string} zones - The zone IDs to filter the data by.
 * @param {string} filter - The filter to apply to the data.
 * @param {number} page - The page number for pagination.
 * @param {number} records - The number of records per page.
 * @param {string} search - The search term to filter the data by.
 */
export const getHistoricalNPA = ({ entity, regions, from, to, zones, filter, page, records, search, orderBy }) => {
  let query = `historical_npa?`
  let filterQuery = []
  if (regions) filterQuery.push(`region_id=${regions}`);
  if (zones) filterQuery.push(`zone_id=${zones}`);
  if (from) filterQuery.push(`system_date=${dateFormat(from, 'YYYY-MM-DD')}`);
  if (filter) filterQuery.push(`filter=${filter}`);
  if (page) filterQuery.push(`page=${page}`);
  if (records) filterQuery.push(`records=${records}`);
  if (search?.length > 3) filterQuery.push(`customer_id_name=${search}`);
  if (orderBy) filterQuery.push(`order_by=${orderBy}`);
  return getAPICall(handleEntity(query, entity) + '&' + filterQuery?.join('&'), { returnObject: true });
}

export const getHistoricalWriteOff = ({ entity, regions, from, to, zones, filter, page, records, search, orderBy }) => {
  let query = `historical/write-off?`
  let filterQuery = []
  if (regions) filterQuery.push(`region_id=${regions}`);
  if (zones) filterQuery.push(`zone_id=${zones}`);
  if (from) filterQuery.push(`system_date=${dateFormat(from, 'YYYY-MM-DD')}`);
  if (filter) filterQuery.push(`filter=${filter}`);
  if (page) filterQuery.push(`page=${page}`);
  if (records) filterQuery.push(`records=${records}`);
  if (search?.length > 3) filterQuery.push(`customer_id_name=${search}`);
  if (orderBy) filterQuery.push(`order_by=${orderBy}`);
  return getAPICall(handleEntity(query, entity) + '&' + filterQuery?.join('&'), { returnObject: true });
}

// get the historical loan book information
export const getHistoricalLoanBook = ({ entity, regions, from, to, zones }) => {
  let query = `historical_loan_book?`
  let filterQuery = []
  if (regions) filterQuery.push(`region_id=${regions}`);
  if (zones) filterQuery.push(`zone_id=${zones}`);
  if (from) filterQuery.push(`system_date=${dateFormat(from, 'YYYY-MM-DD')}`);
  return getAPICall(handleEntity(query, entity) + '&' + filterQuery?.join('&'));
}

// get the historical loan book information
export const getCustomerApplicationStatus = ({ entity, regions, from, to, zones }) => {
  let query = `customer-application-stats?`
  let filterQuery = []
  if (regions) filterQuery.push(`region=${regions}`);
  if (zones) filterQuery.push(`zone=${zones}`);
  if (from) filterQuery.push(`from_date=${dateFormat(from, 'YYYY-MM-DD')}`);
  if (to) filterQuery.push(`to_date=${dateFormat(to, 'YYYY-MM-DD')}`);
  return getAPICall(handleEntity(query, entity, 'entity') + '&' + filterQuery?.join('&'), { returnObject: true });
}

/**
 * Retrieves loan statistics based on the provided parameters.
 *
 * @param {Object} entity - The entity object.
 * @param {string} entity.entityId - The ID of the entity.
 * @param {string} entity.entityType - The type of the entity.
 * @param {Array} regions - An array of region IDs.
 * @param {string} from - The start date for the statistics.
 * @param {string} to - The end date for the statistics.
 * @param {Array} zones - An array of zone IDs.
 * @param {string} filter - The filter to apply.
 * @param {number} page - The page number.
 * @param {number} records - The number of records per page.
 * @param {string} search - The search query.
 * @return {Promise} A promise that resolves with the loan statistics.
 */
export const getLoanStats = ({ entity, regions, from, to, zones, filter, page, records, search }) => {
  let query = `loans-advance-interest-stats?`
  let filterQuery = []
  if (regions) filterQuery.push(`region_id=${regions}`);
  if (zones) filterQuery.push(`zone_id=${zones}`);
  if (from) filterQuery.push(`from_date=${dateFormat(from, 'YYYY-MM-DD')}`);
  if (to) filterQuery.push(`to_date=${dateFormat(to, 'YYYY-MM-DD')}`);
  if (filter) filterQuery.push(`filter=${filter}`);
  if (page) filterQuery.push(`page=${page}`);
  if (records) filterQuery.push(`records=${records}`);
  if (search?.length > 3) filterQuery.push(`customer_id_name=${search}`);
  return getAPICall(handleEntity(query, entity) + '&' + filterQuery?.join('&'), { returnObject: true });
}

/**
 * Retrieves statistics for receipts based on the provided parameters.
 *
 * @param {string} entity - The entity for which the statistics are being retrieved.
 * @param {string} regions - The ID of the region for which the statistics are being retrieved.
 * @param {string} from - The start date for the statistics.
 * @param {string} to - The end date for the statistics.
 * @param {string} zones - The ID of the zone for which the statistics are being retrieved.
 * @param {string} filter - The filter to be applied to the statistics.
 * @param {number} page - The page number for paginated results.
 * @param {number} records - The number of records per page.
 * @param {string} search - The search string for filtering results.
 */
export const getReceiptStats = ({ entity, regions, from, to, zones, filter, page, records, search }) => {
  let query = `receipts-stats?`
  let filterQuery = []
  if (regions) filterQuery.push(`region_id=${regions}`);
  if (zones) filterQuery.push(`zone_id=${zones}`);
  if (from) filterQuery.push(`from_date=${dateFormat(from, 'YYYY-MM-DD')}`);
  if (to) filterQuery.push(`to_date=${dateFormat(to, 'YYYY-MM-DD')}`);
  if (filter) filterQuery.push(`filter=${filter}`);
  if (page) filterQuery.push(`page=${page}`);
  if (records) filterQuery.push(`records=${records}`);
  if (search?.length > 3) filterQuery.push(`customer_id_name=${search}`);
  return getAPICall(handleEntity(query, entity) + '&' + filterQuery?.join('&'), { returnObject: true });
}

/**
 * Retrieves fee statistics based on the provided parameters.
 *
 * @param {object} entity - The entity object.
 * @param {number[]} regions - An array of region IDs.
 * @param {string} from - The start date of the requested statistics.
 * @param {string} to - The end date of the requested statistics.
 * @param {number[]} zones - An array of zone IDs.
 * @param {string} filter - The filter for the statistics.
 * @param {number} page - The page number for pagination.
 * @param {number} records - The number of records per page.
 * @param {string} search - The search query for customer ID or name.
 * @return {string} The API call result for the fee statistics.
 */
export const getFeeStats = ({ entity, regions, from, to, zones, filter, page, records, search }) => {
  let query = `fee-stats?`
  let filterQuery = []
  if (regions) filterQuery.push(`region_id=${regions}`);
  if (zones) filterQuery.push(`zone_id=${zones}`);
  if (from) filterQuery.push(`from_date=${dateFormat(from, 'YYYY-MM-DD')}`);
  if (to) filterQuery.push(`to_date=${dateFormat(to, 'YYYY-MM-DD')}`);
  if (filter) filterQuery.push(`filter=${filter}`);
  if (page) filterQuery.push(`page=${page}`);
  if (records) filterQuery.push(`records=${records}`);
  if (search?.length > 3) filterQuery.push(`customer_id_name=${search}`);
  return getAPICall(handleEntity(query, entity) + '&' + filterQuery?.join('&'), { returnObject: true });
}

/** used to get income of gmfvl  */
export const getEntitiesIncomeDetails = ({ entity, regions, from, to, zones }) => {
  let query = `entity-income?`;
  let filterQuery = []
  if (regions) filterQuery.push(`region_id=${regions}`);
  if (zones) filterQuery.push(`zone_id=${zones}`);
  if (from) filterQuery.push(`from_date=${dateFormat(from, 'YYYY-MM-DD')}`);
  if (to) filterQuery.push(`to_date=${dateFormat(to, 'YYYY-MM-DD')}`);
  return getAPICall(handleEntity(query, entity) + '&' + filterQuery?.join('&'));
}

export const getCreditMISStatsMetaData = ({ entity, regions, from, to, zones, key, page = 1, records = 10, search }) => {
  let url = `/customer-application-stats-meta?page=${page}&records=${records}&queue_name=${key}&`;
  let filterQuery = []
  if (regions) filterQuery.push(`region_id=${regions}`);
  if (zones) filterQuery.push(`zone_id=${zones}`);
  if (from) filterQuery.push(`from_date=${dateFormat(from, 'YYYY-MM-DD')}`);
  if (to) filterQuery.push(`to_date=${dateFormat(to, 'YYYY-MM-DD')}`);
  if (search?.length > 3) filterQuery.push(`customer_id_name=${search}`);
  return getAPICall(handleEntity(url, entity) + '&' + filterQuery?.join('&'));
}