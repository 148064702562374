import React, { useState } from "react";
import { Divider, Tabs, Button, Stack, Group, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import ReactTable from "../../../../components/CommonComponents/Table/ReactTable";
import { getHolidayMaster, getHolidayById, getOfficeHolidays } from '../../../../services/master.service';
import Holiday from './Holiday';
import Body from '../../../../components/Body/Body';
import { displayNotification } from "../../../../components/CommonComponents/Notification/displayNotification";
import { DrawerModal } from "../../../../components/CommonComponents/Drawer/DrawerModal";
import { Asset, BuildingWarehouse } from "tabler-icons-react";
import OfficeHoliday from "./OfficeHoliday";

const HolidayTable = () => {
  const columnHelper = createColumnHelper();
  const [loading, setLoading] = useState(false);
  const [getHolidayName, setGetHolidayName] = useState([]);
  const [modalObj, setModalObj] = useState({});
  const [holidayForm, formHandler] = useDisclosure(false);
  /* use-disclosure hook manages boolean state. It provides open, close and toggle */

  /** used to get the PUBLIC holiday */
  const { data: publicHoliday = [], isLoading: publicHolidayLoading } = useQuery(
    ["master-holiday"],
    () => getHolidayMaster(),
  );

  /** used to get the OFFICE holiday */
  const { data: officeHoliday = [], isLoading: officeHolidayLoading } = useQuery(
    ["office-holiday"],
    () => getOfficeHolidays(),
  );

  /** used to call the function using the country and state or country to get holiday for that state or country */
  const getHoliday = (value) => {
    setLoading(true)
    getHolidayById(value?.state)
      .then((res) => {
        setGetHolidayName(res?.data)
        setLoading(false)
      })
      .catch((e) => {
        displayNotification({ message: e || e.message, variant: 'error' })
        setLoading(false)
      })
  }

  const publicColumnData = [
    columnHelper.accessor("state", {
      header: "State",
      cell: (value) => (
        <span
          style={{ color: "#0063FF", cursor: "pointer" }}
          onClick={() => {
            getHoliday(value.row.original);
            formHandler.open();
          }}
        >
          {value.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor("no_of_holidays", {
      header: "No.of Days",
    }),
  ];

  const officeColumnData = [
    columnHelper.accessor("date", {
      header: "Date",
      cell: (value) => (
        <span
          style={{ color: "#0063FF", cursor: "pointer" }}
          onClick={() => {
            setModalObj({ value: value.row.original, type: 'edit' });
            formHandler.open();
          }}
        >
          {value.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor("description", {
      header: "Description",
    }),
  ];

  const handleClose = () => {
    formHandler.close();
    setGetHolidayName()
  };

  return (
    <Body>

      <Tabs color='teal' defaultValue='office'>
        <Tabs.List>
          <Tabs.Tab value='office' icon={<BuildingWarehouse size={16} />}>
            Office Holiday
          </Tabs.Tab>

          <Tabs.Tab value='public' icon={<Asset size={16} />}>
            Public Holiday
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='office' pt='xs'>
          <Stack align="flex-end">
            <Button variant="light" onClick={() => { setModalObj({ type: 'create' }); formHandler.open() }} color="teal">Create</Button>
          </Stack>
          {officeHoliday?.length
            ? <ReactTable
              title="Office Holiday"
              rowData={officeHoliday}
              columnData={officeColumnData}
              loading={officeHolidayLoading}
            />
            : <Group position="center" my={'md'}><Text size={'sm'} color={'gray'}>No data to display!</Text></Group>
          }
        </Tabs.Panel>

        <Tabs.Panel value='public' pt='xs'>
          <ReactTable
            title="Public Holiday"
            rowData={publicHoliday}
            columnData={publicColumnData}
            loading={publicHolidayLoading}
          />
        </Tabs.Panel>

      </Tabs>

      <DrawerModal
        open={holidayForm}
        onClose={() => {
          handleClose();
        }}
        position="right"
        padding="xl"
        title={'Holidays'}
        overlayProps={{ blur: 2 }}
        size="43%"
      >
        <Divider />

        {!modalObj?.type
          ? <Holiday holiday={getHolidayName} loading={loading} />
          : <OfficeHoliday apiValue={modalObj?.value} type={modalObj?.type} onClose={() => { formHandler.close(); setModalObj({}) }} />
        }

      </DrawerModal>
    </Body>
  );
};

export default HolidayTable;
