import { Box, Button, Group, Skeleton, Table, Text, TextInput, Timeline, Title, Tooltip, createStyles } from "@mantine/core";
import { dateFormat } from "../../../../components/DateFormat/dateFormat";
import { COLORS } from "../../../../constants";
import { Edit } from "tabler-icons-react";
import DialogModal from "../../../../components/CommonComponents/Modal/DialogModal";
import { useState } from "react";
import TextFieldEditor from "../../../../components/CommonComponents/TextEditor/TextFieldEditor";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAllCancellationDetails, getCancellationRemarksByModuleName, updateCancellationPeriods } from "../../../../services/masterCancellation.services";
import { displayNotification } from "../../../../components/CommonComponents/Notification/displayNotification";

const useStyles = createStyles(() => ({
  remarksList: {
    height: 300,
    overflowY: 'scroll',
  }
}));

const MasterCancellation = () => {
  const { classes } = useStyles();
  const [modalObj, setModalObj] = useState({});

  const getCancellationDetailsQuery = useQuery({
    queryKey: ['cancellation-periods'],
    queryFn: () => getAllCancellationDetails(),
  });

  const getCancellationRemarksByModuleQuery = useQuery({
    queryKey: ['cancellation-periods-remarks', modalObj?.module_name],
    queryFn: () => getCancellationRemarksByModuleName({ module_name: modalObj?.module_name }),
    enabled: Boolean(modalObj?.type === 'remarks' && modalObj?.module_name),
  });

  const updateCancellationQuery = useMutation((body) => updateCancellationPeriods(body));

  const updateCancellation = () => {
    if (!modalObj?.remarks || modalObj?.remarks === '') {
      setModalObj((old) => ({ ...old, error: true }));
      return;
    }
    const body = {
      module_name: modalObj?.module_name,
      remarks: modalObj?.remarks,
      new_point_of_no_return: modalObj?.new_point_of_no_return
    };
    updateCancellationQuery.mutate(body, {
      onSuccess: () => {
        displayNotification({
          message: `Record Updated Successfully`,
          variant: 'success',
        });
        setModalObj((old) => ({ ...old, modal: false }));
        getCancellationDetailsQuery?.refetch();
      },
      onError: (e) => {
        displayNotification({
          message: e?.message || e,
          variant: 'error',
        });
      },
    });
  }


  return (
    <>
      <Box>
        {!getCancellationDetailsQuery?.isLoading && !getCancellationDetailsQuery?.data?.length
          ? <center><Text c={'#ccc'} size={'sm'} mt={'xl'}>No Data to Display</Text></center>
          : <Table horizontalSpacing="md" striped highlightOnHover withRowBorders={true} fontSize={'xs'}>
            <thead style={{ backgroundColor: COLORS.primary.light1 }}>
              <tr>
                <th>Module Name</th>
                <th>Cancellation Days</th>
                <th>Last Updated By</th>
                <th>Last Updated On</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {getCancellationDetailsQuery?.isLoading
                ? [1, 2, 3, 4, 5]?.map((item) => (
                  <tr>
                    <td><Skeleton height={20} /></td>
                    <td><Skeleton height={20} /></td>
                    <td><Skeleton height={20} /></td>
                    <td><Skeleton height={20} /></td>
                    <td><Skeleton height={20} /></td>
                  </tr>
                ))
                : getCancellationDetailsQuery?.data?.map((item, index) => (
                  <tr key={index}>
                    <td style={{ textTransform: 'capitalize' }}>{item?.module_name?.replace(/_/g, ' ')}</td>
                    <td>{item?.new_point_of_no_return ? item?.new_point_of_no_return + ' Days' : '-'}</td>
                    <td>{item?.created_by}</td>
                    <td><Tooltip withArrow label={<Text size={'xs'}>Click to view remarks</Text>} color={'gray'}><span style={{ cursor: 'pointer', color: '#0063ff' }} onClick={() => setModalObj({ modal: true, type: 'remarks', ...item, remarks: null })}>{dateFormat(item?.created_date, 'DD MMM YYYY')}</span></Tooltip></td>
                    <td style={{ width: 10 }}><Edit style={{ cursor: 'pointer' }} size={14} color="blue" onClick={() => { setModalObj({ modal: true, ...item, remarks: null }) }} /></td>
                  </tr>
                ))}
            </tbody>
          </Table>}
      </Box>
      <DialogModal
        open={modalObj?.modal || false}
        onClose={() => { setModalObj((old) => ({ ...old, modal: false })) }}
        title={<Title order={6} style={{ textTransform: 'capitalize' }}>{modalObj?.type === 'remarks' ? `${modalObj?.module_name?.replace(/_/g, " ")} Cancellation Period Remarks` : `Update ${modalObj?.module_name?.replace(/_/g, " ")} Cancellation Period`}</Title>}
        size={'lg'}
        centered
      >
        {modalObj?.type === 'remarks'
          ? <>
            <Box className={classes.remarksList}>
              <Timeline active={-1} lineWidth={3} bulletSize={14}>
                {getCancellationRemarksByModuleQuery?.isLoading
                  ? [1, 2, 3, 4, 5, 6, 7, 8]?.map((item) => (
                    <Box mt={'20px'} key={item}>
                      <Group position='apart'>
                        <Skeleton height={10} width={'30%'} radius="xl" />
                        <Skeleton height={10} width={'20%'} radius="xl" />
                      </Group>
                      <Skeleton height={10} mt={10} radius="xl" />
                    </Box>))
                  : getCancellationRemarksByModuleQuery?.data?.map((item, index) => (
                    <Timeline.Item
                      title={
                        <>
                          <Group position='apart'>
                            <Text color={COLORS.gray(40)} size={'xs'}>
                              {dateFormat(item?.created_date, 'DD MMM YYYY - hh:mm A')}
                            </Text>
                          </Group>
                          <Text color={COLORS.gray(60)} size={'xs'}>{`${item?.created_by} ${item?.old_point_of_no_return > item?.new_point_of_no_return ? 'decreased' : 'increased'} the period from ${item?.old_point_of_no_return} to ${item?.new_point_of_no_return}`}</Text>
                        </>
                      }
                      key={index}
                    >
                      <Text
                        size='xs'
                        sx={{
                          maxWidth: '80%',
                          maxHeight: 90,
                          overflow: 'hidden',
                        }}
                        dangerouslySetInnerHTML={{ __html: item?.remarks }}
                      />
                    </Timeline.Item>
                  ))
                }
              </Timeline>
            </Box>
          </>
          : <>
            <Text size={'xs'} c={'gray'}>No Of Days to Cancel</Text>
            <TextInput type={'number'} value={modalObj?.new_point_of_no_return} onChange={(e) => setModalObj((old) => ({ ...old, new_point_of_no_return: e.target.value }))} />
            <Text size={'xs'} c={'gray'} mt={'md'}>Remarks</Text>
            <TextFieldEditor value={modalObj?.remarks} onChange={(e) => setModalObj((old) => ({ ...old, remarks: e }))} />
            {(modalObj?.error && (!modalObj?.remarks || modalObj?.remarks === '')) ? <Text size={'xs'} c={'red'}>Please Enter Remarks</Text> : null}
            <Group mt={'md'} position="right">
              <Button variant="outline" onClick={() => { setModalObj((old) => ({ ...old, modal: false })) }}>Cancel</Button>
              <Button color="green" loading={updateCancellationQuery?.isLoading} onClick={() => { updateCancellation() }}>Save</Button>
            </Group>
          </>
        }
      </DialogModal>
    </>
  )
};

export default MasterCancellation;