import { Box, Modal, Title, Text, Tooltip, ActionIcon } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { LayoutColumns } from "tabler-icons-react";
import { COLORS } from "../../../constants";
import FilterModal from "../Modal/FilterModal";
import { displayNotification } from "../Notification/displayNotification";
import DialogModal from "../Modal/DialogModal";

const ColumnsFilter = ({
  title,
  columnData=[],
  filteredColumnData,
  updateFilter
}) => {
  const [openFilterModal, { open, close }] = useDisclosure(false);

  const onUpdateColumn = (data) => {
    if(data?.length) {
      // callback to update localstorage and table state
      updateFilter(data)
      close();
    }
    else {
      displayNotification({ message: 'Select columns to update', variant: 'warning' })
    }
  }

  return (
    <Box>
      <Tooltip
        label={<Text size={"xs"}>Manage Columns</Text>}
        color={"dark"}
        transitionProps={{ transition: "pop", duration: 300 }}
        withArrow
      >
        <ActionIcon onClick={open}>
          <LayoutColumns />
        </ActionIcon>
      </Tooltip>
      {
        columnData?.length ? (
          <DialogModal
            size={'xl'}
            open={openFilterModal}
            onClose={close}
            lockScroll
            title={
              <div>
                <Title order={3}>Manage columns</Title>
                <Text color={COLORS.text.light1} size={'sm'}>
                  Select the columns you would like to see on {title}
                </Text>
              </div>
            }
            sx={{overflow: 'hidden'}}
            styles={(them) => ({
              root: {
                overflow: 'hidden',
              },
              content: {
                overflowY: "hidden",
              }
            })}
          >
            <FilterModal
              filteredColumnData={filteredColumnData}
              columnData={columnData}
              onUpdateColumn={onUpdateColumn}
              onClose={close}
            />
          </DialogModal>
        ) : null
      }
    </Box>
  )
}

export default ColumnsFilter;