import { Box, Divider, Grid, Table } from '@mantine/core'
import React, { useEffect } from 'react'
import Nums from '../../Body/Nums';
import { dateFormat } from '../../DateFormat/dateFormat';
import { COLORS } from '../../../constants';
import TitleViewer from '../Title/TitleViewer';
import { useListState } from '@mantine/hooks';
import { DrawerModal } from '../Drawer/DrawerModal';

const SideDrawerRenewal = ({open, onClose, data, checkedData, payHead, avaliableHead}) => {

    const [verticalSelection, verticalHandlers] = useListState([false,false, false, false, false, false])

    useEffect(() => {
        const totalPayHead = ['outstanding_principle', 'outstanding_interest', 'outstanding_spread', 'outstanding_addon_spread', 'outstanding_interest_amount_during_penalty', 'outstanding_penal_interest_amount']
        checkedData?.forEach((item, i) => {
            const values = payHead?.length ? Object.values(payHead[i])[0] : null
            values?.map((val, index) => {
                verticalHandlers.applyWhere(
                (item, index) => index == totalPayHead?.indexOf(val),
                () => (true)
                );
            })
        })
    }, [open == true])
    /** this function is used to handle the payhead total value of selected amount */
    const handleSelectedTranchePayheadAmount = (target_val) => {
        let val = 0;
        let values = [];
        /** used to handle the payhead wise total */
        if(payHead) {
            checkedData?.forEach((keys, index) => {
                data?.forEach((item, i) => {
                  /** used to take the array of values */
                  values = payHead?.length > index ? Object.values(payHead[index])[0] : []
                  /*
                   * if penal f means it will not check the values array else it will  
                  */
                  if(target_val == 'penal_f' && values?.includes('outstanding_interest_amount_during_penalty')) {
                    if(keys == item?.id){
                      val = item[target_val] + val;
                    }
                  }else {
                    if(keys == item?.id && values?.includes(target_val)){
                      val = item[target_val] + val;
                    }
                  }
                })
            })
        }
        return Math.round(val * 100)/100 || 0
    }

    /** this function is used to handle the total value of selected pay amount */
    const handleSelectedTrancheTotalAmount = () => {
        let total = 0
        let values = [];
        const handleSubTotalforTable = (arr) => {
            checkedData?.forEach((keys, index) => {
                data?.forEach((item, i) => {
                    let val = 0
                    values = arr?.length > index ? Object.values(arr[index])[0] : []
                    if(keys == item?.id){
                        values?.map((payhead) => {
                            val = item[payhead] + val;
                        })
                        total = val + total;
                        return;
                    }
                })
            })
        }
        if(payHead?.length) {
            handleSubTotalforTable(payHead)
        }
        return total;
    };

  return (
    <>
      <DrawerModal
        open={open}
        onClose={() => onClose(false)}
        title="Loan Details"
        position='right'
        padding='xl'
        size='75%'
      >          
        <Divider  />
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: '90%',
          }}
          my={'md'}
        >
          <Box sx={{ flex: 1, overflow: 'auto', overflowX: 'hidden' }}>
            <Grid>                
                <Grid.Col span={12}>
                    <Table striped highlightOnHover withColumnBorders withBorder fontSize='xs'  mt={'xs'} verticalSpacing='xs'>
                        <thead style={{backgroundColor: COLORS.primary.light1}}>
                            <tr>
                                <th>Loan Code</th>
                                <th>Loan Type</th>
                                <th style={{textAlign: 'right'}}>Loan Amount</th>
                                <th>Disb Date</th>
                                <th>Receipt Date</th>
                                <th>Due Date</th>
                                {avaliableHead?.dpd ? <th>DPD Days</th> : null}
                                {avaliableHead?.if_principle && verticalSelection[0] ? <th style={{textAlign: 'right'}}>Principal</th> : null}
                                {avaliableHead?.if_interest && verticalSelection[1] ? <th style={{textAlign: 'right'}}>Interest</th> : null}
                                {avaliableHead?.if_spread && verticalSelection[2] ? <th style={{textAlign: 'right'}}>Spread</th> : null}
                                {avaliableHead?.if_addon_spread && verticalSelection[3] ? <th style={{textAlign: 'right'}}>AddOn Spread</th> : null}
                                {avaliableHead?.if_penal_f && verticalSelection[4] ? <th style={{textAlign: 'right'}}>Interest F1</th> : null}
                                {avaliableHead?.if_penal && verticalSelection[5] ? <th style={{textAlign: 'right'}}>Penal F2</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                        {data?.map((item) => checkedData?.includes(item?.id) ? 
                            <tr>
                                <td>{item?.prospect_code}</td>
                                <td>{item?.loan_type == 'new' ? 'New' : item?.loan_type == 'revolving' ? 'Revolving' : null}</td>
                                <td style={{textAlign: 'right'}}><Nums value={item?.loan_amount}  /></td>
                                <td>{dateFormat(item?.disbursed_date)}</td>
                                <td>{item?.receipt_date ? dateFormat(item?.receipt_date) : '-'}</td>
                                <td>{dateFormat(item?.due_date)}</td>
                                {avaliableHead?.dpd ? <td>{item?.days_past_due}</td> : null}
                                {avaliableHead?.if_principle && verticalSelection[0] ? <td style={{textAlign: 'right'}}><Nums value={item?.principle_selected ? item?.outstanding_principle : '-'}  /></td> : null}
                                {avaliableHead?.if_interest && verticalSelection[1] ? <td style={{textAlign: 'right'}}><Nums value={item?.outstanding_interest} /></td> : null}
                                {avaliableHead?.if_spread && verticalSelection[2] ? <td style={{textAlign: 'right'}}><Nums value={item?.outstanding_spread}/></td> : null}
                                {avaliableHead?.if_addon_spread && verticalSelection[3] ? <td style={{textAlign: 'right'}}><Nums value={item?.outstanding_addon_spread}/></td> : null}
                                {avaliableHead?.if_penal_f && verticalSelection[4] ? <td style={{textAlign: 'right'}}><Nums value={item?.penal_f}/></td> : null}
                                {avaliableHead?.if_penal && verticalSelection[5] ? <td style={{textAlign: 'right'}}><Nums value={item?.outstanding_penal_interest_amount}/></td> : null}
                            </tr> : null
                        )}
                        <tr  style={{ textAlign: 'right', backgroundColor: COLORS.primary.light2 }}>
                            <td colSpan={avaliableHead?.dpd ? "5" : "4"}></td>
                            <td colSpan="2"><strong>SubTotal</strong></td>
                            {avaliableHead?.if_principle && verticalSelection[0] ?
                                <td style={{ textAlign: 'right', fontWeight: '600' }}>
                                    <Nums value={handleSelectedTranchePayheadAmount('outstanding_principle')}   />
                                </td> : null
                            }
                            {avaliableHead?.if_interest && verticalSelection[1] ?
                                <td style={{ textAlign: 'right', fontWeight: '600' }}>
                                    <Nums value={handleSelectedTranchePayheadAmount('outstanding_interest')} />
                                </td> : null
                            }
                            {avaliableHead?.if_spread && verticalSelection[2] ? 
                                <td style={{ textAlign: 'right', fontWeight: '600' }}>
                                    <Nums value={handleSelectedTranchePayheadAmount('outstanding_spread')} />
                                </td> : null
                            } 
                            {avaliableHead?.if_addon_spread && verticalSelection[3] ? 
                                <td style={{ textAlign: 'right', fontWeight: '600' }}>
                                    <Nums value={handleSelectedTranchePayheadAmount('outstanding_addon_spread')} />
                                </td> : null
                            }
                            {avaliableHead?.if_penal_f && verticalSelection[4] ? 
                                <td style={{ textAlign: 'right', fontWeight: '600' }}>
                                    <Nums value={handleSelectedTranchePayheadAmount('penal_f')} />
                                </td> : null
                            }
                            {avaliableHead?.if_penal && verticalSelection[5] ? 
                                <td style={{ textAlign: 'right', fontWeight: '600' }}>
                                    <Nums value={handleSelectedTranchePayheadAmount('outstanding_penal_interest_amount')} />
                                </td>: null
                            }
                        </tr>
                        </tbody>
                    </Table>
                </Grid.Col>
                <Grid.Col span={4}>
                    <TitleViewer title={'Summary'}  />
                    <Table  withColumnBorders striped withBorder fontSize='xs'  mt={'xs'} verticalSpacing='xs'>
                        <thead>
                            <tr>
                                <th>Pay Head</th>
                                <th style={{ textAlign: 'right' }}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {avaliableHead?.if_principle ?
                                <tr>
                                    <td>Principal</td>
                                    <td style={{ textAlign: 'right' }}><Nums value={handleSelectedTranchePayheadAmount('outstanding_principle')} /></td>
                                </tr> : null
                            }
                            {avaliableHead?.if_interest ?
                                <tr>
                                    <td>Interest</td>
                                    <td style={{ textAlign: 'right' }}><Nums value={handleSelectedTranchePayheadAmount('outstanding_interest')} /></td>
                                </tr> : null
                            }
                            {avaliableHead?.if_spread ?<tr>
                                <td>Spread</td>
                                    <td style={{ textAlign: 'right' }}><Nums value={handleSelectedTranchePayheadAmount('outstanding_spread')} /></td>
                                </tr> : null
                            }
                            {avaliableHead?.if_addon_spread ?<tr>
                                <td>AddOn Spread</td>
                                    <td style={{ textAlign: 'right' }}><Nums value={handleSelectedTranchePayheadAmount('outstanding_addon_spread')} /></td>
                                </tr> : null
                            }
                            {avaliableHead?.if_penal_f ?<tr>
                                <td>Peanl F1</td>
                                    <td style={{ textAlign: 'right' }}><Nums value={handleSelectedTranchePayheadAmount('penal_f')} /></td>
                                </tr> : null
                            }
                            {avaliableHead?.if_penal ?<tr>
                                <td>Penal F2</td>
                                    <td style={{ textAlign: 'right' }}><Nums value={handleSelectedTranchePayheadAmount('outstanding_penal_interest_amount')} /></td>
                                </tr> : null
                            }
                            <tr style={{backgroundColor: COLORS.primary.light1}}>
                                <td><strong>Total</strong></td>
                                <td style={{ textAlign: 'right' }}><Nums value={handleSelectedTrancheTotalAmount()} /></td>
                            </tr>
                        </tbody>
                    </Table>
                </Grid.Col>
            </Grid>            
          </Box>
        </Box>
      </DrawerModal>
    </>
  )
}

export default SideDrawerRenewal
