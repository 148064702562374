import { Help, Logout, Settings, User, UserSearch } from 'tabler-icons-react';
import {
  Box,
  Title,
  ActionIcon,
  Header,
  Avatar,
  Menu,
  Text,
  Tooltip,
  Badge,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import topBar from './Topbar.styles';
import { dateFormat } from '../DateFormat/dateFormat';
import { TimeFormat } from '../CommonComponents/TimeFormat/TimeFormat';
import useAuthStore from '../../store/authStore';
import useSystemConfigStore from '../../store/systemConfigStore';
import CustomerSearchModal from './CustomerSearchModal';
import { AnimatePresence, motion } from 'framer-motion';

const Topbar = () => {
  const navigate = useNavigate();
  // Web sockets has been stopped due to some server error
  // const startWebSocket = useWebSockets();
  const { auth, logout } = useAuthStore(store => ({
    auth: store.auth,
    logout: store.resetAuth
  }));
  const [time, setTime] = useState(Date.now());
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const systemDate = useSystemConfigStore(store => store.config);

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 900);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Header height={60} sx={topBar.root} p={22}>
        <Box sx={topBar.heroText} onClick={() => navigate('/fee-invoice')}>
          <img alt='petromoney-logo' width={35} src='/images/logo-white.png' />
          <Title order={3} sx={{ marginLeft: 12 }}>
            <span style={topBar.petro}>Petro</span>
            <span style={topBar.money}>money</span>
          </Title>
          {["development", "uat", "stage"].includes(process.env.REACT_APP_ENV) 
            ? <Badge ml={'50px'} color="red" size="xl" radius="lg" variant="filled">{process.env.REACT_APP_ENV}</Badge> 
            : null
          }
        </Box>
        <Box sx={topBar.iconsGroup}>
          <Box
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', columnGap: '5px' }}
            mr='sm'
          >
            <Text size={'xs'} weight={'600'}>System Date: </Text>
            <Text size={'xs'} weight={'600'}>
              {dateFormat(systemDate?.current_system_date, 'MMM D YYYY')} {}
            </Text>
          </Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', columnGap: '5px' }}
            mr='sm'
          >
            <Text size={'xs'} color={'gray'} weight={'600'}>Calendar Time: </Text>
            <Text size={'xs'} color={'gray'} sx={{width: '150px'}}>
              {dateFormat(time, 'MMM D YYYY')} {TimeFormat(time, 'hh:mm:ss')}
            </Text>
          </Box>
          <ActionIcon
            onClick={() => setCustomerModalOpen(true)}
            
          >
            <Tooltip label={'Customer Search'} withArrow position='bottom' color={'gray'}>
              <span>
                <UserSearch size={18} />
              </span>
            </Tooltip>
          </ActionIcon>

          <ActionIcon onClick={() => navigate('settings/account')}>
            <Tooltip label={'Settings'} withArrow position='bottom' color={'gray'}>
              <span>
                <Settings size={18} />
              </span>
            </Tooltip>
          </ActionIcon>
          <ActionIcon>
            <Help size={18} />
          </ActionIcon>
          <Menu width={180} position='bottom-end' withArrow>
            <Menu.Target>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <Text size='xs' px='xs'>
                  {auth?.first_name}
                </Text>
                <ActionIcon variant='transparent'>
                  <Avatar radius='xl' size='sm' />
                </ActionIcon>
              </Box>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>User Settings</Menu.Label>
              <Menu.Item icon={<User size={16} />} onClick={() => navigate('/settings/account')}>Profile</Menu.Item>
              <Menu.Item icon={<Logout size={16} />} onClick={() => logout()}>
                Logout
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Box>
      </Header>
      <AnimatePresence>
        {customerModalOpen ? <CustomerSearchModal  open={customerModalOpen} onClose={() => setCustomerModalOpen(false)}  /> : null}
      </AnimatePresence>
    </>
  );
};

export default Topbar;
