import { getAPICall, patchAPICall, postAPICall } from '.';

export const getApprovedDisb = (body = {}) => {
  /** used to get the approved disb */
  return postAPICall("approved/disbursements", { body: body });
};

export const getApprovedDisbById = (id) => {
  /** used to get the approved disb by id */
  return getAPICall(`disbursement/${id}`, { returnFirstItem: true });
};

export const getAllPayments = ({ page, search, reload_type, records = 10, status, entity }) => {
  /** used to get all payments for table */
  let url = `payments?&page=${page}&records=${records}&status=${status}`
  if (search) url = url + `&customer_id_name=${search}`;
  if (reload_type && reload_type !== 'all') url = url + `&reload_type=${reload_type}`;
  if (entity && entity !== 'all') url = url + `&entity_id=${entity}`;
  return getAPICall(url, { returnObject: true });
};

export const getPaymentById = (id) => {
  /** used to get the payment by id */
  return getAPICall(`payment/${id}`, { returnFirstItem: true });
};

export const PaymentCreationSave = (body) => {
  /** used to create the payment */
  return postAPICall(`payment`, { body: body });
};

export const savePaymentAndsendForNextStage = (body) => {
  /** used to send the payment for next stage */
  return postAPICall(`payment/direct_save`, { body: body });
};

export const PaymentPatch = (id, body) => {
  /** used to update the payment */
  return patchAPICall(`payment/${id}`, { body: body });
};

export const sendPaymentforNextStage = (id, body) => {
  /** used to send the payment for next stage by id */
  return postAPICall(`payment/${id}/status/change`, { body: body });
};

export const SendForRejectPayment = (id, body) => {
  /** used to reject the payment */
  return postAPICall(`payment/${id}/rejected`, { body: body });
};

export const SendForPushbackPayment = (id, body) => {
  /** used to pushback the payment */
  return postAPICall(`payment/${id}/pushback`, { body: body });
};

export const sendPaymentforCancellation = (id, body) => {
  /** used to cancel the payment */
  return postAPICall(`payment/${id}/cancelled`, { body: body });
};

export const FileUpload = (id, body) => {
  /** used to handle the file upload */
  return postAPICall(`payment/${id}/receipt`, {
    body: body, headers:
      { headers: { "Content-Type": "multipart/form-data", }, }
  });
};

export const getAllRemarksPayment = (id) => {
  /** used to get all remarks for payment by id */
  return getAPICall(`payment/${id}/remark`);
};

export const getBulkPayments = ({ entity }) => {
  /** used to get the bulk payments */
  let url = `payment-bulk-download?`;
  if (entity && entity !== 'all') url = url + `entity_id=${entity}`;
  return getAPICall(url);
}

export const uploadBulkPayments = (body) => {
  // used to upload the bulk payments
  return postAPICall(`payment-bulk-update`, {
    body: body, returnObject: true, headers:
      { headers: { "Content-Type": "multipart/form-data", }, }
  });
};