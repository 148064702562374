import { getAPICall, patchAPICall, postAPICall } from '.';


export const getAllApprovedLoans = (body = {}) => {
  /** used to get all approved loans */
  return postAPICall("approved/loans", { body: body });
};

export const getApprovedLoansDetailsById = (id) => {
  /** used to get approved loans by id */
  return getAPICall(`approved/loans/${id}`, { returnFirstItem: true });
};

export const getAllDisb = ({ status, reload_type, page, search, records = 10, entity }) => {
  /** used to get all disb by status [active, closed] */
  let url = `/disbursements?page=${page}&records=${records}&status=${status}`
  if (search) url = url + `&customer_id_name=${search}`;
  if (reload_type && reload_type !== 'all') url = url + `&reload_type=${reload_type}`;
  if (entity && entity !== 'all') url = url + `&entity_id=${entity}`;
  return getAPICall(url, { returnObject: true });
};

export const getDisbById = (id) => {
  /** used to get disb by id */
  return getAPICall(`/disbursement/${id}`, { returnFirstItem: true });
};

export const creatingDisb = (body) => {
  /** used to create disb */
  return postAPICall("/disbursement", { body: body });
};

export const saveAndNextDisb = (body) => {
  /** used to send the disb the  next stage */
  return postAPICall("/disbursement/direct_save", { body: body });
};

export const sendDisbforNextStage = (id, body) =>
/** used to send the disb the  next stage by id */ {
  return postAPICall(`/disbursement/${id}/status/change`, { body: body });
};

export const updatingDisb = (id, body) => {
  /** used to update the disb by id */
  return patchAPICall(`/disbursement/${id}`, { body: body });
};

export const SendForRejectDisb = (id, body) => {
  /** used to reject the disb */
  return postAPICall(`/disbursement/${id}/rejected`, { body: body });
};

export const SendForPushbackDisb = (id, body) => {
  /** used to pushback the disb */
  return postAPICall(`/disbursement/${id}/pushback`, { body: body });
};

export const sendForCancellationDisb = (id, body) => {
  /** used to cancel the disb */
  return postAPICall(`/disbursement/${id}/cancelled`, { body: body });
};

export const getAllRemarksDisb = (id) => {
  /** used to remarks for disb by id */
  return getAPICall(`/disbursement/${id}/remark`);
};
