import { Grid, Text, Textarea, Title } from "@mantine/core";
import React from "react";
import { Controller } from "react-hook-form";
import TitleComp from "../TextComponent/TitleComp";

const ControllerTextArea = ({
  name,
  label,
  placeholder,
  control,
  required = false,
  errors,
  variant = "none",
  ...props
}) => {
  return (
    <Grid>
      <Grid.Col span={5}>
        <Title style={{ fontSize: "14px", display: 'flex' }} order={4}>
          <TitleComp label={label} />
          {required ? <Text ml={'4px'} color="red">*</Text> : null}
        </Title>
      </Grid.Col>
      <Grid.Col span={7}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Textarea
              id={name}
              fullWidth
              minRows={2}
              error={errors ? true : false}
              {...field}
              {...props}
            />
          )}
        />
        {errors && (
          <Text size={"xs"} sx={{ color: "#f13e3e" }}>
            {errors}
          </Text>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default ControllerTextArea;
