import React, { useCallback, useEffect, useState } from 'react'
import DataTableWrapper from '../Table/DataTableWrapper';
import Nums from '../../Body/Nums';
import { getAccountingTableAll } from '../../../services/report.service';
import { createColumnHelper } from '@tanstack/react-table';
import { Center, Text } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { dateFormat } from '../../DateFormat/dateFormat';
import { FileDownload } from 'tabler-icons-react';
import { ActionIcon } from '@mantine/core';
import { displayNotification } from '../Notification/displayNotification';
import DialogModal from './../Modal/DialogModal';
import { openFile } from '../../../utils/openFile';

const AccountingInterfaceModal = ({ open, close, id, name, code }) => {

  const columnHelper = createColumnHelper();
  const [interfaceData, setInterfaceData] = useState([])
  const tableData = useMutation((body) => getAccountingTableAll(id, body))

  const getAccountingEntriesData = useCallback((isDownload = 'no') => {
    if (open == true) {
      const body = {
        "module_name": name,
        "download_csv": isDownload
      }
      tableData.mutate(body, {
        onSuccess: msg => {
          setInterfaceData(msg)
          isDownload == 'yes' && msg?.accounting_url?.length && openFile(msg?.accounting_url)
        },
        onError: e => {
          displayNotification({message: e?.message || e, variant: 'error'})
        }
      });
    }
  })

  useEffect(() => {
    getAccountingEntriesData()
  }, [open])

  const handleFileDownload = () => {
    if (interfaceData?.accounting_url) {
      openFile(interfaceData?.accounting_url)
    } else {
      getAccountingEntriesData('yes')
    }
  }

  const columnData = [
    columnHelper.accessor('transaction_id', {
      header: 'Transaction Id',
    }),
    columnHelper.accessor('voucher_no', {
      header: 'Voucher No',
    }),
    columnHelper.accessor('value_date', {
      header: 'Value Date',
      cell: (value) => dateFormat(value.getValue()),
    }),
    columnHelper.accessor('transaction_date', {
      header: 'Transaction Date',
      cell: (value) => dateFormat(value.getValue()),
    }),
    columnHelper.accessor('jv_upload', {
      header: 'JV-Upload',
    }),
    columnHelper.accessor('digiops_ledger_id', {
      header: 'DL Code',
    }),
    columnHelper.accessor('interface_ledger_code', {
      header: 'GL Code',
    }),
    columnHelper.accessor('interface_ledger_name', {
      header: 'GL Description',
    }),
    columnHelper.accessor('debit_amount', {
      header: 'Dr/Cr',
      cell: (value) => value.row.original?.debit_amount && 'Dr' || value.row.original?.credit_amount && 'Cr' || '-',
    }),
    columnHelper.accessor('debit_amount', {
      header: 'Dr',
      cell: (value) => <Text sx={{ textAlign: 'right' }}><Nums value={value.getValue()} /></Text>,
    }),
    columnHelper.accessor('credit_amount', {
      header: 'Cr',
      cell: (value) => <Text sx={{ textAlign: 'right' }}><Nums value={value.getValue()} /></Text>,
    }),
    columnHelper.accessor('loan_account_number', {
      header: 'Loan Account No',
    }),
    columnHelper.accessor('applicant_code', {
      header: 'Account Code',
    }),
    columnHelper.accessor('applicant_customer_name', {
      header: 'Customer Name',
    }),
    columnHelper.accessor('applicant_state_code', {
      header: 'State Code',
    }),
    columnHelper.accessor('gst_reg_no', {
      header: 'GST No',
    }),
    columnHelper.accessor('product', {
      header: 'Product',
    }),
    columnHelper.accessor('scheme', {
      header: 'Scheme',
    }),
    columnHelper.accessor('bank_name', {
      header: 'Bank Name',
    }),
    columnHelper.accessor('instrument_no', {
      header: 'Instrument No',
    }),
    columnHelper.accessor('accounting_id', {
      header: 'Accounting Id',
    }),
    columnHelper.accessor('gst_amount', {
      header: 'GST Amount',
      cell: (value) => <Text sx={{ textAlign: 'right' }}><Nums value={value.getValue()} /></Text>,
    }),
    columnHelper.accessor('igst_amount', {
      header: 'IGST Amount',
      cell: (value) => <Text sx={{ textAlign: 'right' }}><Nums value={value.getValue()} /></Text>,
    }),
    columnHelper.accessor('cgst_amount', {
      header: 'CGST Amount',
      cell: (value) => <Text sx={{ textAlign: 'right' }}><Nums value={value.getValue()} /></Text>,
    }),
    columnHelper.accessor('sgst_amount', {
      header: 'SGST Amount',
      cell: (value) => <Text sx={{ textAlign: 'right' }}><Nums value={value.getValue()} /></Text>,
    }),
    columnHelper.accessor('charge_name', {
      header: 'Fee Name',
    }),
    columnHelper.accessor('previous_transaction_number', {
      header: 'Pre Trans no',
    }),
  ];

  return (
    <DialogModal opened={open} title={<Center sx={{ display: 'flex', justifyContent: 'space-between' }}><Text>Accounting Entries ({code})</Text><ActionIcon ml={'md'} onClick={handleFileDownload}><FileDownload color={'green'} /></ActionIcon></Center>} size={'1000px'} centered onClose={close}>
      <DataTableWrapper
        breadcrumb={false}
        filter={false}
        searchOption={false}
        addDataOption={false}
        pageTop={false}
        defaultPageSize={2}
        rowData={interfaceData?.accounting_data}
        styles={{ overflowX: 'auto', whiteSpace: 'nowrap' }}
        columnData={columnData}
        loading={tableData.isLoading}
      />
    </DialogModal>
  )
}

export default AccountingInterfaceModal
