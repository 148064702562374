
/**
 * Returns a list of the last three years and months based on the given date.
 * 
 * @param {Object} options - The options for generating the date range.
 * @param {string} options.type - The type of range to generate ('date' or 'year').
 * @param {string} options.date - The base date for generating the range.
 * @param {boolean} options.skipCurrentMonth - Whether to skip the current month in the range.
 * @returns {Array} - The list of dates or years in the range.
 */
export const lastThreeYearsAndMonths = ({ type = 'date', date, skipCurrentMonth = true }) => {
  const currentDate = new Date(date);
  let years = 3;
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1;
  const dateRange = [];
  const yearsList = [];

  while (years > 0) {
    yearsList.push({ label: year, value: year });

    while (month > 0 && type === 'date') {
      const lastDayOfMonth = new Date(year, month, 0).getDate();
      const monthName = new Date(year, month - 1)?.toLocaleString('default', { month: 'long' });
      const formattedDate = { 
        value: `${lastDayOfMonth}-${month.toString().padStart(2, '0')}-${year}`,
        label: `${monthName}-${year}`, 
        year: year, 
        month: month.toString().padStart(2, '0') 
      };

      if (currentDate.getMonth() + 1 == month && year === currentDate.getFullYear() && skipCurrentMonth) {
        // Skip the current month if needed.
        // break;
      } else {
        dateRange.push(formattedDate);
      }
      month--;
    }

    years--;
    year--;
    month = 12;
  }

  return type === 'date' ? dateRange : yearsList;
}