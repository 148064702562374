import { ActionIcon, Box, Grid, Table, Text, Title } from '@mantine/core';
import React, { useState } from 'react';
import Body from '../../components/Body/Body';
import { LoanStatusToken, ViewFormData } from '../../components/CommonComponents/Preview/FilePreview';
import TitleViewer from '../../components/CommonComponents/Title/TitleViewer';
import DashBoardCustomerDetails from '../../components/DashboardCard/dashboard';
import { COLORS, PDF_TEXT_MESSAGE } from '../../constants';
import ImdStyle from '../IMD/Imd.styles';
import { customerBankDetails, CompanyBankDetails } from '../../services/imd.service';
import Nums from '../../components/Body/Nums';
import IdBadge from '../../components/CommonComponents/Preview/IdBadge';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { useQuery } from '@tanstack/react-query';
import { generateReceiptPdfbyID } from '../../services/pdf.service';
import { useQueryClient } from "@tanstack/react-query";
import { getRepaymentType } from '../../services/master.service';
import dayjs from 'dayjs';
import { Skeleton } from '@mantine/core';
import { getPaymentHistorybyLoanId, searchLoanIdforPayment } from '../../services/paymentHistory.service';
import { useLocation, useParams } from 'react-router-dom';
import PaymentHistorySearch from './PaymentHistorySearch';
import { STATUS_COLORS } from '../../utils/StatusTable';
import { AUTO_CANCELLED, CANCELLED } from '../../constants/status';
import { FileDownload } from 'tabler-icons-react';
import { getCustomerBankById } from '../../services/customer.service';
import { openFile } from '../../utils/openFile';

const PaymentHistoryPage = () => {

  const { prospectCode } = useParams();
  const location = useLocation();
  
  const [pdfLoading, setpdfLoading] = useState();
  const queryClient = useQueryClient();

  const { data: loanDetails = location.state } = useQuery(
    ['prospectcode-loandetails', prospectCode],
    () => searchLoanIdforPayment(prospectCode),
    {
      enabled: !location?.state?.prospect_code, 
    }
  )

  const { data: paymentHistory = [], isLoading : paymentHistoryLoading } = useQuery(
    ['paymenthistory-id', loanDetails?.id],
    () => loanDetails?.id && getPaymentHistorybyLoanId(loanDetails?.id),
  )
  
  const { data: customerBank = [] } = useQuery(
    ['customer-bank', loanDetails?.customer_id],
    () => getCustomerBankById({customerId: loanDetails?.customer_id}),
    { enabled: Boolean(loanDetails?.customer_id) }
  );

  const { data: targetBank = [] } = useQuery(
    ['petro-bank', paymentHistory[0]?.target_bank_id],
    () => CompanyBankDetails(paymentHistory[0]?.target_bank_id),
    { 
      enabled: Boolean(paymentHistory[0]?.target_bank_id),
    }
  );

  const { data: repaymentType = [] } = useQuery(
    ['repayment-tenures'],
    () => getRepaymentType(),
    { 
      enabled: !!loanDetails?.repayment_tenure_id,
      select: (e) => {
        return e?.find((item) => item?.id == loanDetails?.repayment_tenure_id)
      },
    }
  );

  const handlePDF = (url, id, code) => {
    if(url) {
      openFile(url)
    } else {
      setpdfLoading({ [code]: true })
      generateReceiptPdfbyID(id)
        .then((res) => {
          queryClient.invalidateQueries(['paymenthistory-id', id]);
          displayNotification({ message: PDF_TEXT_MESSAGE?.receipt, variant: 'success' })
          openFile(res)
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' })
        })
        .finally(() => {
          setpdfLoading({ [code]: false })
        })
      }
  }

  /** used to handle the no of days between disb date and due date */
  const handleNoOfDays = () => {
    var due_date = dayjs(loanDetails?.due_date)
    var no_of_days;
    no_of_days = due_date.diff(loanDetails?.disbursed_date, 'day')    
    return no_of_days;
  }

  return (
    <>
    <PaymentHistorySearch searchOpen={prospectCode ? true : false} custName={loanDetails?.customer_name} custID={loanDetails?.customer_id}  />
    {/* id is send as a props to get and display the customer details  */}
    <DashBoardCustomerDetails id={loanDetails?.customer_id} loansId={loanDetails?.id} />
    <Box sx={{ position: 'relative' }}>
      <Body>
        {/* prospect code is send as props to display and status value is send to change the color of the badge */}
        {loanDetails?.prospect_code && (
          <div style={ImdStyle.flag}>
            <IdBadge
              remarks={loanDetails?.status_value}
              label={loanDetails?.prospect_code}
            />
          </div>
        )}
        <Grid gutter='sm' mt={'md'}>
          <Grid.Col span={4}>
            {/* loanDetailsViewerWapper component is used to display the loanDetails inside the bule background */}
            <Grid gutter='md'>
              <Grid.Col span={12}>
                <ViewFormData label='Loan Type' value={loanDetails?.loan_type == 'new' ? 'New' : 'Revolving'} loading={paymentHistoryLoading} />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewFormData label='Product' value={loanDetails?.product_name} loading={paymentHistoryLoading} />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewFormData label='Tranche Amount' value={<Nums value={loanDetails?.loan_amount} />} loading={paymentHistoryLoading} />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewFormData label='Due Amount' value={<Nums value={loanDetails?.due_amount} />} loading={paymentHistoryLoading} />
              </Grid.Col>  
              <Grid.Col span={12}>
                <ViewFormData label='Re-Payment Type' value={repaymentType?.label}  loading={paymentHistoryLoading} />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={4}>
            <Grid gutter='md'>
              <Grid.Col span={12}>
                <ViewFormData label='Disb Date' value={dateFormat(loanDetails?.disbursed_date)} loading={paymentHistoryLoading} />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewFormData label='Due Date' value={dateFormat(loanDetails?.due_date)} loading={paymentHistoryLoading} />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewFormData label='Foreclosure Date' value={dateFormat(loanDetails?.foreclosure_approved_date)} loading={paymentHistoryLoading} />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewFormData label='No.of Days' value={handleNoOfDays()} loading={paymentHistoryLoading} />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={4}>
            <Grid gutter='md'>

              <Grid.Col span={12}>
                <ViewFormData label='Principal' value={<Nums value={loanDetails?.loan_amount} />} valueStyle={{textAlign: 'right'}} loading={paymentHistoryLoading} />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewFormData label={`Interest (${loanDetails?.interest_rate}%)`} value={<Nums value={loanDetails?.interest_amount} />} valueStyle={{textAlign: 'right'}} loading={paymentHistoryLoading} />
              </Grid.Col>                    
              { loanDetails?.spread_rate ?
                <Grid.Col span={12}>
                  <ViewFormData label={`Spread (${loanDetails?.spread_rate}%)`} value={<Nums value={loanDetails?.spread_amount} />} valueStyle={{textAlign: 'right'}} loading={paymentHistoryLoading} />
                </Grid.Col> : null
              }
              { loanDetails?.addon_spread_rate ?            
                <Grid.Col span={12}>
                  <ViewFormData label={`Addon Spread (${loanDetails?.addon_spread_rate}%)`} value={<Nums value={loanDetails?.addon_spread_amount} />} valueStyle={{textAlign: 'right'}} loading={paymentHistoryLoading} />
                </Grid.Col> : null
              }                  
              { loanDetails?.advance_interest ?
                <Grid.Col span={12}>
                  <ViewFormData label='Advance Interest' value={<Nums value={loanDetails?.advance_interest} />} valueStyle={{textAlign: 'right'}} loading={paymentHistoryLoading} />
                </Grid.Col> : null
              }
              { loanDetails?.additional_deduction?.length ?
                <>
                  <Grid.Col span={12}>
                    <Title order={4} sx={ImdStyle.dashedTitle}>
                      Deductions
                    </Title>
                  </Grid.Col>
                  {
                    loanDetails?.additional_deduction?.map((item) => (
                      <Grid.Col span={12}>
                        <ViewFormData label={item?.fee_name} value={<Nums value={item?.fee_value} />} valueStyle={{textAlign: 'right'}}  loading={paymentHistoryLoading} />
                      </Grid.Col>
                    ))
                  }
                </> : null
              }
              <Grid.Col span={12}>
                <ViewFormData label='Net Disbursement Amount' value={<Nums value={loanDetails?.disbursed_amount} />} valueStyle={{textAlign: 'right'}} loading={paymentHistoryLoading} />
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={12} mt={'md'}>
            <TitleViewer title='Payment History' />
            {!paymentHistory?.length && !paymentHistoryLoading ?
              <Text style={{textAlign: 'center'}} mt={'md'}>No Receipt Found</Text> :
              <>
                <Table verticalSpacing='xs' fontSize='xs' mt={'md'}>
                  <thead style={{ backgroundColor: COLORS.primary.light1 }}>
                    <tr>
                      <th>Receipt Code</th>
                      <th>Receipt Amount</th>
                      <th>Pay Head</th>
                      <th>Amount</th>
                      <th>Receipt Date</th>
                      <th>From Bank</th>
                      <th>To Bank</th>
                      <th>Payment Ref.no</th>
                      <th>Status</th>
                      <th>Document</th>
                    </tr>
                  </thead>
                  <tbody>    
                    {paymentHistoryLoading ? Array(4).fill(1)?.map(() => 
                      <>
                        <tr>
                          <td><Skeleton visible={paymentHistoryLoading} height={10} /></td>
                          <td><Skeleton visible={paymentHistoryLoading} height={10} /></td>
                          <td><Skeleton visible={paymentHistoryLoading} height={10} /></td>
                          <td><Skeleton visible={paymentHistoryLoading} height={10} /></td>
                          <td><Skeleton visible={paymentHistoryLoading} height={10} /></td>
                          <td><Skeleton visible={paymentHistoryLoading} height={10} /></td>
                          <td><Skeleton visible={paymentHistoryLoading} height={10} /></td>
                          <td><Skeleton visible={paymentHistoryLoading} height={10} /></td>
                          <td><Skeleton visible={paymentHistoryLoading} height={10} /></td>
                          <td><Skeleton visible={paymentHistoryLoading} height={10} /></td>
                        </tr>
                      </>
                    ) : null }            
                    { paymentHistory?.map((item) => (
                      <>
                        {item?.appropriated_principle ?
                          <tr style={{color: (item?.status_value == CANCELLED || item?.status_value == AUTO_CANCELLED) && 'red'}}>
                            <td>{item?.code}</td>
                            <td>
                              <Nums value={item?.receipt_amount} />
                            </td>
                            <td>Principal</td>
                            <td>
                              <Nums value={item?.appropriated_principle} />
                            </td>
                            <td>                    
                              {dateFormat(item?.receipt_date)}                           
                            </td>
                            <td>
                              {customerBank?.find((bank) => bank.id == item?.source_bank_id)?.bank_name}
                            </td>
                            <td>
                                {targetBank[0]?.bank_name}
                            </td>
                            <td>
                              {item?.payment_ref_no}
                            </td>
                            <td>
                              <LoanStatusToken
                                label={item?.status_value}
                                color={STATUS_COLORS?.[item?.status_value]}
                              />
                            </td>
                            <td>
                              <ActionIcon onClick={() => {handlePDF(item?.receipt_url, item?.id, item?.code)}} loading={pdfLoading?.[item?.code]}>
                                <FileDownload color={COLORS?.icon?.download}/>
                              </ActionIcon>
                            </td>
                          </tr> : null
                        }
                        {item?.appropriated_interest ?
                          <tr style={{color: (item?.status_value == CANCELLED || item?.status_value == AUTO_CANCELLED)&& 'red'}}>
                            <td>{item?.code}</td>
                            <td>
                              <Nums value={item?.receipt_amount} />
                            </td>
                            <td>Interest</td>
                            <td>
                              <Nums value={item?.appropriated_interest} />
                            </td>
                            <td>                    
                              {dateFormat(item?.receipt_date)}
                            </td>
                            <td>
                              {customerBank?.find((bank) => bank.id == item?.source_bank_id)?.bank_name}
                            </td>
                            <td>
                              {targetBank[0]?.bank_name}
                            </td>
                            <td>
                              {item?.payment_ref_no}
                            </td>
                            <td>
                              <LoanStatusToken
                                label={item?.status_value}
                                color={STATUS_COLORS?.[item?.status_value]}
                              />
                            </td>
                            <td>
                              <ActionIcon onClick={() => {handlePDF(item?.receipt_url, item?.id, item?.code)}} loading={pdfLoading?.[item?.code]}>
                                <FileDownload color={COLORS?.icon?.download}/>
                              </ActionIcon>
                            </td>
                          </tr> : null
                        }
                        {item?.appropriated_spread ?
                          <tr style={{color: (item?.status_value == CANCELLED || item?.status_value == AUTO_CANCELLED)&& 'red'}}>
                            <td>{item?.code}</td>
                            <td>
                              <Nums value={item?.receipt_amount} />
                            </td>
                            <td>Spread</td>
                            <td>
                              <Nums value={item?.appropriated_spread} />
                            </td>
                            <td>                    
                              {dateFormat(item?.receipt_date)}
                            </td>
                            <td>
                              {customerBank?.find((bank) => bank.id == item?.source_bank_id)?.bank_name}
                            </td>
                            <td>
                              {targetBank[0]?.bank_name}
                            </td>
                            <td>
                              {item?.payment_ref_no}
                            </td>
                            <td>
                              <LoanStatusToken
                                label={item?.status_value}
                                color={STATUS_COLORS?.[item?.status_value]}
                              />
                            </td>
                            <td>
                              <ActionIcon onClick={() => {handlePDF(item?.receipt_url, item?.id, item?.code)}} loading={pdfLoading?.[item?.code]}>
                                <FileDownload color={COLORS?.icon?.download}/>
                              </ActionIcon>
                            </td>
                          </tr> : null
                        }
                        {item?.appropriated_addon_spread ?
                          <tr style={{color: (item?.status_value == CANCELLED || item?.status_value == AUTO_CANCELLED)&& 'red'}}>
                            <td>{item?.code}</td>
                            <td>
                              <Nums value={item?.receipt_amount} />
                            </td>
                            <td>AddOn Spread</td>
                            <td>
                              <Nums value={item?.appropriated_addon_spread} />
                            </td>
                            <td>                    
                              {dateFormat(item?.receipt_date)}
                            </td>
                            <td>
                              {customerBank?.find((bank) => bank.id == item?.source_bank_id)?.bank_name}
                            </td>
                            <td>
                              {targetBank[0]?.bank_name}
                            </td>
                            <td>
                              {item?.payment_ref_no}
                            </td>
                            <td>
                              <LoanStatusToken
                                label={item?.status_value}
                                color={STATUS_COLORS?.[item?.status_value]}
                              />
                            </td>
                            <td>
                              <ActionIcon onClick={() => {handlePDF(item?.receipt_url, item?.id, item?.code)}} loading={pdfLoading?.[item?.code]}>
                                <FileDownload color={COLORS?.icon?.download}/>
                              </ActionIcon>
                            </td>
                          </tr> : null
                        }
                        {item?.appropriated_interest_amount_during_penalty ?
                          <tr style={{color: (item?.status_value == CANCELLED || item?.status_value == AUTO_CANCELLED)&& 'red'}}>
                            <td>{item?.code}</td>
                            <td>
                              <Nums value={item?.receipt_amount} />
                            </td>
                            <td>Interest F1</td>
                            <td>
                              <Nums value={item?.appropriated_interest_amount_during_penalty} />
                            </td>
                            <td>                    
                              {dateFormat(item?.receipt_date)}
                            </td>
                            <td>
                              {customerBank?.find((bank) => bank.id == item?.source_bank_id)?.bank_name}
                            </td>
                            <td>
                              {targetBank[0]?.bank_name}
                            </td>
                            <td>
                              {item?.payment_ref_no}
                            </td>
                            <td>
                              <LoanStatusToken
                                label={item?.status_value}
                                color={STATUS_COLORS?.[item?.status_value]}
                              />
                            </td>
                            <td>
                              <ActionIcon onClick={() => {handlePDF(item?.receipt_url, item?.id, item?.code)}} loading={pdfLoading?.[item?.code]}>
                                <FileDownload color={COLORS?.icon?.download}/>
                              </ActionIcon>
                            </td>
                          </tr> : null
                        }
                        {item?.appropriated_spread_amount_during_penalty ?
                          <tr style={{color: (item?.status_value == CANCELLED || item?.status_value == AUTO_CANCELLED)&& 'red'}}>
                            <td>{item?.code}</td>
                            <td>
                              <Nums value={item?.receipt_amount} />
                            </td>
                            <td>Spread F1</td>
                            <td>
                              <Nums value={item?.appropriated_spread_amount_during_penalty} />
                            </td>
                            <td>                    
                              {dateFormat(item?.receipt_date)}
                            </td>
                            <td>
                              {customerBank?.find((bank) => bank.id == item?.source_bank_id)?.bank_name}
                            </td>
                            <td>
                                {targetBank[0]?.bank_name}
                            </td>
                            <td>
                              {item?.payment_ref_no}
                            </td>
                            <td>
                              <LoanStatusToken
                                label={item?.status_value}
                                color={STATUS_COLORS?.[item?.status_value]}
                              />
                            </td>
                            <td>
                              <ActionIcon onClick={() => {handlePDF(item?.receipt_url, item?.id, item?.code)}} loading={pdfLoading?.[item?.code]}>
                                <FileDownload color={COLORS?.icon?.download}/>
                              </ActionIcon>
                            </td>
                          </tr> : null
                        }
                        {item?.appropriated_addon_spread_amount_during_penalty ?
                          <tr style={{color: (item?.status_value == CANCELLED || item?.status_value == AUTO_CANCELLED)&& 'red'}}>
                            <td>{item?.code}</td>
                            <td>
                              <Nums value={item?.receipt_amount} />
                            </td>
                            <td>Addon Spread F1</td>
                            <td>
                              <Nums value={item?.appropriated_addon_spread_amount_during_penalty} />
                            </td>
                            <td>                    
                              {dateFormat(item?.receipt_date)}
                            </td>
                            <td>
                              {customerBank?.find((bank) => bank.id == item?.source_bank_id)?.bank_name}
                            </td>
                            <td>
                              {targetBank[0]?.bank_name}
                            </td>
                            <td>
                              {item?.payment_ref_no}
                            </td>
                            <td>
                              <LoanStatusToken
                                label={item?.status_value}
                                color={STATUS_COLORS?.[item?.status_value]}
                              />
                            </td>
                            <td>
                              <ActionIcon onClick={() => {handlePDF(item?.receipt_url, item?.id, item?.code)}} loading={pdfLoading?.[item?.code]}>
                                <FileDownload color={COLORS?.icon?.download}/>
                              </ActionIcon>
                            </td>
                          </tr> : null
                        }
                        {item?.appropriated_penal_interest_amount ?
                          <tr style={{color: (item?.status_value == CANCELLED || item?.status_value == AUTO_CANCELLED)&& 'red'}}>
                            <td>{item?.code}</td>
                            <td>
                              <Nums value={item?.receipt_amount} />
                            </td>
                            <td>Penal F2</td>
                            <td>
                              <Nums value={item?.appropriated_penal_interest_amount} />
                            </td>
                            <td>                    
                              {dateFormat(item?.receipt_date)}
                            </td>
                            <td>
                              {customerBank?.find((bank) => bank.id == item?.source_bank_id)?.bank_name}
                            </td>
                            <td>
                              {targetBank[0]?.bank_name}
                            </td>
                            <td>
                              {item?.payment_ref_no}
                            </td>
                            <td>
                              <LoanStatusToken
                                label={item?.status_value}
                                color={STATUS_COLORS?.[item?.status_value]}
                              />
                            </td>
                            <td>
                              <ActionIcon onClick={() => {handlePDF(item?.receipt_url, item?.id, item?.code)}} loading={pdfLoading?.[item?.code]}>
                                <FileDownload color={COLORS?.icon?.download} />
                              </ActionIcon>
                            </td>
                          </tr> : null
                        }
                      </>
                    ))}
                  </tbody>
                </Table>
              </>
            }
          </Grid.Col>
        </Grid>
      </Body>
    </Box>
    </>
  );
};

export default PaymentHistoryPage;
