import {
  Box,
  Grid,
  Textarea,
  Title,
  Modal,
  Button,
  Tooltip,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import ControllerDatePicker from '../../components/CommonComponents/TextField/ControllerDatePicker';
import ControllerSelect from '../../components/CommonComponents/TextField/ControllerSelect';
import ImdStyle from '../IMD/Imd.styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ModalDataViwer from '../../components/SucessfullModal/ModalDataViwer';
import Body from '../../components/Body/Body';
import PageTitle from '../../components/PageTitle/PageTitle';
import ControllerCurrencyInput
  from '../../components/CommonComponents/TextField/ControllerCurrencyInput';
import ActionButtons from '../../components/CommonComponents/ActionButtons/ActionButtons';
import { currencyAsInt } from '../../components/Currency/currencyFormat';
import { useQuery } from '@tanstack/react-query';
import Nums from '../../components/Body/Nums';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import Remarks from '../../components/CommonComponents/Remarks/Remarks';
import IdBadge from '../../components/CommonComponents/Preview/IdBadge';
import COLORS from '../../constants/colors';
import dayjs from 'dayjs';
import { STAGE_CHANGE_TEXT, STATE_CODE } from '../../constants';
import { getStageforModules } from '../../services/module.service';
import useSystemConfigStore from '../../store/systemConfigStore';
import ControllerCreatableSelect from '../../components/CommonComponents/TextField/ControllerCreatableSelect';
import { Plus, Trash } from 'tabler-icons-react';
import CustomerCreationInvoiceTabs from './InvoiceCustomerComp/CustomerCreationInvoiceTabs';
import { externalCustomerInvoiceCreate, externalCustomerInvoiceCreateAndStatusChange, externalCustomerInvoiceStatusChange, getAlleInvoiceCharges, getExternalCustomerInvoiceById, getRemarksOfExternalCustomerInvoice, geteInvoiceById, searchCustomerForExternalInvoice, updateExternalCustomerInvoice } from '../../services/e-invoice.service';
import styled from '@emotion/styled';
import { sumBy } from 'lodash';
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification';
import DialogModal from '../../components/CommonComponents/Modal/DialogModal';

const DataWapperTable = styled.table`
  .css-1hb7zxy-IndicatorsContainer {
    background: none;
    display: none;
  }
  
  border-collapse: collapse;
  font-size: 12px;

  td, th {
    padding: 6px 16px;
    border: 1px dashed ${COLORS.gray(15)};
    text-align: right;
  }
  tr td:nth-child(2) {
    padding: 0px 16px;
    max-width: 300px;
    min-width: 200px;
  }
  tr th:first-of-type {
    border-left: 0;
    color: ${COLORS.gray(40)};
    text-align: center;
    width: 50px;
  }
  tr th:last-of-type {
    border-right: 0;
  }
  tr:first-of-type th {
    border-top: 0;
  }
  tr td:first-of-type {
    border-left: 0;
    color: ${COLORS.gray(40)};
    text-align: center;
  }
  tr:last-of-type td {
    border-bottom: 0;
  }
  tr td:last-of-type {
    border-right: 0;
  }
  tr td:nth-child(2),
  tr th:nth-child(2),
  tr td:nth-child(3),
  tr th:nth-child(3), {
    text-align: left;
  }
  
  .trash {
    display: none;
  }
  .indexList {
    display: block;
  }
  .rowList:hover {
    .indexList {
      display: none;
    }
    .trash {
      display: block;
    }
  }
`;

const schema = yup.object({
  charge_date: yup.string().required('Please Select a Date'),
  charge_type: yup.string().required('Please Select a Type'),
  customer_id: yup.string().required('Please select the customer name & id'),
});

const ManualCustomerInvoiceCreation = ({ formType }) => {
  const { createdID } = useParams();
  const systemDate = useSystemConfigStore(store => store.config);
  const [sucessfulmodal, setSucessfulmodal] = useState(false);
  const [val, setVal] = useState('')
  const [custName, setCustName] = useState();
  const [buttonLoader, setButtonLoader] = useState({});
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [label, setLabel] = useState(false);
  const [remarksLoading, setRemarksLoading] = useState();
  const [notes, setNotes] = useState();
  const [customerOnboarding, setCustomerOnboarding] = useState(false);

  const { data: fees = [] } = useQuery(
    ['external-invoice-changes'],
    () => getAlleInvoiceCharges(),
  )
  
  const { data: invoiceData = {} } = useQuery(
    ['invoice-id', createdID],
    () => createdID && getExternalCustomerInvoiceById({id: createdID}),
    {
      onSuccess: (data) => {
        setVal(data?.customer_id);
        setCustName(data?.customer_name);
        setNotes(data?.description);
      },
      refetchOnWindowFocus: false
    }
  )

  const { data: stages = [] } = useQuery(
    ['invoice-stage'],
    () => getStageforModules('external_customer_invoice'),
    {
      select: (data) => {
        return {
          'current_stage': 'draft',
          'next_stage': data[1]
        }
      },
      refetchOnWindowFocus: false
    }
  );

  const { data: reviewTrail = [] } = useQuery(
    ['review-trail', createdID],
    () => createdID && getRemarksOfExternalCustomerInvoice({id: createdID}),
    { refetchOnWindowFocus: false }
  );

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues:
    {
      charge_type: 'receivable',
      charge_date: dayjs(new Date()).format(),
      invoice_charges: [{}],
      ...invoiceData,
    },
  });
  const values = watch();
  let gstAmounts = [...values?.invoice_charges];

  const feeFieldArray = useFieldArray({
    control,
    name: 'invoice_charges',
  });

  const { data: externalCustomerData = {} } = useQuery(
    ['external-customer'],
    () => geteInvoiceById({ id: values?.customer_id }),
    {
      enabled: Boolean(values?.customer_id),
      refetchOnWindowFocus: false,
    }
  )

  /** used to handle the customer search */
  const searchQuery = useQuery(
    ["search-data-external-customer", custName],
    /*
     * sending the signal to cancel the per pending api call
     * signal will contain the abort signal
     */
    ({ signal }) => searchCustomerForExternalInvoice({id: custName}),
    {
      /** this api call will call when the searched value length is min of 2 */
      enabled: Boolean(custName?.length > 2),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (Object.keys(invoiceData).length) {
      reset(invoiceData);
    }
  }, [invoiceData]);

  useEffect(() => {
    if (Object.keys(systemDate).length) {
      setValue('charge_date', systemDate?.current_system_date);
    }
  }, [systemDate]);

  /** This function is used to check the all valid fields are present are not */
  const handleReviewModal = () => {
    schema.validate(values)
      .then(res => {
        setOpen(true);
        setStatus('review');
        setLabel(stages?.next_stage == 'approval' ? 'Send for Approval' : 'Send for Review');
      })
      .catch(err => {
        displayNotification({ message: err?.errors, variant: 'error' })
      })
  }

/**
 * Transforms an array of objects into a new array of objects with selected properties.
 *
 * @param {Array} e - The input array of objects.
 * @return {Array} The transformed array of objects.
 */
  const handleGSTInvoiceChargesPayload = (e) => {
    let result = e?.map((item) => {
      return {
        charge_id: item?.charge_id,
        charge_amount: currencyAsInt(item?.charge_amount),
      }
    })
    return result
  }

  /** handling the submit stage */
  const onSubmit = (values) => {
    setButtonLoader({ save: true });
    const body = {
      customer_name: externalCustomerData?.name,
      customer_id: parseInt(values?.customer_id),
      charge_type: values?.charge_type,
      charge_date: dateFormat(values?.charge_date, 'YYYY-MM-DD'),
      invoice_charges: handleGSTInvoiceChargesPayload(values?.invoice_charges),
      description: notes,
    };

    if (!createdID) {
      externalCustomerInvoiceCreate({body})
        .then((res) => {
          setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.save });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' })
          }
        })
        .finally(() => {
          setButtonLoader({ save: false })
        })
    } else {
      updateExternalCustomerInvoice({id: createdID, body})
        .then((res) => {
          setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.update });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' })
          }
        })
        .finally(() => {
          setRemarksLoading(false)
          setButtonLoader({ save: false })
        })
    }

  };

  /** Handling the review modal */
  const onReview = (remarks) => {

    setRemarksLoading(true)
    const body = {
      remark_type: 'maker',
      remarks: remarks,
      customer_name: externalCustomerData?.name,
      customer_id: parseInt(values?.customer_id),
      charge_type: values?.charge_type,
      charge_date: dateFormat(values?.charge_date, 'YYYY-MM-DD'),
      invoice_charges: handleGSTInvoiceChargesPayload(values?.invoice_charges),
      description: notes,
    };

    if (!createdID) {
      externalCustomerInvoiceCreateAndStatusChange({body})
        .then((res) => {
          setSucessfulmodal({ open: true, title: stages?.next_stage == 'approval' ? STAGE_CHANGE_TEXT?.approval : STAGE_CHANGE_TEXT?.review });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' })
          }
        })
        .finally(() => {
          setRemarksLoading(false)
          setOpen(false)
        })
    } else {
      updateExternalCustomerInvoice({id: createdID, body})
        .then((res) => {
          externalCustomerInvoiceStatusChange({id: createdID, body})
            .then((res) => {
              setSucessfulmodal({ open: true, title: stages?.next_stage == 'approval' ? STAGE_CHANGE_TEXT?.approval : STAGE_CHANGE_TEXT?.review });
            })
            .catch((e) => {
              if (e?.RequestValidationError) {
                Object.keys(e?.RequestValidationError).forEach((item, index) => {
                  setError(item, { message: e?.RequestValidationError[item] });
                });
              } else {
                displayNotification({ message: e?.message || e, variant: 'error' })
              }
            })
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' })
        })
        .finally(() => {
          setRemarksLoading(false)
          setOpen(false)
        })
    }
  }

  /**
 * Calculate the GST amount for a given index and GST type.
 *
 * @param {number} index - The index of the item.
 * @param {string} gstType - The type of GST.
 */
  const handleGSTAmount = (index) => {
    let gst_rate = fees?.find((item) => values?.invoice_charges[index]?.charge_id === item?.value)    
    let total_rate = 0;
    STATE_CODE != externalCustomerData?.state && externalCustomerData?.state ? total_rate = gst_rate?.igst : total_rate = gst_rate?.cgst + gst_rate?.sgst;
    gstAmounts?.map((item, i) => {
      if(i === index) {
        item.charge_amount= currencyAsInt(values?.invoice_charges[index]?.charge_amount) || null;
        if (STATE_CODE != externalCustomerData?.state && externalCustomerData?.state) {
          item.igst = (((currencyAsInt(values?.invoice_charges[index]?.charge_amount) * fees?.find((item) => values?.invoice_charges[index]?.charge_id === item?.value)?.igst)/100) || 0);
          item.sgst = 0;
          item.cgst = 0;
        }
        else if(STATE_CODE == externalCustomerData?.state) {
          item.igst = 0;
          item.cgst = ((currencyAsInt(values?.invoice_charges[index]?.charge_amount) * fees?.find((item) => values?.invoice_charges[index]?.charge_id === item?.value)?.cgst)/100) || 0;
          item.sgst = ((currencyAsInt(values?.invoice_charges[index]?.charge_amount) * fees?.find((item) => values?.invoice_charges[index]?.charge_id === item?.value)?.sgst)/100) || 0;
        } 
        item.total= (((currencyAsInt(values?.invoice_charges[index]?.charge_amount) * (total_rate))/100) + currencyAsInt(values?.invoice_charges[index]?.charge_amount)) || 0;
      }
    })
  }

  /**
   * Calculates the subtotal of the GST for a given field name.
   *
   * @param {string} fieldName - The name of the field to calculate the GST subtotal for.
   * @return {number} The subtotal of the GST for the specified field name.
   */
  const handleSubTotalOfGST = (fieldName) => {
    return sumBy(gstAmounts, function (n) {
      return currencyAsInt(n[fieldName]) || 0
    })
  }

  const handleTotalOfChanges = () => {
    let result = 0;
    gstAmounts?.map((item) => {
      result += currencyAsInt(item?.charge_amount) + item?.igst + item?.cgst + item?.sgst;
    })
    return result
  }

  return (
    <>
      <Body background={false}>
        {externalCustomerData?.name ?
          <PageTitle
            title={externalCustomerData?.name} id={externalCustomerData?.customer_code}
          />
          :
          <PageTitle
            title={'Invoice Creation'}
          />
        }
      </Body>
      <Box sx={{ position: 'relative' }}>
        <Body>
          {invoiceData?.code && (
            <div style={ImdStyle.flag}>
              <IdBadge
                remarks={invoiceData?.status_value}
                label={invoiceData?.code}
              />
            </div>
          )}
          <Grid gutter='xl'>
            <Grid.Col span={4}>
              <Grid.Col span={12} mt={'25px'} sx={{ minHeight: '57px', padding: '0 8px 0 8px' }}>
                <ControllerCreatableSelect
                  name={'customer_id'}
                  placeholder={"Search by customer Id / Name"}
                  formatCreateLabel={userInput => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                      <Plus color='blue' />{`Create new customer for ${userInput}`}
                    </div>
                  )}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999, }),
                    control: (provided) => ({ ...provided, fontSize: '13px' }),
                    option: (styles, { data }) => {
                      return {
                        ...styles,
                        backgroundColor: typeof data?.label === 'object' ? COLORS.blue(30) : '',
                        fontSize: '13px', padding: '5px 15px 5px 15px'
                      };
                    },
                  }}
                  setSearchData={setCustName}
                  onCreateOption={() => setCustomerOnboarding(true)}
                  displayLabel={false}
                  spanSize={12}
                  control={control}
                  data={searchQuery?.data || []}
                  errors={errors?.customer_id?.message}
                />
              </Grid.Col>
                <Grid.Col span={12} mt={'xl'} pt={'xs'}>
                  <ControllerDatePicker
                    label='Charge Date'
                    required
                    name='charge_date'
                    maxDate={new Date(systemDate?.current_system_date)}
                    minDate={new Date(new Date(systemDate?.current_system_date).setDate(new Date(systemDate?.current_system_date).getDate() - 3))}
                    control={control}
                    errors={errors?.charge_date?.message}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ControllerSelect
                    label='Charge Type'
                    name='charge_type'
                    required
                    placeholder={'Select'}
                    control={control}
                    data={[
                      {
                        label: 'Receivable',
                        value: 'receivable'
                      }, {
                        label: 'Payable',
                        value: 'payable'
                      }
                    ]}
                    errors={errors?.charge_type?.message}
                  />
                </Grid.Col>
            </Grid.Col>
            <Grid.Col span={8} mt={'20px'}>
              <DataWapperTable>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Charge Name</th>
                    <th>Charge Amount</th>
                    {STATE_CODE != externalCustomerData?.state && externalCustomerData?.state ?
                      <th>IGST</th>
                    : null}
                    {STATE_CODE == externalCustomerData?.state ?
                      <th>SGST</th>
                    : null}
                    {STATE_CODE == externalCustomerData?.state ?
                      <th>CGST</th>
                    : null}
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    feeFieldArray?.fields?.map((item, index) => {
                      return (
                        <tr key={item?.id} className="rowList">
                          <td>
                            {feeFieldArray?.fields?.length > 1 ?
                              <Tooltip
                                label={"Click to remove"}
                                color="gray"
                                withArrow
                                position="right"
                              > 
                                <span className="trash">
                                  <Trash
                                    size={"13px"}
                                    onClick={() => {
                                      feeFieldArray?.remove(index);
                                      gstAmounts.splice(index, 1)
                                    }}
                                    style={{ cursor: "pointer" }}
                                    color={"red"}
                                  />
                                </span>
                              </Tooltip> : null
                            }
                            <span className={feeFieldArray?.fields?.length >1 ? 'indexList' : null}>{index+1}.</span>
                          </td>
                          <td>
                            <ControllerSelect
                              name={`invoice_charges[${index}].charge_id`}
                              minHeight='0'
                              displayLabel={false}
                              onChangeTrigger={() => {
                                handleGSTAmount(index);
                              }}
                              spanSize={12}
                              required
                              control={control}
                              data={fees}
                              styles={{ menu: (provided) => ({ ...provided, zIndex: 9999, }), option: (provided) => ({ ...provided, fontSize: '13px', padding: '5px 15px 5px 15px' }), control: (provided) => ({ ...provided, fontSize: '13px', border: 0 })}}
                            />
                          </td>
                          <td>
                            <ControllerCurrencyInput
                              name={`invoice_charges[${index}].charge_amount`}
                              placeholder={'Enter Charge Amount'}
                              minHeight='0'
                              displayLabel={false}
                              onChangeTrigger={() => {
                                handleGSTAmount(index);
                              }}
                              spanSize={12}
                              required
                              styles={({
                                input: {
                                  border: "none",
                                }
                              })}
                              control={control}
                            />
                          </td>
                          {STATE_CODE != externalCustomerData?.state && externalCustomerData?.state ?
                            <td>
                              <Nums value={values?.invoice_charges[index]?.igst}/>
                            </td>
                          : null}
                          {STATE_CODE == externalCustomerData?.state ?
                            <td>
                              <Nums value={values?.invoice_charges[index]?.sgst}/>
                            </td>
                          : null}
                          {STATE_CODE == externalCustomerData?.state ?
                            <td>
                              <Nums value={values?.invoice_charges[index]?.cgst}/>
                            </td>
                          : null}
                          <td>
                            <Nums value={values?.invoice_charges[index]?.igst + values?.invoice_charges[index]?.sgst + values?.invoice_charges[index]?.cgst + currencyAsInt(values?.invoice_charges[index]?.charge_amount)}/>
                          </td>
                        </tr>
                      )
                    })
                  }
                  {feeFieldArray?.fields?.length > 1 ?
                    <tr>
                      <td></td>
                      <td>Total</td>
                      <td><Nums value={handleSubTotalOfGST("charge_amount")}/></td>
                      {STATE_CODE != externalCustomerData?.state && externalCustomerData?.state ?
                        <td><Nums value={handleSubTotalOfGST("igst")}/></td>
                      : null}
                      {STATE_CODE == externalCustomerData?.state ?
                        <td><Nums value={handleSubTotalOfGST("sgst")}/></td>
                      : null}
                      {STATE_CODE == externalCustomerData?.state ?
                        <td><Nums value={handleSubTotalOfGST("cgst")}/></td>
                      : null}
                      <td><Nums value={handleTotalOfChanges()}/></td>
                    </tr>
                    : null
                  }
                </tbody>
              </DataWapperTable>
              {fees?.length > feeFieldArray?.fields?.length ?
                <Button compact size='xs' color='teal' rightIcon={<Plus />} variant='subtle' onClick={() => feeFieldArray?.append({})}>Added Charges</Button> : null
              }
            </Grid.Col>
            <Grid.Col span={4} mt={'md'}>
              <Textarea
                value={notes}
                onChange={(event) => setNotes(event.currentTarget.value)}
                placeholder="Enter your notes..."
                label="Notes"
                minRows={4}
              />
            </Grid.Col>
          </Grid>
          <ActionButtons
            status={invoiceData?.status_value ? invoiceData?.status_value : 'draft'}
            base={'creation'}
            nextStage={stages?.next_stage}
            sendForReviewCB={handleReviewModal}
            sendForApprovalCB={handleReviewModal}
            saveCB={handleSubmit(onSubmit)}
            loading={{ save: buttonLoader?.save }}
            remarksCB={() => setRemarksOpen(true)}
            remarks={reviewTrail}
            currentStatus={invoiceData?.status_value}
            moduleApplicationId={invoiceData?.id}
            moduleName={'external_customer_invoice'}
            isReady={Boolean(stages?.next_stage)} />

          <Remarks
            open={open}
            setOpen={setOpen}
            type={stages?.next_stage}
            callback={onReview}
            remarksTitle={stages?.next_stage == 'review' ? 'Review Remarks' : 'Approval Remarks'}
            check={status}
            label={label}
            loading={remarksLoading}
            currentStatus={invoiceData?.status_value}
            moduleApplicationId={invoiceData?.id}
            moduleName={'external_customer_invoice'}
          />

          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={invoiceData?.code}
            title={sucessfulmodal?.title}
            href={'/external-customer-invoice'}
          />

          <DialogModal open={customerOnboarding} onClose={() => setCustomerOnboarding(false)} size={'90%'} title={<Title order={5}>External Customer Creation</Title>}>
            {customerOnboarding ? <CustomerCreationInvoiceTabs tabView={true} action={() => setCustomerOnboarding(false)} /> : null}
          </DialogModal>

        </Body>
      </Box>
    </>
  );
};

export default ManualCustomerInvoiceCreation;
