import Nums from "../components/Body/Nums";
import { dateFormat } from "../components/DateFormat/dateFormat";

const valueTypesMap = new Map();
valueTypesMap.set('text', v => v ? v : 'NA');
valueTypesMap.set('date', v => v ? dateFormat(v) : 'NA');
valueTypesMap.set('amount', (v, props={}) => <Nums styles={{textAlign: 'right'}} value={v} {...props} />);
valueTypesMap.set('datetime', v => v ? dateFormat(v, 'DD/MM/YYYY hh:mm A') : 'NA');
valueTypesMap.set('boolean', v => Boolean(v) ? 'Yes' : 'No');
valueTypesMap.set('time', v => v ? v : 'NA');

export const VALUE_TYPES = {
  text: 'text',
  date: 'date',
  amount: 'amount',
  datetime: 'datetime',
  time: 'time',
};

const changeValueFormat = (value, type='text', props={}) => {
  const t = valueTypesMap.has(type) ? type : 'text';

  return valueTypesMap.get(t)(value, props);
}

export default changeValueFormat;