import React from 'react'
import { Grid, Text, Stack, Title } from '@mantine/core';
import { COLORS } from '../../../constants';
import ControllerTextField from '../../../components/CommonComponents/TextField/ControllerTextField';
import ControllerSwitch from '../../../components/CommonComponents/TextField/ControllerSwitch';

/**
 * Renders a payment invoice component with form fields for various payment information.
 *
 * @param {object} control - Object that contains properties and methods for managing form state.
 * @param {object} errors - Object that contains form validation errors.
 * @param {function} setValue - Function that sets the value of a form field.
 * @param {object} placeholderStyle - Object that contains styles for the placeholder text in the form fields.
 */
const PaymentInvoiceComp = ({ control, errors, setValue, placeholderStyle }) => {
  return (
    <Grid gutter={'md'} sx={{maxHeight:"55vh",overflow:'hidden',overflowY: 'auto'}}>
      <Grid.Col span={12} sx={{ height: 'fit-content' }}>
        <Title order={6} mb={'md'}>Payments</Title>
        <Grid gutter={'md'}>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Application Method</Text>
              <ControllerTextField
                name={'application_method'}
                label={'Application Method'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Application Method'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.application_method?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Partner Type</Text>
              <ControllerTextField
                name={'partner_type'}
                label={'Partner Type'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Partner Type'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.partner_type?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Payment Terms Code</Text>
              <ControllerTextField
                name={'payment_terms_code'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Payment Terms Code'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.payment_terms_code?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Payment Method Code</Text>
              <ControllerTextField
                name={'payment_method_code'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Payment Method Code'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.payment_method_code?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Reminder Terms Code</Text>
              <ControllerTextField
                name={'reminder_terms_code'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Reminder Terms Code'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.reminder_terms_code?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Fin. Charge Terms Code</Text>
              <ControllerTextField
                name={'fin_charge_terms_code'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Fin. Charge Terms Code'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.charge_terms_code?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Cash Flow Payment Terms Code</Text>
              <ControllerTextField
                name={'cash_flow_payment_terms_code'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Cash Flow Payment Terms Code'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.cash_flow_payment_terms_code?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Last Statement No.</Text>
              <ControllerTextField
                name={'last_statement_no'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Last Statement No.'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.last_statement_no?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Preferred Bank Account</Text>
              <ControllerTextField
                name={'preferred_bank_account'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Preferred Bank Account'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.preferred_bank_account?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Print Statements</Text>
              <ControllerSwitch
                name={'print_statements'}
                displayLabel={false}
                spanSize={12}
                control={control}
                onLabel="Yes"
                offLabel="No"
                size={'md'}
                color="green"
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Block Payment Tolerance</Text>
              <ControllerSwitch
                name={'block_payment_tolerance'}
                displayLabel={false}
                spanSize={12}
                control={control}
                onLabel="Yes"
                offLabel="No"
                size={'md'}
                color="green"
              />
            </Stack>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  )
}

export default PaymentInvoiceComp
