import { Table, ActionIcon, Tooltip } from "@mantine/core";
import React, { useState } from "react";
import { COLORS } from "../../constants";
import Nums from "../../components/Body/Nums";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import { generateReceiptPdfbyID } from "../../services/pdf.service";
import { PDF_TEXT_MESSAGE } from "../../constants/textMessage";
import { ExternalLink, FileDownload } from "tabler-icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { openFile } from "../../utils/openFile";
import { APPROVED, AUTO_APPROVED, AUTO_CANCELLED, CANCELLATION_APPROVAL, CANCELLATION_REVIEW, CANCELLED, IN_APPROVAL, IN_REVIEW, OPEN } from "../../constants/status";
import { useNavigate } from "react-router-dom";

/*
 * In this comp displaying the list of Loans
 *@prop
 data- (array of object): contain the list of loans
 */
const CustomerLevelReceiptLoansTable = ({ data = [], type = "view", currentStatus, customerId }) => {
  const queryClient = useQueryClient();
  const [pdfLoading, setPDFLoading] = useState({});
  const navigation = useNavigate();

  const handlePDF = (id, index) => {
    setPDFLoading({ [index]: true });
    generateReceiptPdfbyID(id)
      .then((res) => {
        queryClient.invalidateQueries(["customer-level-receipt-loan"]);
        displayNotification({
          message: PDF_TEXT_MESSAGE?.receipt,
          variant: 'success',
        });
        openFile(res);
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setPDFLoading({ [index]: false });
      });
  };

  const handleNavigation = (data) => {
    if (data?.receipt_current_status) {
      if (data?.receipt_current_status == IN_APPROVAL || data?.receipt_current_status == IN_REVIEW) {
        navigation(`/receipt/review/${customerId}/${data?.receipt_id}`);
      } else if (data?.receipt_current_status == APPROVED || data?.receipt_current_status == AUTO_APPROVED) {
        navigation(`/receipt/approved/${customerId}/${data?.receipt_id}`);
      } else if (data?.receipt_current_status == AUTO_CANCELLED) {
        navigation(`/receipt/cancelled/${customerId}/${data?.receipt_id}`);
      } else if (data?.receipt_current_status == CANCELLED) {
        navigation(`/cancellation/receipt-cancelled/${data?.receipt_id}/${customerId}`);
      } else if (data?.receipt_current_status == CANCELLATION_REVIEW || data?.receipt_current_status == CANCELLATION_APPROVAL) {
        navigation(`/cancellation/receipt-cancel/${data?.receipt_id}/${customerId}`);
      }
    }
  }

  return (
    <Table verticalSpacing="xs" fontSize="xs">
      <thead style={{ backgroundColor: COLORS.primary.light1 }}>
        <tr>
          <th>Tranche Code</th>
          {type === "review" ? <th>Receipt Code</th> : null}
          <th>Loan Amount</th>
          <th>Disb Date</th>
          <th>DPD</th>
          <th>Principal</th>
          <th>Interest</th>
          <th>Penal F1</th>
          <th>Penal F2</th>
          <th>Due Amount</th>
          <th>Remaining Amount</th>
          {type === "review" ? <th>PDF</th> : null}
          {['approved']?.includes(currentStatus) ? <th></th> : null}
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) =>
          item?.loan_amount ? (
            <tr key={index}>
              <td>{item?.prospect_code}</td>
              {type === "review" ? <td>{item?.receipt_code}</td> : null}
              <td>
                <Nums value={item?.loan_amount} />
              </td>
              <td>{dateFormat(item?.disbursed_date)}</td>
              <td>{item?.dpd}</td>
              <td>
                <Nums value={item?.outstanding_principle} />
              </td>
              <td>
                <Nums
                  value={
                    parseInt(item?.outstanding_interest) +
                    parseInt(item?.outstanding_spread) +
                    parseInt(item?.outstanding_addon_spread)
                  }
                />
              </td>
              <td>
                <Nums
                  value={
                    parseInt(item?.outstanding_interest_amount_during_penalty) +
                    parseInt(item?.outstanding_spread_amount_during_penalty) +
                    parseInt(
                      item?.outstanding_addon_spread_amount_during_penalty
                    )
                  }
                />
              </td>
              <td>
                <Nums value={item?.outstanding_penal_interest_amount} />
              </td>
              <td>
                <Nums value={item?.loan_due_amount} />
              </td>
              <td>
                <Nums value={item?.remaining_amount} />
              </td>
              {type === "review" ? (
                <td>
                  <ActionIcon
                    onClick={() => handlePDF(item?.receipt_id, index)}
                    loading={pdfLoading[index]}
                  >
                    <FileDownload color={COLORS.success} />
                  </ActionIcon>
                </td>
              ) : null}
              {['approved']?.includes(currentStatus)
                ? (<td>
                  <Tooltip label={'Click to view Receipt'} color='gray' position='top-start'>
                    <span style={{ cursor: 'pointer' }}>
                      <ExternalLink onClick={() => handleNavigation(item)} color='green' size={14} />
                    </span>
                  </Tooltip>
                </td>)
                : null
              }
            </tr>
          ) : null
        )}
      </tbody>
    </Table>
  );
};

export default CustomerLevelReceiptLoansTable;
