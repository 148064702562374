import { Box, Table, Grid } from "@mantine/core";
import React from "react";
import COLORS from '../../../../constants/colors';
import { dateFormat } from '../../../../components/DateFormat/dateFormat';
import { Skeleton } from '@mantine/core';

const Holiday = ({ holiday, loading }) => {

  return (
    <Box>
      <Box>
        <Grid gutter="xs" mt="md">
          <Grid.Col span={12}>
            <Table>
                <thead
                    style={{
                        backgroundColor: COLORS.primary.light1,
                    }}
                >
                    <tr>
                        <th>Date</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {holiday?.map((item) => (
                      <tr>
                          <td>{dateFormat(item?.date)}</td>
                          <td>{item?.description}</td>
                      </tr>
                    ))}
                    {/** If loading is true then the tabel with loading for four row will get displayed */}
                    {loading && [1,2,3,4].map((item) => (
                      <tr>
                          <td><Skeleton height={15} radius="xl" width={'100px'} /></td>
                          <td><Skeleton height={15} radius="xl" width={'100px'} /></td>
                      </tr>
                    ))}
                </tbody>
            </Table>
          </Grid.Col>
        </Grid>
      </Box>      
    </Box>
  );
};

export default Holiday;
