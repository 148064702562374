import { createColumnHelper } from '@tanstack/react-table';
import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Body from '../../components/Body/Body';
import Nums from '../../components/Body/Nums';
import DataTableWrapper from '../../components/CommonComponents/Table/DataTableWrapper';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import {
    APPROVED,
    AUTO_APPROVED,
    AUTO_CANCELLED,
    CANCELLED
} from '../../constants/status';
import EntityFilter from './../../components/CommonComponents/Filter/EntityFilter';
import { getAlleInvoice } from '../../services/gstEbill.service';

const GSTEbillTable = () => {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [records, setRecords] = useState({label: 'Show 10', value: 10});
  // get the search params
  const [searchParams, setSearchParams] = useSearchParams();
  const params = {}
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  
  const { data: gstEbillData = {}, isLoading } = useQuery(
    ['gst-e-bill-table', page, search, records, params],
    () => getAlleInvoice({page, search, records: records?.value, ...params}),
    {
      refetchOnWindowFocus: false,
    }
  );

  /** used to change the page to 1 while the search us used 
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1)
  }, [search, searchParams.get("status"), searchParams.get("entity")])

  const onRowClick = (i) => {
    if (i?.status_value == APPROVED || i?.status_value == AUTO_APPROVED) {
      navigate(`${i?.id}/${i?.customer_id}`);
    } else if (i?.status_value == CANCELLED || i?.status_value == AUTO_CANCELLED) {
      navigate(`cancelled/${i?.id}/${i?.customer_id}`);
    } 
  };

  const columnData = [
    columnHelper.accessor('customer_id', {
      header: 'Customer Id',
      cell: (value) => (
        <span
          style={{ color: '#0063FF', cursor: 'pointer' }}
        >
          {value.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor('customer_name', {
      header: 'Customer Name',
    }),
    columnHelper.accessor('fee_amount', {
      header: 'Fee Amount',
      cell: (value) =>  <Nums value={value.getValue()} />,
    }),
    columnHelper.accessor('gst', {
      header: 'GST Amount',
      cell: (value) =>  <Nums value={value.getValue()} />,
    }),
    columnHelper.accessor('imd_date', {
      header: 'Fee Date',
      cell: (value) => dateFormat(value.getValue()),
    }),
    columnHelper.accessor('maker_name', {
      header: 'Created By',
    }),
  ];

  return (
    <Body background={false}>
      <DataTableWrapper
        title='GST e-Invoice'
        filter={false}
        rowData={gstEbillData?.data}
        module_name='e-invoice'
        columnData={columnData}
        onRowClick={onRowClick}
        noDataText='No GST e-Invoice details yet!'
        noDataSubText='This Section will contain list of created Fee details.'
        useApiPagination
        totalNoOfPages= {gstEbillData?.total_number_of_pages}
        totalNoOfRecords={gstEbillData?.total_number_of_records}
        page={page}
        setPage={setPage}
        addDataOption={false}
        options={
          <EntityFilter useQueryParams />
        }
        apiSearch={setSearch}
        showStatus
        customStatus={[
          {module_status: 'unprocessed'},
          {module_status: 'processed'},
          {module_status: 'cancelled'}
        ]}
        rowLength={records}
        setRowLength={setRecords}
        // when the status is none then the loading is set to false other wise it will dependent on isLoading
        loading={isLoading}
        // options={}
      />
    </Body>
  );
};

export default GSTEbillTable;
