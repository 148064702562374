import React from 'react'
import { Grid, Box } from '@mantine/core';
import DataViewerWapper from './../../../components/CommonComponents/Preview/DataViewerWapper';
import TitleViewer from '../../../components/CommonComponents/Title/TitleViewer';
import { ViewFormData } from '../../../components/CommonComponents/Preview/FilePreview';

/**
 * Renders tax information, GST details, and PAN details on an invoice review component.
 *
 * @param {Object} data - An object of data.
 */
const TaxInformationInvoiceReviewComp = ({ data }) => {
  return (
    <>
      <Grid gutter={'md'}>
        <Grid.Col span={6}>
          <DataViewerWapper>
            <Box mb={'sm'}>
              <TitleViewer title={"Tax Information Details"} />
            </Box>
            <ViewFormData
              label={'Tax'}
              value={data?.data?.tax}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'L.S.T. No.'}
              value={data?.data?.lst_no}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'C.S.T. No.'}
              value={data?.data?.cst_no}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Tax Liable'}
              value={data?.data?.tax_liable ? 'Yes' : 'No'}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Excise'}
              value={data?.data?.excise}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'E.C.C. No.'}
              value={data?.data?.ecc_no}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Range'}
              value={data?.data?.range}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Collectorate'}
              value={data?.data?.collectorate}
              loading={data?.isLoading}
            />
          </DataViewerWapper>
        </Grid.Col>
        <Grid.Col span={6}>
          <DataViewerWapper>
            <Box mb={'sm'}>
              <TitleViewer title={"GST Details"} />
            </Box>
            <ViewFormData
              label={'GST'}
              value={data?.data?.gst_number}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'GST Customer Type'}
              value={data?.data?.gst_customer_type}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'GST Registration Type'}
              value={data?.data?.gst_registration_type}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'GST Registration No.'}
              value={data?.data?.gst_registration_no}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'e-Commerce Operator'}
              value={data?.data?.ecommerce_operator ? 'Yes' : 'No'}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'ARN No.'}
              value={data?.data?.arn_no}
              loading={data?.isLoading}
            />
          </DataViewerWapper>
        </Grid.Col>
        <Grid.Col span={6}>
          <DataViewerWapper>
            <Box mb={'sm'}>
              <TitleViewer title={"PAN Details"} />
            </Box>
            <ViewFormData
              label={'ARN No.'}
              value={data?.data?.arn_no}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Income Tax'}
              value={data?.data?.income_tax}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'P.A.N. No.'}
              value={data?.data?.pan_no}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'P.A.N. Status'}
              value={data?.data?.pan_status}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'P.A.N. Reference No.'}
              value={data?.data?.pan_reference_no}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'VAT'}
              value={data?.data?.vat}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'T.I.N. No.'}
              value={data?.data?.tin_no}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Export or Deemed Export'}
              value={data?.data?.export_or_deemed_export ? 'Yes' : 'No'}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'VAT Exempted'}
              value={data?.data?.vat_exempted ? 'Yes' : 'No'}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Service Tax'}
              value={data?.data?.service_tax}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Nature of Services'}
              value={data?.data?.nature_of_services}
              loading={data?.isLoading}
            />
            <ViewFormData
              label={'Service Entity Type'}
              value={data?.data?.service_entity_type}
              loading={data?.isLoading}
            />
          </DataViewerWapper>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default TaxInformationInvoiceReviewComp