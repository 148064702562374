import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Box,
  TextInput,
  Text,
  Button,
  Title,
  PasswordInput,
  Badge,
} from '@mantine/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AlertCircle, Lock, PhonePlus } from 'tabler-icons-react';
import * as yup from 'yup';
import { getUsersPermissions } from '../../services/common.service';
import { loginLMS } from '../../services/login.service';
import { loginStyles } from './Login';
import useAuthStore from '../../store/authStore';

/*
  The schema validates the form input values
*/
const schema = yup.object({
  mobile: yup
    .string()
    .required('Please Enter a valid mobile number')
    .test(
      'maxDigits',
      'Mobile Number must have 10 digits',
      (number) => String(number).length === 10
    ),
  password: yup.string().nullable().required('Enter password'),
});

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    /*
      every field is validated onBlur
    */
    resolver: yupResolver(schema),
  });
  const { classes } = loginStyles();
  const { saveAuth } = useAuthStore(authStore => ({
    saveAuth: authStore.saveAuth
  }))
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const from = window.location.state?.from?.pathname || '/';

  const onSubmit = (values) => {
    setLoading(true);
    loginLMS(values?.mobile, values?.password)
      .then((data) => {
        setLoading(false);
        /*
          Data response while logging in saved in the context API.
        */
        // setAuth(data);
        /*
          The get permission api call is written inside this scope, because i have spread
          the response from the getUserPermission in the context API so that i can access permissions anywhere in this project.
        */
        console.log('Login Response >> ', data);
        getUsersPermissions(data?.access_token)
          .then((res) => {
            setLoading(false);
            // setAuth({ ...data, permissions: res });

            saveAuth({ ...data, permissions: res });
            // navigate(from, { replace: true });
          })
          .catch((e) => setLoading(false));
      })
      .catch((e) => {
        setLoading(false);
        setErrorMsg(e);
      });
  };

  return (
    <Box className={classes.login_card}>
      <Box className={classes.logo_container}>
        <img alt='Logo' src='/images/logo-white.png' className={classes.logo} />
        {["development", "uat", "stage"].includes(process.env.REACT_APP_ENV) 
          ? <Badge ml={'50px'} mt={'lg'} color="red" size="xl" radius="lg" variant="filled">{process.env.REACT_APP_ENV}</Badge> 
          : null
        }
      </Box>
      <Box sx={{ marginBottom: '2rem' }}>
        <Title order={2}>Login</Title>
        <Text variant='h3' style={{ color: 'rgb(0,0,0,0.4)' }}>
          Please login to your account
        </Text>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          id='mobile'
          label='Mobile Number'
          type='number'
          variant='filled'
          fullwidth="true"
          sx={{ height: '85px' }}
          error={errors?.mobile?.message}
          icon={<PhonePlus size={16} />}
          {...register('mobile', { required: true })}
        />
        <PasswordInput
          id='password'
          variant='filled'
          label='Password'
          fullwidth="true"
          sx={{ height: '85px', marginTop: 5 }}
          error={errors?.password?.message}
          icon={<Lock size={16} />}
          {...register('password', { required: true })}
        />
        <Button
          variant='filled'
          loading={loading}
          mt={14}
          color='green'
          size='md'
          type='submit'
          fullwidth="true"
        >
          Login
        </Button>
      </form>
      {errorMsg && (
        <Box mt='md'>
          <Alert icon={<AlertCircle size={16} />} withCloseButton color='red'>
            {errorMsg?.message || errorMsg}
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default LoginForm;
