import React, { useState } from "react";
import { useParams } from "react-router";
import Body from "../../components/Body/Body";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  Box,
  Grid,
  Table,
  Skeleton,
  Tabs,
  Badge,
  ActionIcon,
  Button,
  Text,
  Center,
  Tooltip,
  Group,
  Popover,
} from "@mantine/core";
import ImdStyle from "../IMD/Imd.styles";
import IdBadge from "../../components/CommonComponents/Preview/IdBadge";
import DataViewerWapper from "../../components/CommonComponents/Preview/DataViewerWapper";
import { LoanStatusToken, StatusToken, ViewData } from "../../components/CommonComponents/Preview/FilePreview";
import Nums from "../../components/Body/Nums";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import ActionButtons from "../../components/CommonComponents/ActionButtons/ActionButtons";
import Remarks from "../../components/CommonComponents/Remarks/Remarks";
import ModalDataViwer from "../../components/SucessfullModal/ModalDataViwer";
import ViewRemark from "../../components/Remark/ViewRemark";
import { getStageforModules } from "../../services/module.service";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllRemarksDisb } from "../../services/disbursement.service";
import COLORS from "../../constants/colors";
import { getAllRemarksDisbursementBatch, getDisbursementBatchBankFile, getDisbursementBatchById, removeDisbMEMOInBatch, sendDisbursementBatchForCancel, sendDisbursementBatchForNextStage, sendDisbursementBatchForReject } from "../../services/disbBatch.service";
import { APPROVED, AUTO_CANCELLED, CANCELLATION_REVIEW, CANCELLED, IN_APPROVAL, IN_REVIEW, REJECTED } from "../../constants/status";
import { STAGE_CHANGE_TEXT } from "../../constants";
import { sumBy } from "lodash";
import TitleViewer from "../../components/CommonComponents/Title/TitleViewer";
import { sendDisbursementBatchForPushback } from './../../services/disbBatch.service';
import { CANCELLATION_APPROVAL } from "../../constants/status";
import { LOAN_STATUS_COLORS, STATUS_COLORS } from "../../utils/StatusTable";
import { Download, ExternalLink, FileDownload, InfoCircle, Trash } from "tabler-icons-react";
import { useNavigate } from 'react-router-dom';
import styled from "@emotion/styled";
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { openFile } from "../../utils/openFile";
import DialogModal from "../../components/CommonComponents/Modal/DialogModal";

const DataTableWapper = styled.div`
  .trash {
    display: none;
  }
  .rowList:hover {
    .trash {
      display: block;
    }
  }
  .fixTableHead thead {
    position: sticky;
    top: 0;
  }
`;

const SBI_BANK = 'STATE BANK OF INDIA';

const DisbBatchReview = ({ home = "/Batch-disb" }) => {
  const { BatchId } = useParams();
  const navigate = useNavigate()
  const queryClient = useQueryClient();
  const [pdfLoading, setpdfLoading] = useState();
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState();
  const [remarksTitle, setRemarksTitle] = useState();
  const [label, setLabel] = useState();
  const [remarksLoading, setRemarksLoading] = useState();
  const [sucessfulmodal, setSucessfulmodal] = useState({});
  const [bankName, setBankName] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState({});

  const { data: disbDataById = [], isLoading: loading } = useQuery(
    ["disb-Batch", BatchId],
    () => getDisbursementBatchById(BatchId),
    {
      onSuccess: (data) => {
        console.log(data);
        setBankName(`1`);
      },
      enabled: Boolean(BatchId),
    }
  );

  const { data: stages = [] } = useQuery(
    ["disb-stages"],
    () => getStageforModules("disbursement_batch"),
    {
      enabled: Boolean(disbDataById?.batch_info?.status_value),
      // enabled: false,
      select: (dataItem) => {
        return {
          current_stage: disbDataById?.batch_info?.status_value,
          next_stage:
            dataItem[dataItem.indexOf(disbDataById?.batch_info?.status_value) + 1],
        };
      },
      refetchOnWindowFocus: false,
    }
  );

  const { data: reviewTrail = [] } = useQuery(
    ["remarks", BatchId],
    () => getAllRemarksDisbursementBatch(BatchId),
    {
      enabled: Boolean(BatchId),
    }
  );

  const onSendforNextStageDisb = (remarks, status) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true);
    sendDisbursementBatchForNextStage(BatchId, body)
      .then((res) => {
        setSucessfulmodal({
          open: true, title:
            status == IN_APPROVAL ? STAGE_CHANGE_TEXT?.approved
              : status == CANCELLATION_REVIEW ? STAGE_CHANGE_TEXT?.reviewCancel
                : STAGE_CHANGE_TEXT?.approveCancel
        });
      })
      .catch((e) => {
        displayNotification({
          id: 'disb-approval',
          message: e?.message || e,
          variant: 'error',
        });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const handleReject = (remarks) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true);
    sendDisbursementBatchForReject(BatchId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.reject });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const handlePushback = (remarks) => {
    const body = {
      remarks,
    };

    setRemarksLoading(true);
    sendDisbursementBatchForPushback(BatchId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.pushback });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const handleCancel = (remarks, status, reason) => {
    const body = {
      remarks,
      status,
      reason: reason?.value,
    };

    setRemarksLoading(true);
    sendDisbursementBatchForCancel(BatchId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.cancel });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const callFunction = (remarks, status, reason) => {
    switch (status) {
      case "review":
      case "approval":
      case 'cancellation':
        onSendforNextStageDisb(remarks, status);
        break;
      case 'pushback':
        handlePushback(remarks, status);
        break;
      case "reject":
        handleReject(remarks);
        break;
      case "cancel":
        handleCancel(remarks, status, reason);
        break;
      default:
        displayNotification({
          message: "Something went wrong, Please contact support!",
          variant: 'error',
        });
        break;
    }
  };

  // get total memo count
  const handleTotalMemoCount = () => {
    let totalCount = 0;
    disbDataById?.disbursements?.map((item) => {
      totalCount += item?.disbursement_list?.length;
    })
    return totalCount;
  }

  // handle pushback callback 
  const pushbackcallback = () => {
    setOpen(true);
    setRemarksTitle("Pushback Remarks");
    setStatus("pushback");
    setLabel("Pushback");
  }

  const handleDisbMemoNavigate = (i) => {
    if (i?.status_value == IN_APPROVAL || i?.status_value == IN_REVIEW) {
      navigate(`/disb/review/${i?.id}/${i?.customer_id}`);
    } else if (i?.status_value == APPROVED) {
      navigate(`/disb/approved/${i?.id}/${i?.customer_id}`);
    } else if (i?.status_value == AUTO_CANCELLED) {
      navigate(`/disb/cancelled/${i?.id}/${i?.customer_id}`);
    } else if (i?.status_value == REJECTED) {
      navigate(`/disb/rejected/${i?.id}/${i?.customer_id}`);
    } else if (i?.status_value == CANCELLED) {
      navigate(`/cancellation/disb-cancelled/${i?.id}/${i?.customer_id}`);
    } else if (i?.status_value == CANCELLATION_REVIEW || i?.status_value == CANCELLATION_APPROVAL) {
      navigate(`/cancellation/disb-cancel/${i?.id}/${i?.customer_id}`);
    }
  }

  const handleBatchMEMODelete = () => {
    setOpenDeleteModal((old) => ({ ...old, loading: 'true' }));
    removeDisbMEMOInBatch(BatchId, openDeleteModal?.data?.id)
      .then((res) => {
        displayNotification({ message: res?.message || res, variant: 'success' });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setOpenDeleteModal({});
        queryClient.invalidateQueries(['disb-Batch']);
      })
  }

  /**
   * Downloads the file of the specified type.
   *
   * @param {string} type - The type of file to download.
   */
  const handleFileDownload = (type) => {
    setpdfLoading((old) =>  ({...old, [`${type}_loading`]: true}));
    getDisbursementBatchBankFile({ batch_id: BatchId, bank_category: type })
      .then((res) => {
        if(res?.message) {
          displayNotification({message: res?.message, variant: 'error'});
        } else if (typeof res === 'string') {
          const blob = new Blob([res]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'file.txt';
          link.click();
        } else {
          displayNotification({message: 'It seems server is busy. Please try again after sometime!', variant: 'error'});
        }
      })
      .catch((e) => {
        displayNotification({message: e?.message || e, variant: 'error'});
      })
      .finally(() => {
        setpdfLoading({modal: false, [`${type}_loading`]: false});        
      })
  }

  return (
    <>
      <Body background={false}>
        <PageTitle title={"Disbursement Batch"} />
      </Body>
      <Box sx={{ position: "relative" }}>
        <Body>
          {disbDataById?.batch_info?.batch_code && (
            <div style={ImdStyle.flag}>
              <IdBadge
                remarks={disbDataById?.batch_info?.status_value}
                label={disbDataById?.batch_info?.batch_code}
              />
            </div>
          )}
          <Grid gutter="lg">
            <Grid.Col span={5}>
              <DataViewerWapper p={30}>
                <ViewData
                  title={"Net Disb Amount"}
                  value={disbDataById?.batch_info?.total_net_disb_amount}
                  loading={loading}
                />
                <ViewData
                  title={"Memo Count"}
                  value={disbDataById?.batch_info?.disbursement_count}
                  loading={loading}
                  type="text"
                />
                <ViewData
                  title={"Tranche Amount"}
                  value={disbDataById?.batch_info?.total_tranche_amount}
                  loading={loading}
                />
                <ViewData
                  title={"Created Date"}
                  value={disbDataById?.batch_info?.created_on}
                  loading={loading}
                  type="date"
                />
                <ViewData
                  title={"Created By"}
                  value={disbDataById?.batch_info?.maker_name}
                  loading={loading}
                  type="text"
                />
              </DataViewerWapper>
            </Grid.Col>
            <Grid.Col span={5}>
              <DataViewerWapper p={20}>
                <Grid.Col p={0} mb={'md'}>
                  <TitleViewer title={'Bank wise net Disb Amount'} />
                </Grid.Col>
                {disbDataById?.disbursements?.length
                  ? disbDataById?.disbursements?.map((e, index) => {
                    return (
                      <ViewData
                        title={e?.name}
                        key={index}
                        value={sumBy(e?.disbursement_list, "net_disbursement_amount")}
                        loading={loading}
                      />
                    )
                  })
                  : <ViewData title={'Bank Name'} value={''} loading={loading} />
                }
              </DataViewerWapper>
            </Grid.Col>
            <Grid.Col span={12} mt={"md"}>
              <Tabs
                value={`${bankName}`}
                onTabChange={(e) => setBankName(e)}
                styles={(them) => ({
                  tab: {
                    "&:is([aria-selected=true])": {
                      fontWeight: 600,
                      backgroundColor: "#f9f9f9",
                    },
                  }
                })}
              >
                <Tabs.List>
                  {disbDataById?.disbursements?.map((e, i) => (
                    <Tabs.Tab
                      value={`${i + 1}`}
                      key={i}
                      rightSection={
                        <Badge color={'gray'}>
                          {e?.disbursement_list?.length}
                        </Badge>
                      }
                    >
                      <Group spacing={2}>
                        {e?.name}
                        {e?.name?.toUpperCase() === SBI_BANK ?
                          <Tooltip
                            label={<Text size={'xs'} sx={{fontWeight: 500}}>Click to download file</Text>}
                            withArrow
                            position="top"
                            color="gray"
                          >
                            <Popover withArrow opened={pdfLoading?.modal} closeOnClickOutside>
                              <Popover.Target>
                                <ActionIcon
                                  p={0}
                                  m={0}
                                  sx={{
                                    height: 'fit-content',
                                    minHeight: 'fit-content'
                                  }}
                                  onClick={() => setpdfLoading((old) => ({...old, modal: !old?.modal}))}
                                >
                                  <Download color={COLORS.icon.download} size={18} />
                                </ActionIcon>
                              </Popover.Target>
                              <Popover.Dropdown sx={{padding: 8}}>
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: 8}}>
                                  {e?.disbursement_list?.filter((f) => f?.target_bank_name?.toUpperCase() === SBI_BANK).length ?
                                    <Button
                                      variant="light"
                                      size={'xs'}
                                      color={'teal'}
                                      sx={{width: '100%'}}
                                      leftIcon={<FileDownload color={COLORS.icon.download} size={18} />}
                                      onClick={() => handleFileDownload('SBIN-SBIN')}
                                      loading={pdfLoading?.[`SBIN-SBIN_loading`]}
                                    >
                                      SBI to SBI File
                                    </Button>
                                    : null
                                  }
                                  {e?.disbursement_list?.filter((f) => f?.target_bank_name?.toUpperCase() !== SBI_BANK).length ?
                                    <Button
                                      variant="light"
                                      size={'xs'}
                                      color={'teal'}
                                      sx={{width: '100%'}}
                                      leftIcon={<FileDownload color={COLORS.icon.download} size={18} />}
                                      onClick={() => handleFileDownload('SBIN-OTHR')}
                                      loading={pdfLoading?.[`SBIN-OTHR_loading`]}
                                    >
                                      SBI to Others File
                                    </Button>
                                    : null
                                  }
                                </Box>
                              </Popover.Dropdown>
                            </Popover>
                          </Tooltip> :
                          null
                        }
                      </Group>
                    </Tabs.Tab>
                  ))}
                </Tabs.List>
              </Tabs>
              <DataTableWapper>

                <Table fontSize={"xs"} striped highlightOnHover>
                  <thead style={{ backgroundColor: COLORS.primary.light1 }}>
                    <tr>
                      <th>Tranche Code</th>
                      <th>Customer Name</th>
                      <th style={{ textAlign: 'right' }}>Tranche Amount</th>
                      <th style={{ textAlign: 'right' }}>Net Disb Amount</th>
                      <th>Scheme</th>
                      <th>Bank Name</th>
                      <th>Bank No</th>
                      <th>Status</th>
                      <th>Loan Status</th>
                      <th>Created Date</th>
                      <th></th>
                      {(disbDataById?.batch_info?.status_value === IN_APPROVAL && handleTotalMemoCount() > 1) ? <th style={{ width: '50px' }}></th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      // loading
                      disbDataById?.disbursements?.length
                        ? disbDataById?.disbursements[parseInt(bankName) - 1]?.disbursement_list?.map((item, index) => (
                          <tr key={index} className="rowList">
                            <td>{item?.tranche_code}</td>
                            <td>{item?.customer_name}</td>
                            <td style={{ textAlign: 'right' }}>
                              <Nums value={item?.tranche_amount} />
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              <Nums value={item?.net_disbursement_amount} />
                            </td>
                            <td>{item?.product_name}</td>
                            <td>{item?.target_bank_name}</td>
                            <td>{item?.target_bank_acc_no}</td>
                            <td>
                              <StatusToken
                                label={item?.status_value}
                                color={STATUS_COLORS?.[item?.status_value]}
                              />
                            </td>
                            <td>
                              <LoanStatusToken
                                label={item?.loan_status}
                                color={LOAN_STATUS_COLORS?.[item?.loan_status]}
                              />
                            </td>
                            <td>{dateFormat(item?.created_date)}</td>
                            <td>
                              <Tooltip label={'View Memo'} color={'gray'} withArrow position="top-end">
                                <span>
                                  <ActionIcon onClick={() => handleDisbMemoNavigate(item)}>
                                    <ExternalLink size={16} color="green" />
                                  </ActionIcon>
                                </span>
                              </Tooltip>
                            </td>
                            {(disbDataById?.batch_info?.status_value === IN_APPROVAL && handleTotalMemoCount() > 1) ? <td>
                              <Tooltip label={'Remove form list'} withArrow position="top-end" color="gray">
                                <span className="trash">
                                  <ActionIcon onClick={() => setOpenDeleteModal({ modal: true, data: item })}>
                                    <Trash size={16} color="red" />
                                  </ActionIcon>
                                </span>
                              </Tooltip>
                            </td> : null}
                          </tr>
                        )
                        )
                        : [1, 2, 3, 4, 5]?.map((e, index) => (
                          <tr key={index}>
                            <td>
                              <Skeleton height={15} />
                            </td>
                            <td>
                              <Skeleton height={15} />
                            </td>
                            <td>
                              <Skeleton height={15} />
                            </td>
                            <td>
                              <Skeleton height={15} />
                            </td>
                            <td>
                              <Skeleton height={15} />
                            </td>
                            <td>
                              <Skeleton height={15} />
                            </td>
                            <td>
                              <Skeleton height={15} />
                            </td>
                            <td>
                              <Skeleton height={15} />
                            </td>
                            <td>
                              <Skeleton height={15} />
                            </td>
                            <td>
                              <Skeleton height={15} />
                            </td>
                            <td>
                              <Skeleton height={15} />
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </DataTableWapper>
            </Grid.Col>
          </Grid>
          {/** This Action buttons component is used to display the buttons in the flow */}
          <ActionButtons
            status={stages?.current_stage}
            isReady={Boolean(stages?.current_stage)}
            nextStage={stages?.next_stage}
            remarks={reviewTrail}
            pdfLoading={false}
            handlePDF={() => openFile(disbDataById?.link)}
            accountingCB={false}
            downloadText={
              disbDataById?.link
                ? "Download Batch File"
                : false
            }
            sendForApprovalCB={() => {
              setOpen(true);
              setRemarksTitle("Approval Remarks");
              setStatus("review");
              setLabel("Send for Approval");
            }}
            approvedCB={() => {
              setOpen(true);
              setRemarksTitle("Approval Remarks");
              setStatus("approval");
              setLabel("Approve");
            }}
            pushbackCB={[CANCELLATION_APPROVAL, CANCELLATION_REVIEW]?.includes(disbDataById?.batch_info?.status_value) ? pushbackcallback : false}
            rejectCB={() => {
              setOpen(true);
              setRemarksTitle("Reject Remarks");
              setStatus("reject");
              setLabel("Reject");
            }}
            remarksCB={() => setRemarksOpen(true)}
            cancelCB={() => {
              setOpen(true);
              setRemarksTitle("Cancellation Remarks");
              setStatus('cancellation');
              setLabel('Send for Cancellation')
            }}
            approveCancelCB={() => {
              setOpen(true);
              setRemarksTitle("Cancellation Remarks");
              setStatus('cancel');
              setLabel('Approve Cancellation')
            }}
          />

          {/** used to get remarks from user */}
          <Remarks
            open={open}
            setOpen={setOpen}
            type={stages?.next_stage}
            callback={callFunction}
            remarksTitle={remarksTitle}
            check={status}
            moduleName={"disbursement_batch"}
            label={label}
            loading={remarksLoading}
          />

          {/** used to display the suceccfull stage change modal */}
          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={disbDataById?.code}
            title={sucessfulmodal?.title}
            href={home}
            hreftext={"Back to list table"}
          />

          <DialogModal
            open={openDeleteModal?.modal}
            onClose={() => setOpenDeleteModal(false)}
            title={<Center><InfoCircle size="1rem" /> Are you Sure!</Center>}
            size={'md'}
            centered
          >
            <Text size={'sm'}>
              By pressing Proceed, you will remove <strong>{openDeleteModal?.data?.tranche_code}</strong> record from the list
            </Text>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }} mt={'lg'}>
              <Button variant="outline" onClick={() => setOpenDeleteModal({ modal: false })}>
                Cancel
              </Button>
              <Button color="red" onClick={() => handleBatchMEMODelete()} loading={openDeleteModal?.loading}>
                Proceed
              </Button>
            </Box>
          </DialogModal>

        </Body>
        {/** used to display the remarks */}

        <ViewRemark
          open={remarksOpen}
          onClose={setRemarksOpen}
          remarkTrail={reviewTrail}
        />
      </Box>
    </>
  );
};

export default DisbBatchReview;
