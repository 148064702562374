import { Box, Grid, Image } from '@mantine/core'
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { getAllRefundRemarksById, getRefundById, refundCancel, refundPushback, refundReject, refundStatusChange } from '../../services/refund.service';
import Body from '../../components/Body/Body';
import PageTitle from '../../components/PageTitle/PageTitle';
import DashBoardCustomerDetails from '../../components/DashboardCard/dashboard';
import ImdStyle from '../IMD/Imd.styles';
import DataViewerWapper from '../../components/CommonComponents/Preview/DataViewerWapper';
import { ViewFormData } from '../../components/CommonComponents/Preview/FilePreview';
import TitleViewer from '../../components/CommonComponents/Title/TitleViewer';
import { getStageforModules } from '../../services/module.service';
import { CANCELLATION_REVIEW, IN_APPROVAL, IN_REVIEW } from '../../constants/status';
import { GENERAL_IMG, STAGE_CHANGE_TEXT } from '../../constants';
import ActionButtons from '../../components/CommonComponents/ActionButtons/ActionButtons';
import Remarks from '../../components/CommonComponents/Remarks/Remarks';
import ModalDataViwer from '../../components/SucessfullModal/ModalDataViwer';
import ViewRemark from '../../components/Remark/ViewRemark';
import Nums from '../../components/Body/Nums';
import { CompanyBankDetails, customerBankDetails } from '../../services/imd.service';
import { openFile } from '../../utils/openFile';
import { generateRefundPDFbyID } from '../../services/pdf.service';
import AccountingInterfaceModal from '../../components/CommonComponents/AccuntingInterface/AccountingInterfaceModal';
import IdBadge from '../../components/CommonComponents/Preview/IdBadge';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification';

const RefundReview = ({ home = '/refund' }) => {

  const { customerId, refundId } = useParams();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [interfaceOpen, setInterfaceOpen] = useState(false);
  const [sucessfulmodal, setSucessfulmodal] = useState({});
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [remarksLoading, setRemarksLoading] = useState();
  const [pdfLoading, setPDFLoading] = useState(false);
  const [remarksTitle, setRemarksTitle] = useState();
  const [status, setStatus] = useState();
  const [label, setLabel] = useState();

  // get refund data by id
  const { data: refundData = {}, isLoading } = useQuery(
    ['refund-id', refundId],
    () => getRefundById(refundId),
    {
      select: (data) => {
        return data[0];
      }
    }
  )

  // get all active stages
  const { data: stages = [] } = useQuery(
    ['refund-stages'],
    () => getStageforModules('refund'),
    {
      enabled: Boolean(refundData?.status_value),
      select: (dataItem) => {
        return {
          'current_stage': refundData?.status_value,
          'next_stage': dataItem[dataItem.indexOf(refundData?.status_value) + 1]
        }
      },
    }
  );

  // get all remarks by id
  const { data: reviewTrail = [] } = useQuery(
    ['remarks', refundId],
    () => getAllRefundRemarksById(refundId),
    { enabled: Boolean(refundId) }
  );

  /** get customer banks */
  const { data: customerBank = [], isLoading: customerBankLoading } = useQuery(
    ['customer-bank', customerId],
    () => customerBankDetails(customerId),
    {
      enabled: Boolean(refundData?.target_bank_id),
      select: (data) => {
        return data?.find(e => e.id == refundData?.target_bank_id) || {}
      }
    }
  );

  // get company banks
  const { data: companyBank = [], isLoading: companyBankLoading } = useQuery(
    ['company-bank', refundData?.source_bank_id],
    () => CompanyBankDetails(refundData?.source_bank_id),
    {
      enabled: Boolean(refundData?.source_bank_id),
      select: (data) => {
        return data[0];
      }
    }
  );

  // Handles generating and displaying a PDF for a refund.
  const handlePDF = () => {
    setPDFLoading(true)
    generateRefundPDFbyID(refundId)
      .then((res) => {
        queryClient.invalidateQueries(['refund-id', refundId]);
        displayNotification({ message: 'PDF Generated Successfully', variant: 'success' })
        openFile(res)
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' })
      })
      .finally(() => {
        setPDFLoading(false)
      })
  }

  const onsendReceiptForNextStage = (remarks, status) => {
    const body = {
      remarks: remarks,
    };
    setRemarksLoading(true);
    refundStatusChange(refundId, body)
      .then((res) => {
        setSucessfulmodal({
          open: true, title:
            status == IN_REVIEW ? STAGE_CHANGE_TEXT?.approval
              : status == IN_APPROVAL ? STAGE_CHANGE_TEXT?.approved
                : status == CANCELLATION_REVIEW ? STAGE_CHANGE_TEXT?.reviewCancel
                  : STAGE_CHANGE_TEXT?.approveCancel
        });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const handlePushback = (remarks) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true);
    refundPushback(refundId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.pushback });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const handleReject = (remarks) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true);
    refundReject(refundId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.reject });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const handleCancel = (remarks, reason) => {
    const body = {
      remarks,
      reason: reason?.reason,
    };
    setRemarksLoading(true);
    refundCancel(refundId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.cancel });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const callFunction = (remarks, status, reason) => {
    switch (status) {
      case 'review':
      case 'approval':
      case 'cancellation':
        onsendReceiptForNextStage(remarks, status);
        break;
      case 'reject':
        handleReject(remarks);
        break;
      case 'pushback':
        handlePushback(remarks);
        break;
      case 'cancel':
        handleCancel(remarks, reason);
        break;
      default:
        displayNotification({
          message: 'Something went wrong, Please contact support!',
          variant: 'error',
        });
        break;
    }
  };

  return (
    <Box>
      <Body background={false}>
        <PageTitle title={refundData?.customer_name} id={refundData?.customer_id} />
      </Body>
      <DashBoardCustomerDetails id={customerId} codeName={'Refund Code'} code={refundData?.code} />
      <Box sx={{ position: 'relative' }}>
        <Body>
          {refundData?.code && (
            <div style={ImdStyle.flag}>
              <IdBadge
                remarks={refundData?.status_value}
                label={refundData?.code}
              />
            </div>
          )}
          <Grid gutter={'xs'} mt={'xs'}>
            <Grid.Col span={4}>
              <Grid.Col
                span={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  src={GENERAL_IMG}
                  alt="Illustration"
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={4}>
              <DataViewerWapper>
                <ViewFormData label={'Existing Excess'} value={<Nums value={refundData?.excess_amount} />} loading={isLoading} />
                <ViewFormData label={'Refund Amount'} value={<Nums value={refundData?.refund_amount} />} loading={isLoading} />
                <ViewFormData label={'Current Excess'} value={<Nums value={(parseInt(refundData?.excess_amount) - parseInt(refundData?.refund_amount))} />} loading={isLoading} />
                <ViewFormData label={'Refund date'} value={dateFormat(refundData?.refund_date, 'DD/MM/YYYY')} loading={isLoading} />
                <ViewFormData label={'Instrument Type'} value={refundData?.instrument_type} loading={isLoading} />
                <ViewFormData label={'Payment Ref No'} value={refundData?.payment_ref_no} loading={isLoading} />
              </DataViewerWapper>
            </Grid.Col>
            <Grid.Col span={4}>
              <DataViewerWapper>
                <Grid.Col span={12} p={0} mb={'xs'}>
                  <TitleViewer title={'Source Bank: Petromoney'} />
                </Grid.Col>
                <ViewFormData label={'Name'} value={companyBank?.bank_name} loading={companyBankLoading} />
                <ViewFormData label={'Acc No'} value={companyBank?.account_number} loading={companyBankLoading} />
                <ViewFormData label={'IFSC'} value={companyBank?.ifsc} loading={companyBankLoading} />
                <Grid.Col span={12} p={0} my={'xs'}>
                  <TitleViewer title={'Target Bank: Customer'} />
                </Grid.Col>
                <ViewFormData label={'Name'} value={customerBank?.bank_name} loading={customerBankLoading} />
                <ViewFormData label={'Acc No'} value={customerBank?.account_number} loading={customerBankLoading} />
                <ViewFormData label={'IFSC'} value={customerBank?.ifsc} loading={customerBankLoading} />
              </DataViewerWapper>
            </Grid.Col>
          </Grid>
          {/** This Action buttons component is used to display the buttons in the flow */}
          <ActionButtons
            status={stages?.current_stage}
            currentStatus={refundData?.status_value}
            moduleApplicationId={refundData?.id}
            moduleName={'refund'}
            nextStage={stages?.next_stage}
            isReady={Boolean(stages?.next_stage)}
            remarks={reviewTrail}
            accountingCB={() => setInterfaceOpen(true)}
            pdfLoading={pdfLoading}
            handlePDF={() => refundData?.refund_url ? openFile(refundData?.refund_url) : handlePDF()}
            downloadText={refundData?.refund_url ? 'Download Refund PDF' : 'Generate Refund PDF'}
            sendForApprovalCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('review'); setLabel('Send for Approval') }}
            approvedCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('approval'); setLabel('Approve') }}
            pushbackCB={() => { setOpen(true); setRemarksTitle("Pushback Remarks"); setStatus('pushback'); setLabel('Pushback') }}
            rejectCB={() => { setOpen(true); setRemarksTitle("Reject Remarks"); setStatus('reject'); setLabel('Reject') }}
            cancelCB={() => { setOpen(true); setRemarksTitle("Cancellation Remarks"); setStatus('cancellation'); setLabel('Send for Cancellation') }}
            approveCancelCB={() => { setOpen(true); setRemarksTitle("Are you sure?"); setStatus('cancel'); setLabel('Confirm') }}
            remarksCB={() => setRemarksOpen(true)} />
          {/** used to get the remarks from the user */}
          <Remarks
            open={open}
            setOpen={setOpen}
            type={stages?.next_stage}
            callback={callFunction}
            remarksTitle={remarksTitle}
            moduleName={'refund'}
            check={status}
            label={label}
            loading={remarksLoading}
            currentStatus={refundData?.status_value}
            moduleApplicationId={refundData?.id}
          />
          {/** display the modal on sucessfull status change */}
          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={refundData?.code}
            title={sucessfulmodal?.title}
            href={home}
            hreftext={'Back to table'}
          />
        </Body>
        <ViewRemark open={remarksOpen} onClose={setRemarksOpen} remarkTrail={reviewTrail} />
        <AccountingInterfaceModal open={interfaceOpen} close={() => setInterfaceOpen(false)} id={refundId} name={'refund'} code={refundData?.code} />
      </Box>
    </Box>
  )
}

export default RefundReview