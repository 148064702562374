import { getAPICall } from ".";
import { postAPICall } from "./postAPICall";

// Used to run the EOD
export const handleRunEOD = () => {
  return postAPICall("/procedure/loan-dues-overdue");
};

// Used to get the EOD status
export const handleEODStatus = () => {
  return getAPICall("/procedure/status/loan-dues-overdue");
};

// Used to get the last date of run
export const getLastDateofEOD = () => {
  return getAPICall("/procedure/system-date", { returnFirstItem: true });
};

// Used to get all eod
export const getallEODDetails = () => {
  return getAPICall("/procedure/history/loan-dues-overdue");
};

// Used to get all eod
export const getValidatedHoliday = () => {
  return getAPICall("/holiday-validation");
};

// activate the eod schedule
export const activateEODSchedule = ({body}) => {
  return postAPICall("/activate-eod-schedule", {body, returnObject: true});
}

// updating the eod schedule
export const UpdateEODSchedule = ({body}) => {
  return postAPICall("update-eod-schedule", {body, returnObject: true});
}

// deactivate the eod schedule
export const deactivateEODSchedule = ({body}) => {
  return postAPICall("/deactivate-eod-schedule", {body, returnObject: true});
}

export const getEODSchedule = () => {
  return getAPICall("/schedule-details");
}

export const getEODScheduleRemarks = () => {
  return getAPICall("/eod-schedule/history");
}
