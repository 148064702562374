import {
  ActionIcon,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Group,
  Text,
  Title,
  Collapse,
  Table,
  Tooltip,
  NumberInput,
  Card,
  Modal,
  Popover,
  TextInput,
  SegmentedControl,
  Stack,
  MantineProvider,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { Fragment, useState, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Check, ChevronDown, ChevronUp, Edit, Percentage, Plus, Trash, X } from 'tabler-icons-react';
import ControllerSegmentedControl from '../../../../components/CommonComponents/ControllerComponents/ControllerSegmentedControl';
import DraggableList from '../../../../components/CommonComponents/Dnd/DraggableList';
import { productSchema } from './ProductSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  addProductFeeById,
  deleteProductFeeById,
  getAllFeesByProductId,
  getAppropriation,
  getFeeType,
  getRepaymentType,
  getStakeholders,
  getTrancheAmountDetails,
  getTrancheAmountDetailsByProductId,
  removeProductBankByIds,
  saveProduct,
  updateProduct,
  updateProductFeeById,
  updateTrancheAmountDetailsByProductId,
} from '../../../../services/master.service';
import ControllerTextField from '../../../../components/CommonComponents/TextField/ControllerTextField';
import ControllerSelect from '../../../../components/CommonComponents/TextField/ControllerSelect';
import ControllerTextArea from '../../../../components/CommonComponents/TextField/ControllerTextArea';
import { currencyAsInt } from '../../../../components/Currency/currencyFormat';
import { getBusinessBanks } from '../../../../services/banks.service';
import { displayNotification } from '../../../../components/CommonComponents/Notification/displayNotification';
import Nums from '../../../../components/Body/Nums';
import styled from '@emotion/styled';
import TitleComp from '../../../../components/CommonComponents/TextComponent/TitleComp';
import ControllerMultiSelect from '../../../../components/CommonComponents/TextField/ControllerMultiSelect';
import { COLORS } from '../../../../constants';
import Select from 'react-select';

const DataTableWapper = styled.div`
  width: 100%;
  th {
    text-align: center;
  }
  td {
    text-align: center;
  }
  .edit {
    display: none;
  }
  .rowList:hover {
    .edit {
      display: block;
    }
  }
`

const DivDataContainer = styled.div`
  max-width: 170px;
  position: relative;
  .cardContainer {
    max-width: 170px; 
    border: 1px dashed gray;
    font-weight: 500;
  }
  .trash {
    position: absolute;
    top: -3px;
    right: -8px;
    display: none;
    &:hover {
      background: none;
    }
  },
  .bankList {
    width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;    
  }
  &:hover {
    .cardContainer {
      // filter: blur(.8px);
    }
    .trash {
      display: block;
    }
  }
`;

// used to handle the calculation
const oneLakh = 100000;

// There is no API to get this option, So we are using this
const feetype = [
  {
    label: 'Fee',
    value: 'fee',
  },
];

const FeeEditModal = ({ data, feeTypes, callback = {} }) => {
  const [stateObj, setStateObj] = useState(data);
  const [modalObj, setModalObj] = useState({ modal: false });
  return (
    <Popover opened={modalObj?.modal || false} onChange={(e) => { setModalObj({ modal: e }) }} position="top-end" withArrow>
      <Popover.Target>
        <ActionIcon
          onClick={() => setModalObj({ modal: !modalObj?.modal })}
        >
          <Edit size={16} color='green' />
        </ActionIcon>
      </Popover.Target>

      <Popover.Dropdown sx={{ background: COLORS.background }}>
        <Stack sx={{ width: 350 }}>
          <Title order={6} mb={0}>Update Fee</Title>
          <Box>
            <Text size={'xs'} sx={{ color: 'gray' }} mb={2}>Type</Text>
            <Select
              placeholder={'Select type of fee'}
              styles={{ menu: (provided) => ({ ...provided, zIndex: 9999, }), option: (provided) => ({ ...provided, fontSize: '13px', padding: '5px 15px 5px 15px' }), control: (provided) => ({ ...provided, fontSize: '13px' }) }}
              options={feetype}
              value={feetype?.find((o) => o?.value === stateObj?.category)}
              onChange={(val) =>
                setStateObj((o) => ({ modal: o?.modal, category: val?.value }))
              }
            />
          </Box>
          {stateObj?.category === 'fee' ?
            <Box>
              <Text size={'xs'} sx={{ color: 'gray' }} mb={2}>Fee Name</Text>
              <Select
                placeholder={'Select fee'}
                styles={{ menu: (provided) => ({ ...provided, zIndex: 9999, }), option: (provided) => ({ ...provided, fontSize: '13px', padding: '5px 15px 5px 15px' }), control: (provided) => ({ ...provided, fontSize: '13px' }) }}
                options={feeTypes}
                value={feeTypes?.find((o) => o?.value === stateObj?.fee_name)}
                onChange={(val) =>
                  setStateObj((o) => ({ ...o, fee_name: val.value }))
                }
              />
            </Box>
            : null
          }
          {stateObj?.category === 'others' ?
            <Box>
              <Text size={'xs'} sx={{ color: 'gray' }} mb={2}>Fee Name</Text>
              <TextInput
                placeholder={'Enter fee name'}
                value={stateObj?.fee_name}
                onChange={(val) =>
                  setStateObj((o) => ({ ...o, fee_name: val.target.value }))
                }
              />
            </Box>
            : null
          }
          {stateObj?.fee_name ?
            <Box>
              <Text size={'xs'} sx={{ color: 'gray' }} mb={2}>Fee Value</Text>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <MantineProvider theme={{ fontFamily: 'sans-serif' }}>
                  <TextInput
                    placeholder={'Enter Fee Value'}
                    type='number'
                    value={stateObj?.fee_value}
                    icon={stateObj?.fee_type === 'fixed' ? '₹' : null}
                    rightSection={stateObj?.fee_type === 'percent' ? '%' : null}
                    onChange={(val) =>
                      setStateObj((o) => ({ ...o, fee_value: val.target.value }))
                    }
                  />
                </MantineProvider>
                <SegmentedControl
                  color='blue'
                  value={stateObj?.fee_type}
                  onChange={(e) =>
                    setStateObj((o) => ({ ...o, fee_type: e }))
                  }
                  data={[
                    { label: 'Fixed', value: 'fixed' },
                    { label: 'Perc(%)', value: 'percent' },
                  ]}
                />
              </Box>
            </Box>
            : null
          }
          <Button
            disabled={!stateObj?.fee_value}
            color={'green'}
            loading={modalObj?.loading}
            onClick={() => { callback(stateObj, setModalObj); }}
          >
            Update
          </Button>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}

const Product = ({ id, formData, onClose }) => {
  const queryClient = useQueryClient();
  const [trancheAmountCollapse, setTrancheAmountCollapse] = useState(true)
  const [trancheAmountDetails, setTrancheAmountDetails] = useState([])
  const [trancheAmountIndex, setTrancheAmountIndex] = useState()
  const [loanLimitArray, setLoanLimitArray] = useState([]);
  const [changedValues, setChangedValues] = useState({});
  const [trashObject, setTrashObject] = useState(false);
  const [addFeeModalObj, setAddFeeModalObj] = useState({ modal: false });

  const detailTranche = useQuery(
    ['tranche-details'],
    () => getTrancheAmountDetails(),
    {
      onSuccess: (data) => {
        setLoanLimitArray(data);
        setTrancheAmountDetails(data)
      },
      enabled: Boolean(!id),
    }
  );

  const detailTrancheByProduct = useQuery(
    ['tranche-details-by-product'],
    () => getTrancheAmountDetailsByProductId(id),
    {
      onSuccess: (data) => {
        setLoanLimitArray(data);
      },
      enabled: Boolean(id),
    }
  );

  // ReactQuery Api calls
  const { data: listData = [] } = useQuery(['appropriation'], () => getAppropriation(), {
    refetchOnWindowFocus: false,
    select: res => res.map(d => d.name)
  });
  const { data: feeType = [] } = useQuery(['feeType'], () => getFeeType(), {
    refetchOnWindowFocus: false,
  });
  const { data: stakeholdersList = [] } = useQuery(['stakeholder'], () => getStakeholders(), {
    refetchOnWindowFocus: false,
  });
  const { data: repaymentType = [] } = useQuery(['repayment-tenures'], () => getRepaymentType(), {
    refetchOnWindowFocus: false,
  });
  const { data: bankList = [] } = useQuery(['bank-list'], () => getBusinessBanks({ category: 'all' }), {
    refetchOnWindowFocus: false,
  });
  const { data: feesDetails = [] } = useQuery(['fees-product', id], () => getAllFeesByProductId({ product_id: id }), {
    enabled: Boolean(id),
  })
  // Form Handlers
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(productSchema),
    defaultValues: {
      holiday_offset: 'precede',
      foreclosure_fee: 'no',
      product_banks: [],
      fee: feesDetails?.data,
      ...formData,
      interest_calc_period: formData?.interest_calc_period ? formData?.interest_calc_period.toString() : '360',
    },
  });
  let values = watch();

  const feeFieldArray = useFieldArray({
    control,
    name: 'fee',
  });

  useEffect(() => {
    if (feesDetails?.data?.length) {
      setValue('fee', feesDetails?.data);
    }
  }, [feesDetails?.data]);
  // React States
  const [communication, communicationHandler] = useState(
    formData
      ? Object.keys(formData?.communication_preference).filter(
        (k) => formData?.communication_preference[k] === true
      ) || []
      : ['sms', 'email', 'voice_call', 'whatsapp']
  );
  const [appropriation, setAppropriation] = useState([]);
  const [addSpread, addSpreadHandler] = useDisclosure(
    formData?.spread_rate ? true : false
  );
  const [addOnSpread, addOnSpreadHandler] = useDisclosure(
    formData?.addon_spread_rate ? true : false
  );

  /** used to handle the deduction value from string to int */
  const handleDeductions = () => {
    const deduction = []
    values?.fee?.map((item, i) => {
      item.fee_type == 'fixed' ? item.fee_value = currencyAsInt(item?.fee_value) : item.fee_value = parseFloat(item?.fee_value)

      deduction?.push(item)
    })
    return deduction
  }

  // Functions
  const onSaveProduct = (value) => {
    let com_perf = {};
    communication?.forEach((item) => {
      com_perf[item] = true;
    });

    // Body construction
    const body = {
      ...value,
      appropriation_logic: appropriation,
      communication_preference: com_perf,
      repayment_tenure_id: parseInt(values?.repayment_tenure_id),
      penal_rate: parseFloat(values?.penal_rate),
      spread_rate: parseFloat(value?.spread_rate),
      addon_spread_rate: parseFloat(values?.addon_spread_rate),
      interest_rate: parseFloat(values?.interest_rate),
      transaction_cost: currencyAsInt(values?.transaction_cost),
      fee: handleDeductions(),
      code: values?.code?.toUpperCase(),
      interest_entity: value?.interest_entity,
      spread_entity: value?.spread_entity,
      addon_spread_entity: value?.addon_spread_entity,
      interest_calc_period: parseInt(values?.interest_calc_period),
      loan_limits: !id ? trancheAmountDetails : null,
    };

    if (id) {
      updateProduct(id, body)
        .then((res) => {
          displayNotification({ message: 'Product Updated sucessfully', variant: 'success' })
          queryClient.invalidateQueries(['products']);
          onClose();
        })
        .catch((e) => {
          if (Object.keys(e?.RequestValidationError)?.length)
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          else {
            displayNotification({ message: e?.message || e, variant: 'error' })
          }
        });
    } else {
      saveProduct(body)
        .then((res) => {
          displayNotification({ message: 'Product created sucessfully', variant: 'success' })
          queryClient.invalidateQueries(['products']);
          onClose();
        })
        .catch((e) => {
          if (Object.keys(e?.RequestValidationError)?.length)
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          else {
            displayNotification({ message: e?.message || e, variant: 'error' })
          }
        });
    }
  };

  /**
   * Handles fees of a product based on the specified type.
   *
   * @param {string} type - The type of operation to perform (add, delete, or update).
   * @param {Object} data - The data related to the fee operation.
   */
  const handleFeesOfProduct = (type, data, state) => {
    // handling the fee update
    const updateFee = () => {
      updateProductFeeById({ product_id: id, fee_id: data?.product_fee_id, data })
        .then((res) => {
          displayNotification({ message: 'Fee updated successfully', variant: 'success' })
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' })
        })
        .finally(() => {
          queryClient.invalidateQueries(['fees-product']);
          setAddFeeModalObj({})
          state({ modal: false })
        })
    }
    // handling the fee delete
    const deleteFee = () => {
      deleteProductFeeById({ product_id: id, fee_id: data?.product_fee_id })
        .then((res) => {
          displayNotification({ message: 'Fee deleted successfully', variant: 'success' })
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' })
        })
        .finally(() => {
          queryClient.invalidateQueries(['fees-product']);
          setTrashObject({})
          setAddFeeModalObj({})
        })
    }
    // handling the fee addition
    const addFee = () => {
      addProductFeeById({ product_id: id, data: { fee: [data] } })
        .then((res) => {
          displayNotification({ message: 'Fee added successfully', variant: 'success' })
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' })
        })
        .finally(() => {
          queryClient.invalidateQueries(['fees-product']);
          setAddFeeModalObj({})
        })
    }

    if (id) {
      switch (type) {
        case 'add':
          addFee();
          break;
        case 'delete':
          deleteFee();
          break;
        case 'update':
          updateFee();
          break;
        default:
          displayNotification({ message: 'Invalid Fee', variant: 'error' });
          break;
      }
    } else {
      setAddFeeModalObj({})
    }
  }

  /* handling the selected entity
   * if one entity is selected then it will be disabled
   * once it is unselected then it will be enabled
   */
  const allowedStakeholdersList = () => {
    return stakeholdersList?.map((item) => ({
      ...item,
      isDisabled: [values?.interest_entity, values?.spread_entity, values?.addon_spread_entity].includes(item?.value)
    }))
  }

  const handleAmountInLack = (amount) => {
    let result;
    if (amount > 0) {
      result = 'L';
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
        <Nums value={amount / oneLakh} round={false} /> {result}
      </div>
    );
  }

  const handleEditableTrancheDetails = (obj) => {
    if (id) {
      updateTrancheAmountDetailsByProductId(obj?.id, { ...obj, ...changedValues })
        .then((res) => {
          displayNotification({ message: 'Updated Successfully', variant: 'success' })
          queryClient.invalidateQueries(['tranche-details-by-product']);
        })
        .catch((err) => {
          displayNotification({ message: err?.message || err, variant: 'error' })
        });
    } else {
      let index = trancheAmountDetails?.findIndex(e => e?.id === obj.id);
      if (index >= 0) {
        trancheAmountDetails?.splice(index, 1);
      }
      setTrancheAmountDetails((old) => [...old, { ...obj, ...changedValues }])
    }
    setTrancheAmountIndex()
    setChangedValues({})
  }

  const handleRemoveBankAPICall = (bank_id) => {
    removeProductBankByIds({ product_id: id, bank_id })
      .then((res) => {
        displayNotification({ message: 'Removed Successfully', variant: 'success' })
        queryClient.invalidateQueries(['products']);
        handleRemoveBankArray(bank_id)
      })
      .catch((e) => {
        console.log(e);
        displayNotification({ message: e?.message || e, variant: 'error' })
      })
      .finally(() => {
        setTrashObject(false)
      })
  }

  const handleRemoveBankArray = (bank_id) => {
    let index = values?.product_banks?.findIndex(e => e === bank_id);
    let value = values?.product_banks;
    if (index >= -1) {
      value?.splice(index, 1);
      setValue('product_banks', value || []);
    }
  }

  /**
   * Handles the trash action.
   */
  const handleTrashAction = () => {
    setTrashObject({ modal: true, loading: true })
    if (typeof trashObject?.index === 'number') {
      if (id) {
        handleFeesOfProduct('delete', trashObject?.data)
      } else {
        feeFieldArray.remove(trashObject?.index)
        setTrashObject({ modal: false, loading: false })
      }
    } else {
      handleRemoveBankAPICall(trashObject?.id)
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box>
        <Grid gutter='xs' mt='sm'>
          <Grid.Col span={12}>
            <ControllerTextField
              name='name'
              label='Product Name'
              required
              value={values?.name}
              control={control}
              errors={errors?.name?.message}
            />
          </Grid.Col>
          {!formData?.code ?
            <Grid.Col span={12}>
              <ControllerTextField
                name='code'
                label='Product Code'
                required
                value={values?.code?.toUpperCase()}
                control={control}
                errors={errors?.code?.message}
              />
            </Grid.Col> : null
          }
          <Grid.Col span={12}>
            <ControllerTextArea
              name='description'
              label='Product Description'
              required
              value={values?.description}
              control={control}
              errors={errors?.description?.message}
            />
          </Grid.Col>
          <Grid.Col span={12} mt='sm'>
            <ControllerSelect
              name='repayment_tenure_id'
              label='Repayment Type'
              required
              control={control}
              data={repaymentType}
              errors={errors?.repayment_tenure_id?.message}
            />
          </Grid.Col>
          <Grid.Col span={12} px={5}>
            <Box sx={{ border: "3px solid #e7e7e7", borderRadius: '5px', position: "relative", padding: '0px 11px' }}>
              <Title
                sx={{
                  position: "absolute",
                  fontSize: "12px",
                  top: "-18px",
                  left: "10px",
                  color: "#949494",
                  backgroundColor: "white",
                  padding: "0px 5px",
                }}
              >
                Interest Fields
              </Title>
              <Grid.Col
                span={12}
                mt="xs"
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <Grid.Col span={3} p={0} pr={5}>
                  <Title
                    sx={{ fontSize: "14px", lineHeight: "20px" }}
                    mb={"sm"}
                  >
                    <TitleComp label={"Interest"} />
                  </Title>
                  <ControllerTextField
                    name="interest_rate"
                    label="Interest Rate"
                    required
                    control={control}
                    type="number"
                    rightSection={<Percentage size={16} />}
                    errors={errors?.interest_rate?.message}
                    displayLabel={false}
                    spanSize={12}
                  />
                </Grid.Col>
                <Grid.Col span={9} p={0} pl={5}>
                  <Title
                    mb={"sm"}
                    sx={{ fontSize: "14px", lineHeight: "20px" }}
                  >
                    <TitleComp label={"Entity"} />
                  </Title>
                  <ControllerSelect
                    name="interest_entity"
                    control={control}
                    type="number"
                    data={allowedStakeholdersList()}
                    errors={errors?.interest_entity?.message}
                    displayLabel={false}
                    spanSize={12}
                  />
                </Grid.Col>
              </Grid.Col>

              {values?.interest_entity && values?.interest_rate ? (
                <Divider
                  size="md"
                  labelPosition="right"
                  variant="dashed"
                  label={
                    <Button
                      size="xs"
                      variant="subtle"
                      color={addSpread && "red"}
                      leftIcon={
                        !addSpread ? <Plus size={12} /> : <Trash size={12} />
                      }
                      onClick={() => {
                        if (addSpread) {
                          addSpreadHandler.close();
                          addOnSpreadHandler.close();
                          reset({
                            ...values,
                            spread_rate: null,
                            spread_entity: null,
                            addon_spread_rate: null,
                            addon_spread_entity: null,
                          });
                        } else {
                          addSpreadHandler.open();
                        }
                      }}
                    >
                      {!addSpread ? "Add Spread" : "Remove Spread"}
                    </Button>
                  }
                />
              ) : null}
              {addSpread && (
                <Grid.Col span={12}>
                  <Grid gutter="xs">
                    <Grid.Col
                      span={12}
                      sx={{ display: "flex", flexDirection: "row" }}
                    >
                      <Grid.Col span={3} p={0} pr={5}>
                        <Title
                          mb={"sm"}
                          sx={{ fontSize: "14px", lineHeight: "20px" }}
                        >
                          Spread Rate
                        </Title>
                        <ControllerTextField
                          name="spread_rate"
                          rightSection={<Percentage size={16} />}
                          control={control}
                          type="number"
                          errors={errors?.spread_rate?.message}
                          displayLabel={false}
                          spanSize={12}
                        />
                      </Grid.Col>
                      <Grid.Col span={9} p={0} pl={5}>
                        <Title
                          mb={"sm"}
                          sx={{ fontSize: "14px", lineHeight: "20px" }}
                        >
                          Entity
                        </Title>
                        <ControllerSelect
                          name="spread_entity"
                          control={control}
                          type="number"
                          data={allowedStakeholdersList()}
                          errors={errors?.spread_entity?.message}
                          displayLabel={false}
                          spanSize={12}
                        />
                      </Grid.Col>
                    </Grid.Col>
                    {values?.spread_entity && values?.spread_rate ? (
                      <Grid.Col span={12} p={0}>
                        <Divider
                          size="xs"
                          labelPosition="right"
                          variant="dashed"
                          label={
                            <Button
                              size="xs"
                              variant="subtle"
                              color={addOnSpread && "red"}
                              leftIcon={
                                !addOnSpread ? (
                                  <Plus size={12} />
                                ) : (
                                  <Trash size={12} />
                                )
                              }
                              onClick={() => {
                                if (addOnSpread) {
                                  addOnSpreadHandler.close();
                                  reset({
                                    ...values,
                                    addon_spread_rate: null,
                                    addon_spread_entity: null,
                                  });
                                } else {
                                  addOnSpreadHandler.open();
                                }
                              }}
                            >
                              {!addOnSpread ? "Add On Spread" : "Remove Add On"}
                            </Button>
                          }
                        />
                      </Grid.Col>
                    ) : null}
                    {addOnSpread && (
                      <>
                        <Grid.Col
                          span={12}
                          sx={{ display: "flex", flexDirection: "row" }}
                        >
                          <Grid.Col span={3} p={0} pr={5}>
                            <Title
                              mb={"sm"}
                              sx={{ fontSize: "14px", lineHeight: "20px" }}
                            >
                              Addon Spread Rate
                            </Title>
                            <ControllerTextField
                              name="addon_spread_rate"
                              rightSection={<Percentage size={16} />}
                              control={control}
                              type="number"
                              errors={errors?.addon_spread_rate?.message}
                              displayLabel={false}
                              spanSize={12}
                            />
                          </Grid.Col>
                          <Grid.Col span={9} p={0} pl={5}>
                            <Title
                              mb={"sm"}
                              sx={{ fontSize: "14px", lineHeight: "20px" }}
                            >
                              Entity
                            </Title>
                            <ControllerSelect
                              name="addon_spread_entity"
                              control={control}
                              data={allowedStakeholdersList()}
                              errors={errors?.addon_spread_entity?.message}
                              displayLabel={false}
                              spanSize={12}
                            />
                          </Grid.Col>
                          <Divider size="md" variant="dashed" />
                        </Grid.Col>
                      </>
                    )}
                  </Grid>
                </Grid.Col>
              )}
            </Box>
          </Grid.Col>
          <Grid.Col span={12} mt="xs">
            <ControllerTextField
              name="penal_rate"
              label="Penal Rate"
              control={control}
              required
              subText="Excluding interest & spread %"
              type="number"
              rightSection={<Percentage size={16} />}
              errors={errors?.penal_rate?.message}
            />
          </Grid.Col>
          <Grid.Col span={12} mt="xs">
            <ControllerMultiSelect
              name='product_banks'
              label='Choose Bank'
              data={bankList}
              value={values?.product_banks}
              required
              control={control}
              errors={errors?.product_banks?.message}
            />
          </Grid.Col>
          <Grid.Col span={12} sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            {bankList.map((item, index) => (
              values?.product_banks?.includes(item.value) ? (
                <DivDataContainer key={index}>
                  <Card className={'cardContainer'} py={6} px={10}>
                    <Text size={'xs'} className={'bankList'}>{item?.bank_name}</Text>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '5px' }}>
                      <Text size={'xs'} color={'gray'} sx={{ whiteSpace: 'nowrap', fontWeight: 400 }}>Acc no</Text>
                      <Text size={'xs'} className={'bankList'} sx={{ textAlign: 'end' }}>{item?.account_number}</Text>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Text size={'xs'} color={'gray'} sx={{ fontWeight: 400 }}>IFSC</Text>
                      <Text size={'xs'} className={'bankList'} sx={{ textAlign: 'end' }}>{item?.ifsc}</Text>
                    </Box>
                    {/* <Text size={'xs'} className={'bankList'}>{item?.ifsc}</Text> */}
                  </Card>
                  <ActionIcon onClick={() => formData?.product_banks?.includes(item?.id) ? setTrashObject({ modal: true, id: item?.id }) : handleRemoveBankArray(item?.id)} className={'trash'}><Trash size={16} color={'red'} /></ActionIcon>
                </DivDataContainer>
              ) : null))}
          </Grid.Col>
          <Grid.Col span={12} mt='xs'>
            <ControllerTextField
              name='grace_days'
              label='Grace Days'
              type='number'
              control={control}
              errors={errors?.grace_days?.message}
            />
          </Grid.Col>
          {/** commented temporary */}
          {/* <Grid.Col span={12}>
            <ControllerCurrencyInput
              name='transaction_cost'
              label='Transaction Cost'
              control={control}
              errors={errors?.transaction_cost?.message}
            />
          </Grid.Col> */}
          <Grid.Col span={12}>
            <Grid gutter='md'>
              <Grid.Col span={5}>
                <div style={{ display: 'flex' }}>
                  <TitleComp label={"Appropriation Logic"} />
                  <Text color="red">*</Text>
                </div>
                <Text sx={{ color: 'rgb(0,0,0,0.3)', fontSize: '12px' }}>
                  Drag and drop to change the order
                </Text>
              </Grid.Col>
              <Grid.Col span={7}>
                {
                  Array.isArray(listData) && listData.length && (
                    <DraggableList
                      listData={Array.isArray(formData?.appropriation_logic) ? formData?.appropriation_logic : listData}
                      onChange={setAppropriation}
                    />
                  )
                }
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={12} mt='sm'>
            <ControllerSegmentedControl
              name='foreclosure_fee'
              label='Foreclosure Fee'
              required
              control={control}
              size='xs'
              data={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
              ]}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <ControllerTextField
              name='no_of_days_backdate'
              label='Back Dated Reciept'
              control={control}
              subText='Enter number of days'
              type='number'
              errors={errors?.no_of_days_backdate?.message}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Grid gutter='md'>
              <Grid.Col span={5} sx={{ display: 'flex' }}>
                <TitleComp label={"Communication Mode"} />
                <Text ml={'4px'} color="red">*</Text>
              </Grid.Col>
              <Grid.Col span={7}>
                <Chip.Group
                  size='xs'
                  multiple={true}
                  variant='filled'
                  onChange={communicationHandler}
                  value={communication}
                >
                  <Group>
                    <Chip value='sms'>SMS</Chip>
                    <Chip value='whatsapp'>WhatsApp</Chip>
                    <Chip value='email'>Email</Chip>
                    <Chip value='voice_call'>Voice call</Chip>
                  </Group>
                </Chip.Group>
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={12} mt='md'>
            <ControllerSegmentedControl
              name='holiday_offset'
              control={control}
              required
              label='Holiday'
              data={[
                { label: 'Preceding', value: 'precede' },
                { label: 'Succeeding', value: 'succeed' },
              ]}
            />
          </Grid.Col>
          <Grid.Col span={12} mt='md'>
            <ControllerSegmentedControl
              name='interest_calc_period'
              control={control}
              required
              label='Interest Period'
              subText='In days'
              data={[
                { label: '360', value: '360' },
                { label: '365', value: '365' },
              ]}
            />
          </Grid.Col>
          {loanLimitArray?.length ?
            <Grid.Col span={12} mt='md'>
              <Divider
                size='md'
                labelPosition='right'
                variant='dashed'
                label={
                  <Button
                    size='xs'
                    variant='subtle'
                    leftIcon={!trancheAmountCollapse ? <ChevronDown size={12} /> : <ChevronUp size={12} />}
                    onClick={() => setTrancheAmountCollapse(!trancheAmountCollapse)}
                  >
                    Tranche Limits
                  </Button>
                }
              />
              <Collapse in={trancheAmountCollapse}>
                <DataTableWapper>
                  <Table>
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center' }}>Range</th>
                        <th style={{ textAlign: 'center' }}>Max no. of Tranches</th>
                        <th style={{ textAlign: 'center' }}>Min Tranche Amount</th>
                        <th style={{ minWidth: '80px' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        loanLimitArray?.map((item, index) => {
                          return trancheAmountIndex === index ?
                            <tr key={index}>
                              <td><Group position="center" spacing="xs">{handleAmountInLack(item?.min_approved_amount)}-{handleAmountInLack(item?.max_approved_amount)}</Group></td>
                              <td>
                                <span style={{ width: '110px', display: 'inline-block' }}>
                                  <NumberInput
                                    placeholder="Tranches count"
                                    value={changedValues?.max_no_of_tranches || item?.max_no_of_tranches}
                                    size="xs"
                                    min={1}
                                    onChange={(e) => { setChangedValues((old) => ({ ...old, max_no_of_tranches: e })) }}
                                  />
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span style={{ width: '110px', display: 'inline-block' }}>
                                  <TextInput
                                    size="xs"
                                    type='number'
                                    value={changedValues?.min_tranche_amount / oneLakh || parseFloat(item?.min_tranche_amount) / oneLakh}
                                    icon={<Text sx={{ fontFamily: 'sans-serif' }}>₹</Text>}
                                    rightSection={<Tooltip color='gray' label={'Lack(s)'} withArrow><Text size={'xs'} color='gray'>L</Text></Tooltip>}
                                    onChange={(e) => { setChangedValues((old) => ({ ...old, min_tranche_amount: Math.round(parseFloat(e.target.value) * oneLakh) })) }}
                                  />
                                </span>
                              </td>
                              <td style={{ display: 'flex', gap: '10px' }}>
                                <Tooltip label={'close'} color='gray' withArrow>
                                  <span>
                                    <ActionIcon
                                      size={'31px'}
                                      onClick={() => { setTrancheAmountIndex(); setChangedValues({}) }}
                                      variant='light'
                                      color='red'
                                    >
                                      <X
                                        size={"15px"}
                                        color={"red"}
                                      />
                                    </ActionIcon>
                                  </span>
                                </Tooltip>
                                <Tooltip label={'save'} color='gray' withArrow>
                                  <span>
                                    <ActionIcon
                                      size={'31px'}
                                      onClick={() => { handleEditableTrancheDetails(item) }}
                                      variant='light'
                                      color='green'
                                    >
                                      <Check
                                        size={"15px"}
                                        color={"green"}
                                      />
                                    </ActionIcon>
                                  </span>
                                </Tooltip>
                              </td>
                            </tr>
                            :
                            <tr key={index} className='rowList'>
                              <td><Group position="center" spacing="xs">{handleAmountInLack(item?.min_approved_amount)}-{handleAmountInLack(item?.max_approved_amount)}</Group></td>
                              <td>{item?.max_no_of_tranches}</td>
                              <td>{handleAmountInLack(item?.min_tranche_amount)}</td>
                              <td>
                                {trancheAmountIndex >= 0
                                  ? null
                                  : <Tooltip label={'Click to edit'} color='gray' withArrow>
                                    <span className="edit">
                                      <Edit
                                        size={"15px"}
                                        onClick={() => { setTrancheAmountIndex(index) }}
                                        style={{ cursor: "pointer" }}
                                        color={"green"}
                                      />
                                    </span>
                                  </Tooltip>
                                }
                              </td>
                            </tr>
                        }
                        )
                      }
                    </tbody>
                  </Table>
                </DataTableWapper>
              </Collapse>
            </Grid.Col>
            : null
          }
          <Grid.Col span={12}>
            <Divider
              size='md'
              labelPosition='right'
              variant='dashed'
              label={
                <>
                  <Popover opened={addFeeModalObj?.modal || false} onChange={(e) => { setAddFeeModalObj({ modal: e }) }} position="top-end" withArrow>
                    <Popover.Target>
                      <Button
                        onClick={
                          () => setAddFeeModalObj((o) => ({ modal: !o?.modal, category: 'fee', fee_type: 'fixed' }))
                        }
                        size='xs'
                        leftIcon={<Plus size={12} />}
                        variant='subtle'
                      >
                        Add Fee
                      </Button>
                    </Popover.Target>

                    <Popover.Dropdown sx={{ background: COLORS.background }}>
                      <Stack sx={{ width: 350 }}>
                        <Title order={6} mb={0}>Add Fee</Title>
                        <Box>
                          <Text size={'xs'} sx={{ color: 'gray' }} mb={2}>Type</Text>
                          <Select
                            placeholder={'Select type of fee'}
                            styles={{ menu: (provided) => ({ ...provided, zIndex: 9999, }), option: (provided) => ({ ...provided, fontSize: '13px', padding: '5px 15px 5px 15px' }), control: (provided) => ({ ...provided, fontSize: '13px' }) }}
                            options={feetype}
                            value={feetype[0]}
                            onChange={(val) =>
                              setAddFeeModalObj((o) => ({ modal: o?.modal, category: val?.value, fee_type: 'fixed' }))
                            }
                          />
                        </Box>
                        {addFeeModalObj?.category === 'fee' ?
                          <Box>
                            <Text size={'xs'} sx={{ color: 'gray' }} mb={2}>Fee Name</Text>
                            <Select
                              placeholder={'Select fee'}
                              styles={{ menu: (provided) => ({ ...provided, zIndex: 9999, }), option: (provided) => ({ ...provided, fontSize: '13px', padding: '5px 15px 5px 15px' }), control: (provided) => ({ ...provided, fontSize: '13px' }) }}
                              options={feeType}
                              onChange={(val) =>
                                setAddFeeModalObj((o) => ({ ...o, fee_name: val.value }))
                              }
                            />
                          </Box>
                          : null
                        }
                        {addFeeModalObj?.category === 'others' ?
                          <Box>
                            <Text size={'xs'} sx={{ color: 'gray' }} mb={2}>Fee Name</Text>
                            <TextInput
                              placeholder={'Enter fee name'}
                              onChange={(val) =>
                                setAddFeeModalObj((o) => ({ ...o, fee_name: val.target.value }))
                              }
                            />
                          </Box>
                          : null
                        }
                        {addFeeModalObj?.fee_name ?
                          <Box>
                            <Text size={'xs'} sx={{ color: 'gray' }} mb={2}>Fee Value</Text>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 10 }}>
                              {
                                <MantineProvider theme={{ fontFamily: 'sans-serif' }}>
                                  <TextInput
                                    placeholder={'Enter Fee Value'}
                                    type='number'
                                    icon={addFeeModalObj?.fee_type === 'fixed' ? '₹' : null}
                                    rightSection={addFeeModalObj?.fee_type === 'percent' ? '%' : null}
                                    onChange={(val) =>
                                      setAddFeeModalObj((o) => ({ ...o, fee_value: parseFloat(val.target.value) }))
                                    }
                                  />
                                </MantineProvider>
                              }
                              <SegmentedControl
                                color='blue'
                                value={addFeeModalObj?.fee_type || 'fixed'}
                                onChange={(e) =>
                                  setAddFeeModalObj((o) => ({ ...o, fee_type: e }))
                                }
                                data={[
                                  { label: 'Fixed', value: 'fixed' },
                                  { label: 'Perc(%)', value: 'percent' },
                                ]}
                              />
                            </Box>
                          </Box>
                          : null
                        }
                        <Button
                          color={'green'}
                          loading={addFeeModalObj?.loading}
                          disabled={!addFeeModalObj?.fee_value}
                          onClick={() => {
                            setAddFeeModalObj((old) => ({ ...old, loading: true }))
                            handleFeesOfProduct(
                              'add',
                              {
                                category: addFeeModalObj?.category,
                                fee_name: addFeeModalObj?.fee_name,
                                fee_value: addFeeModalObj?.fee_value,
                                fee_type: addFeeModalObj?.fee_type,
                              }
                            );
                            feeFieldArray?.append({
                              category: addFeeModalObj?.category,
                              fee_name: addFeeModalObj?.fee_name,
                              fee_value: addFeeModalObj?.fee_value,
                              fee_type: addFeeModalObj?.fee_type,
                            });
                          }}
                        >
                          Submit
                        </Button>
                      </Stack>
                    </Popover.Dropdown>
                  </Popover>
                </>
              }
            />
          </Grid.Col>
          {feeFieldArray?.fields?.length > 0 ?
            <DataTableWapper>
              <Table>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>Type</th>
                    <th style={{ textAlign: 'center' }}>Fee Name</th>
                    <th style={{ textAlign: 'center' }}>Fee Value</th>
                    <th style={{ textAlign: 'center' }}>Fee Type</th>
                    <th style={{ width: 100 }}></th>
                  </tr>
                </thead>
                <tbody>
                  {feeFieldArray?.fields?.map((item, index) => (
                    <tr key={item?.id}>
                      <td>{item?.category?.toUpperCase()}</td>
                      <td>{item?.fee_name}</td>
                      <td>{item?.fee_type === 'fixed' ? <Nums value={item?.fee_value} /> : `${item?.fee_value} %`}</td>
                      <td>{item?.fee_type?.toUpperCase()}</td>
                      <td style={{ textAlign: 'left' }}>
                        <Group position='right'>
                          <FeeEditModal
                            data={item}
                            feeTypes={feeType}
                            callback={(e, state) => {
                              state(o => ({ ...o, loading: true }))
                              handleFeesOfProduct('update', e, state);
                            }}
                          />
                          <ActionIcon onClick={() => { setTrashObject({ modal: true, index: index, data: item }) }}>
                            <Trash size={16} color='red' />
                          </ActionIcon>
                        </Group>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </DataTableWapper> : null
          }
        </Grid>
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '12px 0',
          }}
        >
          <Button variant='subtle' onClick={onClose} mr='md'>
            Cancel
          </Button>
          <Button color='green' onClick={handleSubmit(onSaveProduct)}>
            Save Product
          </Button>
        </Box>
      </Box>
      <Modal
        opened={trashObject?.modal}
        onClose={() => setTrashObject({ modal: false, loading: false })}
        title='Are you sure?'
        centered
      >
        {typeof trashObject?.index === 'number' ? "Do you want to remove this fee." : "Do you want to delete this product bank."}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }} mt={'md'}>
          <Button variant='outline' onClick={() => setTrashObject({ modal: false, loading: false })}>Cancel</Button>
          <Button color={'red'} loading={trashObject?.loading} onClick={() => handleTrashAction()}>Remove</Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Product;
