import { getAPICall, patchAPICall, postAPICall } from '.';

export const getLoansforWaiver = (body = {}) => {
  /** used to get eligible waiver */
  return postAPICall("waiver/eligible-loans", { body: body });
};

export const getLoanDetailsforWaiverbyId = (body = {}) => {
  /** used to get eligible waiver */
  return postAPICall(`waiver/eligible-loans/${body?.loansId}`, { body: body?.body });
};

export const getLoansforWaiverbyId = (id) => {
  /** used to get eligible waiver by id */
  return getAPICall(`waiver/eligible-loans/${id}`, { returnFirstItem: true });
};

export const getAllWaiver = ({page, search, records = 10, status, entity}) => {
  /** used to get all waiver */
  let url = `waiver?page=${page}&records=${records}&status=${status}`
  if(search) url = url + `&customer_id_name=${search}`;
  if(entity && entity !== 'all') url = url + `&entity_id=${entity}`;
  return getAPICall(url, { returnObject: true });
};

export const getWaiverbyId = (id) => {
  /** used to get waiver by id */
  return getAPICall(`waiver/${id}`, { returnFirstItem: true });
};

export const createWaiver = (body) => {
  /** used to create the waiver */
  return postAPICall(`waiver`, { body: body });
};

export const updateWaiver = (id, body) => {
  /** used to update the waiver */
  return patchAPICall(`waiver/${id}`, { body: body });
};

export const saveWaiverAndsendforNextStage = (body) => {
  /** used to send the waiver to the next stage */
  return postAPICall(`waiver/direct_save`, { body: body });
};

export const sendWaiverForNextStage = (id, body) => {
  /** used to send the waiver to the next stage by id */
  return postAPICall(`waiver/${id}/status/change`, { body: body });
};

export const sendForPushbackWaiver = (id, body) => {
  /** used to pushback the waiver */
  return postAPICall(`waiver/${id}/pushback`, { body: body });
};

export const sendForRejectWaiver = (id, body) => {
  /** used to reject the waiver */
  return postAPICall(`waiver/${id}/rejected`, { body: body });
};

export const sendWaiverforCancellation = (id, body) => {
  /** used to cancel the waiver */
  return postAPICall(`waiver/${id}/cancelled`, { body: body });
};

export const getAllRemarksWaiver = (id) => {
  /** used to get remarks */
  return getAPICall(`waiver/${id}/remark`);
};

export const getAllReasonsWaiver = () => {
  /** used to get reasons for waiver */
  const handleValue = (data) => {
    const result = data?.map((item) => ({
      label: item.name,
      value: item.name,
      ...item,
    }));
    return result || [];
  };

  return getAPICall(`waiver-reasons`, { selectValue: handleValue });
};

export const createWaiverReasons = (body) => {
  /** used to create waiver reasons */
  return postAPICall(`waiver/reason`, { body: body });
};
