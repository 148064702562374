import { Box, Grid, Skeleton, Text, Title, Badge, Tooltip } from '@mantine/core';
import React from 'react';
import { AlertCircle } from 'tabler-icons-react';
import { COLORS, VISIBLECHECK } from '../../../constants';
import Nums from '../../Body/Nums';
import { currencyFormat } from '../../Currency/Currency';
import { dateFormat } from '../../DateFormat/dateFormat';
import TitleComp from '../TextComponent/TitleComp';

const styles = {
  title: {
    color: '#888',
  },
  details: {
    borderColor: 'grey',
    minWidth: 80,
    maxWidth: 250,
    textAlign: 'left',
    marginBottom: 8,
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgb(0,0,0,0.5)',
  },
  success: {
    color: COLORS.success,
  },
  error: {
    color: COLORS.error.main,
  },
  warning: {
    color: COLORS.warning.main,
  },
  formdatacontained: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: COLORS.primary.main,
    color: COLORS.white,
  },
};

export const ViewData = ({
  title,
  value,
  secondaryLabel,
  type = 'currency',
  loading = false,
  info = false,
  infotext,
  style={marginBottom: '7px'},
  withTooltip = false,
  withCopy = false,
}) => {

  /** used to handle the values type */
  const checkValues = () => {
      if(type == 'text') {
        return value
      } else if(type == 'date') {
        return dateFormat(value)
      } else if(type == 'currency') {
        return <Nums withTooltip={withTooltip} withCopy={withCopy} value={value} />
      }
  }

  return (
    <Grid
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...style,
      }}
    >
      <Grid.Col span={5} sx={{display: 'flex'}}>
        <Title order={6} sx={{fontWeight: '500'}}>{title}</Title> {VISIBLECHECK?.SHOW_SECONDARYLABEL && secondaryLabel ? <Text sx={{fontSize: '14px'}}> ({secondaryLabel}%)</Text> : null}
      </Grid.Col>
      <Grid.Col span={7} sx={{ color: COLORS.primary.dark }}>
        {!loading ? 
          <Text size='sm' weight={600} sx={{display: 'flex', wordWrap: 'break-word'}}>
            {checkValues()}
            {info && <Text weight={200}><Tooltip label={infotext} multiline shadow='lg' position="right" width={250} withArrow color="gray"><Text><AlertCircle style={{paddingLeft: '5px'}} size={'20px'}  /></Text></Tooltip></Text>}
          </Text> : 
          <div style={{ padding: '6px' }}>
            <Skeleton visible={loading} height={10}></Skeleton>
          </div>
        }
      </Grid.Col>
    </Grid>
  );
};

export const ViewTitleData = ({
  title,
  value,
  style = { marginBottom: 8 },
}) => {
  return (
    <Box sx={styles.details} style={style}>
      <Title order={4}>{title}</Title>
      <Text size='xs' sx={styles.text}>
        {value || null}
      </Text>
    </Box>
  );
};

export const StatusToken = ({ color = 'blue', label }) => {
  return (
      <Badge color={color} variant='dot' styles={{inner: { color:  color }}} sx={{border: 'none'}}>
        {label?.toUpperCase()}
      </Badge>
  );
};

export const LoanStatusToken = ({ color = 'blue', label }) => {
  return (
    <>
    {label ?
      <Badge color={color} variant='light' styles={{inner: { color:  color }, root: { width: 'fit-content'}}}>
        {label?.toUpperCase()}
      </Badge> : null
    }
    </>
  );
};

export const DuesOverDuesToken = ({ color = 'red', label }) => {
  return (
      <Badge color={color} variant='light' styles={{inner: { color:  color ? `${color}` : 'red' }}} sx={{border: 'none'}}>
        {label?.toUpperCase()}
      </Badge>
  );
};

export const ViewFormData = ({ label, labelPosition="left", value, secondaryLabel, loading = false, style = {}, valueStyle = {}, action = false }) => {
  return (
    <Grid sx={{ display: 'flex', alignItems: 'center'}} style={style}>
      <Grid.Col span={6} sx={{display: 'flex'}}>
        <TitleComp label={label}  align={labelPosition} /> {VISIBLECHECK.SHOW_SECONDARYLABEL && secondaryLabel ? <Text sx={{lineHeight: '20px'}}> ({secondaryLabel}%)</Text> : null}
      </Grid.Col>
      <Grid.Col span={6} sx={{ padding: '7px', display: 'flex', alignItems: 'center' }}>
        <Text size='sm' sx={{ color: COLORS.primary.dark, overflowWrap: 'anywhere', ...valueStyle }}>
          {loading ? (
            <div style={{ padding: '6px' }}>
              <Skeleton visible={loading} width={80} height={10}></Skeleton>
            </div>
          ) : (
            <span style={{display: 'flex', alignItems: 'center'}}>
              <strong>{value || '-'}</strong>
              {action && action}
            </span>
          )}
        </Text>
      </Grid.Col>
    </Grid>
  );
};

export const ViewFormDataLabelRight = (props) => {
  return <ViewFormData {...props} labelPosition="right" />
};

export const ViewFormDataContained = ({ label, labelPosition="left", value, loading = false }) => {
  return (
    <Grid sx={styles.formdatacontained}>
      <Grid.Col span={6}>
        <TitleComp label={label}  align={labelPosition} />
      </Grid.Col>
      <Grid.Col span={6} sx={{ padding: '7px' }}>
        <Text size='sm'>
          {loading ? (
            <div style={{ padding: '6px' }}>
              <Skeleton visible={loading} height={10}></Skeleton>
            </div>
          ) : (
            <strong>{value || '-'}</strong>
          )}
        </Text>
      </Grid.Col>
    </Grid>
  );
};

export const ViewFormDataContainedLableRight = (props) => {
  return <ViewFormDataContained {...props} labelPosition='right' />
};
