import { Box, Grid, Tooltip, Group, Text } from '@mantine/core';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import * as yup from 'yup';
import {
  ViewData,
  ViewFormData,
  ViewFormDataContained,
} from '../../components/CommonComponents/Preview/FilePreview';
import ControllerDatePicker from '../../components/CommonComponents/TextField/ControllerDatePicker';
import ControllerSelect from '../../components/CommonComponents/TextField/ControllerSelect';
import ControllerTextField from '../../components/CommonComponents/TextField/ControllerTextField';
import ImdStyle from './Imd.styles';
import DataViewerWrapper from '../../components/CommonComponents/Preview/DataViewerWapper';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  calculateImd,
  customerApplicationSearch,
  customerBankDetails,
  getImdById,
  saveImd,
  saveAndNext,
  updateImd,
  getImdReviewTrail,
  sendForNextStage,
} from '../../services/imd.service';
import {
  getFeeTypesByProductId,
  getAllProductById,
} from '../../services/common.service';
import { STAGE_CHANGE_TEXT } from '../../constants';
import ModalDataViwer from '../../components/SucessfullModal/ModalDataViwer';
import TitleViewer from '../../components/CommonComponents/Title/TitleViewer';
import DashBoardCustomerDetails from '../../components/DashboardCard/dashboard';
import Body from '../../components/Body/Body';
import Nums from '../../components/Body/Nums';
import PageTitle from '../../components/PageTitle/PageTitle';
import IdBadge from '../../components/CommonComponents/Preview/IdBadge';
import Remarks from '../../components/CommonComponents/Remarks/Remarks';
import ViewRemark from '../../components/Remark/ViewRemark';
import dayjs from 'dayjs';
import { currencyAsInt, currencyAsFloat } from '../../components/Currency/currencyFormat';
import ActionButtons
  from '../../components/CommonComponents/ActionButtons/ActionButtons';
import { instrumentType } from '../../utils/InstrumentTypes';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import { getStageforModules } from '../../services/module.service';
import { IN_APPROVAL, IN_REVIEW, OPEN } from '../../constants/status';
import useSystemConfigStore from '../../store/systemConfigStore';
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification';
import { InfoCircle } from 'tabler-icons-react';
import UnidentifiedReceiptListModal from '../Receipt/UnidentifiedReceiptListModal';
import { getEligibleUnidentifiedReceiptByLoanId } from '../../services/unidentifiedReceipt.service';
import { PAYMENT_REF_NO_LIMIT } from '../../constants/validationCheck';
import { getBusinessBanks } from '../../services/banks.service';

const schema = yup.object({
  imd_date: yup.string().required('Please Select a Date'),
  fee_id: yup.number().required('Please Select a Fee Type'),
  fee_base_amount: yup
    .string()
    .required('Please Enter Fee Base Amount'),
});

const schemaSave = schema.shape({
  source_bank_id: yup.string()
    .nullable()
    .required('Please Select a Bank'),
  target_bank_id: yup.string()
    .nullable()
    .required('Please Select a Bank'),
})

const schemaAfterSave = schema.shape({
  source_bank_id: yup.string()
    .nullable()
    .required('Please Select a Bank'),
  target_bank_id: yup.string()
    .nullable()
    .required('Please Select a Bank'),
  instrument_date: yup.string()
    .nullable()
    .required('Please Select instrument date'),
  instrument_type: yup.string()
    .nullable()
    .required('Please Select instrument type'),
  payment_ref_no: yup.string()
    .max(parseInt(PAYMENT_REF_NO_LIMIT), `payment no must be less then ${PAYMENT_REF_NO_LIMIT} characters`)
    .nullable()
    .test('space', 'Invalid payment ref no', (val) => !val.toString().includes(' '))
    .required('Please Enter payment referecnce no'),
})

const ImdCreation = ({ formType }) => {
  const { custId, imdId } = useParams();
  const queryClient = useQueryClient();
  const systemDate = useSystemConfigStore(store => store.config);

  const { data: custBank = [] } = useQuery(
    ['custBank', custId],
    () => custId && customerBankDetails(custId),
    { refetchOnWindowFocus: false }
  );

  const { data: imdData = [] } = useQuery(
    ['imd-data', imdId],
    () => imdId && getImdById(imdId),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoading(false);
      },
    }
  );

  const { data: stages = [] } = useQuery(
    ['imd-stage'],
    () => getStageforModules('imd'),
    {
      select: (data) => {
        return {
          'current_stage': OPEN,
          'next_stage': data[1]
        }
      },
      refetchOnWindowFocus: false
    }
  );

  const { data: loanData = [] } = useQuery(
    ['loan-data', custId],
    () => custId && customerApplicationSearch({ customer_id: custId }),
    {
      onSuccess: (data) => {
        setLoading(false);
      },
      refetchOnWindowFocus: false
    }
  );

  const { data: reviewTrail = [] } = useQuery(
    ['review-trail', imdId],
    () => imdId && getImdReviewTrail(imdId),
    { refetchOnWindowFocus: false }
  );

  const { data: product = [] } = useQuery(
    ['product-byid', loanData[0]?.product_id],
    () => loanData[0]?.product_id && getAllProductById(loanData[0]?.product_id),
    { refetchOnWindowFocus: false }
  )

  const { data: targetBank = [], isLoading: tagretBankLoading } = useQuery(
    ['company-banks', loanData[0]?.product_id],
    () => getBusinessBanks({ product_id: loanData[0]?.product_id }),
    {
      enabled: Boolean(loanData[0]?.product_id),
      refetchOnWindowFocus: false
    }
  );

  const [calculatedImdValue, setCalculatedImdValue] = useState({});
  const [open, setOpen] = useState(false);
  const [percent, setPercent] = useState(false)
  const [loading, setLoading] = useState(true);
  const [sucessfulmodal, setSucessfulmodal] = useState({});
  const [label, setLabel] = useState();
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [remarksLoading, setRemarksLoading] = useState();
  const [status, setStatus] = useState();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [unidentifiedReceipt, setUnidentifiedReceipt] = useState({});

  /* 
    React hooks form is not working with mantine library, after checking with mantine useFoms these will be replaced with mantine useForms.
  */
  const {
    handleSubmit,
    setError,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaSave),
    defaultValues:
      formType == OPEN
        ? { ...imdData }
        : {
          imd_date: dayjs(new Date()).format(),
          receipt_date: dayjs(new Date()).format(),
          instrument_date: dayjs(new Date()).format(),
          ...loanData[0],
        },
  });
  const value = watch();

  const { data: feeType = [] } = useQuery(
    ['fee-type', loanData[0]?.product_id],
    () => getFeeTypesByProductId(loanData[0]?.product_id, loanData?.[0]?.id),
    {
      onSuccess: (data) => {
        if (data?.length)
          setValue("fee_id", data[0]?.value)
      },
      enabled: Boolean(loanData[0]?.product_id),
      refetchOnWindowFocus: false,
    }
  );

  /** getting the unidentified receipt list */
  const { data: UnidentifiedReceiptData = [] } = useQuery(
    ["unidentified-receipt", custId, imdId],
    () => getEligibleUnidentifiedReceiptByLoanId({ customerId: custId, feeAmount: calculatedImdValue?.total_processing_fee, receiptId: imdId }),
    {
      select: (response) => {
        return response.map((item) => ({
          ...item,
          search_key: Object.values(item).join().toLowerCase(),
        }));
      },
      enabled: Boolean(formType === 'view' ? imdId : calculatedImdValue?.total_processing_fee),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (imdData?.unidentified_receipt_id) {
      setUnidentifiedReceipt(UnidentifiedReceiptData?.find((e) => e?.id === imdData?.unidentified_receipt_id))
    } else {
      setUnidentifiedReceipt({})
    }
  }, [UnidentifiedReceiptData])

  const handleReviewModal = () => {
    schemaAfterSave.validate(value)
      .then(res => {
        setOpen(true);
        setStatus(IN_REVIEW);
        setLabel(stages?.next_stage == IN_APPROVAL ? 'Send for Approval' : 'Send for Review');
      })
      .catch(err => {
        displayNotification({ message: err?.errors, variant: 'error' })
      })
  }

  /* 
    This useEffect resets the form and fills default value to the resp fields.
  */
  useEffect(() => {
    if (Object.keys(imdData).length) {
      reset(imdData);
    }
  }, [imdData]);

  /* 
    This useEffect fills the loan details only for the new IMD Creation.
  */
  useEffect(() => {
    if (Object.keys(loanData).length && !formType) {
      reset(loanData[0]);
      // setValue('fee_base_amount', loanData[0]?.approved_amount);
    }
    if (Object.keys(systemDate).length && !formType) {
      setValue('imd_date', systemDate?.current_system_date);
      setValue('receipt_date', systemDate?.current_system_date);
      setValue('instrument_date', systemDate?.current_system_date);
    }
  }, [loanData, systemDate]);

  useEffect(() => {
    const body = {
      customer_application_id: loanData[0]?.id,
      fee_id: value?.fee_id,
      fee_base_amount: currencyAsInt(value?.fee_base_amount)
    };
    if (value?.fee_base_amount && value?.fee_id) {
      schema.validate(value)
        .then(() => {
          if (currencyAsInt(value?.fee_base_amount) <= loanData[0]?.approved_amount) {
            calculateImd(body)
              .then((data) => {
                setCalculatedImdValue(data);
                setLoading(false);
              })
              .catch((e) => {
                setLoading(false);
              });
          }
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' })
        })
    }
  }, [value?.fee_base_amount, value?.fee_id])

  const calculateFeeAmount = () => {
    if (value?.fee_id) {
      const fee = feeType?.find((val) => {
        return val?.fee_id == value?.fee_id;
      });
      if (value?.fee_base_amount) {
        if (fee?.fee_type == 'percent') {
          !percent && setPercent(true)
          return `${parseFloat(fee?.fee_value)} %` || 0;
        } else {
          percent == true && setPercent(false)
          return currencyAsFloat(fee?.fee_value || 0);
        }
      }
    }
  };

  const calculateBackDate = () => {
    const backDate = new Date(systemDate?.current_system_date)
    return backDate.setDate(backDate.getDate() - product[0]?.no_of_days_backdate);
  }

  const onSave = (values) => {
    const body = {
      customer_id: custId,
      customer_application_id: loanData[0]?.id,
      imd_date: dateFormat(values?.imd_date, 'YYYY-MM-DD'),
      type: 'fee',
      fee_id: values?.fee_id,
      unidentified_receipt_id: unidentifiedReceipt?.id,
      fee_base_amount: currencyAsInt(value?.fee_base_amount),
      receipt_date: dateFormat(values?.imd_date, 'YYYY-MM-DD'),
      source_bank_id: value?.source_bank_id,
      target_bank_id: value?.target_bank_id,
      instrument_type: value?.instrument_type,
      instrument_date: dateFormat(values?.instrument_date, 'YYYY-MM-DD'),
      payment_ref_no: value?.payment_ref_no?.toUpperCase(),
    };

    setButtonLoader({ save: true });
    if (!imdId) {
      saveImd(body)
        .then((data) => {
          setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.save });
          queryClient.invalidateQueries(['imd-data', imdId]);
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({
              id: 'imd-save',
              message: e?.message || e,
              variant: 'error',
            });
          }
        })
        .finally(() => {
          setButtonLoader({ save: false });
        })
    } else {
      updateImd(imdId, body)
        .then((data) => {
          setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.update });
        })
        .catch((e) => {
          // This catch block sets field validation errors from api whic are not handled in UI.
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({
              id: 'imd-update',
              message: e?.message || e,
              variant: 'error',
            });
          }
        })
        .finally(() => {
          setButtonLoader({ save: false });
        })
    }
  };

  const onSendForReview = (remarks) => {
    const body = {
      remarks: remarks,
    };
    const saveImdBodyWithRemarks = {
      customer_application_id: loanData[0]?.id,
      customer_id: custId,
      imd_date: dateFormat(value?.imd_date, 'YYYY-MM-DD'),
      type: 'fee',
      fee_id: value?.fee_id,
      unidentified_receipt_id: unidentifiedReceipt?.id,
      fee_base_amount: currencyAsInt(value?.fee_base_amount),
      receipt_date: dateFormat(value?.imd_date, 'YYYY-MM-DD'),
      source_bank_id: value?.source_bank_id,
      target_bank_id: value?.target_bank_id,
      instrument_date: dateFormat(value?.instrument_date, 'YYYY-MM-DD'),
      instrument_type: value?.instrument_type,
      payment_ref_no: value?.payment_ref_no?.toUpperCase(),
      ...body,
    };
    setRemarksLoading(true);
    if (!imdId) {
      saveAndNext(saveImdBodyWithRemarks)
        .then((res) => {
          setSucessfulmodal({ open: true, title: stages?.next_stage == IN_APPROVAL ? STAGE_CHANGE_TEXT?.approval : STAGE_CHANGE_TEXT?.review });
        })
        .catch((e) => {
          // This catch block sets field validation errors from api whic are not handled in UI.
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({
              id: 'imd-review-save',
              message: e?.message || e,
              variant: 'error',
            });
          }
        })
        .finally(() => {
          setRemarksLoading(false);
          setOpen(false);
        })
    } else {
      updateImd(imdId, saveImdBodyWithRemarks)
        .then((res) => {
          sendForNextStage(imdId, body)
            .then((data) => {
              setSucessfulmodal({ open: true, title: stages?.next_stage == IN_APPROVAL ? STAGE_CHANGE_TEXT?.approval : STAGE_CHANGE_TEXT?.review });
            })
            .catch((e) => {
              displayNotification({
                id: 'imd-review',
                message: e?.message || e,
                variant: 'error',
              });
            });
        })
        .catch((e) => {
          displayNotification({
            id: 'imd-review',
            message: e?.message || e,
            variant: 'error',
          });
        })
        .finally(() => {
          setRemarksLoading(false);
          setOpen(false)
        })
    }
  };

  const handleFeeBaseAmountError = () => {
    var msg = 'Fee Base Amount should be less than Approved Amount';
    if (currencyAsInt(value?.fee_base_amount) > loanData[0]?.approved_amount) {
      return msg
    } else {
      return errors?.fee_base_amount?.message
    }
  }

  /**
  * Sets the unidentified receipt data if it exists in the input data object.
  * If not, clears the unidentified receipt data and sets default values for
  * the form fields.
  *
  * @param {Object} data - The data object containing unidentified receipt data.
  */
  const handleUnidentifiedReceiptSelection = (data) => {
    if (data?.id) {
      setUnidentifiedReceipt(data)
      setValue('imd_date', data?.unidentified_receipt_date)
      setValue('instrument_type', data?.instrument_type)
      setValue('payment_ref_no', data?.payment_ref_no)
      setValue('target_bank_id', data?.target_bank_id)
    } else {
      setUnidentifiedReceipt({})
      setValue('imd_date', systemDate?.current_system_date)
      setValue('instrument_type', '')
      setValue('payment_ref_no', '')
      setValue('target_bank_id', '')
    }
  }

  return (
    <>
      <Body background={false}>
        <PageTitle
          title={loanData[0]?.customer_name}
          id={loanData[0]?.customer_id}
          action={<UnidentifiedReceiptListModal submitCallback={handleUnidentifiedReceiptSelection} selectedRowId={unidentifiedReceipt?.id} data={UnidentifiedReceiptData} mode={'edit'} />}
        />
      </Body>
      <DashBoardCustomerDetails id={custId} />
      <Box sx={{ position: 'relative' }}>
        <Body>
          {/* 
          The Flag Component is in WIP, once that is finished the component comes here!
        */}
          {imdData?.code && (
            <div style={ImdStyle.flag}>
              <IdBadge
                remarks={imdData?.status_value}
                label={imdData?.code}
              />
            </div>
          )}
          <Grid gutter='xl' mt='sm' pt={'xs'}>
            <Grid.Col span={4}>
              <Grid gutter='sm'>
                <Grid.Col span={12}>
                  <ViewData title={'Product'} value={loanData[0]?.product_name ? loanData[0]?.product_name : '-'} type={'text'} />
                </Grid.Col>
                <Grid.Col span={12}>
                  {unidentifiedReceipt?.unidentified_receipt_date
                    ? <ViewData title={'Fee Date'} value={dateFormat(unidentifiedReceipt?.unidentified_receipt_date, 'DD/MM/YYYY')} type={'text'} />
                    : <ControllerDatePicker
                      name='imd_date'
                      label='Fee Date'
                      required
                      maxDate={new Date(systemDate?.current_system_date)}
                      minDate={new Date(calculateBackDate())}
                      control={control}
                      errors={errors?.imd_date?.message}
                    />
                  }
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewData title={'Type'} value={'Fee'} type={'text'} />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ControllerSelect
                    name='fee_id'
                    label='Fee Type'
                    required
                    autoSelect
                    setValue={setValue}
                    placeholder='Select Fee Type'
                    control={control}
                    errors={errors?.fee_id?.message}
                    data={feeType}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewData title={'Approved Amount'} value={loanData[0]?.approved_amount} />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewData title={'Fee Base Amount'} value={loanData[0]?.fee_base_amount} />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewData title={`Fee Amount ${percent ? '%' : ''}`} value={calculateFeeAmount() || imdData?.fee_amount} type={percent ? 'text' : 'currency'} />
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={4} pt={5}>
              <DataViewerWrapper>

                <Grid gutter='md'>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label='Approved Amount'
                      loading={loading}
                      value={
                        <Nums withCopy value={calculatedImdValue?.approved_amount || imdData?.approved_amount} />
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label='Fee Base Amount'
                      loading={loading}
                      value={
                        <Nums withCopy value={calculatedImdValue?.fee_base_amount || imdData?.fee_base_amount} />
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label='Fee Amount'
                      loading={loading}
                      value={
                        <Nums withCopy value={calculatedImdValue?.fee_amount || imdData?.fee_amount} />
                      }
                    />
                  </Grid.Col>
                  {calculatedImdValue?.igst || imdData?.igst ? (
                    <Grid.Col span={12}>
                      <ViewFormData
                        label='IGST'
                        loading={loading}
                        value={
                          <Nums value={calculatedImdValue?.igst || imdData?.igst} />
                        }
                      />
                    </Grid.Col>
                  ) : null}
                  {calculatedImdValue?.sgst || imdData?.sgst ? (
                    <Grid.Col span={12}>
                      <ViewFormData
                        label='SGST'
                        loading={loading}
                        value={
                          <Nums value={calculatedImdValue?.sgst || imdData?.sgst} />
                        }
                      />
                    </Grid.Col>
                  ) : null}
                  {calculatedImdValue?.cgst || imdData?.cgst ? (
                    <Grid.Col span={12}>
                      <ViewFormData
                        label='CGST'
                        loading={loading}
                        value={
                          <Nums value={calculatedImdValue?.cgst || imdData?.cgst} />
                        }
                      />
                    </Grid.Col>
                  ) : null}
                  <Grid.Col span={12}>
                    <ViewFormDataContained
                      label='Total Processing Fee'
                      loading={loading}
                      value={
                        <Group>
                          <Nums withCopy value={calculatedImdValue?.total_processing_fee || imdData?.total_processing_fee} />
                          <Text size={12} weight={300}>
                            <Tooltip withArrow label={`Here the fee is calculated for ${loanData[0]?.fee_collection_month} Months`} color='gray'>
                              <span style={{ marginTop: '5px' }}>
                                <InfoCircle color='white' size={18} />
                              </span>
                            </Tooltip>
                          </Text>
                        </Group>
                      }
                    />
                  </Grid.Col>
                </Grid>

              </DataViewerWrapper>
            </Grid.Col>
            <Grid.Col span={4}>
              <Grid gutter='sm'>
                <Grid.Col span={12}>
                  <TitleViewer
                    title={'Bank Details of the Petromoney'}
                  />
                </Grid.Col>
                {!unidentifiedReceipt?.unidentified_receipt_date ? (
                  <Grid.Col span={12} pt={'sm'}>
                    <ControllerSelect
                      name='target_bank_id'
                      label='Bank Details'
                      placeholder='Select Bank Details'
                      control={control}
                      type={'bank'}
                      autoSelect
                      setValue={setValue}
                      required
                      errors={errors?.target_bank?.message}
                      data={targetBank}
                    />
                  </Grid.Col>
                ) : <>
                  <Grid.Col span={12}>
                    <ViewData title={'Bank Name'} value={targetBank[0]?.bank_name} type={'text'} loading={tagretBankLoading} />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewData title={'Account No'} value={targetBank[0]?.account_number} type={'text'} loading={tagretBankLoading} />
                  </Grid.Col>
                </>}
                <Grid.Col span={12}>
                  <ViewData title={'Receipt Date'} value={value?.imd_date} type={'date'} loading={tagretBankLoading} />
                </Grid.Col>
              </Grid>
              <Grid gutter='sm'>
                <Grid.Col span={12}>
                  <TitleViewer title={'Select Bank Details of the Customer'} />
                </Grid.Col>
                <Grid.Col span={12} pt={'sm'}>
                  <ControllerSelect
                    name='source_bank_id'
                    label='Bank Details'
                    placeholder='Select Bank Details'
                    control={control}
                    type={'bank'}
                    autoSelect
                    setValue={setValue}
                    required
                    errors={errors?.source_bank?.message}
                    data={custBank}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  {unidentifiedReceipt?.instrument_type ? (
                    <ViewData title={'Instrument Type'} value={value?.instrument_type} type={'text'} loading={tagretBankLoading} />
                  ) : (
                    <ControllerSelect
                      label={'Instrument Type'}
                      name={'instrument_type'}
                      data={instrumentType}
                      required
                      control={control}
                      autoSelect
                      setValue={setValue}
                      errors={errors?.instrument_type?.message}
                    />
                  )}
                </Grid.Col>
                <Grid.Col span={12}>
                  {unidentifiedReceipt?.unidentified_receipt_date
                    ? <ViewData title={'Instrument Date'} value={unidentifiedReceipt?.unidentified_receipt_date} type={'date'} loading={tagretBankLoading} />
                    : <ControllerDatePicker
                      name='instrument_date'
                      label='Instrument Date'
                      maxDate={new Date(systemDate?.current_system_date)}
                      minDate={new Date(calculateBackDate())}
                      required
                      errors={errors?.instrument_date?.message}
                      control={control}
                    />
                  }
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={12} pt={'sm'}>
                  {unidentifiedReceipt?.payment_ref_no ? (
                    <ViewData title={'Payment Ref.No'} value={value?.payment_ref_no} type={'text'} />
                  ) : (
                    <ControllerTextField
                      name='payment_ref_no'
                      control={control}
                      required
                      value={value?.payment_ref_no?.toUpperCase()}
                      label='Payment Ref.No'
                      errors={errors?.payment_ref_no?.message}
                      variant='outline'
                      autoComplete='off'
                    />
                  )}

                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
          {/** This Action buttons component is used to display the buttons in the flow */}
          <ActionButtons
            status={imdData?.status_value ? imdData?.status_value : OPEN}
            nextStage={stages?.next_stage}
            base={'creation'}
            sendForReviewCB={handleReviewModal}
            sendForApprovalCB={handleReviewModal}
            saveCB={handleSubmit(onSave)}
            loading={{ save: buttonLoader?.save }}
            remarks={reviewTrail}
            isReady={Boolean(stages?.next_stage)}
            currentStatus={imdData?.status_value}
            moduleApplicationId={imdData?.id}
            moduleName={'imd'}
            remarksCB={() => setRemarksOpen(true)} />

          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={imdData?.code}
            title={sucessfulmodal?.title}
            href={'/fee-invoice'}
            hreftext={'Back to list table'}
          />
        </Body>

        <ViewRemark open={remarksOpen} onClose={setRemarksOpen} remarkTrail={reviewTrail} />

        <Remarks
          open={open}
          setOpen={setOpen}
          type={stages?.next_stage}
          callback={onSendForReview}
          remarksTitle={stages?.next_stage === IN_APPROVAL ? 'Approval Remarks' : 'Review Remarks'}
          check={status}
          label={label}
          loading={remarksLoading}
          currentStatus={imdData?.status_value}
          moduleApplicationId={imdData?.id}
          moduleName={'IMD'}
        />
      </Box>
    </>
  );
};

export default ImdCreation;
