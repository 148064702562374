import React, { useState } from 'react'
import { Button, Skeleton, Grid, Modal, Text, Box, Title, Center } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { MessageCircle, FileDownload, Table, ArrowNarrowLeft, AlertTriangle } from 'tabler-icons-react';
import ImdStyle from '../../../pages/IMD/Imd.styles';
import {
    APPROVED,
    CANCELLED,
    IN_APPROVAL,
    IN_REVIEW,
    OPEN,
    AUTO_APPROVED,
    AUTO_CANCELLED,
    REJECTED,
    CANCELLATION_REVIEW,
    CANCELLATION_APPROVAL
} from '../../../constants/status';
import { getCurrentStatusByModuleNameAndId } from '../../../services/module.service';
import { displayNotification } from '../Notification/displayNotification';
import DialogModal from '../Modal/DialogModal';

const ActionButtons = ({ 
    status,
    downloadText,
    isReady = false,
    showAccountingEntries = false,
    loading = {},
    /** next stage is used to get the next status */
    nextStage,
    saveCB = () => {},
    sendForReviewCB = () => {},
    pushbackCB = () => {},
    sendForApprovalCB = () => {},
    approvedCB = () => {},
    rejectCB = () => {},
    cancelCB = () => {},
    approveCancelCB = () => {},
    remarksCB = () => {},
    accountingCB = () => {},
    handlePDF = () => {},
    pdfLoading = false,
    remarks,
    currentStatus,
    moduleApplicationId,
    moduleName,
    base = 'review',
}) => {
    
  const navigate = useNavigate();
  const [disableAction, setDisableAction] = useState(false);
  const [statusModal, setStatusModal] = useState({open:false, status: ''})

  /** if Is ready is false then the loading will display else it will display buttons */
  if (!isReady) {
    return (
        <Grid gutter='xl' mt='xl'>
            <Grid.Col span={12} sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}>
                {Array(3).fill().map(() => 
                    <Skeleton height={32} width={'10%'} ml={'md'} radius="sm" />
                )}
              </Grid.Col>
        </Grid>
    )
  }

  if(isReady && ((base === 'creation' && status !== OPEN) || (base === 'review' && status === OPEN))) {
    return (
        <Grid gutter='xl' mt='xl'>
            <Grid.Col span={12} sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#ffc5c5',
                borderRadius: '5px'
              }}>
                <Center>
                    <AlertTriangle size={'16px'} color='red' />
                    <Title ml={10} order={4}>This application status has been changed, so please go back and proceed further.</Title>
                </Center>
                <Button onClick={() => navigate(-1)} color='red' leftIcon={<ArrowNarrowLeft />}>Go Back</Button>
              </Grid.Col>
        </Grid>
    )
  }

  const checkCurrentStatus = (callBack) => {
    setDisableAction(true);
    if(moduleApplicationId) {
        getCurrentStatusByModuleNameAndId({ moduleName, moduleApplicationId })
          .then((res) => {
            if (res?.current_status === currentStatus || res?.current_status === null) {
              callBack();
            } else {
              setStatusModal({ open: true, status: res?.current_status });
            }
          })
          .catch((e) => {
            console.log(e);
            displayNotification({
              message: "Server is busy. Please try again after sometime.",
              color: "red",
            });
          })
          .finally(() => {
            setDisableAction(false);
          })
    } else {
        callBack();
        setDisableAction(false);
    }
  };

  return (
    <>
        <Grid gutter='xl' mt='xl' sx={{display: 'flex'}}>
            <Grid.Col span={4} sx={{ display: 'flex', columnGap: '15px'}}>
                {/** this button is used to view the accounting entries in approved or rejected stage if accountingCB has the functioncall
                 * if it is not required then pass false
                 */}
                { ([APPROVED, CANCELLED, AUTO_APPROVED, AUTO_CANCELLED].includes(status) || showAccountingEntries) && accountingCB &&
                    <Button color='blue' variant='light' leftIcon={<Table size={18} />} onClick={accountingCB}>View Accounting Entries</Button>
                }
                {/** handlePDF is used to generate PDF, It will display in review or higher stages if the downloadText has the value
                 * If it is not required then leave it as empty
                 */}
                { ![REJECTED, CANCELLED, AUTO_CANCELLED, CANCELLATION_APPROVAL, CANCELLATION_APPROVAL].includes(status) && downloadText &&
                    <Button color='green' variant='outline' leftIcon={<FileDownload size={18} />} onClick={handlePDF} loading={pdfLoading}>{downloadText}</Button>                    
                }
            </Grid.Col>                
            <Grid.Col span={8} sx={ImdStyle.flexEnd} style={{justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                {/** remarksCB is used to view remarks, it will display in all stage if it is avilable */}
                { remarks?.length ? 
                    <Button variant='outline' color="gray" ml='md' leftIcon={<MessageCircle  />} onClick={remarksCB}>
                        View Remarks
                    </Button> : null
                }
                {/** this buttons will vissible only in the draft stage
                 * if status is undefined then it is draft only
                 */}


                { (status == OPEN) &&
                    <>
                        {/** navigate(-1) is used to go back */}
                        <Button variant='outline' ml='md' onClick={() => navigate(-1)}>Cancel</Button>
                        <Button ml='md' onClick={saveCB} loading={loading?.save}>Save</Button>
                    </>
                }
                {/** this button will vissible in the draft stage
                 * if status is undefined then it is draft only and if the next stage is review
                 */}
                { (nextStage == IN_REVIEW) &&
                    <Button color='green' ml='md' onClick={sendForReviewCB}>Send for Review</Button>
                }
                {/** this button will display only in the approved stage */}
                { (status == APPROVED && cancelCB) &&
                    <Button color={'red'} ml='md' onClick={() => checkCurrentStatus(cancelCB)} loading={disableAction}>Send for Cancellation</Button>
                }
                {/** Pushback is used to go to the previous stage, 
                 * Reject is used to reject the customer, 
                 * it will display only in review and approval satge */}
                { [IN_REVIEW, IN_APPROVAL, CANCELLATION_REVIEW, CANCELLATION_APPROVAL].includes(status) ? (
                    <>
                        {pushbackCB
                        ? <Button
                            ml='md'
                            variant='light'
                            onClick={pushbackCB} 
                          >
                            Pushback
                          </Button>
                        : null}
                        {rejectCB
                        ? <Button
                            variant='outline'
                            color='red'
                            ml='md'
                            onClick={rejectCB} 
                          >
                            Reject
                          </Button>
                        : null}
                    </>
                    ) : null
                }
                {/** this button will display only in the cancellation review stage */}
                { status == CANCELLATION_REVIEW &&
                    <Button color={'red'} ml='md' onClick={() => checkCurrentStatus(cancelCB)} loading={disableAction}>Cancellation for Approval</Button>
                }
                {/** this button will display only in the cancellation approval stage */}
                { status == CANCELLATION_APPROVAL &&
                    <Button color={'red'} ml='md' onClick={approveCancelCB}>Approve Cancellation</Button>
                }
                {/** if the next stage is approval then approval button will display */}
                {
                    (nextStage == IN_APPROVAL) &&  <Button color='green' ml='md' onClick={() =>  checkCurrentStatus(sendForApprovalCB)} loading={disableAction}>Send for Approval</Button>
                }                
                {/** Approve button will display only in approval stage */}
                {
                    status == IN_APPROVAL && <Button color='green' ml='md' onClick={() => checkCurrentStatus(approvedCB)} loading={disableAction}>Approve</Button>
                }
            </Grid.Col>
        </Grid>
        <DialogModal centered size={'32rem'} open={statusModal?.open} onClose={() => setStatusModal({open: false})} title="Alert!" styles={(them) => ({title: {fontWeight: '500'}})}>
            <Text>
                The current application is already moved to <strong>{statusModal?.status}</strong> status.
                Please go back to proceed further
            </Text>
            <Box sx={{display: 'flex', justifyContent: 'right', gap:'20px'}} mt={'md'}>
                <Button variant='outline' leftIcon={<ArrowNarrowLeft   />} onClick={() => navigate(-1)}>Go Back</Button>
            </Box>
        </DialogModal>
    </>
  )
}

export default ActionButtons