import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import React, { useEffect, useState } from 'react'
import DataTableWrapper from '../../../components/CommonComponents/Table/DataTableWrapper';
import { getReportPaginationDetails, getReportbyReportName } from '../../../services/report.service';
import { ActionIcon, Box, Button, createStyles, Group, Popover, Text, Tooltip } from '@mantine/core';
import EntityFilter from '../../../components/CommonComponents/Filter/EntityFilter';
import { useSearchParams } from 'react-router-dom';
import { generateReportHeaders } from '../../Report/generateReportHeaders';
import { useQuery } from '@tanstack/react-query';
import { Download } from 'tabler-icons-react';
import { displayNotification } from '../../../components/CommonComponents/Notification/displayNotification';
import { dateFormat } from '../../../components/DateFormat/dateFormat';
import useSystemConfigStore from '../../../store/systemConfigStore';
import { IconCalendar } from "@tabler/icons-react";
import { reportDownload } from "../../Report/reportDownload/reportDownload";

const createStyle = createStyles({
  dateSelection: {
    background: "#FFFF",
    display: "flex",
    justifyContent: "center",
    width: "260px",
    padding: "8px",
    columnGap: "5px",
    borderRadius: "5px",
    marginLeft: "10px",
    cursor: "pointer",
    border: "1px solid #e1e1e1",
  },
});

const ExcessAmountReceiptTable = () => {
  const { classes } = createStyle();
  const [page, setPage] = useState(1);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [search, setSearch] = useState('');
  const [records, setRecords] = useState({ label: "Show 10", value: 10 });
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const systemDate = useSystemConfigStore(store => store.config);

  useEffect(() => {
    page != 1 && setPage(1);
  }, [search, records]);

  /** used to set the default date as system date when the system date context is loaded */
  useEffect(() => {
    if (systemDate?.current_system_date) {
      setState([
        {
          startDate: new Date(systemDate?.current_system_date),
          endDate: new Date(systemDate?.current_system_date),
          key: "selection",
        },
      ]);
      searchParams?.set("from_date", dateFormat(new Date(systemDate?.current_system_date), "YYYY-MM-DD"));
      searchParams?.set("to_date", dateFormat(new Date(systemDate?.current_system_date), "YYYY-MM-DD"));
      setSearchParams(searchParams);
    }
  }, [systemDate?.current_system_date]);

  // get the excess amount data
  const excessAmountQuery = useQuery({
    queryKey: ['excess-module', searchParams?.get('entity'), page, search, records, searchParams?.get('from_date'), searchParams?.get('to_date')],
    queryFn: () => getReportbyReportName(
      'excess_module',
      { from_date: searchParams?.get('from_date'), to_date: searchParams?.get('to_date'), entity_id: searchParams?.get('entity') != 'all' && searchParams?.get('entity') },
      { page, search, records: records?.value },
    ),
    select: (data) => {
      return {
        ...data,
        headers: generateReportHeaders(data?.headers),
      };
    },
    enabled: Boolean(systemDate?.current_system_date),
  });

  // get the pagination details
  const paginationQuery = useQuery({
    queryKey: ['excess-module-pagination', searchParams?.get('entity'), search, records, searchParams?.get('from_date'), searchParams?.get('to_date')],
    queryFn: () => getReportPaginationDetails(
      { report_name: 'excess_module', search, records: records?.value },
      { from_date: searchParams?.get('from_date'), to_date: searchParams?.get('to_date'), entity_id: searchParams?.get('entity') != 'all' && searchParams?.get('entity') },
    ),
    enabled: Boolean(systemDate?.current_system_date),
  })

  // handling the download
  const handleDownload = () => {
    setDownloadLoading(true);
    getReportbyReportName(
      'excess_module',
      { from_date: searchParams?.get('from_date'), to_date: searchParams?.get('to_date'), entity_id: searchParams?.get('entity') != 'all' && searchParams?.get('entity'), download_csv: 'yes' },
      { page, search, records: records?.value },
    ).then((res) => {
      displayNotification({
        message: 'Report will download shortly',
        variant: 'warning',
        loading: true,
        id: 'report-loading',
        autoClose: false
      });
      reportDownload({ id: res?.report_download_id })
    }).catch((e) => {
      displayNotification({
        message: e,
        variant: 'error',
      });
    }).finally(() => {
      setDownloadLoading(false);
    })
  }

  /** used to handle the date */
  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    console.log(ranges);
    setState([selection]);
  };

  const handleModalSubmit = () => {
    setModalOpen(false);
    searchParams?.set("from_date", dateFormat(new Date(state[0]?.startDate), "YYYY-MM-DD"));
    searchParams?.set("to_date", dateFormat(new Date(state[0]?.endDate), "YYYY-MM-DD"));
    setSearchParams(searchParams);
  };

  return (
    <>
      <DataTableWrapper
        rowData={excessAmountQuery?.data?.data || []}
        styles={{ overflowX: "auto", whiteSpace: "nowrap", maxWidth: "100vw" }}
        columnData={excessAmountQuery?.data?.headers || []}
        onRowClick={false}
        filter={false}
        page={page}
        setPage={setPage}
        apiSearch={setSearch}
        rowLength={records}
        setRowLength={setRecords}
        useApiPagination
        totalNoOfPages={paginationQuery?.data?.total_number_of_pages}
        totalNoOfRecords={paginationQuery?.data?.total_number_of_records}
        title={'Excess Amount'}
        loading={excessAmountQuery?.isLoading}
        actions={
          <Group spacing={4}>
            <EntityFilter useQueryParams />

            <Tooltip label={'Download'} withArrow color='gray'>
              <span>
                <ActionIcon color='green' variant='light' loading={downloadLoading} onClick={() => { handleDownload(); }}><Download /></ActionIcon>
              </span>
            </Tooltip>

            <Popover
              position="bottom-end"
              shadow="md"
              opened={modalOpen}
              onChange={setModalOpen}
            >
              <Popover.Target>
                <Text
                  onClick={() => setModalOpen(true)}
                  size={"12px"}
                  className={classes?.dateSelection}
                  m={0}
                >
                  <IconCalendar strokeWidth={1.5} color={"gray"} size={"20"} />{" "}
                  <strong>From:</strong> {dateFormat(state[0]?.startDate)}{" "}
                  <strong style={{ marginLeft: "6px" }}>To:</strong>{" "}
                  {dateFormat(state[0]?.endDate)}
                </Text>
              </Popover.Target>
              <Popover.Dropdown>
                <Box sx={{ display: "flex", flexDirection: "column", }}>
                  <DateRange
                    onChange={handleOnChange}
                    months={1}
                    maxDate={new Date(systemDate?.current_system_date ? systemDate?.current_system_date : new Date())}
                    ranges={state}
                    retainEndDateOnFirstSelection
                    direction="horizontal"
                  />
                  <Button onClick={handleModalSubmit}>Apply</Button>
                </Box>
              </Popover.Dropdown>
            </Popover>
          </Group>
        }
      />
    </>
  )
}

export default ExcessAmountReceiptTable