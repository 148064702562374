import { Button, Group, Text } from "@mantine/core";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Plus } from "tabler-icons-react";
import Body from "../../components/Body/Body";
import {
  LoanStatusToken,
} from "../../components/CommonComponents/Preview/FilePreview";
import DataTableWrapper from "../../components/CommonComponents/Table/DataTableWrapper";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import DisbDrawer from "./DisbDrawer";
import { useQuery } from "@tanstack/react-query";
import { getAllDisb } from "../../services/disbursement.service";
import Nums from "../../components/Body/Nums";
import {
  LOAN_STATUS_COLORS,
} from "../../utils/StatusTable";
import {
  APPROVED,
  CANCELLED,
  IN_APPROVAL,
  IN_REVIEW,
  OPEN,
  REJECTED,
  CANCELLATION_REVIEW,
  CANCELLATION_APPROVAL,
  AUTO_CANCELLED
} from '../../constants/status';
import EntityFilter from './../../components/CommonComponents/Filter/EntityFilter';
import useModuleRecordCountStore from "../../store/moduleRecordCountStore";
import LoanTypeFilter from "../../components/CommonComponents/Filter/LoanTypeFilter";

const DisbTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const columnHelper = createColumnHelper();
  const { moduleCount } = useModuleRecordCountStore();
  const [addDisb, setAddDisb] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [records, setRecords] = useState({ label: "show 10", value: 10 });
  // get the search params
  const [searchParams, setSearchParams] = useSearchParams();
  const params = {}
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  const [selectedData, setSelectedData] = useState([]);

  /** used to change the page to 1 while the search us used
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1);
  }, [search, searchParams.get("status"), searchParams.get("entity"), searchParams.get("reload_type"), records]);

  const { data: loanData = [], isLoading } = useQuery(
    ["all-disb", params, page, search, records],
    () => getAllDisb({ ...params, page, search, records: records?.value }),
    {
      enabled: Boolean(searchParams.get("status") && searchParams.get("status") !== "none"),
      select: (data) => {
        // If there is a badge if then the select option will disabled
        if (searchParams.get("status") === IN_APPROVAL) {
          let result = data?.data?.map((e) => ({
            isDisabled: Boolean(e?.disbursement_batch_code),
            ...e,
          }));
          return { ...data, data: result }
        }
        return data;
      }
    }
  );

  const onRowClick = (i) => {
    if (i?.status_value == OPEN) {
      navigate(`/disb/view/${i?.id}/${i?.customer_id}/${i?.loan_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == IN_APPROVAL || i?.status_value == IN_REVIEW) {
      navigate(`/disb/review/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == APPROVED) {
      navigate(`/disb/approved/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == AUTO_CANCELLED) {
      navigate(`/disb/cancelled/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == REJECTED) {
      navigate(`/disb/rejected/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == CANCELLED) {
      navigate(`/cancellation/disb-cancelled/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == CANCELLATION_REVIEW || i?.status_value == CANCELLATION_APPROVAL) {
      navigate(`/cancellation/disb-cancel/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    }
  };

  const onClickAction = (value) => {
    return (
      <span
        style={{ color: "#0063FF", cursor: "pointer" }}
        onClick={() => value.row.original?.status_value === IN_APPROVAL ? onRowClick(value.row.original) : null}
      >
        {value.getValue()}
      </span>
    );
  };

  const tableAction = (
    <Button
      variant="filled"
      color="primary"
      leftIcon={<Plus size={18} />}
      size="sm"
      onClick={() => setAddDisb(true)}
    >
      Create Disbursement
    </Button>
  );

  const columnData = [
    columnHelper.accessor("prospect_code", {
      header: "Tranche Code",
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor("customer_name", {
      header: "Customer Name",
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor("customer_id", {
      header: "Customer Code",
    }),
    columnHelper.accessor("product_name", {
      header: "Scheme",
    }),
    columnHelper.accessor("loan_amount", {
      header: <Text sx={{ textAlign: 'right' }}>Tranche Amount</Text>,
      cell: (value) => <Nums value={value.getValue()} styles={{ textAlign: 'right' }} />,
    }),
    columnHelper.accessor("created_date", {
      header: "Created Date",
      cell: (value) => dateFormat(value.getValue()),
    }),
    columnHelper.accessor('maker_name', {
      header: 'Created By',
    }),
    columnHelper.accessor("loan_status", {
      header: "Loan Status",
      cell: (value) => (
        <LoanStatusToken
          label={value.getValue()}
          color={LOAN_STATUS_COLORS?.[value.getValue()]}
        />
      ),
    }),
  ];

  return (
    <Body background={false}>
      <DataTableWrapper
        title='Disbursement Memo'
        filter={false}
        rowData={loanData?.data}
        columnData={columnData}
        // if the status if approved the the selectable react table will display to select and create a badge
        selectableTable={searchParams.get("status") === 'approval'}
        onRowClick={onRowClick}
        noDataText="No disbursement memo details yet!"
        noDataSubText='This section will contain list of created disbursement memo details.'
        useApiPagination
        totalNoOfPages={Math.ceil(parseInt(moduleCount?.count) / records?.value)}
        totalNoOfRecords={moduleCount?.count}
        page={page}
        setPage={setPage}
        rowLength={records}
        setRowLength={setRecords}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        apiSearch={setSearch}
        // when the status is none then the loading is set to false other wise it will dependent on isFetching
        loading={searchParams.get("status") !== "none" ? isLoading : searchParams.get("status") === null ? true : false}
        module_name="disbursement"
        showStatus
        addDataCallback={() => setAddDisb(true)}
        /** used to have tab in disb table to filter active and closed loans */
        options={<Group spacing={2}><EntityFilter useQueryParams /><LoanTypeFilter /></Group>}
        actions={tableAction}
      />
      <DisbDrawer open={addDisb} onClose={() => setAddDisb(false)} />
    </Body>
  );
};

export default DisbTable;
