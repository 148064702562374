import React, { useState } from 'react'
import Body from '../../components/Body/Body'
import PageTitle from '../../components/PageTitle/PageTitle'
import { Box, Tabs, createStyles } from '@mantine/core';
import { COLORS, STAGE_CHANGE_TEXT } from '../../constants';
import ActionButtons from '../../components/CommonComponents/ActionButtons/ActionButtons';
import GeneralInvoiceReviewComp from './InvoiceCustomerComp/GeneralInvoiceReviewComp';
import { CANCELLATION_REVIEW, IN_APPROVAL, IN_REVIEW } from '../../constants/status';
import CommunicationInvoiceReviewComp from './InvoiceCustomerComp/CommunicationInvoiceReviewComp';
import InvoicingInvoiceReviewComp from './InvoiceCustomerComp/InvoicingInvoiceReviewComp';
import PaymentsInvoiceReviewComp from './InvoiceCustomerComp/PaymentsInvoiceReviewComp';
import ShippingInvoiceReviewComp from './InvoiceCustomerComp/ShippingInvoiceReviewComp';
import TaxInformationInvoiceReviewComp from './InvoiceCustomerComp/TaxInformationInvoiceReviewComp';
import { getStageforModules } from '../../services/module.service';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { eInvoiceCancellation, eInvoicePushback, eInvoiceReject, eInvoiceStatusChange, getAllRemarkseInvoice, geteInvoiceById } from '../../services/e-invoice.service';
import ViewRemark from '../../components/Remark/ViewRemark';
import ModalDataViwer from '../../components/SucessfullModal/ModalDataViwer';
import Remarks from '../../components/CommonComponents/Remarks/Remarks';
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification';

const useStyles = createStyles({
  mainBox: {
    background: COLORS.white,
    borderRadius: 8,
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1)",
    
  },
  tabPanelRoot: {
    padding: '24px',
    marginTop: '10px'
  }
})

const CustomerInvoiceReview = ({ home = '/external-customer'}) => {

  const { createdInvoiceId } = useParams();
  const [remarksOpen, setRemarksOpen] = useState();
  const [selectedValue, setSelectedValue] = useState("general");
  const [sucessfulmodal, setSucessfulmodal] = useState({});
  const [label, setLabel] = useState();
  const [remarksTitle, setRemarksTitle] = useState();
  const [remarksLoading, setRemarksLoading] = useState(false);
  const [status, setStatus] = useState();
  const [open, setOpen] = useState(false);
  const { classes } = useStyles();

  const eInvoiceDataQuery = useQuery({
    queryKey: ['eInvoiceData', createdInvoiceId],
    queryFn: () => geteInvoiceById({ id: createdInvoiceId }),
    enabled: Boolean(createdInvoiceId),
    // select: (data) => {
    //   // return data?.[0] || {};
    // },
    initialData: {}
  })
console.log(eInvoiceDataQuery?.data);
  const remarksTrailQuery = useQuery({
    queryKey: ['remarksTrail', createdInvoiceId],
    queryFn: () => getAllRemarkseInvoice({ id: createdInvoiceId }),
    enabled: Boolean(createdInvoiceId),
    initialData: []
  })

  const getStagesQuery = useQuery({
    queryKey: ['stages', createdInvoiceId],
    queryFn: () => getStageforModules('external_customer'),
    enabled: Boolean(eInvoiceDataQuery?.data?.status_value),
    select: (data) => {
      return {
        'current_stage': eInvoiceDataQuery?.data?.status_value,
        'next_stage': data[data.indexOf(eInvoiceDataQuery?.data?.status_value) + 1]
      }
    },
    initialData: []
  })

    /**
   * Handles the change of the next stage in the e-invoice status flow.
   *
   * @param {string} remarks - The remarks to be added to the e-invoice.
   * @param {string} status - The status of the e-invoice.
   */
  const handleNextStageChange = (remarks, status) => {
    const body = {
      remarks,
    };    
    setRemarksLoading(true)
    eInvoiceStatusChange({id: createdInvoiceId, body})
      .then((data) => {
        setSucessfulmodal({open: true, title:
          status == IN_REVIEW ? STAGE_CHANGE_TEXT?.approval
          : status == IN_APPROVAL ? STAGE_CHANGE_TEXT?.approved
          : status == CANCELLATION_REVIEW ? STAGE_CHANGE_TEXT?.reviewCancel
          : STAGE_CHANGE_TEXT?.approveCancel
        });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error'})
      })
      .finally(() => {
        setOpen(false)
        setRemarksLoading(false)      
      })
  };

    /**
   * Handles the rejection of an e-invoice.
   *
   * @param {string} remarks - The remarks for the rejection.
   */
  const handleReject = (remarks) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true)
    eInvoiceReject({id: createdInvoiceId, body})
    .then((data) => {
        setSucessfulmodal({open: true, title: STAGE_CHANGE_TEXT?.reject});
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error'})
      })
      .finally(() => {
        setOpen(false)
        setRemarksLoading(false)
      })
  };

    /**
   * Handles the pushing back of remarks.
   *
   * @param {string} remarks - The remarks to be pushed back.
   */
  const handlePushback = (remarks) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true)
    eInvoicePushback({id: createdInvoiceId, body})
    .then((data) => {
        setSucessfulmodal({open: true, title: STAGE_CHANGE_TEXT?.pushback});
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error'})
      })
      .finally(() => {
        setOpen(false)
        setRemarksLoading(false)
      })
  };

    /**
   * Handles the cancellation of an e-invoice.
   *
   * @param {Object} remarks - The remarks object with details about the cancellation.
   * @param {Object} reason - The reason object with details about the cancellation reason.
   */
  const handleCancellation = (remarks, reason) => {
    const body = {
      remarks,
      reason: reason?.reason,
    };
    setRemarksLoading(true)
    eInvoiceCancellation({id: createdInvoiceId, body})
    .then((data) => {
        setSucessfulmodal({open: true, title: STAGE_CHANGE_TEXT?.cancel});
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error'})
      })
      .finally(() => {
        setOpen(false)
        setRemarksLoading(false)
      })
  };

    /**
   * Handles the save action based on the status provided.
   *
   * @param {string} remarks - Remarks or comment to be saved
   * @param {string} status - Status of the action being performed
   * @param {string} reason - Reason for cancelling an action
   */
  const handleSave = (remarks, status, reason) => {
    switch (status) {
      case 'review':
      case 'approval':
      case 'cancellation' :
        handleNextStageChange(remarks, status);
        break;
      case 'pushback':
        handlePushback(remarks);
        break;
      case 'reject':
        handleReject(remarks);
        break;
      case 'cancel':
        handleCancellation(remarks, reason);
        break;
      default:
        displayNotification({
          message: 'Something went wrong, Please contact support!',
          variant: 'error',
        });
        break;
    }
  };
  
  return (
    <>
      <Body background={false}>
        <PageTitle title={'e-Invoice'} />
      </Body>
      <Body background={false}>
        <Box className={classes?.mainBox}>
          <Tabs
            radius="xs"
            value={selectedValue}
            onTabChange={setSelectedValue}
            color='orange'
            styles={(theme) => ({
              tab: {
                padding: '9px 16px',
                "&:is(:first-of-type)": {
                  borderTopLeftRadius: 8,
                },
                "&:is(:last-of-type)": {
                  borderTopRightRadius: 8,
                },
                "&:is([aria-selected=true])": {
                  color: COLORS.orange(),
                  fontWeight: 600,
                  backgroundColor: COLORS.orange(6),
                },
              },
            })}
          >
            <Tabs.List grow>
              <Tabs.Tab value="general">General</Tabs.Tab>
              <Tabs.Tab value="communication">Communication</Tabs.Tab>
              <Tabs.Tab value="invoicing">Invoicing</Tabs.Tab>
              <Tabs.Tab value="payments">Payments</Tabs.Tab>
              <Tabs.Tab value="shipping">Shipping</Tabs.Tab>
              <Tabs.Tab value="tax_information">Tax Information</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="general" pt="xs" pb={0} className={classes?.tabPanelRoot}>
              <GeneralInvoiceReviewComp data={eInvoiceDataQuery}  />
            </Tabs.Panel>

            <Tabs.Panel value="communication" pt="xs" pb={0} className={classes?.tabPanelRoot}>
              <CommunicationInvoiceReviewComp data={eInvoiceDataQuery}  />
            </Tabs.Panel>

            <Tabs.Panel value="invoicing" pt="xs" pb={0} className={classes?.tabPanelRoot}>
              <InvoicingInvoiceReviewComp data={eInvoiceDataQuery} />
            </Tabs.Panel>

            <Tabs.Panel value="payments" pt="xs" pb={0} className={classes?.tabPanelRoot}>
              <PaymentsInvoiceReviewComp data={eInvoiceDataQuery} />
            </Tabs.Panel>

            <Tabs.Panel value="shipping" pt="xs" pb={0} className={classes?.tabPanelRoot}>
              <ShippingInvoiceReviewComp data={eInvoiceDataQuery} />
            </Tabs.Panel>

            <Tabs.Panel value="tax_information" pt="xs" pb={0} className={classes?.tabPanelRoot}>
              <TaxInformationInvoiceReviewComp data={eInvoiceDataQuery} />
            </Tabs.Panel>
          </Tabs>
          <Box px={'xl'}>
            <ActionButtons
              status={getStagesQuery?.data?.current_stage} 
              currentStatus={eInvoiceDataQuery?.data?.status_value}
              moduleApplicationId={eInvoiceDataQuery?.data?.id}
              moduleName={'imd'}
              isReady={Boolean(getStagesQuery?.data?.current_stage)}
              remarks={remarksTrailQuery?.data}
              nextStage={getStagesQuery?.data?.next_stage}
              remarksCB={() => setRemarksOpen(true)}
              accountingCB={false}
              sendForApprovalCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('review'); setLabel('Send for Approval') }}
              approvedCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('approval'); setLabel('Approve') }}
              pushbackCB={() => { setOpen(true); setRemarksTitle("Pushback Remarks"); setStatus('pushback'); setLabel('Pushback') }}
              rejectCB={() => { setOpen(true); setRemarksTitle("Reject Remarks"); setStatus('reject'); setLabel('Reject') }}
              cancelCB={() => { setOpen(true); setRemarksTitle("Cancellation Remarks"); setStatus('cancellation'); setLabel('Send for Cancellation') }}
              approveCancelCB={() => { setOpen(true); setRemarksTitle("Are you sure?"); setStatus('cancel'); setLabel('Confirm') }}/>
          </Box>
        </Box>

        <ViewRemark open={remarksOpen} onClose={setRemarksOpen} remarkTrail={remarksTrailQuery?.data} />

        <ModalDataViwer
          opened={sucessfulmodal?.open}
          label={eInvoiceDataQuery?.data?.code}
          title={sucessfulmodal?.title}
          href={home}
          hreftext={'Back to list table'}
        />

        <Remarks
          open={open}
          setOpen={setOpen}
          moduleName={'external_customer'}
          type={getStagesQuery?.data?.next_stage}
          currentStatus={eInvoiceDataQuery?.data?.status_value}
          moduleApplicationId={eInvoiceDataQuery?.data?.id}
          callback={handleSave}
          remarksTitle={remarksTitle}
          check={status}
          label={label}
          loading={remarksLoading}
        />
      </Body>
    </>
  )
}

export default CustomerInvoiceReview