import { getAllDisb } from "../../services/disbursement.service";
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";

const getAllApprovalDisbMemoRecords = ({
  totalRecordCount,
  selectedRecordCount,
  addAllRows,
  entity,
  loanType,
  clearAllRows,
}) => {
  getAllDisb({
    status: "approval",
    records: parseInt(totalRecordCount) + 50,
    entity: entity,
    reload_type: loanType,
    page: 1,
  })
    .then((res) => {
      let data = res?.data?.filter((e) => !e?.disbursement_batch_code)
      if (data?.length !== selectedRecordCount) {
        addAllRows(res?.data?.filter((e) => !e?.disbursement_batch_code), data?.length);
      } else {
        clearAllRows();
      }
    })
    .catch((err) => {
      displayNotification({ message: err.message || err, variant: 'error' });
    });
};
export default getAllApprovalDisbMemoRecords;
