import { Grid, Text } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import dayjs from 'dayjs';
import React from 'react';
import { Controller } from 'react-hook-form';
import { CalendarEvent } from 'tabler-icons-react';
import TitleComp from '../TextComponent/TitleComp';

const ControllerDatePicker = ({
  name,
  label,
  required = false,
  displayLabel = true,
  spanSize = 7,
  placeholder,
  control,
  errors,
  fetch = () => {},
  ...props
}) => {
  return (
    <Grid sx={{ display: 'flex', alignItems: 'center' }}>
      {displayLabel ? <Grid.Col span={5} sx={{ minHeight: '57px', padding: '10px 8px 0 8px', display: 'flex' }}>
        <TitleComp label={label} />
          {required ? <Text ml={'4px'} color="red">*</Text> : null}
      </Grid.Col> : null}
      <Grid.Col span={spanSize} sx={{ minHeight: '57px', padding: '0 8px 0 8px' }}>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange, ...rest } }) => (
            <DatePickerInput
              id={name}
              valueFormat='DD/MM/YYYY'
              value={value ? new Date(value) : new Date()}
              onChange={(value) => {
                onChange(dayjs(value).format());
                fetch();
              }}
              icon={<CalendarEvent size={18} />}
              error={errors ? true : false}
              clearable={false}
              {...props}
              {...rest}
            />
          )}
        />
        {errors && (
          <Text size={'xs'} sx={{ color: '#f13e3e' }}>
            {errors}
          </Text>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default ControllerDatePicker;
