import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashBoardCustomerDetails from "../../components/DashboardCard/dashboard";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import { getEligibleUnidentifiedReceiptByLoanId } from "../../services/unidentifiedReceipt.service";
import { getStageforModules } from "../../services/module.service";
import {
  customerBankDetails,
} from "../../services/imd.service";
import Body from "../../components/Body/Body";
import PageTitle from "../../components/PageTitle/PageTitle";
import UnidentifiedReceiptListModal from "./UnidentifiedReceiptListModal";
import ImdStyle from "../IMD/Imd.styles";
import IdBadge from "../../components/CommonComponents/Preview/IdBadge";
import ControllerCurrencyInput from "../../components/CommonComponents/TextField/ControllerCurrencyInput";
import { Divider, Switch, Text, Grid, Box, Title, Button } from "@mantine/core";
import FormLabel from "../../components/CommonComponents/TextField/FormLabel";
import { ViewData } from "../../components/CommonComponents/Preview/FilePreview";
import TitleViewer from "../../components/CommonComponents/Title/TitleViewer";
import ControllerTextField from "../../components/CommonComponents/TextField/ControllerTextField";
import ControllerSelect from "../../components/CommonComponents/TextField/ControllerSelect";
import { currencyAsFloat } from "../../components/Currency/currencyFormat";
import ControllerDatePicker from "../../components/CommonComponents/TextField/ControllerDatePicker";
import { instrumentType } from "../../utils/InstrumentTypes";
import ActionButtons from "../../components/CommonComponents/ActionButtons/ActionButtons";
import ModalDataViwer from "../../components/SucessfullModal/ModalDataViwer";
import Remarks from "../../components/CommonComponents/Remarks/Remarks";
import { STAGE_CHANGE_TEXT } from "../../constants/textMessage";
import ViewRemark from "../../components/Remark/ViewRemark";
import {
  getAllDisbursedLoansByCustomerId,
  getCustomerReceiptRemarks,
  getCustomerLevelReceiptById,
  createCustomerReceipt,
  updateCustomerReceipt,
  saveCustomerReceiptAndSendForNextStage,
  sendCustomerReceiptForNextStage,
  getCustomerDetailsForCustomerReceiptById,
  checkLoansEligibleForBackDateByCustomerId,
} from "../../services/customerReceipt.service";
import CustomerLevelReceiptLoansTable from "./CustomerLevelReceiptLoansTable";
import { sumBy } from "lodash";
import useSystemConfigStore from "../../store/systemConfigStore";
import { OPEN } from "../../constants/status";
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { PAYMENT_REF_NO_LIMIT } from "../../constants/validationCheck";
import { getBusinessBanks } from "../../services/banks.service";

const schema = yup.object({
  receipt_date: yup.string().nullable().required("Please select receipt Date"),
  excess_amount: yup.number().nullable(),
  target_bank_id: yup.number().required("Please select Bank"),
  customer_level_receipt_amount: yup.string().when(["excess_amount"], {
    is: (excess_amount) => excess_amount <= 0,
    then: () => yup.string().nullable().required("Please Enter Receipt Amount"),
  }),
  instrument_date: yup
    .string()
    .nullable()
    .required("Please select instrument Date"),
  instrument_type: yup
    .string()
    .nullable()
    .required("Please select instrument type"),
});

const saveSchema = schema.shape({
  source_bank_id: yup
    .string()
    .nullable()
    .when(["instrument_type"], {
      is: (instrument_type) => instrument_type != "cash",
      then: () => yup.string().nullable().required("Please select customer bank"),
    }),
  payment_ref_no: yup
    .string()
    .max(parseInt(PAYMENT_REF_NO_LIMIT), `payment no must be less then ${PAYMENT_REF_NO_LIMIT} characters`)
    .nullable()
    .when(["instrument_type"], {
      is: (instrument_type) => instrument_type != "cash",
      then: () => yup.string().nullable().required("Please Enter Payment Ref No"),
    }),
});

const CustomerReceiptCreation = ({ formType }) => {
  const { receiptId, customerId } = useParams();
  const systemDate = useSystemConfigStore(store => store.config);

  const [checked, setChecked] = useState(false);
  const [unidentifiedReceipt, setUnidentifiedReceipt] = useState();
  const [disbReceipt, setDisbReceipt] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState();
  const [remarksLoading, setRemarksLoading] = useState();
  const [successfulModal, setSuccessfulModal] = useState({});
  const [label, setLabel] = useState();
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [excessAmount, setExcessAmount] = useState(0);

  // Ued to get the customer Details
  const { data: customerDetails = {} } = useQuery({
    queryKey: ["customer-details-receipt", customerId],
    queryFn: () => getCustomerDetailsForCustomerReceiptById(customerId),
  });

  // get the next stage
  const { data: stages = [] } = useQuery({
    queryKey: ["cust-receipt-stage", "cust-receipt"],
    queryFn: () => getStageforModules("customer_level_receipt"),
    select: (data) => {
      return {
        current_stage: "draft",
        next_stage: data[1],
      };
    },
  });

  /** get customer banks */
  const { data: customerBank = [] } = useQuery({
    queryKey: ["customer-bank", customerId],
    queryFn: () => customerId && customerBankDetails(customerId),
    enabled: Boolean(customerId),
  });

  /** get remarks by receiptId */
  const { data: reviewTrail = [] } = useQuery({
    queryKey: ["remarks", receiptId],
    queryFn: () => getCustomerReceiptRemarks(receiptId),
    enabled: Boolean(receiptId),
  });

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues:
      formType == "view"
        ? {
          excess_amount: 0,
        }
        : {
          instrument_date: dayjs(new Date()).format(),
          excess_amount: customerDetails?.current_excess_amount,
        },
  });
  const values = watch();

  /** used to get the created receipt details by sending receipt id */
  const loanDetailswithCalculationQuery = useQuery({
    queryKey: ["receipt-details", customerId],
    queryFn: () =>
      getAllDisbursedLoansByCustomerId({
        customer_id: customerId,
        receipt_date: dateFormat(values?.receipt_date, "YYYY-MM-DD"),
        receipt_amount: currencyAsFloat(values?.customer_level_receipt_amount),
        unidentified_receipt_id: unidentifiedReceipt?.id,
      }),
    initialData: [],
    onSuccess: (data) => {
      setDisbReceipt(data);
    },
    enabled: false,
  });

  /** used to get the created receipt details by sending receipt id */
  const { data: receiptDetails = [] } = useQuery({
    queryKey: ["receipt-details", receiptId],
    queryFn: () => receiptId && getCustomerLevelReceiptById(receiptId),
    onSuccess: (data) => {
      setChecked(data?.tds);
      reset(data);
      setValue("excess_amount", data?.current_excess_amount || 0);
    },
  });

  /** getting the unidentified receipt list */
  const { data: UnidentifiedReceiptData = [] } = useQuery({
    queryKey: ["unidentified-receipt"],
    queryFn: () => getEligibleUnidentifiedReceiptByLoanId({ customerId: customerId, moduleName: 'CLR' }),
    select: (response) => {
      return response.map((item) => ({
        ...item,
        search_key: Object.values(item).join().toLowerCase(),
      }));
    },
    enabled: Boolean(formType === "view" ? receiptDetails?.id : true),
  });

  /** used to get the created receipt details by sending receipt id */
  const { data: loanDetails = [] } = useQuery({
    queryKey: ["receipt-details", customerId, values?.receipt_date],
    queryFn: () =>
      getAllDisbursedLoansByCustomerId({
        customer_id: customerId,
        receipt_date: dateFormat(values?.receipt_date, "YYYY-MM-DD"),
      }),
    onSuccess: (data) => {
      setDisbReceipt(data);
      receiptId && loanDetailswithCalculationQuery?.refetch();
    },
    enabled: Boolean(values?.receipt_date),
  });

  /** get Business banks */
  const { data: targetBank = [], isLoading: targetBankLoading } = useQuery({
    queryKey: ["company-bank", customerDetails?.target_bank_id],
    queryFn: () => getBusinessBanks({ product_id: customerDetails?.product_id }),
    enabled: Boolean(customerDetails?.product_id),
  });

  // checking the loan data when the
  const { data: checkData = [] } = useQuery({
    queryKey: ["loan-check", values?.receipt_date],
    queryFn: () =>
      checkLoansEligibleForBackDateByCustomerId(
        customerId,
        values?.receipt_date
      ),
    onSuccess: (data) => {
      if (data?.message) {
        displayNotification({ message: data?.message, variant: 'warning' });
      }
    },
    enabled: Boolean(values?.receipt_date),
  });

  useEffect(() => {
    if (receiptDetails?.unidentified_receipt_id) {
      setUnidentifiedReceipt(UnidentifiedReceiptData?.find((e) => e?.id === receiptDetails?.unidentified_receipt_id))
    } else {
      if (unidentifiedReceipt?.id) {
        setUnidentifiedReceipt({})
      }
    }
  }, [UnidentifiedReceiptData, receiptDetails?.unidentified_receipt_id])

  useEffect(() => {
    if (Object.keys(systemDate).length && !receiptId) {
      setValue("receipt_date", systemDate?.current_system_date);
      setValue("instrument_date", systemDate?.current_system_date);
    }
  }, [systemDate]);

  useEffect(() => {
    if (Object.keys(customerDetails).length) {
      setValue("excess_amount", customerDetails?.current_excess_amount || 0);
    }
  }, [customerDetails]);

  // set default value when the receipt amount or receipt date get changed
  useEffect(() => {
    setDisbReceipt(loanDetails);
  }, [values?.customer_level_receipt_amount]);

  // handling the send for review button
  // validating the values
  const handleReviewModal = () => {
    saveSchema
      .validate(values)
      .then((res) => {
        if (
          !customerDetails?.current_excess_amount &&
          !values?.customer_level_receipt_amount
        ) {
          displayNotification({
            message: "Please Enter Receipt Amount",
            variant: 'error',
          });
        } else if (!values?.tds_amount && checked) {
          displayNotification({
            message: "Please Enter TDS amount",
            variant: 'error',
          });
        } else {
          setOpen(true);
          setStatus("review");
          setLabel(
            stages?.next_stage == "approval"
              ? "Send for Approval"
              : "Send for Review"
          );
        }
      })
      .catch((err) => {
        displayNotification({ message: err?.errors, variant: 'error' });
      });
  };

  // used to calculate the no of back date allowed
  const calculateBackDate = () => {
    return new Date(systemDate?.current_system_date).setDate(
      new Date(systemDate?.current_system_date).getDate() -
      customerDetails?.no_of_days_backdate
    );
  };

  /** function will call when the user click the save button
   * this function will handle the the save and update call
   * data is a object which will contain the
   * {
   *  receipt_date, customer_level_receipt_amount, instrument_type, instrument_date, bank_id, appropriated_amount
   * }
   */
  const onSubmit = () => {
    // body is used for create and update receipt
    const body = {
      customer_id: parseInt(customerId),
      customer_level_receipt_amount: currencyAsFloat(
        values?.customer_level_receipt_amount
      ),
      receipt_date: dateFormat(values?.receipt_date, "YYYY-MM-DD"),
      instrument_date: dateFormat(values?.receipt_date, "YYYY-MM-DD"),
      instrument_type: values?.instrument_type,
      tds: checked == true ? 1 : 0,
      unidentified_receipt_id: unidentifiedReceipt?.id,
      tds_amount:
        checked == true ? currencyAsFloat(values?.tds_amount) || 0 : 0,
      source_bank_id: null,
      target_bank_id: values?.target_bank_id,
    };

    if (values?.instrument_type != "cash") {
      body.payment_ref_no = values?.payment_ref_no?.toUpperCase();
      body.source_bank_id = values?.source_bank_id;
    }

    setButtonLoader({ save: true });
    if (!receiptId) {
      createCustomerReceipt(body)
        .then((res) => {
          setSuccessfulModal({ open: true, title: STAGE_CHANGE_TEXT?.save });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' });
          }
        })
        .finally(() => {
          setButtonLoader({ save: false });
        });
    } else {
      updateCustomerReceipt(receiptId, body)
        .then((res) => {
          setSuccessfulModal({ open: true, title: STAGE_CHANGE_TEXT?.update });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' });
          }
        })
        .finally(() => {
          setButtonLoader({ save: false });
        });
    }
  };

  const OnSendForReview = (remarks) => {
    // body to send for review from created receipt
    const body = {
      remarks: remarks,
      remark_type: "maker",
    };
    // body to direct send for review
    const saveBody = {
      customer_id: parseInt(customerId),
      customer_level_receipt_amount: currencyAsFloat(
        values?.customer_level_receipt_amount
      ),
      receipt_date: dateFormat(values?.receipt_date, "YYYY-MM-DD"),
      instrument_date: dateFormat(values?.receipt_date, "YYYY-MM-DD"),
      instrument_type: values?.instrument_type,
      tds: checked == true ? 1 : 0,
      unidentified_receipt_id: unidentifiedReceipt?.id,
      tds_amount:
        checked == true ? currencyAsFloat(values?.tds_amount) || 0 : 0,
      source_bank_id: null,
      target_bank_id: values?.target_bank_id,
      ...body,
    };

    if (values?.instrument_type != "cash") {
      saveBody.payment_ref_no = values?.payment_ref_no?.toUpperCase();
      saveBody.source_bank_id = values?.source_bank_id;
    }

    setRemarksLoading(true);
    // if there is receipt id then the update function will call or the direct send for review will call
    // If direct send for review then there is no receipt ID, It has been handled by backend
    if (!receiptId) {
      saveCustomerReceiptAndSendForNextStage(saveBody)
        .then((res) => {
          setSuccessfulModal({
            open: true,
            title:
              stages?.next_stage == "approval"
                ? STAGE_CHANGE_TEXT?.approval
                : STAGE_CHANGE_TEXT?.review,
          });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' });
          }
        })
        .finally(() => {
          setOpen(false);
          setRemarksLoading(false);
        });
    } else {
      updateCustomerReceipt(receiptId, saveBody)
        .then((res) => {
          sendCustomerReceiptForNextStage(receiptId, body)
            .then((res) => {
              setSuccessfulModal({
                open: true,
                title:
                  stages?.next_stage == "approval"
                    ? STAGE_CHANGE_TEXT?.approval
                    : STAGE_CHANGE_TEXT?.review,
              });
            })
            .catch((e) => {
              if (e?.RequestValidationError) {
                Object.keys(e?.RequestValidationError).forEach(
                  (item, index) => {
                    setError(item, {
                      message: e?.RequestValidationError[item],
                    });
                  }
                );
              } else {
                displayNotification({ message: e?.message || e, variant: 'error' });
              }
            });
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' });
        })
        .finally(() => {
          setOpen(false);
          setRemarksLoading(false);
        });
    }
  };

  const handleUnidentifiedReceiptSelection = (data) => {
    if (data?.id) {
      setUnidentifiedReceipt(data)
      setValue('receipt_date', data?.unidentified_receipt_date)
      setValue('customer_level_receipt_amount', data?.unidentified_receipt_amount)
      setValue('instrument_type', data?.instrument_type)
      setValue('payment_ref_no', data?.payment_ref_no)
      setValue('target_bank_id', data?.target_bank_id)
    } else {
      setUnidentifiedReceipt({})
      setValue('receipt_date', systemDate?.current_system_date)
      setValue('customer_level_receipt_amount', '')
      setValue('instrument_type', '')
      setValue('payment_ref_no', '')
      setValue('target_bank_id', '')
    }
  }

  /**
   * Calculates the excess amount by considering the customer level receipt 
   * amount and the current excess amount. If there is a remaining amount, then
   * it is subtracted from the sum of the receipt amount and current excess 
   * amount. Otherwise, the loan due amount is subtracted from the sum of the 
   * receipt amount and current excess amount. The result is returned as the 
   * excess amount or 0 if it is negative.
   *
   * @return {number} The calculated excess amount.
   */
  const handleCurrentExcessAmount = () => {
    let remaining_amount = sumBy(disbReceipt, "remaining_amount")
    let due_amount = sumBy(disbReceipt, "loan_due_amount")
    if (remaining_amount > 0) {
      return 0
    } else {
      let amount = (currencyAsFloat(values?.customer_level_receipt_amount || 0) + customerDetails?.current_excess_amount) - due_amount
      return amount > 0 ? amount : 0
    }
  }

  return (
    <>
      <Body background={false}>
        {/* name and id has send as props to display in the page */}
        <PageTitle
          title={
            customerDetails?.customer_name || receiptDetails?.customer_name
          }
          id={customerDetails?.customer_id || receiptDetails?.customer_id}
          action={
            <UnidentifiedReceiptListModal
              submitCallback={handleUnidentifiedReceiptSelection}
              selectedRowId={unidentifiedReceipt?.id}
              data={UnidentifiedReceiptData}
              mode={"edit"}
            />
          }
        />
      </Body>
      <DashBoardCustomerDetails id={customerId} />
      <Box sx={{ position: "relative" }}>
        <Body>
          {/* receipt name is send as props to display and status value is send to change the color of the badge */}
          {receiptDetails?.code ? (
            <div style={ImdStyle.flag}>
              <IdBadge
                label={
                  receiptDetails?.code
                }
              />
            </div>
          ) : null}
          <Grid gutter={"xs"} mt={"md"}>
            <Grid.Col span={4}>
              {!unidentifiedReceipt?.id ? (
                <>
                  <Grid.Col span={12}>
                    <ControllerDatePicker
                      name="receipt_date"
                      label="Receipt Date"
                      required
                      maxDate={new Date(systemDate?.current_system_date)}
                      minDate={new Date(calculateBackDate())}
                      control={control}
                      errors={errors?.receipt_date?.message}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ControllerCurrencyInput
                      name="customer_level_receipt_amount"
                      label="Receipt Amount"
                      /** asterisk will be vissible when there is no excess amount */
                      required={values?.excess_amount <= 0 ? true : false}
                      amountInWords
                      value={values?.customer_level_receipt_amount}
                      control={control}
                      errors={errors?.customer_level_receipt_amount?.message}
                      autoComplete='off'
                    />
                  </Grid.Col>
                </>
              ) : (
                <>
                  <Grid.Col span={12}>
                    <ViewData
                      title={"Receipt Date"}
                      value={values?.receipt_date}
                      type={"date"}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewData
                      title={"Receipt Amount"}
                      value={values?.customer_level_receipt_amount}
                    />
                  </Grid.Col>
                </>
              )}
              <Grid.Col span={12}>
                <ViewData
                  title={"Current Excess"}
                  value={handleCurrentExcessAmount()}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewData
                  title={"Existing Excess"}
                  value={customerDetails?.current_excess_amount}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewData
                  title={"Due Amount"}
                  value={sumBy(disbReceipt, "loan_due_amount")}
                />
              </Grid.Col>
              {disbReceipt?.dpd ? (
                <Grid
                  sx={{ display: "flex", background: "#ff5a5a", width: "60%" }}
                  ml={"1px"}
                >
                  <Grid.Col span={12}>
                    <Title order={6} sx={{ color: "#ffd7d7" }}>
                      DPD {disbReceipt?.dpd} Days
                    </Title>
                  </Grid.Col>
                </Grid>
              ) : null}
            </Grid.Col>
            <Grid.Col span={4}>
              <Grid.Col span={12}>
                <ViewData
                  title="Instrument Date"
                  value={dateFormat(values?.receipt_date)}
                  type={"text"}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                {!unidentifiedReceipt?.id ? (
                  <ControllerSelect
                    name="instrument_type"
                    label="Instrument Type"
                    required
                    control={control}
                    autoSelect
                    setValue={setValue}
                    data={[
                      ...instrumentType,
                      {
                        id: 4,
                        value: "cash",
                        label: "Cash",
                      },
                    ]}
                    errors={errors?.instrument_type?.message}
                  />
                ) : (
                  <ViewData
                    title={"Instrument Type"}
                    type={"text"}
                    value={values?.instrument_type?.toUpperCase()}
                  />
                )}
              </Grid.Col>
              <Grid.Col span={12}>
                {values?.instrument_type === "cash" ? (
                  <ViewData
                    title={"Payment Ref.No"}
                    value={"Cash"}
                    type="text"
                  />
                ) : !unidentifiedReceipt?.id ? (
                  <ControllerTextField
                    name="payment_ref_no"
                    label="Payment Ref.No"
                    required
                    value={values?.payment_ref_no?.toUpperCase()}
                    disabled={values?.instrument_type === "cash"}
                    control={control}
                    errors={errors?.payment_ref_no?.message}
                    autoComplete='off'
                  />
                ) : (
                  <ViewData
                    title={"Payment Ref.No"}
                    value={values?.payment_ref_no}
                    type={"text"}
                  />
                )}
              </Grid.Col>
              <Grid.Col span={12}>
                <FormLabel
                  label="TDS"
                  render={
                    <Divider
                      labelPosition="right"
                      label={
                        <Box sx={{ display: "flex" }}>
                          <Text size="xs" mr="sm">
                            No
                          </Text>
                          <Switch
                            checked={checked}
                            onChange={(event) =>
                              setChecked(event.currentTarget.checked)
                            }
                          />
                          <Text size="xs" ml="sm">
                            Yes
                          </Text>
                        </Box>
                      }
                    />
                  }
                />
              </Grid.Col>
              {checked ? (
                <Grid.Col span={12}>
                  <ControllerCurrencyInput
                    name="tds_amount"
                    label="TDS Amount"
                    control={control}
                    errors={errors?.tds_amount?.message}
                  />
                </Grid.Col>
              ) : null}
            </Grid.Col>
            <Grid.Col span={4}>
              {values?.instrument_type != "cash" ? (
                <>
                  <Grid.Col>
                    <TitleViewer title="Source Bank: Customer" />
                  </Grid.Col>
                  <Grid.Col pt="md">
                    <ControllerSelect
                      name="source_bank_id"
                      control={control}
                      type={'bank'}
                      autoSelect
                      setValue={setValue}
                      spanSize={12}
                      displayLabel={false}
                      placeholder="Select Bank Details"
                      data={customerBank}
                      errors={errors?.source_bank_id?.message}
                    />
                  </Grid.Col>
                </>
              ) : null}
              <Grid.Col>
                <TitleViewer title="Target Bank: Petromoney" />
              </Grid.Col>
              {!unidentifiedReceipt?.id ? (
                <Grid.Col span={12} mt={'xs'}>
                  <ControllerSelect
                    name="target_bank_id"
                    control={control}
                    type={'bank'}
                    autoSelect
                    setValue={setValue}
                    spanSize={12}
                    displayLabel={false}
                    placeholder="Select Bank Details"
                    data={targetBank}
                    errors={errors?.target_bank_id?.message}
                  />
                </Grid.Col>
              ) : <>
                <Grid.Col span={12}>
                  <ViewData
                    title="Account No"
                    value={targetBank?.find(e => e.id === values?.target_bank_id)?.account_number}
                    type={"text"}
                    style={{ marginBottom: "0" }}
                    loading={targetBankLoading}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewData
                    title="IFSC"
                    value={targetBank?.find(e => e.id === values?.target_bank_id)?.ifsc}
                    type={"text"}
                    loading={targetBankLoading}
                  />
                </Grid.Col>
              </>}
            </Grid.Col>
            <Grid.Col span={12}>
              <Grid.Col span={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "2px dashed rgb(0,0,0,0.2)",
                  }}
                >
                  <Title order={4}>Receipt Appropriation</Title>
                  <Box>
                    <Button
                      size={"xs"}
                      mr={"10px"}
                      sx={{ height: 27 }}
                      variant={"subtle"}
                      onClick={() => loanDetailswithCalculationQuery?.refetch()}
                      loading={loanDetailswithCalculationQuery?.isFetching}
                    >
                      Calculate Appropration
                    </Button>
                  </Box>
                </Box>
              </Grid.Col>
              <Grid.Col>
                <CustomerLevelReceiptLoansTable data={disbReceipt} />
              </Grid.Col>
            </Grid.Col>
          </Grid>
          {/** This Action buttons component is used to display the buttons in the flow */}
          <ActionButtons
            status={receiptDetails?.status_value ? receiptDetails?.status_value : OPEN}
            base={'creation'}
            nextStage={stages?.next_stage}
            currentStatus={values?.status_value}
            sendForReviewCB={handleReviewModal}
            sendForApprovalCB={handleReviewModal}
            saveCB={() => handleSubmit(onSubmit)()}
            loading={{ save: buttonLoader?.save }}
            remarksCB={() => setRemarksOpen(true)}
            remarks={reviewTrail}
            moduleApplicationId={values?.id}
            moduleName={'customer_level_receipt'}
            isReady={Boolean(stages?.next_stage)}
          />
          {/** display the modal on sucessfull status change */}
          <ModalDataViwer
            opened={successfulModal?.open}
            label={receiptDetails?.code}
            title={successfulModal?.title}
            href={"/customer-receipt"}
            hreftext={"Back to list table"}
          />
          {/** used to get the remarks from the user */}
          <Remarks
            open={open}
            setOpen={setOpen}
            type={stages?.next_stage}
            callback={OnSendForReview}
            remarksTitle={
              stages?.next_stage == "review"
                ? "Review Remarks"
                : "Approval Remarks"
            }
            check={status}
            label={label}
            loading={remarksLoading}
          />
        </Body>
        {/** used to display the remarks */}
        <ViewRemark
          open={remarksOpen}
          onClose={setRemarksOpen}
          remarkTrail={reviewTrail}
        />
      </Box>
    </>
  );
};

export default CustomerReceiptCreation;
