import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import { createStyles, Text, Box, Card } from "@mantine/core";
import Body from "../../components/Body/Body";
import { COLORS } from "../../constants";
import { useNavigate } from "react-router-dom";
import { getAllReports } from "./utils/getReportStateByName";
import { isAllowed } from "../../utils/roleBaseAccess";
import useAuthStore from "../../store/authStore";

const useStyles = createStyles({
  cardstyle: {
    cursor: "pointer",
    height: 120,
    width: 130,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 20px 30px'
    }
  },
  textStyle: {
    color: COLORS.primary.dark,
    textAlign: "center",
    fontSize: 12,
  },
});

const reportsList = getAllReports();

const Report = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const auth = useAuthStore(store => store.auth)

  return (
    <Body background={false}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          marginBottom: 1,
          alignItems: "center",
        }}
      >
        <PageTitle title={"Report"} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {reportsList?.map((item, index) => (
          isAllowed(auth?.group_id,'', item?.isShow) && (
            <Card
              key={index}
              shadow="sm"
              padding="md"
              m="md"
              radius="md"
              sx={{ width: "150px" }}
              className={classes?.cardstyle}
              onClick={() => navigate(item?.href)}
            >
              <item.icon size={30} color={"gray"} strokeWidth={"1px"} />
              <Text mt={"20px"} className={classes?.textStyle}>
                {item?.label}
              </Text>
            </Card>
        )))}
      </Box>
    </Body>
  );
};

export default Report;
