import { getAPICall } from '.';

export const getForeclosureTableData = ({page, search, records = 10}) => {
  /** used to get foreclosure data */
  return getAPICall(`foreclosed-loans?page=${page}&customer_id_name=${search ? search : ''}&records=${records}`, { returnObject: true })
};

export const getForeclosureDatabyId = (id) => {
  /** used to get foreclosure data by id */
  return getAPICall(`foreclosures/?loan_id=${id}`)
};