import { Box, createStyles, Title } from '@mantine/core';
import React from 'react';

const useStyles = createStyles({
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '2px dashed rgb(0,0,0,0.2)',
    alignItems: 'center'
  },
  main: {
    paddingBottom: 4,
  }
});

const TitleViewer = ({ title, children }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.box}>
      <Title order={6} className={classes.main}>
        {title}
      </Title>
      {children}
    </Box>
  );
};

export default TitleViewer;
