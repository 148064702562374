import React, { useEffect, useState } from "react";
import Body from "../../components/Body/Body";
import DataTableWrapper from "../../components/CommonComponents/Table/DataTableWrapper";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import { getReportbyReportName, getReportPaginationDetails, getReportSummaryDetails } from "../../services/report.service";
import {
  ActionIcon,
  Box,
  Button,
  createStyles,
  Popover,
  Text,
  Tooltip,
} from "@mantine/core";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { Calendar, Download } from "tabler-icons-react";
import { useQuery } from "@tanstack/react-query";
import { ReportFilterKey } from "../../constants/symbols";
import { generateReportHeaders } from "./generateReportHeaders";
import EntityFilter from "../../components/CommonComponents/Filter/EntityFilter";
import useSystemConfigStore from "../../store/systemConfigStore";
import FeeSummaryDetails from "./FeeSummaryDetails";
import { getReportObjectByReportName } from "./utils/getReportStateByName";
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { openFile } from "../../utils/openFile";
import { sumBy } from 'lodash';
import { reportDownload } from "./reportDownload/reportDownload";

const createStyle = createStyles({
  dateSelection: {
    background: "#FFFF",
    display: "flex",
    justifyContent: "center",
    width: "250px",
    padding: "8px",
    columnGap: "5px",
    borderRadius: "5px",
    marginLeft: "10px",
    cursor: "pointer",
    border: "1px solid #e1e1e1",
  },
});

const FeeReport = ({ withEntityFilter }) => {
  const reportStateObj = getReportObjectByReportName('fee-report')
  const systemDate = useSystemConfigStore(store => store.config);
  const { classes } = createStyle();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [reportDownloadLoading, setReportDownloadLoading] = useState(false);
  const [records, setRecords] = useState({ label: 'Show 10', value: 10 });
  const [modalOpen, setModalOpen] = useState(false);
  const [searchData, setSearchData] = useState({
    from_date: dateFormat(new Date(systemDate?.current_system_date), "YYYY-MM-DD"),
    to_date: dateFormat(new Date(systemDate?.current_system_date), "YYYY-MM-DD"),
    download_csv: "no",
  });
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);


  /** used to change the page to 1 while the search us used 
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1)
  }, [search])

  /** used to get the report by sending the report name and from_date, to_date and csv */
  const { data: reportData = {}, isLoading, isFetching } = useQuery(
    [`report-details-${reportStateObj?.report_name}`, searchData, page, search, records],
    ({ signal }) =>
      getReportbyReportName(
        reportStateObj?.report_name,
        searchData,
        { page, search, records: records?.value },
        signal,
      ),
    {
      select: (data) => {
        return {
          ...data,
          headers: generateReportHeaders(data?.headers),
        }
      },
      enabled: Boolean(systemDate?.current_system_date),
      refetchOnWindowFocus: false,
    }
  );

  /** used to get the page count and total records */
  const { data: paginationDetails = {} } = useQuery(
    [`pagination-${reportStateObj?.report_name}`, search, records, searchData],
    ({ signal }) => getReportPaginationDetails(
      {
        report_name: reportStateObj?.report_name,
        search,
        records: records?.value
      },
      searchData,
      signal
    ),
    {
      refetchOnWindowFocus: false,
    }
  )

  /** used to get the page count and total records */
  const { data: summaryDetails = [] } = useQuery(
    [`pagination-${reportStateObj?.report_name}`, search, searchData],
    ({ signal }) => getReportSummaryDetails(
      {
        report_name: reportStateObj?.report_name,
        search,
      },
      searchData,
      signal
    ),
    {
      refetchOnWindowFocus: false,
      select: (data) => {
        // get the sum fee and push it into the data to display the fee summary with total
        let totalData = { fee_type: 'total', dealer_count: sumBy(data?.data, 'dealer_count'), fee_amount: sumBy(data?.data, 'fee_amount'), gst: sumBy(data?.data, 'gst'), total_fee: sumBy(data?.data, 'total_fee') }
        return { ...data, data: [...data?.data, totalData] };
      }
    }
  )
  /** used to set the default date as system date when the system date context is loaded */
  useEffect(() => {
    if (systemDate?.current_system_date) {
      setState([
        {
          startDate: new Date(systemDate?.current_system_date),
          endDate: new Date(systemDate?.current_system_date),
          key: "selection",
        },
      ]);
    }
  }, [systemDate?.current_system_date])

  /** used to handle the date */
  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setState([selection]);
  };

  /** used to handle the date filter */
  const handleFilter = () => {
    setModalOpen(false);
    setSearchData(oldSearchData => ({
      ...oldSearchData,
      from_date: dateFormat(state[0]?.startDate, "YYYY-MM-DD"),
      to_date: dateFormat(state[0]?.endDate, "YYYY-MM-DD"),
      download_csv: "no",
    }));
  };

  /** used to handle the file download */
  const handleDownload = () => {
    if (reportData?.report_url) {
      openFile(reportData?.report_url);
    } else {
      setReportDownloadLoading(true)
      getReportbyReportName(
        reportStateObj?.report_name,
        { ...searchData, download_csv: 'yes' },
        { page, search, records: records?.value }
      )
        .then((res) => {
          displayNotification({
            message: 'Report will download shortly',
            variant: 'warning',
            loading: true,
            id: 'report-loading',
            autoClose: false
          });
          reportDownload({id: res?.report_download_id})
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' })
        })
        .finally(() => {
          setReportDownloadLoading(false)
        })
    }
  };

  const tableAction = (
    <>
      {reportData?.data?.length ? (
        <Tooltip
          label={<Text size={"xs"}>Download Report</Text>}
          color={"green"}
          transition="pop"
          transitionDuration={300}
          withArrow
        >
          <ActionIcon ml={"sm"} mr={"sm"} onClick={handleDownload} loading={reportDownloadLoading}>
            <Download color={"#40c057"} />
          </ActionIcon>
        </Tooltip>
      ) : null}

      {
        withEntityFilter ? (
          <EntityFilter
            value={searchData}
            onChange={v => {
              setSearchData(oldSearchData => ({
                ...oldSearchData,
                entity_id: v === 'all' ? undefined : v
              }))
            }}
          />
        ) : null
      }

      <Popover
        position="bottom"
        shadow="md"
        opened={modalOpen}
        onChange={setModalOpen}
      >
        <Popover.Target>
          <Text
            onClick={() => setModalOpen(true)}
            size={"12px"}
            className={classes?.dateSelection}
          >
            <Calendar strokeWidth={1.5} color={"gray"} size={"20"} />{" "}
            <strong>From:</strong> {dateFormat(state[0]?.startDate)}{" "}
            <strong style={{ marginLeft: "6px" }}>To:</strong>{" "}
            {dateFormat(state[0]?.endDate)}
          </Text>
        </Popover.Target>
        <Popover.Dropdown>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <DateRange
              onChange={handleOnChange}
              months={1}
              maxDate={new Date(systemDate?.current_system_date ? systemDate?.current_system_date : new Date())}
              ranges={state}
              retainEndDateOnFirstSelection
              direction="horizontal"
            />
            <Button onClick={handleFilter}>Apply</Button>
          </Box>
        </Popover.Dropdown>
      </Popover>
    </>
  );

  return (
    <Body background={false}>
      <DataTableWrapper
        title={"Fee Report"}
        localKey={ReportFilterKey[reportStateObj?.header]}
        noDataText={`No ${reportStateObj?.title} yet!`}
        noDataSubText="This Section will contain list accounting entries details."
        actions={tableAction}
        addDataOption={false}
        rowData={reportData?.data || []}
        styles={{ overflowX: "auto", whiteSpace: "nowrap", maxWidth: "100vw" }}
        columnData={reportData?.headers || []}
        useApiPagination
        totalNoOfPages={paginationDetails?.total_number_of_pages}
        totalNoOfRecords={paginationDetails?.total_number_of_records}
        page={page}
        setPage={setPage}
        apiSearch={setSearch}
        rowLength={records}
        summaryDetailsJSX={summaryDetails?.data?.length ? <FeeSummaryDetails data={summaryDetails} /> : null}
        setRowLength={setRecords}
        loading={isLoading || isFetching}
      />
    </Body>
  );
};

export default FeeReport;
