import { createStyles, Title, Badge } from "@mantine/core";
import React from "react";
import ReactTable from "../../../../components/CommonComponents/Table/ReactTable";
import { getAllUserDetails, getRoles } from "../../../../services/user.service";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { Box } from "@mantine/core";
import { useState } from "react";
import { CircleCheck, Search } from "tabler-icons-react";
import Body from '../../../../components/Body/Body';
import { TextInput } from "@mantine/core";

const useStyles = createStyles({
  main: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
});

const UsersTable = () => {
  const { classes } = useStyles();
  const columnHelper = createColumnHelper();
  const [search, setSearch] = useState();
  /** userDetails will contain all user details in array format */
  const userDetails = useQuery(["all-users"], () => getAllUserDetails(), {
    refetchOnWindowFocus: false,
  });
  /** roles will contain all available roles in array format */
  const roles = useQuery(["roles"], () => getRoles(), {
    refetchOnWindowFocus: false,
  });

  /** adding the color */
  const spanField = (value) => {
    return <span style={{ color: "#0063FF" }}>{value}</span>;
  };

  const columnData = [
    columnHelper.accessor("id", {
      header: "User ID",
      cell: (value) => spanField(value.getValue(), value),
    }),
    columnHelper.accessor("first_name", {
      header: "Name",
      cell: (value) =>
        spanField(
          value.row.original?.first_name + " " + value.row.original?.last_name,
          value
        ),
    }),
    columnHelper.accessor("mobile", {
      header: "Mobile",
      cell: (value) => spanField(value.getValue(), value),
    }),
    columnHelper.accessor("email", {
      header: "Email",
    }),
    columnHelper.accessor("group_id", {
      header: "Role",
      cell: (value) =>
        roles?.data?.map(
          (item) =>
            value.row.original?.group_id == item?.id &&
            item?.name?.toUpperCase()
        ),
    }),
    columnHelper.accessor("status", {
      header: "IsActive",
      cell: (value) => (
        <CircleCheck
          size={"15px"}
          color={value.getValue() == 1 ? "green" : "gray"}
        />
      ),
    }),
  ];

  return (
    <Body>
      <Box className={classes.main} mb={"lg"}>
        <Title order={4} pl={"5px"}>
          All Users
          {userDetails?.data?.length ? (
            <Badge ml={"sm"} mb={"xs"} color={"indigo"}>
              {userDetails?.data?.length}
            </Badge>
          ) : null}
        </Title>
        <TextInput
          placeholder="Search"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          icon={<Search size={16} />}
        />
      </Box>
      <ReactTable
        title="Fee"
        search={search}
        allowSorting
        rowData={userDetails?.data || []}
        columnData={columnData}
        loading={userDetails?.isLoading}
      />
    </Body>
  );
};

export default UsersTable;
