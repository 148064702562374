import { Box } from "@mantine/core";
import { ChevronLeft, ChevronRight } from "tabler-icons-react";

const SummaryToggleBlock = ({ open, onClick }) => {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: "0px",
          borderRadius: "5px",
          height: "400px",
          backgroundColor: "#ddd",
          width: "4px",
          cursor: "pointer",
          opacity: ".1",
          borderRadius: 2,
          transition: "all .2s ease-in-out",
          ":hover": {
            opacity: ".5",
          }
        }}
        onClick={onClick}
      />
      <Box
        sx={{
          position: "absolute",
          top: 8,
          right: -10,
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        {open ? (
          <ChevronLeft color={"#bbb"} />
        ) : (
          <ChevronRight color={"#bbb"} />
        )}
      </Box>
    </>
  )
}

export default SummaryToggleBlock;