import { apiCallProtected } from '../api/axios';


// Used to generate the receipt PDF by receipt ID
export const generateIMDPdfbyID = (id) => {
    return new Promise((resolve, reject) => {
      apiCallProtected
        .get(`imd/pdf/${id}`)
        .then(({ status, data, message }) => {
          if (status === 'success') {
            resolve(data);
          } else {
            reject(message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
};
export const generateDisbMEMOPdfbyID = (id) => {
    return new Promise((resolve, reject) => {
      apiCallProtected
        .get(`disbursement/memo-pdf/${id}`)
        .then(({ status, data, message }) => {
          if (status === 'success') {
            resolve(data);
          } else {
            reject(message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
};
export const generateReceiptPdfbyID = (id) => {
    return new Promise((resolve, reject) => {
      apiCallProtected
        .get(`receipt/pdf/${id}`)
        .then(({ status, data, message }) => {
          if (status === 'success') {
            resolve(data);
          } else {
            reject(message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
};
export const generateWaiverPdfbyID = (id) => {
    return new Promise((resolve, reject) => {
      apiCallProtected
        .get(`waiver/pdf/${id}`)
        .then(({ status, data, message }) => {
          if (status === 'success') {
            resolve(data);
          } else {
            reject(message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
};
export const generateExternalCustomerInvoicePDFbyID = (id) => {
    return new Promise((resolve, reject) => {
      apiCallProtected
        .get(`/external-customer/invoice-pdf/${id}`)
        .then(({ status, data, message }) => {
          if (status === 'success') {
            resolve(data);
          } else {
            reject(message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
};
export const generateRefundPDFbyID = (id) => {
    return new Promise((resolve, reject) => {
      apiCallProtected
        .get(`/refund/pdf/${id}`)
        .then(({ status, data, message }) => {
          if (status === 'success') {
            resolve(data);
          } else {
            reject(message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
};