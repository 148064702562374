
const topBar = {
  main: {
    position: '-webkit-sticky',
    position: 'sticky',
    top: '0',
    zIndex:'99',
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  petro: {
    color: 'rgba(241, 89, 42, 1)'
  },
  money: {
    color: 'rgba(5, 104, 57, 1)'
  },
  heroText: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  iconColor: {
    color: 'rgba(143, 143, 143, 1)'
  },
  iconsGroup: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  navbar: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    position: 'sticky'
  },
  Dashboardcard: {
    margin: '20px',
    marginBottom: '0',
    borderRadius: '12px',
  },
}

export default topBar
