import React from 'react'
import { Grid, Text, Stack, Title } from '@mantine/core';
import { COLORS } from '../../../constants';
import ControllerTextField from '../../../components/CommonComponents/TextField/ControllerTextField';

/**
 * Renders a communication invoice component with phone number, fax number, email,
 * home page, IC partner code, and document sending profile fields.
 *
 * @param {Object} control - An object that contains the control functions of the form.
 * @param {Object} errors - An object that contains the errors of the form.
 * @param {Function} setValue - A function that sets the value of the field.
 * @param {Object} placeholderStyle - An object that contains the styles of the placeholders.
 */
const CommunicationInvoiceComp = ({ control, errors, setValue, placeholderStyle }) => {
  return (
    <Grid gutter={'md'} sx={{maxHeight:"55vh",overflow:'hidden',overflowY: 'auto'}}>
      <Grid.Col span={12} sx={{ height: 'fit-content' }}>
        <Title order={6} mb={'md'}>Communication</Title>
        <Grid gutter={'md'}>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Phone No</Text>
              <ControllerTextField
                name={'mobile'}
                label={'Name'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Phone No'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.name?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Fax No</Text>
              <ControllerTextField
                name={'fax_no'}
                label={'Fax No'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Fax number'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.fax_no?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>E-Mail</Text>
              <ControllerTextField
                name={'email'}
                label={'E-Mail'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter your email'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.email?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Home Page</Text>
              <ControllerTextField
                name={'home_page'}
                label={'Home Page'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Home page'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.home_page?.message}
                required
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>IC Partner Code</Text>
              <ControllerTextField
                name={'ic_partner_code'}
                label={'IC Partner Code'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter IC Partner Code'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.ic_partner_code?.message}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={4} py={0}>
            <Stack spacing={'xs'}>
              <Text size={'xs'}>Document Sending Profile</Text>
              <ControllerTextField
                name={'document_sending_profile'}
                label={'Document Sending Profile'}
                displayLabel={false}
                spanSize={12}
                placeholder={'Enter Document Sending Profile'}
                control={control}
                styles={placeholderStyle}
                errors={errors?.document_sending_profile?.message}
              />
            </Stack>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  )
}

export default CommunicationInvoiceComp
