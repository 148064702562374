import { Grid, Title } from '@mantine/core';
import React from 'react';

const FormLabel = ({ label, render }) => {
  return (
    <Grid gutter='md' mb={8}>
      <Grid.Col span={5}>
        <Title order={4}>{label}</Title>
      </Grid.Col>
      <Grid.Col span={7}>
        {render}
      </Grid.Col>
    </Grid>
  );
};

export default FormLabel;
