import { apiCallProtected } from '../api/axios';


export const getPaymentHistorybyLoanId = (id) => {
    return new Promise((resolve, reject) => {
      apiCallProtected
        .get(`/payment-histories/${id}`)
        .then(({ status, data, message }) => {
          if (status === 'success') {
            resolve(data);
          } else {
            reject(message);
          }
        })
        .catch(reject);
    });
};

export const searchLoanIdforPayment = (loanId) => {
    return new Promise((resolve, reject) => {
      apiCallProtected
        .get(`/loan/search/${loanId}`)
        .then(({ status, data, message }) => {
          if (status === 'success') {
            resolve(data[0]);
          } else {
            reject(message);
          }
        })
        .catch(reject);
    });
};