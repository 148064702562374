import { Badge, Box, Button, Checkbox, Grid, Group, TextInput, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { Search } from "tabler-icons-react";
import { SIZES } from "../../../constants";
import DraggableList from "../Dnd/DraggableList";

const FilterModal = ({
  columnData,
  filteredColumnData,
  onUpdateColumn,
  onClose,
}) => {
  // filteredColumndata is the data which is stored in localStorage of the table
  const [filteredData, setFilteredData] = useState(filteredColumnData?.map(item => item?.header));
  const [data, setData] = useState(columnData?.map(item => item?.header))
  const [demoData, setDemoData] = useState(columnData?.map(item => item?.header))
  const [columnSearchData, setColumnSearchData] = useState(filteredData)
  const [appropriation, setAppropriation] = useState([])
  const [columnSearch, setColumnSearch] = useState()
  const [search, setSearch] = useState()

  useEffect(() => {
    if (search) {
      const res = data?.filter((entry) =>
        entry?.toLowerCase()?.includes(search?.toLowerCase())
      );
      setDemoData(res)
    }
    else {
      setDemoData(columnData?.map(item => item?.header))
    }
  }, [search])

  useEffect(() => {
    if (columnSearch) {
      const res = filteredData?.filter((entry) =>
        entry?.toLowerCase()?.includes(columnSearch?.toLowerCase())
      );
      setColumnSearchData(res)
    }
    else {
      setColumnSearchData(filteredData)
    }
  }, [columnSearch, filteredData])
  return (
    <Box>
      <Grid sx={{
        borderTop: '2px solid rgb(0,0,0,0.1)',
        borderBottom: '2px solid rgb(0,0,0,0.1)',
        margin: 0
      }}>
        <Grid.Col span={6} p={SIZES.padding * 2} pb={0} pl={0} >
          <Title order={6}>Column Options</Title>
          <TextInput
            mt={SIZES.padding}
            mb={SIZES.padding}
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
            icon={<Search size={16} />}
          />
          <Box sx={{
            overflow: 'auto',
            maxHeight: '50vh',
            minHeight: '50vh'
          }}>
            <Checkbox.Group value={filteredData} onChange={setFilteredData}>
              <Group>
                {
                  demoData?.map((item, i) => <Checkbox key={i} sx={{ minWidth: '90%' }} styles={(theme) => ({ label: { cursor: 'pointer' }, input: { cursor: 'pointer' } })} mt={SIZES.base} ml={SIZES.base} value={item} label={item} />)
                }
              </Group>
            </Checkbox.Group>
          </Box>
        </Grid.Col>
        <Grid.Col span={6} sx={{ borderLeft: '2px solid rgb(0,0,0,0.1)' }} p={SIZES.padding * 2} pb={SIZES.padding} pr={0}>
          <Box sx={{ display: 'flex' }}>
            <Title order={6}>Selected Columns</Title>
            <Badge variant="light" ml={'xs'} color="indigo">{filteredData?.length}</Badge>
          </Box>
          <TextInput
            mt={SIZES.padding}
            mb={SIZES.padding}
            mr={SIZES.padding}
            placeholder="Search"
            onChange={(e) => setColumnSearch(e.target.value)}
            icon={<Search size={16} />}
          />
          <DraggableList
            containerStyle={{
              overflow: 'auto',
              maxHeight: '50vh',
              minHeight: '50vh',
            }}
            listData={columnSearchData}
            onChange={setAppropriation}
          />
        </Grid.Col>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px",
        }}
        mt={"md"}
      >
        <Button
          variant='outline'
          size='sm'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          sx={{ minWidth: 90 }}
          color='green'
          variant='filled'
          size='sm'
          onClick={() => onUpdateColumn(appropriation)}
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}

export default FilterModal;