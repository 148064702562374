import { useState } from "react";
import changeValueFormat, { VALUE_TYPES } from "../../../utils/changeValueFormat";
import { Text, Tooltip } from "@mantine/core";
import { currencyInWords } from "../TextField/NumberToWords";

const CRORE = 10000000;

const DisplayAmountComp = ({ value, type = 'amount', styles = {} }) => {
  const [amountInCR, setAmountInCR] = useState(true);

  let FinalValue = changeValueFormat(value, type);

  if (type?.toLowerCase() !== VALUE_TYPES.amount) {
    return (
      <Text
        sx={{
          fontWeight: 500,
          display: "flex",
          flexDirection: "row",
          gap: "2px",
          ...styles
        }}
      >
        {FinalValue}
      </Text>
    );
  }

  let suffix = "";
  if (type?.toLowerCase() === VALUE_TYPES.amount && amountInCR) {
    FinalValue = changeValueFormat(value / CRORE, type, { round: false });
    suffix = "Cr";
  }

  return (
    <Tooltip label={currencyInWords(parseInt(value))} disabled={value<0 || !value} color="gray">
      <Text
        onClick={() => setAmountInCR(!amountInCR)}
        sx={{
          fontWeight: 500,
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          gap: "2px",
          ...styles
        }}
      >
        {FinalValue} {suffix}
      </Text>
    </Tooltip>
  );
};

export default DisplayAmountComp