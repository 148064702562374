import { createStyles, Title, Text } from '@mantine/core';
import React from 'react';
import { Box } from '@mantine/core';

const useStyles = createStyles({
  badge: {
    width: '230px',
    height: '0px',
    display: 'flex',
    borderTopLeftRadius: '10px',
    borderRight: '18px solid transparent',
    position: 'relative',
  },
  maker: {
    borderTop: '18px solid gray',
    borderLeft: '18px solid gray',
    borderBottom: '18px solid gray',
  },
  review: {
    borderTop: '18px solid #0063FF',
    borderLeft: '18px solid #0063FF',
    borderBottom: '18px solid #0063FF',
  },
  approved: {
    borderTop: '18px solid green',
    borderLeft: '18px solid green',
    borderBottom: '18px solid green',
  },
  rejected: {
    borderTop: '18px solid red',
    borderLeft: '18px solid red',
    borderBottom: '18px solid red',
  },
  cancelled: {
    borderTop: '18px solid red',
    borderLeft: '18px solid red',
    borderBottom: '18px solid red',
  },
  approval: {
    borderTop: '18px solid #0063FF',
    borderLeft: '18px solid #0063FF',
    borderBottom: '18px solid #0063FF',
  },
  autoApproved: {
    borderTop: '18px solid teal',
    borderLeft: '18px solid teal',
    borderBottom: '18px solid teal',
  },
  textdata: {
    width: '210px',
    color: 'white',
    position: 'absolute',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    top: '9px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  textReview: {
    backgroundColor: '#0063FF',
  },
  textApproval: {
    backgroundColor: '#0063FF',
  },
  textMaker: {
    backgroundColor: 'gray',
  },
  textApproved: {
    backgroundColor: 'green',
  },
  textRejected: {
    backgroundColor: 'red',
  },
  textCancelled: {
    backgroundColor: 'red',
  },
  textAutoApproved: {
    backgroundColor: 'teal',
  }
});

const remarkStatusStyleMap = {
  draft: {
    badge: 'maker',
    text: 'textMaker'
  },
  review: {
    badge: 'review',
    text: 'textReview'
  },
  approval: {
    badge: 'approval',
    text: 'textApproval'
  },  
  approved: {
    badge: 'approved',
    text: 'textApproved'
  },
  rejected: {
    badge: 'rejected',
    text: 'textRejected'
  },
  cancelled: {
    badge: 'cancelled',
    text: 'textCancelled'
  },  
  cancel_review: {
    badge: 'cancelled',
    text: 'textCancelled'
  },  
  cancel_approval: {
    badge: 'cancelled',
    text: 'textCancelled'
  },  
  auto_approved: {
    badge: 'autoApproved',
    text: 'textAutoApproved'
  },  
  auto_cancelled: {
    badge: 'cancelled',
    text: 'textCancelled'
  },  
}


const IdBadge = ({ label, remarks = 'draft' }) => {
  const { classes, cx } = useStyles();

  return (
    <Box>
      <div className={cx(classes.badge, classes[remarkStatusStyleMap[remarks].badge] )}></div>
      <Title order={4} className={cx(classes.textdata, classes[remarkStatusStyleMap[remarks].text] )}>{label}</Title>
    </Box>
  );
};

export default IdBadge;
