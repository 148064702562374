import { Box, Grid, Image, Title, Button } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import DataViewerWapper from '../../components/CommonComponents/Preview/DataViewerWapper';
import {
  ViewFormData,
  ViewFormDataContained,
} from '../../components/CommonComponents/Preview/FilePreview';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import ImdStyle from '../IMD/Imd.styles';
import {
  getLoanDetailsById,
  sendForPushback,
  sendForReject,
  getAllRemarks,
  getDeductionLoansbyID,
  getLoanByDealershipId,
  sendLoansForCancellation,
  sendLoanforNextStage
} from '../../services/loan.service';
import { getRepaymentType } from '../../services/master.service';
import DashBoardCustomerDetails from '../../components/DashboardCard/dashboard';
import Body from '../../components/Body/Body';
import Remarks from '../../components/CommonComponents/Remarks/Remarks';
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { GENERAL_IMG, STAGE_CHANGE_TEXT } from '../../constants';
import PageTitle from '../../components/PageTitle/PageTitle';
import Nums from '../../components/Body/Nums';
import IdBadge from '../../components/CommonComponents/Preview/IdBadge';
import ViewRemark from '../../components/Remark/ViewRemark';
import ModalDataViwer from '../../components/SucessfullModal/ModalDataViwer';
import ActionButtons
  from '../../components/CommonComponents/ActionButtons/ActionButtons';
import dayjs from 'dayjs';
import LoanRenewalTable from '../../components/CommonComponents/RenewalTable/LoanRenewalTable';
import { getStageforModules } from '../../services/module.service';
import { IN_REVIEW, IN_APPROVAL, CANCELLATION_REVIEW } from '../../constants/status';
import CreditReloadDetails
  from '../../components/CommonComponents/CreditReload/CreditReloadDetails';
import { ViewFeeDetailsWithGST } from '../../components/FeePopover/FeeCalculationPopover';
import TitleViewer from '../../components/CommonComponents/Title/TitleViewer';
import ReloadFeePopover from '../../components/FeePopover/ReloadFeePopover';

const LoanReview = ({ action = true, home = '/tranche' }) => {
  const { loanId, createdLoanId } = useParams();
  const [repayment, setRepayment] = useState();
  const [remarksTitle, setRemarksTitle] = useState()
  const [label, setLabel] = useState();
  const [remarksLoading, setRemarksLoading] = useState();
  const [status, setStatus] = useState()
  const [prospectcodebody, setProspectcodebody] = useState(null)
  const [ProspectCode, setProspectCode] = useState([])
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [sucessfulmodal, setSucessfulmodal] = useState(false);

  const { data = [], isLoading: loading } = useQuery(
    ['loan-detail', createdLoanId],
    () => createdLoanId && getLoanDetailsById(createdLoanId),
    {
      onSuccess: (data) => {
        setProspectcodebody({
          id: data?.deduction_loan_ids?.length
            ? data?.deduction_loan_ids
            : null,
        });
      },
      refetchOnWindowFocus: false
    }
  );

  const { data: stages = [] } = useQuery(
    ['loan-stages'],
    () => getStageforModules('loan'),
    {
      enabled: Boolean(data?.status_value),
      select: (dataItem) => {
        return {
          'current_stage': data?.status_value,
          'next_stage': dataItem[dataItem.indexOf(data?.status_value) + 1]
        }
      },
      refetchOnWindowFocus: false
    }
  );

  const { data: reviewTrail = [] } = useQuery(
    ['remarks', createdLoanId],
    () => getAllRemarks(createdLoanId),
    { refetchOnWindowFocus: false }
  );

  const { data: repaymentType = [] } = useQuery(
    ['repayment-tenures'],
    () => getRepaymentType(),
    { refetchOnWindowFocus: false }
  );

  repaymentType?.map(
    (item) =>
      item?.id == data?.repayment_tenure_id &&
      repayment != item &&
      setRepayment(item)
  );

  /** used to get the revolving loan details */
  useEffect(() => {
    if (data?.status >= '4' && prospectcodebody && data?.deduction_loan_payload?.selection) {
      getDeductionLoansbyID(createdLoanId, prospectcodebody)
        .then((res) => {
          setProspectCode(res)
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' })
        })
    }
    else if (data?.loan_type == 'revolving' && data?.status < '4' && data?.customer_application_id && data?.deduction_loan_payload?.selection) {
      getLoanByDealershipId(data?.customer_application_id, { loan_type: 'revolving' }, createdLoanId)
        .then((res) => {
          setProspectCode(res?.filter((item, index) => data?.deduction_loan_ids?.includes(item?.id)))
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' })
        })
    }
  }, [data?.customer_application_id, prospectcodebody])

  const onSendforNextStageLoan = (remarks, status) => {

    const body = {
      remarks,
    };
    setRemarksLoading(true)
    sendLoanforNextStage(createdLoanId, body)
      .then((res) => {
        setSucessfulmodal({
          open: true, title:
            status == IN_REVIEW ? STAGE_CHANGE_TEXT?.approval
              : status == IN_APPROVAL ? STAGE_CHANGE_TEXT?.approved
                : status == CANCELLATION_REVIEW ? STAGE_CHANGE_TEXT?.reviewCancel
                  : STAGE_CHANGE_TEXT?.approveCancel
        });
      })
      .catch((e) => {
        displayNotification({ message: e.message || e, variant: 'error' })
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  }

  const handlePushback = (remarks) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true)
    sendForPushback(createdLoanId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.pushback });
      })
      .catch((e) => {
        displayNotification({ message: e.message || e, variant: 'error' })
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  }

  const handleReject = (remarks) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true)
    sendForReject(createdLoanId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.reject });
      })
      .catch((e) => {
        displayNotification({ message: e.message || e, variant: 'error' })
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  }

  const handleCancellation = (remarks, reason) => {
    const body = {
      remarks,
      reason: reason?.reason,
    };
    setRemarksLoading(true)
    sendLoansForCancellation(createdLoanId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.cancel });
      })
      .catch((e) => {
        displayNotification({ message: e.message || e, variant: 'error' })
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false)
      })
  }

  const callFunction = (remarks, status, reason) => {
    switch (status) {
      case 'review':
      case 'approval':
      case 'cancellation':
        onSendforNextStageLoan(remarks, status);
        break;
      case 'reject':
        handleReject(remarks);
        break;
      case 'pushback':
        handlePushback(remarks);
        break;
      case 'cancel':
        handleCancellation(remarks, reason);
        break;
      default:
        displayNotification({
          message: 'Something went wrong, Please contact support!',
          variant: 'error',
        });
        break;
    }
  };

  /** used to handle the no of days between disb date and due date */
  const handleNoOfDays = () => {
    var due_date = dayjs(data?.due_date)
    var no_of_days;
    no_of_days = due_date.diff(data?.disbursed_date, 'day')
    return no_of_days;
  }

  return (
    <>
      <Body background={false}>
        <PageTitle
          title={data?.customer_name}
          id={data?.customer_id}
          action={
            data?.crr_id ?
              /** display the credit reload request */
              <CreditReloadDetails
                selectedRowId={data?.crr_id}
                type={data?.additional_deduction?.find(i => i?.fee_type === 'express_reload_fee') ? 'express' : null}
              />
              : null
          }
        />
      </Body>
      <DashBoardCustomerDetails id={loanId} />
      <Box sx={{ position: 'relative' }}>
        <Body>
          {data?.prospect_code && (
            <div style={ImdStyle.flag}>
              <IdBadge
                remarks={data?.status_value}
                label={data?.prospect_code}
              />
            </div>
          )}
          <Grid gutter='md'>
            <Grid.Col
              span={5}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                src={GENERAL_IMG}
                alt='Illustration'
              />
            </Grid.Col>
            <Grid.Col span={7}>
              <DataViewerWapper p={30}>
                <Grid gutter='md'>
                  <Grid.Col span={12}>
                    <ViewFormData label='Loan Type' value={data?.loan_type == 'new' ? 'New' : data?.loan_type == 'enhancement' ? 'Enhancement' : data?.loan_type == 'transfer' ? 'Transfer' : 'Revolving'} loading={loading} />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData label='Product' value={data?.product_name} loading={loading} />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData label='Tranche Amount' value={<Nums withTooltip withCopy value={data?.loan_amount} />} loading={loading} />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label='Re-Payment Type'
                      value={repayment?.label}
                      loading={loading}
                    />
                  </Grid.Col>
                  {data?.loan_type == 'new' &&
                    <>
                      <Grid.Col span={12}>
                        <TitleViewer title={'Cash Flow (OTP Loan)'} />
                      </Grid.Col>
                    </>
                  }
                  {
                    data?.loan_type == 'revolving' &&
                    <>
                      <Grid.Col span={12}>
                        <TitleViewer title={'Revolving'} />
                      </Grid.Col>
                    </>
                  }
                  <Grid.Col span={12}>
                    <ViewFormData label='Disb Date' value={dateFormat(data?.disbursed_date)} loading={loading} />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData label='Due Date' value={dateFormat(data?.due_date)} loading={loading} />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData label='No.of Days' value={handleNoOfDays()} loading={loading} />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData label='Principal' value={<Nums withCopy value={data?.loan_amount} />} loading={loading} />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData label={`Interest`} secondaryLabel={data?.interest_rate} value={<Nums value={data?.interest_amount} />} loading={loading} />
                  </Grid.Col>
                  {data?.spread_rate ?
                    <Grid.Col span={12}>
                      <ViewFormData label={`Spread`} secondaryLabel={data?.spread_rate} value={<Nums value={data?.spread_amount} />} loading={loading} />
                    </Grid.Col> : null
                  }
                  {data?.addon_spread_rate ?
                    <Grid.Col span={12}>
                      <ViewFormData label={`Addon Spread`} secondaryLabel={data?.addon_spread_rate} value={<Nums value={data?.addon_spread_amount} />} loading={loading} />
                    </Grid.Col> : null
                  }
                  <Grid.Col span={12}>
                    <ViewFormData label='Due Amount' value={<Nums withCopy value={data?.due_amount} />} loading={loading} />
                  </Grid.Col>
                  {data?.advance_interest ?
                    <Grid.Col span={12}>
                      <ViewFormData label='Advance Interest' value={<Nums withCopy value={data?.advance_interest} />} loading={loading} />
                    </Grid.Col> : null
                  }
                  {data?.renewal_fee?.length ?
                    <Grid.Col span={12}>
                      <ViewFormData label='Renewal Fee' value={<Nums withCopy value={data?.renewal_fee[0]?.total_amount} />} loading={loading} action={<ViewFeeDetailsWithGST calculatedFee={data?.renewal_fee[0]} />} />
                    </Grid.Col> : null
                  }
                  {data?.additional_deduction?.length ?
                    <>
                      <Grid.Col span={12}>
                        <TitleViewer title={'Deduction'} />
                      </Grid.Col>
                      {
                        data?.additional_deduction?.map((item) => (
                          <Grid.Col span={12}>
                            <ViewFormData label={item?.fee_name} value={<Nums withCopy value={item?.total_amount} />} loading={loading} action={item?.fee_type == 'fee' ? <ViewFeeDetailsWithGST calculatedFee={item} /> : item?.fee_type === 'express_reload_fee' ? <ReloadFeePopover value={item} /> : null} />
                          </Grid.Col>
                        ))
                      }
                    </> : null
                  }
                  <Grid.Col span={12} mt='sm'>
                    <ViewFormDataContained label='Net Disbursement Amount' value={<Nums withCopy value={data?.disbursed_amount} />} loading={loading} />
                  </Grid.Col>
                </Grid>
              </DataViewerWapper>
            </Grid.Col>
            {
              /** used to displey the table if it is available */
              data?.deduction_loan_payload?.selection?.length && ProspectCode?.length ?
                <Grid.Col span={12}>
                  <DataViewerWapper>
                    <LoanRenewalTable renewalLoans={ProspectCode} loanDetails={data} />
                  </DataViewerWapper>
                </Grid.Col> : null
            }
          </Grid>
          {/** This Action buttons component is used to display the buttons in the flow */}
          <ActionButtons
            status={stages?.current_stage}
            isReady={Boolean(stages?.current_stage)}
            nextStage={stages?.next_stage}
            remarks={reviewTrail}
            accountingCB={false}
            currentStatus={data?.status_value}
            moduleApplicationId={data?.id}
            moduleName={'loan'}
            sendForApprovalCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('review'); setLabel('Send for Approval') }}
            approvedCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('approval'); setLabel('Approve') }}
            pushbackCB={() => { setOpen(true); setRemarksTitle("Pushback Remarks"); setStatus('pushback'); setLabel('Pushback') }}
            rejectCB={() => { setOpen(true); setRemarksTitle("Reject Remarks"); setStatus('reject'); setLabel('Reject') }}
            cancelCB={() => { setOpen(true); setRemarksTitle("Cancellation Remarks"); setStatus('cancellation'); setLabel('Send for Cancellation') }}
            approveCancelCB={() => { setOpen(true); setRemarksTitle("Are you sure?"); setStatus('cancel'); setLabel('Confirm') }}
            remarksCB={() => setRemarksOpen(true)} />

          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={data?.code}
            title={sucessfulmodal?.title}
            href={home}
            hreftext={'Back to list table'}
          />

          <Remarks
            open={open}
            setOpen={setOpen}
            type={stages?.next_stage}
            moduleName={'Loan'}
            callback={callFunction}
            remarksTitle={remarksTitle}
            check={status}
            label={label}
            loading={remarksLoading}
            currentStatus={data?.status_value}
            moduleApplicationId={data?.id}
          />
        </Body>

        <ViewRemark open={remarksOpen} onClose={setRemarksOpen} remarkTrail={reviewTrail} />
      </Box>
    </>
  );
};

export default LoanReview;
