import React, { useRef } from 'react'
import { Paper, Box, TextInput, Button, Grid, createStyles, ScrollArea } from '@mantine/core';
import { Table } from '@mantine/core';
import { useState, useEffect } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import { rankItem } from '@tanstack/match-sorter-utils';
import { flexRender, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { Filter, Search } from 'tabler-icons-react';
import { Text } from '@mantine/core';
import { useVirtual } from '@tanstack/react-virtual';

const useStyles = createStyles({
  tableHeader: {
    backgroundColor: '#e4edfd',
    position: "sticky",
    top: 0,    
  },
})

const SimpleReactTable = ({
  rowData = [],
  columnData,
  onRowClick = () => { },
  allowSorting = false,
  styles = {},
  setFilter,
  actions,
  showFilter = false,
  extraButton,
}) => {
  const { classes } = useStyles();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [debounce] = useDebouncedValue(search, 400);
  const [sorting, setSorting] = useState([]);

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank,
    });
    return itemRank.passed;
  };

  useEffect(() => {
    setData([...rowData]);
  }, [rowData]);

  const table = useReactTable({
    data,
    columns: columnData,
    state: {
      globalFilter: debounce,
      sorting,
      showPagination: false,
    },
    onGlobalFilterChange: setSearch,
    onSortingChange: setSorting,
    globalFilterFn: fuzzyFilter,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const tableContainerRef = useRef()

  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 20,
  })

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer

  const paddingTop = () =>  virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
  const paddingBottom = () =>
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0

  return (
    <Paper mt={10}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 1,
          alignItems: 'center'
        }}
      >
        <TextInput
          placeholder="Search"
          onChange={(e) => setSearch(e.target.value)}
          icon={<Search size={16} />}
        />
        {showFilter &&
          <Button
            variant="filled"
            color="dark"
            size='sm'
            sx={{ marginLeft: 12 }}
            onClick={() => setFilter(true)}
            leftIcon={<Filter size={18} />}
          >
            Back to Filter
          </Button>
        }
        {extraButton &&
          extraButton
        }
        {actions && actions}
      </Box>
      <Grid>
        <Grid.Col span={12} sx={{ display: 'flex', gap: '5px', padding: '15px 10px 10px 10px' }}>
          {
            data.map((item, index) => {
              return (item[1] ?
                <>
                  <Text size={'xs'} color={'gray'}>{item[0].toUpperCase()}:</Text>
                  <Text size={'xs'} pr={'5px'}>{item[1]}</Text>
                </> : null
              )
            })
          }
        </Grid.Col>
      </Grid>
      <Box>
        {/* <ScrollArea offsetScrollbars h={560}> */}
        <Box sx={{ maxHeight: 560, overflow: 'scroll' }} ref={tableContainerRef}>
          <Table
            highlightOnHover
            fontSize="xs"
            mb={10}
            verticalSpacing="xs"
            showPaginationBottom={false}
            sx={{ ...styles }}
            striped
          >
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className={classes.tableHeader}>
                  {headerGroup.headers.map((header, index) => (
                    <th key={header.id} style={{ cursor: 'pointer' }}>
                      {header.isPlaceholder ? null : (
                        <div onClick={allowSorting ? header.column.getToggleSortingHandler() : undefined}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
            {paddingTop() > 0 && (
              <tr>
                <td style={{ height: `${paddingTop()}px` }} />
              </tr>
            )}
              {virtualRows?.map(virtualRow => {
                const row = rows[virtualRow?.index]
                return (
                  <tr style={{cursor: 'pointer'}} key={row.id} onClick={() => onRowClick(row.original)}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
              {paddingBottom() > 0 && (
                <tr>
                  <td style={{ height: `${paddingBottom()}px` }} />
                </tr>
              )}
            </tbody>
          </Table>
        </Box>
        {/* </ScrollArea> */}
      </Box>
    </Paper>
  )
}

export default SimpleReactTable
