import { createColumnHelper } from "@tanstack/react-table";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Body from "../../components/Body/Body";
import DataTableWrapper from "../../components/CommonComponents/Table/DataTableWrapper";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import { useQuery } from "@tanstack/react-query";
import Nums from "../../components/Body/Nums";
import {
    APPROVED,
    AUTO_CANCELLED,
    CANCELLATION_APPROVAL,
    CANCELLATION_REVIEW,
    CANCELLED,
    IN_APPROVAL,
    REJECTED,
} from '../../constants/status';
import { getAllDisbursementBatch } from "../../services/disbBatch.service";
import { Popover, createStyles, Box, Button, Text } from '@mantine/core';
import { Calendar } from "tabler-icons-react"
import { DateRange } from 'react-date-range';
import useSystemConfigStore from '../../store/systemConfigStore';
import useModuleRecordCountStore from "../../store/moduleRecordCountStore";

const createStyle = createStyles({
  dateSelection: {
    background: "#FFFF",
    display: "flex",
    justifyContent: "center",
    width: "250px",
    padding: "8px",
    columnGap: "5px",
    borderRadius: "5px",
    marginLeft: "10px",
    cursor: "pointer",
    border: "1px solid #e1e1e1",
  },
});

const DisbBatchTable = () => {
  const { classes } = createStyle()
  const systemDate = useSystemConfigStore(store => store.config);
  const { moduleCount } = useModuleRecordCountStore();
  const navigate = useNavigate();
  const location = useLocation();
  const columnHelper = createColumnHelper();
  const [addDisb, setAddDisb] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [records, setRecords] = useState({ label: "show 10", value: 10 });
  const [selectedData, setSelectedData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [dateSearch, setDateSearch] = useState({
    to_date: dateFormat(
      new Date(systemDate?.current_system_date),
      "YYYY-MM-DD"
    ),
  });
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  // get the search params
  const [searchParams, setSearchParams] = useSearchParams();
  const params = {}
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  /** used to change the page to 1 while the search us used
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1);
  }, [search, searchParams.get("status"), records]);

  /** used to set the default date as system date when the system date context is loaded */
  useEffect(() => {
    if (systemDate?.current_system_date) {
      setState([
        {
          startDate: new Date(systemDate?.current_system_date),
          endDate: new Date(systemDate?.current_system_date),
          key: "selection",
        },
      ]);
    }
  }, [systemDate?.current_system_date]);

  /** used to handle the date */
  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setState([selection]);
  };

  /** used to handle the date filter */
  const handleFilter = () => {
    setModalOpen(false);
    setDateSearch({
      from_date: state[0]?.startDate === systemDate?.current_system_date ? null : dateFormat(state[0]?.startDate, "YYYY-MM-DD"),
      to_date: dateFormat(state[0]?.endDate, "YYYY-MM-DD"),
    });
  };

  const { data: loanData = [], isLoading } = useQuery(
    ["all-disb-Batch", page, search, records, params, dateSearch],
    () => getAllDisbursementBatch({...params, page, search, records: records?.value, ...dateSearch}),
    {
      enabled: Boolean(searchParams.get("status") && searchParams.get("status") !== "none")
    }
  );

  const onRowClick = (i) => {
    if (i?.status_value == IN_APPROVAL || i?.status_value == APPROVED) {
      navigate(`/batch-disb/${i?.id}`, { state: { from: location.pathname } });
    } else if (i?.status_value == AUTO_CANCELLED) {
      navigate(`/batch-disb/cancelled/${i?.id}/${i?.customer_id}`, { state: { from: location.pathname } });
    } else if (i?.status_value === REJECTED) {
      navigate(`/batch-disb/rejected/${i?.id}`, { state: { from: location.pathname } });
    } else if(i?.status_value == CANCELLED) {
      navigate(`/cancellation/batch-disb-cancelled/${i?.id}`, { state: { from: location.pathname } });
    } else if(i?.status_value == CANCELLATION_REVIEW || i?.status_value == CANCELLATION_APPROVAL) {
      navigate(`/cancellation/batch-disb-cancel/${i?.id}`, { state: { from: location.pathname } });      
    }
  };

  const onClickAction = (value) => {
    return (
      <span
        style={{ color: "#0063FF", cursor: "pointer" }}
      >
        {value.getValue()}
      </span>
    );
  };

  const columnData = [
    columnHelper.accessor("batch_code", {
      header: "Batch Code",
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor("total_tranche_amount", {
      header: <Text sx={{textAlign: 'right'}}>Tranche Amount</Text>,
      cell: (value) => <Nums value={value.getValue()} styles={{textAlign: 'right'}} />,
    }),
    columnHelper.accessor("total_net_disb_amount", {
      header: "Disbursed Amount",
      cell: (value) => <Nums value={value.getValue()} />,
    }),
    columnHelper.accessor("disbursement_count", {
      header: "MEMO Count",
    }),
    columnHelper.accessor("created_on", {
      header: "Created Date",
      cell: (value) => dateFormat(value.getValue()),
    }),
    columnHelper.accessor('maker_name', {
      header: 'Created By',
    }),
  ];

  const tableAction = (
    <Popover
      position="bottom"
      shadow="md"
      opened={modalOpen}
      onChange={setModalOpen}
    >
      <Popover.Target>
        <Text
          onClick={() => setModalOpen(true)}
          size={"12px"}
          className={classes?.dateSelection}
        >
          <Calendar strokeWidth={1.5} color={"gray"} size={"20"} />{" "}
          <strong>From:</strong> {dateFormat(state[0]?.startDate)}{" "}
          <strong style={{ marginLeft: "6px" }}>To:</strong>{" "}
          {dateFormat(state[0]?.endDate)}
        </Text>
      </Popover.Target>
      <Popover.Dropdown>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <DateRange
            onChange={handleOnChange}            
            months={1}
            maxDate={new Date(systemDate?.current_system_date ? systemDate?.current_system_date : new Date())}
            ranges={state}
            retainEndDateOnFirstSelection
            direction="horizontal"
          />
          <Button onClick={handleFilter}>Apply</Button>
        </Box>
      </Popover.Dropdown>
    </Popover>
  )

  return (
    <Body background={false}>
      <DataTableWrapper
        title='Disbursement Batch'
        filter={false}
        rowData={loanData?.data}
        columnData={columnData}
        onRowClick={onRowClick}
        searchOption={false}
        noDataText="No disbursement Batch details yet!"
        noDataSubText='This section will contain list of created disbursement Batch details.'
        useApiPagination
        addDataOption={false}
        totalNoOfPages= {Math.ceil(parseInt(moduleCount?.count)/records?.value)}
        totalNoOfRecords={moduleCount?.count}
        page={page}
        setPage={setPage}
        rowLength={records}
        setRowLength={setRecords}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        showStatus
        apiSearch={setSearch}
        // when the status is none then the loading is set to false other wise it will dependent on isFetching
        loading={searchParams.get("status") !== "none" ? isLoading : searchParams.get("status") === null ? true : false}
        module_name="disbursement_batch"
        addDataCallback={() => setAddDisb(true)}
        actions={tableAction}
      />
    </Body>
  );
};

export default DisbBatchTable;
