import { getAPICall, patchAPICall, postAPICall } from '.';


export const getAllManualInvoice = ({page, search, records = 10, status}) => {
  /** used to get manual invoice */
  let url = `/manual-invoice?page=${page}&records=${records}&status=${status}`
  if(search) url = url + `&customer_id_name=${search}`;
  return getAPICall(url, { returnObject: true });
};

export const getAllAutoInvoice = ({page, search, records = 10, status}) => {
  /** used to get auto invoice */
  return getAPICall(`/auto-invoice?page=${page}&customer_id_name=${search}&records=${records}&status=${status}`, { returnObject: true });
};

export const getAutoInvoiceData = (id) => {
  /** used to get auto invoice data */
  return getAPICall(`/auto-invoice/${id}`, { returnFirstItem: true });
};

export const getByInvoiceID = (id) => {
  /** used to get invoice data by id */
  return getAPICall(`/manual-invoice/${id}`, { returnFirstItem: true });
};

export const getInvoiceRemarksById = (id) => {
  /** used to get remarks by id */
  return getAPICall(`/manual-invoice/${id}/remark`);
};

export const createManualInvoice = (body) => {
  /** used to create the invoice */
  return postAPICall("/manual-invoice", { body: body });
};

export const updateManualInvoice = (id, body) => {
  /** used to update the invoice */
  return patchAPICall(`/manual-invoice/${id}`, { body: body });
};

export const saveInvoiceAndsendForNextStage = (body) => {
  /** used to send the invoice to the next stage */
  return postAPICall(`/manual-invoice/direct_save`, { body: body });
};

export const sendInvoiceforNextStage = (id, body) => {
  /** used to send the invoice to next stage by id */
  return postAPICall(`/manual-invoice/${id}/status/change`, { body: body });
};

export const sendInvoiceReject = (id, body) => {
  /** used to reject the invoice */
  return postAPICall(`/manual-invoice/${id}/rejected`, { body: body });
};

export const sendInvoicePushback = (id, body) => {
  /** used to pushback the invoice */
  return postAPICall(`/manual-invoice/${id}/pushback`, { body: body });
};

export const invoiceGSTCalculation = (body) => {
  /** used to calculate the invoice */
  return postAPICall(`manual-invoice/gst-calculation`, {
    body: body,
    returnFirstItem: true,
  });
};

export const getInvoiceFee = () => {
  const handleValue = (data) => {
    const result = data?.map((item) => ({
      label: item?.name,
      value: item?.name,
      ...item,
    }));
    return result || [];
  };

  /** used to get the invoice fee */
  return getAPICall(`/manual-invoice/provide-data/charges-and-fees`, {
    selectValue: handleValue,
  });
};

export const customerSearchInvoice = (param) => {
  const handleValue = (data) => {
    const result = data?.map((item) => ({
      label: `${item.name} (${item.id})`,
      value: item.id,
      ...item,
    }));
    return result || [];
  };

  /** used to search the customer */
  return getAPICall(`/customer/search/?customer_id_name=${param}`, {
    selectValue: handleValue,
  });
};

export const generateManualInvoicePDF = (id) => {
  /** used to generate the pdf */
  return getAPICall(`/manual-invoice/generate-pdf/${id}`);
};

export const generateAutoInvoicePDF = (id) => {
  /** used to generate the pdf */
  return getAPICall(`/auto-invoice/generate-pdf/${id}`);
};

export const sendInvoiceforCancellation = (id, body) => {
  /** used to cancel the invoice */
  return postAPICall(`/manual-invoice/${id}/cancellation`, { body: body });
};