import { getAPICall } from ".";

export const getAllUserDetails = () => {
  return getAPICall(`users`);
};

export const getRoles = () => {
  const handleValue = (data) => {
    const result = data?.map((item) => ({
      label: item.name,
      value: item.id,
      ...item,
    }));
    return result;
  };
  return getAPICall(`rbac/groups`, { selectValue: handleValue });
};

export const getUserProfile = () => {
  return getAPICall(`/userprofile`);
};

export const getUserDetailsById = (id) => {
  return getAPICall(`/user/${id}`);
};
