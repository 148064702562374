import { useQuery } from "@tanstack/react-query";
import { getAllEntities } from "../../../services/dashboard.service";
import Select from 'react-select';
import { Box } from "@mantine/core";
import { useSearchParams } from "react-router-dom";

const styles = {
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  option: (provided) => ({ ...provided, fontSize: '12px', padding: '4px 10px 4px 10px' }),
  control: (provided) => ({ ...provided, fontSize: '12px', border: '1px solid #ddd' })
}

const defaultValue = [
  { label: "All Tranches", value: 'all' },
  { label: 'Regular', value: 'regular' },
  { label: 'Express', value: 'express' }
];

const LoanTypeFilterDropdown = ({ value, onChange }) => {

  return (
    <Box style={{ width: 140 }}>
      <Select
        defaultValue={{ label: "All Tranches", value: 'all' }}
        value={defaultValue.find(v => v.value == value)}
        options={defaultValue}
        styles={styles}
        onChange={d => onChange(d)}
      />
    </Box>
  )
}

const LoanTypeFilter = ({ value = 'all', }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <LoanTypeFilterDropdown
      value={searchParams?.get("reload_type") || value}
      onChange={(d) => {
        searchParams?.set("reload_type", d?.value); setSearchParams(searchParams)
      }}
    />
  )

}

export default LoanTypeFilter;