import styled from '@emotion/styled';
import {
  Box,
  Collapse,
  createStyles,
  Skeleton,
  Text,
  Tooltip,
} from '@mantine/core';
import React from 'react';
import { ArrowUpCircle, ArrowDownCircle } from 'tabler-icons-react';
import { COLORS } from '../../constants';
import { useDisclosure } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import { getCustomerDetailsById } from '../../services/master.service';
import Nums from '../Body/Nums';
import { ActionIcon } from '@mantine/core';
import { getApprovedLoansDetailsById } from '../../services/disbursement.service';
import { dateFormat } from '../DateFormat/dateFormat';

const DashCardWapper = styled.div`
  .linktext {
    border-right: 2px solid #ebeef3;
    width: 14%;
    position: relative;
    padding: 0 15px 0 15px;
    color: ${COLORS.text.light1};
    :last-child {
      border: none;
    }
    :nth-child(7) {
      border: none;
    }
    :nth-child(14) {
      border: none;
    }
  }
  .doubleWidth {
    width: 28%;
    position: relative;
    padding: 0 15px 0 15px;
    color: ${COLORS.text.light1};
  }
  .titletextcol {
    color: ${COLORS.text.light1};
  }
  .texttitle {
    color: #0063ff;
  }
  .morebutt {
    :hover {
      background-color: none;
    }
  }
  .notactive {
    display: flex;
    flex-direction: column;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
  }
  ,
  .mantine-u9ajgw {
    background-color: white;
    color: black;
    white-space: normal;
  },
  .mantine-u9ajgw {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
`;

const useStyles = createStyles({
  main: {
    padding: '10px 10px 10px 0px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    maxWidth: '1300px',
    margin: 'auto',
    cursor: 'context-menu',
  },
  displaynav: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0',
    flexWrap: 'wrap',
    position: 'relative',
  },
  morbutton: {
    position: 'absolute',
    right: '25px',
    top: '0px',
  },
  collapse: {
    display: 'flex',
  },
  collapsenav: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'auto',
    flexWrap: 'wrap',
  },
  titletext: {
    flexDirection: 'column',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '12px',
  },
  valuetext: {
    color: 'black',
    flexDirection: 'column',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

const DisplayNavDetails = ({ title, value, loading, type, span }) => {
  const { classes } = useStyles();

  return (
    <div className={span ? 'doubleWidth' : 'linktext'}>
      <div className='notactive'>
        {loading ? (
          <Skeleton visible={loading} height={12} width={'60%'} mb={'16px'}></Skeleton>
        ) : (
          <div className={classes.titletext}>{(title == 'Available Limit' || title == 'Approved Amount' || title == 'Used Limit') ? <Text color={'#91A7FF'}>{title}</Text> : title}</div>
        )}
        {loading ? (
          <Skeleton visible={loading} height={12}></Skeleton>
        ) : !value ? (
          '-'
        ) : (
          // If content width is greater then length 18 then it will show tooltip
          <Tooltip
            multiline
            shadow='lg'
            label={typeof value == 'string' && value?.replace(/,/g, ', ')}
            disabled={typeof value != 'string' || value?.length < 18}
            withArrow
            transitionProps={{ transition: 'scale-y', duration: 200 }}
            position='bottom'
            offset={10}
            color='gray'
          >
            <div className={classes.valuetext}>{(title == 'Available Limit' || title == 'Approved Amount' || title == 'Used Limit') ?
              <strong><Text color={'blue'}><Nums value={Math.max(0, value)} /></Text></strong> :
              (type == 'amount') ?
                <Nums value={Math.max(0, value)} /> :
                value}
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

const DashBoardCustomerDetails = ({ id, loansId, codeName, code }) => {
  const { classes } = useStyles();
  const [opened, handlers] = useDisclosure(false);
  /* use-disclosure hook manages boolean state. It provides open, close and toggle */

  const { data: result = {}, isLoading } = useQuery(
    ['customer-details', id],
    () => getCustomerDetailsById(id),
    {
      enabled: Boolean(id),
      refetchOnWindowFocus: false,
    }
  );

  const { data: DisbData = [], isLoading: disbLoading } = useQuery(
    ['loan-details', loansId],
    () => loansId && getApprovedLoansDetailsById(loansId),
    { refetchOnWindowFocus: false }
  );

  const handelCollapse = () => {
    handlers.toggle();
  };

  return (
    <DashCardWapper>
      <Box className={classes.main} shadow='xs'>
        <div className={classes.displaynav}>
          {!isLoading ?
            <>
              <DisplayNavDetails title={'Name'} value={result?.customer?.name} loading={isLoading} />
              <DisplayNavDetails title={'Code'} value={result?.customer?.customer_code} loading={isLoading} />
              <DisplayNavDetails title={'Pincode'} value={result?.customer?.pincode} loading={isLoading} />
              <DisplayNavDetails title={'State'} value={result?.customer?.state} loading={isLoading} />
              <DisplayNavDetails title={'Zone'} value={result?.customer?.zone} loading={isLoading} />
              <DisplayNavDetails title={'Product Name'} value={result?.customer?.product_name} loading={isLoading} span={2} />
            </>
            : Array(7).fill().map((item, i) => <DisplayNavDetails loading={true} key={i} />)
          }
          {!isLoading ?
            <div className={classes.morbutton}>
              <ActionIcon
                variant='transparent'
                onClick={handelCollapse}
                sx={{ position: 'absolute', top: '20px', left: '97%' }}
              >
                {opened ? (
                  <ArrowUpCircle color={'#9e9e9e'} />
                ) : (
                  <ArrowDownCircle color={'#9e9e9e'} />
                )}
              </ActionIcon>
            </div>
            : null
          }
        </div>

        <Collapse in={opened} transitionDuration={600}>
          <div style={{ display: 'flex', paddingTop: '10px', flexWrap: 'wrap', rowGap: '10px' }}>
            {!isLoading ?
              <>
                <DisplayNavDetails title={'Business Type'} value={result?.customer?.business_type} loading={isLoading} />
                <DisplayNavDetails title={'Property Ownership'} value={result?.customer?.property_ownership} loading={isLoading} />
                <DisplayNavDetails title={'Region'} value={result?.customer?.region} loading={isLoading} />
                <DisplayNavDetails title={'District'} value={result?.customer?.district} loading={isLoading} />
                <DisplayNavDetails title={'Approved Amount'} value={result?.customer?.approved_amount} loading={isLoading} />
                <DisplayNavDetails title={'Available Limit'} value={result?.customer?.available_limit} loading={isLoading} />
                <DisplayNavDetails title={'Used Limit'} value={result?.customer?.used_limit} loading={isLoading} />
                <DisplayNavDetails title={'OMC Name'} value={result?.omc_details?.omc_name} loading={isLoading} />
              </>
              : null
            }
            {(!disbLoading && loansId) ?
              <>
                {code ? <DisplayNavDetails title={codeName} value={code} loading={isLoading} /> : null}
                <DisplayNavDetails title={'Tranche Amount'} value={DisbData?.loan_amount} loading={isLoading} type='amount' />
                <DisplayNavDetails title={'Disbursed Amount'} value={DisbData?.disbursed_amount} loading={isLoading} type='amount' />
                <DisplayNavDetails title={'Interest Amount'} value={DisbData?.interest_amount} loading={isLoading} type='amount' />
                <DisplayNavDetails title={'Disbursed Date'} value={dateFormat(DisbData?.disbursed_date)} loading={isLoading} />
                <DisplayNavDetails title={'Due Date'} value={dateFormat(DisbData?.due_date)} loading={isLoading} />
              </> : null
            }
          </div>
        </Collapse>
      </Box>
    </DashCardWapper>
  );
};

export default DashBoardCustomerDetails;
