import {
    Box,
    Grid,
    Textarea,
    Text,
  } from '@mantine/core';
  import React, { useEffect, useState } from 'react';
  import { useForm } from 'react-hook-form';
  import { useParams } from 'react-router';
  import ControllerDatePicker from '../../components/CommonComponents/TextField/ControllerDatePicker';
  import ControllerSelect from '../../components/CommonComponents/TextField/ControllerSelect';
  import ImdStyle from '../IMD/Imd.styles';
  import * as yup from 'yup';
  import { yupResolver } from '@hookform/resolvers/yup';
import ModalDataViwer from '../../components/SucessfullModal/ModalDataViwer';
import DashBoardCustomerDetails from '../../components/DashboardCard/dashboard';
import Body from '../../components/Body/Body';
import PageTitle from '../../components/PageTitle/PageTitle';
import DataViewerWapper from '../../components/CommonComponents/Preview/DataViewerWapper';
import {
    ViewFormDataContained,
    ViewFormData,
    ViewData
} from '../../components/CommonComponents/Preview/FilePreview';
import ControllerCurrencyInput
    from '../../components/CommonComponents/TextField/ControllerCurrencyInput';
import {
    createManualInvoice,
    updateManualInvoice,
    getInvoiceFee,
    invoiceGSTCalculation,
    getByInvoiceID,
    getInvoiceRemarksById,
    saveInvoiceAndsendForNextStage,
    sendInvoiceforNextStage
} from '../../services/invoice.service';
import ActionButtons from '../../components/CommonComponents/ActionButtons/ActionButtons';
import { currencyAsInt } from '../../components/Currency/currencyFormat';
import { useQuery } from '@tanstack/react-query';
import Nums from '../../components/Body/Nums';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import Remarks from '../../components/CommonComponents/Remarks/Remarks';
import { customerSearch } from '../../services/imd.service';
import IdBadge from '../../components/CommonComponents/Preview/IdBadge';
import COLORS from '../../constants/colors';
import dayjs from 'dayjs';
import {
    ControllerAutoComplete
} from '../../components/CommonComponents/TextField/ControllerAutoComplete';
import { STAGE_CHANGE_TEXT } from '../../constants';
import { getStageforModules } from '../../services/module.service';
import useSystemConfigStore from '../../store/systemConfigStore';
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification';
import TitleViewer from '../../components/CommonComponents/Title/TitleViewer';
import { getBusinessBanks } from '../../services/banks.service';
  
  const schema = yup.object({
    charge_date: yup.string().required('Please Select a Date'),
    charge_type: yup.string().required('Please Select a Type'),
    charge_amount: yup.string().required('Please Enter Charge Amount'),
    charge_name: yup.string().required('Please Select Charge name'),
    customer_id: yup.string().required('Please select the customer name & id'),
    business_bank_id: yup.string().required('Please select bank'),
  });
  const GSTSchema = yup.object({
    charge_date: yup.string().required('Please Select a Date'),
    charge_amount: yup.string().required('Please Enter Charge Amount'),
    customer_id: yup.string().required('Please select the customer name & id'),
  });
  
  const InvoiceCreation = ({ formType }) => {
    const { createdID, dealerID } = useParams();
    const systemDate = useSystemConfigStore(store => store.config);
    const [gstData, setGSTData] = useState({});
    const [sucessfulmodal, setSucessfulmodal] = useState(false);
    const [val, setVal] = useState('')
    const [custName, setCustName] = useState();
    const [buttonLoader, setButtonLoader] = useState({});
    const [remarksOpen, setRemarksOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState(false);
    const [label, setLabel] = useState(false);
    const [remarksLoading, setRemarksLoading] = useState();
    const [notes, setNotes] = useState();

    const {data: fees = [] } = useQuery(
      ['fees'],
      () => getInvoiceFee(),
      { refetchOnWindowFocus: false }
    )

    const { data: invoiceData = {} } = useQuery(
      ['invoice-id', createdID],
      () => createdID && getByInvoiceID(createdID),
      { 
        onSuccess: (data) => {
          setVal(data?.customer_id);
          setCustName({customer_name: data?.customer_name, id: data?.customer_id, product_id: data?.product_id}); 
          setNotes(data?.description);
        },
        refetchOnWindowFocus: false 
      }
    )

    const { data: stages = [] } = useQuery(
      ['invoice-stage'],
      () => getStageforModules('invoice'),
      {
        select: (data) => {
          return {
            'current_stage': 'draft',
            'next_stage': data[1]
          }
        },
        refetchOnWindowFocus: false
      } 
    );

    const { data: reviewTrail = [] } = useQuery(
      ['review-trail', createdID],
      () => createdID && getInvoiceRemarksById(createdID),
      { refetchOnWindowFocus: false }
    );
  
    const {
      handleSubmit,
      control,
      setValue,
      setError,
      reset,
      formState: { errors },
      watch,
    } = useForm({
      mode: 'all',
      resolver: yupResolver(schema),
      defaultValues:
      {
        gst_applicable: 'yes',
        charge_type: 'receivable',
        charge_date: dayjs(new Date()).format(),
        ...invoiceData,
      },
    });
    const values = watch();

    /** used to handle the customer search */
    const searchQuery = useQuery(
      ["search-data", values?.customer_id],
      /*
       * sending the signal to cancel the per pending api call
       * signal will contain the abort signal
       */
      ({ signal }) => customerSearch(values?.customer_id, 'invoice', signal),
      {
        /** this api call will call when the searched value length is min of 2 */
        enabled: Boolean(values?.customer_id?.length > 2),
        refetchOnWindowFocus: false,
      }
    );

    const { data: targetBank = [], isLoading: tagretBankLoading } = useQuery(
      ['comapy-bank', custName?.product_id],
      () => getBusinessBanks({ product_id: custName?.product_id }),
      { 
        enabled: Boolean(custName?.product_id),
        refetchOnWindowFocus: false 
      }
    );

    useEffect(() => {
      if (Object.keys(invoiceData).length) {
        reset(invoiceData);
      }
    }, [invoiceData]);  

    useEffect(() => {
      if (Object.keys(systemDate).length) {
        setValue('charge_date', systemDate?.current_system_date);
      }
    }, [systemDate]);    

    /** used to call the gst calculation api to calculate gst */
    useEffect(() => {
      /** used to null the gst calculation data when there is no charge amount */
      if(!values?.charge_amount) {
        setGSTData()
      }
      if(custName?.id && values?.charge_amount && values?.charge_name){
        /** this validate will validate the required field and send the api call */
        GSTSchema.validate(values)
        .then(() => {
          const body = {
            customer_id : custName?.id,
            charge_amount: currencyAsInt(values?.charge_amount),
            charge_name: values?.charge_name
          }
          invoiceGSTCalculation(body)
            .then((res) => {
              setGSTData(res)
            })
            .catch((e) => {      
              /** used to null the data if there is error */        
              setGSTData()
              /** used to handle the request validation error from api */
              if (e?.RequestValidationError) {
                Object.keys(e?.RequestValidationError).forEach((item, index) => {
                  setError(item, {message: e?.RequestValidationError[item]});
                });
              } else {
                /** used to display the error msg */
                displayNotification({
                  id: 'imd-review-save',
                  message: e?.message || e,
                  variant: 'error',
                });
              }
            }) 
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' })
        })
      }
    }, [values?.charge_amount, custName?.id, values?.charge_name])

    /** This function is used to check the all valid fields are present are not */
    const handleReviewModal = () => {
      schema.validate(values)
      .then(res => {
        setOpen(true);
        setStatus('review');
        setLabel(stages?.next_stage == 'approval' ? 'Send for Approval' : 'Send for Review');
      })
      .catch(err => {
        displayNotification({message: err?.errors, variant: 'error'})
      })
    }

    /** handling the submit stage */
    const onSubmit = (values) => {
      setButtonLoader({ save: true });
      const body = {
        customer_name: custName?.name, 
        customer_id : parseInt(custName?.id),
        charge_type: values?.charge_type,
        charge_name: values?.charge_name,
        charge_date: dateFormat(values?.charge_date, 'YYYY-MM-DD'),
        charge_amount: currencyAsInt(values?.charge_amount),
        gst_applicable: values?.gst_applicable,
        business_bank_id: values?.business_bank_id,
        description: notes, 
      };

      if(!createdID){
        createManualInvoice(body)
          .then((res) => {
            setSucessfulmodal({open: true, title: STAGE_CHANGE_TEXT?.save});
          })
          .catch((e) => {            
            if (e?.RequestValidationError) {
              Object.keys(e?.RequestValidationError).forEach((item, index) => {
                setError(item, {message: e?.RequestValidationError[item]});
              });
            } else {
              displayNotification({ message: e?.message || e, variant: 'error'})
            }
          })
          .finally(() => {
            setButtonLoader({ save: false})
          })
      } else {
        updateManualInvoice(createdID, body)
          .then((res) => {
            setSucessfulmodal({open: true, title: STAGE_CHANGE_TEXT?.update});
          })
          .catch((e) => {
            if (e?.RequestValidationError) {
              Object.keys(e?.RequestValidationError).forEach((item, index) => {
                setError(item, {message: e?.RequestValidationError[item]});
              });
            } else {
              displayNotification({ message: e?.message || e, variant: 'error'})
            }
          })      
          .finally(() => {
            setRemarksLoading(false)
            setButtonLoader({ save: false})
          })    
      }

    };

    /** Handling the review modal */
    const onReview = (remarks) => {

      setRemarksLoading(true)
      const body = {
        remark_type: 'maker',
        remarks: remarks,
        customer_name: custName?.name || values?.customer_name, 
        customer_id : parseInt(custName?.id) || values?.customer_id,
        charge_type: values?.charge_type,
        charge_name: values?.charge_name,
        charge_date: dateFormat(values?.charge_date, 'YYYY-MM-DD'),
        charge_amount: currencyAsInt(values?.charge_amount),
        gst_applicable: values?.gst_applicable,
        business_bank_id: values?.business_bank_id,
        description: notes, 
      };

      if(!createdID) {
        saveInvoiceAndsendForNextStage(body)
          .then((res) => {
            setSucessfulmodal({open: true, title: stages?.next_stage == 'approval' ? STAGE_CHANGE_TEXT?.approval : STAGE_CHANGE_TEXT?.review});
          })
          .catch((e) => {
            if (e?.RequestValidationError) {
              Object.keys(e?.RequestValidationError).forEach((item, index) => {
                setError(item, {message: e?.RequestValidationError[item]});
              });
            } else {
              displayNotification({ message: e?.message || e, variant: 'error'})
            }
          })
          .finally(() => {
            setRemarksLoading(false)
            setOpen(false)
          })
      } else {
        updateManualInvoice(createdID, body)
          .then((res) => {
            sendInvoiceforNextStage(createdID, body)
              .then((res) => {
                setSucessfulmodal({open: true, title: stages?.next_stage == 'approval' ? STAGE_CHANGE_TEXT?.approval : STAGE_CHANGE_TEXT?.review});
              })
              .catch((e) => {
                if (e?.RequestValidationError) {
                  Object.keys(e?.RequestValidationError).forEach((item, index) => {
                    setError(item, {message: e?.RequestValidationError[item]});
                  });
                } else {
                  displayNotification({ message: e?.message || e, variant: 'error'})
                }
              })            
          })
          .catch((e) => {
            displayNotification({ message: e?.message || e, variant: 'error'})
          })
          .finally(() => {
            setRemarksLoading(false)
            setOpen(false)
          })
      }
    }

    return (
      <>
        <Body background={false}>
        {custName?.id || invoiceData?.customer_id ?
          <PageTitle
            title={custName?.name || invoiceData?.customer_name} id={custName?.id || invoiceData?.customer_id} 
          />
          :
          <PageTitle
            title={'Invoice Creation'}
          />
        }
        </Body>
        {custName?.id || invoiceData?.customer_id ?
          <DashBoardCustomerDetails id={custName?.id || invoiceData?.customer_id} /> : null
        }
          <Box sx={{ position: 'relative' }}>
            <Body>
              {invoiceData?.code && (
                <div style={ImdStyle.flag}>
                  <IdBadge
                    remarks={invoiceData?.status_value}
                    label={invoiceData?.code}
                  />
                </div>
              )}
              <Grid gutter='xl'>
                <Grid.Col span={4}>
                  <Grid.Col span={12} mt={'25px'} sx={{ minHeight: '57px', padding: '0 8px 0 8px' }}>
                    <ControllerAutoComplete
                      data={searchQuery?.data || []}
                      valueid={val}
                      name={'customer_id'}
                      setData={setCustName}
                      placeholder="Search by customer Id / Name"
                      label={'customer'}
                      filter={(value, item) => item.value.includes(value.trim()) || item.label.toLowerCase().includes(value.toLowerCase().trim())}
                      control={control}
                      errors={errors?.customer_id?.message}
                    />
                  </Grid.Col>                    
                  <Grid.Col span={12}>                      
                    <ViewFormData label={'Customer ID'} value={' '} />
                    <Text mt={'sm'} mb={'lg'} size='sm' weight={400} sx={{ color: COLORS.primary.dark }}><strong>{custName?.id }</strong></Text>
                    <ViewFormData label={'Customer Name'} value={' '} />
                    <Text mt={'sm'} size='sm' weight={400} sx={{ color: COLORS.primary.dark }}><strong>{custName?.name || values?.customer_name}</strong></Text>
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Grid gutter='lg'>          
                    <Grid.Col span={12} mt={'xl'} pt={'xs'}>
                      <ControllerDatePicker
                        label='Charge Date'
                        required
                        name='charge_date'
                        maxDate={new Date(systemDate?.current_system_date)}
                        minDate={new Date(new Date(systemDate?.current_system_date).setDate(new Date(systemDate?.current_system_date).getDate() - 3))}
                        control={control}
                        errors={errors?.charge_date?.message}
                      />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      {/* <ControllerSelect
                        label='Charge Type'
                        name='charge_type'
                        required
                        placeholder={'Select'}
                        control={control}
                        data={[
                          {
                              label: 'Receivable',
                              value: 'receivable'
                          },{
                              label: 'Payable',
                              value: 'payable'
                          }
                        ]}
                        errors={errors?.charge_type?.message}
                      /> */}
                      <ViewData title={'Charge Type'} value={'Receivable'} type={'text'} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <ControllerSelect
                        label='Charge Name'
                        name='charge_name'
                        required
                        control={control}
                        data={fees}
                        errors={errors?.charge_name?.message}
                      />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <ControllerCurrencyInput
                        label='Charge Amount'
                        name='charge_amount'
                        required
                        control={control}
                        errors={errors?.charge_amount?.message}
                        autoComplete='off'
                      />
                    </Grid.Col>
                  </Grid>
                </Grid.Col>        
                <Grid.Col span={4} mt={'md'}>
                  <DataViewerWapper>
                  <Grid gutter='xs'>

                    {!gstData ?
                      <Grid.Col span={12}>
                        <ViewFormData label={'GST'} value={'NA'} />
                      </Grid.Col> : null
                    }
                    {gstData?.igst ?
                      <Grid.Col span={12}>
                        <ViewFormData label={'IGST'} value={<Nums value={gstData?.igst} />} />
                      </Grid.Col> : null
                    }
                    {gstData?.cgst  ?
                      <Grid.Col span={12}>
                        <ViewFormData label={'CGST'} value={<Nums value={gstData?.cgst} />} />
                      </Grid.Col> : null
                    }
                    {gstData?.sgst  ?
                      <Grid.Col span={12}>
                        <ViewFormData label={'SGST'} value={<Nums value={gstData?.sgst} />} />
                      </Grid.Col> : null
                    }
                    <Grid.Col span={12} mt={'md'}>
                      <ViewFormDataContained label={'Total Amount'} value={<Nums withCopy value={gstData?.total_amount || values?.charge_amount} />} />
                    </Grid.Col>                  
                  </Grid>
                  </DataViewerWapper>                    
                  <Grid.Col span={12} px={0}>
                    <TitleViewer title={'Target Bank: Petromoney'} />
                  </Grid.Col>
                  <Grid.Col span={12} pt={'sm'} px={0}>
                    <ControllerSelect
                      name='business_bank_id'
                      displayLabel={false}
                      spanSize={12}
                      placeholder='Select Bank Details'
                      control={control}
                      autoSelect
                      setValue={setValue}
                      required
                      errors={errors?.business_bank_id?.message}
                      data={targetBank}
                    />
                  </Grid.Col>
                  <Textarea
                    value={notes} 
                    onChange={(event) => setNotes(event.currentTarget.value)}
                    placeholder="Enter your notes..."
                    label="Notes"
                    minRows={4}
                  />          
                </Grid.Col>              
              </Grid>
              <ActionButtons
                status={invoiceData?.status_value ? invoiceData?.status_value : 'draft'}
                base={'creation'}
                nextStage={stages?.next_stage}
                sendForReviewCB={handleReviewModal}
                sendForApprovalCB={handleReviewModal} 
                saveCB={handleSubmit(onSubmit)} 
                loading={{ save: buttonLoader?.save }}
                remarksCB={() => setRemarksOpen(true)}
                remarks={reviewTrail} 
                currentStatus={invoiceData?.status_value}
                moduleApplicationId={invoiceData?.id}
                moduleName={'invoice'}
                isReady={Boolean(stages?.next_stage)}/>

              <Remarks
                open={open}
                setOpen={setOpen}
                type={stages?.next_stage}
                callback={onReview}
                remarksTitle={stages?.next_stage == 'review' ? 'Review Remarks' : 'Approval Remarks'}
                check={status}
                label={label}
                loading={remarksLoading}
                currentStatus={invoiceData?.status_value}
                moduleApplicationId={invoiceData?.id}
                moduleName={'Invoice'}
              />
              
              <ModalDataViwer
                opened={sucessfulmodal?.open}
                label={invoiceData?.code}
                title={sucessfulmodal?.title}
                href={'/invoice-manual'}
                hreftext={'Back to list table'}
              />

            </Body>
          </Box>
      </>
    );
  };
  
  export default InvoiceCreation;
  