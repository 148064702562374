import {
  Box,
  Grid,
  Image,
  Button,
  Modal,
  Alert,
  List,
  Tooltip,
} from '@mantine/core';
import { upperFirst } from '@mantine/hooks';
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import DataViewerWapper from '../../components/CommonComponents/Preview/DataViewerWapper';
import {
  ViewFormData,
  ViewFormDataContained,
} from '../../components/CommonComponents/Preview/FilePreview';
import DashBoardCustomerDetails from '../../components/DashboardCard/dashboard';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import { getFeeTypesByProductId } from '../../services/common.service';
import {
  getImdById,
  getImdReviewTrail,
  CompanyBankDetails,
  pushbackImd,
  rejectImd,
  sendForNextStage,
  sedIMDforCancellation,
} from '../../services/imd.service';
import ImdStyle from './Imd.styles';
import Body from '../../components/Body/Body';
import Nums from '../../components/Body/Nums';
import PageTitle from '../../components/PageTitle/PageTitle';
import ViewRemark from '../../components/Remark/ViewRemark';
import IdBadge from '../../components/CommonComponents/Preview/IdBadge';
import Remarks from '../../components/CommonComponents/Remarks/Remarks';
import ModalDataViwer from '../../components/SucessfullModal/ModalDataViwer';
import { useQueryClient } from "@tanstack/react-query";
import ActionButtons
  from '../../components/CommonComponents/ActionButtons/ActionButtons';
import { generateIMDPdfbyID } from '../../services/pdf.service';
import AccountingInterfaceModal
  from '../../components/CommonComponents/AccuntingInterface/AccountingInterfaceModal';
import { STAGE_CHANGE_TEXT, PDF_TEXT_MESSAGE, GENERAL_IMG } from '../../constants';
import { getStageforModules } from '../../services/module.service';
import {
  IN_REVIEW,
  IN_APPROVAL,
  CANCELLATION_REVIEW,
} from '../../constants/status';
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification';
import TitleViewer from '../../components/CommonComponents/Title/TitleViewer';
import { getCustomerBankById } from '../../services/customer.service';
import UnidentifiedReceiptListModal from '../Receipt/UnidentifiedReceiptListModal';
import { cancelGSTeInvoice, generateGSTEbill, getRemarkseInvoice, geteInvoiceStatus } from '../../services/gstEbill.service';
import { AlertCircle, FileDownload, MessageCircle } from 'tabler-icons-react';
import { openFile } from '../../utils/openFile';
import DialogModal from '../../components/CommonComponents/Modal/DialogModal';

const ImdReview = ({ action = true, home = '/fee-invoice', type = 'imd' }) => {
  const navigate = useNavigate();
  const { custId, imdId } = useParams();
  const queryClient = useQueryClient();

  const { data: imdData = [], isLoading } = useQuery(
    ['imd-data', imdId],
    () => getImdById(imdId),
    {
      refetchOnWindowFocus: false
    }
  );

  const { data: stages = [] } = useQuery(
    ['imd-stages'],
    () => getStageforModules('imd'),
    {
      enabled: Boolean(imdData?.status_value),
      select: (data) => {
        return {
          'current_stage': imdData?.status_value,
          'next_stage': data[data.indexOf(imdData?.status_value) + 1]
        }
      },
      refetchOnWindowFocus: false
    }
  );

  const { data: target_bank = [], isLoading: tagretBankLoading } = useQuery(
    ['company-bank', imdData?.target_bank_id],
    () => CompanyBankDetails(imdData?.target_bank_id),
    {
      enabled: Boolean(imdData?.target_bank_id),
      refetchOnWindowFocus: false
    }
  );

  const { data: source_bank = {} } = useQuery(
    ['custBank', custId, imdData?.source_bank_id],
    () => getCustomerBankById({ customerId: custId, bankId: imdData?.source_bank_id }),
    {
      enabled: Boolean(imdData?.source_bank_id),
      select: (data) => {
        return data[0]
      },
    }
  );

  const { data: reviewTrail = [] } = useQuery(
    ['review-trail', imdId],
    () => type === 'e-bill' ? getRemarkseInvoice({ imd_id: imdId }) : getImdReviewTrail(imdId),
  );

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('approval');
  const [remarksTitle, setRemarksTitle] = useState()
  const [label, setLabel] = useState();
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [remarksLoading, setRemarksLoading] = useState();
  const [sucessfulmodal, setSucessfulmodal] = useState(false);
  const [pdfLoading, setpdfLoading] = useState();
  const [interfaceOpen, setInterfaceOpen] = useState(false)
  const [eInvoiceError, seteInvoiceError] = useState({});

  const eInvoiceStatus = useQuery({
    queryKey: ['eInvoiceStatus', imdId],
    queryFn: () => geteInvoiceStatus({ imd_id: imdId }),
    enabled: Boolean(type === 'e-bill'),
    onSuccess: (data) => {
      if (data?.[0]?.message?.length) {
        seteInvoiceError((old) => ({
          ...old,
          message: data?.[0]?.message
        }))
      }
    },
    refetchOnWindowFocus: false
  })

  /** this function is used to handle the pdf module if pdf url is not present */
  const handlePDF = (pdfType, navigateToTable = false) => {
    setpdfLoading(true)
    if (pdfType === 'e-bill') {
      generateGSTEbill({ imd_id: imdId })
        .then((res) => {
          displayNotification({ message: res?.message, variant: 'success' });
          navigateToTable && navigate('/e-invoice')
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' })
        })
        .finally(() => {
          setpdfLoading(false)
        })
    } else {
      generateIMDPdfbyID(imdId)
        .then((res) => {
          queryClient.invalidateQueries(['imd-data', imdId]);
          displayNotification({ message: PDF_TEXT_MESSAGE?.imd, variant: 'success' })
          openFile(res)
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' })
        })
        .finally(() => {
          setpdfLoading(false)
        })
    }
  }

  const handleImdNextStage = (remarks, status) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true)
    sendForNextStage(imdId, body)
      .then((data) => {
        setSucessfulmodal({
          open: true, title:
            status == IN_REVIEW ? STAGE_CHANGE_TEXT?.approval
              : status == IN_APPROVAL ? STAGE_CHANGE_TEXT?.approved
                : status == CANCELLATION_REVIEW ? STAGE_CHANGE_TEXT?.reviewCancel
                  : STAGE_CHANGE_TEXT?.approveCancel
        });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' })
      })
      .finally(() => {
        setOpen(false)
        setRemarksLoading(false)
      })
  };

  const handleReject = (remarks) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true)
    rejectImd(imdId, body)
      .then((data) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.reject });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' })
      })
      .finally(() => {
        setOpen(false)
        setRemarksLoading(false)
      })
  };

  const handlePushback = (remarks) => {
    const body = {
      remarks,
    };
    setRemarksLoading(true)
    pushbackImd(imdId, body)
      .then((data) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.pushback });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' })
      })
      .finally(() => {
        setOpen(false)
        setRemarksLoading(false)
      })
  };

  const handleCancellation = (remarks, reason) => {
    const body = {
      remarks,
      reason: reason?.reason,
    };
    setRemarksLoading(true)
    sedIMDforCancellation(imdId, body)
      .then((data) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.cancel });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' })
      })
      .finally(() => {
        setOpen(false)
        setRemarksLoading(false)
      })
  };

  /**
   * Handles the cancellation of a GST e-invoice.
   *
   * @param {string} remarks - The remarks for the invoice cancellation.
   * @param {object} reason - The reason for the invoice cancellation.
   * @param {string} reason.reason - The specific reason for the cancellation.
   */
  const handleInvoiceCancellation = (remarks, reason) => {
    const body = {
      remarks,
      reason: reason?.reason,
    }
    setRemarksLoading(true)
    cancelGSTeInvoice({ imd_id: imdId, body })
      .then((data) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.cancel });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' })
      })
      .finally(() => {
        setOpen(false)
        setRemarksLoading(false)
      })
  }

  const handleSave = (remarks, status, reason) => {
    switch (status) {
      case 'review':
      case 'approval':
      case 'cancellation':
        handleImdNextStage(remarks, status);
        break;
      case 'pushback':
        handlePushback(remarks);
        break;
      case 'reject':
        handleReject(remarks);
        break;
      case 'cancel':
        handleCancellation(remarks, reason);
        break;
      case 'e-invoice_cancel':
        handleInvoiceCancellation(remarks, reason);
        break;
      default:
        displayNotification({
          message: 'Something went wrong, Please contact support!',
          variant: 'error',
        });
        break;
    }
  };

  return (
    <>
      <Body background={false}>
        <PageTitle title={imdData?.customer_name} id={imdData?.customer_id} action={imdData?.unidentified_receipt_id ? <UnidentifiedReceiptListModal selectedRowId={imdData?.unidentified_receipt_id} /> : null} />
      </Body>
      <DashBoardCustomerDetails id={custId} />
      <Box sx={{ position: 'relative' }}>
        <Body>
          {imdData?.code && (
            <div style={ImdStyle.flag}>
              <IdBadge
                remarks={imdData?.status_value}
                label={imdData?.code}
              />
            </div>
          )}
          <Grid gutter='xl' mt='sm'>
            <Grid.Col span={4}>
              <Box p={32} sx={ImdStyle.alignCenter}>
                <Image
                  src={GENERAL_IMG}
                  alt='Illustration'
                />
              </Box>
            </Grid.Col>
            <Grid.Col span={4}>
              <DataViewerWapper>
                <Grid gutter='md'>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label='Product'
                      value={imdData?.product_name}
                      loading={isLoading}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label='IMD Date'
                      value={dateFormat(imdData?.imd_date)}
                      loading={isLoading}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label='IMD Type'
                      value={upperFirst(imdData?.type)}
                      loading={isLoading}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label='Fee Type'
                      value={imdData?.fee_name}
                      loading={isLoading}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label='Approved Amount'
                      value={
                        <Nums withCopy value={imdData?.approved_amount} />
                      }
                      loading={isLoading}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label='Fee Base Amount'
                      value={
                        <Nums withCopy value={imdData?.fee_base_amount} />
                      }
                      loading={isLoading}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label='Fee Amount'
                      value={<Nums withCopy value={imdData?.fee_amount} />}
                      loading={isLoading}
                    />
                  </Grid.Col>
                  {
                    imdData?.igst ?
                      <Grid.Col span={12}>
                        <ViewFormData
                          label='IGST'
                          value={<Nums value={imdData?.igst} />}
                          loading={isLoading}
                        />
                      </Grid.Col> : null
                  }
                  {
                    imdData?.sgst ?
                      <Grid.Col span={12}>
                        <ViewFormData
                          label='SGST'
                          value={<Nums value={imdData?.sgst} />}
                          loading={isLoading}
                        />
                      </Grid.Col> : null
                  }
                  {
                    imdData?.cgst ?
                      <Grid.Col span={12}>
                        <ViewFormData
                          label='CGST'
                          value={<Nums value={imdData?.cgst} />}
                          loading={isLoading}
                        />
                      </Grid.Col> : null
                  }
                  <Grid.Col span={12}>
                    <ViewFormDataContained
                      label='Total Processing Fee'
                      value={
                        <Nums withCopy value={imdData?.total_processing_fee} />
                      }
                      loading={isLoading}
                    />
                  </Grid.Col>
                </Grid>
              </DataViewerWapper>
            </Grid.Col>
            <Grid.Col span={4}>
              <Grid gutter='sm'>
                <Grid.Col span={12}>
                  <TitleViewer title={'Petromoney Bank Details'} />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewFormData
                    label='Petromoney Bank'
                    value={
                      target_bank &&
                      `${target_bank[0]?.account_number} (${target_bank[0]?.bank_name})`
                    }
                    loading={tagretBankLoading}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewFormData
                    label='Reciept Date'
                    value={dateFormat(imdData?.receipt_date)}
                    loading={tagretBankLoading}
                  />
                </Grid.Col>
              </Grid>
              <Grid gutter='sm' mt='md'>
                <Grid.Col span={12}>
                  <TitleViewer title={'Customer Bank Details'} />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewFormData
                    label='Customer Bank'
                    value={
                      source_bank &&
                      `${source_bank?.account_number} (${source_bank?.bank_name})`
                    }
                    loading={isLoading}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewFormData
                    label='Instrument Type'
                    value={imdData?.instrument_type?.toUpperCase()}
                    loading={isLoading}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <ViewFormData
                    label='Instrument Date'
                    value={dateFormat(imdData?.instrument_date)}
                    loading={isLoading}
                  />
                </Grid.Col>
              </Grid>
              <Grid gutter='sm' mt='md'>
                <Grid.Col span={12}>
                  <Box
                    p={16}
                    sx={{
                      marginTop: 3,
                      border: '1px solid #0063FF',
                    }}
                  >
                    <ViewFormData
                      label='Payment Ref.No'
                      value={imdData?.payment_ref_no}
                      loading={isLoading}
                    />
                  </Box>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
          {/** This Action buttons component is used to display the buttons in the flow */}
          {type === 'e-bill' ? (
            <Grid gutter='xl' mt='xl' sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid.Col span={6} sx={{ display: 'flex', columnGap: '15px' }}>
                {action ?
                  (
                    <>
                      <Button color='green' variant='outline' leftIcon={<FileDownload size={18} />} onClick={() => handlePDF()}>{imdData?.imd_url ? 'Invoice Tax Download' : 'Generate Tax Invoice'}</Button>
                      {!eInvoiceError?.message?.length ? (
                        <Button
                          color='green'
                          variant='outline'
                          leftIcon={<FileDownload size={18} />}
                          onClick={() => { imdData?.einvoice_url ? openFile(imdData?.einvoice_url) : handlePDF('e-bill', true) }}
                        >
                          {imdData?.einvoice_url ? 'e-Invoice Download' : 'Generate e-Invoice'}
                        </Button>
                      ) : (
                        <Button
                          color="red"
                          leftIcon={<AlertCircle />}
                          onClick={
                            () => {
                              seteInvoiceError((old) => ({ ...old, modal: true }));
                            }
                          }
                        >e-Invoice issue</Button>
                      )}
                    </>
                  ) : null
                }
              </Grid.Col>
              <Grid.Col span={6} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                {reviewTrail?.length && (!imdData?.einvoice_url) ?
                  <Button variant='outline' color="gray" ml='md' leftIcon={<MessageCircle />} onClick={() => { setRemarksOpen(true) }}>
                    View Remarks
                  </Button> : null
                }
                {imdData?.einvoice_url && action ? <Button color={'red'} ml='md' onClick={() => { setOpen(true); setRemarksTitle("Are you sure?"); setStatus('e-invoice_cancel'); setLabel('Confirm') }}>Cancel</Button> : null}
              </Grid.Col>
            </Grid>
          ) : (
            <ActionButtons
              status={stages?.current_stage}
              currentStatus={imdData?.status_value}
              moduleApplicationId={imdData?.id}
              moduleName={'imd'}
              isReady={Boolean(stages?.current_stage)}
              remarks={reviewTrail}
              nextStage={stages?.next_stage}
              remarksCB={() => setRemarksOpen(true)}
              downloadText={imdData?.imd_url ? 'Invoice Download' : 'Generate Invoice'}
              accountingCB={() => setInterfaceOpen(true)}
              pdfLoading={pdfLoading}
              handlePDF={() => imdData?.imd_url ? openFile(imdData?.imd_url) : handlePDF()}
              sendForApprovalCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('review'); setLabel('Send for Approval') }}
              approvedCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('approval'); setLabel('Approve') }}
              pushbackCB={() => { setOpen(true); setRemarksTitle("Pushback Remarks"); setStatus('pushback'); setLabel('Pushback') }}
              rejectCB={() => { setOpen(true); setRemarksTitle("Reject Remarks"); setStatus('reject'); setLabel('Reject') }}
              cancelCB={() => { setOpen(true); setRemarksTitle("Cancellation Remarks"); setStatus('cancellation'); setLabel('Send for Cancellation') }}
              approveCancelCB={() => { setOpen(true); setRemarksTitle("Are you sure?"); setStatus('cancel'); setLabel('Confirm') }} />
          )}
        </Body>

        <ViewRemark open={remarksOpen} onClose={setRemarksOpen} remarkTrail={reviewTrail} />

        {/** used to display the modal for accounting data */}
        <AccountingInterfaceModal open={interfaceOpen} close={() => setInterfaceOpen(false)} id={imdId} name={'imd'} code={imdData?.code} />
        {/** used to display error message of e-invoice */}
        <DialogModal size={'xl'} open={eInvoiceError?.modal} onClose={() => seteInvoiceError((old) => ({ ...old, modal: false }))} title="Issue Generating e-invoice">
          <Alert icon={<AlertCircle size="1rem" />} title="e-Invoice Generation Error!" color="red">
            <List>
              {eInvoiceError?.message?.map((item, index) => (
                <List.Item key={index}>{item}</List.Item>
              ))}
            </List>
          </Alert>
          <Box mt={'lg'} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Tooltip color={'gray'} withArrow label={'You will navigate to table once transaction is successful'}>
              <span>
                <Button
                  color='green'
                  variant='outline'
                  leftIcon={<FileDownload size={18} />}
                  onClick={() => { seteInvoiceError((old) => ({ ...old, modal: false })); imdData?.einvoice_url ? window.open(imdData?.einvoice_url) : handlePDF('e-bill', true) }}
                >
                  {imdData?.einvoice_url ? 'e-Invoice Download' : 'Generate e-Invoice'}
                </Button>
              </span>
            </Tooltip>
          </Box>
        </DialogModal>

        <ModalDataViwer
          opened={sucessfulmodal?.open}
          label={imdData?.code}
          title={sucessfulmodal?.title}
          href={type === 'e-bill' ? '/e-invoice' : home}
          hreftext={'Back to list table'}
        />

        <Remarks
          open={open}
          setOpen={setOpen}
          moduleName={'IMD'}
          type={stages?.next_stage}
          currentStatus={imdData?.status_value}
          moduleApplicationId={imdData?.id}
          callback={handleSave}
          remarksTitle={remarksTitle}
          check={status}
          label={label}
          loading={remarksLoading}
        />
      </Box>
    </>
  );
};

export default ImdReview;
