import { AnimatePresence, motion } from 'framer-motion'
import {
  Overlay,
  Box,
  createStyles,
  Group,
  Text,
  Title,
  ActionIcon,
  Button,
  Chip,
  Switch,
  Paper,
  FocusTrap,
  Checkbox,
  TextInput,
  Tooltip,
  Timeline,
  Badge,
  Skeleton,
} from '@mantine/core'
import { COLORS } from '../../../../constants'
import React, { useState } from 'react'
import { InfoCircle, X } from 'tabler-icons-react';
import { dateFormat } from '../../../../components/DateFormat/dateFormat';
import TextFieldEditor from '../../../../components/CommonComponents/TextEditor/TextFieldEditor';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UpdateEODSchedule, activateEODSchedule, deactivateEODSchedule, getEODSchedule, getEODScheduleRemarks } from '../../../../services/eod.service';
import { displayNotification } from '../../../../components/CommonComponents/Notification/displayNotification';
import dayjs from 'dayjs';

const useStyles = createStyles((them, isEdit) => ({
  mainDivStyles: {
    zIndex: 300,
    width: '550px',
    height: 'fit-content',
    position: 'absolute',
    top: 'calc(10vh + 20px)',
    left: '30%',
  },
  innerContentStyles: {
    height: '250px',
    display: 'flex',
    flexDirection: 'column',
  },
  actionButtonsStyles: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'flex-end',
    gap: '10px',
  },
  timeBoxStyles: {
    display: 'flex',
    flexDirection: 'column',
  },
  eodCardStyles: {
    border: `1px solid ${COLORS.gray(20)}`,
    position: 'relative',
    borderRadius: 6,
    padding: '15px',
    width: '450px',
    height: '55px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  eodCardTitle: {
    position: 'absolute',
    top: -10,
    left: 10,
    backgroundColor: COLORS.white,
    padding: '0 5px',
    color: COLORS.gray(30),
    fontSize: 12,
  },
  remarksList: {
    height: 300,
    overflowY: 'scroll',
  }
}));

const day_value = [
  { label: 'Sun', value: 'sunday' },
  { label: 'Mon', value: 'monday' },
  { label: 'Tue', value: 'tuesday' },
  { label: 'Wed', value: 'wednesday' },
  { label: 'Thu', value: 'thursday' },
  { label: 'Fri', value: 'friday' },
  { label: 'Sat', value: 'saturday' },
]

const EODSchedule = ({ setScheduledData }) => {
  const [dataObj, setDataObj] = useState({ modal: false });
  const [value, setValue] = useState({ date: dateFormat(new Date(), 'HH:mm'), scheduled_days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'], remarks: '', schedule: true });

  // get api call
  const getEODScheduleDetails = useQuery({
    queryKey: ['eod-schedule-details'],
    queryFn: () => getEODSchedule(),
    onSuccess: (data) => {
      setValue((old) => ({
        ...old,
        schedule: data?.eod_schedule_status,
        date: dayjs(`${dayjs().format('YYYY-MM-DD')} ${data?.time_to_run}`).format('HH:mm'),
        scheduled_days: data?.selected_days
      }))
      setScheduledData(data)
    }
  });

  const getEODScheduledRemarks = useQuery({
    queryKey: ['eod-schedule-remarks'],
    queryFn: () => getEODScheduleRemarks(),
    onSuccess: (data) => {
      setValue((old) => ({
        ...old,
        remarks: data?.remarks
      }))
    },
    enabled: false
  })

  // post api call
  const activateSchedule = useMutation((body) => activateEODSchedule(body))
  const updateSchedule = useMutation((body) => UpdateEODSchedule(body))
  const deactivateSchedule = useMutation((body) => deactivateEODSchedule(body))

  const { classes } = useStyles(dataObj?.edit);
  const queryClient = useQueryClient();

  //* Activates the schedule call.
  const activateScheduleCall = () => {
    const body = {
      time_to_run: value?.date,
      selected_days: value?.scheduled_days,
      remarks: value?.remarks,
    }
    if (!body?.time_to_run || !body?.selected_days?.length) {
      displayNotification({
        message: 'Please fill all valid fields',
        variant: 'error'
      });
      return;
    }
    activateSchedule.mutate({ body }, {
      onSuccess: () => {
        displayNotification({ message: 'EOD schedule activated successfully', variant: 'success' })
      },
      onError: (e) => {
        displayNotification({ message: e?.message || e, variant: 'error' })
      },
      onSettled: () => {
        setDataObj((old) => ({ ...old, modal: false }));
        queryClient.invalidateQueries(['eod-schedule-details']);
      }
    })
  }

  //* Update the schedule call.
  const updateScheduleCall = () => {
    const body = {
      time_to_run: value?.date,
      selected_days: value?.scheduled_days,
      remarks: value?.remarks,
    }
    if (!body?.time_to_run || !body?.selected_days?.length) {
      displayNotification({
        message: 'Please fill all valid fields',
        variant: 'error',
      });
      return;
    }
    updateSchedule.mutate({ body }, {
      onSuccess: () => {
        displayNotification({ message: 'EOD schedule updated successfully', variant: 'success' })
      },
      onError: (e) => {
        displayNotification({ message: e?.message || e, variant: 'error' })
      },
      onSettled: () => {
        setDataObj((old) => ({ ...old, modal: false }));
        queryClient.invalidateQueries(['eod-schedule-details']);
      }
    })
  }

  //* Deactivate the schedule call.
  const deactivateScheduleCall = () => {
    const body = {
      remarks: value?.remarks,
    }
    if (!body?.remarks) {
      displayNotification({
        message: 'Please enter remarks to proceed',
        variant: 'error',
      });
      return;
    }
    deactivateSchedule.mutate({ body }, {
      onSuccess: () => {
        displayNotification({ message: 'EOD schedule deactivated successfully', variant: 'success' })
      },
      onError: (e) => {
        displayNotification({ message: e?.message || e, variant: 'error' })
      },
      onSettled: () => {
        setDataObj((old) => ({ ...old, modal: false }));
        queryClient.invalidateQueries(['eod-schedule-details']);
      }
    })
  }

  // handling modal close
  const handleModalClose = () => {
    setDataObj((old) => ({ modal: false, }));
    setValue((old) => ({
      ...old,
      schedule: getEODScheduleDetails?.data?.eod_schedule_status,
      date: getEODScheduleDetails?.data?.time_to_run,
      scheduled_days: getEODScheduleDetails?.data?.selected_days
    }))
  }

  // used to display the selected day
  const displaySelectedDay = () => {
    if (value?.scheduled_days?.length === 7) {
      return ['Repeat every Day']
    } else {
      const result = value?.scheduled_days?.map((item) => {
        console.log(item);
        return day_value?.find(day => day.value === item)?.label?.toUpperCase();
      })
      return result
    }
  }

  return (
    <Box>
      <Box className={classes.eodCardStyles} mt={'sm'} mb={'xs'}>
        {!dataObj?.modal ?
          <>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
              <Text className={classes.eodCardTitle}>EOD Schedule Details</Text>
              <Box onClick={() => setDataObj({ modal: true, layoutId: 'schedule-button-eod', edit: true })} sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'end' }} component={motion.div} layoutId={'schedule-button-eod'}>
                <Text color={'gray'} sx={{ fontSize: 24, lineHeight: 1 }}>{dayjs(`${dayjs().format('YYYY-MM-DD')} ${value?.date}`).format('hh:mm A')}</Text>
                <Text color={COLORS.gray(60)} ml={'xs'}>[{displaySelectedDay()?.join(', ')}]</Text>
              </Box>
              <Tooltip label={'Click to view remarks'} color={'gray'} withArrow position='right'>
                <span style={{ height: 19, cursor: 'pointer' }} onClick={() => { setDataObj({ modal: true, type: 'remarks' }); getEODScheduledRemarks?.refetch(); }}>
                  <InfoCircle color={COLORS.gray(40)} strokeWidth={2} size={18} />
                </span>
              </Tooltip>
            </Box>
            <Switch size='md' onLabel="ON" offLabel="OFF" checked={value?.schedule} color='teal' onChange={() => setDataObj({ modal: !dataObj?.modal })} />
          </>
          : null
        }
      </Box>
      <AnimatePresence>
        {dataObj?.modal ?
          (<>
            <Overlay color={COLORS.black} component={motion.div} opacity={0.3} fixed exit={{ opacity: 0 }} />
            <Box className={classes.mainDivStyles} component={motion.div} layoutId={'schedule-button-eod'}>
              <Paper
                size='md'
                p={'xl'}
                role="dialog"
                tabIndex={-1}
                aria-modal
                radius={'md'}
                shadow="xs"
              >
                {dataObj?.type === 'remarks'
                  ? <>
                    <Group position='apart' mb={'md'}>
                      <Title order={3} color='gray'>EOD Schedule Remarks</Title>
                      <ActionIcon size={'sm'} onClick={handleModalClose}><X size={18} /></ActionIcon>
                    </Group>
                    <Box className={classes.remarksList}>
                      <Timeline active={-1} lineWidth={3} bulletSize={14}>
                        {getEODScheduledRemarks?.data?.length ? getEODScheduledRemarks?.data?.map((item, index) => (
                          <Timeline.Item
                            title={
                              <Group position='apart'>
                                <Text color={COLORS.gray(40)} size={'xs'}>
                                  {dateFormat(item?.created_date, 'DD MMM YYYY - hh:mm A')} by {item?.created_name}
                                </Text>
                                <Badge color={item?.action_type === 'schedule activated' ? 'teal' : item?.action_type === 'schedule updated' ? 'green' : 'red'}>{item?.action_type === 'schedule activated' ? 'Activated' : item?.action_type === 'schedule updated' ? 'Updated' : 'Deactivated'}</Badge>
                              </Group>
                            }
                            key={index}
                          >
                            <Text
                              size='xs'
                              sx={{
                                maxWidth: '80%',
                                maxHeight: 90,
                                overflow: 'hidden',
                              }}
                              dangerouslySetInnerHTML={{ __html: item?.remarks }}
                            />
                            {/* <RichTextViewer value={item?.remarks || ''} /> */}
                          </Timeline.Item>
                        )) : [1, 2, 3, 4, 5, 6]?.map((item) => (
                          <Box mt={'20px'}>
                            <Group position='apart'>
                              <Skeleton height={10} width={'30%'} radius="xl" />
                              <Skeleton height={10} width={'20%'} radius="xl" />
                            </Group>
                            <Skeleton height={10} mt={10} radius="xl" />
                          </Box>))
                        }
                      </Timeline>
                    </Box>
                  </>
                  : <>
                    <Group position='apart'>
                      <Title order={3} color='gray'>EOD Schedule</Title>
                      <ActionIcon size={'sm'} onClick={handleModalClose}><X size={18} /></ActionIcon>
                    </Group>
                    <Box mt={15}>
                      {dataObj?.edit ?
                        <>
                          <Text size={'sm'} color={'gray'} weight={500}>Time</Text>
                          <Text sx={{ fontSize: 10 }} color={COLORS.gray(40)}>Enter the time to schedule EOD</Text>
                          <FocusTrap active={true}>
                            <TextInput
                              type='time'
                              size='xs'
                              value={value?.date}
                              onChange={(e) => setValue((old) => ({ ...old, date: e.target.value }))}
                            />
                          </FocusTrap>
                          {/* <InputBase component={IMaskInput} mask="00:00" onChange={(e) => handleTimeChange(e.target.value)} /> */}
                          <Text size={'sm'} color={'gray'} weight={500} mt={5}>Day</Text>
                          <Text sx={{ fontSize: 10 }} color={COLORS.gray(40)}>Pick a day to schedule the EOD accordingly</Text>
                          <Chip.Group multiple value={value?.scheduled_days} onChange={(e) => setValue((old) => ({ ...old, scheduled_days: e }))}>
                            <Group spacing={0}>
                              {day_value?.map((item, index) => (
                                <Chip key={index} value={item.value} variant='light' radius={0}>{item.label}</Chip>
                              ))}
                            </Group>
                          </Chip.Group>
                          <Group mt={'xs'}>
                            <Checkbox size={'xs'} checked={value?.scheduled_days?.length === 7} onChange={() => setValue((old) => ({ ...old, scheduled_days: value?.scheduled_days?.length === 7 ? [] : ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'] }))} />
                            <Text>Repeat All days</Text>
                          </Group>
                        </>
                        : null
                      }
                      <Box mt={10}>
                        <Text size={'sm'} color={'gray'} weight={500}>Enter Remarks</Text>
                        <TextFieldEditor value={value?.remarks} onChange={(e) => setValue((old) => ({ ...old, remarks: e }))} />
                      </Box>
                      <Box className={classes.actionButtonsStyles} mt={10}>
                        <Button variant={'outline'} onClick={handleModalClose}>Cancel</Button>
                        {
                          dataObj?.edit ? (
                            !getEODScheduleDetails?.data?.eod_schedule_status ? (
                              <Button color={"green"} loading={activateSchedule.isLoading} onClick={activateScheduleCall}>Activate</Button>
                            ) : (
                              <Button color={"green"} loading={updateSchedule.isLoading} onClick={updateScheduleCall}>Update</Button>
                            )
                          ) : (
                            !getEODScheduleDetails?.data?.eod_schedule_status ? (
                              <Button color={"green"} loading={activateSchedule.isLoading} onClick={activateScheduleCall}>Activate</Button>
                            ) : (
                              <Button color={"green"} loading={deactivateSchedule.isLoading} onClick={deactivateScheduleCall}>Deactivate</Button>
                            )
                          )
                        }
                      </Box>
                    </Box>
                  </>
                }
              </Paper>
            </Box>
          </>)
          : null
        }
      </AnimatePresence>
    </Box >
  )
}

export default EODSchedule