import { Text } from '@mantine/core';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import { customerApplicationSearch } from '../../services/imd.service';
import Filter from '../../components/CommonComponents/Filter/Filter';
import Nums from '../../components/Body/Nums';
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification';
import SimpleReactTable from './../../components/CommonComponents/Table/SimpleReactTable';
import { createColumnHelper } from '@tanstack/react-table';
import { DrawerModal } from '../../components/CommonComponents/Drawer/DrawerModal';

const DisbursedLoans = ({ addIMD, setAddIMD }) => {

  const columnHelper = createColumnHelper();
  const [filter, setFilter] = useState(true)
  const [loading, setLoading] = useState()
  const [disbursedLoans, setDisbursedLoans] = useState([])
  const [filterDetails, setFilterDetails] = useState([]);

  const navigate = useNavigate();

  const onSubmit = (values, filterData) => {
    setFilterDetails(filterData)
    setLoading(true)
    customerApplicationSearch({...values})
      .then((res) => {
        setDisbursedLoans(res)
      })
      .catch((e) => {
        setDisbursedLoans()
        displayNotification({id: 'customer-imd', message: e?.message || e, variant: 'error'})
      })
      .finally(() => {
        setLoading(false)
        setFilter(false)
      })
  }

  const columns = [
    columnHelper.accessor('customer_id', {
      header: 'Customer Id',
    }),
    columnHelper.accessor('customer_name', {
      header: 'Customer Name',
    }),
    columnHelper.accessor('approved_date', {
      header: 'Approved Date',
      cell: (value) => dateFormat(value.getValue()),
    }),
    columnHelper.accessor('requested_amount', {
      header: <Text sx={{textAlign: 'right'}}>Requested Amount</Text>,
      cell: (value) =>  <Nums value={value.getValue()} styles={{textAlign: 'right'}} />,
    }),
    columnHelper.accessor('approved_amount', {
      header: <Text sx={{textAlign: 'right'}}>Approved Amount</Text>,
      cell: (value) =>  <Nums value={value.getValue()} styles={{textAlign: 'right'}} />,
    }),
  ];
  return (
    <DrawerModal
      open={addIMD}
      onClose={() => setAddIMD(false)}
      title='Find an Application'
      position='right'
      padding='xl'
      size='50%'
    >
      {/* Used to show filter */}
      { filter ?
        <Filter 
          callback={onSubmit} 
          onClose={() => setAddIMD(false)} 
          loading={loading} 
          open={addIMD}
          type={'imd'} 
        /> :
        <SimpleReactTable
          rowData={disbursedLoans}
          columnData={columns}
          filterData={filterDetails}
          allowSorting
          showFilter
          setFilter={setFilter}
          sx={{ height: '70vh' }}
          rowKeyGetter={(row) => {
            return row?.id;
          }}
          onRowClick={(i, o) => navigate(`/fee-invoice/${i?.customer_id}`)}
        />
      }
    </DrawerModal>
  );
};

export default DisbursedLoans;
