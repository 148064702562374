import { getAPICall, patchAPICall, postAPICall } from '.';

export const getEligibleReceiptLoans = (body) => {
  /** used to get eligible loans by id */
  return postAPICall(`receipt/eligible-loans`, { body: body });
};

export const getEligibleReceiptLoanbyId = (body) => {
  /** used to get eligible loans by id */
  return postAPICall(`receipt/eligible-loans/${body?.loanId}`, { body: body?.body });
};

export const createReceipt = (body) => {
  /** used to create receipt */
  return postAPICall("receipt", { body: body });
};

export const updateReceipt = (id, body) => {
  /** used to update the receipt */
  return patchAPICall(`receipt/${id}`, { body: body });
};

export const getAllReceipts = ({page, search, records = 10, status, entity}) => {
  /** used to get all receipt */
  let url = `receipts?page=${page}&records=${records}&status=${status}`
  if(search) url = url + `&customer_id_name=${search}`;
  if(entity && entity !== 'all') url = url + `&entity_id=${entity}`;
  return getAPICall(url, { returnObject: true });
};

export const getAllReceiptsById = (id) => {
  /** used to get receipt details by id */
  return getAPICall(`receipt/${id}`, { returnFirstItem: true });
};

export const saveReceiptAndsendForNextStage = (body) => {
  /** used to sed the receipt to the next stage */
  return postAPICall(`receipt/direct_save`, { body: body });
};

export const sendReceiptforNextStage = (id, body) => {
  /** used to sed the receipt to the next stage by id */
  return postAPICall(`receipt/${id}/status/change`, { body: body });
};

export const rejectReceipt = (id, body) => {
  /** used to reject the receipt */
  return postAPICall(`receipt/${id}/rejected`, { body: body });
};

export const pushbackReceipt = (id, body) => {
  /** used to pushback the receipt */
  return postAPICall(`receipt/${id}/pushback`, { body: body });
};

export const getReceiptRemarks = (id) => {  
  /** used to get remarks for receipt by id */
  return getAPICall(`receipt/${id}/remark`);
};

export const getAppropriationLogic = (body) => {
  /** used to calculate the appropriate logic */
  return postAPICall(`calculate/appropriation-logic`, {
    body: body,
    returnFirstItem: true,
  });
};

export const sendReceiptforCancellation = (id, body) => {
  /** used to cancel the receipt */
  return postAPICall(`receipt/${id}/cancelled`, { body: body });
};
