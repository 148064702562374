export const LOAN_STATUS_COLORS = {
    'disbursed': 'gray',
    'loan-approved': 'gray',
    'foreclosed': 'green',
    'closed': 'red',
    'loan-rejected': 'red',
    'disbursement-rejected': 'red',
    'payment-rejected': 'red',
    'loan-cancelled': 'red',
    'disbursement-cancelled': 'red',
    'payment-cancelled': 'red',
    'auto-cancelled': 'red',
}

export const STATUS_COLORS = {
    'draft': 'gray',
    'review': 'blue',
    'approval': 'blue',
    'approved': 'green',
    'auto_approved': 'teal',
    'rejected': 'red',
    'cancel_rejected': 'red',
    'cancelled': 'red',
    'cancel_approval': 'red',
    'cancel_review': 'red',
    'auto_cancelled': 'red',
    'closed': 'red',
    'processed': 'green',
}

export const FEE_STATUS_VALUE = {
    'tranche_fee_collection': "Tranche Fee",
    'renewal_fee_collection': "Renewal Fee",
    'fee_collection': "Fee",
    'reload_fee': 'Express Reload Fee',
}

export const STATUS_VALUE = {
    'draft': 'draft',
    'review': 'pending review',
    'approval': 'pending approval',
    'approved': 'approved',
    'auto_approved': 'auto approved',
    'rejected': 'rejected',
    'cancelled': 'cancelled',
    'auto_cancelled': 'auto cancelled',
    'cancel_approval': 'In Cancellation',
    'cancel_review': 'In Cancellation',
}

export const STATUS_VALUE_SELECTOR = {
    'draft': 'Draft',
    'review': 'Pending Review',
    'approval': 'Pending Approval',
    'approved': 'Approved',
    'auto_approved': 'Auto Approved',
    'rejected': 'Rejected',
    'cancelled': 'Cancelled',
    'closed': 'Closed',
    'auto_cancelled': 'Auto Cancelled',
    'cancel_approval': 'Cancellation Approval',
    'cancel_review': 'Cancellation Review',
    'processed': 'Processed',
    'unprocessed': 'Unprocessed',
}

export const CANCELLATION_STATUS_VALUE = {
    'approved': 'approved',
    'cancel_approval': 'pending approval',
    'cancel_review': 'pending review',
    'rejected': 'rejected',
}

export const DASHBOARD_STATUS_VALUE = {
    'pre_submit': 'Sales Login',
    "submitted": "Credit Login",
    "loan_review": "Pending Review",
    "loan_approval": "Pending Approval",
    "approved": "Approved",
    "rejected": "Rejected",
    "disbursed": "Disbursed",
}

export const DASHBOARD_STATUS_COLOR = {
    'pre_submit': 'gray',
    "submitted": "gray",
    "loan_review": "blue",
    "loan_approval": "blue",
    "approved": "green",
    "rejected": "red",
    "disbursed": "teal",
}