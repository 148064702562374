import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Body from '../../components/Body/Body';
import Nums from '../../components/Body/Nums';
import { LoanStatusToken } from '../../components/CommonComponents/Preview/FilePreview';
import DataTableWrapper from '../../components/CommonComponents/Table/DataTableWrapper';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import { getForeclosureTableData } from '../../services/foreclosure.service';
import { APPROVED, REJECTED } from '../../constants/status';
import { LOAN_STATUS_COLORS } from '../../utils/StatusTable';
import { Text } from '@mantine/core';

const ForeclosureTable = () => {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [records, setRecords] = useState({label: 'show 10', value: 10});

  const { data: foreclosureData = [], isLoading } = useQuery(
    ['foreclosure-table', page, search, records], 
    () => getForeclosureTableData({page, search, records: records?.value}), 
    { refetchOnWindowFocus: false }
  );

  /** used to change the page to 1 while the search us used 
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1)
  }, [search])

  const onRowClick = (i) => {
    if (i?.status_value == APPROVED) {
      navigate(`/foreclosure/approved/${i?.customer_id}/${i?.id}`);
    } else if (i?.status_value == REJECTED) {
      navigate(`/foreclosure/rejected/${i?.customer_id}/${i?.id}`);
    }
  };

  const onClickAction = (value) => {
    return (
      <span
        style={{ color: '#0063FF', cursor: 'pointer' }}
        onClick={() => onRowClick(value.row.original)}
      >
        {value.getValue()}
      </span>
    );
  };

  const columnData = [
    columnHelper.accessor('prospect_code', {
      header: 'Tranche Code',
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor('customer_name', {
      header: 'Customer Name',
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor('customer_id', {
      header: 'Customer Code',
    }),
    columnHelper.accessor('product_name', {
      header: 'Scheme',
    }),
    columnHelper.accessor('loan_amount', {
      header: <Text sx={{textAlign: 'right'}}>Tranche Amount</Text>,
      cell: (value) => <Nums value={value.getValue()} styles={{textAlign: 'right'}} />,
    }),
    columnHelper.accessor('disbursed_amount', {
      header: <Text sx={{textAlign: 'right'}}>Disb Amount</Text>,
      cell: (value) => <Nums value={value.getValue()} styles={{textAlign: 'right'}} />,
    }),
    columnHelper.accessor('disbursed_date', {
      header: 'Disb Date',
      cell: (value) => dateFormat(value.getValue()),
    }),
    columnHelper.accessor('maker_name', {
      header: 'Created By',
    }),
    columnHelper.accessor('loan_status', {
      header: 'Status',
      cell: (value) => (
        <LoanStatusToken
          label={value.getValue()}
          color={LOAN_STATUS_COLORS?.[value.getValue()]}
        />
      ),
    }),
  ];

  return (
    <Body background={false}>
      <DataTableWrapper
        title='Foreclosed Tranches'
        filter={false}
        rowData={foreclosureData?.data}
        onRowClick={onRowClick}
        columnData={columnData}
        loading={isLoading}
        noDataText="No Foreclosed Tranche details yet!"
        noDataSubText='This Section will contain list of foreclosed tranche details.'
        useApiPagination
        totalNoOfPages= {foreclosureData?.total_number_of_pages}
        totalNoOfRecords= {foreclosureData?.total_number_of_records}
        page={page}
        setPage={setPage}
        addDataOption={false}
        rowLength={records}
        setRowLength={setRecords}
        apiSearch={setSearch}
        actions={false}
      />
    </Body>
  );
};

export default ForeclosureTable;
