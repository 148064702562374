import { Drawer } from '@mantine/core'
import React from 'react'

export const DrawerModal = ({ open, onClose, title, children, ...props }) => {
    return (
        <Drawer
            opened={open}
            onClose={onClose}
            title={title}
            transitionProps={{transition: "slide-left", duration: 400, timingFunction: 'ease'}}
            {...props}
        >
            {children}
        </Drawer>
    )
}
