import React, { useEffect } from "react";
import {
  ActionIcon,
  Box,
  Paper,
  ScrollArea,
  Skeleton,
  Table,
  Text,
  createStyles,
  Checkbox,
} from "@mantine/core";
import { useState } from "react";
import Select from "react-select";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { ArrowRight, ChevronsLeft, ChevronsRight } from "tabler-icons-react";
import { useDebouncedValue } from "@mantine/hooks";
import { rankItem } from "@tanstack/match-sorter-utils";
import Nums from "../../Body/Nums";
import { sumBy } from "lodash";
import useSelectedTableRowsStore from './../../../store/selectedTableRowsStore';
import CreationBatchModal from '../../../pages/Disb/CreationBatchModal';
import { motion } from 'framer-motion';
import { COLORS } from "../../../constants";
import getAllApprovalDisbMemoRecords from './../../../pages/DisbBatch/getAllDisbBatchApprovalRecords';
import { useSearchParams } from "react-router-dom";

const useStyles = createStyles({
  floating: {
    padding: "5px",
    borderRadius: "50px",
    position: "fixed",
    alignItems: "center",
    opacity: "0.93",
    left: "0",
    right: "0",
    width: 'fit-content',
    // bottom: '20px',
    marginLeft: 'auto',
    boxShadow: '0px 4px 4px 2px rgba(0, 0, 0, 0.25)',
    background: 'linear-gradient(181.64deg, #444444 -5.87%, #242424 98.6%)',
    marginRight: 'auto',
  },
  floatingText: {
    color: "#afafaf",
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainBoxStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  displayText: {
    display: "flex",
    flexDirection: "row",
    gap: '40px',
    padding: '0 20px'
  },
  textStyle: {
    fontWeight: 500,
    color: 'white',
    fontSize: '14px'
  },
  actionDiv: {
    position: 'relative',
    width: '65px',
    height: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  actionIcon: {
    borderRadius: '50px',
    height: '60px',
    width: '60px',
    background: 'linear-gradient(270deg, #454545 -11.43%, #242424 97.14%)',
    cursor: 'pointer',
    position: 'relative',
    zIndex: '99',
  },
  expressContainer: {
    width: '8px',
    height: '100%',
    padding: 2,
    background: COLORS.orange(100),
    color: 'white',
    left: 0,
    position: 'absolute',
  },
  expressInner: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    bottom: '50%',
    left: '-12.5px',
    rotate: '270deg',
    fontSize: '6px',
    fontWeight: 600,
  }
});

const SelectableReactTable = ({
  rowData,
  columnData,
  onRowClick = () => null,
  search,
  setSearch,
  allowSorting = false,
  loading,
  page,
  setPage,
  setRowLength,
  rowLength,
  useApiPagination = false,
  totalNoOfRecords,
  totalNoOfPages,
  styles,
}) => {
  const { classes } = useStyles();
  const [data, setData] = useState([]);
  const [debounce] = useDebouncedValue(search, 400);
  const [sorting, setSorting] = useState([]);
  const [openBatchModal, setOpenBatchModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { selectedRows, addToSelectedRows, removeFromSelectedRows, resetSelectedRows, addAllRows } = useSelectedTableRowsStore(store => ({
    selectedRows: store.selectedRows,
    addToSelectedRows: store.addToSelectedRows,
    removeFromSelectedRows: store.removeFromSelectedRows,
    resetSelectedRows: store.resetSelectedRows,
    addAllRows: store.addAllRows,
  }))

  useEffect(() => {
    resetSelectedRows();
  }, [searchParams.get('entity')])

  const no_of_records = [
    {
      label: "Show 10",
      value: 10,
    },
    {
      label: "Show 15",
      value: 15,
    },
    {
      label: "Show 20",
      value: 20,
    },
  ];

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank,
    });
    return itemRank.passed;
  };

  useEffect(() => {
    setData([...rowData]);
  }, [rowData]);

  const table = useReactTable({
    data,
    columns: columnData,
    state: {
      globalFilter: debounce,
      sorting,
    },
    onGlobalFilterChange: setSearch,
    onSortingChange: setSorting,
    globalFilterFn: fuzzyFilter,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  if (loading) {
    return (
      <Paper mt={10}>
        <Table
          highlightOnHover
          fontSize="xs"
          mb={10}
          verticalSpacing="xs"
          sx={{ ...styles }}
        >
          <tbody>
            {Array(10)
              .fill()
              .map((_, rowKey) => (
                <tr key={`row-${rowKey}`}>
                  {Array(6)
                    .fill()
                    .map((_, cellKey) => (
                      <td key={`cell-${cellKey}`}>
                        <Skeleton height={20} />
                      </td>
                    ))}
                </tr>
              ))}
          </tbody>
        </Table>
      </Paper>
    );
  }

  return (
    <>
      <Paper mt={10}>
        <ScrollArea>
          <Table
            highlightOnHover
            fontSize="xs"
            mb={10}
            verticalSpacing="xs"
            sx={{ ...styles }}
          >
            <thead style={{ backgroundColor: COLORS.primary.light1 }}>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  <th>
                    <Checkbox
                      key={headerGroup.id}
                      styles={(them) => ({
                        input: {
                          cursor: "pointer",
                        },
                      })}
                      color="green"
                      checked={selectedRows?.count === selectedRows?.data?.length}
                      onChange={(e) => {
                        getAllApprovalDisbMemoRecords({
                          totalRecordCount: totalNoOfRecords,
                          selectedRecordCount: selectedRows?.data?.length,
                          addAllRows: addAllRows,
                          entity: searchParams.get("entity"),
                          loanType: searchParams.get("reload_type"),
                          clearAllRows: resetSelectedRows
                        })
                      }}
                    />
                  </th>
                  {headerGroup.headers.map((header, index) => (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} style={{ backgroundColor: selectedRows?.data?.find((e) => row?.original?.id === e.id)?.id ? '#EDFFED' : row?.original?.reload_type === 'express' ? COLORS.red(10) : '', position: 'relative' }}>
                  <td>
                    {!row?.original?.isDisabled ? (
                      <Checkbox
                        key={row?.original?.id}
                        styles={(them) => ({
                          input: {
                            cursor: "pointer",
                          },
                        })}
                        color="green"
                        checked={
                          selectedRows?.data?.filter(
                            (e) => row?.original?.id === e.id
                          )?.length
                        }
                        onChange={(e) => {
                          // if currentTarget checked is checked then the push will take place else pop
                          if (e.currentTarget.checked) {
                            addToSelectedRows(row?.original);
                          } else {
                            removeFromSelectedRows(row?.original);
                          }
                        }}
                      />
                    ) : null}
                  </td>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                  {row?.original?.reload_type === 'express' ? <><div className={classes.expressContainer}></div><div className={classes.expressInner}>EXP CRR</div></> : null}
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollArea>
      </Paper>
      <Box
        mt="md"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {useApiPagination ? (
          <>
            {totalNoOfPages ? (
              <>
                <Box>
                  <Box sx={{ display: "flex", gap: 10, alignItems: "center" }}>
                    <Text size="xs" sx={{ color: "rgb(0,0,0,0.5)" }}>
                      <strong>{`Showing Page ${page} - ${totalNoOfPages}`}</strong>
                    </Text>
                    {totalNoOfRecords > 10 ? (
                      <Select
                        options={no_of_records}
                        onChange={(e) => {
                          table.setPageSize(e.value);
                          setRowLength(e);
                        }}
                        value={rowLength}
                        menuPlacement="top"
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                            padding: "0",
                          }),
                          option: (provided) => ({
                            ...provided,
                            fontSize: "10px",
                            padding: "5px 10px 5px 10px",
                          }),
                          control: (provided) => ({
                            ...provided,
                            padding: 0,
                            fontSize: "10px",
                            maxHeight: 10,
                          }),
                        }}
                      />
                    ) : null}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 10, alignItems: "center" }}>
                  {page != 1 ? (
                    <ActionIcon variant="light" onClick={() => setPage(1)}>
                      <ChevronsLeft size={16} />
                    </ActionIcon>
                  ) : null}
                  {page != 1 ? (
                    <ActionIcon
                      variant="light"
                      onClick={() => setPage(page - 1)}
                    >
                      <Text size="xs" sx={{ color: "rgb(0,0,0,0.5)" }}>
                        {page - 1}
                      </Text>
                    </ActionIcon>
                  ) : null}
                  {page >= 1 ? (
                    <ActionIcon variant="light" color="blue">
                      <Text size="xs" sx={{ color: "rgb(0,0,0,0.5)" }}>
                        {page}
                      </Text>
                    </ActionIcon>
                  ) : null}
                  {totalNoOfPages != 1 && totalNoOfPages != page ? (
                    <>
                      <ActionIcon
                        variant="light"
                        onClick={() => setPage(page + 1)}
                      >
                        <Text size="xs" sx={{ color: "rgb(0,0,0,0.5)" }}>
                          {page + 1}
                        </Text>
                      </ActionIcon>
                      <ActionIcon
                        variant="light"
                        onClick={() => setPage(totalNoOfPages)}
                      >
                        <ChevronsRight size={16} />
                      </ActionIcon>
                    </>
                  ) : null}
                  {totalNoOfRecords ? (
                    <Box>
                      <Text size="xs" sx={{ color: "rgb(0,0,0,0.5)" }}>
                        {totalNoOfRecords} Records
                      </Text>
                    </Box>
                  ) : null}
                </Box>
              </>
            ) : null}
          </>
        ) : (
          <>
            <Box>
              <Text size="xs" sx={{ color: "rgb(0,0,0,0.5)" }}>
                <strong>{`Showing Page ${table.getState().pagination.pageIndex + 1
                  } - ${table.getPageCount()}`}</strong>
              </Text>
            </Box>
            <Box sx={{ display: "flex", gap: 10, alignItems: "center" }}>
              {table.getState().pagination.pageIndex != 0 ? (
                <>
                  <ActionIcon
                    variant="light"
                    onClick={() => table.setPageIndex(0)}
                  >
                    <ChevronsLeft size={16} />
                  </ActionIcon>
                  <ActionIcon
                    variant="light"
                    onClick={() => table.previousPage()}
                  >
                    <Text size="xs" sx={{ color: "rgb(0,0,0,0.5)" }}>
                      {table.getState().pagination.pageIndex}
                    </Text>
                  </ActionIcon>
                </>
              ) : null}
              {table.getState().pagination.pageIndex >= 0 ? (
                <ActionIcon variant="light" color="blue">
                  <Text size="xs" sx={{ color: "rgb(0,0,0,0.5)" }}>
                    {table.getState().pagination.pageIndex + 1}
                  </Text>
                </ActionIcon>
              ) : null}
              {table.getPageCount() != 0 &&
                table.getPageCount() !=
                table.getState().pagination.pageIndex + 1 ? (
                <>
                  <ActionIcon variant="light" onClick={() => table.nextPage()}>
                    <Text size="xs" sx={{ color: "rgb(0,0,0,0.5)" }}>
                      {table.getState().pagination.pageIndex + 2}
                    </Text>
                  </ActionIcon>
                  <ActionIcon
                    variant="light"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                  >
                    <ChevronsRight size={16} />
                  </ActionIcon>
                </>
              ) : null}
              {data?.length ? (
                <Box>
                  <Text size="xs" sx={{ color: "rgb(0,0,0,0.5)" }}>
                    {data?.length} Records
                  </Text>
                </Box>
              ) : null}
            </Box>
          </>
        )}
        {selectedRows?.data?.length && !openBatchModal ? (
          <motion.div
            className={classes.floating}
            initial={{ opacity: 0, scale: 0.5, bottom: -10, }}
            animate={{ opacity: 1, scale: 1, bottom: 20, }}
            transition={{ type: "spring", damping: 10, stiffness: 100 }}
          >
            <Box className={classes.mainBoxStyle}>
              <div className={classes.displayText}>
                <Text size={"xs"} className={classes.floatingText}>
                  <span className={classes.textStyle}>
                    {selectedRows?.data?.length}
                  </span>
                  Selected
                </Text>
                <Text size={"xs"} className={classes.floatingText}>
                  <span className={classes.textStyle}>
                    <Nums
                      value={sumBy(selectedRows?.data, function (n) {
                        return parseFloat(n.loan_amount);
                      })}
                    />
                  </span>
                  Tranche Amount
                </Text>
                <Text size={"xs"} className={classes.floatingText}>
                  <span className={classes.textStyle}>
                    <Nums
                      value={sumBy(selectedRows?.data, function (n) {
                        return parseFloat(n?.net_disbursement_amount);
                      })}
                    />
                  </span>
                  Net Disb Amount
                </Text>
              </div>
              <div className={classes.actionDiv}>
                <motion.div
                  style={{
                    position: 'absolute',
                    borderRadius: '50px',
                    left: 0,
                    height: '64px',
                    width: '64px',
                    border: "2px dashed #AC9647",
                  }}
                  initial={{ rotate: 0 }}
                  animate={{ rotate: 360 }}
                  transition={{ duration: 20, repeatDelay: -19, repeat: Infinity }}
                >
                </motion.div>
                <div className={classes.actionIcon}>
                  <motion.div
                    style={{
                      height: '60px',
                      width: '60px',
                      background: "rgba(0, 0, 0, 0)",
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                    }}
                    whileHover={{ scale: 1.2 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    whileTap={{ scale: 0.8 }}
                    onClick={() => setOpenBatchModal(true)}
                  >
                    <ArrowRight color="rgba(183, 153, 46, 1)" />
                  </motion.div>
                </div>
              </div>
            </Box>
          </motion.div>
        ) : null}
        {openBatchModal ? (
          <CreationBatchModal
            data={selectedRows?.data}
            callBack={(d) => removeFromSelectedRows(d)}
            onClose={() => setOpenBatchModal(false)}
            open={openBatchModal}
            clearAll={() => resetSelectedRows()}
          />
        ) : null}
      </Box>
    </>
  );
};

export default SelectableReactTable;
