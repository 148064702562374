import React, { useEffect, useState } from "react";
import Body from "../../components/Body/Body";
import DataTableWrapper from "../../components/CommonComponents/Table/DataTableWrapper";
import { getReportbyReportName, getReportPaginationDetails, uploadCibilBorrowersData } from "../../services/report.service";
import {
  ActionIcon,
  Box,
  Text,
  Tooltip,
  SegmentedControl,
} from "@mantine/core";
import Select from 'react-select';
import { Download, Upload } from "tabler-icons-react";
import { useQuery } from "@tanstack/react-query";
import { DEFAULT_SUPPORTED_EXCEL_TYPES, PDF_TEXT_MESSAGE } from '../../constants';
import { generateReportHeaders } from "./generateReportHeaders";
import useSystemConfigStore from "../../store/systemConfigStore";
import { getReportObjectByReportName } from "./utils/getReportStateByName";
import { openFile } from "../../utils/openFile";
import { lastThreeYearsAndMonths } from "../../utils/getMonthAndYearAsList";
import EntityFilter from "../../components/CommonComponents/Filter/EntityFilter";
import { ReportFilterKey } from "../../constants/symbols";
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import AddFile from "../../components/CommonComponents/FileUpload/FileUpload";
import { reportDownload } from "./reportDownload/reportDownload";

const CibilReport = () => {
  const reportName = 'bureau-report';
  const reportStateObj = getReportObjectByReportName(reportName);
  const systemDate = useSystemConfigStore(store => store.config);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(reportStateObj?.defaultValue);
  const [records, setRecords] = useState({ label: "Show 10", value: 10 });
  const [selectedYear, setSelectedYear] = useState();
  const [fileOpen, setFileOpen] = useState(false);
  const [fileLoading, setFileLoading] = useState({});
  const formData = new FormData();
  const [searchData, setSearchData] = useState({
    download_csv: "no",
  });

  const getYearsList = lastThreeYearsAndMonths({ type: 'year', date: systemDate?.current_system_date });
  const getMonthsWithYearsList = lastThreeYearsAndMonths({ date: systemDate?.current_system_date });

  /** used to change the page to 1 while the search us used
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1);
  }, [search]);

  /** used to get the report by sending the report name and from_date, to_date and csv */
  const { data: reportData = {}, isLoading, isFetching } = useQuery(
    [`report-details-${reportStateObj?.report_name}`, status, searchData, page, search, records],
    ({ signal }) =>
      getReportbyReportName(
        status,
        { ...searchData, cibil_report_date: searchData?.cibil_report_date?.replace(/-/g, '') },
        { page, search, records: records?.value },
        signal,
      ),
    {
      select: (data) => {
        return {
          ...data,
          headers: generateReportHeaders(data?.headers),
        };
      },
      enabled: Boolean(systemDate?.current_system_date && searchData?.cibil_report_date),
      refetchOnWindowFocus: false,
    }
  );

  /** used to get the page count and total records */
  const { data: paginationDetails = {} } = useQuery(
    [`pagination-${reportStateObj?.report_name}`, status, search, records, searchData],
    ({ signal }) => getReportPaginationDetails(
      {
        report_name: status,
        search,
        records: records?.value
      },
      { ...searchData, cibil_report_date: searchData?.cibil_report_date?.replace(/-/g, '') },
      signal,
    ),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(systemDate?.current_system_date && searchData?.cibil_report_date),
    }
  )

  /** used to set the default date as system date when the system date context is loaded */
  useEffect(() => {
    if (systemDate?.current_system_date) {
      let result = lastThreeYearsAndMonths({ date: systemDate?.current_system_date });
      setSearchData({
        cibil_report_date: result?.filter(item => item.year === new Date(systemDate?.current_system_date).getFullYear())[0]?.value,
        download_csv: "no",
      })
      setSelectedYear(new Date(systemDate?.current_system_date).getFullYear());
    }
  }, [systemDate?.current_system_date]);

  /** used to handle the date filter */
  const handleFilter = (e) => {
    setSearchData({
      cibil_report_date: e,
      download_csv: "no",
    });
  };

  /** used to handle the file download */
  const handleDownload = (type) => {
    if (reportData?.report_url) {
      openFile(reportData?.report_url);
    } else {
      let body = {
        download_csv: type === 'csv' ? 'yes' : 'no',
        download_text: type === 'text' ? 'yes' : 'no'
      }
      setFileLoading({ download: true });
      getReportbyReportName(status,
        { ...searchData, cibil_report_date: searchData?.cibil_report_date?.replace(/-/g, ''), ...body },
        { page, search, records: records?.value }
      )
        .then((res) => {
          displayNotification({
            message: 'Report will download shortly',
            variant: 'warning',
            loading: true,
            id: 'report-loading',
            autoClose: false
          });
          reportDownload({id: res?.report_download_id})
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: "error" });
        })
        .finally(() => {
          setFileLoading({ download: false });
        });
    }
  };

  const handleFileUpload = (fileUpload, setLoading) => {
    setFileLoading({ upload: true });
    formData.append("file", fileUpload[0]);
    if (!fileUpload?.length) {
      displayNotification({
        message: "Please select any file to upload",
        variant: 'error',
      });
      setLoading(false);
    } else {
      uploadCibilBorrowersData(formData)
        .then((res) => {
          displayNotification({ message: res?.message, variant: 'success' });
          openFile(res?.report_url);
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
          setFileOpen(false);
          setFileLoading({ upload: false });
        });
    }
  };

  const tableAction = (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
      {reportData?.data?.length ? (
        <>
          <Tooltip
            label={<Text size={"xs"}>Download Report</Text>}
            color={"teal"}
            transition="pop"
            transitionDuration={300}
            withArrow
          >
            <ActionIcon
              onClick={() => handleDownload('csv')}
              loading={fileLoading?.download}
            >
              <Download color={"#40c057"} />
            </ActionIcon>
          </Tooltip>
          {status === 'cibil_borrower' ?
            <Tooltip
              label={<Text size={"xs"}>Upload Report</Text>}
              color={"teal"}
              transition="pop"
              transitionDuration={300}
              withArrow
            >
              <ActionIcon
                onClick={() => setFileOpen(true)}
                loading={fileLoading?.upload}
              >
                <Upload color={"#40c057"} />
              </ActionIcon>
            </Tooltip>
            : null
          }
        </>
      ) : null}

      <EntityFilter
        value={searchData}
        onChange={v => {
          setSearchData(oldSearchData => ({
            ...oldSearchData,
            entity_id: v === 'all' ? undefined : v
          }))
        }}
      />

      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
        <Box sx={{ width: 130 }}>
          <Select
            options={getYearsList || []}
            value={getYearsList?.find(e => e.value === selectedYear)}
            onChange={(e) => { setSelectedYear(e?.value); setSearchData(old => ({ ...old, cibil_report_date: null })) }}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999, }),
              option: (provided) => ({ ...provided, fontSize: 13, padding: '5px 15px 5px 15px' }),
              control: (provided) => ({ ...provided, fontSize: 13, minHeight: 0 })
            }}
          />
        </Box>
        <Box sx={{ width: 130 }}>
          <Select
            options={getMonthsWithYearsList?.filter(e => e.year === selectedYear) || []}
            value={getMonthsWithYearsList?.find(e => e.value === searchData?.cibil_report_date) || ''}
            // clearValue={!searchData?.to_date ? true : false}
            onChange={(e) => { handleFilter(e?.value) }}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999, }),
              option: (provided) => ({ ...provided, fontSize: 13, padding: '5px 15px 5px 15px' }),
              control: (provided) => ({ ...provided, fontSize: 13, minHeight: 0 })
            }}
            noOptionsMessage={() => <Text size={'xs'}>Please Select Year</Text>}
          />
        </Box>
      </Box>
    </Box>
  );

  return (
    <Body background={false}>
      <DataTableWrapper
        title={reportStateObj?.title + " Report"}
        localKey={ReportFilterKey[reportStateObj?.header]}
        noDataText={searchData?.cibil_report_date ? `No ${reportStateObj?.title} data found on ${getMonthsWithYearsList?.find(e => e.value === searchData?.cibil_report_date)?.label}` : `Please Select Valid date to get data.`}
        noDataSubText={`This Section will contain list ${reportStateObj?.title} details.`}
        actions={tableAction}
        addDataOption={false}
        rowData={reportData?.data || []}
        styles={{ overflowX: "auto", whiteSpace: "nowrap", maxWidth: "100vw" }}
        columnData={reportData?.headers || []}
        useApiPagination
        totalNoOfPages={paginationDetails?.total_number_of_pages}
        totalNoOfRecords={paginationDetails?.total_number_of_records}
        page={page}
        options={
          <SegmentedControl
            value={status}
            onChange={setStatus}
            size={"md"}
            styles={(theme) => ({
              root: {
                background: "white",
                padding: "3px",
              },
              label: {
                fontSize: "12px",
              },
            })}
            color={'green'}
            data={reportStateObj?.options}
          />
        }
        setPage={setPage}
        apiSearch={setSearch}
        rowLength={records}
        setRowLength={setRecords}
        loading={isLoading || isFetching}
      />

      <AddFile
        open={fileOpen}
        setOpen={setFileOpen}
        acceptedFileTypes={[...DEFAULT_SUPPORTED_EXCEL_TYPES]}
        callback={handleFileUpload}
      />
    </Body>
  );
};

export default CibilReport;
