import { createStyles, Paper } from '@mantine/core'
import React from 'react'

const useStyles = createStyles({
  main: {
    border: '1px solid #D0E6FF',
    backgroundColor: 'rgba(208, 230, 255, 0.3)',
    // paddingTop: '5px'
  }
})

const DataViewerWapper = ({ children, ...props }) => {
  const { classes } = useStyles()

  return (
    <Paper radius="xs" p="md" className={classes.main} {...props}>
        {children}
    </Paper>
  )
}

export default DataViewerWapper