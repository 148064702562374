import {
  Grid,
  Box,
  Text,
  Title,
  Skeleton,
  Tooltip,
  Group,
  Progress,
  Badge,
  createStyles,
  Switch,
  Button,
} from "@mantine/core";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getCustomerDetailsById } from "../../services/master.service";
import { COLORS } from "../../constants";
import Nums from "../Body/Nums";
import { dateFormat } from './../DateFormat/dateFormat';
import DisplayAmountComp from "../CommonComponents/DataDisplay/DisplayAmountComp";
import ScoreCardComp from "./ScoreCardComp";
import { ArrowRight } from "tabler-icons-react";
import TrancheDetails from "./TrancheDetails";
import { getUtilizationPercentageDetails } from "../../services/customer.service";

const useStyles = createStyles({
  card: {
    background: COLORS.white,
    borderRadius: "5px",
    border: `1px solid ${COLORS.border.light}`,
  },
  amountCard: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'column'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  amountCardInner: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '20px',
    justifyContent: 'space-between'
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  applicationCard: {
    background: COLORS.white,
    borderRadius: '5px',
    border: `1px solid ${COLORS.border.light}`,
    cursor: 'pointer',
    position: 'relative',
    transition: 'all 300ms ease-in-out',
    '&:hover': {
      boxShadow: 'rgba(119, 119, 119, .6) 0px 14px 12px -10px',
      background: 'rgba(246, 255, 246, 1)',
      border: `1px solid rgba(20, 161, 66, 1)`,
    }
  },
  rightSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  textOverflow: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
})

/**
 * DisplayData component displays label and value of a data point.
 *
 * @param {string} label - value of label
 * @param {string} value - value
 * @param {boolean} isLoading - whether the data is being loading or not
 */
const DisplayData = ({ label, value, type, isLoading }) => {
  const { classes } = useStyles();
  return (
    <>
      {
        isLoading ?
          <Box mx={'sm'}>
            < Skeleton size="md" height={10} width={'50%'} />
            <Skeleton mt="xs" height={10} />
          </Box > :
          <Box mx={'sm'}>
            <Text size={'12px'} color={COLORS.text.light2} className={classes?.textOverflow}>{label}</Text>
            {type === 'amount' ? <DisplayAmountComp value={value} styles={{ fontSize: '14px' }} /> : <Title order={6} className={classes?.textOverflow}>{value || '-'}</Title>}
          </Box>}
    </>
  )
}

const CustomerDetailsCard = ({ id, data, setSideDrawerOpen, trancheDetails, isCheck, setIsCheck }) => {

  const { classes } = useStyles();
  const [modalObj, setModalObj] = useState({})

  const utilizationPercentageQuery = useQuery({
    queryKey: ['utilization-percentage', isCheck, id],
    // if isCheck is true then 12 is send to api to get last 12months data. 
    queryFn: () => getUtilizationPercentageDetails(id, isCheck ? 12 : null),
  });

  const { data: result = {}, isLoading } = useQuery(
    ["customer-details", id],
    () => getCustomerDetailsById(id),
    {
      enabled: Boolean(id),
      refetchOnWindowFocus: false,
    }
  );

  const customerDetailsHeaders = [
    {
      label: 'Customer Code',
      value: result?.customer?.customer_code,
    }, {
      label: 'Pincode',
      value: result?.customer?.pincode,
    }, {
      label: 'Business Type',
      value: result?.customer?.business_type,
    }, {
      label: 'Property Ownership',
      value: result?.customer?.property_ownership,
    }, {
      label: 'State',
      value: result?.customer?.state,
    }, {
      label: 'Zone',
      value: result?.customer?.zone,
    }, {
      label: 'Region',
      value: result?.customer?.region,
    }, {
      label: 'OMC Name',
      value: result?.omc_details?.omc_name,
    }
  ];

  const trancheDetailsHeaders = [
    {
      label: 'No. of Tranches',
      value: trancheDetails?.no_of_tranches,
    }, {
      label: 'Cum.Disbursed Amount',
      value: trancheDetails?.cumulative_disbursed_amount,
      type: "amount"
    }, {
      label: 'Status',
      value: trancheDetails?.status?.toUpperCase(),
    }, {
      label: 'Utilization (%)',
      value: `${utilizationPercentageQuery?.data?.utilized_percentage || '0'} %`
    }
  ]

  const trancheRepayments = [
    {
      label: 'LT 3 Days',
      value: trancheDetails?.repayment_behavior?.lt_3_days,
    }, {
      label: '3-7 Days',
      value: trancheDetails?.repayment_behavior?.from_4_to_7_days,
    }, {
      label: '7-14 Days',
      value: trancheDetails?.repayment_behavior?.from_8_to_14_days,
    }, {
      label: '14-30 Days',
      value: trancheDetails?.repayment_behavior?.from_15_to_30_days,
    }, {
      label: '30-60 Days',
      value: trancheDetails?.repayment_behavior?.from_31_to_60_days,
    }, {
      label: 'GT 60 Days',
      value: trancheDetails?.repayment_behavior?.gt_60_days,
    }
  ]

  return (
    <>
      <Grid>
        <Grid.Col span={12}>
          <Grid>
            <Grid.Col span={4}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text mb={'2px'} size={'xs'} color={'gray'}>
                  Customer Information
                </Text>
                <Button compact onClick={() => setModalObj((old) => ({ ...old, modal: true }))} size="xs" variant="subtle" rightIcon={<ArrowRight />}>View Sales & Bureau</Button>
              </Box>
              <Box className={classes.card} sx={{ minHeight: 220 }}>
                <Grid my={'2px'}>
                  {customerDetailsHeaders?.map((item, i) => (
                    <Grid.Col span={6} key={i}>
                      <DisplayData isLoading={isLoading} label={item?.label} value={item?.value} />
                    </Grid.Col>
                  ))}
                </Grid>
              </Box>
            </Grid.Col>
            <Grid.Col span={4}>
              <Box sx={{ display: "flex", justifyContent: "space-between", marginRight: '5px' }}>
                <Text mb={'2px'} size={'xs'} color={'gray'}>
                  Tranche Details
                </Text>
                <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                  <Text size={'xs'} color={COLORS.gray(40)}>Last 12 Months</Text>
                  <Switch size="xs" checked={isCheck} onChange={(event) => setIsCheck(event.currentTarget.checked)} />
                </Box>
              </Box>
              <Box className={classes.card} sx={{ minHeight: 220 }}>
                <Grid my={'2px'}>
                  <Grid.Col span={6} p={0} m={0}>
                    {trancheDetailsHeaders?.map((item, i) => (
                      <Grid.Col span={12} key={i} m={0}>
                        <DisplayData isLoading={isLoading || utilizationPercentageQuery?.isLoading} label={item?.label} value={item?.value} type={item?.type} />
                      </Grid.Col>
                    ))}
                  </Grid.Col>
                  <Grid.Col span={6} p={0} m={0} mt={'8px'}>
                    <Title order={6} sx={{ color: COLORS.text.light2, textDecoration: 'underline' }}>Repayment Behavior</Title>
                    <Box mr={'lg'}>
                      {
                        trancheRepayments?.map((item, i) => (
                          <Group mt={'xs'} position="apart">
                            <Text size={'xs'} className={classes?.textOverflow}>{item?.label}</Text>
                            <Text size={'xs'} sx={{ fontWeight: 600 }}>{item?.value ? `${item?.value}%` : '-'}</Text>
                          </Group>
                        ))
                      }
                    </Box>
                  </Grid.Col>
                </Grid>
              </Box>
            </Grid.Col>
            <Grid.Col span={4}>
              <Box className={classes.flexCenter}>
                <Text mb={'2px'} color={'gray'} size={'xs'}>
                  Applications
                </Text>
                <Badge color="green">{data?.length}</Badge>
              </Box>
              {data?.map((item, index) => (
                <Box className={classes.applicationCard} onClick={() => setSideDrawerOpen({ modal: true, data: item })}>
                  <Box key={index} px={'sm'} pt={'5px'}>
                    <Group position="apart" sx={{ alignItems: 'center' }}>
                      <Text color={'#4b4b4b'} sx={{ fontWeight: '600' }} size={'20px'}><Nums value={item?.approved_amount} /></Text>
                      <Box mt={'3px'} className={classes.rightSection}>
                        <Tooltip label={`Renewal on ${dateFormat(item?.agreement_start_date, 'MMM YYYY')}, (${item?.agreement_day_count} days remaining)`} withArrow>
                          <Text size={'12px'} sx={{ fontWeight: 500, color: 'gray' }}>
                            {dateFormat(item?.agreement_start_date, 'DD MMM YYYY')}
                          </Text>
                        </Tooltip>
                        <Text size={'10px'} sx={{ color: 'gray' }}>
                          Agreement started at
                        </Text>
                      </Box>
                    </Group>
                    <Group position="apart" my={'xs'}>
                      <Text size={'sm'}>{item?.product_name}</Text>
                      <Text size={'12px'} color={'gray'}>
                        {`CUSTAPPID000${item?.customer_application_id}`}
                      </Text>
                    </Group>
                  </Box>
                  <Progress
                    size="xs"
                    radius={0}
                    sx={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}
                    sections={[
                      { value: (item?.used_amount / item?.approved_amount) * 100, color: 'red', tooltip: 'Used Amount' },
                      { value: (item?.available_amount / item?.approved_amount) * 100, color: 'green', tooltip: 'Available Amount' },
                    ]}
                  />
                </Box>
              ))}
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
      <Grid mt={'md'}>
        <Grid.Col span={12}>
          {/* used to display last 10 tranche details */}
          <TrancheDetails customerId={id} />
        </Grid.Col>
      </Grid>
      <ScoreCardComp modalObj={modalObj} setModalObj={setModalObj} customerData={result?.customer} id={id} />
    </>
  );
};

export default CustomerDetailsCard;
