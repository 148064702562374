import {
    Archive,
    Briefcase,
    BuildingBank,
    Cash,
    CircleX,
    CurrencyRupee,
    Dashboard,
    FileInvoice,
    ReportMoney,
    ReportSearch,
    Wallet,
    ClipboardList,
    ClipboardCheck,
    Tree
} from 'tabler-icons-react';
import { SpotlightProvider } from "@mantine/spotlight";
import { useNavigate } from "react-router-dom";
import { getAllReports } from '../pages/Report/utils/getReportStateByName';

const reportsList = getAllReports();

export const SpotlightRoutes = ({children}) => {
  const navigate = useNavigate();
  const actions = [
    {
      title: "Dashboard",
      onTrigger: () => navigate('/'),
      group: 'Navigation',
      icon: <Dashboard size={22} />,
    },
    {
      title: "Fee Invoice",
      group: 'Invoice',
      onTrigger: () => navigate('/fee-invoice'),
      icon: <ReportMoney size={22} />,
    },
    {
      title: "Tranches",
      group: 'Disbursement',
      onTrigger: () => navigate('/tranche'),
      icon: <BuildingBank size={22} />,
    },
    {
      title: "Disb Memo",
      group: 'Disbursement',
      onTrigger: () => navigate('/disb'),
      icon: <Cash size={22} />,
    },
    {
      title: "Disb Batch",
      group: 'Disbursement',
      onTrigger: () => navigate('/batch-disb'),
      icon: <Tree size={22} />,
    },
    {
      title: "Payments",
      group: 'Disbursement',
      onTrigger: () => navigate('/payment'),
      icon: <CurrencyRupee size={22} />,
    },
    {
      title: "Manual Invoice",
      group: 'Invoice',
      onTrigger: () => navigate('/invoice-manual'),
      icon: <ReportMoney size={22} />,
    },
    // {
    //   title: "Auto Invoice",
    //   group: 'Invoice',
    //   onTrigger: () => navigate('/invoice-auto'),
    //   icon: <ArrowAutofitContent size={22} />,
    // },
    {
      title: "Receipts",
      group: 'Receipts',
      onTrigger: () => navigate('/receipt'),
      icon: <ClipboardCheck size={22} />,
    },
    {
      title: "Unidentified Receipts",
      group: 'Receipts',
      onTrigger: () => navigate('/unidentified-receipt'),
      icon: <ClipboardList size={22} />,
    },
    {
      title: "Foreclosure",
      group: 'Navigation',
      onTrigger: () => navigate('/foreclosure'),
      icon: <ReportMoney size={22} />,
    },
    {
      title: "Waiver",
      group: 'Navigation',
      onTrigger: () => navigate('/waiver'),
      icon: <Archive size={22} />,
    },
    {
      title: "Cancellation",
      group: 'Navigation',
      onTrigger: () => navigate('/cancellation'),
      icon: <CircleX size={22} />,
    },
    {
      title: "Report",
      group: 'Reports',
      onTrigger: () => navigate('/report'),
      icon: <ReportSearch size={22} />,
    },
    {
      title: "Historical Report",
      group: 'Reports',
      onTrigger: () => navigate('/historical-report'),
      icon: <Briefcase size={22} />,
    },
    {
      title: "Payment History",
      group: 'Navigation',
      onTrigger: () => navigate('/payment-history'),
      icon: <Wallet size={22} />,
    },
    {
      title: "SOA",
      group: 'Reports',
      onTrigger: () => navigate('/soa'),
      icon: <FileInvoice size={22} />,
    },
  ];

  reportsList.forEach((item) => {
    actions.push({
      title: item.label,
      description: item.label,
      onTrigger: () => navigate(item.href, item.hrefData),
      group: "Report",
      icon: <item.icon size={22} />
    })
  })

  return(
    <SpotlightProvider actions={actions} limit={7}>
      {children}
    </SpotlightProvider>
  )
}
