import React, { useState } from "react";
import { getPassbookDetails } from "../../services/report.service";
import { PDF_TEXT_MESSAGE } from "../../constants";
import { customerSearch } from "../../services/imd.service";
import Body from "../../components/Body/Body";
import DataTableWrapper from "../../components/CommonComponents/Table/DataTableWrapper";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import Nums from "../../components/Body/Nums";
import { ArrowNarrowRight, Download, Search } from "tabler-icons-react";
import { Button, Center, FocusTrap } from "@mantine/core";
import { Text, Image, ActionIcon, Box, Autocomplete, Tooltip } from "@mantine/core";
import { createColumnHelper } from "@tanstack/react-table";
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { useQuery } from "@tanstack/react-query";
import { getReportObjectByReportName } from "./utils/getReportStateByName";
import { openFile } from "../../utils/openFile";

const PassbookReport = () => {
  const reportStateObj = getReportObjectByReportName("passbook-report")
  const columnHelper = createColumnHelper();
  const [reportDownloadLoading, setReportDownloadLoading] = useState(false);
  const [searchedValue, setSearchedValue] = useState("");
  const [selectedValue, setSelectedValue] = useState();

  /** getting the passbook details by sending the customer id */
  const { data: passbookDetails = [], isLoading } = useQuery(
    [`passbook-${selectedValue?.id}`, selectedValue?.id],
    () => getPassbookDetails(selectedValue?.id),
    {
      enabled: Boolean(selectedValue?.id),
      select: (data) => {
        return data?.data;
      },
      refetchOnWindowFocus: false,
    }
  );

  const { data: onSearchData = [], isFetching } = useQuery(
    ["search-data", searchedValue],
    /*
     * sending the signal to cancel the per pending api call
     * signal will contain the abort signal
     */
    ({ signal }) => customerSearch(searchedValue, "all", signal),
    {
      /** this api call will take place when the searched value length should be greater then of 2 */
      enabled: Boolean(searchedValue?.length > 2),
      refetchOnWindowFocus: false,
    }
  );

  /** handle the file download */
  const handleDownload = () => {
    setReportDownloadLoading(true);
    /** when the user click the download icon then we will call the
     * passbook api with the query parameter of download_csv as yes
     * then api will send the response as url in passbook url key.
     */
    getPassbookDetails(selectedValue?.id, { download_csv: "yes" })
      .then((res) => {
        displayNotification({ message: PDF_TEXT_MESSAGE?.report, variant: 'success' });
        openFile(res?.passbook_url);
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setReportDownloadLoading(false);
      });
  };

  const columnData = [
    columnHelper.accessor("customer_id", {
      header: "Customer Id",
    }),
    columnHelper.accessor("transaction_date", {
      header: "Transaction Date",
      cell: (value) => dateFormat(value.getValue()),
    }),
    columnHelper.accessor("narrative", {
      header: "Narrative",
    }),
    columnHelper.accessor("disbursement", {
      header: "Disbursement",
      cell: (value) => <Nums value={value.getValue()} />,
    }),
    columnHelper.accessor("repayment", {
      header: "Repayment",
      cell: (value) => <Nums value={value.getValue()} />,
    }),
  ];

  /** here we are passing the download icon and search field to the table */
  const tableAction = (
    <>
      <Button
        onClick={handleDownload}
        leftIcon={<Download color={"#40c057"} />}
        variant={"outline"}
        color={"green"}
        loading={reportDownloadLoading}
      >
        Download
      </Button>
      <Autocomplete
        icon={
          <ActionIcon loading={isFetching}>
            <Search size={18} stroke-width={1.5} />
          </ActionIcon>
        }
        value={searchedValue}
        /** this prop will trigger when the user select the option in drop down */
        onItemSubmit={(value) => {
          setSelectedValue(value);
        }}
        onChange={(value) => {
          setSearchedValue(value);
        }}
        rightSection={
          <Tooltip label={'Select the value to search'} color={'gray'} withArrow>
            <Center>
              <ArrowNarrowRight size={18} color={"blue"} stroke-width={1.5} />
            </Center>
          </Tooltip>
        }
        data={onSearchData}
        placeholder="Search by customer Id / Name"
        filter={(value, item) =>
          item.value.includes(value.trim()) ||
          item.label.toLowerCase().includes(value.toLowerCase().trim())
        }
      />
    </>
  );

  return (
    <Body background={false}>
      {selectedValue?.id ? (
        <DataTableWrapper
          title={reportStateObj?.title + " Report"}
          filter={false}
          noDataText={`No ${reportStateObj?.title} yet!`}
          noDataSubText={`This Section will contain list ${reportStateObj?.title} details.`}
          actions={tableAction}
          addDataOption={false}
          searchOption={false}
          rowData={passbookDetails}
          styles={{
            overflowX: "auto",
            whiteSpace: "nowrap",
            maxWidth: "100vw",
          }}
          columnData={columnData}
          loading={isLoading}
        />
      ) : (
        <Box>
          <Box
            mt="md"
            p="xl"
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 10,
              height: "60vh",
            }}
          >
            <Image
              src="https://i.imgur.com/A6KRQAV.png"
              width={150}
              height={100}
              radius="md"
            />
            <Box sx={{ width: "50%" }}>
              <Text size="sm" sx={{ color: "rgb(0,0,0,0.4)" }}>
                {
                  "Please search the customer name or id to get the Passbook details"
                }
              </Text>
              <FocusTrap active={true}>
                <Autocomplete
                  icon={
                    <ActionIcon loading={isFetching}>
                      <Search size={18} stroke-width={1.5} />
                    </ActionIcon>
                  }
                  value={searchedValue}
                  /** this prop will trigger when the user select the option in drop down */
                  onItemSubmit={(value) => {
                    setSelectedValue(value);
                  }}
                  onChange={(value) => {
                    setSearchedValue(value);
                  }}
                  rightSection={
                    <ArrowNarrowRight size={18} color={"blue"} stroke-width={1.5} />
                  }
                  data={onSearchData}
                  placeholder="Search by customer Id / Name"
                  filter={(value, item) =>
                    item.value.includes(value.trim()) ||
                    item.label.toLowerCase().includes(value.toLowerCase().trim())
                  }
                  rightSectionWidth={42}
                />
              </FocusTrap>
            </Box>
          </Box>
        </Box>
      )}
    </Body>
  );
};

export default PassbookReport;
