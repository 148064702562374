import {
  Box,
  Center,
  createStyles,
  Paper,
  Text,
  Title,
  Tooltip,
  Loader,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { SIZES } from "../../constants";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import { VictoryPie } from "victory";
import { DatePickerInput } from "@mantine/dates";
import useSystemConfigStore from "../../store/systemConfigStore";

const createdStyles = createStyles({
  mainComp: {
    padding: SIZES.base * 2,
    position: "relative",
  },
  stats1: {
    cursor: "pointer",
    padding: SIZES.base * 2,
    ":hover": {
      backgroundColor: "rgba(255, 99, 72, 0.1)",
      borderRadius: 8,
    },
  },
  stats2: {
    cursor: "pointer",
    padding: SIZES.base * 2,
    ":hover": {
      backgroundColor: "rgba(255, 165, 0, 0.1)",
      borderRadius: 8,
    },
  },
  stats3: {
    cursor: "pointer",
    padding: SIZES.base * 2,
    ":hover": {
      backgroundColor: "rgba(197, 236, 209, 0.3)",
      borderRadius: 8,
    },
  },
  statsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  legend: { width: 4, height: 50, position: "absolute", borderRadius: 8 },
});

const DashboardLoanStats = ({ entity, type, dataProp }) => {
  const { classes } = createdStyles();
  const systemDate = useSystemConfigStore((store) => store.config);
  const [creditBookData, setCreditBookData] = useState([]);
  const [highlight, setHighlight] = useState(false);
  const [staticDate, setStaticDate] = useState(new Date());

  useEffect(() => {
    systemDate?.current_system_date &&
      setStaticDate(new Date(systemDate?.current_system_date));
  }, [systemDate]);

  const handleChartHighlight = (chart) => {
    setHighlight(highlight == chart ? false : chart);
  };

  const { data: creditBookDetails = {}, isFetching } = useQuery(
    [`credit-book-${type}`, entity, staticDate],
    () => dataProp(entity, dateFormat(staticDate, "YYYY-MM-DD")),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(staticDate),
      onSuccess: (data) => {
        setCreditBookData([
          {
            x: "overdue_principle",
            y: data?.overdue_principle,
            title: "Overdue",
          },
          { x: "due_principle", y: data?.due_principle, title: "Due" },
          {
            x: "total_current_principle",
            y: data?.total_current_principle,
            title: "Current",
          },
        ]);
      },
    }
  );

  return (
    <Paper className={classes.mainComp}>
      <Box sx={{ width: "fit-content", display: "flex", alignItems: "center" }}>
        <Title order={5} sx={{ color: "#4c4c4c" }}>
          Credit Book ({type}) -
        </Title>

        {type === "Current" ? (
          <Text
            sx={{ fontWeight: 600, opacity: 0.6, fontSize: "1.2rem" }}
            ml={4}
          >
            {dateFormat(creditBookDetails?.system_date, "DD MMM YYYY")}
          </Text>
        ) : (
          <Tooltip label={"Click to change Date"} color={"gray"} withArrow>
            {staticDate ? (
              <DatePickerInput
                size={"sm"}
                valueFormat="DD MMM YYYY"
                value={
                  staticDate
                    ? new Date(staticDate)
                    : systemDate?.current_system_date
                    ? new Date(systemDate?.current_system_date)
                    : new Date()
                }
                onChange={(value) => {
                  setStaticDate(value);
                }}
                maw={170}
                maxDate={
                  systemDate?.current_system_date
                    ? new Date(systemDate?.current_system_date)
                    : new Date()
                }
                styles={() => ({
                  input: {
                    border: "none",
                    fontWeight: 600,
                    opacity: 0.6,
                    fontSize: "1.2rem",
                    borderBottomStyle: "dashed",
                    textAlign: 'center',
                    lineHeight: 0,
                    height: 0,
                    minHeight: "30px",
                    borderRadius: "0",
                    cursor: "pointer",
                  },
                })}
                clearable={false}
              />
            ) : null}
          </Tooltip>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
        <Center sx={{ width: "60%", position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Title order={3} sx={{ fontSize: "1.6rem", fontWeight: 600 }}>
              {creditBookDetails?.total_principle || "0.00"}
            </Title>
            <Text>
              Loan Book <span style={{ fontSize: 10 }}>(in Crs)</span>
            </Text>
          </Box>
          {isFetching ? (
            <Loader size={285} mt={"35px"} mb={"34px"} color={"gray"} />
          ) : (
            <VictoryPie
              data={creditBookData}
              width="250"
              height="250"
              innerRadius={100}
              radius={({ datum }) => {
                if (datum?.title == highlight) {
                  return 70;
                } else {
                  return 75;
                }
              }}
              padAngle={1}
              cornerRadius={4}
              colorScale={["tomato", "orange", "#39d47a"]}
              style={{ labels: { display: "none" } }}
            />
          )}
        </Center>
        <Box className={classes.statsContainer}>
          <Box
            onClick={() => handleChartHighlight("Overdue")}
            className={classes.stats1}
          >
            <Box
              className={classes.legend}
              style={{ backgroundColor: "tomato" }}
            />
            <Box ml="md">
              <Title order={3} sx={{ fontSize: "1.7rem", fontWeight: 600 }}>
                {creditBookDetails?.overdue_principle || "0.00"}
              </Title>
              <Text sx={{ color: "tomato", marginTop: SIZES.base }}>
                Overdue <span style={{ fontSize: 10 }}>(in Crs)</span>
              </Text>
            </Box>
          </Box>
          <Box
            onClick={() => handleChartHighlight("Due")}
            className={classes.stats2}
          >
            <Box
              className={classes.legend}
              style={{ backgroundColor: "orange" }}
            />
            <Box ml="md">
              <Title order={3} sx={{ fontSize: "1.7rem", fontWeight: 600 }}>
                {creditBookDetails?.due_principle || "0.00"}
              </Title>
              <Text sx={{ color: "orange", marginTop: SIZES.base }}>
                Due <span style={{ fontSize: 10 }}>(in Crs)</span>
              </Text>
            </Box>
          </Box>
          <Box
            onClick={() => handleChartHighlight("Current")}
            className={classes.stats3}
          >
            <Box
              className={classes.legend}
              style={{ backgroundColor: "#39d47a" }}
            />
            <Box ml="md">
              <Title order={3} sx={{ fontSize: "1.7rem", fontWeight: 600 }}>
                {creditBookDetails?.total_current_principle || "0.00"}
              </Title>
              <Text sx={{ color: "#39d47a", marginTop: SIZES.base }}>
                Current <span style={{ fontSize: 10 }}>(in Crs)</span>
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default DashboardLoanStats;
