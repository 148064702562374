import { Autocomplete, Grid, Text } from '@mantine/core';
import React from 'react'
import { Controller } from 'react-hook-form';

export const ControllerAutoComplete = ({
  name,
  label,  
  data,
  placeholder,
  setData,
  onChangeAction = {},
  control,
  valueid,
  errors,
  ...props
}) => {
  return (
    <Grid sx={{ display: 'flex', alignItems: 'center' }}>
      <Grid.Col span={12} sx={{ minHeight: '57px', padding: '0 8px 0 8px' }}>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value = valueid, ...rest } }) => (
            <Autocomplete
              id={name}
              value = {value}
              placeholder={placeholder}
              onChange={(value) => {onChange(value)}}
              onItemSubmit={(value) => {setData(value)}}
              data={data}
              styles={{ menu: (provided) => ({ ...provided, zIndex: 9999, }), option: (provided) => ({ ...provided, fontSize: '13px', padding: '5px 15px 5px 15px' }), control: (provided) => ({ ...provided, fontSize: '13px'})}}
              error={errors ? true : false}
              {...props}
              {...rest}
            />
          )}
        />
        {errors && (
          <Text size={'xs'} sx={{ color: '#f13e3e' }}>
            {errors}
          </Text>
        )}
      </Grid.Col>
    </Grid>
  )
}
