import { getAPICall } from "./getAPICall";
import { postAPICall } from "./postAPICall";


export const generateGSTEbill = ({ imd_id }) => {
  /** used to generate e-invoice by id */
  return postAPICall(`imd/einvoice/${imd_id}`, { returnObject: true });
};

export const getAlleInvoice = ({page, search, records = 10, status, entity}) => {
  /** used to get all e-invoice */
  let url = `/einvoice/eligible-imds?page=${page}&records=${records}&einvoice_status=${status}`
  if(search) url = url + `&customer_id_name=${search}`;
  if(entity && entity !== 'all') url = url + `&entity_id=${entity}`;
  return getAPICall(url, { returnObject: true });
}

export const geteInvoiceStatus = ({ imd_id }) => {
  /** used to get e-invoice status */
  return getAPICall(`imd/einvoice/${imd_id}/status`);
}

export const cancelGSTeInvoice = ({ imd_id, body }) => {
  /** used to cancel e-invoice */
  return postAPICall(`/imd/einvoice/${imd_id}/cancelled`, { body });
}

export const getRemarkseInvoice = ({ imd_id }) => {
  /** used to cancel e-invoice */
  return getAPICall(`/imd/einvoice/${imd_id}/remarks`);
}