import React, { useState } from "react";
import {
  Modal,
  Box,
  Table,
  Tooltip,
  Text,
  Button,
  Title,
} from "@mantine/core";
import { Trash } from "tabler-icons-react";
import styled from "@emotion/styled";
import { COLORS } from "../../constants";
import Nums from "../../components/Body/Nums";
import { sumBy } from "lodash";
import { createDisbursementBatch } from "../../services/disbBatch.service";
import { useQueryClient } from '@tanstack/react-query';
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import DialogModal from "../../components/CommonComponents/Modal/DialogModal";

const DataTableWapper = styled.div`
  .trash {
    display: none;
  }
  .rowList:hover {
    .trash {
      display: block;
    }
  }
  .fixTableHead thead {
    position: sticky;
    top: 0;
  }
`;

const CreationBatchModal = ({
  open = true,
  onClose,
  data = [],
  callBack,
  clearAll,
}) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  if (!data?.length) {
    onClose();
  }

  const handleCreateDisbBatch = () => {
    var ids = [];
    data.map((item) => {
      ids.push(item.id);
    })
    if(ids?.length > 1) {
      createDisbursementBatch({"disbursement_id_list": ids})
      .then((res) => {
        console.log(res);
        displayNotification({message: res?.message || res, variant: 'success'});
      })
      .catch((err) => {
        displayNotification({message: err?.message || err, variant: 'error'});
      })
      .finally(() => {
        setLoading(false);
        clearAll();
        queryClient.invalidateQueries(['all-disb']);
      })
    } else {
      displayNotification({ message: 'Kindly add two or more items to create a batch', variant: 'warning'})
      setLoading(false);
    }
  }

  return (
    <DialogModal
      open={open}
      onClose={!loading && onClose}
      title="Disbursement Batch Creation"
      size={"65%"}
      transitionProps={{ transition: "pop", duration: 600, timingFunction: "ease" }}
      closeOnClickOutside={!loading}
      sx={{padding:0}}
      styles={(them) => ({
        root: {
          padding: 0,
        },
        modal: {
          padding: 0,

        }
      })}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '30px',
          marginBottom: '16px',
        }}
      >
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <Title order={2} sx={{lineHeight: '36px'}}>{data?.length}</Title>
          <Text size={12}>Selected</Text>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <Title order={2}>
            <Nums value={sumBy(data, function (n) {
              return parseFloat(n?.net_disbursement_amount);
            })} />
          </Title>
          <Text size={12}>Net Disb Amount</Text>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <Title order={2}>
            <Nums value={sumBy(data, function (n) {
              return parseFloat(n?.loan_amount);
            })} />
          </Title>
          <Text size={12}>Tranche Amount</Text>
        </Box>
      </Box>
      <Box sx={{ maxHeight: "300px", overflow: "scroll" }}>
        <DataTableWapper>
          <Table
            fontSize={"xs"}
            striped
            highlightOnHover
            className="fixTableHead"
          >
            <thead style={{ backgroundColor: COLORS.primary.light1 }}>
              <tr>
                <th>Tranche Code</th>
                <th>Customer Name</th>
                <th>Customer Code</th>
                <th>Scheme</th>
                <th style={{textAlign: 'right'}}>Tranche Amount</th>
                <th style={{textAlign: 'right'}}>Net Disb Amount</th>
                <th width={"50px"}></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((e, index) => (
                <tr key={index} className="rowList">
                  {console.log(e)}
                  <td>{e?.prospect_code}</td>
                  <td>{e?.customer_name}</td>
                  <td>{e?.customer_id}</td>
                  <td>{e?.product_name}</td>
                  <td style={{textAlign: 'right'}}>
                    <Nums value={e?.loan_amount} />
                  </td>
                  <td style={{textAlign: 'right'}}>
                    <Nums value={e?.net_disbursement_amount} />
                  </td>
                  <td>
                    <Tooltip
                      label={"Click to remove"}
                      color="gray"
                      withArrow
                      position="right"
                    >
                      <span className="trash">
                        <Trash
                          size={"13px"}
                          onClick={() => callBack(e)}
                          style={{ cursor: "pointer" }}
                          color={"red"}
                        />
                      </span>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </DataTableWapper>
      </Box>
      {/* <Divider /> */}
      <Box mt={"md"} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: '20px' }}>
          <Button variant="outline" onClick={() => {clearAll(); onClose();}}>
            Clear selected & Close
          </Button>
          <Button
            color="green"
            styles={{}}
            onClick={
              () => {
                setLoading(true);
                handleCreateDisbBatch()
              }
            }
            loading={loading}
          >
            {loading ? "Creating Batch..." : "Create Batch"}
          </Button>
        </Box>
      </Box>
    </DialogModal>
  );
};

export default CreationBatchModal;
