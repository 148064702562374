import { Tabs } from '@mantine/core';
import React from 'react';
import {
  MapPin,
  Sitemap,
  NewSection,
  BoxModel2,
  BrandReason
} from 'tabler-icons-react';
import FeeTable from './Fee/FeeTable';
import Modules from './ModulesPage/Modules';
import ProductTable from './Products/ProductTable';
import ReasonTabel from './Reason/ReasonTabel';
import RegionTable from './Region/RegionTable';
import Body from '../../../components/Body/Body';
import { IconShieldCancel, IconUserShield } from '@tabler/icons-react';
import MasterCancellation from './CancellationTable/MasterCancellation';

const Master = () => {
  return (
    <Body>

      <Tabs color='teal' defaultValue='product'>
        <Tabs.List>
          <Tabs.Tab value='product' icon={<Sitemap size={16} />}>
            Product
          </Tabs.Tab>
          <Tabs.Tab value='modules' icon={<IconUserShield size={16} />}>
            Modules
          </Tabs.Tab>
          <Tabs.Tab value='cancellation-modules' icon={<IconShieldCancel size={16} />}>
            Cancellation Periods
          </Tabs.Tab>
          <Tabs.Tab value='reason' icon={<BrandReason size={16} />}>
            Reason
          </Tabs.Tab>
          <Tabs.Tab value='region' icon={<MapPin size={16} />}>
            Regions
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='product' pt='xs'>
          <ProductTable />
        </Tabs.Panel>
        <Tabs.Panel value='modules' pt='xs'>
          <Modules />
        </Tabs.Panel>
        <Tabs.Panel value='cancellation-modules' pt='xs'>
          <MasterCancellation />
        </Tabs.Panel>
        <Tabs.Panel value='region' pt='xs'>
          <RegionTable />
        </Tabs.Panel>
        <Tabs.Panel value='fee' pt='xs'>
          <FeeTable />
        </Tabs.Panel>
        <Tabs.Panel value='reason' pt='xs'>
          <ReasonTabel />
        </Tabs.Panel>
      </Tabs>
    </Body>
  );
};

export default Master;
