import { Box, NavLink as NaviLink, Text } from '@mantine/core';
import React from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { Edit, User, Users, PremiumRights, CalendarOff, ChartAreaLine, Broadcast, Timeline, ClockHour1 } from 'tabler-icons-react';
import Body from '../../../components/Body/Body';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { action_id } from '../../../config/userRules';
import { isAllowed } from '../../../utils/roleBaseAccess';
import useAuthStore from '../../../store/authStore';

const SettingsLayout = () => {
  const location = useLocation();
  let test = location.pathname.split('/');
  const auth = useAuthStore(store => store.auth);

  // side nav for settings
  const navItems = [
    {
      label: 'Account',
      href: 'account',
      icon: <User size={16} />,
      isShow: isAllowed(auth?.group_id, '', action_id?.settings_nav?.account_view),
    },
    {
      label: 'Users',
      href: 'users',
      icon: <Users size={16} />,
      isShow: isAllowed(auth?.group_id, '', action_id?.settings_nav?.users_view),
    },
    {
      label: 'Data Config',
      href: 'data-config',
      icon: <Edit size={16} />,
      isShow: isAllowed(auth?.group_id, '', action_id?.settings_nav?.data_config_view),
    },
    {
      label: 'Fee',
      href: 'fee',
      icon: <PremiumRights size={16} />,
      isShow: isAllowed(auth?.group_id, '', action_id?.settings_nav?.fee_view),
    },
    {
      label: 'EOD',
      href: 'eod-details',
      icon: <ChartAreaLine size={16} />,
      isShow: isAllowed(auth?.group_id, '', action_id?.settings_nav?.eod_view),
    },
    {
      label: 'Triggers',
      href: 'triggers',
      icon: <Broadcast size={16} />,
      isShow: isAllowed(auth?.group_id, '', action_id?.settings_nav?.triggers),
    },
    {
      label: 'Holiday List',
      href: 'holiday-list',
      icon: <CalendarOff size={16} />,
      isShow: isAllowed(auth?.group_id, '', action_id?.settings_nav?.holiday_list),
    },
    {
      label: 'Office Timing',
      href: 'office-timing',
      icon: <ClockHour1 size={16} />,
      isShow: isAllowed(auth?.group_id, '', action_id?.settings_nav?.holiday_list),
    },
  ].map((item, index) => {
    return (
      <NavLink to={item?.href}>
        {
          item?.isShow &&
          <NaviLink
            label={<Text size='sm'>{item?.label}</Text>}
            sx={{ borderRadius: 4 }}
            icon={item?.icon}
            variant='subtle'
            active={test.includes(item.href)}
            color='indigo'
          />
        }
      </NavLink>
    );
  });

  return (
    <>
      <Body background={false}>
        <PageTitle title='Settings' />
      </Body>
      <Body background={false}>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box sx={{ width: '20%' }} py='lg' mt='xs'>
            {navItems}
          </Box>
          <Box sx={{ width: '80%' }} m='xs'>
            <Outlet />
          </Box>
        </Box>
      </Body>
    </>
  );
};

export default SettingsLayout;
