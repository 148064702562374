import React, { useState } from 'react'
import { createOfficeHoliday, deleteOfficeHoliday, updateOfficeHoliday } from '../../../../services/master.service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Box, Text, Textarea, Stack, Group, Button } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { displayNotification } from '../../../../components/CommonComponents/Notification/displayNotification';
import { dateFormat } from '../../../../components/DateFormat/dateFormat';

const OfficeHoliday = ({ type, apiValue = { date: new Date() }, onClose }) => {

  const [value, setValue] = useState(apiValue);

  const queryClient = useQueryClient();
  const updateHolidayQuery = useMutation((body) => updateOfficeHoliday(body));
  const createHolidayQuery = useMutation((body) => createOfficeHoliday(body));
  const deleteHolidayQuery = useMutation((body) => deleteOfficeHoliday(body));

  const updateOfficeHolidayCall = (value) => {
    const body = {
      "date": dateFormat(value?.date, 'YYYY-MM-DD'),
      "description": value?.description,
    }
    updateHolidayQuery?.mutate({ body, id: value?.id }, {
      onSuccess: (res) => {
        displayNotification({ message: res?.message, variant: 'success' })
      },
      onError: (e) => {
        displayNotification({ message: e || e.message, variant: 'error' })
      },
      onSettled: () => {
        queryClient.invalidateQueries(['office-holiday'])
        onClose()
      }
    })
  }

  const createOfficeHolidayCall = (value) => {
    const body = {
      "date": dateFormat(value?.date, 'YYYY-MM-DD'),
      "description": value?.description,
    }
    createHolidayQuery?.mutate({ body }, {
      onSuccess: (res) => {
        displayNotification({ message: res?.message, variant: 'success' })
      },
      onError: (e) => {
        displayNotification({ message: e || e.message, variant: 'error' })
      },
      onSettled: () => {
        queryClient.invalidateQueries(['office-holiday'])
        onClose()
      }
    })
  }

  const deleteOfficeHolidayCall = (value) => {
    deleteHolidayQuery?.mutate({ id: value?.id }, {
      onSuccess: (res) => {
        displayNotification({ message: res?.message, variant: 'success' })
      },
      onError: (e) => {
        displayNotification({ message: e || e.message, variant: 'error' })
      },
      onSettled: () => {
        queryClient.invalidateQueries(['office-holiday'])
        onClose()
      }
    })
  }

  return (
    <Box mt={'md'}>
      <Text size="sm" color={'gray'}>Date</Text>
      <DatePickerInput
        valueFormat="DD/MM/YYYY"
        value={new Date(value?.date)}
        onChange={(e) => setValue((old) => ({ ...old, date: e }))}
      />

      <Text
        mt={'md'}
        size="sm"
        color={'gray'}
      >
        Description
      </Text>
      <Textarea
        value={value?.description}
        onChange={(e) => setValue((old) => ({ ...old, description: e.target.value }))}
      />
      <Stack align='flex-end' mt={'xl'}>
        <Group>
          <Button variant='outline' onClick={onClose}>Cancel</Button>
          {type === 'edit' ? <Button variant='light' color='red' onClick={() => deleteOfficeHolidayCall(value)}>Delete</Button> : null}
          <Button color='teal' onClick={() => type === 'edit' ? updateOfficeHolidayCall(value) : createOfficeHolidayCall(value)} loading={type === 'edit' ? updateHolidayQuery.isLoading : createHolidayQuery.isLoading}>{type === 'edit' ? 'Update' : 'Create'}</Button>
        </Group>
      </Stack>
    </Box>
  )
}

export default OfficeHoliday