import { Table, Box, Modal, Group } from '@mantine/core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Body from '../../components/Body/Body';
import DataTableWrapper from '../../components/CommonComponents/Table/DataTableWrapper';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import Nums from '../../components/Body/Nums';
import {
  APPROVED,
  AUTO_APPROVED,
  CANCELLED,
  IN_APPROVAL,
  IN_REVIEW,
  REJECTED,
  CANCELLATION_REVIEW,
  CANCELLATION_APPROVAL,
  AUTO_CANCELLED
} from '../../constants/status';
import { createBulkReceipt, getAllBulkReceipts } from '../../services/bulkReceipt.service';
import { BULK_RECEIPT_SAMPLE_URL, COLORS } from '../../constants';
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification';
import { openFile } from '../../utils/openFile';
import FileUploadDownloadComp from '../../components/CommonComponents/FileUpDown/FileUploadDownloadComp';

const BulkReceipt = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const columnHelper = createColumnHelper();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [records, setRecords] = useState({ label: 'Show 10', value: 10 });
  const [errorData, setErrorData] = useState({ modal: false });
  const [fileObj, setFileObj] = useState({});
  const formData = new FormData();
  // get the search params
  const [searchParams, setSearchParams] = useSearchParams();
  const params = {}
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  /** used to change the page to 1 while the search us used 
   * because if we are in page 10 and we are searching something we will get the result in page 10
   */
  useEffect(() => {
    page != 1 && setPage(1)
  }, [search, searchParams.get("status")])

  const { data: receiptTable = [], isLoading } = useQuery(
    ['all-bulk-receipt', params, page, search, records],
    () => getAllBulkReceipts({ ...params, page, search, records: records?.value }),
    {
      enabled: Boolean(searchParams.get("status") && searchParams.get("status") !== "none"),
      refetchOnWindowFocus: false,
    }
  );

  const onRowClick = (i) => {
    if (i?.status_value == IN_APPROVAL || i?.status_value == IN_REVIEW) {
      navigate(`/bulk-receipt/review/${i?.id}`);
    } else if (i?.status_value == APPROVED || i?.status_value == AUTO_APPROVED) {
      navigate(`/bulk-receipt/approved/${i?.id}`);
    } else if (i?.status_value == REJECTED) {
      navigate(`/bulk-receipt/rejected/${i?.id}`);
    } else if ([CANCELLED, AUTO_CANCELLED]?.includes(i?.status_value)) {
      navigate(`/bulk-receipt/cancelled/${i?.id}`);
    } else if (i?.status_value == CANCELLATION_REVIEW || i?.status_value == CANCELLATION_APPROVAL) {
      navigate(`/bulk-receipt/cancellation/${i?.id}`);
    }
  };

  const onClickAction = (value) => {
    return (
      <span
        style={{ color: '#0063FF', cursor: 'pointer' }}
      >
        {value.getValue()}
      </span>
    );
  };

  const tableAction = (
    <Group mx={'4px'}>
      <FileUploadDownloadComp
        open={fileObj?.modal}
        onClose={() => setFileObj({ modal: false, menu: false })}
        uploadLoading={fileObj?.loading}
        setFileState={setFileObj}
        fileState={fileObj}
        onFileDownload={() => openFile(BULK_RECEIPT_SAMPLE_URL)}
        onFileUpload={(file) => handleFileUpload(file)}
        title="Bulk Receipt"
        label="Create Bulk Receipt"
        uploadText='Upload Bulk Receipt'
        uploadTextHelper='Once the file has been uploaded, bulk receipt will be created.'
      />
    </Group>
  );

  const columnData = [
    columnHelper.accessor('batch_code', {
      header: 'Receipt Code',
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor('receivable_count', {
      header: 'Receipt Count',
      cell: (value) => onClickAction(value),
    }),
    columnHelper.accessor('total_amount', {
      header: 'Receipt Amount',
      cell: (value) => <Nums value={value.getValue()} />,
    }),
    columnHelper.accessor('created_on', {
      header: 'Created Date',
      cell: (value) => dateFormat(value.getValue()),
    }),
    columnHelper.accessor('maker_name', {
      header: 'Created By',
    }),
  ];

  /**
 * Handles file upload and sets loading status.
 *
 * @param {FileList} fileUpload - List of files to be uploaded
 * @param {Function} setFileLoading - Function to set loading status
 */
  const handleFileUpload = (fileUpload) => {
    formData.append("file", fileUpload?.[0]);
    if (!fileUpload?.length) {
      displayNotification({ message: "Please select excel file to upload", variant: "warning" });
    } else {
      createBulkReceipt(formData)
        .then((res) => {
          displayNotification({ message: res?.message || res, variant: "success" });
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: "error" });
          if (e?.data?.length) {
            setErrorData({ modal: true, data: e?.data || [] });
          }
        })
        .finally(() => {
          setFileObj({});
          queryClient.invalidateQueries('all-bulk-receipt')
        });
    }
  };

  return (
    <Body background={false}>
      <DataTableWrapper
        title='Bulk Receipt'
        addDataOption={false}
        filter={false}
        rowData={receiptTable?.data}
        columnData={columnData}
        onRowClick={onRowClick}
        noDataText="No Bulk Receipt yet!"
        noDataSubText='This section will contain list of created Bulk Receipt details.'
        useApiPagination
        totalNoOfPages={receiptTable?.total_number_of_pages}
        totalNoOfRecords={receiptTable?.total_number_of_records}
        page={page}
        setPage={setPage}
        rowLength={records}
        setRowLength={setRecords}
        apiSearch={setSearch}
        showStatus
        module_name='bulk_receipt'
        addDataCallback={false}
        actions={tableAction}
        // when the status is none then the loading is set to false other wise it will dependent on isLoading
        loading={searchParams.get("status") !== "none" ? isLoading : false}
      />
      <Modal opened={errorData?.modal} onClose={() => setErrorData({})} size='65rem' centered title='Error Data'>
        <Box sx={{ maxHeight: '400px', overflow: 'hidden', overflowY: 'scroll', overflowX: 'scroll', marginTop: '20px' }}>
          <Table verticalSpacing='xs' fontSize='xs' horizontalSpacing="xl" sx={{ whiteSpace: "nowrap", maxWidth: "100%", display: 'inline-table' }}>
            <thead style={{ backgroundColor: COLORS.primary.light1, fontSize: '14px', position: 'sticky', top: 0 }}>
              <tr>
                <th>Customer Code</th>
                <th>Receipt Amount</th>
                <th>Receipt Date</th>
                <th>Instrument Type</th>
                <th>Payment Ref No</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              {errorData?.data?.map((i, index) => (
                <tr key={index}>
                  <td>{i?.customer_code}</td>
                  <td>{i?.receipt_amount}</td>
                  <td>{dateFormat(i.receipt_date, 'DD/MM/YYYY')}</td>
                  <td>{i.instrument_type?.toUpperCase()}</td>
                  <td>{i.payment_ref_no}</td>
                  <td>{i.comments ? i.comments : '-'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
      </Modal>
    </Body>
  );
};

export default BulkReceipt;
