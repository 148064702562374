const defaultTheme = {
  white: 'rgba(255, 255, 255, 1)',
  fontFamily: 'Poppins, sans-serif',
  black: '#323232',
  components: {
    Text: {
      styles: {
        root: { fontSize: 13, },
      },
    },
    Button: {
      styles: {
        root: { fontSize: 13 },
      },
    },
    Title: {
      styles: {
        root: { fontWeight: 600 },
      },
    },
    Checkbox: {
      styles: {
        label: { fontSize: 13 },
      },
    }
  },
  dateFormat: 'DD/MM/YYYY',
  headings: {
    fontFamily: "'Poppins', 'Open Sans', Roboto, sans-serif",
    fontWeight: 500,
    sizes: {
      h1: { fontSize: '30px', lineHeight: '35px' },
      h2: { fontSize: '1.5rem', lineHeight: '28px' },
      h3: { fontSize: '18px', lineHeight: '24px' },
      h4: { fontSize: '14px', lineHeight: '20px' },
      h5: { fontSize: '16px', lineHeight: '20px' },
      h6: { fontSize: '13px', lineHeight: '18px' },
    },
  },
};

export default defaultTheme;
