import { Navigate, Outlet, useLocation } from 'react-router-dom'
import MainLayout from '../layout/MainLayout'
import useAuthStore from '../store/authStore'

const ProtectedRoute = ({ path }) => {
  const { auth } = useAuthStore(store => ({
    auth: store?.auth
  }))
  const location = useLocation()
  /*
    This check using group_id is temproary once the contex is saved with just the access_token
    group_id will be replaced with access_token
  */
  if (
    auth?.permissions &&
    auth?.permissions
      ?.map((item) => {
        return item?.resource_name
      })
      .includes(path)
  ) {
    return (
      <MainLayout>
        <Outlet />
      </MainLayout>
    )
  } else if (auth?.group_id) {
    return <Navigate to="/unauthorized" />
  } else {
    return <Navigate to="/" state={{ from: location }} replace />
  }
}

export default ProtectedRoute
