import React, { useEffect, useState } from "react";
import { COLORS } from "../../constants";
import { CircleCheck, ArrowNarrowLeft } from "tabler-icons-react";
import { createStyles, Text, Title } from "@mantine/core";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import DialogModal from './../CommonComponents/Modal/DialogModal';

const useStyles = createStyles({
  main: {
    margin: "50px auto auto auto",
  },
  icon: {
    margin: "0 auto 0 auto",
    width: "fit-content",
    color: "#34925f",
  },
  navtext: {
    margin: "0 auto 0 auto",
    width: "fit-content",
    paddingTop: "20%",
    paddingBottom: "20px",
    color: "#1c7ed6",
  },
  labelid: {
    margin: "0 auto 0 auto",
    width: "fit-content",
    marginTop: "10px",
  },
});

const ModalDataViwer = ({
  label,
  href,
  hreftext = "Back To Table",
  title,
  action,
  opened,
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation()
  const [count, setCount] = useState(-1);

  useEffect(() => {
    opened == true && setCount(3);
  }, [opened]);

  const handleActions = () => {
    if(href) {
      location.state?.from && navigate(-1);
      navigate(href)
    }
    action && action();
  }

  useEffect(() => {
    let interval;
    if (count > 0) {
      interval = setInterval(() => setCount(count - 1), 1000);
    }
    if (count == 0) {
      handleActions()
    }
    return () => {
      clearInterval(interval);
    };
  }, [count]);

  return (
    <DialogModal centered opened={opened} onClose={() => {handleActions()}} size={"lg"}>
      <div className={classes.main}>
        <div className={classes.icon}>
          <CircleCheck
            color={COLORS.success}
            size={"100px"}
            strokeWidth={"1px"}
          />
        </div>
        <Title className={classes.icon}>{title}</Title>
        <Text className={classes.labelid}>{label}</Text>
        {(hreftext && href) || action ? (
          <Text className={classes.navtext}>
            <Text
              style={{
                color: "teal",
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                handleActions();
              }}
            >
              <ArrowNarrowLeft /> Back to Table {`(${count})`}
            </Text>
          </Text>
        ) : null}
      </div>
    </DialogModal>
  );
};

export default ModalDataViwer;
