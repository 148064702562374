import React, { useRef, useState } from 'react'
import { Button, Group, Text, TextInput, Title, Stack } from '@mantine/core';
import { COLORS } from '../../../../constants';
import dayjs from 'dayjs';
import Body from '../../../../components/Body/Body';
import { Check, Edit } from 'tabler-icons-react';
import ReactTable from '../../../../components/CommonComponents/Table/ReactTable';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getOfficeTiming, updateOfficeTiming } from '../../../../services/master.service';
import { createColumnHelper } from '@tanstack/table-core';
import { displayNotification } from '../../../../components/CommonComponents/Notification/displayNotification';
import DialogModal from '../../../../components/CommonComponents/Modal/DialogModal';

const TimeComponent = ({ onChange, apiTime, disable }) => {
  const ref = useRef();
  const [value, setValue] = useState(dayjs(`${dayjs().format('YYYY-MM-DD')} ${apiTime}`).format('HH:mm'))

  return (
    <TextInput
      ref={ref}
      type='time'
      size='xs'
      value={value}
      ml={6}
      w={150}
      disabled={disable}
      p={0}
      m={0}
      onChange={(e) => { onChange(e.target.value); setValue(dayjs(`${dayjs().format('YYYY-MM-DD')} ${e.target.value}`).format('HH:mm')); }}
    />
  )
}

const OfficeTiming = () => {
  const [officeTimeObj, setOfficeTimeObj] = useState({});
  const columnHelper = createColumnHelper();

  /** used to get the OFFICE holiday */
  const { data: officeTiming = [], refetch } = useQuery(
    ["office-timing"],
    () => getOfficeTiming(),
  );

  const updateOfficeTimingQuery = useMutation((body) => updateOfficeTiming(body));

  const updateOfficeTimingCall = (value) => {
    const body = [
      {
        "name": "CLOSE_TIME",
        "config_value": value?.end || officeTiming?.find(e => e?.name?.toLowerCase() === 'close_time')?.config_value
      },
      {
        "name": "START_TIME",
        "config_value": value?.start || officeTiming?.find(e => e?.name?.toLowerCase() === 'start_time')?.config_value
      }
    ]
    updateOfficeTimingQuery.mutate({ body }, {
      onSuccess: (res) => {
        displayNotification({ message: res?.message, variant: 'success' })
      },
      onError: (e) => {
        displayNotification({ message: e || e.message, variant: 'error' })
      },
      onSettled: () => {
        setOfficeTimeObj({})
        refetch()
      }
    });
  }

  const officeColumnData = [
    columnHelper.accessor("date", {
      header: "Date",
    }),
    columnHelper.accessor("description", {
      header: "Description",
    }),
  ];

  return (
    <Body>
      <Group position='apart'>
        <Title order={6}>Office Time</Title>
        {!officeTimeObj?.edit
          ? <Button variant='light' compact color='orange' rightIcon={<Edit size={14} />} onClick={() => setOfficeTimeObj({ edit: !officeTimeObj?.edit })}>Edit</Button>
          : <Button variant='light' compact color='teal' loading={updateOfficeTimingQuery?.isLoading} rightIcon={<Check size={14} />} onClick={() => setOfficeTimeObj((old) => ({ ...old, modal: true }))}>{updateOfficeTimingQuery?.isLoading ? '...saving' : 'Save'}</Button>
        }
      </Group>

      <Stack align='flex-start' mt={'md'} >
        <Group spacing={2}>
          <Text size={'xs'} color={COLORS.gray(50)}>
            Start Time:
          </Text>
          {!officeTimeObj?.edit ?
            <Text
              sx={{ cursor: 'pointer' }}
              ml={6}
              size={'xs'}
              color={COLORS.gray(70)}
              fw={'bold'}
            >
              {dayjs(`${dayjs().format('YYYY-MM-DD')} ${officeTiming?.find(e => e?.name?.toLowerCase() === 'start_time')?.config_value}`).format('hh:mm A')}
            </Text> :
            <TimeComponent apiTime={officeTiming?.find(e => e?.name?.toLowerCase() === 'start_time')?.config_value} onChange={(e) => setOfficeTimeObj((old) => ({ ...old, start: e }))} disable={officeTimeObj?.disable} />
          }
        </Group>

        <Group spacing={2} mt={'sm'}>
          <Text size={'xs'} color={COLORS.gray(50)}>
            End Time:
          </Text>
          {!officeTimeObj?.edit ?
            <Text
              sx={{ cursor: 'pointer' }}
              ml={6}
              size={'xs'}
              color={COLORS.gray(70)}
              fw={'bold'}
              onClick={() => setOfficeTimeObj({ edit: 'end' })}
            >
              {dayjs(`${dayjs().format('YYYY-MM-DD')} ${officeTiming?.find(e => e?.name?.toLowerCase() === 'close_time')?.config_value}`).format('hh:mm A')}
            </Text> :
            <TimeComponent apiTime={officeTiming?.find(e => e?.name?.toLowerCase() === 'close_time')?.config_value} onChange={(e) => setOfficeTimeObj((old) => ({ ...old, end: e }))} disable={officeTimeObj?.disable} />
          }
        </Group>
      </Stack>

      {/* <ReactTable
        title="Office Holiday"
        rowData={officeTimingList}
        columnData={officeColumnData}
        loading={officeTimingListLoading}
      /> */}

      <DialogModal
        open={officeTimeObj?.modal}
        onClose={() => setOfficeTimeObj({})}
        title={<Title order={5}>Are you sure!</Title>}
        withCloseButton={false}
        closeOnClickOutside={false}
        size='sm'
      >
        <Text>Are you sure you want to update the office timing</Text>
        <Stack align='flex-end'>
          <Group mt='md'>
            <Button variant='outline' color='red' onClick={() => setOfficeTimeObj({ modal: false })}>No</Button>
            <Button color='teal' onClick={() => { setOfficeTimeObj((old) => ({ ...old, modal: false, disable: true })); updateOfficeTimingCall(officeTimeObj) }} >Yes</Button>
          </Group>
        </Stack>
      </DialogModal>
    </Body>
  )
}

export default OfficeTiming