import { Box, Center, Group, LoadingOverlay, Radio, Table, Title } from '@mantine/core'
import React, { useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getAllModulesStatus, sendModuleStatusActiveInActive } from '../../../../services/module.service';
import { Check, User, Users } from 'tabler-icons-react';
import COLORS from '../../../../constants/colors';
import { displayNotification } from '../../../../components/CommonComponents/Notification/displayNotification';

const Modules = () => {

    const [loading, setLoading] = useState(false)
    const queryClient = useQueryClient();

    /** used to get all module status */
    const { data: moduleStatus = [] } = useQuery(
        ['all-module-stages'],
        () => getAllModulesStatus(),
        {            
            refetchOnWindowFocus: false
        }
    )

    /** this function is used to change the review level */
    const handleReviewLevelChange = (val) => {
        setLoading(true);
        const body = {
            status: 'review',
            is_active: val?.level,
            module_name: val?.module_name
        }
        sendModuleStatusActiveInActive(body)
            .then((res) => {
                displayNotification({message: 'Updated Successfully', variant: 'success'})
            })
            .catch((e) => {
                displayNotification({message: e?.message || e, variant: 'error'})
            })
            .finally(() => {
                queryClient.invalidateQueries(['all-module-stages']);
                setLoading(false);

            })
    }


    return (
        <>
            <Box>
                <Title order={6} mb={'lg'} mt={'md'}>All Module Review Levels</Title>                
                <div style={{ width: '100%', position: 'relative' }}>
                    <Table fontSize='xs'>
                        <thead style={{ backgroundColor: COLORS.primary.light1 }}>
                            <tr>
                                <th>
                                    Modules Name
                                </th>
                                <th style={{textAlign: 'center'}}>
                                    Draft
                                </th>
                                <th style={{textAlign: 'center'}}>
                                    Review Levels
                                </th>
                                <th style={{textAlign: 'center'}}>
                                    Pushback
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                moduleStatus?.map((item) => (
                                    <tr>
                                        <td>
                                            {item?.label}
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            <Center><Check size={'15'} color={'green'}  /></Center>
                                        </td>
                                        <td style={{display: 'flex', justifyContent: 'center'}}>
                                            {item?.data?.map((status) => status?.status == 'review' ? (                                                
                                                <Radio.Group
                                                    value={status?.is_active == true ? 'two_level' : 'one_level'}
                                                    size={'xs'}
                                                >
                                                    <Group>
                                                        <Radio value="one_level" label={<Center><User size={'15'} /> (1)</Center>} onClick={() => handleReviewLevelChange({module_name: item?.module_name, level: 0})} />
                                                        <Radio value="two_level" label={<Center><Users size={'15'} /> (1+1)</Center>} onClick={() => handleReviewLevelChange({module_name: item?.module_name, level: 1})} />
                                                    </Group>
                                                </Radio.Group>
                                            ) : null )}
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            <Center><Check size={'15'} color={'green'}  /></Center>
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </Table>
                    <LoadingOverlay visible={loading} overlayBlur={2} />
                </div>
            </Box>
        </>
    )

}

export default Modules