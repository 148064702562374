import { getAPICall } from "./getAPICall";
import { patchAPICall } from "./patchAPICall";
import { postAPICall } from "./postAPICall";


export const getAllCancellationDetails = () => {
  return getAPICall(`/cancellation-period`);
}

export const updateCancellationPeriods = (body) => {
  return postAPICall(`/cancellation-period`, { body });
}

export const getCancellationRemarksByModuleName = ({ module_name }) => {
  return getAPICall(`/cancellation-period/remarks?module_name=${module_name}`);
}