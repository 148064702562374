import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mantine/core";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { ViewData } from "../../components/CommonComponents/Preview/FilePreview";
import ControllerCurrencyInput from "../../components/CommonComponents/TextField/ControllerCurrencyInput";
import {
  currencyAsInt,
  currencyAsFloat,
} from "../../components/Currency/currencyFormat";
import AdditionalDeduction from "./AdditionalDeduction";
import ActionButtons from "../../components/CommonComponents/ActionButtons/ActionButtons";
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import * as yup from "yup";
import {
  updateLoanById,
  createNewLoan,
  saveLoanAndsendForNextStage,
  sendLoanforNextStage,
} from "../../services/loan.service";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import { STAGE_CHANGE_TEXT } from "../../constants/textMessage";
import { IN_APPROVAL } from "../../constants/status";
import Remarks from "../../components/CommonComponents/Remarks/Remarks";

const loanSchema = yup.object({
  loan_amount: yup
    .string()
    .nullable()
    .required("Please Enter a Tranche Amount"),
});

/** here we are handling the new loan features 
 * @props
 * formType: contain string used to set the default value
 * repaymentType: contain object of the customer repayment details
 * approvedImd: contain object of approved IMD
 * systemDate: contain object of system info
 * loanData: contain object of saved loan details
 * createdLoanId: contain created Loan Id
 * customerAppId: contain customer Application Id
 * stages: contain list of stages available
 * reviewTrail: contain list of remarks of user
 * props: contain the set states
 */
const NewLoan = ({
  formType,
  repayment,
  approvedIMD,
  systemDate,
  loanData,
  createdLoanId,
  customerAppId,
  creditReloadData,
  stages,
  enhancement,
  transfer,
  reviewTrail,
  feeType,
  ...props
}) => {
  const [interestValue, setInterestValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState();
  const [label, setLabel] = useState();
  const [remarksLoading, setRemarksLoading] = useState();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [calculatedFee, setCalculateFee] = useState({});

  const {
    control,
    setValue,
    setError,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    mode: "all",
    resolver: yupResolver(loanSchema),
    defaultValues:
      formType == "view"
        ? {
          ...loanData,
          loan_type: "new",
        }
        : {
          disbursed_date: dayjs(new Date()).format(),
          loan_type: "new",
          additional_deduction: [
            {
              fee_type: "advance_interest",
              fee_value: interestValue,
            },
          ],
        },
  });
  const values = watch();

  // used to handle the additional deduction
  const deductionFieldArray = useFieldArray({
    control,
    name: "additional_deduction",
  });

  /** used to set loan amount when the credit reload request is selected */
  useEffect(() => {
    if (creditReloadData?.amount) {
      setValue("loan_amount", creditReloadData?.amount);
    }
  }, [creditReloadData]);

  useEffect(() => {
    // set the saved loan data as default value 
    if (Object.keys(loanData)?.length) {
      setValue("eligible_amount", loanData?.eligible_amount);
      reset(loanData);
      setValue("loan_type", 'new')
    }
    // handling the advance interest in saved stage
    if (values?.advance_interest) {
      deductionFieldArray?.append({
        fee_type: "advance_interest",
        fee_value: interestValue,
      });
    }
  }, [loanData]);

  useEffect(() => {
    if (Object.keys(approvedIMD)?.length && !formType) {
      setValue("eligible_amount", approvedIMD?.eligible_amount);
      if (approvedIMD?.enhancement_amount > 0) {
        (approvedIMD?.enhancement_amount <= approvedIMD?.eligible_amount) && setValue("loan_amount", approvedIMD?.enhancement_amount)
      }
      if (approvedIMD?.transfer_amount > 0) {
        (approvedIMD?.transfer_amount <= approvedIMD?.eligible_amount) && setValue("loan_amount", approvedIMD?.transfer_amount)
      }
    }
    if (Object.keys(systemDate)?.length && !formType) {
      setValue("disbursed_date", systemDate?.current_system_date);
    }
  }, [approvedIMD, systemDate]);

  const handleReviewModal = () => {
    // used to handle the send for review button
    loanSchema
      .validate(values)
      .then((res) => {
        setOpen(true);
        setStatus("review");
        setLabel(
          stages?.next_stage == "approval"
            ? "Send for Approval"
            : "Send for Review"
        );
      })
      .catch((err) => {
        displayNotification({ message: err?.errors, variant: 'error' });
      });
  };

  /** this function is used to handle the loan amount field */
  const validateLoanAmount = () => {
    let msg = "Tranche amount should be less than approved amount";
    if (approvedIMD?.approved_amount) {
      if (
        currencyAsInt(values?.loan_amount) >
        parseInt(approvedIMD?.approved_amount)
      ) {
        return msg;
      } else {
        return errors?.loan_amount?.message;
      }
    }
  };

  /** this function is used to handle the dueDate */
  let newdate = new Date(values?.disbursed_date);
  const calculateDueDate = () => {
    if (loanData?.due_date) {
      return loanData?.due_date;
    } else if (values?.disbursed_date) {
      return newdate.setDate(newdate.getDate() + repayment?.no_of_days);
    }
  };

  /** this function is used to handle the no.of Days between disb date and due date */
  let disb_date = dayjs(values?.disbursed_date);
  let no_of_days;
  const calculateNoOfDays = () => {
    if (loanData?.due_date) {
      let due_date = dayjs(loanData?.due_date);
      no_of_days = due_date.diff(disb_date, "day");
      return no_of_days;
    } else {
      no_of_days = disb_date.diff(newdate, "day");
      return Math.abs(no_of_days);
    }
  };

  /** this function is used to calculate the net disb amount */
  const calculateNetDisbAmount = () => {
    let amount = currencyAsInt(values?.loan_amount);
    if (values?.additional_deduction?.length) {
      values?.additional_deduction?.map((item) => {
        if (item?.fee_type === "advance_interest") {
          amount = amount - parseInt(interestValue);
        } else if (["fee", "express_reload_fee"]?.includes(item?.fee_type) && item?.total_amount) {
          amount = amount - currencyAsInt(item?.total_amount);
        } else if (item?.fee_value) {
          amount = amount - currencyAsInt(item?.fee_value);
        }
      });
    } else {
      amount = amount;
    }
    if (currencyAsInt(values?.loan_amount)) {
      return amount > 0 ? amount : "0";
    }
    return "0";
  };

  /** this function is used to handle the additional deduction and advance interest amount */
  const handleDeduction = (val) => {
    let deduction = [];
    let amount;
    values?.additional_deduction?.map((item, i) => {
      item.fee_value = currencyAsInt(item?.fee_value);

      if (!["advance_interest"]?.includes(item?.fee_type)) {
        deduction.push(item);
      } else if (item?.fee_type == val) {
        amount = interestValue;
      }
    });

    /** if u need the array then pass the value as deduction or amount means pass as interest */
    if (val == "deductions") {
      return deduction;
    } else if (val != "deductions") {
      return amount || null;
    }

    return deduction;
  };

  // this function is handling the save and update api call
  const onSubmit = (values) => {
    const body = {
      customer_application_id: customerAppId,
      customer_id: approvedIMD?.customer_id,
      loan_type: enhancement ? 'enhancement' : transfer ? 'transfer' : values?.loan_type,
      loan_amount: currencyAsFloat(values?.loan_amount),
      payment_mode: "OTP",
      crr_id: creditReloadData?.crr_id,
      disbursed_date: dateFormat(values?.disbursed_date, "YYYY-MM-DD"),
      additional_deduction: handleDeduction("deductions"),
      advance_interest: handleDeduction("advance_interest"),
    };

    setButtonLoader(true);
    if (!createdLoanId) {
      createNewLoan(body)
        .then((res) => {
          props?.setSucessfulmodal({
            open: true,
            title: STAGE_CHANGE_TEXT?.save,
          });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({
              id: "imd-save",
              message: e?.message || e,
              variant: 'error',
            });
          }
        })
        .finally(() => {
          setButtonLoader(false);
        });
    } else {
      updateLoanById(createdLoanId, body)
        .then((res) => {
          props?.setSucessfulmodal({
            open: true,
            title: STAGE_CHANGE_TEXT?.update,
          });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({
              id: "imd-save",
              message: e?.message || e,
              variant: 'error',
            });
          }
        })
        .finally(() => {
          setButtonLoader(false);
        });
    }
  };

  // handling the review or approval api call
  const onSendForReview = (remarks) => {
    setRemarksLoading(true);
    const body = {
      remarks: remarks,
    };
    const saveLoanBody = {
      customer_application_id: customerAppId,
      customer_id: approvedIMD?.customer_id,
      ...body,
      loan_type: enhancement ? 'enhancement' : transfer ? 'transfer' : values?.loan_type,
      loan_amount: currencyAsFloat(values?.loan_amount),
      payment_mode: "OTP",
      crr_id: creditReloadData?.crr_id,
      disbursed_date: dateFormat(values?.disbursed_date, "YYYY-MM-DD"),
      additional_deduction: handleDeduction("deductions"),
      advance_interest: handleDeduction("advance_interest"),
    };
    if (!createdLoanId) {
      const saveLoanBodyWithRemarks = {
        ...saveLoanBody,
        ...body,
      };
      saveLoanAndsendForNextStage(saveLoanBodyWithRemarks)
        .then((res) => {
          setRemarksLoading(false);
          setOpen(false);
          props?.setSucessfulmodal({
            open: true,
            title:
              stages?.next_stage == IN_APPROVAL
                ? STAGE_CHANGE_TEXT?.approval
                : STAGE_CHANGE_TEXT?.review,
          });
        })
        .catch((e) => {
          // This catch block sets field validation errors from api whic are not handled in UI.
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({
              id: "imd-save",
              message: e?.message || e,
              variant: 'error',
            });
          }
        })
        .finally(() => {
          setRemarksLoading(false);
          setOpen(false);
        });
    } else {
      updateLoanById(createdLoanId, saveLoanBody)
        .then(() => {
          sendLoanforNextStage(createdLoanId, body)
            .then((res) => {
              props?.setSucessfulmodal({
                open: true,
                title:
                  stages?.next_stage == IN_APPROVAL
                    ? STAGE_CHANGE_TEXT?.approval
                    : STAGE_CHANGE_TEXT?.review,
              });
            })
            .catch((e) => {
              if (e?.RequestValidationError) {
                Object.keys(e?.RequestValidationError).forEach(
                  (item, index) => {
                    setError(item, {
                      message: e?.RequestValidationError[item],
                    });
                  }
                );
              } else {
                displayNotification({
                  id: "loan-save",
                  message: e?.message || e,
                  variant: 'error',
                });
              }
            });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({
              id: "loan-save",
              message: e?.message || e,
              variant: 'error',
            });
          }
        })
        .finally(() => {
          setRemarksLoading(false);
          setOpen(false);
        });
    }
  };

  return (
    <>
      <Grid mt="sm">
        <Grid.Col span={7} p={0}>
          <Grid.Col span={12} py={2}>
            <ViewData
              title={"Product"}
              value={approvedIMD?.product_name}
              type={"text"}
            />
          </Grid.Col>
          <Grid.Col span={12} py={2}>
            <ViewData
              title={"Available Limit"}
              value={Math.max(0, approvedIMD?.eligible_amount)}
            />
          </Grid.Col>
          <Grid.Col span={12} py={2}>
            {!creditReloadData?.crr_id ? (
              <ControllerCurrencyInput
                name="loan_amount"
                label="Tranche Amount"
                value={values?.loan_amount}
                amountInWords
                required
                disabled={creditReloadData?.crr_id ? true : false}
                control={control}
                errors={validateLoanAmount()}
              />
            ) : (
              <ViewData
                title={"Loan Amount"}
                value={values?.loan_amount}
                type="currency"
                withTooltip
              />
            )}
          </Grid.Col>
          <Grid.Col span={12} py={2}>
            <ViewData
              title={"Disb Date"}
              value={values?.disbursed_date}
              type={"date"}
            />
          </Grid.Col>
          <Grid.Col span={12} py={2}>
            <ViewData
              title={"Repayment Type"}
              value={repayment?.label}
              type={"text"}
            />
          </Grid.Col>
        </Grid.Col>
        <Grid.Col span={5} mt={"md"}>
          <Grid>
            <Grid.Col span={12} py={2}>
              <ViewData
                title={"Due Date"}
                value={calculateDueDate() || loanData?.due_date}
                type={"date"}
                info={true}
                infotext={
                  "Due date may be change according to holidays so please save and check the date"
                }
                style={{ marginBottom: "0px" }}
              />
            </Grid.Col>
            <Grid.Col span={12} py={2}>
              <ViewData
                title={"No.of Days"}
                value={calculateNoOfDays()}
                type={"text"}
                info={true}
                infotext={
                  "No.of Days may be change according to holidays so please save and check the date"
                }
                style={{ marginBottom: "0px" }}
              />
            </Grid.Col>
            <Grid.Col span={12} py={2}>
              <ViewData
                title={"Principal"}
                value={values?.loan_amount}
                style={{ marginBottom: "0px" }}
              />
            </Grid.Col>
            <Grid.Col span={12} py={2}>
              <ViewData
                title={`Interest`}
                secondaryLabel={approvedIMD?.interest_rate}
                value={
                  ((currencyAsInt(values?.loan_amount) *
                    (approvedIMD?.interest_rate / 100)) /
                    approvedIMD?.interest_calc_period) *
                  approvedIMD?.no_of_days || loanData?.interest_amount
                }
                style={{ marginBottom: "0px" }}
              />
            </Grid.Col>

            {/* If spread rate percentage is available then it will display */}
            {approvedIMD?.spread_rate ? (
              <Grid.Col span={12} py={2}>
                <ViewData
                  title={`Spread`}
                  secondaryLabel={approvedIMD?.spread_rate}
                  style={{ marginBottom: "0px" }}
                  value={
                    ((currencyAsInt(values?.loan_amount) *
                      (approvedIMD?.spread_rate / 100)) /
                      approvedIMD?.interest_calc_period) *
                    approvedIMD?.no_of_days || loanData?.spread_rate
                  }
                />
              </Grid.Col>
            ) : null}
            {/* If spread rate percentage is available then it will display */}
            {approvedIMD?.addon_spread_rate ? (
              <Grid.Col span={12} py={2}>
                <ViewData
                  title={`Addon Spread`}
                  secondaryLabel={approvedIMD?.addon_spread_rate}
                  style={{ marginBottom: "0px" }}
                  value={
                    ((currencyAsInt(values?.loan_amount) *
                      (approvedIMD?.addon_spread_rate / 100)) /
                      approvedIMD?.interest_calc_period) *
                    approvedIMD?.no_of_days || loanData?.addon_spread_rate
                  }
                />
              </Grid.Col>
            ) : null}
            <Grid.Col span={12} py={2}>
              <ViewData
                title={"Due Amount"}
                style={{ marginBottom: "0px" }}
                value={
                  Math.round(
                    parseInt(currencyAsInt(values?.loan_amount)) +
                    ((currencyAsInt(values.loan_amount) *
                      ((approvedIMD?.interest_rate +
                        approvedIMD?.spread_rate +
                        approvedIMD?.addon_spread_rate) /
                        100)) /
                      approvedIMD?.interest_calc_period) *
                    approvedIMD?.no_of_days
                  ) || loanData?.due_amount
                }
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <AdditionalDeduction
                control={control}
                values={values}
                createdLoanId={createdLoanId}
                approvedIMD={approvedIMD}
                advanceInterest={interestValue}
                customerAppId={customerAppId}
                calculatedFee={calculatedFee}
                setCalculateFee={setCalculateFee}
                setAdvanceInterest={setInterestValue}
                feeType={feeType}
                deductionFieldArray={deductionFieldArray}
                loanType={'new'}
                additionalFee={[
                  {
                    label: "Advance Interest",
                    value: "advance_interest",
                    id: 3,
                    isDisabled: false,
                  },
                ]}
              />
            </Grid.Col>
            <Grid.Col
              span={12}
              mt={"xs"}
              style={{ padding: "0", border: "2px solid #228be6" }}
            >
              <ViewData
                title={"Net Disb Amount"}
                style={{
                  marginBottom: "0px",
                  padding: "10px",
                  margin: "0px",
                }}
                value={calculateNetDisbAmount()}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
      {/** This Action buttons component is used to display the buttons in the flow */}
      <ActionButtons
        status={loanData?.status_value ? loanData?.status_value : "draft"}
        base={'creation'}
        nextStage={stages?.next_stage}
        sendForReviewCB={handleReviewModal}
        sendForApprovalCB={handleReviewModal}
        saveCB={handleSubmit(onSubmit)}
        loading={{ save: buttonLoader }}
        remarksCB={() => props?.setRemarksOpen(true)}
        remarks={reviewTrail}
        isReady={Boolean(stages?.next_stage)}
        currentStatus={values?.status_value}
        moduleApplicationId={values?.id}
        moduleName={'loan'}
      />
      <Remarks
        open={open}
        setOpen={setOpen}
        type={stages?.next_stage}
        callback={onSendForReview}
        remarksTitle={
          stages?.next_stage == IN_APPROVAL
            ? "Approval Remarks"
            : "Review Remarks"
        }
        check={status}
        label={label}
        loading={remarksLoading}
        currentStatus={values?.status_value}
        moduleApplicationId={values?.id}
        moduleName={'Loan'}
      />
    </>
  );
};

export default NewLoan;
