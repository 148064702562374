import { Drawer, Text } from "@mantine/core";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Filter from "../../components/CommonComponents/Filter/Filter";
import Nums from "../../components/Body/Nums";
import { getAllEligibleCustomerLevelReceiptCust } from "../../services/customerReceipt.service";
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { createColumnHelper } from "@tanstack/react-table";
import SimpleReactTable from "../../components/CommonComponents/Table/SimpleReactTable";
import { DrawerModal } from "../../components/CommonComponents/Drawer/DrawerModal";

const CustomerReceiptList = ({ open, onClose }) => {
  const columnHelper = createColumnHelper();
  const [filter, setFilter] = useState(true);
  const [loading, setLoading] = useState();
  const location = useLocation();
  const [approvedIMD, setApprovedIMD] = useState([]);
  const [filterDetails, setFilterDetails] = useState([]);

  const navigate = useNavigate();

  const onSubmit = (values, filterData) => {
    setFilterDetails(filterData);
    setLoading(true);
    getAllEligibleCustomerLevelReceiptCust({ ...values })
      .then((res) => {
        setApprovedIMD(res);
      })
      .catch((e) => {
        setApprovedIMD();
        displayNotification({
          id: "customer-imd",
          message: e?.message || e,
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setFilter(false);
      });
  };

  const columns = [
    columnHelper.accessor('customer_name', {
      header: 'Customer Name',
    }),
    columnHelper.accessor('customer_id', {
      header: 'Customer Id',
    }),
    columnHelper.accessor('customer_total_due_amount', {
      header: <Text sx={{textAlign: 'right'}}>Tranches Amount</Text>,
      cell: (value) =>  <Nums value={value.getValue()} styles={{textAlign: 'right'}} />,
    }),
    columnHelper.accessor('total_loan_amount', {
      header: <Text sx={{textAlign: 'right'}}>Principle Amount</Text>,
      cell: (value) =>  <Nums value={value.getValue()} styles={{textAlign: 'right'}} />,
    }),
  ];
  return (
    <DrawerModal
      open={open}
      onClose={onClose}
      filterData={filterDetails}
      title="Find Loan Details"
      position="right"
      padding="xl"
      size="50%"
    >
      {/* Used to show filter */}
      {filter ? (
        <Filter
          callback={onSubmit}
          onClose={onClose}
          open={open}
          loading={loading}
          type={"receipt"}
        />
      ) : (
        <SimpleReactTable
          rowData={approvedIMD}
          columnData={columns}
          filterData={filterDetails}
          setFilter={setFilter}
          showFilter
          sx={{ height: '70vh' }}
          rowKeyGetter={(row) => {
            return row?.id;
          }}
          onRowClick={(i, o) => navigate(`/customer-receipt/${i?.customer_id}`, { state: { from: location.pathname } })}
        />
      )}
    </DrawerModal>
  );
};

export default CustomerReceiptList;
