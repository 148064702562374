import React from "react";
import { getUserProfile, getRoles } from '../../../../services/user.service';
import { createStyles, Text } from "@mantine/core";
import { Title, Grid, Avatar, Box } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";

const useStyles = createStyles({
  headDetail: {
    width: "100%",
    height: "100px",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "50px",
    position: "relative",
    background:
      "linear-gradient(135deg, rgba(71,224,236,0.8047337278106509) 0%, rgba(9,142,240,0.6568047337278107) 40%, rgba(85,168,226,0.8461538461538461) 90%)",
  },
  avatar: {
    position: "absolute",
    top: "60px",
    left: "30px",
    border: "3px solid white",
  },
  userName: {
    position: "absolute",
    top: "55px",
    right: "20px",
    fontWeight: "500",
    fontSize: "35px",
    color: "white",
    opacity: "0.6",
  },
  detailsMain: {
    backgroundColor: "white",
    borderRadius: "10px",
  },
  detailsCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const Accounts = () => {
  const { classes } = useStyles();

  const { data } = useQuery(["user-details"], getUserProfile,{
    refetchOnWindowFocus: false
  });
  /** roles will contain all available roles in array format */
  const roles = useQuery(["roles"], () => getRoles(), {
    refetchOnWindowFocus: false,
  });

  /** storing the first letter of first name and last name */
  var str = data?.first_name + " " + data?.last_name;
  var matches = str?.match(/\b(\w)/g);
  var acronym = matches?.join("").toUpperCase();

  return (
    <Box>
      <Box className={classes.headDetail}>
        <Avatar
          size={"100px"}
          color="blue"
          radius={"50px"}
          className={classes.avatar}
        >
          {data?.first_name && <Title>{acronym}</Title>}
        </Avatar>
        {/** taking the current role for the user */}
        <Text className={classes.userName}>{roles?.data?.filter((e) => e?.id == data?.group_id)[0]?.name?.toUpperCase()}</Text>
      </Box>
      <Grid mx={"0px"} pb={"xl"} className={classes.detailsMain}>
        <Grid mt={"70px"} pt={"lg"} ml={"30px"} className={classes.detailsCard}>
          <Grid.Col span={6}>
            <Title order={6}>First Name</Title>
            <Text size={14}>
              {data?.first_name
                ? data?.first_name
                : "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Title order={6}>Last Name</Title>
            <Text size={14}>
              {data?.last_name
                ? data?.last_name
                : "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={6} mt={"md"}>
            <Title order={6}>Mobile</Title>
            <Text size={14}>
              {data?.mobile ? data?.mobile : "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={6} mt={"md"}>
            <Title order={6}>Email Id</Title>
            <Text size={14}>
              {data?.email ? data?.email : "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={6} mt={"md"}>
            <Title order={6}>Personal Email Id</Title>
            <Text size={14}>
              {data?.personal_email
                ? data?.personal_email
                : "-"}
            </Text>
          </Grid.Col>
          <Grid.Col span={6} mt={"md"}>
            <Title order={6}>Adderss</Title>
            <Text size={14}>
              {data?.address ? data?.address : "-"}
            </Text>
          </Grid.Col>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Accounts;
