import { Grid, Title, Text, Box } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import ControllerSelect from '../../components/CommonComponents/TextField/ControllerSelect';
import ImdStyle from '../IMD/Imd.styles';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ModalDataViwer from '../../components/SucessfullModal/ModalDataViwer';
import {
  ViewData,
  ViewFormData,
  ViewFormDataContained,
} from '../../components/CommonComponents/Preview/FilePreview';
import { useQuery } from '@tanstack/react-query';
import {
  customerBankDetails,
} from '../../services/imd.service';
import Body from '../../components/Body/Body';
import {
  getApprovedLoansDetailsById,
  creatingDisb,
  getDisbById,
  updatingDisb,
  getAllRemarksDisb,
  saveAndNextDisb,
  sendDisbforNextStage
} from '../../services/disbursement.service';
import DashBoardCustomerDetails from '../../components/DashboardCard/dashboard';
import { STAGE_CHANGE_TEXT } from '../../constants';
import { getDeductionLoansbyID } from '../../services/loan.service';
import Remarks from '../../components/CommonComponents/Remarks/Remarks';
import ViewRemark from '../../components/Remark/ViewRemark';
import PageTitle from '../../components/PageTitle/PageTitle';
import Nums from '../../components/Body/Nums';
import IdBadge from '../../components/CommonComponents/Preview/IdBadge';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import DataViewerWapper from '../../components/CommonComponents/Preview/DataViewerWapper';
import TitleViewer from '../../components/CommonComponents/Title/TitleViewer';
import ActionButtons
  from '../../components/CommonComponents/ActionButtons/ActionButtons';
import LoanRenewalTable from '../../components/CommonComponents/RenewalTable/LoanRenewalTable';
import { getStageforModules } from '../../services/module.service';
import CreditReloadDetails
  from '../../components/CommonComponents/CreditReload/CreditReloadDetails';
import { ViewFeeDetailsWithGST } from '../../components/FeePopover/FeeCalculationPopover';
import { displayNotification } from '../../components/CommonComponents/Notification/displayNotification';
import { getBusinessBanks } from '../../services/banks.service';
import ReloadFeePopover from '../../components/FeePopover/ReloadFeePopover';

const schema = yup.object({
  target_bank_id: yup.number().required('Please Select Bank Details'),
  source_bank_id: yup.number().required('Please Select Bank Details'),
});

const DisbCreation = ({ formType }) => {
  const { disbId, loansId, createdDisbId } = useParams();
  const [open, setOpen] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [sucessfulmodal, setSucessfulmodal] = useState(false);
  const [status, setStatus] = useState();
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [label, setLabel] = useState();
  const [remarksLoading, setRemarksLoading] = useState();
  const [prospectcodebody, setProspectcodebody] = useState(null);

  const { data: DisbData = [], isLoading: disbLoading } = useQuery(
    ['loan-details', loansId],
    () => loansId && getApprovedLoansDetailsById(loansId),
    {
      onSuccess: (data) => {
        setProspectcodebody({
          id: data?.deduction_loan_ids?.length
            ? data?.deduction_loan_ids
            : null,
        });
      },
      refetchOnWindowFocus: false,
    }
  );



  const { data: stages = [] } = useQuery(
    ['disb-stage'],
    () => getStageforModules('disbursement'),
    {
      select: (data) => {
        return {
          'current_stage': 'draft',
          'next_stage': data[1]
        }
      },
      refetchOnWindowFocus: false
    }
  );

  const { data: reviewTrail = [] } = useQuery(
    ['remarks', createdDisbId],
    () => createdDisbId && getAllRemarksDisb(createdDisbId),
    { refetchOnWindowFocus: false }
  );

  const { data: ProspectCode = [] } = useQuery(
    ['prospectcode', prospectcodebody],
    () => prospectcodebody && getDeductionLoansbyID(loansId, prospectcodebody),
    { refetchOnWindowFocus: false }
  );
  const { data: disbDataById = [], isLoading: disbByIdLoading } = useQuery(
    ['disb-byid', createdDisbId],
    () => createdDisbId && getDisbById(createdDisbId),
    { refetchOnWindowFocus: false }
  );

  const { data: customerBank = [], isLoading: customerBankLoading } = useQuery(
    ['customer-bank', disbId],
    () => disbId && customerBankDetails(disbId),
    {
      enabled: Boolean(createdDisbId ? disbDataById?.id : true),
      // used to get the bank details if crr is mapped else it will return the total array
      select: (data) => {
        if (disbDataById?.crr_id) {
          return data?.filter((item) => item?.id == disbDataById?.target_bank_id);
        }
        return data;
      },
      refetchOnWindowFocus: false,
    }
  );

  const { data: sourceBank = [] } = useQuery(
    ['company-bank', DisbData?.product_id],
    () => getBusinessBanks({ product_id: DisbData?.product_id }),
    {
      enabled: Boolean(DisbData?.product_id),
      refetchOnWindowFocus: false
    }
  );

  const {
    register,
    handleSubmit,
    setError,
    control,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: formType == 'view' && { ...disbDataById },
  });

  useEffect(() => {
    if (Object.keys(disbDataById).length) {
      reset(disbDataById);
    }
  }, [disbDataById]);

  useEffect(() => {
    /** customer bank will be displayed based on CRR */
    if (disbDataById?.crr_id) {
      setValue('target_bank_id', disbDataById?.target_bank_id)
    }
  }, [disbDataById?.crr_id])

  const values = watch();

  const handleReviewModal = () => {
    schema.validate(values)
      .then(res => {
        setOpen(true);
        setStatus('review');
        setLabel(stages?.next_stage == 'approval' ? 'Send for Approval' : 'Send for Review');
      })
      .catch(err => {
        displayNotification({ message: err?.errors, variant: 'error' })
      })
  }

  const onSubmit = (values) => {
    const body = {
      customer_application_id: DisbData?.customer_application_id,
      target_bank_id: values?.target_bank_id,
      source_bank_id: values?.source_bank_id,
      loan_id: parseInt(loansId),
      customer_id: DisbData?.customer_id
    };
    setButtonLoader({ save: true });
    if (!createdDisbId) {
      const createBody = {
        customer_id: parseInt(disbId),
        customer_application_id: DisbData.customer_application_id,
        loan_id: parseInt(loansId),
        ...body,
      };
      creatingDisb(createBody)
        .then((res) => {
          setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.save });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({
              id: 'disb-create',
              message: e?.message || e,
              variant: 'error',
            });
          }
        })
        .finally(() => {
          setButtonLoader({ save: false });
          setOpen(false);
        })
    } else {
      updatingDisb(createdDisbId, body)
        .then((res) => {
          setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.update });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({
              id: 'disb-update',
              message: e?.message || e,
              variant: 'error',
            });
          }
        })
        .finally(() => {
          setButtonLoader({ save: false });
          setOpen(false);
        })
    }
  };

  const onReview = (remarks) => {
    const body = {
      customer_application_id: DisbData?.customer_application_id,
      target_bank_id: values?.target_bank_id,
      source_bank_id: values?.source_bank_id,
      loan_id: parseInt(loansId),
      customer_id: DisbData?.customer_id,
      remark_type: 'maker',
      remarks: remarks,
    };
    setRemarksLoading(true);
    if (!createdDisbId) {
      saveAndNextDisb(body)
        .then((res) => {
          setSucessfulmodal({ open: true, title: stages?.next_stage == 'approval' ? STAGE_CHANGE_TEXT?.approval : STAGE_CHANGE_TEXT?.review });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({
              id: 'disb-review-save',
              message: e?.message || e,
              variant: 'error',
            });
          }
        })
        .finally(() => {
          setRemarksLoading(false);
          setOpen(false);
        })
    } else {
      updatingDisb(createdDisbId, body)
        .then((res) => {
          sendDisbforNextStage(createdDisbId,
            {
              remark_type: 'maker',
              remarks: remarks
            })
            .then((res) => {
              setSucessfulmodal({ open: true, title: stages?.next_stage == 'approval' ? STAGE_CHANGE_TEXT?.approval : STAGE_CHANGE_TEXT?.review });
            })
            .catch((e) => {
              if (e?.RequestValidationError) {
                Object.keys(e?.RequestValidationError).forEach((item, index) => {
                  setError(item, { message: e?.RequestValidationError[item] });
                });
              } else {
                displayNotification({ message: e?.message || e, variant: 'error' });
              }
            });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' });
          }
        })
        .finally(() => {
          setRemarksLoading(false);
          setOpen(false);
        })
    }
  };

  return (
    <>
      <Body background={false}>
        <PageTitle
          title={DisbData?.customer_name}
          id={DisbData?.customer_id}
          action={
            DisbData?.crr_id ?
              /** display the credit reload request */
              <CreditReloadDetails
                selectedRowId={DisbData?.crr_id}
                type={DisbData?.additional_deduction?.find(i => i?.fee_type === 'express_reload_fee') ? 'express' : null}
              />
              : null
          }
        />
      </Body>
      <DashBoardCustomerDetails id={disbId} loansId={loansId} codeName={'Disb Code'} code={disbDataById?.code} />
      <Box sx={{ position: 'relative' }}>
        <Body>
          {DisbData?.prospect_code && (
            <div style={ImdStyle.flag}>
              <IdBadge
                remarks={'draft'}
                label={DisbData?.prospect_code}
              />
            </div>
          )}
          <Grid gutter='xl' mt={'sm'} pt={'xs'}>
            <Grid.Col span={7}>
              <DataViewerWapper>
                <Grid gutter='lg'>
                  <Grid.Col span={12}>
                    <ViewFormData
                      label={'Loan Type'}
                      value={
                        DisbData?.loan_type == 'new'
                          ? 'New'
                          : DisbData?.loan_type == 'enhancement'
                            ? 'Enhancement'
                            : DisbData?.loan_type == 'transfer'
                              ? 'Transfer'
                              : 'Revolving'
                      }
                      loading={disbByIdLoading || disbLoading}
                    />
                  </Grid.Col>
                  <Grid.Col span={12} mb={'md'}>
                    <ViewFormData
                      label={'Product'}
                      value={DisbData?.product_name}
                      loading={disbByIdLoading || disbLoading}
                    />
                  </Grid.Col>
                </Grid>
                {DisbData?.loan_type == 'new' || DisbData?.loan_type === 'enhancement' || DisbData?.loan_type === 'transfer' ? (
                  <>
                    <Grid gutter='lg'>
                      <Grid.Col span={12} pt={0}>
                        <TitleViewer title='Cash Flow' />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={'Disb Date'}
                          value={dateFormat(
                            DisbData?.disbursed_date ||
                            disbDataById?.disbursed_date
                          )}
                          loading={disbByIdLoading && disbLoading}
                        />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={'Due Date'}
                          value={dateFormat(
                            DisbData?.due_date || disbDataById?.due_date
                          )}
                          loading={disbByIdLoading && disbLoading}
                        />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={'Principal'}
                          value={<Nums withCopy value={DisbData?.loan_amount || disbDataById?.loan_amount} />}
                          loading={disbByIdLoading && disbLoading}
                        />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={`Interest`}
                          secondaryLabel={DisbData?.interest_rate}
                          value={
                            <Nums value={DisbData?.interest_amount || disbDataById?.interest_amount} />
                          }
                          loading={disbByIdLoading && disbLoading}
                        />
                      </Grid.Col>
                      {/* If spread rate percentage is avalible then it will display */}
                      {DisbData?.spread_rate ?
                        <Grid.Col span={12}>
                          <ViewFormData
                            label={`Spread`}
                            secondaryLabel={DisbData?.spread_rate}
                            value={
                              <Nums value={DisbData?.spread_amount || disbDataById?.spread_amount} />
                            }
                            loading={disbByIdLoading && disbLoading}
                          />
                        </Grid.Col> : null
                      }
                      {/* If addon spread rate percentage is avalible then it will display */}
                      {DisbData?.addon_spread_rate ?
                        <Grid.Col span={12}>
                          <ViewFormData
                            label={`Addon Spread`}
                            secondaryLabel={DisbData?.addon_spread_rate}
                            value={
                              <Nums value={DisbData?.addon_spread_amount || disbDataById?.addon_spread_amount} />
                            }
                            loading={disbByIdLoading && disbLoading}
                          />
                        </Grid.Col> : null
                      }
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={'Due Amount'}
                          value={
                            <Nums withCopy value={DisbData?.due_amount || disbDataById?.due_amount} />
                          }
                          loading={disbByIdLoading && disbLoading}
                        />
                      </Grid.Col>
                      {DisbData?.advance_interest ?
                        <Grid.Col span={12}>
                          <ViewFormData
                            label={`Advance Interest`}
                            value={
                              <Nums withCopy value={DisbData?.advance_interest} />
                            }
                            loading={disbByIdLoading && disbLoading}
                          />
                        </Grid.Col> : null
                      }
                      {DisbData?.additional_deduction?.length ? (
                        <>
                          <Grid.Col span={12}>
                            <TitleViewer title='Deductions' />
                          </Grid.Col>
                          {DisbData?.additional_deduction?.map((item) => (
                            <Grid.Col span={12}>
                              <ViewFormData
                                label={item?.fee_name}
                                value={
                                  <Nums withCopy value={item?.total_amount} />
                                }
                                action={item?.fee_type == 'fee' ? <ViewFeeDetailsWithGST calculatedFee={item} /> : item?.fee_type === 'express_reload_fee' ? <ReloadFeePopover value={item} /> : null}
                              />
                            </Grid.Col>
                          ))}
                        </>
                      ) : null}
                      <Grid.Col span={12}>
                        <ViewFormDataContained
                          label={'Net Disb Amount'}
                          value={
                            <Nums withCopy value={DisbData?.disbursed_amount || disbDataById?.disbursed_amount} />
                          }
                          loading={disbByIdLoading && disbLoading}
                        />
                      </Grid.Col>
                    </Grid>
                  </>
                ) : null}
                {DisbData?.loan_type == 'revolving' ||
                  disbDataById?.loan_type == 'revolving' ? (
                  <>
                    <Grid gutter='lg' mt='md'>
                      <Grid.Col span={12}>
                        <TitleViewer title={'Revolving'} />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={'Tranche Amount'}
                          value={
                            <Nums withCopy value={DisbData?.loan_amount || disbDataById?.loan_amount} />
                          }
                          loading={disbByIdLoading && disbLoading}
                        />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={'Disb Date'}
                          value={dateFormat(
                            DisbData?.disbursed_date ||
                            disbDataById?.disbursed_date
                          )}
                          loading={disbByIdLoading && disbLoading}
                        />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={'Due Date'}
                          value={dateFormat(
                            DisbData?.due_date || disbDataById?.due_date
                          )}
                          loading={disbByIdLoading && disbLoading}
                        />
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={`Interest`}
                          secondaryLabel={DisbData?.interest_rate}
                          value={
                            <Nums value={DisbData?.interest_amount || disbDataById?.interest_amount} />
                          }
                          loading={disbByIdLoading && disbLoading}
                        />
                      </Grid.Col>
                      {/* If spread rate percentage is avalible then it will display */}
                      {DisbData?.spread_rate ?
                        <Grid.Col span={12}>
                          <ViewFormData
                            label={`Spread`}
                            secondaryLabel={DisbData?.spread_rate}
                            value={
                              <Nums value={DisbData?.spread_amount || disbDataById?.spread_amount} />
                            }
                            loading={disbByIdLoading && disbLoading}
                          />
                        </Grid.Col> : null
                      }
                      {/* If addon spread rate percentage is avalible then it will display */}
                      {DisbData?.addon_spread_rate ?
                        <Grid.Col span={12}>
                          <ViewFormData
                            label={`Addon Spread`}
                            secondaryLabel={DisbData?.addon_spread_rate}
                            value={
                              <Nums value={DisbData?.addon_spread_amount || disbDataById?.addon_spread_amount} />
                            }
                            loading={disbByIdLoading && disbLoading}
                          />
                        </Grid.Col> : null
                      }
                      <Grid.Col span={12}>
                        <ViewFormData
                          label={'Due Amount'}
                          value={
                            <Nums withCopy value={DisbData?.due_amount || disbDataById?.due_amount} />
                          }
                          loading={disbByIdLoading && disbLoading}
                        />
                      </Grid.Col>
                      {DisbData?.advance_interest ?
                        <Grid.Col span={12}>
                          <ViewFormData
                            label={`Advance Interest`}
                            value={
                              <Nums withCopy value={DisbData?.advance_interest} />
                            }
                            loading={disbByIdLoading && disbLoading}
                          />
                        </Grid.Col> : null
                      }
                      {DisbData?.renewal_fee?.length ?
                        <Grid.Col span={12}>
                          <ViewFormData
                            label='Renewal Fee'
                            value={
                              <Nums withCopy value={DisbData?.renewal_fee[0]?.total_amount} />
                            }
                            loading={disbByIdLoading && disbLoading}
                            action={<ViewFeeDetailsWithGST calculatedFee={DisbData?.renewal_fee[0]} />}
                          />
                        </Grid.Col> : null
                      }
                      {DisbData?.additional_deduction?.length ? (
                        <>
                          <Grid.Col span={12}>
                            <TitleViewer title='Deductions' />
                          </Grid.Col>
                          {DisbData?.additional_deduction?.map((item) => (
                            <Grid.Col span={12}>
                              <ViewFormData
                                label={item?.fee_name}
                                value={
                                  <Nums withCopy value={item?.total_amount} />
                                }
                                action={item?.fee_type == 'fee' ? <ViewFeeDetailsWithGST calculatedFee={item} /> : item?.fee_type === 'express_reload_fee' ? <ReloadFeePopover value={item} /> : null}
                              />
                            </Grid.Col>
                          ))}
                        </>
                      ) : null}
                      <Grid.Col span={12}>
                        <ViewFormDataContained
                          label={'Net Disb Amount'}
                          value={
                            <Nums withCopy value={DisbData?.disbursed_amount || disbDataById?.disbursed_amount} />
                          }
                          loading={disbByIdLoading && disbLoading}
                        />
                      </Grid.Col>
                    </Grid>
                  </>
                ) : null}
              </DataViewerWapper>
            </Grid.Col>
            <Grid.Col span={5}>
              <TitleViewer title={'Source Bank: Petromoney'} />
              <Grid pt={'lg'}>
                <Grid.Col span={12}>
                  <ControllerSelect
                    label={"Choose Source"}
                    name={"source_bank_id"}
                    required
                    data={sourceBank}
                    control={control}
                    type={'bank'}
                    autoSelect
                    setValue={setValue}
                    errors={errors?.source_bank_id?.message}
                  />
                </Grid.Col>
              </Grid>
              <TitleViewer title={'Target Bank: Customer'} />
              {disbDataById?.crr_id ? (
                <Grid pt={"lg"}>
                  <Grid.Col span={12} pt={"sm"}>
                    <ViewData
                      title={"Bank Name"}
                      value={customerBank[0]?.bank_name}
                      type={"text"}
                      loading={customerBankLoading}
                    />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <ViewData
                      title={"Account No"}
                      value={customerBank[0]?.account_number}
                      type={"text"}
                      loading={customerBankLoading}
                    />
                  </Grid.Col>
                </Grid>
              ) : (
                <Grid pt={"lg"}>
                  <Grid.Col span={12}>
                    <ControllerSelect
                      label={"Choose Target"}
                      name={"target_bank_id"}
                      required
                      data={customerBank}
                      type={'bank'}
                      control={control}
                      autoSelect
                      setValue={setValue}
                      errors={errors?.target_bank_id?.message}
                    />
                  </Grid.Col>
                </Grid>
              )
              }
            </Grid.Col>
            {DisbData?.deduction_loan_payload?.selection?.length && ProspectCode?.length ?
              /** used to displey the table if it is available */
              <Grid.Col span={12}>
                <LoanRenewalTable renewalLoans={ProspectCode} loanDetails={DisbData} />
              </Grid.Col> : null
            }
          </Grid>
          {/** This Action buttons component is used to display the buttons in the flow */}
          <ActionButtons
            status={disbDataById?.status_value ? disbDataById?.status_value : 'draft'}
            base={'creation'}
            nextStage={stages?.next_stage}
            sendForReviewCB={handleReviewModal}
            sendForApprovalCB={handleReviewModal}
            saveCB={handleSubmit(onSubmit)}
            loading={{ save: buttonLoader?.save }}
            remarksCB={() => setRemarksOpen(true)}
            remarks={reviewTrail}
            currentStatus={disbDataById?.status_value}
            moduleApplicationId={disbDataById?.id}
            moduleName={'disbursement'}
            isReady={Boolean(stages?.next_stage)} />

          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={disbDataById?.code}
            title={sucessfulmodal?.title}
            href={'/disb'}
            hreftext={'Back to list table'}
          />

          <Remarks
            open={open}
            setOpen={setOpen}
            type={stages?.next_stage}
            callback={onReview}
            remarksTitle={stages?.next_stage == 'review' ? 'Review Remarks' : 'Approval Remarks'}
            check={status}
            label={label}
            loading={remarksLoading}
            currentStatus={disbDataById?.status_value}
            moduleApplicationId={disbDataById?.id}
            moduleName={'Disbursement'}
          />
        </Body>

        <ViewRemark open={remarksOpen} onClose={setRemarksOpen} remarkTrail={reviewTrail} />

      </Box>
    </>
  );
};

export default DisbCreation;
