import { AppShell } from '@mantine/core';
import React from 'react';
import Topbar from '../components/Topbar/Topbar';
import NavigationBar from '../components/Navbar/NavgationBar';

const MainLayout = ({ children }) => {
  return (
    <AppShell navbarOffsetBreakpoint="md" padding='xl' header={<Topbar />} navbar={<NavigationBar />}>
      {children}
    </AppShell>
  );
};

export default MainLayout;