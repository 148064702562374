import { Box, Breadcrumbs, Skeleton, Text, Title } from "@mantine/core";
import React from "react";
import { NavLink } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { isNumeric } from "../../utils/IsNumeric";
import { APPROVED, IN_APPROVAL, IN_REVIEW } from "../../constants/status";
import { REJECTED } from "../../constants/status";
import { CANCELLED } from "../../constants/status";

const PageTitle = ({ title, id, action, breadcrumb = true }) => {
  const breadcrumbs = useBreadcrumbs();
  const items = breadcrumbs.map(({ breadcrumb, match }) => {
    if (!isNumeric(breadcrumb.props.children)) {
      return (
        <NavLink to={!['view', REJECTED, CANCELLED, APPROVED, IN_REVIEW, IN_APPROVAL, 'cancelled']?.includes?.(breadcrumb.props.children?.toLowerCase()) && match.pathname} key={match.pathname}>
          <Text size="sm" sx={{ color: "#242424", fontWeight: 500 }}>
            {breadcrumb}
          </Text>
        </NavLink>
      );
    }
    return null;
  });
  return (
    <Box mb="sm" sx={{display: 'flex', justifyContent: 'space-between'}}>
      <Box>
        {title ? (
          <Box style={{ display: "flex", gap: "5px" }}>
            <Title order={2}>{title}</Title>
            {id && <Text mt={"4px"}>({id})</Text>}
          </Box>
        ) : (
          <Box style={{ display: "flex", gap: "5px" }}>
            <Skeleton
              visible={true}
              height={12}
              mb={"18px"}
              width={"40%"}
            ></Skeleton>
          </Box>
        )}
        {breadcrumb ? <Breadcrumbs my="xs">{items}</Breadcrumbs> : null}
      </Box>
      {action && <Box>{action}</Box>}
    </Box>
  );
};

export default PageTitle;
