import { downloadReportbyReportName } from "../../../services/report.service"
import { openFile } from "../../../utils/openFile"
import { displayUpdatedNotification } from "../../../components/CommonComponents/Notification/displayUpdatedNotification"

export const reportDownload = ({id}) => {
    const interval = setInterval(() => {
        if(id) {
            downloadReportbyReportName({id})
            .then((res) => {
                if(res?.report_url) {
                    displayUpdatedNotification({
                        message: 'Report Downloaded Successfully',
                        title: 'Success',
                        variant: 'success',
                        id: 'report-loading',
                    });
                    openFile(res?.report_url);
                    clearInterval(interval);
                } else if(res?.report_status != 'inprogress') {
                    displayUpdatedNotification({
                        message: 'Something went wrong!',
                        title: 'Error',
                        variant: 'error',
                        id: 'report-loading',
                    });
                    clearInterval(interval);
                }
            })
            .catch((e) => {
                displayUpdatedNotification({
                    message: e?.message || e,
                    title: 'Error',
                    variant: 'error',
                    id: 'report-loading'
                });
                clearInterval(interval);
            })
        } else {
            displayUpdatedNotification({
                message: 'Something went wrong!',
                title: 'Error',
                variant: 'error',
                id: 'report-loading'
            });
            clearInterval(interval);
        }
    }, 5000)
}