import { Drawer, Text } from '@mantine/core';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import Filter from '../../components/CommonComponents/Filter/Filter';
import Nums from '../../components/Body/Nums';
import { getEligibleReceiptLoans } from '../../services/receipt.service';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import SimpleReactTable from '../../components/CommonComponents/Table/SimpleReactTable';
import { createColumnHelper } from '@tanstack/react-table';
import { DrawerModal } from '../../components/CommonComponents/Drawer/DrawerModal';

const Receipt = ({ open, onClose }) => {
  const columnHelper = createColumnHelper();
  const [filter, setFilter] = useState(true);
  const [loading, setLoading] = useState();
  const [approvedIMD, setApprovedIMD] = useState([]);
  const [filterDetails, setFilterDetails] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (values, filterData) => {
    setFilterDetails(filterData)
    setLoading(true)
    getEligibleReceiptLoans({...values})
      .then((res) => {
        setApprovedIMD(res)
      })
      .catch((e) => {
        setApprovedIMD()
        displayNotification({id: 'customer-imd', message: e?.message || e, variant: 'error'})
      })
      .finally(() => {
        setLoading(false)
        setFilter(false)
      })
  }

  const columns = [
    columnHelper.accessor('prospect_code', {
      header: 'Tranche Code',
    }),
    columnHelper.accessor('customer_name', {
      header: 'Name',
    }),
    columnHelper.accessor('disbursed_date', {
      header: 'Disbursed Date',
      cell: (value) => dateFormat(value.getValue()),
    }),
    columnHelper.accessor('loan_amount', {
      header: <Text sx={{textAlign: 'right'}}>Loan Amount</Text>,
      cell: (value) =>  <Nums value={value.getValue()} styles={{textAlign: 'right'}} />,
    }),
    columnHelper.accessor('outstanding_principle', {
      header: <Text sx={{textAlign: 'right'}}>Principal Amount</Text>,
      cell: (value) =>  <Nums value={value.getValue()} styles={{textAlign: 'right'}} />,
    }),
  ];
  return (
    <DrawerModal
      open={open}
      onClose={onClose}
      filterData={filterDetails}
      title='Find Loan Details'
      position='right'
      padding='xl'
      size='50%'
    >
      {/* Used to show filter */}
      { filter ?
        <Filter 
          callback={onSubmit} 
          onClose={onClose} 
          open={open}
          loading={loading} 
          type={'receipt'} 
        /> :
        <SimpleReactTable
          rowData={approvedIMD}
          columnData={columns}
          filterData={filterDetails}
          setFilter={setFilter}
          showFilter
          sx={{ height: '70vh' }}
          rowKeyGetter={(row) => {
            return row?.id;
          }}
          onRowClick={(i, o) =>
            navigate(`/receipt/${i?.customer_id}/${i?.loan_id}`, { state: { from: location.pathname } })
          }
        />
      }
    </DrawerModal>
  );
};

export default Receipt;
