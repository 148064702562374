import { Box, Grid, Image } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Body from "../../components/Body/Body";
import ControllerDatePicker from "../../components/CommonComponents/TextField/ControllerDatePicker";
import ControllerSelect from "../../components/CommonComponents/TextField/ControllerSelect";
import ControllerTextField from "../../components/CommonComponents/TextField/ControllerTextField";
import TitleViewer from "../../components/CommonComponents/Title/TitleViewer";
import PageTitle from "../../components/PageTitle/PageTitle";
import ControllerCurrencyInput from "../../components/CommonComponents/TextField/ControllerCurrencyInput";
import { dateFormat } from "../../components/DateFormat/dateFormat";
import Remarks from "../../components/CommonComponents/Remarks/Remarks";
import ViewRemark from "../../components/Remark/ViewRemark";
import ModalDataViwer from "../../components/SucessfullModal/ModalDataViwer";
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { currencyAsFloat } from "../../components/Currency/currencyFormat";
import dayjs from "dayjs";
import ActionButtons from "../../components/CommonComponents/ActionButtons/ActionButtons";
import { instrumentType } from "../../utils/InstrumentTypes";
import { ViewData } from "../../components/CommonComponents/Preview/FilePreview";
import { getStageforModules } from "../../services/module.service";
import { STAGE_CHANGE_TEXT } from "../../constants/textMessage";
import {
  createUnidentifiedReceipt,
  saveAndSendUnidentifiedReceiptForNextStage,
  sendUnidentifiedReceiptForNextStage,
  updateUnidentifiedReceipt,
  getUnidentifiedReceiptById,
  getUnidentifiedReceiptRemarksById,
} from "../../services/unidentifiedReceipt.service";
import { getBusinessBanks } from "../../services/banks.service";
import ImdStyle from '../IMD/Imd.styles';
import IdBadge from '../../components/CommonComponents/Preview/IdBadge';
import useSystemConfigStore from "../../store/systemConfigStore";
import { PAYMENT_REF_NO_LIMIT } from "../../constants/validationCheck";
import { GENERAL_IMG } from "../../constants";

const schema = yup.object({
  unidentified_receipt_date: yup
    .string()
    .nullable()
    .required("Please select receipt Date"),
  unidentified_receipt_amount: yup
    .string()
    .required("Please Enter Receipt Amount"),
  instrument_type: yup
    .string()
    .nullable()
    .required("Please select instrument type"),
  target_bank_id: yup
    .string()
    .nullable()
    .required("Please select customer bank"),
  payment_ref_no: yup
    .string()
    .max(parseInt(PAYMENT_REF_NO_LIMIT),  `payment no must be less then ${PAYMENT_REF_NO_LIMIT} characters`)
    .nullable()
    .required("Please Enter Payment Ref No"),
});

const UnidentifiedReceiptCreation = ({ formType }) => {
  const { createdUnReceiptId } = useParams();
  const systemDate = useSystemConfigStore(store => store.config);
  // set remarks modal open or close as boolean
  const [open, setOpen] = useState(false);
  // set loading for buttons
  const [buttonLoader, setButtonLoader] = useState(false);
  // handling the status
  const [status, setStatus] = useState();
  // handling the remarks modal loading
  const [remarksLoading, setRemarksLoading] = useState();
  // handling the successful modal [contain the modal open or close and title]
  const [sucessfulmodal, setSucessfulmodal] = useState({});
  // handling the remarks modal label
  const [label, setLabel] = useState();
  // handling the remarks modal
  const [remarksOpen, setRemarksOpen] = useState(false);

  /** used to get the created receipt details by sending receipt id */
  const { data: unReceiptDetails = {} } = useQuery(
    ["unidentified-receipt-details", createdUnReceiptId],
    () => getUnidentifiedReceiptById(createdUnReceiptId),
    {
      enabled: Boolean(createdUnReceiptId),
      select: (data) => {
        return data[0]
      },
      refetchOnWindowFocus: false,
    }
  );

  // used to get the available stages
  const { data: stages = [] } = useQuery(
    ["unidentified-receipt-stage"],
    () => getStageforModules("unidentified_receipt"),
    {
      select: (data) => {
        return {
          current_stage: "draft",
          next_stage: data[1],
        };
      },
      refetchOnWindowFocus: false,
    }
  );

  // get the business bank list
  const { data: targetBank = [] } = useQuery(
    ["company-bank"],
    () => getBusinessBanks({ category: "all" }),
    {
      refetchOnWindowFocus: false,
    }
  );

  // get the unidentified receipt remarks by id
  const { data: reviewTrail = [] } = useQuery(
    ["remarks", createdUnReceiptId],
    () => getUnidentifiedReceiptRemarksById(createdUnReceiptId),
    {
      enabled: Boolean(createdUnReceiptId),
      refetchOnWindowFocus: false,
    }
  );

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues:
      formType == "view"
        ? {
            ...unReceiptDetails,
          }
        : {
            instrument_date: dayjs(new Date()).format(),
          },
  });
  const values = watch();

  useEffect(() => {
    if (Object.keys(unReceiptDetails).length) {
      reset(unReceiptDetails);
    }
  }, [unReceiptDetails]);

  useEffect(() => {
    if (Object.keys(systemDate).length && !createdUnReceiptId) {
      setValue("unidentified_receipt_date", systemDate?.current_system_date);
      setValue("instrument_date", systemDate?.current_system_date);
    }
  }, [systemDate]);

  const handleReviewModal = () => {
    schema
      .validate(values)
      .then((res) => {
        setOpen(true);
        setStatus("review");
        setLabel(
          stages?.next_stage == "approval"
            ? "Send for Approval"
            : "Send for Review"
        );
      })
      .catch((err) => {
        displayNotification({ message: err?.errors, variant: 'error' });
      });
  };

  const onSubmit = () => {
    // body is used for create and update receipt
    const body = {
      unidentified_receipt_amount: currencyAsFloat(
        values?.unidentified_receipt_amount
      ),
      unidentified_receipt_date: dateFormat(
        values?.unidentified_receipt_date,
        "YYYY-MM-DD"
      ),
      instrument_date: dateFormat(
        systemDate?.current_system_date,
        "YYYY-MM-DD"
      ),
      instrument_type: values?.instrument_type,
      payment_ref_no: values?.payment_ref_no?.toUpperCase(),
      target_bank_id: values?.target_bank_id,
    };

    setButtonLoader({ save: true });
    if (!createdUnReceiptId) {
      createUnidentifiedReceipt(body)
        .then((res) => {
          setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.save });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' });
          }
        })
        .finally(() => {
          setButtonLoader({ save: false });
        });
    } else {
      updateUnidentifiedReceipt(createdUnReceiptId, body)
        .then((res) => {
          setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.update });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' });
          }
        })
        .finally(() => {
          setButtonLoader({ save: false });
        });
    }
  };

  const OnSendForReview = (remarks) => {
    // body to send for review from created receipt
    const body = {
      remarks: remarks,
      remark_type: "maker",
    };
    // body to direct send for review
    const savebody = {
      unidentified_receipt_amount: currencyAsFloat(
        values?.unidentified_receipt_amount
      ),
      unidentified_receipt_date: dateFormat(
        values?.unidentified_receipt_date,
        "YYYY-MM-DD"
      ),
      instrument_date: dateFormat(
        systemDate?.current_system_date,
        "YYYY-MM-DD"
      ),
      instrument_type: values?.instrument_type,
      payment_ref_no: values?.payment_ref_no?.toUpperCase(),
      target_bank_id: values?.target_bank_id,
    };

    setRemarksLoading(true);
    if (!createdUnReceiptId) {
      saveAndSendUnidentifiedReceiptForNextStage(savebody)
        .then((res) => {
          setSucessfulmodal({
            open: true,
            title:
              stages?.next_stage == "approval"
                ? STAGE_CHANGE_TEXT?.approval
                : STAGE_CHANGE_TEXT?.review,
          });
        })
        .catch((e) => {
          if (e?.RequestValidationError) {
            Object.keys(e?.RequestValidationError).forEach((item, index) => {
              setError(item, { message: e?.RequestValidationError[item] });
            });
          } else {
            displayNotification({ message: e?.message || e, variant: 'error' });
          }
        })
        .finally(() => {
          setOpen(false);
          setRemarksLoading(false);
        });
    } else {
      // first the update function will call if it get pass then the review function will call and if it get pass then the generate PDF function will call
      updateUnidentifiedReceipt(createdUnReceiptId, savebody)
        .then((res) => {
          sendUnidentifiedReceiptForNextStage(createdUnReceiptId, body)
            .then((res) => {
              setSucessfulmodal({
                open: true,
                title:
                  stages?.next_stage == "approval"
                    ? STAGE_CHANGE_TEXT?.approval
                    : STAGE_CHANGE_TEXT?.review,
              });
            })
            .catch((e) => {
              if (e?.RequestValidationError) {
                Object.keys(e?.RequestValidationError).forEach(
                  (item, index) => {
                    setError(item, {
                      message: e?.RequestValidationError[item],
                    });
                  }
                );
              } else {
                displayNotification({ message: e?.message || e, variant: 'error' });
              }
            });
        })
        .catch((e) => {
          displayNotification({ message: e?.message || e, variant: 'error' });
        })
        .finally(() => {
          setOpen(false);
          setRemarksLoading(false);
        });
    }
  };

  return (
    <>
      <Body background={false}>
        {/* name and id has send as props to display in the page */}
        <PageTitle title={"Unidentified Receipt"} />
      </Body>
      <Box sx={{ position: "relative" }}>
        <Body>
          {/* receipt name is send as props to display and status value is send to change the color of the badge */}
          {unReceiptDetails?.prospect_code ? (
            <div style={ImdStyle.flag}>
              <IdBadge
                label={
                  unReceiptDetails?.prospect_code || unReceiptDetails?.prospect_code
                }
              />
            </div>
          ) : null}
          <Grid gutter="xs" mt={"md"}>
            <Grid.Col span={4} sx={{ display: "flex", alignItems: "center" }}>
              <Grid.Col
                span={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  src={GENERAL_IMG}
                  alt="Illustration"
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={4}>
              <Grid.Col span={12}>
                <ControllerDatePicker
                  name="unidentified_receipt_date"
                  label="Receipt Date"
                  required
                  maxDate={new Date(systemDate?.current_system_date)}
                  control={control}
                  errors={errors?.receipt_date?.message}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <ControllerCurrencyInput
                  name="unidentified_receipt_amount"
                  label="Receipt Amount"
                  value={values?.unidentified_receipt_amount}
                  amountInWords
                  required
                  control={control}
                  errors={errors?.receipt_amount?.message}
                  autoComplete='off'
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <ControllerSelect
                  name="instrument_type"
                  label="Instrument Type"
                  required
                  control={control}
                  autoSelect
                  setValue={setValue}
                  data={[...instrumentType]}
                  errors={errors?.instrument_type?.message}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={4}>
              <Grid.Col>
                <TitleViewer title="Entity Bank" />
              </Grid.Col>
              <Grid.Col pt="md">
                <ControllerSelect
                  name="target_bank_id"
                  control={control}
                  autoSelect
                  setValue={setValue}
                  spanSize={12}
                  displayLabel={false}
                  placeholder="Select Bank Details"
                  data={targetBank}
                  errors={errors?.target_bank_id?.message}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <ViewData
                  title="Instrument Date"
                  value={dateFormat(values?.instrument_date || systemDate?.current_system_date)}
                  type={"text"}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <ControllerTextField
                  name="payment_ref_no"
                  label="Payment Ref.No"
                  required
                  value={values?.payment_ref_no?.toUpperCase()}
                  disabled={values?.instrument_type === "cash"}
                  control={control}
                  errors={errors?.payment_ref_no?.message}
                  autoComplete='off'
                />
              </Grid.Col>
            </Grid.Col>
          </Grid>
          {/** This Action buttons component is used to display the buttons in the flow */}
          <ActionButtons
            status={unReceiptDetails?.status_value ? unReceiptDetails?.status_value : "draft"}
            base={'creation'}
            nextStage={stages?.next_stage}
            sendForReviewCB={handleReviewModal}
            sendForApprovalCB={handleReviewModal}
            saveCB={() => {
              handleSubmit(onSubmit)();
            }}
            loading={{ save: buttonLoader?.save }}
            remarksCB={() => setRemarksOpen(true)}
            remarks={reviewTrail}
            isReady={Boolean(stages?.next_stage)}
            currentStatus={unReceiptDetails?.status_value}
            moduleApplicationId={unReceiptDetails?.id}
            moduleName={'unidentified_receipt'}
          />
          {/** display the modal on sucessfull status change */}
          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={unReceiptDetails?.code}
            title={sucessfulmodal?.title}
            href={"/unidentified-receipt"}
            hreftext={"Back to list table"}
          />
          {/** used to get the remarks from the user */}
          <Remarks
            open={open}
            setOpen={setOpen}
            type={stages?.next_stage}
            callback={OnSendForReview}
            remarksTitle={
              stages?.next_stage == "review"
                ? "Review Remarks"
                : "Approval Remarks"
            }
            check={status}
            label={label}
            loading={remarksLoading}
            currentStatus={unReceiptDetails?.status_value}
            moduleApplicationId={unReceiptDetails?.id}
            moduleName={'unidentified_receipt'}
          />
        </Body>
        {/** used to display the remarks */}
        <ViewRemark
          open={remarksOpen}
          onClose={setRemarksOpen}
          remarkTrail={reviewTrail}
        />
      </Box>
    </>
  );
};

export default UnidentifiedReceiptCreation;
