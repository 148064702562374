import {
  Box,
  Button,
  Image,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import { Plus, Search } from "tabler-icons-react";
// import useTableInfo from "../../../hooks/useTableInfo";
import PageTitle from "../../PageTitle/PageTitle";
import ColumnsFilter from "../Filter/ColumnsFilter";
import ReactTable from "./ReactTable";
import StatusSelection from "../ActiveStatusBar/activeStatuslist";
import useTableColumnsStore from '../../../store/tableColumnsStore';
import SelectableReactTable from './SelectableReactTable';

const DataTableWrapper = ({
  title,
  actions,
  rowData = [],
  showStatus = false,
  onRowClick,
  useApiPagination = false,
  page,
  setPage,
  apiSearch,
  rowLength,
  setRowLength,
  selectableTable = false,
  totalNoOfPages,
  totalNoOfRecords,
  module_name = "",
  summaryDetailsJSX,
  customStatus = [],
  columnData,
  noDataText = "No data yet!",
  noDataSubText = "No data found in this section, try adding data.",
  breadcrumb = true,
  filter = true,
  searchOption = true,
  pageTop = true,
  styles = {},
  addDataOption = {},
  addDataCallback,
  loading = false,
  options = false,
  allowSorting = false,
  sorting,
  apiSorting = false,
  setSorting,
  isSortingRemoval,
  localKey,
}) => {
  const [search, setSearch] = useState();
  // const { tableData, setTableData } = useTableInfo();
  const { tableData, saveTableData } = useTableColumnsStore(store => ({
    tableData: store?.getTableData(localKey),
    saveTableData: store?.saveTableData,
  }))
  const [filteredColumnData, setFilteredColumnData] = useState([]);

  const addCellKey = (filteredColumn, actualColumn) => {
    /** It searches for an element in the actualColumn array that has a header property equal to the current element in the filteredColumn array. */
    /** If a matching element is found, the function returns the array of objects. */
    return filteredColumn?.reduce((temp, actItem) => {
      return temp.concat(
        actualColumn?.find((item) => actItem === item?.header)
      );
    }, []);
  };

  useEffect(() => {
    if (localKey && (columnData?.length)) {
      if (tableData) {
        const addData = addCellKey(tableData, columnData)
        const buffer = addData?.map(item => item?.header)
        setFilteredColumnData(addData)
        saveTableData({ [localKey]: buffer });
      }
      else
        setFilteredColumnData(columnData)
    }
    else
      setFilteredColumnData(columnData)
  }, [localKey, columnData])

  const onUpdateFilter = (data) => {
    const addData = addCellKey(data, columnData);
    setFilteredColumnData(addData);
    saveTableData({ [localKey]: data })
  }

  return (
    <Box>
      {pageTop && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 1,
            alignItems: "center",
            flexWrap: 'wrap',
          }}
        >
          {breadcrumb ? (
            <PageTitle title={title} />
          ) : (
            <Title order={2} mb={"lg"} mt={"xs"}>
              {title}
            </Title>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: '2px',
              // flexFlow: 'wrap',
              alignContent: 'center',
              marginLeft: 'auto',
              marginRight: '0',
            }}
          >
            {/** We can pass a components to use it in table as a name options */}
            {options && options}
            {searchOption && (
              <TextInput
                placeholder="Search"
                onChange={(e) => {
                  apiSearch
                    ? apiSearch(e.target.value)
                    : setSearch(e.target.value);
                }}
                mx={0}
                icon={<Search size={16} />}
              />
            )}
            {filter && rowData?.length ? (
              <ColumnsFilter
                title={title}
                columnData={columnData}
                filteredColumnData={filteredColumnData}
                updateFilter={onUpdateFilter}
              />
            ) : null}
            {actions && actions}
          </Box>
        </Box>
      )}
      {/* <Box> */}
      {/* display active status bar */}
      {showStatus ? <StatusSelection module_name={module_name} customStatus={customStatus} /> : null}
      {!loading && !rowData.length ? (
        <Box
          mt="md"
          p="xl"
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 10,
            height: "60vh",
          }}
        >
          <Image
            src="https://i.imgur.com/A6KRQAV.png"
            width={150}
            height={100}
            radius="md"
          />
          <Box>
            <Text>{noDataText}</Text>
            <Text size="sm" sx={{ color: "rgb(0,0,0,0.4)" }}>
              {noDataSubText}
            </Text>
            {addDataOption && (
              <Button
                mt="md"
                variant="outline"
                size="xs"
                leftIcon={<Plus size={12} />}
                onClick={addDataCallback}
              >
                Add Data
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            {summaryDetailsJSX && summaryDetailsJSX}
            <Box sx={{ flex: 1, overflowX: "scroll" }}>
              {!selectableTable ? (
                <ReactTable
                  page={page}
                  setPage={setPage}
                  rowLength={rowLength}
                  setRowLength={setRowLength}
                  totalNoOfRecords={totalNoOfRecords}
                  totalNoOfPages={totalNoOfPages}
                  useApiPagination={useApiPagination}
                  rowData={rowData}
                  onRowClick={onRowClick}
                  columnData={filteredColumnData}
                  search={search}
                  styles={styles}
                  setSearch={setSearch}
                  loading={loading}
                  allowSorting={allowSorting}
                  apiSorting={apiSorting}
                  sorting={sorting}
                  isSortingRemoval={isSortingRemoval}
                  setSorting={setSorting}
                />
              ) : (
                <SelectableReactTable
                  page={page}
                  setPage={setPage}
                  rowLength={rowLength}
                  setRowLength={setRowLength}
                  totalNoOfRecords={totalNoOfRecords}
                  totalNoOfPages={totalNoOfPages}
                  useApiPagination={useApiPagination}
                  rowData={rowData}
                  onRowClick={onRowClick}
                  columnData={filteredColumnData}
                  search={search}
                  styles={styles}
                  setSearch={setSearch}
                  loading={loading}
                />
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default DataTableWrapper;
