import { Modal } from '@mantine/core';
import React from 'react';

const DialogModal = ({ open, onClose, title, children, sx, styles, ...props }) => {
  return (
    <Modal
      opened={open}
      onClose={onClose}
      title={title}
      overlayBlur={3}
      size='md'
      padding='xl'
      sx={sx}
      styles={styles} 
      {...props}
    >
      {children}
    </Modal>
  );
};

export default DialogModal;
