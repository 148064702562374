import { URL } from "../api/serverUrls";
import { getAPICall, patchAPICall, postAPICall } from '.';

// imd services here

export const getAllImd = ({page, search, records = 10, status, entity}) => {
  /** used to get all imd for table */
  let url = `imds?page=${page}&records=${records}&status=${status}`
  if(search) url = url + `&customer_id_name=${search}`;
  if(entity && entity !== 'all') url = url + `&entity_id=${entity}`;
  return getAPICall(url, { returnObject: true });
};

export const getImdById = (imdId) => {
  /** used to get imd data by id */
  return getAPICall(`${URL.imd}/${imdId}`, { returnFirstItem: true });
};

export const customerApplicationSearch = (body = {}) => {
  /** used to get the customer application details by cust id */
  return postAPICall("/customer/applications/search", { body: body });
};

export const customerSearch = (customerIdName, moduleName = "all", signal) => {
  const handleValue = (n) => {
    const result = n?.map((item) => ({
      label: `${item.name} (${item.id})`,
      value: item.id,
      ...item,
    }));
    return result || [];
  };
  
  /** used to get customer name and id matched to the customerIDName prop */
  return getAPICall(
    `/customer/search/?customer_id_name=${customerIdName}&module_type=${moduleName}`,
    { selectValue: handleValue, signal }
  );
};

export const getImdReviewTrail = (id) => {
  /** used to get remarks for imd by id */
  return getAPICall(`imd/${id}/remark`);
};

export const CompanyBankDetails = (id) => {
  const handleValue = (n) => {
    const result = n?.map((item) => ({
      label: item.account_number + ` (${item.bank_name})`,
      value: item.id,
      ...item,
    }));
    return result || [];
  };

  /** used to get the company bank */
  return getAPICall(`/business/bank/${id}`, { selectValue: handleValue });
};

export const customerBankDetails = (custId) => {
  const handleValue = (n) => {
    const result = n?.map((item) => ({
      label: item.account_number + ` (${item.bank_name})`,
      value: item.id,
      ...item,
    }));
    return result || [];
  };
  
  /** used to get the customer bank by custId */
  return getAPICall(`/customer/bank/${custId}`, { selectValue: handleValue });
};

export const saveImd = (body) => {
  /** used to save the imd */
  return postAPICall(URL.imd, { body: body });
};

export const saveAndNext = (body) => {
  /** move the imd to next stage */
  return postAPICall(`${URL.imd}/direct_save`, { body: body });
};

export const updateImd = (id, body) => {
  /** used to update the imd */
  return patchAPICall(`${URL.imd}/${id}`, { body: body });
};

export const calculateImd = (body) => {
  /**used to calculate the fee for imd */
  return postAPICall(`${URL.imd}/compute-fees`, { body: body });
};

export const sendForNextStage = (id, body) => {
  /** move the imd to next stage by id */
  return postAPICall(`${URL.imd}/${id}/status/change`, { body: body });
};

export const rejectImd = (id, body) => {
  /** used to reject the imd */
  return postAPICall(`${URL.imd}/${id}/rejected`, { body: body });
};

export const pushbackImd = (id, body) => {
  /** used to pushback the imd */
  return postAPICall(`${URL.imd}/${id}/pushback`, { body: body });
};

export const sedIMDforCancellation = (id, body) => {
  /** used to cancel the imd */
  return postAPICall(`${URL.imd}/${id}/cancelled`, { body: body });
};
