import {
  Title,
  Skeleton,
  createStyles,
} from "@mantine/core";
import { useState } from "react";
import changeValueFormat from "../../utils/changeValueFormat";
import styled from "@emotion/styled";

const CRORE = 10000000;

const IncomeDataTable = styled.table`
  margin-right: 10px;
  margin-top: 4px;
  min-width: 100%;
  font-size: 12px;
  text-align: right;
  tr td {
    padding: 4px 4px;
    border-bottom: 1px dashed rgba(100,100,100,0.2);
    border-right: 1px dashed rgba(100,100,100,0.2);
    width: 25%;
  }
  tr th {
    padding: 4px 4px;
    border-bottom: 1px solid rgba(100,100,100,0.2);
    border-right: 1px dashed rgba(100,100,100,0.2);
    width: 25%;
  }
  tr:last-of-type td {
    border-bottom: 0;
  }
  tr td:last-of-type, tr th:last-of-type {
    border-right: 0;
  }
  tr td:first-of-type, tr th:first-of-type {
    text-align: left;
  }
`;

const useStyles = createStyles({
  displayAmount: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: 'right',
    fontWeight: "400",
    gap: "2px",
    fontSize: '12px'
  },
})

// used to display the currency in cr by clicking on
const DisplayValue = ({ value, type = "amount", styles = {} }) => {
  const { classes } = useStyles();
  const [amountInCR, setAmountInCR] = useState(true);

  let FinalValue = changeValueFormat(value, type);

  let suffix;
  if (amountInCR) {
    FinalValue = changeValueFormat(value / CRORE, type, { round: false });
    suffix = "Cr";
  }

  return value ? (
    <Title
      onClick={() => setAmountInCR(!amountInCR)}
      order={6}
      sx={{...styles}}
      className={classes.displayAmount}
    >
      {FinalValue} {suffix}
    </Title>
  ) : (
    "-"
  );
};

/**
 * Displays the income content table.
 *
 * @param {object} data - The data for the table.
 * @param {boolean} [loading=false] - Indicates if the table is in loading state.
 * @return {JSX.Element} The rendered income content table.
 */
const DisplayIncomeContentTable = ({ data, total, loading = false }) => {

  return (
    <>
      <IncomeDataTable>
        <tbody>
          <tr>
            <th>Entities</th>
            <th>Interest</th>
            <th>Penal</th>
            <th>Total</th>
          </tr>
          {!loading ? 
              data?.length ? 
              <>
                {data?.map((item, index) => (
                  <tr key={index} style={{ backgroundColor: item?.color && item?.color }}>
                    <td>{item?.label}</td>
                    <td>{item?.interest ? <DisplayValue value={item?.interest} /> : 0}</td>
                    <td>{item?.penal ? <DisplayValue value={item?.penal} /> : 0}</td>
                    <td>{<DisplayValue value={parseInt(item?.interest) + parseInt(item?.penal)} styles={{fontWeight: 600}} /> || 0}</td>
                  </tr>
                ))}
                <tr>
                  <td style={{fontWeight: 600}}>Total</td>
                  <td><DisplayValue value={total?.total_interest} styles={{fontWeight: 600}} /></td>
                  <td><DisplayValue value={total?.total_penal} styles={{fontWeight: 600}} /></td>
                  <td><DisplayValue value={(total?.total_interest + total?.total_penal)} styles={{fontWeight: 600}} /></td>
                </tr> 
              </>
              : null
            :
            [1,2,3]?.map((item) => (
              <tr key={item}>
                <td><Skeleton height={15} width={"60%"} /></td>
                <td><Skeleton height={15} width={"60%"} /></td>
                <td><Skeleton height={15} width={"60%"} /></td>
                <td><Skeleton height={15} width={"60%"} /></td>
              </tr>
            ))
          }
        </tbody>
      </IncomeDataTable>
    </>
  )
}

export default DisplayIncomeContentTable