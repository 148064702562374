import { Box, Loader, createStyles, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import PreviewFile from './PreviewFile';
import { Modal } from '@mantine/core';
import DialogModal from './../Modal/DialogModal';
import { Paperclip, Trash } from 'tabler-icons-react';
import { COLORS } from '../../../constants';
import { getSignedUrl } from '../../../services/common.service';

const useStyles = createStyles(() => ({
  title: {
    fontSize: 11,
    color: '#888',
  },
  details: {
    borderColor: 'grey',
    minWidth: 80,
    maxWidth: 250,
    textAlign: 'left',
  },
  text: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
  },
  avatarCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  maindiv: {
    display: 'flex',
  },
}));

export const AvatarCard = ({ file, title = 'File', handleDeleteFile, creation = true, fileName = 'Reference File' }) => {
  const { classes } = useStyles();
  const [imageModal, setImageModal] = useState({});

  useEffect(() => {
    imageModal?.getStoredURL && getSignedFile(file)
  }, [imageModal?.getStoredURL])

  const getSignedFile = async (d) => {
    const response = await getSignedUrl(d)
    setImageModal({
      open: true,
      image: response?.url,
      type: file?.endsWith('.pdf'),
    })
  }

  return (
    <>
      <Box
        sx={{
          transition: '.2s ease-in-out',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: '#fcfcfc',
            '.attachmentDelete': {
              visibility: 'visible',
            },
          },
          border: `1px dashed ${COLORS.gray(20)}`,
          maxWidth: 200,
          minWidth: 150,
          height: 25,
          borderRadius: 6,
          display: 'flex',
          alignItems: 'center',
          margin: '0px 0px 8px 15px',
          position: 'relative',
        }}
        onClick={() =>
          setImageModal({
            open: false,
            getStoredURL: true,
            image: file,
            type: file?.endsWith('.pdf'),
          })
        }
      >
        {imageModal?.getStoredURL ?
          <Loader color="teal" variant="dots" /> :          
          <Paperclip size={16} color='green' />
        }
        <Text size={'xs'} ml={'sm'} sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{fileName}</Text>
        {creation &&
          <Box
            className='attachmentDelete'
            sx={{
              position: 'absolute',
              width: 25,
              height: 23,
              bottom: 0,
              right: 0,
              backgroundColor: 'rgb(255,59,48)',
              borderRadius: '5px 5px 5px 0px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              visibility: 'hidden',
              zIndex: 9999,
              ':hover': {
                border: '2px solid #F19C9C',
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteFile();
            }}
          >
            <Trash size={12} color='white' />
          </Box>}
      </Box>
      <DialogModal
        size='650px'
        className={classes.dialogBox}
        title={title}
        opened={imageModal.open}
        onClose={() => setImageModal({ open: false })}
      >
        <PreviewFile data={imageModal} />
      </DialogModal>
    </>
  );
};
