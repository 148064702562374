import {
  Box,
  Grid,
  Table,
  Text,
  ActionIcon,
  Tooltip,
  Center,
  Image,
  Modal
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Body from '../../components/Body/Body';
import DataViewerWapper from '../../components/CommonComponents/Preview/DataViewerWapper';
import { ViewFormData } from '../../components/CommonComponents/Preview/FilePreview';
import TitleViewer from '../../components/CommonComponents/Title/TitleViewer';
import DashBoardCustomerDetails from '../../components/DashboardCard/dashboard';
import PageTitle from '../../components/PageTitle/PageTitle';
import { COLORS, STAGE_CHANGE_TEXT, PDF_TEXT_MESSAGE } from '../../constants';
import ImdStyle from '../IMD/Imd.styles';
import {
  getAllReceiptsById,
  pushbackReceipt,
  rejectReceipt,
  getReceiptRemarks,
  sendReceiptforCancellation,
  sendReceiptforNextStage
} from '../../services/receipt.service';
import { CompanyBankDetails } from '../../services/imd.service';
import Remarks from '../../components/CommonComponents/Remarks/Remarks';
import ViewRemark from '../../components/Remark/ViewRemark';
import Nums from '../../components/Body/Nums';
import ModalDataViwer from '../../components/SucessfullModal/ModalDataViwer';
import IdBadge from '../../components/CommonComponents/Preview/IdBadge';
import { dateFormat } from '../../components/DateFormat/dateFormat';
import { displayNotification } from "../../components/CommonComponents/Notification/displayNotification";
import { useQuery } from '@tanstack/react-query';
import ActionButtons
  from '../../components/CommonComponents/ActionButtons/ActionButtons';
import { generateReceiptPdfbyID } from '../../services/pdf.service';
import { useQueryClient } from "@tanstack/react-query";
import AccountingInterfaceModal
  from '../../components/CommonComponents/AccuntingInterface/AccountingInterfaceModal';
import { getStageforModules } from '../../services/module.service';
import { CANCELLATION_REVIEW, IN_APPROVAL, IN_REVIEW } from '../../constants/status';
import UnidentifiedReceiptListModal from './UnidentifiedReceiptListModal';
import { getCustomerBankById } from './../../services/customer.service';
import { FileInfo } from 'tabler-icons-react';
import { openFile } from '../../utils/openFile';
import { getSignedUrl } from '../../services/common.service';
import DialogModal from '../../components/CommonComponents/Modal/DialogModal';

const ReceiptReview = ({ action = true, home = '/receipt' }) => {

  const { receiptId, createdReceiptId } = useParams();
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState();
  const [remarksLoading, setRemarksLoading] = useState();
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [remarksTitle, setRemarksTitle] = useState();
  const [status, setStatus] = useState();
  const [sucessfulmodal, setSucessfulmodal] = useState({});
  const [pdfLoading, setpdfLoading] = useState();
  const [modalObj, setModalObj] = useState({});
  const queryClient = useQueryClient();
  const [interfaceOpen, setInterfaceOpen] = useState(false);
  const [signedUrl, setSignedUrl] = useState();

  const { data: receiptDetails = [], isLoading } = useQuery(
    ['receipt-by-id', createdReceiptId],
    () => createdReceiptId && getAllReceiptsById(createdReceiptId),
    { refetchOnWindowFocus: false }
  )

  const { data: stages = [] } = useQuery(
    ['receipt-stages'],
    () => getStageforModules('receipt'),
    {
      enabled: Boolean(receiptDetails?.status_value),
      select: (dataItem) => {
        return {
          'current_stage': receiptDetails?.status_value,
          'next_stage': dataItem[dataItem.indexOf(receiptDetails?.status_value) + 1]
        }
      },
      refetchOnWindowFocus: false
    }
  );

  const { data: sourceBank = {} } = useQuery(
    ['customer-bank', receiptId],
    () => getCustomerBankById({ customerId: receiptId, bankId: receiptDetails?.source_bank_id }),
    {
      enabled: Boolean(receiptDetails?.source_bank_id),
      select: (data) => {
        return data[0]
      },
    }
  );

  const { data: targetBank = [], isLoading: targetBankLoading } = useQuery(
    ['company-bank', receiptDetails?.target_bank_id],
    () => CompanyBankDetails(receiptDetails?.target_bank_id),
    {
      enabled: Boolean(receiptDetails?.target_bank_id),
      refetchOnWindowFocus: false
    }
  );

  const { data: reviewTrail = [] } = useQuery(
    ['remarks', createdReceiptId],
    () => getReceiptRemarks(createdReceiptId),
    { refetchOnWindowFocus: false }
  );

  const getUrl = async () => {
    if (receiptDetails?.receipt_proof_url) {
      const response = await getSignedUrl(receiptDetails?.receipt_proof_url)
      setModalObj({ modal: true, type: 'proof' })
      setSignedUrl({url: response?.url})
    }
  }

  const handlePDF = () => {
    setpdfLoading(true)
    generateReceiptPdfbyID(createdReceiptId)
      .then((res) => {
        queryClient.invalidateQueries(['receipt-by-id', createdReceiptId]);
        displayNotification({ message: PDF_TEXT_MESSAGE?.receipt, variant: 'success' })
        openFile(res)
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' })
      })
      .finally(() => {
        setpdfLoading(false)
      })
  }

  const onSendReceiptforNextStage = (remarks, status) => {
    const body = {
      remarks,
    };

    setRemarksLoading(true);
    sendReceiptforNextStage(createdReceiptId, body)
      .then((data) => {
        setSucessfulmodal({
          open: true, title:
            status == IN_REVIEW ? STAGE_CHANGE_TEXT?.approval
              : status == IN_APPROVAL ? STAGE_CHANGE_TEXT?.approved
                : status == CANCELLATION_REVIEW ? STAGE_CHANGE_TEXT?.reviewCancel
                  : STAGE_CHANGE_TEXT?.approveCancel
        });
      })
      .catch((e) => {
        displayNotification({
          id: 'receipt-approve',
          message: e?.message || e,
          variant: 'error',
        });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const handlePushback = (remarks) => {
    const body = {
      remarks,
    };

    setRemarksLoading(true);
    pushbackReceipt(createdReceiptId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.pushback });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const handleReject = (remarks) => {
    const body = {
      remarks,
    };

    setRemarksLoading(true);
    rejectReceipt(createdReceiptId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.reject });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  const handleCancel = (remarks, reason) => {
    const body = {
      remarks,
      reason: reason?.reason,
    };

    setRemarksLoading(true);
    sendReceiptforCancellation(createdReceiptId, body)
      .then((res) => {
        setSucessfulmodal({ open: true, title: STAGE_CHANGE_TEXT?.cancel });
      })
      .catch((e) => {
        displayNotification({ message: e?.message || e, variant: 'error' });
      })
      .finally(() => {
        setRemarksLoading(false);
        setOpen(false);
      })
  };

  // used to call the function by status from the remarks component and pass the remarks and assignedTo to the function
  const callFunction = (remarks, status, reason) => {
    switch (status) {
      case 'review':
      case 'approval':
      case 'cancellation':
        onSendReceiptforNextStage(remarks, status);
        break;
      case 'reject':
        handleReject(remarks);
        break;
      case 'pushback':
        handlePushback(remarks);
        break;
      case 'cancel':
        handleCancel(remarks, reason);
        break;
      default:
        displayNotification({
          message: 'Something went wrong, Please contact support!',
          variant: 'error',
        });
        break;
    }
  };

  const handleCancellationCallbackButton = () => {
    setOpen(true);
    setRemarksTitle("Cancellation Remarks");
    setStatus('cancellation');
    setLabel('Send for Cancellation');
  }

  return (
    <>
      <Body background={false}>
        {/* name and id has send as props to display in the page */}
        <PageTitle title={receiptDetails?.customer_name} id={receiptDetails?.customer_id} action={receiptDetails?.unidentified_receipt_id ? <UnidentifiedReceiptListModal selectedRowId={receiptDetails?.unidentified_receipt_id} /> : null} />
      </Body>
      {/* id is send as a props to get and display the customer details  */}
      <DashBoardCustomerDetails id={receiptId} loansId={receiptDetails?.loan_id} codeName={'Receipt Code'} code={receiptDetails?.code} />
      <Box sx={{ position: 'relative' }}>
        <Body>
          {/* receipt name is send as props to display and status value is send to change the color of the badge */}
          {receiptDetails?.prospect_code && (
            <div style={ImdStyle.flag}>
              <IdBadge
                remarks={receiptDetails?.status_value}
                label={receiptDetails?.prospect_code}
              />
            </div>
          )}
          <Grid gutter='sm' mt={'md'}>
            <Grid.Col span={3}>
              {/* DataViewerWapper component is used to display the data inside the bule background */}
              <DataViewerWapper>
                <ViewFormData label='Receipt No' value={receiptDetails?.id} loading={isLoading} />
                <ViewFormData label='Receipt Date' value={dateFormat(receiptDetails?.receipt_date, 'DD/MM/YYYY')} loading={isLoading} />
                <ViewFormData
                  label='Receipt Amount'
                  value={<Nums withTooltip withCopy value={receiptDetails?.receipt_amount} />}
                  loading={isLoading}
                />
                <ViewFormData
                  label='Due Amount'
                  value={<Nums withCopy value={receiptDetails?.loan_due_amount} />}
                  loading={isLoading}
                />
                {receiptDetails?.receipt_excess_amount ?
                  <ViewFormData
                    label='Receipt Excess'
                    value={<Nums withCopy value={receiptDetails?.receipt_excess_amount} />}
                    loading={isLoading}
                  /> : null
                }
                {receiptDetails?.customer_excess_amount ?
                  <ViewFormData
                    label='Current Excess'
                    value={<Nums withCopy value={receiptDetails?.customer_excess_amount} />}
                    loading={isLoading}
                  /> : null
                }
                {receiptDetails?.used_excess_amount ?
                  <ViewFormData
                    label='Used Excess'
                    value={<Nums withCopy value={receiptDetails?.used_excess_amount} />}
                    loading={isLoading}
                  /> : null
                }
                <ViewFormData label='Instrument Date' value={dateFormat(receiptDetails?.instrument_date, 'DD/MM/YYYY')} loading={isLoading} />
                <ViewFormData label='Instrument Type' value={receiptDetails?.instrument_type?.toUpperCase()} loading={isLoading} />
                {receiptDetails?.dpd ? <ViewFormData label='DPD' value={receiptDetails?.dpd} loading={isLoading} /> : null}
                <ViewFormData label='TDS' value={receiptDetails?.tds == '1' ? 'Yes' : 'No'} loading={isLoading} />
                {receiptDetails?.tds_amount ? <ViewFormData label='TDS Amount' value={<Nums withCopy value={receiptDetails?.tds_amount} />} loading={isLoading} /> : null}
                <ViewFormData
                  label={
                    <Center mt={'-5px'}>
                      Payment Ref.No
                      {receiptDetails?.receipt_proof_url ?
                        <Tooltip
                          label={'Click to view Receipt Proof'}
                          color='gray'
                          withArrow
                          position='top-start'
                        >
                          <span>
                            <ActionIcon
                              onClick={() => {
                                setSignedUrl({loading: true})
                                getUrl()
                              }}
                              loading={signedUrl?.loading}
                            >
                              <FileInfo size={16} color={'green'} />
                            </ActionIcon>
                          </span>
                        </Tooltip>
                        : null}
                    </Center>
                  }
                  value={receiptDetails?.payment_ref_no}
                  loading={isLoading}
                />
                {receiptDetails?.receipt_type === 'CLR' ? <ViewFormData label='Receipt Type' value={receiptDetails?.receipt_type} loading={isLoading} /> : null}
              </DataViewerWapper>
            </Grid.Col>
            <Grid.Col span={3}>
              {receiptDetails?.instrument_type != 'cash' ?
                <Grid.Col span={12}>
                  {/* TitleViewer is used to display the in the title format */}
                  <TitleViewer title='Bank Details of Customer' />
                  <ViewFormData
                    style={{ paddingTop: '10px' }}
                    label='Account No'
                    value={sourceBank?.account_number}
                    loading={targetBankLoading}
                  />
                  <ViewFormData
                    label='Bank Name'
                    value={sourceBank?.bank_name}
                    loading={targetBankLoading}
                  />
                </Grid.Col>
                : null}
              <Grid.Col span={12} mt={4}>
                <TitleViewer title='Bank Details of Petromoney' />
                <ViewFormData
                  style={{ paddingTop: '10px' }}
                  label='Account No'
                  value={targetBank[0]?.account_number}
                  loading={targetBankLoading}
                />
                <ViewFormData
                  label='Bank Name'
                  value={targetBank[0]?.bank_name}
                  loading={targetBankLoading}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={6}>
              <Grid.Col>
                <TitleViewer title='Receipt Appropriation' children={receiptDetails?.appropriation_application_mode ? <Text size={'sm'} color={'#969696'} sx={{ fontWeight: '500' }}>Appropration Type: {receiptDetails?.appropriation_application_mode?.toUpperCase()}</Text> : null} />
              </Grid.Col>
              <Grid.Col>
                <Table verticalSpacing='xs' fontSize='xs'>
                  <thead style={{ backgroundColor: COLORS.primary.light1 }}>
                    <tr>
                      <th>Pay Head</th>
                      <th>Due Amount</th>
                      <th>Appropriated Amount</th>
                      <th>Remaining Due</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td>Principal</td>
                      <td>
                        <Nums value={receiptDetails?.outstanding_principle} />
                      </td>
                      <td>
                        <Nums value={receiptDetails?.appropriated_principle} />
                      </td>
                      <td>
                        <Nums value=
                          {receiptDetails?.remaining_principle}
                        />
                      </td>
                    </tr>

                    {receiptDetails?.outstanding_interest ?
                      <tr>
                        <td>Interest ({receiptDetails?.interest_rate}%)</td>
                        <td>
                          <Nums value={receiptDetails?.outstanding_interest} />
                        </td>
                        <td>
                          <Nums value={receiptDetails?.appropriated_interest} />
                        </td>
                        <td>
                          <Nums value=
                            {receiptDetails?.remaining_interest}
                          />
                        </td>
                      </tr> : null
                    }

                    {receiptDetails?.outstanding_spread ?
                      <tr>
                        <td>Spread ({receiptDetails?.spread_rate}%)</td>
                        <td>
                          <Nums value={receiptDetails?.outstanding_spread} />
                        </td>
                        <td>
                          <Nums value={receiptDetails?.appropriated_spread} />
                        </td>
                        <td>
                          <Nums value=
                            {receiptDetails?.remaining_spread}
                          />
                        </td>
                      </tr> : null
                    }

                    {receiptDetails?.outstanding_addon_spread ?
                      <tr>
                        <td>Addon Spread ({receiptDetails?.addon_spread_rate}%)</td>
                        <td>
                          <Nums value={receiptDetails?.outstanding_addon_spread} />
                        </td>
                        <td>
                          <Nums value={receiptDetails?.appropriated_addon_spread} />
                        </td>
                        <td>
                          <Nums value=
                            {receiptDetails?.remaining_addon_spread}
                          />
                        </td>
                      </tr> : null
                    }

                    {/** penal for interest */}
                    {receiptDetails?.outstanding_interest_amount_during_penalty ?
                      <tr>
                        <td>Interest F1 ({receiptDetails?.interest_rate}%)</td>
                        <td>
                          <Nums value={receiptDetails?.outstanding_interest_amount_during_penalty} />
                        </td>
                        <td>
                          <Nums value={receiptDetails?.appropriated_interest_amount_during_penalty} />
                        </td>
                        <td>
                          <Nums value=
                            {receiptDetails?.remaining_interest_amount_during_penalty}
                          />
                        </td>
                      </tr> : null
                    }

                    {/** penal for spread */}
                    {receiptDetails?.outstanding_spread_amount_during_penalty ?
                      <tr>
                        <td>Spread F1 ({receiptDetails?.spread_rate}%)</td>
                        <td>
                          <Nums value={receiptDetails?.outstanding_spread_amount_during_penalty} />
                        </td>
                        <td>
                          <Nums value={receiptDetails?.appropriated_spread_amount_during_penalty} />
                        </td>
                        <td>
                          <Nums value=
                            {receiptDetails?.remaining_spread_amount_during_penalty}
                          />
                        </td>
                      </tr> : null
                    }

                    {/** penal for addon_spread */}
                    {receiptDetails?.outstanding_addon_spread_amount_during_penalty ?
                      <tr>
                        <td>Addon Spread F1 ({receiptDetails?.addon_spread_rate}%)</td>
                        <td>
                          <Nums value={receiptDetails?.outstanding_addon_spread_amount_during_penalty} />
                        </td>
                        <td>
                          <Nums value={receiptDetails?.appropriated_addon_spread_amount_during_penalty} />
                        </td>
                        <td>
                          <Nums value=
                            {receiptDetails?.remaining_addon_spread_amount_during_penalty}
                          />
                        </td>
                      </tr> : null
                    }

                    {receiptDetails?.outstanding_penal_interest_amount ?
                      <tr>
                        <td>Penal F2 ({receiptDetails?.penal_interest_percent}%)</td>
                        <td>
                          <Nums value={receiptDetails?.outstanding_penal_interest_amount} />
                        </td>
                        <td>
                          <Nums value={receiptDetails?.appropriated_penal_interest_amount} />
                        </td>
                        <td>
                          <Nums value=
                            {receiptDetails?.remaining_penal_interest_amount}
                          />
                        </td>
                      </tr> : null
                    }

                    {receiptDetails?.outstanding_charges ?
                      <tr>
                        <td>Fee</td>
                        <td>
                          <Nums value={receiptDetails?.outstanding_charges} />
                        </td>
                        <td>
                          <Nums value={receiptDetails?.appropriated_charges} />
                        </td>
                        <td>
                          <Nums value=
                            {receiptDetails?.remaining_charges}
                          />
                        </td>
                      </tr> : null
                    }

                  </tbody>
                </Table>
              </Grid.Col>
            </Grid.Col>
          </Grid>
          {/** This Action buttons component is used to display the buttons in the flow */}
          <ActionButtons
            status={stages?.current_stage}
            currentStatus={receiptDetails?.status_value}
            moduleApplicationId={receiptDetails?.id}
            moduleName={'receipt'}
            nextStage={stages?.next_stage}
            isReady={Boolean(stages?.next_stage)}
            remarks={reviewTrail}
            accountingCB={() => setInterfaceOpen(true)}
            pdfLoading={pdfLoading}
            handlePDF={() => receiptDetails?.receipt_url ? openFile(receiptDetails?.receipt_url) : handlePDF()}
            downloadText={receiptDetails?.receipt_url ? 'Download Receipt' : 'Generate Receipt'}
            sendForApprovalCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('review'); setLabel('Send for Approval') }}
            approvedCB={() => { setOpen(true); setRemarksTitle("Approval Remarks"); setStatus('approval'); setLabel('Approve') }}
            pushbackCB={() => { setOpen(true); setRemarksTitle("Pushback Remarks"); setStatus('pushback'); setLabel('Pushback') }}
            rejectCB={() => { setOpen(true); setRemarksTitle("Reject Remarks"); setStatus('reject'); setLabel('Reject') }}
            remarksCB={() => setRemarksOpen(true)}
            cancelCB={receiptDetails?.receipt_type === 'CLR' ? false : handleCancellationCallbackButton}
            approveCancelCB={() => { setOpen(true); setRemarksTitle("Are you sure?"); setStatus('cancel'); setLabel('Confirm') }} />

          {/** used to display the modal for accounting data */}
          <AccountingInterfaceModal open={interfaceOpen} close={() => setInterfaceOpen(false)} id={createdReceiptId} name={'receipt'} code={receiptDetails?.code} />

          {/* remarks component is used to get remarks from user */}
          <Remarks
            open={open}
            setOpen={setOpen}
            type={stages?.next_stage}
            callback={callFunction}
            remarksTitle={remarksTitle}
            moduleName={'Receipt'}
            check={status}
            label={label}
            loading={remarksLoading}
            currentStatus={receiptDetails?.status_value}
            moduleApplicationId={receiptDetails?.id}
          />
          {/* ModalDataViwer is used to display the status on sucess stage change */}
          <ModalDataViwer
            opened={sucessfulmodal?.open}
            label={receiptDetails?.code}
            title={sucessfulmodal?.title}
            href={home}
            hreftext={'Back to list table'}
          />
        </Body>
        <DialogModal centered open={modalObj?.modal} onClose={() => { setModalObj({ modal: false }) }} title={modalObj?.type === 'proof' ? 'Receipt Proof' : null} withCloseButton={modalObj?.type === 'proof' ? true : false}>
          <Box sx={{ maxHeight: '500px', overflowY: 'scroll' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Image
                src={signedUrl?.url}
                alt="Receipt Proof"
              />
            </Box>
          </Box>
        </DialogModal>
        {/* viewremarks is used to display remarks */}

        <ViewRemark open={remarksOpen} onClose={setRemarksOpen} remarkTrail={reviewTrail} />

      </Box>
    </>
  );
};

export default ReceiptReview;
