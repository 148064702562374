import React, { useState } from "react";
import { Box, Text, Tooltip } from "@mantine/core";
import { motion } from "framer-motion";
import styled from "@emotion/styled";
import changeValueFormat from "../../utils/changeValueFormat";
import SummaryToggleBlock from "./SummaryToggleBlock";
import { VALUE_TYPES } from "./../../utils/changeValueFormat";
import { currencyInWords } from './../../components/CommonComponents/TextField/NumberToWords';

const DataTableWapper = styled.table`
  border-collapse: collapse;
  margin: 0 auto;
  margin-right: 10px;

  td {
    padding: 12px 16px;
    border: 1px dashed #bbb;
  }
  tr:first-of-type td {
    border-top: 0;
  }
  tr td:first-of-type {
    border-left: 0;
  }
  tr:last-of-type td {
    border-bottom: 0;
  }
  tr td:last-of-type {
    border-right: 0;
  }
`;

const CRORE = 10000000;

const DisplayValue = ({ value, type }) => {
  const [amountInCR, setAmountInCR] = useState(true);

  let FinalValue = changeValueFormat(value, type);

  if (type?.toLowerCase() !== VALUE_TYPES.amount) {
    return (
      <Text
        sx={{
          fontWeight: 500,
          display: "flex",
          flexDirection: "row",
          gap: "2px",
          justifyContent: 'right',
        }}
      >
        {FinalValue}
      </Text>
    );
  }

  let suffix = "";
  if (type?.toLowerCase() === VALUE_TYPES.amount && amountInCR) {
    FinalValue = changeValueFormat(value / CRORE, type, { round: false });
    suffix = "Cr";
  }

  return (
    <Tooltip label={currencyInWords(parseInt(value))} disabled={value < 0 || !value} color="gray">
      <Text
        onClick={() => setAmountInCR(!amountInCR)}
        sx={{
          fontWeight: 500,
          cursor: "pointer",
          display: "flex",
          justifyContent: 'right',
          flexDirection: "row",
          gap: "2px",
        }}
      >
        {FinalValue} {suffix}
      </Text>
    </Tooltip>
  );
};

const FeeSummaryDetails = ({ data = {} }) => {
  const [open, setOpen] = useState(true);
  return (
    <Box mt={10} sx={{ position: "relative" }}>
      <SummaryToggleBlock open={open} onClick={() => setOpen((v) => !v)} />
      {open ? (
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -100, opacity: 0 }}
        >
          <DataTableWapper rules="all">
            <tbody>
              {data?.headers?.map((headerItem, index) => (
                <tr key={index}>
                  <td>
                    <Text size={"sm"}>{headerItem?.name}</Text>
                  </td>
                  {data?.data?.map((item) => {
                    const key = headerItem?.key;
                    const type = headerItem?.type;
                    return (
                      <td>
                        <DisplayValue
                          value={item[key]}
                          type={type}
                        />
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </DataTableWapper>
        </motion.div>
      ) : null}
    </Box>
  );
};

export default FeeSummaryDetails;
